import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { ShopRelatedRepository } from '../shop';
import { FieldFunctions } from "../base/repository";
import { Asset } from './model';

export class AssetRepository extends ShopRelatedRepository<Asset> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'assets', fieldFunctions);
  }

  public async findByProductType(productType: string): Promise<Asset> {
    const results = await this.find({
      where: [this.whereShopIs('castiron'), { field: 'metadata.productType', operator: '==', value: productType }],
    });
    return this.firstOrNull(results);
  }

  public async findByArtisanCategory(category: string): Promise<Asset> {
    const results = await this.find({
      where: [this.whereShopIs('castiron'), { field: 'metadata.category', operator: '==', value: category }],
    });
    return this.firstOrNull(results);
  }
}
