import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BentoIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 14" {...props}>
    <path
      d="M18 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14H18C19.1 14 20 13.1 20 12V2C20 0.9 19.1 0 18 0ZM18 6H12V2H18V6ZM2 2H10V12H2V2ZM12 12V8H18V12H12ZM7.5 7C7.5 7.83 6.83 8.5 6 8.5C5.17 8.5 4.5 7.83 4.5 7C4.5 6.17 5.17 5.5 6 5.5C6.83 5.5 7.5 6.17 7.5 7Z"
      fill="#005FFF"
    />
  </SvgIcon>
);