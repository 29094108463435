import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Banner, Button, Typography } from '@castiron/components';
import { areSubscriptionFeaturesActive } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { openModal } from '../../store/reducers/modalConductor';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 0,
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

const SubscriptionBanner: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { shop, subscription, tier } = useAppSelector(state => ({
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
  }));

  useEffect(() => {
    if (!subscription || !areSubscriptionFeaturesActive(subscription)) {
      trackEvent('Subscription Banner Test Is Active');
    }
  }, []);

  const openChangePlanModal = () => {
    trackEvent('Subscription Plans Banner');
    trackEvent('Shop View Upgrade Modal', {
      shopId: shop.id,
      location: 'subscriptionBanner',
      tier: tier,
      currentPlan: subscription?.plan.name,
    });

    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  return (
    <>
      {(!subscription || !areSubscriptionFeaturesActive(subscription)) && (
        <a className={classes.clickable} onClick={openChangePlanModal}>
          <Banner variant="star-blue">
            <Grid container justify="space-between" xs={12}>
              <Typography variant="body2">
                Upgrade to our new <b>Pro Plan</b> to reduce transaction fees and get daily payouts!
              </Typography>
              <Button variant="text" className={classes.button}>
                See Plans
              </Button>
            </Grid>
          </Banner>
        </a>
      )}
    </>
  );
};

export default SubscriptionBanner;
