import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Card, TextAreaInput, TextInput } from '@castiron/components';
import { FulfillmentOption } from '@castiron/domain';
import DateTimeOptions from './DateTimeOptions';

const DeliveryDetails: React.FC = () => {
  const formik = useFormikContext<FulfillmentOption>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container direction='column'>
      <Grid item>
        <DateTimeOptions fulfillmentType='delivery' />
      </Grid>
      <Card title='Delivery Details'>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <TextInput
              label='Neighborhood, Borough, or Zip Code'
              name='postalCode'
              required
              error={formik.touched.postalCode && formik.errors.postalCode}
            />
          </Grid>
          <Grid item>
            <TextAreaInput
              label='Details'
              secondaryLabel='Provide your customers with the info they need to make an informed selection at checkout.'
              required
              name='description'
              rowCount={5}
              error={formik.touched.description && formik.errors.description}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default DeliveryDetails;