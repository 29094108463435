export const defaultCoverPhotoMap = {
  Cookies: 'Cookies',
  Cakes: 'Cakes',
  Cupcakes: 'Cupcakes',
  Breads: 'Breads',
  'Sweet Pastries': 'Sweet Pastries',
  'Charcuterie Boards': 'Charcuterie Boards',
  Chocolate: 'Chocolate',
  'Ice Cream': 'Ice Cream',
  'Other Desserts': 'Other Desserts',
  'Event Catering': 'prepared meals',
  'Ready to Eat Meals': 'prepared meals',
  'Meal Prep.': 'prepared meals',
  Juices: 'beverages',
  'Coffee/Tea': 'Coffee/Tea',
  Coffee: 'Coffee/Tea',
  'Other Beverages': 'beverages',
  Honey: 'sauces, spices, & more',
  'Spice Mixes': 'sauces, spices, & more',
  'Jams, Jellies, Preserves': 'sauces, spices, & more',
  'Hot Sauce': 'sauces, spices, & more',
  'Other Sauce or Spices': 'sauces, spices, & more',
  Bagels: 'Charcuterie Boards',
  Popcorn: 'Charcuterie Boards',
  'Pickled Products': 'Charcuterie Boards',
  'Snack Mixes': 'Charcuterie Boards',
  'Savory Pastries': 'Charcuterie Boards',
  'Other Snacks': 'Charcuterie Boards',
  'Appetizers & Snacks': 'Charcuterie Boards',
  'Cooking Classes': 'Cooking Classes',
  'Desserts & Baked Goods': 'Desserts & Baked Goods',
  'Drinks': 'beverages',
  'Hot Food': 'prepared meals',
  'Shelf-Stable Products': 'Shelf-Stable Products',
  'Whole Ingredients': 'Whole Ingredients',
};
