import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { assets } from '@castiron/castiron-firebase';

export interface AssetsState {
  assets: any[];
  castIronAssets: any[];
  loading: boolean;
  error: string;
}

const initialState: AssetsState = {
  assets: [],
  castIronAssets: [],
  loading: false,
  error: '',
};

const getAssets = async (shopId: string) => {
  const response = await assets.queryByShopId(shopId);
  console.log('response from getAssets: ', response);
  return response;
};

const getCastIronAssets = async () => {
  const response = await assets.queryByCastIron();
  console.log('response from getCastIronAssets: ', response);
  return response;
};

const getAssetById = async (id: string) => {
  const response = await assets.get(id);
  console.log('response from getAssetById: ', response);
  return response;
};

const createAsset = async asset => {
  const response = await assets.create(asset);
  console.log('response from createAsset: ', response);
  return response;
};

const updateAsset = async asset => {
  const response = await assets.update(asset);
  console.log('response from updateAsset: ', response);
  return response;
};

export const getAssetsAction = createAsyncThunk('assets/getAssets', getAssets);
export const getCastIronAssetsAction = createAsyncThunk('assets/getCastIronAssets', getCastIronAssets);
export const getAssetByIdAction = createAsyncThunk('assets/getAssetById', getAssetById);
export const createAssetAction = createAsyncThunk('assets/createAsset', createAsset);
export const updateAssetAction = createAsyncThunk('assets/updateAsset', updateAsset);

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAssetsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getAssetsAction.fulfilled, (state, action) => {
        state.assets = action.payload;
        state.loading = false;
      })
      .addCase(getCastIronAssetsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getCastIronAssetsAction.fulfilled, (state, action) => {
        state.castIronAssets = action.payload;
        state.loading = false;
      });
  },
});

export default assetsSlice.reducer;
