import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const RocketOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
    <g>
      <path
        d="M2.99992 9.00012C2.44659 9.00012 1.94659 9.22678 1.58659 9.58678C0.799919 10.3735 0.333252 13.6668 0.333252 13.6668C0.333252 13.6668 3.62659 13.2001 4.41325 12.4135C4.77325 12.0535 4.99992 11.5535 4.99992 11.0001C4.99992 9.89345 4.10659 9.00012 2.99992 9.00012ZM3.47325 11.4735C3.28659 11.6601 2.02659 11.9801 2.02659 11.9801C2.02659 11.9801 2.33992 10.7268 2.53325 10.5335C2.64659 10.4068 2.81325 10.3335 2.99992 10.3335C3.36659 10.3335 3.66659 10.6335 3.66659 11.0001C3.66659 11.1868 3.59325 11.3535 3.47325 11.4735ZM10.6133 8.10012C14.8533 3.86012 13.4399 0.560118 13.4399 0.560118C13.4399 0.560118 10.1399 -0.853216 5.89992 3.38678L4.23992 3.05345C3.80659 2.96678 3.35325 3.10678 3.03325 3.42012L0.333252 6.12678L3.66659 7.55345L6.44659 10.3335L7.87325 13.6668L10.5733 10.9668C10.8866 10.6535 11.0266 10.2001 10.9399 9.76012L10.6133 8.10012ZM3.93992 6.22012L2.66659 5.67345L3.97992 4.36012L4.93992 4.55345C4.55992 5.10678 4.21992 5.68678 3.93992 6.22012ZM8.32659 11.3335L7.77992 10.0601C8.31325 9.78012 8.89325 9.44012 9.43992 9.06012L9.63325 10.0201L8.32659 11.3335ZM9.66659 7.16012C8.78659 8.04012 7.41325 8.76012 6.97325 8.98012L5.01992 7.02678C5.23325 6.59345 5.95325 5.22012 6.83992 4.33345C9.95992 1.21345 12.3266 1.67345 12.3266 1.67345C12.3266 1.67345 12.7866 4.04012 9.66659 7.16012ZM8.99992 6.33345C9.73325 6.33345 10.3333 5.73345 10.3333 5.00012C10.3333 4.26678 9.73325 3.66678 8.99992 3.66678C8.26659 3.66678 7.66659 4.26678 7.66659 5.00012C7.66659 5.73345 8.26659 6.33345 8.99992 6.33345Z"
      ></path>
    </g>
  </SvgIcon>
);
