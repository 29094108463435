import { Input } from '@castiron/components';
import { Box, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import InputLabel from '../../../InputLabel';

type Props = {
  classes: any;
  error?: string;
  setFieldValue?: any;
  isUnlimitedInventory?: any;
  primaryLabel?: ReactNode;
  touched?: boolean;
  value?: number;
  placeholder?: string;
};

const useStyles = makeStyles(() => ({
  inventoryLabel: {
    position: 'relative',
    top: 12,
    marginBottom: 0,
  },
}));

const InventoryInput: React.FC<Props> = (props: Props) => {
  const { classes, error, isUnlimitedInventory, touched, value, placeholder } = props;
  console.log('isUnlimitedInventory: ', isUnlimitedInventory);
  const invClasses = useStyles();

  return (
    <Box className={classes.inventoryContainer}>
      <Input
        name="inventory"
        type="number"
        min={0}
        disabled={!!isUnlimitedInventory}
        step={1}
        label={<InputLabel containerClass={invClasses.inventoryLabel} primaryLabel={props.primaryLabel} />}
        value={value}
        placeholder={placeholder}
        error={error}
        touched={touched}
        onWheel={e => e.target.blur()}
      />
      {/* <Forms.Checkbox
        name="unlimitedInventory"
        // onChange={e => {
        //   console.log('CHECKBOX e: ', e.target);
        //   setFieldValue('unlimitedInventory', e.target.value);
        // }}
        label="Unlimited inventory"
      /> */}
    </Box>
  );
};

export default InventoryInput;
