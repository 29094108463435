import React from "react";
import { backendStateToFrontendState, Transaction, TransactionContext } from "@castiron/domain";
import Chip from "../Chip";

interface Props {
  transaction: Transaction;
  context: TransactionContext;
};

const statusToColorMapping = {
  open: 'warning',
  fulfilled: 'fulfilled',
  completed: 'success',
  new: 'quotesLightBlue',
  pending: 'quotesLightOrange',
  canceled: 'quotesLightRed',
  draft: 'quotesLightYellow',
  paid: 'quotesLightGreen',
  archived: 'quotesLightRed',
};

const OrderStatusPill: React.FC<Props> = (props: Props) => {
  const { transaction, context } = props;

  const frontEndStatus = backendStateToFrontendState(transaction, context).toLowerCase();
  const colorScheme = statusToColorMapping[frontEndStatus];

  return <Chip bold uppercase colorScheme={colorScheme}>{frontEndStatus.toUpperCase()}</Chip>;
};

export default OrderStatusPill;