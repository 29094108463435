export function removeEmptyStrings<T>(obj: T): Partial<T> {
  //@ts-ignore
  const data = (obj.data && obj.data instanceof Function) ? obj.data() : obj;

  Object.keys(data).forEach(function(key) {
    // Get this value and its type
    const value = data[key];
    const type = typeof value;
    if (Array.isArray(value)) {
      data[key] = value
        .map(item => {
          const type = typeof item;
          if (type === 'object' && item) {
            return removeEmptyStrings(item);
          } else return item;
        })
        .filter(item => (typeof item === 'object' ? !!Object.keys(item).length : true));
    } else if (type === 'object' && value) {
      // Recurse...
      removeEmptyStrings(value);
      // ...and remove if now "empty" (NOTE: insert your definition of "empty" here)
      if (!Object.keys(value).length) {
        delete data[key];
      }
    } else if (value === '') {
      // Undefined, remove it
      delete data[key];
    }
  });

  return data;
}
