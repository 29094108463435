import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowForward, Check, Close, VisibilityOutlined } from '@material-ui/icons';
import { ButtonV2, Chip, ChipColorScheme, StoreIcon, Typography } from '@castiron/components';
import { ChecklistValues } from '@castiron/domain';
import { toQueryString, useTracking } from '@castiron/utils';
import clsx from 'clsx';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { shopRepository } from '../../domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createOpenShopButtonClickedEvent } from '../../lib/events/commonEvents';
import { getShopAction } from '../../store/reducers/shops';

const STRIPE_DISPLAY_STATUSES = ['INCOMPLETE', 'PROCESSING', 'ERROR'];

const useStyles = makeStyles((theme: Theme) => ({
  check: {
    height: '20px',
    width: '20px',
    /* no idea why I can't center this through normal means */
    marginBottom: '6px',
    marginLeft: '1px',
    color: theme.branding.gray[100],
  },
  checkContainer: {
    height: '24px',
    width: '24px',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: '100px',
    marginTop: '2px',
    marginRight: '12px',
  },
  checkFilled: {
    backgroundColor: theme.branding.blue.primary,
    border: 'none',
  },
  container: {
    /* width of hero image */
    width: '679px',
    [theme.breakpoints.down(679)]: {
      width: '100%',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  fakeH5: {
    fontSize: '21px',
    lineHeight: '32px',
  },
  grayText: {
    color: theme.branding.gray[600],
  },
  groupContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: '12px',
  },
  header: {
    color: theme.branding.blue.primary,
  },
  headerArrow: {
    height: '24px',
    width: '24px',
  },
  helpContainer: {
    padding: '32px 24px',
  },
  helpImage: {
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  launchContainer: {
    padding: '16px 24px',
  },
  launchDialogCloseButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  overview: {
    padding: '24px 0 24px 24px',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '8px',
    backgroundColor: theme.branding.blue.primary,
    borderRadius: '0 100px 100px 0',
  },
  progressContainer: {
    position: 'relative',
    width: '100%',
    height: '8px',
    backgroundColor: theme.branding.gray[200],
  },
  progressText: {
    color: theme.branding.blue.primary,
  },
  section: {
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[300]}`
  },
  stepsContainer: {
    padding: '0 24px 24px',
  },
  stepContainer: {
    padding: '16px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepBorder: {
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  stripeStatusPill: {
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '8px',
    },
  },
}));

interface Step {
  header: string;
  description: string;
  includePill: boolean;
  completed: boolean;
  ctaHandler: () => void;
}

const PrelaunchSetup: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop, me, stripeStatus, } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
    stripeStatus: state.shops.stripe?.status,
  }));

  const [showLaunched, setShowLaunched] = useState(false);
  const [localStripeStatus, setLocalStripeStatus] = useState(stripeStatus);
  const [steps, setSteps] = useState<Step[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromStripe = urlParams.get('fromStripe');

  const trackChecklistClick = (ctaName: string) => {
    trackEvent('Setup Checklist Click', { description: ctaName });
  };

  const handleViewShop = () => {
    trackEvent('Open Shop Button Clicked', createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}?${toQueryString(queryParams)}`, '_blank');
  };

  const handleHelp = () => {
    trackEvent('Help Center Clicked');
    window.open('https://castiron.helpscoutdocs.com/', '_blank');
  };

  const handleLaunchButton = async () => {
    await shopRepository.updateProps(shop.id, {
      checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.GoLive),
      status: 'active',
    });
    setShowLaunched(true);
  };

  /* update shop in redux after dialog is closed */
  const launchDialogClosed = () => {
    dispatch(getShopAction(shop.id));
  };

  const stripeStatusPill = (): ReactNode => {
    let stripeStatusColor: ChipColorScheme;
    switch (localStripeStatus) {
      case 'INCOMPLETE':
        stripeStatusColor = 'yellow';
        break;
      case 'PROCESSING':
        stripeStatusColor = 'gray';
        break;
      case 'ERROR':
        stripeStatusColor = 'red';
        break;
    }
    return (
      stripeStatusColor && (
        <Chip colorScheme={stripeStatusColor} className={classes.stripeStatusPill}>
          {_.capitalize(localStripeStatus)}
        </Chip>
      )
    );
  };

  const completedStepCount = steps.filter(step => step.completed).length;
  const allStepsCompleted = steps.every(step => step.completed);

  useEffect(() => {
    dispatch(getShopAction(shop.id));
  }, []);

  useEffect(() => {
    if (fromStripe === 'true' && stripeStatus === 'INCOMPLETE') {
      setLocalStripeStatus('PROCESSING');
    } else {
      setLocalStripeStatus(stripeStatus);
    }
  }, [stripeStatus]);

  /* need to make sure these update appropriately when shop is upated, so put it in a useEffect */
  useEffect(() => {
    setSteps([
      {
        header: 'Add a Product or Order Form',
        description: 'Let your customers know what you offer.',
        includePill: false,
        completed: shop?.checklistCompletions?.includes(ChecklistValues.ProductAdded),
        ctaHandler: () => {
          trackChecklistClick('Add a Product');
          history.push('/products');
        },
      },
      {
        header: 'Brand Your Shop',
        description: 'Add your brand assets and tailor your shop to your business.',
        includePill: false,
        completed: shop?.checklistCompletions?.includes(ChecklistValues.Brand),
        ctaHandler: async () => {
          trackChecklistClick('Brand Your Shop');
          shop.addToChecklist(ChecklistValues.Brand);
          dispatch(getShopAction(shop.id));
          history.push('/store/appearance');
        },
      },
      {
        header: 'Customize Your Shop',
        description: 'Let your customers know what makes your business unique!',
        includePill: false,
        completed: shop?.checklistCompletions?.includes(ChecklistValues.Customize),
        ctaHandler: async () => {
          trackChecklistClick('Customize Your Shop');
          shop.addToChecklist(ChecklistValues.Customize);
          dispatch(getShopAction(shop.id));
          history.push('/store/business-details');
        },
      },
      {
        header: 'Add a Fulfillment Option',
        description: 'Help your customers see how they can receive their orders.',
        includePill: false,
        completed: shop?.checklistCompletions?.includes(ChecklistValues.FulfillmentAdded),
        ctaHandler: () => {
          trackChecklistClick('Add a Fulfillment Option');
          history.push('/store/fulfillment');
        },
      },
      {
        header: 'Connect to Get Paid',
        description: 'We partner with Stripe to securely collect payments and transfer the funds to you.',
        includePill: STRIPE_DISPLAY_STATUSES.includes(localStripeStatus),
        completed: shop?.checklistCompletions?.includes(ChecklistValues.StripeConnection),
        ctaHandler: () => {
          trackChecklistClick('Connect to Get Paid');
          history.push('/store/payments?fromChecklist=true');
        },
      },
    ]);
  }, [shop]);

  return (
    <>
      <Grid container justify='center'>
        <Grid item>
          <Box className={classes.container}>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <Box className={classes.groupContainer}>
                  <Grid container direction='column'>
                    <Grid item>
                      <img src="/assets/img/home/prelaunch/hero.png" style={{ width: '100%' }} />
                    </Grid>
                    <Grid item className={clsx([classes.overview, classes.section])}>
                      <Grid container direction='column'>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Grid container direction='column'>
                                <Grid item>
                                  <Typography variant='placeholder2' className={classes.progressText}>
                                    {completedStepCount}/{steps.length} steps complete
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <Typography component='span' variant='h5' className={classes.fakeH5}>📒 My Setup Guide</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant='body2' className={classes.grayText}>
                            Castiron is a food business management platform that handles all of your business needs including eCommerce,
                            custom quotes and invoicing, payment processing, calendar, marketing and more. Get started in five simple steps!
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.section}>
                      <Box className={classes.progressContainer}>
                        <div className={classes.progressBar} style={{ width: `${(completedStepCount / steps.length) * 100}%` }} />
                      </Box>
                    </Grid>
                    <Grid item className={clsx([classes.stepsContainer, classes.section])}>
                      <Grid container direction='column'>
                        {
                          steps.map((step, index) => (
                            <Grid
                              item
                              className={clsx([classes.stepContainer, classes.section, index !== steps.length - 1 && classes.stepBorder])}
                              onClick={step.ctaHandler}
                            >
                              <Grid container>
                                <Grid item>
                                  <Box className={clsx([classes.checkContainer, step.completed && classes.checkFilled])}>
                                    {step.completed && <Check className={classes.check} />}
                                  </Box>
                                </Grid>
                                <Grid item xs={10} sm={11}>
                                  <Typography variant='button' component='span' className={classes.header} style={{ marginRight: '4px' }}>
                                    {step.header}
                                  </Typography>
                                  <ArrowForward className={clsx([classes.header, classes.headerArrow])} />
                                  <Typography variant='body2' className={classes.grayText}>{step.description}</Typography>
                                  {step.includePill && stripeStatusPill()}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Grid>
                    <Grid item className={clsx([classes.launchContainer, classes.section])}>
                      <Grid container justify='flex-end' spacing={1}>
                        <Grid item>
                          <ButtonV2 variant="outlined" onClick={handleViewShop}>
                            <VisibilityOutlined />
                            &nbsp;&nbsp;Preview {isMobile ? '' : 'Shop'}
                          </ButtonV2>
                        </Grid>
                        <Grid item>
                          <ButtonV2 variant='contained' disabled={!allStepsCompleted} onClick={handleLaunchButton}>
                            Launch Shop
                          </ButtonV2>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box className={clsx([classes.helpContainer, classes.groupContainer])}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <img src='/assets/img/home/prelaunch/help-card.png' className={classes.helpImage} />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Typography variant='subtitle1'>Find the Answers You Need</Typography>
                          <Typography variant='body2' className={classes.grayText}>
                            No matter what you are looking to build, we can help you get there.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} style={{ cursor: 'pointer' }} onClick={handleHelp}>
                            <Grid item>
                              <Typography variant='button' className={classes.header}>
                                Visit Help Center
                              </Typography>
                            </Grid>
                            <Grid item>
                              <ArrowForward className={clsx([classes.header, classes.headerArrow])} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box >
        </Grid>
      </Grid>
      <Dialog open={showLaunched} onClose={launchDialogClosed}>
        <DialogTitle style={{ paddingTop: '65px' }}>
          <Close className={classes.launchDialogCloseButton} onClick={() => setShowLaunched(false)} />
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <StoreIcon style={{ color: theme.branding.blue.primary }} />
            </Grid>
            <Grid item>
              <Typography variant="h2">Congrats! Your Shop is Live!</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center">
            View your shop and copy the link to share it on Facebook, Twitter, Instagram, and anywhere you have an
            audience to being receiving sales.
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '40px 24px 32px' }}>
          <ButtonV2 variant="outlined" onClick={handleViewShop}>
            <VisibilityOutlined />
            &nbsp;&nbsp;View Shop
          </ButtonV2>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrelaunchSetup;