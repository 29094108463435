import { Grid, makeStyles, Paper, TextField, Theme, Typography, } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { backendStateToFrontendState, Customer, Transaction } from '@castiron/domain';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { getCustomerByIdAction, getCustomersAction } from '../../../../store/reducers/customers';
import CustomerDetailsBox from '../../../CustomerDetailsBox';
import { Autocomplete } from '@material-ui/lab';
import { Spinner } from '@castiron/components';
import { QuoteValues } from '../index';
import AddOrEditCustomer from './AddOrEditCustomer';
import ActionsMenu from '../../../ActionsMenu';

type Props = {
  transaction: Transaction;
  setQuoteFooter?: (dirty: boolean) => void;
};

export interface CustomerProps {
  existingCustomers: string;
  email: string;
  id: string;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  addCustomerButton: {
    cursor: 'pointer',
    paddingTop: 16,
    width: '100%',
    '& span': {
      color: '#fff',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '1em',
      padding: '0 5px',
    },
  },
  button: {
    minWidth: 80,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    padding: '16px 80px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px',
    },
  },
  createCustomerButton: {
    cursor: 'pointer',
    padding: 16,
    paddingBottom: 0,
  },
  formControl: {
    margin: '24px 0',
    width: '100%',
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    paddingBottom: 32,
    width: '100%',
  },
  itemBoxInner: {
    padding: '0 16px',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: 16,
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  customerBoxAdjustment: {
    border: 'none',
    marginBottom: 0,
    paddingBottom: 0,
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
}));

const QuoteCustomer: React.FC<Props> = (props: Props) => {
  const { transaction } = props;
  const { setFieldValue, isSubmitting } = useFormikContext<QuoteValues>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [addCustomerOpen, setAddCustomerOpen] = useState(false);
  const [fullCustomerList, setFullCustomerList] = useState<CustomerProps[]>([]);
  const [customer, setCustomer] = useState<Customer>(null);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [editCustomerOpen, setEditCustomerOpen] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const errorCode = query.get('error');

  const { customers, shop } = useAppSelector(state => ({
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));

  useEffect(() => {
    const getCustomer = async id => {
      const customer = await dispatch(getCustomerByIdAction(id));
      setCustomer(customer.payload as Customer);
    };

    if (transaction) {
      getCustomer(transaction.customer);
      const state = backendStateToFrontendState(transaction, 'quote');
      setIsEditable(state === 'new' || state === 'draft');
      setShowMenu(transaction.frontendState('quote') !== 'pending');
    }
  }, [transaction]);

  useEffect(() => {
    const trimmedCustomers = customers.map(customer => ({
      email: customer.email,
      id: customer.id,
      name: customer.firstName + ' ' + customer.lastName,
    }));

    const groupedCustomers = trimmedCustomers.map(customer => {
      return {
        existingCustomers: 'Existing customers',
        ...customer,
      }
    });

    setFullCustomerList(groupedCustomers);
  }, [customers]);

  useEffect(() => {
    const getCustomers = async () => {
      dispatch(getCustomersAction(shop.id));
    };

    if (shop?.id) {
      getCustomers();
    }
  }, [shop]);

  useEffect(() => {
    if (errorCode && errorCode === 'missingEmail') {
      setFieldValue('customer.email', '');
    }
  }, [errorCode]);

  const setCustomerAndFields = (custProps: CustomerProps): void => {
    const setCustomerFields = (customer?: Customer) => {
      const customerFields = {
        id: customer?.id || '',
        email: customer?.email || '',
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        mobileNumber: customer?.mobileNumber || '',
        notes: customer?.notes || '',
        subscribed: customer?.subscribed || '',
      };

      Object.keys(customerFields).forEach(key => {
        setFieldValue(`customer.${key}`, customerFields[key]);
      });
    };

    const foundCustomer = customers.find(c => c.id === custProps.id);
    /* should always be here, called from autocomplete populated by customers */
    setCustomer(foundCustomer);
    setCustomerFields(foundCustomer);
  };

  const deleteCustomer = () => {
    const initialDummyCustomerKey = '--Initial--';

    setCustomer(null);
    setAddCustomerOpen(false);
    setEditCustomerOpen(false);
    setFieldValue('customer', {
      shopId: shop.id,
      email: initialDummyCustomerKey,
      subscribed: false,
    });
  };

  const CreateCustomerButton = ({ children, ...other }) => (
    <Paper {...other}>
      <Typography
        className={classes.createCustomerButton}
        onMouseDown={() => {
          setEditCustomerOpen(true)
        }}
      >
        Create new customer
      </Typography>
      {children}
    </Paper>
  );

  return (
    <Grid container>
      {customer?.email ? (
        <Grid className={`${classes.itemBox} ${classes.customerBoxAdjustment}`}>
          <Grid style={{ position: 'relative' }} item xs={12}>
            <Spinner show={isSubmitting} size={'fullscreen'} />
            {showMenu && <Grid className={classes.menuIcon}>
              <ActionsMenu onDelete={deleteCustomer} onEdit={(): void => setEditCustomerOpen(!editCustomerOpen)}
                type="quotes-customer" />
            </Grid>}
            {editCustomerOpen ? (
              // Editing customer
              <AddOrEditCustomer onDiscard={(): void => setEditCustomerOpen(!editCustomerOpen)} />
            ) : (
              <CustomerDetailsBox {...{ customer }} />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {editCustomerOpen ? (
            // Adding customer
            <AddOrEditCustomer newCustomer onDiscard={(): void => setEditCustomerOpen(!editCustomerOpen)} />
          ) : (
            <Grid className={classes.itemBox}>
              <Grid container className={classes.itemHeader} alignItems="center">
                <Typography className={classes.title}>Customer</Typography>
              </Grid>
              <Grid className={classes.itemBoxInner} container justify="space-between" direction="column">
                {!addCustomerOpen ? (
                  <Typography className={classes.addCustomerButton} onClick={() => setAddCustomerOpen(true)}>
                    <span>+</span> Add customer
                  </Typography>
                ) : (
                  <Autocomplete
                    id="select-customer"
                    options={fullCustomerList}
                    groupBy={option => option.existingCustomers}
                    autoHighlight
                    getOptionLabel={option => `${option.name} (${option.email})`}
                    renderInput={params => <TextField {...params} label="Choose a customer" />}
                    onChange={(event, custValue) => {
                      /* no idea why it can be a string, but avoiding that option */
                      if (typeof custValue === 'string') { return; }
                      setCustomerAndFields(custValue);
                    }}
                    PaperComponent={CreateCustomerButton}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default QuoteCustomer;
