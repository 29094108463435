import React, { ReactNode, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Button, Card, Typography } from '@castiron/components';
import { PreviewIcon, ArticleIcon, CookieOutlinedIcon, MegaphoneOutlinedIcon } from '@castiron/components/src/Icons';
import { useTracking } from '@castiron/utils';

type Icon = 'preview' | 'article' | 'cookie' | 'megaphone';

export interface TipsForYou {
  icon: Icon;
  title: string;
  description: string;
  ctaAction: () => void;
}

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
  ctaAction?: () => void;
  ctaText?: string;
  tipsForYou: TipsForYou[];
  page: string;
}

const renderIcon = (icon: Icon) => {
  switch (icon) {
    case 'preview':
      return <PreviewIcon />;
    case 'article':
      return <ArticleIcon />;
    case 'cookie':
      return <CookieOutlinedIcon />;
    case 'megaphone':
      return <MegaphoneOutlinedIcon />;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonContainer: {
    paddingTop: 24,
  },
  container: {
    maxWidth: 505,
    textAlign: 'center',
    padding: '40px 0px',
  },
  divider: {
    width: 80,
    marginTop: 32,
    marginBottom: 32,
  },
  grayText: {
    color: theme.branding.gray[600],
    textAlign: 'left',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  iconBlue: {
    minWidth: 48,
    maxWidth: 48,
    height: 48,
    color: theme.branding.blue.primary,
    background: theme.branding.blue.light,
    borderRadius: 12,
    margin: '0px 16px',
  },
  iconGreen: {
    minWidth: 48,
    maxWidth: 48,
    height: 48,
    color: theme.branding.green.primary,
    background: theme.branding.green.light,
    borderRadius: 12,
    margin: '0px 16px',
  },
  iconContainer: {
    paddingBottom: 32,
  },
  itemContainer: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '10px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemContainerLast: {
    padding: '10px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tipsContainer: {
    padding: 8,
  },
}));

const EmptyPage: React.FC<Props> = (props: Props) => {
  const { icon, title, description, ctaAction, ctaText, tipsForYou, page } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{ padding: 0 }}>
      <Grid container item className={classes.container}>
        <Grid item xs={12} className={classes.iconContainer}>
          {icon}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.header}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
        {ctaAction && ctaText && (
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant="contained" onClick={ctaAction}>
              {ctaText}
            </Button>
          </Grid>
        )}
        <hr className={classes.divider} />
        <Grid item xs={12} className={classes.tipsContainer}>
          <Card title="Tips For You ⭐" removeChildPadding={true}>
            {tipsForYou.map((tip, index) => (
              <Grid
                key={`tips-resources-${index}`}
                container
                direction="row"
                wrap="nowrap"
                className={index !== tipsForYou.length - 1 ? classes.itemContainer : classes.itemContainerLast}
                onClick={() => {
                  tip.ctaAction();
                  trackEvent('Clicked Empty State Content', {
                    title: tip.title,
                    page: page,
                  });
                }}
              >
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="center"
                  className={tip.icon === 'article' || tip.icon === 'megaphone' ? classes.iconGreen : classes.iconBlue}
                >
                  {renderIcon(tip.icon)}
                </Grid>
                <Grid container item direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item>
                    <Button variant="text" endIcon={<ArrowForward />} className={classes.button}>
                      {tip.title}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.grayText}>
                      {tip.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmptyPage;
