import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface InputLabelProps {
  label: ReactNode;
  secondaryLabel?: ReactNode;
  required?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  required: {
    color: theme.branding.red.primary,
  },
  secondaryLabel: {
    color: theme.branding.gray[700],
  },
}));

const InputLabel: React.FC<InputLabelProps> = (props: InputLabelProps) => {
  const { label, secondaryLabel, required } = props;
  const classes = useStyles();

  return (
    <Grid container direction='column'>
      <Grid item>
        {typeof label === 'string' ? (
          <Typography variant='subtitle2'>
            {label}
            {
              required &&
              <span className={classes.required}>&nbsp;*</span>
            }
          </Typography>
        ) : (
          label
        )}
      </Grid>
      {
        secondaryLabel &&
        <Grid item>
          {typeof secondaryLabel === 'string' ? (
            <Typography variant='body2' className={classes.secondaryLabel}>{secondaryLabel}</Typography>
          ) : (
            secondaryLabel
          )}
        </Grid>
      }
    </Grid>
  );
};

export default InputLabel;
