import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addExperimentAction } from "./reducer";

export interface ExperimentConfig {
  id: string;
  name: string;
  variants: string[];
}

interface Props {
  experiments: ExperimentConfig[];
  loadTimeout: number;
  loading: ReactNode;
  children: ReactNode;
}

const ExperimentConfiguration: React.FC<Props> = (props: Props) => {

  const { experiments, loadTimeout, loading, children } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  const [expComplete, setExpComplete] = useState<number>(0);

  const dispatch = useDispatch();

  console.debug('Experiment Config', experiments);

  // Note, ripped most of this out of useGoogleOptimize
  useEffect(
    () => {
      if (experiments?.length > 0) {
        // Sets a timeout
        let optimizeTimedOut: ReturnType<typeof setTimeout>;

        if (loadTimeout !== Infinity) {
          optimizeTimedOut = setTimeout(() => {
            console.error('Optimize Timeout');

            // Clears the callback just in case this was a network timeout
            removeCallback();
            // Defaults to the 'Original'
            Promise.all(experiments.map(ex => dispatch(addExperimentAction({
              id: ex.id,
              name: ex.name,
              activeVariant: {
                index: 0,
                name: ex.variants[0]
              }
            })))).then(() => setIsLoading(false));
          }, loadTimeout);
          setTimerId(optimizeTimedOut);
        }
        // Sets the variant returned by Optimize and clears the timeout check
        const callback = (value: number, experimentId: string) => {
          try {
            console.debug(`Value: [${ value }], Experiment Id: [${ experimentId }]`);
            const exp = experiments.find(ex => ex.id == experimentId);
            if (exp) {
              dispatch(addExperimentAction({
                id: experimentId,
                name: exp?.name,
                activeVariant: {
                  index: value,
                  name: exp.variants[value]
                }
              }));
              setExpComplete(c => c + 1);
            }
          } catch (err) {
            setExpComplete(c => c + 1);
            console.error('Error', err);
          }
        }
        // Documented here:
        // https://support.google.com/optimize/answer/9059383?hl=en
        // @ts-ignore
        gtag('event', 'optimize.callback', {
          callback,
        });

        // Cleans up the optimize callback if the request times out
        const removeCallback = () => {
          optimizeTimedOut && clearTimeout(optimizeTimedOut);
          optimizeTimedOut = undefined;
          // @ts-ignore
          gtag('event', 'optimize.callback', {
            callback,
            remove: true,
          })
        }
        // Unregisters the event when the parent is unmounted or the experiment
        // id is changed
        return removeCallback
      } else {
        setIsLoading(false);
      }
    },
    // Only update if the experiment ID changes
    [experiments, loadTimeout]
  );

  useEffect(() => {
    console.debug(`Exp Complete: [${expComplete}]`);
      if (expComplete >= experiments.length) {
        setIsLoading(false);
        timerId && clearTimeout(timerId);
      }
  },[expComplete, timerId]);

  console.debug(`Is Loading: [${isLoading}]`)

  return <>
    {isLoading ? loading : children}
  </>;
}

// More ripped from useGoogleOptimize
function gtag() {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'function') {
      // eslint-disable-next-line prefer-rest-params
      window.gtag.apply(null, arguments as any)
    } else if (Array.isArray(window.dataLayer)) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    } else {
      if (
        typeof process !== 'undefined' &&
        process.env.NODE_ENV !== 'production' &&
        !didWarn
      ) {
        didWarn = true
        console.warn(
          '[@react-hook/google-optimize] Google Tag Manager was not found on your site. Neither "gtag()" or "dataLayer[]" could be located on the "window". If you are not using Google Tag Manager in dev you can ignore this warning. Otherwise, see the Google Tag Manager dev guide for examples: https://developers.google.com/tag-manager/devguide'
        )
      }
    }
  }
}

let didWarn = false

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export default ExperimentConfiguration;
