import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

type Props = {
  notes: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: 14,
    lineHeight: '24px',
  },
  notesTitle: {
    fontSize: '16px',
    fontWeight: 700,
    marginBottom: '5px',
  },
  notesBox: {
    background: theme.branding.gray[200],
    borderRadius: '10px',
    padding: '25px 20px 25px 20px',
    boxSizing: 'border-box',
  },
}));

const CustomerNotes: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.notesBox}>
      <Typography variant="subtitle1" className={classes.notesTitle}>
        Order Notes
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {props.notes}
      </Typography>
    </Box>
  );
};

export default CustomerNotes;
