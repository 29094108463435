import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import CircleButton from './CircleButton';

type Props = {
  containerClass?: any;
  onClick?: any;
  product?: any;
  value: number;
  onDecreaseClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onIncreaseClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addIcon: {
    color: '#1B41AD',
  },
}));

const AmountButtons: React.FC<Props> = (props: Props) => {
  const { containerClass, onDecreaseClick, onIncreaseClick, value } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.container} ${containerClass}`}>
      <CircleButton onClick={onDecreaseClick} icon={Remove} />
      <Typography>{value}</Typography>
      <CircleButton iconClassName={classes.addIcon} onClick={onIncreaseClick} icon={Add} />
    </Box>
  );
};

export default AmountButtons;
