import React, { ReactNode } from 'react';
import { FormHelperText, Grid, makeStyles, Theme } from '@material-ui/core';
import { Field, FormikErrors } from 'formik';
import InputLabel from '../../InputLabel';
import { AttachMoney } from '@material-ui/icons';
import { isClassExpression } from 'typescript';
import clsx from "clsx";

interface Props {
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  hideArrows?: boolean;
  inputClassName?: string;
  label?: ReactNode;
  max?: number;
  name?: string;  
  onChange?: (event: any) => void;
  required?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  attachMoneyIconContainer: {
    width: 24,
    height: 24,
  },
  containerClass: {
    marginBottom: 4,
  },
  hideArrows: {
    '& > input::-webkit-outer-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input::-webkit-inner-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input[type="number"]': {
      appearance: 'textfield',
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,

      '&:hover::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
      },
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkitinner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
  input: {
    width: '100%',
    height: '100%',
    textAlign: 'right',
    outline: 'none',
    border: 'none',
    fontSize: 16,
    fontWeight: 400,
  },
  inputContainer: {
    height: 56,
    width: '100%',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
    padding: 16,
  },
}));

const PriceInput: React.FC<Props> = (props: Props) => {
  const { 
    error,
    hideArrows,
    inputClassName,
    label,
    max,
    name,
    onChange,
    required,
  } = props;
  const classes = useStyles();

  const clearZero = event => {
    if (event.target.value === '0') {
      event.target.value = '';
    }
  };

  return (
    <Grid container>
      {
        label &&
        <Grid item xs={12}>
          <InputLabel
            containerClass={classes.containerClass}
            primaryLabel={label} 
            required={required}
          />
        </Grid>
      }
      <Grid container item xs={12} className={clsx([classes.inputContainer, inputClassName])}>
        <Grid item className={classes.attachMoneyIconContainer}>
          <AttachMoney />
        </Grid>
        <Grid item xs className={`${hideArrows && classes.hideArrows}`}>
          <Field
            as={"input"}
            className={classes.input}
            name={name}
            min="0"
            max={max}
            {...(onChange ? { onChange } : {})}
            onClick={clearZero}
            placeholder="0"
            step="0.01"
            type="number"
          >
          </Field>
        </Grid>
      </Grid>
      {
        error &&
        <Grid item xs={12}>
          <FormHelperText error={true}>{error}</FormHelperText>
        </Grid>
      }
    </Grid >
  );
}

export default PriceInput;
