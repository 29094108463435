import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, DialogActions, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import moment from 'moment';
import { Typography, Button } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import ModalWrapper from '../../../RootModal/ModalWrapper';
import { closeModal } from '../../../../store/reducers/modalConductor';

export type Props = {
  open: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "12px 16px"
  },
  closeButton: {
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: 13
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    [theme.breakpoints.down('sm')]: {
      right: 24,
      top: 20
    },
  },
  content: {
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px'
    }
  },
  featureTitle: {
    color: theme.branding.blue.primary
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  info: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 14px)'
    }
  },
  mobileWidth: {
    width: 'calc(100% - 14px)'
  },
}));

const CancelSuccessModal: React.FC<Props> = (props: Props) => {
  const { open } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [endDate, setEndDate] = useState<any>();

  const { account } = useAppSelector(state => ({
    account: state.shops.account
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (account?.isInTrial()) {
      const trialBillingDate = moment.unix(account?.subscription?.trialEndDate).format('LL');
      setEndDate(trialBillingDate);
    } else {
      const nextBillingDate = moment.unix(account?.subscription?.nextPaymentDate).format('LL');
      setEndDate(nextBillingDate);
    }
  }, [account]);

  return (
    <ModalWrapper fullScreen={isMobile} size="sm" show={open}>
      <DialogTitle className={classes.header}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Typography variant="h3">Your Subscription Has Been Canceled</Typography>
        </Grid>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {account?.hasLegacySubscription() && account?.isInTrial() ?
          <Grid>
            <Typography variant="body1" style={{ marginBottom: 16 }} className={classes.mobileWidth}>You will continue to have access to your Pro features until the end of your trial, which is <span style={{ fontWeight: 700 }}>{endDate}</span>.</Typography>
          </Grid>
          :
          <Grid>
            <Typography variant="body1" style={{ marginBottom: 24 }} className={classes.mobileWidth}>We are sorry to see you go! Here are a few things you need to know now that your subscription has been canceled:</Typography>
            <Grid container>
              <Grid direction="column" justify="center" className={classes.info} container item>
                <Typography variant="subtitle1" className={classes.featureTitle}>Access &#9989;</Typography>
                <Typography variant="body1">You will continue to have access to your account until the end of your {account?.isInTrial() ? 'trial' : 'billing period'}, which is <span style={{ fontWeight: 700 }}>{endDate}</span>.</Typography>
              </Grid>
              <Grid direction="column" justify="center" className={classes.info} container item>
                <Typography variant="subtitle1" className={classes.featureTitle}>Open Orders &#128230;</Typography>
                <Typography variant="body1">Once your current billing cycle has ended, you will no longer be able to access your account and your online shop will become hidden to customers. Please make sure you have closed out any open orders before this date.</Typography>
              </Grid>
              <Grid direction="column" justify="center" className={classes.info} container item>
                <Typography variant="subtitle1" className={classes.featureTitle}>Payouts &#128181;</Typography>
                <Typography variant="body1">You will continue to have access to your Stripe account and will be paid for any outstanding payments. You can log in directly to your Stripe account on their website at <a href="https://www.stripe.com" target="_blank">www.stripe.com</a>.</Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">If you have any questions, please don’t hesitate to reach out to us at <a href="mailto:help@castiron.me">help@castiron.me</a></Typography>
          </Grid>
        }
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" onClick={handleClose} className={classes.closeButton}>Close</Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default CancelSuccessModal;
