import { Coupon } from '@castiron/domain';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { couponRepository } from '../../domain';
import { handleReducerError } from './errorHandler';

export interface CouponsState {
  coupons: Coupon[];
  loading: boolean;
}

const initialState: CouponsState = {
  coupons: [],
  loading: false,
};

const getCoupons = async (shopId: string) => {
  const response = await couponRepository.findByShopId(shopId);
  console.log('response from getCoupons: ', response);
  return response;
};

export const getCouponAction = createAsyncThunk('customers/storeCustomers', getCoupons);

const customersSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getCouponAction.pending, state => {
        state.loading = true;
      })
      .addCase(getCouponAction.fulfilled, (state, action) => {
        state.coupons = [...action.payload];
        state.loading = false;
      })
      .addCase(getCouponAction.rejected, handleReducerError('Error Getting Coupon'));
  },
});

export default customersSlice.reducer;
