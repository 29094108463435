import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { nanoid } from '@reduxjs/toolkit';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowForward, Check } from '@material-ui/icons';
import _ from 'lodash';
import {
  Button,
  Chip,
  ChipColorScheme,
  CloseIcon,
  EyeIcon,
  LinkIcon,
  ProgressBar,
  RocketIcon,
  StoreIcon,
  Typography,
} from '@castiron/components';
import { ChecklistValues } from '@castiron/domain';
import { useConfig } from '@castiron/castiron-firebase';
import { toQueryString, useTracking } from '@castiron/utils';
import firebase from 'firebase/compat/app';
import { LayoutPageProps } from "../Layout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ViewShopButton from "../Layout/Header/ViewShopButton";
import AvatarMenu from "../Layout/AvatarMenu";
import { getShopAction } from "../../store/reducers/shops";
import { createOpenShopButtonClickedEvent } from "../../lib/events/commonEvents";
import { shopRepository } from "../../domain";
import { openModal } from "../../store/reducers/modalConductor";
import ResourceGuide from "../ResourceGuide";
import HelpCards from "../HelpCards";
import obModalDesktopScreen2 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen2.png';
import obModalDesktopScreen3 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen3.png';
import obModalDesktopScreen4 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen4.png';
import obModalMobileScreen2 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen2.png';
import obModalMobileScreen3 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen3.png';
import obModalMobileScreen4 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen4.png';

interface Step {
  header: (isCompleted: boolean) => ReactNode;
  description: string;
  includePill: boolean;
  bulletPoints?: string[];
  link?: string;
  linkText?: string;
  completed: boolean;
  mustBeCompleted?: boolean;
  ctaHandler: () => void;
}

const STRIPE_DISPLAY_STATUSES = ['INCOMPLETE', 'PROCESSING', 'ERROR'];

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: theme.branding.blue.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bulletPoints: {
    margin: 0,
    paddingInlineStart: 15,
  },
  checkIcon: {
    height: '18px',
    width: '18px',
    [theme.breakpoints.down('sm')]: {
      height: '14px',
      width: '14px',
    },
  },
  completedText: {
    color: theme.branding.gray[600],
    textDecorationLine: 'line-through',
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px 0',
    },
  },
  finalStepContentXSmall: {
    [theme.breakpoints.only('xs')]: {
      flexBasis: '90% !important',
      maxWidth: '90% !important',
    },
  },
  instructionBodyText: {
    color: theme.branding.gray[600],
  },
  launchButtonMobile: {
    marginTop: '16px',
  },
  launchDialogCloseButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rocketIcon: {
    height: '24px',
    width: '24px',
    [theme.breakpoints.down('sm')]: {
      height: '18px',
      width: '18px',
    },
  },
  shareShopLinkButton: {
    padding: '11px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '11px 13px',
    },
  },
  shopLink: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 4,
    padding: '13px 16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  stepContainer: {
    position: 'relative',
    width: '100%',
    padding: '24px',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  stepContainerClickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepContainerFirst: {
    border: 'none',
  },
  stepContent: {
    display: 'inline-block',
    marginLeft: '64px',
    flexBasis: '55%',
    maxWidth: '55%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '44px',
    },
    [theme.breakpoints.only('md')]: {
      flexBasis: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.only('sm')]: {
      flexBasis: '75%',
      maxWidth: '75%',
    },
    [theme.breakpoints.only('xs')]: {
      flexBasis: '65%',
      maxWidth: '65%',
    },
  },
  stepCtaContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '24px',
    [theme.breakpoints.down('sm')]: {
      right: '16px',
    },
  },
  stepCtaIndexLiveContainer: {
    transform: 'none',
    top: '24px',
    [theme.breakpoints.down('sm')]: {
      top: '16px',
    },
  },
  stepIndex: {
    display: 'inline-block',
    height: '40px',
    width: '40px',
    marginRight: '24px',
    backgroundColor: theme.branding.blue.light,
    borderRadius: '8px',
    color: theme.branding.blue.primary,
    textAlign: 'center',
    padding: '7px 0',
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      width: '28px',
      padding: '0',
    },
  },
  stepIndexContainer: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      top: '16px',
      left: '16px',
    },
    [theme.breakpoints.up('md')]: {
      top: '50%',
      transform: 'translateY(-50%)',
      left: '24px',
    },
  },
  stepIndexText: {
    color: theme.branding.blue.primary,
    [theme.breakpoints.down('sm')]: {
      padding: '3px 0',
    },
  },
  stepsContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: `16px`,
  },
  stepLink: {
    color: theme.branding.blue.primary,
  },
  stripeStatusPill: {
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '8px',
    },
  },
  welcomeModalMediaAdjustments: {
    position: 'absolute',
    bottom: '88px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 22px',
    },
    [theme.breakpoints.down('xs')]: {
      right: 0, // Needed for Safari to position the image correctly
    },
  },
  positionAdjustment: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '88px',
    },
  },
  viewShopButton: {
    marginTop: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const SetupChecklist: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const config = useConfig();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { shop, account, stripeStatus, subscription, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    account: state.shops.account,
    stripeStatus: state.shops.stripe?.status,
    subscription: state.shops.account.subscription,
    me: state.users.me,
  }));

  const subscriptionsEnabled = config.featureFlag('feature_subscriptions', shop);

  const [showLaunched, setShowLaunched] = useState(false);
  const [localStripeStatus, setLocalStripeStatus] = useState(stripeStatus);
  const [showShareShopLink, setShowShareShopLink] = useState(false);
  const [islinkCopied, setIsLinkCopied] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const fromStripe = urlParams.get('fromStripe');
  const shopLink = `${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`;

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const getIframeSizes = () => {
    if (isMobile) {
      return {
        width: '343px',
        height: '193px',
      };
    } else if (!isMobile) {
      return {
        width: '504px',
        height: '283px',
      };
    }
  };

  useEffect(() => {
    const welcomeModalContent = [
      {
        header: `Welcome to Castiron! 🎉`,
        body: 'If you have a minute, we have a few pointers to help you start your journey!',
        media: (
          <iframe
            width={getIframeSizes().width}
            height={getIframeSizes().height}
            src="https://www.youtube.com/embed/o3k3V3QkdY4?rel=0"
            title="Welcome to Castiron"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ),
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen2} alt="Welcome Screen 2" />
        ) : (
          <img src={obModalDesktopScreen2} alt="Welcome Screen 2" />
        ),
        mediaClassName: classes.welcomeModalMediaAdjustments,
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen3} alt="Welcome Screen 3" />
        ) : (
          <img src={obModalDesktopScreen3} alt="Welcome Screen 3" />
        ),
        mediaClassName: classes.welcomeModalMediaAdjustments,
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen4} alt="Welcome Screen 4" />
        ) : (
          <img src={obModalDesktopScreen4} alt="Welcome Screen 4" />
        ),
        mediaClassName: classes.positionAdjustment,
      },
    ];

    if (shop?.status === 'prelaunch' && account) {
      if (!account.onboardingModals || (account.onboardingModals && !account.onboardingModals?.welcomeModalShown)) {
        dispatch(
          openModal({
            modalType: 'ONBOARDING_MODAL',
            modalProps: {
              show: true,
              stepContent: welcomeModalContent,
              onboardingModalType: 'welcomeModal',
            },
          }),
        );
      }
    }
  }, [account]);

  useEffect(() => {
    setPageTitle(isMobile ? 'Checklist' : `Checklist For ${shop.businessName}`);
    setHeaderCTAs([<ViewShopButton />, isMobile && <AvatarMenu />]);
    setFooterCTAs([]);
    window.Beacon('destroy');

    return () => {
      setHeaderCTAs([]);
      setPageTitle('');

      window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_KEY);
      window.Beacon('config', { hideFABOnMobile: true });
    }
  }, [isMobile]);

  useEffect(() => {
    dispatch(getShopAction(me?.uid));
  }, [me]);

  useEffect(() => {
    if (fromStripe === 'true' && stripeStatus === 'INCOMPLETE') {
      setLocalStripeStatus('PROCESSING');
    } else {
      setLocalStripeStatus(stripeStatus);
    }
  }, [stripeStatus]);

  if (!shop) return <></>;

  const handleViewShop = () => {
    trackEvent('Open Shop Button Clicked', createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}?${toQueryString(queryParams)}`, '_blank');
  };

  const trackChecklistClick = ctaName => {
    trackEvent('Setup Checklist Click', { description: ctaName });
  };

  const stripeStatusPill = (): ReactNode => {
    let stripeStatusColor: ChipColorScheme;
    switch (localStripeStatus) {
      case 'INCOMPLETE':
        stripeStatusColor = 'yellow';
        break;
      case 'PROCESSING':
        stripeStatusColor = 'gray';
        break;
      case 'ERROR':
        stripeStatusColor = 'red';
        break;
    }
    return (
      stripeStatusColor && (
        <Chip colorScheme={stripeStatusColor} className={classes.stripeStatusPill}>
          {_.capitalize(localStripeStatus)}
        </Chip>
      )
    );
  };

  const createHeader = (headerText: string, isCompleted: boolean, includeStripeStatusPill: boolean = false) => {
    return (
      <Typography variant="h4" className={!isLive && isCompleted && classes.completedText}>
        {headerText + ' '}
        {includeStripeStatusPill && stripeStatusPill()}
      </Typography>
    );
  };

  const handleAnnounceNewShop = async () => {
    if (!shop?.checklistCompletions?.includes(ChecklistValues.AnnounceNewShop)) {
      await shopRepository.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.AnnounceNewShop),
      });
      dispatch(getShopAction(shop.id));
    }
  };

  const resourceGuideProps = {
    id: 'checklist-video-guide',
    link: 'https://castiron.helpscoutdocs.com/article/94-getting-started-with-castiron',
    title: 'Your New Shop in 60 Seconds',
    text: 'Let us show you how to create a beautiful shop for you and your customers.',
    videoLength: '60 Sec',
    trackEventTitle: 'Setup Checklist Click',
  };

  const resourceGuideLiveProps = {
    id: 'checklist-live-guide',
    link: 'https://www.castiron.me/blog/where-to-share-your-online-store-link-so-you-can-make-sales',
    title: 'Where to Share Your Shop Link',
    text: 'Share your link in key places to make more money.',
    trackEventTitle: 'Setup Checklist Click',
  };

  const setupPlanStep: Step = subscriptionsEnabled
    ? {
      header: (isCompleted: boolean) => createHeader('Select a Plan', isCompleted),
      description: "Tell us how you'd prefer to be billed from Castiron.",
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.SelectPlan),
      mustBeCompleted: false,
      ctaHandler: async () => {
        trackChecklistClick('Select a Plan');
        shop?.addToChecklist(ChecklistValues.SelectPlan);
        dispatch(
          openModal({
            modalType: 'CHANGE_PLANS_MODAL',
            modalProps: {
              open: true,
            },
          }),
        );
        dispatch(getShopAction(shop.id));
      },
    }
    : null;

  const launchChecklistSteps: Step[] = [
    {
      header: (isCompleted: boolean) => createHeader('Customize Your Shop', isCompleted),
      description: 'Adding a profile picture, logo, and more to tailor your shop to you.',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.Customize),
      mustBeCompleted: true,
      ctaHandler: async () => {
        trackChecklistClick('Customize Your Shop');
        shop.addToChecklist(ChecklistValues.Customize);
        dispatch(getShopAction(shop.id));
        history.push('/store/appearance');
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Add a Product or Order Form', isCompleted),
      description: 'Tell your customers what you offer and what they can customize',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.ProductAdded),
      mustBeCompleted: true,
      ctaHandler: () => {
        trackChecklistClick('Add a Product');
        history.push('/products');
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Add a Fulfillment Option', isCompleted),
      description: 'Let your customers know when and how they can receive their orders.',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.FulfillmentAdded),
      mustBeCompleted: true,
      ctaHandler: () => {
        trackChecklistClick('Add a Fulfillment Option');
        history.push('/store/fulfillment');
      },
    },
    {
      header: (isCompleted: boolean) =>
        createHeader(
          'Connect to Get Paid',
          isCompleted,
          !isMobile && STRIPE_DISPLAY_STATUSES.includes(localStripeStatus),
        ),
      description: 'We partner with Stripe to securely and quickly process payments and pay you.',
      includePill: isMobile && STRIPE_DISPLAY_STATUSES.includes(localStripeStatus),
      completed: shop?.checklistCompletions?.includes(ChecklistValues.StripeConnection),
      mustBeCompleted: true,
      ctaHandler: () => {
        trackChecklistClick('Connect to Get Paid');
        history.push('/store/payments?fromChecklist=true');
      },
    },
    setupPlanStep,
  ].filter(step => !!step);

  const postLaunchCheckliststeps: Step[] = [
    setupPlanStep,
    {
      header: (isCompleted: boolean) =>
        createHeader('Update Your Social Profiles With Your Castiron Link', isCompleted),
      description: 'Let your customers know you are open for business!',
      link: 'https://www.castiron.me/blog/where-to-share-your-online-store-link-so-you-can-make-sales',
      linkText: '8 places to update your shop link',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.ShareShopLink),
      ctaHandler: () => {
        trackChecklistClick('Share Shop Link');
        setShowShareShopLink(true);
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Send a Marketing Email', isCompleted),
      description: 'Use our Email Templates to market your products to your email list and drive more orders!',
      linkText: 'Create an email',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.EmailMarketingSingleSend),
      ctaHandler: () => {
        trackChecklistClick('Create An Email');
        history.push('/marketing');
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Announce Your New Shop', isCompleted),
      description: 'Use our pre-made Canva template to let your customers know about your beautiful new shop!',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.AnnounceNewShop),
      ctaHandler: () => {
        trackChecklistClick('Announce Your New Shop');
        handleAnnounceNewShop();
        window.open(
          'https://www.canva.com/design/DAFPUdyKy5c/M-N-Dxvb82dP4hu509bHog/view?utm_content=DAFPUdyKy5c&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
          '_blank',
        );
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Build Your Photo Gallery', isCompleted),
      description: 'Add images from your current and past work to inspire your customers.',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.GalleryPhotoAdded),
      ctaHandler: () => {
        trackChecklistClick('Add Photos');
        history.push('/store/gallery');
      },
    },
    {
      header: (isCompleted: boolean) => createHeader('Join Our Facebook Community', isCompleted),
      description: 'Join a thriving community of other like-minded artisans using Castiron.',
      includePill: false,
      completed: shop?.checklistCompletions?.includes(ChecklistValues.JoinCommunity),
      ctaHandler: async () => {
        trackChecklistClick('Join Community');
        window.open('https://www.facebook.com/groups/thekitchencommunity', '_blank').focus();
        if (!shop?.checklistCompletions?.includes(ChecklistValues.JoinCommunity)) {
          await shopRepository.updateProps(shop.id, {
            checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.JoinCommunity),
          });
          dispatch(getShopAction(shop.id));
        }
      },
    },
  ].filter(step => !!step);

  const isLive = shop.status === 'active';
  const steps = isLive ? postLaunchCheckliststeps : launchChecklistSteps;
  const completedStepCount = steps.filter(step => step.completed).length;
  const allRequiredStepsCompleted = steps.every(step => !step.mustBeCompleted || step.completed);

  const handleLaunchButton = async () => {
    if (isLive) {
      handleViewShop();
    } else {
      await shopRepository.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.GoLive),
        status: 'active',
      });
      dispatch(getShopAction(shop.id));
      setShowLaunched(true);
    }
  };

  const handleCopyLink = async () => {
    navigator.clipboard.writeText(shopLink);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);
    if (!shop?.checklistCompletions?.includes(ChecklistValues.ShareShopLink)) {
      await shopRepository.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.ShareShopLink),
      });
      dispatch(getShopAction(shop.id));
    }
  };

  return (
    <>
      <Grid container direction="column" className={classes.contentContainer}>
        {!isLive && <ResourceGuide {...resourceGuideProps} />}
        {isLive && <ResourceGuide {...resourceGuideLiveProps} />}
        <Grid item container direction="column" className={classes.stepsContainer}>
          <Grid item container className={`${classes.stepContainer} ${classes.stepContainerFirst}`}>
            <Grid item container xs={isXsMobile ? 12 : 7}>
              <Grid item xs={12}>
                {isLive ? (
                  <Typography variant="h3">Your Shop Is Live! What Now?</Typography>
                ) : (
                  <>
                    <div>
                      <Typography variant="h2">Welcome to Castiron! 👋</Typography>
                    </div>
                    <div>
                      <Typography variant="body2" className={classes.instructionBodyText}>
                        Launch your shop in minutes with {subscriptionsEnabled ? 'five' : 'four'} simple steps.
                      </Typography>
                    </div>
                    {isXsMobile && (
                      <Grid container xs={12} justify="space-between" alignItems="center" style={{ marginTop: 5 }}>
                        <Grid xs={11}>
                          <ProgressBar percentage={completedStepCount / steps.length} fullwidth={true} />
                        </Grid>
                        <Grid xs={1} style={{ marginBottom: 2, paddingLeft: 16 }}>
                          <Typography variant="caption" style={{ color: theme.branding.gray[600] }}>
                            {completedStepCount}/{steps.length}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            {!isLive && !isXsMobile && (
              <Grid item container xs={5} direction="column" alignItems="flex-end">
                <Grid item>
                  <ProgressBar percentage={completedStepCount / steps.length} />
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ color: theme.branding.gray[600] }}>
                    {isLive
                      ? isMobile
                        ? ''
                        : `${steps.length} suggestions for you`
                      : `${completedStepCount}/${steps.length} steps complete`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {steps.map((step, index) => (
            <Grid
              item
              className={`${classes.stepContainer} ${classes.stepContainerClickable}`}
              key={`step${index}`}
              onClick={step.ctaHandler}
            >
              <Box className={`${classes.stepIndexContainer} ${classes.stepCtaIndexLiveContainer}`}>
                <Box className={classes.stepIndex}>
                  {step.completed ? (
                    <Check className={classes.checkIcon} />
                  ) : (
                    <Typography variant={isMobile ? 'h5' : 'h3'} className={classes.stepIndexText}>
                      {index + 1}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box className={classes.stepContent}>
                <Box>{step.header(step.completed)}</Box>
                {(!isMobile || !step.completed || isLive) && (
                  <Box>
                    <Typography variant="body2" className={!isLive && step.completed && classes.completedText}>
                      {step.description}
                    </Typography>
                    {step.link && (
                      <a href={step.link} target="_blank">
                        <Typography variant="body2" className={classes.stepLink}>
                          {step.linkText}
                        </Typography>
                      </a>
                    )}
                  </Box>
                )}
                {step.includePill && <Box>{stripeStatusPill()}</Box>}
                {step.bulletPoints && (
                  <ul className={classes.bulletPoints}>
                    {step.bulletPoints?.map(bulletPoint => (
                      <li>
                        <Typography variant="body2">{bulletPoint}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
              <Box
                className={`${classes.stepCtaContainer} ${!isMobile && isLive && classes.stepCtaIndexLiveContainer}`}
              >
                <ArrowForward className={classes.arrowIcon} />
              </Box>
            </Grid>
          ))}
          {!isLive && (
            <Grid item className={classes.stepContainer}>
              <Box className={classes.stepIndexContainer}>
                <Box className={classes.stepIndex}>
                  <RocketIcon className={classes.rocketIcon} />
                </Box>
              </Box>
              <Box className={`${classes.stepContent} ${classes.finalStepContentXSmall}`}>
                <Box>
                  <Typography variant="h4">Launch Your New Shop</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {allRequiredStepsCompleted
                      ? "You're ready to launch!  Tap the button to launch your new shop."
                      : 'Complete the steps above to publish your shop and start selling!'}
                  </Typography>
                </Box>
              </Box>
              {isMobile ? (
                <Box style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    className={classes.launchButtonMobile}
                    fullWidth={true}
                    disabled={!allRequiredStepsCompleted}
                    onClick={handleLaunchButton}
                  >
                    Launch Your Shop
                  </Button>
                </Box>
              ) : (
                <Box className={classes.stepCtaContainer}>
                  <Button variant="contained" disabled={!allRequiredStepsCompleted} onClick={handleLaunchButton}>
                    Launch Your Shop
                  </Button>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        <HelpCards />
      </Grid>
      <Dialog open={showLaunched}>
        <DialogTitle style={{ paddingTop: '65px' }}>
          <CloseIcon className={classes.launchDialogCloseButton} onClick={() => setShowLaunched(false)} />
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <StoreIcon style={{ color: theme.branding.blue.primary }} />
            </Grid>
            <Grid item>
              <Typography variant="h2">Congrats! Your Shop is Live!</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center">
            View your shop and copy the link to share it on Facebook, Twitter, Instagram, and anywhere you have an
            audience to being receiving sales.
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '40px 24px 32px' }}>
          <Button
            variant="outlined"
            className={classes.viewShopButton}
            onClick={handleViewShop}
            style={{ margin: '0 auto' }}
          >
            <EyeIcon />
            &nbsp;&nbsp;View My Shop
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showShareShopLink} onClose={() => setShowShareShopLink(false)}>
        <DialogTitle style={{ padding: '65px 0 0 0' }}>
          <CloseIcon className={classes.launchDialogCloseButton} onClick={() => setShowShareShopLink(false)} />
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <LinkIcon />
            </Grid>
            <Grid item>
              <Typography variant="h2" style={{ marginTop: 8 }}>
                Share Your Shop Link
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center">
            Share your shop link on Facebook, Twitter, Instagram, and anywhere else you have an audience to begin
            receiving sales.
          </Typography>
          <Typography variant="subtitle2" style={{ marginTop: '24px' }}>
            Shop Link
          </Typography>
          <Typography variant="body2" className={classes.shopLink}>
            {shopLink}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '24px' }}>
          <Button variant="outlined" className={classes.shareShopLinkButton} onClick={handleViewShop}>
            <EyeIcon />
            &nbsp;&nbsp;View My Shop
          </Button>
          <Button variant="contained" className={classes.shareShopLinkButton} onClick={handleCopyLink}>
            {islinkCopied ? 'Link Copied!' : 'Copy Link'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetupChecklist;
