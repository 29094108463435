import React, { ReactElement, useEffect, useState, useRef } from 'react';
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ChecklistValues } from '@castiron/domain';
import { getService } from '../../../firebase';
import { Formik, FormikProps, getIn } from 'formik';
import Fuse from 'fuse.js';
import * as yup from 'yup';
import _ from 'lodash';
import {
  Banner,
  Button,
  CollapsableCard,
  DeleteButton,
  ImageRadioInput,
  SaveButton,
  SearchInput,
  TextInput,
  Typography,
} from '@castiron/components';
import { BaseProduct, Customer } from '@castiron/domain';
import { productRepository, customerRepository } from '../../../domain';
import { useAppSelector } from '../../../hooks';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import EmailPreview from '../../../assets/img/email-preview.png';
import NoPhoto from '../../../assets/img/no-photo-thumbnail.png';
import EllipsisMenu, { EllipsisMenuOption } from '../../Layout/Header/EllipsisMenu';
import { DropDownOption } from '../../Dropdown';
import { useAppDispatch } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import RichTextInput from '../../RichTextEditor';
import Close from '@material-ui/icons/Close';
import { useHistory, useParams } from 'react-router-dom';
import GeneralModal from '../../RootModal/GeneralModal';
import { ModalType } from "../../RootModal/RootModal";

const sendProductAnnouncementService = getService('products', 'sendproductannouncementv2', { version: 2 });
const sendProductAnnouncementTestService = getService('products', 'sendproductannouncementtestv2', { version: 2 });

const useStyles = makeStyles((theme: Theme) => ({
  addContacts: {
    color: 'inherit',
    borderColor: theme.branding.red.dark,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
  banner: {
    maxWidth: '416px',
  },
  bodyTextContainer: {
    marginBottom: '45px !important',
    '& label': {
      fontFamily: 'Nunito Sans,sans-serif',
      marginTop: 24,
    },
    '& .ql-editor': {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderBottom: 'none',
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    '& .ql-toolbar': {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  bodyTextInput: {
    border: 'none',
    '& div': {
      border: 'none',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: 'calc(100% - 32px)',
      minWidth: 0,
      paddingTop: '16px',
    },
    width: '100%',
  },
  emptySearch: {
    width: '100%',
    padding: '40px 0px 150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorBox: {
    marginBottom: 24,
    padding: '16px 18px',
    backgroundColor: theme.branding.red.light,
    color: theme.branding.red.primary,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 12,
  },
  errorText: {
    color: 'inherit',
    margin: '1px 0px 0px 10px',
  },
  infoIcon: {
    color: theme.branding.gray[500],
    marginLeft: 6,
    height: 16,
    width: 16,
    display: 'flex',
    alignContent: 'center',
  },
  infoSend: {
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: theme.branding.blue.dark,
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  noArrow: {
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  noContactsBanner: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '24px 16px 0px',
    },
  },
  optionContainer: {
    marginBottom: 24,
  },
  previewImage: {
    minWidth: 0,
    maxWidth: 'fit-content',
    minHeight: 0,
    maxHeight: 'fit-content',
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  previewModalContainer: {
    background: '#F4F4F5', // match preview image background
  },
  previewModalHeader: {
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  searchIcon: {
    height: 36,
    width: 36,
    marginBottom: 16,
    color: theme.branding.gray[600],
  },
  sendText: {
    color: theme.branding.blue.primary,
    cursor: 'pointer',
  },
  sendTestEmailText: {
    border: `1px solid ${theme.branding.gray[300]}`,
    padding: '12px 16px',
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.gray[100],
    borderRadius: 12,
    position: 'fixed',
    bottom: 24,
    [theme.breakpoints.down('xs')]: {
      bottom: 124,
      border: 'none',
      boxShadow: `8px 8px 24px ${theme.branding.gray[500]}`,
      color: theme.branding.gray[100],
      backgroundColor: theme.branding.gray[800],
    },
  },
  titleCutoff: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  select: {
    width: '100%',
    borderRadius: 12,
    marginTop: 4,
    cursor: 'not-allowed',
  },
  sendOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
}));

interface Params {
  id?: string;
}

const NewProductForm: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setLongTitle, setOptFooterFormat, setHeaderCTAs, setFooterCTAs } = props;

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams<Params>();
  const formikRef = useRef<FormikProps<any>>();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const fuseOptions = {
    keys: ['title'],
    threshold: 0.5,
    useExtendedSearch: true,
  };

  // matches empty body text <p><br></p> or <p><br></p><p><br></p><p><br></p>... etc if multiple empty lines
  const emptyBodyTextRegex = /^(<p>(<br>){0,}<\/p>)+$/;

  const [submitting, setSubmitting] = useState(false);
  const [sendingTest, setSendingTest] = useState(false);
  const [testEmailSent, setTestEmailSent] = useState(false);

  const [productExpanded, setProductExpanded] = useState(true);
  const [contentExpanded, setContentExpanded] = useState(false);
  const [sendDetailsExpanded, setSendDetailsExpanded] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showPreSendModal, setShowPreSendModal] = useState(false);

  const [activeProducts, setActiveProducts] = useState<BaseProduct[]>([]);
  const [formattedProducts, setFormattedProducts] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [chosenProduct, setChosenProduct] = useState<BaseProduct>();
  const [productError, setProductError] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [contentError, setContentError] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);

  const formatProducts = (productArray: BaseProduct[]) => {
    const radioOptions = productArray.map(product => {
      return {
        label: product.title,
        url: product.imageObj?.mediumVersion?.downloadUrl || product.imageObj?.downloadUrl || NoPhoto,
        value: product,
      };
    });

    setFormattedProducts(radioOptions);
  };

  const getProducts = async () => {
    if (shop && shop.id) {
      const products = await productRepository.findActive(shop.id);
      const orderedProducts = products
        .map(product => ({
          ...product,
          lastEdited: product.updatedAt ? product.updatedAt : product.createdAt,
        }))
        .sort((a, b) => {
          return b.lastEdited - a.lastEdited;
        });

      setActiveProducts(orderedProducts);

      const radioOptions = products.map(product => {
        return {
          label: product.title,
          url: product.imageObj?.downloadUrl,
          value: product,
        };
      });

      setFormattedProducts(radioOptions);
    }
  };

  const getCustomers = async () => {
    if (shop && shop.id) {
      const subscribers = await customerRepository.findSubscribed(shop.id);

      setCustomers(subscribers);
      setCustomersLoaded(true);
    }
  };

  const getSingleProduct = (id: string) => {
    const chosenProduct = activeProducts.find(product => id === product.id);
    setChosenProduct(chosenProduct);
  };

  useEffect(() => {
    getProducts();
    getCustomers();
  }, [shop]);

  useEffect(() => {
    formatProducts(activeProducts);
  }, [activeProducts]);

  useEffect(() => {
    if (id) {
      getSingleProduct(id);
    }
  }, [activeProducts]);

  const testLabels = () => {
    if (sendingTest) {
      return <CircularProgress style={{ height: 24, width: 24 }} />;
    } else if (testEmailSent) {
      return 'Test Email Sent!';
    } else if (!sendingTest && !testEmailSent) {
      return 'Send a Test Email';
    }
  };

  const mobileDropdown = () => {
    const options: DropDownOption[] = [
      {
        label: 'Preview Email',
        icon: <VisibilityOutlinedIcon />,
        onClick: previewModal,
      },
      {
        label: 'Send a Test Email',
        icon: <SendOutlinedIcon />,
        onClick: sendTest,
      },
    ].map(a => a as DropDownOption);
    const ellipsisOptions: EllipsisMenuOption[] = options.map(option => ({
      display: option.label,
      color: option.color,
      icon: option.icon,
      action: option.onClick,
    }));

    return <EllipsisMenu options={ellipsisOptions} />;
  };

  useEffect(() => {
    const title = isMobile ? 'Announcement' : 'Product Announcement Email';

    setBackLocation(true);
    setPageTitle(title);
    setLongTitle(true);

    isMobile
      ? setHeaderCTAs([
          mobileDropdown(),
          <ButtonBase onClick={previewModal}>
            <VisibilityOutlinedIcon />
          </ButtonBase>,
        ])
      : setHeaderCTAs([
          <Button variant="outlined" onClick={sendTest} style={{ minWidth: 190 }}>
            {testLabels()}
          </Button>,
        ]);

    return () => {
      setLongTitle(false);
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, [isMobile, sendingTest, testEmailSent]);

  useEffect(() => {
    setOptFooterFormat('two-space-between');
    setFooterCTAs([
      <DeleteButton backLocation="/marketing" isSubmitting={submitting} />,
      <SaveButton
        isSubmitting={submitting}
        handleSubmit={() => {
          setShowPreSendModal(true);
        }}
        customName="Send Email"
      />,
    ]);
    return () => {
      setOptFooterFormat('');
      setFooterCTAs([]);
    };
  }, [submitting, isXsMobile, customers]);

  const sendTest = async () => {
    if (!formikRef.current.values.product) {
      setProductError(true);
      !productExpanded && setProductExpanded(true);
      closePreviewModal();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setSendingTest(true);
      setTestEmailSent(true);

      try {
        const currentValues = formikRef.current.values;
        sendProductAnnouncementTestService({
          product: currentValues.product,
          subjectLine: currentValues.subjectLine,
          bodyText: currentValues.bodyText,
          buttonText: currentValues.buttonText,
          isTest: true,
        });
      } catch (err) {
        console.log('Error in Send Test Email');
        console.log(err.message);
        console.log(err.response);
      }

      setSendingTest(false);
      setTimeout(() => {
        setTestEmailSent(false);
      }, 2000);
    }
  };

  const handleSearchChange = (event: any) => {
    setEmptySearch(false);
    const fuse = new Fuse(activeProducts, fuseOptions);

    if (event.target.value === '') {
      formatProducts(activeProducts);
    } else {
      const result = fuse.search(event.target.value);

      if (_.isEmpty(result)) {
        setEmptySearch(true);
        setSearchValue(event.target.value);
      } else {
        //get rid of extra formatting so we can reuse function
        const formattedSearch = [];
        result.forEach(o => formattedSearch.push(o.item));

        formatProducts(formattedSearch);
      }
    }
  };

  const scrollToTop = (containerId: string) => {
    const scrollTo = document.getElementById(`${containerId}Error`);

    const yOffset = -120;
    const y = scrollTo.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // scrolls to the error within the scroll container, then scrolls to the element on the page
    scrollTo.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const onSubmit = async values => {
    setSubmitting(true);

    if (!values.product) {
      setProductError(true);
      !productExpanded && setProductExpanded(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (
      formikRef.current.errors.subjectLine ||
      formikRef.current.errors.bodyText ||
      formikRef.current.errors.buttonText
    ) {
      setContentError(true);
      if (contentExpanded) {
        scrollToTop('content');
      } else {
        setContentExpanded(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      try {
        const subscribersArr = customers.map(s => s.id);

        await sendProductAnnouncementService({
          customerIds: subscribersArr,
          product: values.product,
          subjectLine: values.subjectLine,
          bodyText: values.bodyText,
          buttonText: values.buttonText,
          isTest: false,
        });

        dispatch(openModal(postSendModal));
        if (!shop?.checklistCompletions?.includes(ChecklistValues.EmailMarketingSingleSend)) {
          shop?.addToChecklist(ChecklistValues.EmailMarketingSingleSend);
        }
        formikRef.current.resetForm();
      } catch (err) {
        console.log('Error in Submit');
        console.log(err.message);
        console.log(err.response);
      }
    }
    setSubmitting(false);
  };

  const formSchema = yup.object().shape({
    product: yup.string().required('Please select a product.'),
    subjectLine: yup.string().required(),
    bodyText: yup
      .string()
      .required()
      .test(value => !emptyBodyTextRegex.test(value)),
    buttonText: yup.string().required(),
  });

  const initialValues = {
    product: id || '',
    subjectLine: 'A New Product Just Dropped!',
    bodyText:
      'We are excited to announce our latest product! Click on the button below to learn more and place an order.',
    buttonText: 'Order Now',
  };

  const previewModal = () => {
    setOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const emailPreview = (
    <>
      <img src={EmailPreview} className={classes.previewImage} />
      <Banner variant="info-blue" className={classes.banner}>
        <Typography variant="body2" style={{ color: 'inherit' }}>
          This preview is for your reference only. Curious what customers see?{isMobile ? <br /> : ' '}
          <span onClick={sendTest} className={classes.infoSend}>
            Send yourself a test email
          </span>
        </Typography>
      </Banner>
    </>
  );

  const audienceOptions = [
    {
      label: `All Subscribers (${customers.length})`,
      value: 'all',
    },
  ];

  const preSendModal = (
    <GeneralModal
      icon="💌"
      title={`Ready to send this email to ${customers.length} subscriber${customers.length !== 1 ? 's' : ''}?`}
      content={
        <>
          <Typography variant="body1">
            Prefer to preview your email before sending it to your subscriber{customers.length !== 1 ? 's' : ''}?
            {isXsMobile ? <br /> : ' '}
            <span onClick={sendTest} className={classes.sendText}>
              Send a Test Email
            </span>
          </Typography>
          {testEmailSent && (
            <Typography variant="button" component="p" className={classes.sendTestEmailText}>
              {testLabels()}
            </Typography>
          )}
        </>
      }
      actions={[
        <Button
          variant="outlined"
          onClick={() => {
            setShowPreSendModal(false);
          }}
        >
          Continue Editing
        </Button>,
        <Button
          variant="contained"
          onClick={() => {
            setShowPreSendModal(false);
            onSubmit(formikRef.current.values);
          }}
        >
          Send Email
        </Button>,
      ]}
      onClose={() => {
        setShowPreSendModal(false);
      }}
      show={showPreSendModal}
    />
  );

  const postSendModal = {
    modalType: 'GENERAL_MODAL' as ModalType,
    modalProps: {
      icon: '🎉',
      title: 'Product Announcement Sent!',
      content: (
        <Typography variant="body1">
          <b>
            {customers.length} subscriber{customers.length !== 1 ? 's' : ''}
          </b>{' '}
          {customers.length !== 1 ? 'are' : 'is'} now aware of your latest product. Get your ingredients ready! It’s
          almost time to get cooking!
        </Typography>
      ),
      actions: [
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(closeModal());
            window.scrollTo(0, 0);
            setProductExpanded(true);
            setContentExpanded(false);
            setSendDetailsExpanded(false);
          }}
        >
          Create Another Email
        </Button>,
        <Button
          variant="contained"
          onClick={() => {
            dispatch(closeModal());
            history.push('/marketing');
          }}
        >
          Close
        </Button>,
      ],
      show: true,
    },
  };

  return (
    <>
      <Formik
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={formSchema}
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({ dirty, values, setFieldValue, errors }): ReactElement => {
          return (
            <AdminForm>
              <UnsavedChangesPrompt when={dirty} />
              <Grid container spacing={!isMobile && 3} className={classes.innerContainer}>
                <Grid container item xs={12} md={7} justify="center">
                  {customersLoaded && customers.length === 0 && (
                    <Banner
                      variant="error"
                      className={classes.noContactsBanner}
                      children={
                        <Grid container item justify="space-between" direction="row" alignItems="flex-start">
                          <Grid container item xs={8}>
                            <Typography variant="body2" style={{ color: 'inherit' }}>
                              Currently, no one will receive this email. To continue, add contacts who’ve opted in to
                              receive marketing offers.
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <Button
                              variant="outlined"
                              className={classes.addContacts}
                              onClick={() => history.push('/contacts/add')}
                              color="inherit"
                            >
                              <Typography variant="button" style={{ color: 'inherit' }}>
                                Add Contacts
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                  )}
                  <Grid container className={classes.cardContainer}>
                    <CollapsableCard
                      cta={
                        <Button
                          onClick={() => {
                            if (_.isEmpty(values.product)) {
                              scrollToTop('product');
                              setProductError(true);
                            } else {
                              setProductExpanded(false);
                              setContentExpanded(true);
                            }
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Next Step: Content
                        </Button>
                      }
                      expanded={productExpanded}
                      handleExpand={() => {
                        setProductExpanded(!productExpanded);
                      }}
                      id="product"
                      supertitle="STEP 1/3"
                      title={
                        <Grid className={classes.title} xs={12}>
                          <Typography variant="subtitle1" className={classes.titleCutoff}>
                            {!_.isEmpty(values.product) ? `Announcing: ${chosenProduct?.title}` : 'Product To Announce'}
                          </Typography>
                          <Tooltip
                            title={
                              <Typography variant="body2" style={{ color: theme.branding.gray[100] }}>
                                We'll include a product link and its photo in the email.
                              </Typography>
                            }
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      }
                    >
                      <div id="productError">
                        {productError && (
                          <Grid className={classes.errorBox}>
                            <ErrorOutlineIcon />
                            <Typography variant="body2" className={classes.errorText}>
                              Please select a product to continue.
                            </Typography>
                          </Grid>
                        )}
                      </div>
                      <Grid style={{ marginBottom: 8 }}>
                        <SearchInput onChange={handleSearchChange} />
                      </Grid>
                      {emptySearch ? (
                        <Grid container className={classes.emptySearch}>
                          <SearchOutlinedIcon className={classes.searchIcon} />
                          <Typography variant="body3">
                            No results for <span style={{ fontWeight: 700 }}>"{searchValue}"</span>
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid container item>
                          <ImageRadioInput
                            options={formattedProducts}
                            onChange={value => {
                              setProductError(false);
                              getSingleProduct(value);
                              setFieldValue('product', value);
                            }}
                            value={getIn(values, 'product')}
                          />
                        </Grid>
                      )}
                    </CollapsableCard>

                    <CollapsableCard
                      cta={
                        <Button
                          onClick={() => {
                            if (errors.subjectLine || errors.bodyText || errors.buttonText) {
                              scrollToTop('content');
                              setContentError(true);
                            } else {
                              setContentExpanded(false);
                              document.getElementById('send').scrollIntoView({ behavior: 'smooth', block: 'start' });
                              setSendDetailsExpanded(true);
                            }
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Next Step: Send Details
                        </Button>
                      }
                      expanded={contentExpanded}
                      handleExpand={() => {
                        setContentExpanded(!contentExpanded);
                      }}
                      id="content"
                      noScroll={isMobile}
                      supertitle="STEP 2/3"
                      title={
                        <Grid className={classes.title}>
                          <Typography variant="subtitle1">Content</Typography>
                          <Tooltip
                            title={
                              <Typography variant="body2" style={{ color: theme.branding.gray[100] }}>
                                Customize the content of your email.
                              </Typography>
                            }
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      }
                    >
                      <div id="contentError">
                        {contentError && (
                          <Grid className={classes.errorBox}>
                            <ErrorOutlineIcon />
                            <Typography variant="body2" className={classes.errorText}>
                              Please enter the following to continue:{' '}
                              {[
                                errors.subjectLine && 'Subject Line',
                                errors.bodyText && 'Body Text',
                                errors.buttonText && 'Button Text',
                              ]
                                .filter(error => error)
                                .join(', ')}
                              .
                            </Typography>
                          </Grid>
                        )}
                      </div>
                      <Grid style={{ marginBottom: '3px' }}>
                        <TextInput
                          label="Subject Line"
                          name="subjectLine"
                          onChange={e => {
                            setContentError(false);
                            setFieldValue('subjectLine', e.target.value);
                          }}
                        >
                          {values.subjectLine}
                        </TextInput>
                        <Typography variant="caption">The main header of your email.</Typography>
                      </Grid>
                      <Grid style={{ marginBottom: '24px' }}>
                        <RichTextInput
                          className={classes.bodyTextContainer}
                          editorClassName={classes.bodyTextInput}
                          height={103}
                          label="Body Text"
                          name="bodyText"
                          onChange={value => {
                            setContentError(false);
                            setFieldValue('bodyText', value);
                          }}
                          placeholder=""
                        />
                        <Typography variant="caption">
                          The main message of your email. We recommend keeping this short and concise.
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextInput
                          label="Button Text"
                          name="buttonText"
                          onChange={e => {
                            setContentError(false);
                            setFieldValue('buttonText', e.target.value);
                          }}
                        >
                          {values.buttonText}
                        </TextInput>
                        <Typography variant="caption">
                          This button links directly to this product in your shop.
                        </Typography>
                      </Grid>
                    </CollapsableCard>
                    <CollapsableCard
                      cta={
                        <Button variant="contained" fullWidth onClick={sendTest}>
                          {testLabels()}
                        </Button>
                      }
                      expanded={sendDetailsExpanded}
                      handleExpand={() => {
                        !sendDetailsExpanded && document.getElementById('send').scrollIntoView({ behavior: 'smooth' });
                        setSendDetailsExpanded(!sendDetailsExpanded);
                      }}
                      id="send"
                      noScroll
                      supertitle="STEP 3/3"
                      title={
                        <Grid className={classes.title}>
                          <Typography variant="subtitle1">Send Details</Typography>
                          <Tooltip
                            title={
                              <Typography variant="body2" style={{ color: theme.branding.gray[100] }}>
                                Select when to send and who should receive this email.
                              </Typography>
                            }
                          >
                            <InfoOutlinedIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      }
                    >
                      <Banner variant="info-white">
                        <Typography variant="button">
                          Announcement emails can only be sent immediately to all subscribers but we’re cooking up even
                          more flexibility for your marketing soon!
                        </Typography>
                      </Banner>
                      <Grid xs={12} container className={classes.sendOptionsContainer}>
                        <Grid className={classes.optionContainer}>
                          <Typography variant="subtitle2">Audience</Typography>
                          <Select
                            className={`${classes.noArrow} ${classes.select}`}
                            disabled
                            variant="outlined"
                            defaultValue="all"
                          >
                            {audienceOptions.map((option, index) => (
                              <MenuItem value={option.value} key={index}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid>
                          <Typography variant="subtitle2">Schedule Send</Typography>
                          <Select
                            className={`${classes.noArrow} ${classes.select}`}
                            disabled
                            variant="outlined"
                            defaultValue="immediately"
                          >
                            <MenuItem value="immediately">Immediately</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </CollapsableCard>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid container item md={5}>
                    {emailPreview}
                  </Grid>
                )}
              </Grid>
            </AdminForm>
          );
        }}
      </Formik>
      {preSendModal}
      <Dialog fullScreen={true} open={openPreviewModal} onClose={closePreviewModal}>
        <DialogTitle className={classes.previewModalHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h2">Email Preview</Typography>
            <IconButton onClick={closePreviewModal}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.previewModalContainer}>
          <Grid container direction="column" alignItems="center">
            {emailPreview}
            {testEmailSent && (
              <Typography variant="button" component="p" className={classes.sendTestEmailText}>
                {testLabels()}
              </Typography>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewProductForm;
