import React from 'react';
import { GridCellParams, GridColumnHeaderTitle } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import SortIcon from '../DataGrid/SortIcon';

type Props = {
  params: GridCellParams;
};

const SortHeader: React.FC<Props> = (props: Props) => {
  const { field, api, colDef } = props.params;
  const sort = api.state.sorting.sortModel.filter(s => s.field === field)?.[0]?.sort;
  const isSorting = Boolean(sort);

  return (
    <>
      <GridColumnHeaderTitle
        label={colDef.headerName || colDef.field}
        description={colDef.description}
        columnWidth={colDef.width}
      />
      {!isSorting && (
        <div className="MuiDataGrid-iconButtonContainer">
          <IconButton size="small">
            <SortIcon direction="default" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default SortHeader;
