import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

export interface DebugState {
  stripe?: {
    testClock?: number;
  }
}

const initialState: DebugState = {};

type SetDebug = {
  path: string;
  value: string;
}

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebugParam(state: DebugState, action: PayloadAction<SetDebug>) {
      console.debug(`Setting Debug State: [${action.payload.path}: ${action.payload.value}]`);
      _.set(state, action.payload.path, action.payload.value);
    }
  },
  extraReducers: builder => {
  },
});

export const {
  setDebugParam: setDebugParam,
} = debugSlice.actions;

export default debugSlice.reducer;
