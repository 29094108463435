import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '@castiron/client-admin/src/hooks';
import { Helmet } from 'react-helmet';
import CouponForm from './CouponForm';
import { getCouponAction } from '@castiron/client-admin/src/store/reducers/coupons';
import { LayoutPageProps } from '../../Layout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  leftColumn: {
    marginRight: '24px',
  },
}));

interface Props extends LayoutPageProps {
  editing?: boolean;
}

const EditCoupon: React.FC<Props> = (props: Props) => {
  const { editing = false, setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle(editing ? 'Edit Coupon' : 'Add a Coupon');
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    dispatch(getCouponAction(shop.id));
  }, [shop]);

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>Coupons | {shop.businessName}</title>
      </Helmet>
      <Grid container justify="center" alignItems="center" wrap="nowrap">
        <Grid container item xs={12}>
          <CouponForm editing={editing} setFooterCTAs={setFooterCTAs} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditCoupon;
