import React, { useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useTracking } from '@castiron/utils';
import { backendStateToFrontendState, FrontendTransactionState, Transaction } from '@castiron/domain';
import { transactionRepository } from '../../../domain';
import { openModal } from '../../../store/reducers/modalConductor';
import { listCustomTransactionsAction, listTransactionsAction } from '../../../store/reducers/transactions';
import { availableQuoteActions, prepareQuoteSegmentData, validateQuote } from '../../Quotes/QuoteUtils';
import Spinner from '../../Spinner';
import { getService } from "../../../firebase";
import * as Sentry from "@sentry/react";

type Props = {
  transaction: Transaction;
  editing: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  }
}));


const sendQuoteUpdatedService = getService('orders', 'sendQuoteUpdatedEmail');

const QuoteActionsMenu: React.FC<Props> = (props: Props) => {
  const { transaction, editing, onClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [showSpinner, setShowSpinner] = useState(false);

  const trackAction = (action: string): void => {
    trackEvent(
      'Quote Action Clicked',
      {
        action,
        url: window.location.href,
        ...prepareQuoteSegmentData(transaction)
      }
    );
  }

  const existingStatus = backendStateToFrontendState(transaction, 'quote');
  const trackStatusChange = (newStatus: FrontendTransactionState): void => {
    trackEvent('Quote Status Changed', {
      url: window.location.href,
      previousStatus: existingStatus,
      newStatus,
      ...prepareQuoteSegmentData(transaction)
    });
  };

  const actions = availableQuoteActions(transaction);

  const formikContext = useFormikContext();

  const sendQuote = async (event) => {
    event.stopPropagation();
    const sendQuoteTx = Sentry.startTransaction({
      op: 'submit',
      name: 'Send Quote',
      tags: {
        transactionType: 'business'
      }
    });
    Sentry.getCurrentHub().configureScope(scope => {
      scope.setSpan(sendQuoteTx);
      scope.setUser({
        id: shop.id,
        email: shop.email,
        username: shop.websiteUrl
      })
    });

    if (editing && formikContext) {
      const span = sendQuoteTx.startChild({
        op: 'submit',
        description: 'Submit Form'
      });
      await formikContext.submitForm();
      span.finish();
    }

    /* I hate that I'm doing this but there is no guarantee the transaction in the props is up to date enough for our purposes here
     * get the most recent from the source of truth after the form is submitted
     */
    const upToDateTransaction = await transactionRepository.get(transaction.id);

    trackAction('sendQuote');
    if (validateQuote(upToDateTransaction).length > 0) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=missingRequired`);
      window.scrollTo(0, 0);
      onClose(event);
      return;
    }
    const subtotal = upToDateTransaction.totals?.subtotal || 0;
    if (subtotal < 50) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=illegalTotal`);
      window.scrollTo(0, 0);
      onClose(event);
      return;
    }

    dispatch(
      openModal({
        modalType: 'QUOTE_SEND_MODAL',
        modalProps: {
          show: true,
          transaction: upToDateTransaction,
        },
      }),
    );
    onClose(event);
  };
  const sendQuoteAction = (
    <ButtonBase key="sendQuoteMenuAction" focusRipple className={classes.buttonBase} onClick={sendQuote}>
      <Typography className={classes.menuLabel}>{editing ? 'Save and s' : 'S'}end quote</Typography>
    </ButtonBase>
  );

  const resendQuote = async (event) => {
    event.stopPropagation();
    trackAction('resendQuote');

    if (editing && formikContext) {
      /* this should do everything we do in the else below for us */
      await formikContext.submitForm();
    } else {
      setShowSpinner(true);
      if (validateQuote(transaction).length > 0) {
        history.push(`/quotes/edit/${transaction.id}?error=missingRequired`);
        window.scrollTo(0, 0);
        return;
      }
      const subtotal = transaction.totals?.subtotal || 0;
      if (subtotal < 0.5) {
        history.push(`/quotes/edit/${transaction.id}?error=illegalTotal`);
        window.scrollTo(0, 0);
        return;
      }

      try {
        await sendQuoteUpdatedService({ transactionId: transaction.id });
        setShowSpinner(false);
      } catch (error) {
        setShowSpinner(false);
      }
    }
    onClose(event);
  };
  const resendQuoteAction = (
    <ButtonBase key="resendQuoteMenuAction" focusRipple className={classes.buttonBase} onClick={resendQuote}>
      <Typography className={classes.menuLabel}>{editing ? 'Save and r' : 'R'}e-send quote</Typography>
    </ButtonBase>
  );

  const openContactModal = (event): void => {
    event.stopPropagation();
    trackAction('sendMessage');
    dispatch(
      openModal({
        modalType: 'CONTACT_MODAL',
        modalProps: {
          show: true,
          email: transaction.customerObj?.email,
          customerId: transaction.customerObj?.id,
        },
      }),
    );
    onClose(event);
  };
  const sendMessageAction = (
    <ButtonBase key="sendMessageMenuAction" focusRipple className={classes.buttonBase} onClick={openContactModal}>
      <Typography className={classes.menuLabel}>Send message to customer</Typography>
    </ButtonBase>
  );

  const cancel = useCallback(async (event) => {
    event.stopPropagation();
    trackAction('cancel');
    dispatch(
      openModal({
        modalType: 'REJECT_CUSTOM_ORDER',
        modalProps: {
          show: true,
          transaction,
        },
      }),
    );
    onClose(event);
  }, []);
  const cancelAction = (
    <ButtonBase key="cancelMenuAction" focusRipple className={classes.buttonBase} onClick={cancel}>
      <Typography className={classes.menuLabel}>Cancel quote</Typography>
    </ButtonBase>
  );

  const setArchived = (isArchived: boolean) => useCallback(async (event) => {
    event.stopPropagation();
    trackAction(isArchived ? 'archive' : 'unarchive');
    await transactionRepository.updateProps(transaction.id, {
      isArchived: isArchived,
    });
    /* hack a new transaction here to confirm new status */
    trackStatusChange(backendStateToFrontendState({ ...transaction, isArchived }, 'quote'));
    dispatch(listTransactionsAction(shop.id));
    dispatch(listCustomTransactionsAction(shop.id));
    if (isArchived) {
      history.push('/quotes');
    }
    onClose(event);
  }, []);
  const archiveAction = (
    <ButtonBase key="archiveMenuAction" focusRipple className={classes.buttonBase} onClick={setArchived(true)}>
      <Typography className={classes.menuLabel}>Archive quote</Typography>
    </ButtonBase>
  );
  const unarchiveAction = (
    <ButtonBase key="unarchiveMenuAction" focusRipple className={classes.buttonBase} onClick={setArchived(false)}>
      <Typography className={classes.menuLabel}>Unarchive quote</Typography>
    </ButtonBase>
  );

  const viewQuote = (event) => {
    trackAction('view');
    event.stopPropagation();
    history.push(`/orders/edit/${transaction.id}`);
    onClose(event);
  };
  const viewAction = (
    <ButtonBase key="viewQuoteMenuAction" focusRipple className={classes.buttonBase} onClick={viewQuote}>
      <Typography className={classes.menuLabel}>View order</Typography>
    </ButtonBase>
  );

  const actionComponents = actions.map((actionName) => {
    switch (actionName) {
      case 'sendQuote':
        return sendQuoteAction;
      case 'resendQuote':
        return resendQuoteAction;
      case 'sendMessage':
        return sendMessageAction;
      case 'cancel':
        return cancelAction;
      case 'archive':
        return archiveAction;
      case 'unarchive':
        return unarchiveAction;
      case 'view':
        return viewAction;
      default:
        return null;
    }
  }).filter((actionComp) => !!actionComp);

  return (
    <>
      <Spinner show={showSpinner} size="fullscreen" />
      {actionComponents}
    </>
  );
};

export default QuoteActionsMenu;
