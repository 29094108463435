import { InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import SearchIcon from '../../assets/img/search.svg';
import React from 'react';
import { SvgIcon } from '@castiron/components';

type Props = {
  onChange?: (e) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: 40,
    minWidth: 170,
    backgroundColor: '#E4E4E43D',
    border: 'none',
    borderRadius: 8,
    fontSize: 14,
    paddingLeft: 2,
  },
  outline: {
    border: 'none',
  },
  icon: {
    color: theme.branding.gray[700],
    padding: 0,
    width: 32,
    height: 32,
  },
}));

const SearchInput: React.FC<Props> = (props: Props) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        classes: { root: classes.input, notchedOutline: classes.outline },
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon className={classes.icon}>
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      placeholder="Filter list"
      onChange={onChange}
    />
  );
};

export default SearchInput;
