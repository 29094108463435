import React, { useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import moment from 'moment';
import Confetti from '../../Confetti/Confetti';
import { Button } from '@castiron/components';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import PartyPopper from '../../../assets/img/party-popper.png';

export type Props = {
  open: boolean;
  nextBillingDate: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: 16,
    paddingTop: 24,
    maxWidth: 400,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    [theme.breakpoints.down('sm')]: {
      right: 24,
      top: 20
    },
  },
  content: {
    fontSize: 16,
  },
}));

const ResumeSubscriptionModal: React.FC<Props> = (props: Props) => {
  const { open, nextBillingDate } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { account } = useAppSelector(state => ({
    account: state.shops.account
  }));

  const initConfetti = (isShowing): void => {
    setTimeout(() => {
      new Confetti();
    }, 500);
  };

  useEffect(() => {
    initConfetti(open);
  }, [open]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper show={open} size="xs">
      <DialogTitle>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={PartyPopper} />
          <Typography variant="h3" style={{ padding: '16px 0px' }}>You Are Resubscribed!</Typography>
          <Typography variant="body1" style={{ textAlign: "center" }}>We’re thrilled you decided to continue your subscription! Your subscription will remain the same, and your next billing date will be <span style={{ fontWeight: 700 }}>{nextBillingDate}</span>.</Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0px 24px 24px 0px' }}>
        <Button onClick={handleClose} variant="contained" style={{ padding: 16 }}>
          Done
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default ResumeSubscriptionModal;
