import { GalleryPhoto, Shop } from './index';

export const shopToEventModel = (shop: Shop) => ({
  id: shop.id,
  websiteUrl: shop.websiteUrl,
  businessName: shop.businessName,
  email: shop.email,
  tags: shop.tags,
  isICP: !!shop.tags?.includes('ICP'),
});

export const galleryPhotoToEventModel = (photo: GalleryPhoto) => ({
  url: photo.photo.downloadUrl,
  caption: photo.caption,
  category: photo.category,
});