import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Theme } from "@material-ui/core";
import { PhotoListAndGallery, Typography } from '@castiron/components';
import { Order } from "@castiron/domain";

interface Props {
  order: Order;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: 16,
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  itemBoxInner: {
    padding: '18px 16px',
  },
  itemName: {
    fontSize: '10pt',
    fontWeight: 'bold',
    marginBottom: '8px'
  },
}));

const QuoteRequestDetails: React.FC<Props> = (props: Props) => {
  const { order } = props;
  const classes = useStyles();

  return (
    <Grid className={ classes.itemBox }>
      <Grid container className={ classes.itemHeader } alignItems="center">
        <Typography className={ classes.title }>Request details</Typography>
      </Grid>
      <Grid className={ classes.itemBoxInner } container justify="space-between" direction="column" spacing={ 3 }>
        { order?.items[0]?.selections?.map((s, index) =>
          <Grid item key={index}>
            <Typography variant="body1" className={ classes.itemName }>{ s.inputFieldName }</Typography>
            <Typography variant="body2">{ s.selectedValues.map(v => v.name).join(', ') }</Typography>
            {s.selectedAssets && <PhotoListAndGallery images={ s.selectedAssets } />}
          </Grid>
        ) }
      </Grid>
    </Grid>
  );
};

export default QuoteRequestDetails;
