import React, { useEffect, useState } from "react";
import { FeatureContext } from "@castiron/components";
import { FeatureName } from "@castiron/domain";
import { getAuth, User } from "@firebase/auth";
import { tierRepository } from "../../domain";
import { useAppSelector } from "../../hooks";


const FeatureConfiguration: React.FC = (props) => {
  const { children } = props;

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const [features, setFeatures] = useState<FeatureName[]>();

  const updateClaims = (user: User) => {
    console.log('Updating features!', user);
    if (user) {
      user.getIdTokenResult(true).then(info => {
        console.log('user claims:', info.claims);
        if (info.claims?.tierId) {
          console.log('setting claims for tier', info.claims.tierId);
          tierRepository.get(info.claims.tierId as string)
            .then(t => t && setFeatures(t.features));
        }
      })
    }
  };

  useEffect(() => {
    if (!['canceled', 'inactive'].includes(account?.subscription?.status)) {
      getAuth().onAuthStateChanged(user => updateClaims(user));
    }

    updateClaims(getAuth().currentUser);
  }, [account]);

  return <FeatureContext.Provider value={ { features } }>{ children }</FeatureContext.Provider>;
}

export default FeatureConfiguration;
