import React, { ReactNode } from 'react';
import { Badge, Link, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FeatureGate, NewChip, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { FeatureName } from "@castiron/domain";

type Props = {
  label: string;
  path: string;
  external?: boolean;
  Icon?: any;
  itemCount?: number;
  onClick?: any;
  newChip?: boolean;
  className?: string;
  endIcon?: any;
  feature?: FeatureName;
};

type NavGateProps = {
  feature?: FeatureName;
  children: ReactNode;
  disabled?: ReactNode;
}

const NavGate: React.FC<NavGateProps> = (props) => {
  const { children, feature, disabled } = props;
  if (feature) {
    return (<FeatureGate name={ feature } disabled={ disabled }>{ children }</FeatureGate>);
  } else {
    return (<>{ children }</>)
  }
}

const NavItem: React.FC<Props> = (props: Props) => {
  const { Icon, className, endIcon, feature } = props;
  const { trackEvent } = useTracking();

  const location = useLocation();
  let isActive;
  if (props.path === '/') isActive = props.path === location.pathname;
  else isActive = location.pathname.includes(props.label.toLowerCase()) || location.pathname.includes(props.path);

  const classes = makeStyles(theme => ({
    active: {
      backgroundColor: theme.branding.blue.light,
      color: theme.branding.blue.primary,
      '.MuiCollapse-container &': {
        background: 'none',
      },
      '& span': {
        color: theme.branding.blue.primary
      },
    },
    badge: {
      right: '-16px',
      '& .MuiBadge-badge': {
        backgroundColor: theme.branding.orange.primary,
        color: theme.branding.gray[100],
        fontSize: 9,
        height: 16,
        lineHeight: 2,
      },
    },
    icon: {
      width: 20,
      height: 20,
      fontSize: 20,
      color: theme.branding.gray[800],
    },
    iconActive: {
      '& svg': {
        color: theme.branding.blue.primary,
      },
      '&:hover': {
        '& svg': {
          color: theme.branding.blue.primary,
        },
      }
    },
    newChip: {
      transform: 'scale(1) translate(50%, 0%)',
      transformOrigin: '100% 0%',
    },
    listItem: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: '12px',
      marginBottom: 2,
      '&.MuiLink-root': {
        color: 'inherit',
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
      '.MuiCollapse-container &': {
        fontSize: 13,
        width: 'auto',
        marginLeft: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:hover': {
          background: 'none',
        },
      },
    },
    wrapperActive: {
      '&:hover': {
        backgroundColor: theme.branding.blue.light,
        borderRadius: '12px',
      },
      ' & .MuiListItem-button:hover': {
        backgroundColor: theme.branding.blue.light,

      },
    }
  }))();

  const NavComponent = !props.external ? NavLink : Link;

  const handleClick = (e) => {
    props.onClick && props.onClick(e);
    trackEvent('Admin Nav Item Clicked', { label: props.label, path: props.path });
  };

  return (
    <NavGate feature={ feature }>
      <li className={`${isActive && classes.wrapperActive} ${className}`}>
        <ListItem
          button
          component={NavComponent}
          data-href={props.path}
          to={!props.external ? props.path : null}
          href={props.external ? props.path : null}
          target={props.external ? '_blank' : '_self'}
          className={`${classes.listItem}${isActive ? ` ${classes.active}` : ''}`}
          onClick={handleClick}
        >
          {props.Icon && (
            <ListItemIcon className={isActive ? classes.iconActive : ''}>
              <Icon className={classes.icon} />
            </ListItemIcon>
          )}
          <ListItemText
            disableTypography
            primary={
              <Typography variant="button" >
                {props.label}
              </Typography>
            }
          />
          {props.newChip && <NewChip className={classes.newChip} />}
          {props.itemCount > 0 &&
          <ListItemIcon>
            <Badge badgeContent={props.itemCount} className={classes.badge} />
          </ListItemIcon>
          }
          {endIcon && (
            <ListItemIcon>
              {endIcon}
            </ListItemIcon>
          )}
        </ListItem>
      </li>
    </NavGate>
  );
};

export default NavItem;
