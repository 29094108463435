import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Dinero from 'dinero.js';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { transactionRepository } from '../../domain';
import moment from 'moment';
import { useAppSelector } from '../../hooks';

type Props = {
  period: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  statsContainer: {
    marginTop: 32,
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  box: {
    padding: 36,
    border: '1px solid #E7E7E7',
    borderRadius: 16,
    gap: 24,
    [theme.breakpoints.down('md')]: {
      padding: 24,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 34,
    },
  },
  infoText: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
    fontSize: 12,
  },
  largeInfoText: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 12,
  },
  payoutLabel: {
    color: theme.branding.gray[700],
    fontSize: 14,
  },
  payoutDate: {
    fontSize: 14,
    fontWeight: 700,
  },
  payoutTitle: {
    marginBottom: '.5em',
    fontSize: 14,
    fontWeight: 700,
  },
  payoutBoxInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallBox: {
    padding: 16,
    border: '1px solid #E7E7E7',
    borderRadius: 16,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  smallBoxContainer: {
    display: 'flex',
    gap: 12,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  smallStatBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  statBoxMobile: {
    width: '100%',
    border: '1px solid #E7E7E7',
    borderRadius: 12,
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  statBoxSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    color: '#171717',
    lineHeight: '48px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    [theme.breakpoints.up('md')]: {
      fontSize: 54,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 48,
    },
  },
  smallValue: {
    color: '#171717',
    lineHeight: '40px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontSize: 28,
  },
  smallValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sign: {
    fontFamily: 'Roboto',
    verticalAlign: 'super',
    color: '#868686',
    fontSize: 29,
  },
  subTitle: {
    color: theme.branding.gray[800],
    fontSize: 14,
    lineHeight: '16px',
  },
  subTitlePushed: {
    color: theme.branding.gray[800],
    fontSize: 14,
    lineHeight: '16px',
  },
  success: {
    color: theme.palette.success.dark,
  },
  error: {
    color: theme.palette.error.dark,
  },
  circleSucces: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
  },
  circleError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  arrow: {
    borderRadius: 100,
    padding: 8,
    width: 35,
    height: 35,
    marginRight: 8,
  },
  arrowContainer: {
    height: 48,
    width: 48,
  },
  arrowIcon: {
    width: 38,
    height: 38,
  },
}));

const Stats: React.FC<Props> = (props: Props) => {
  const { period } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, amount, payoutFrequency } = useAppSelector(state => ({
    shop: state.shops.shop,
    amount: state.balance.amount,
    payoutFrequency: state.shops?.account?.tier?.payoutFrequency,
  }));

  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [previousTransactions, setPreviousTransactions] = useState([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

  useEffect(() => {
    const getTransactions = async () => {
      setIsTransactionsLoading(true);
      if (shop && shop.id) {
        const currentResponse = await transactionRepository.getHistoricalTransactions({
          shopId: shop.id,
          startDate: moment()
            .subtract(period, 'days')
            .startOf('day')
            .unix(),
          endDate: moment()
            .endOf('day')
            .unix(),
        });

        await setCurrentTransactions(currentResponse.filter(transaction => transaction.status !== 'canceled'));
        const previousResponse = await transactionRepository.getHistoricalTransactions({
          shopId: shop.id,
          startDate: moment()
            .subtract(period * 2, 'days')
            .startOf('day')
            .unix(),
          endDate: moment()
            .subtract(period + 1, 'days')
            .endOf('day')
            .unix(),
        });
        await setPreviousTransactions(previousResponse.filter(transaction => transaction.status !== 'canceled'));
      }
      setIsTransactionsLoading(false);
    };

    getTransactions();
  }, [shop, period]);

  let currentTransactionTotal = 0;
  let currentTransactionAvg = 0;
  let currentTransactionArtisanFees = 0;
  let currentTransactionCustomerFees = 0;
  if (currentTransactions?.length > 0) {
    currentTransactionTotal = currentTransactions.reduce((acc, transaction) => acc + transaction.totals.total, 0);
    currentTransactionAvg = Math.ceil(currentTransactionTotal / currentTransactions.length);
    currentTransactions.reduce(
      (acc, transaction) =>
        transaction.totals.totalArtisanFees
          ? (currentTransactionArtisanFees = currentTransactionArtisanFees + transaction.totals.totalArtisanFees)
          : 0,
      0,
    );
    currentTransactions.reduce(
      (acc, transaction) =>
        transaction.totals.totalCustomerFees
          ? (currentTransactionCustomerFees = currentTransactionCustomerFees + transaction.totals.totalCustomerFees)
          : 0,
      0,
    );
  }

  let previousTransactionTotal = 0;
  let previousTransactionAvg = 0;
  const previousTransactionArtisanFees = 0;
  const previousTransactionCustomerFees = 0;
  if (previousTransactions?.length > 0) {
    previousTransactionTotal = previousTransactions.reduce((acc, transaction) => acc + transaction.totals.total, 0);
    previousTransactionAvg = Math.ceil(previousTransactionTotal / previousTransactions.length);
    previousTransactions.reduce(
      (acc, transaction) =>
        transaction.totals.totalArtisanFees
          ? (currentTransactionArtisanFees = currentTransactionArtisanFees + transaction.totals.totalArtisanFees)
          : 0,
      0,
    );
    previousTransactions.reduce(
      (acc, transaction) =>
        transaction.totals.totalCustomerFees
          ? (currentTransactionCustomerFees = currentTransactionCustomerFees + transaction.totals.totalCustomerFees)
          : 0,
      0,
    );
  }

  const getDifference = (current, previous, unit = '$') => {
    let isZero = false;
    if (!current || current === 0) isZero = true;

    const changeValue = isZero ? 0 : Math.abs(current - previous);
    const valueIncreased = current >= previous;

    return { valueIncreased, changeValue };
  };

  const revenueDifference = getDifference(currentTransactionTotal, previousTransactionTotal);
  const salesDifference = getDifference(currentTransactions.length, previousTransactions.length, '');
  const averagePerSaleDifference = getDifference(currentTransactionAvg, previousTransactionAvg);
  const artisanFeeDifference = getDifference(currentTransactionArtisanFees, previousTransactionArtisanFees);
  const customerFeeDifference = getDifference(currentTransactionCustomerFees, previousTransactionCustomerFees);
  const totalPayout =
    revenueDifference.changeValue - artisanFeeDifference.changeValue - customerFeeDifference.changeValue;

  const nextPayoutDate = payoutFrequency => {
    switch (payoutFrequency) {
      case 'daily':
        return moment()
          .add(1, 'days')
          .format('MMM D, YYYY');
      case 'weekly':
        const dayINeed = 4;
        const today = moment().isoWeekday();
        if (today <= dayINeed) {
          return moment()
            .isoWeekday(dayINeed)
            .format('MMM D, YYYY');
        } else {
          return moment()
            .add(1, 'weeks')
            .isoWeekday(dayINeed)
            .format('MMM D, YYYY');
        }
      default:
        return '--';
    }
  };

  return (
    <Grid className={classes.statsContainer} container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
      <Grid xs={12} lg={8} container alignItems="center" justify="space-around" className={classes.box} wrap="nowrap">
        <Grid
          container
          direction="column"
          alignItems="center"
          xs={12}
          // lg={7}
          style={{ paddingRight: isMobile ? 0 : 16 }}
        >
          <Typography className={classes.value}>
            {Dinero({ amount: currentTransactionTotal }).toFormat('$0.00')}
          </Typography>

          <Grid container alignItems="center" justify="center" style={{ marginTop: 8 }}>
            {revenueDifference.valueIncreased ? (
              <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
            ) : (
              <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
            )}
            <Grid>
              <Typography className={classes.subTitlePushed}>Gross sales</Typography>
              {!isTransactionsLoading && (
                <Typography
                  className={`${classes.largeInfoText} ${
                    revenueDifference.valueIncreased ? classes.success : classes.error
                  }`}
                >
                  {Dinero({ amount: revenueDifference.changeValue }).toFormat('$0.00')} (last {period} days{')'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container xs={12} lg={5} direction="column">
          <Typography className={classes.payoutTitle}>
            Next Payout:{' '}
            <span className={classes.payoutDate}>{payoutFrequency ? (nextPayoutDate(payoutFrequency)) : '--'}</span>
          </Typography>
          <LabelValue
            label={
              <Text presetFont="txt--s" color={theme.branding.gray[700]}>
                Gross sales
              </Text>
            }
            value={Dinero({ amount: revenueDifference.changeValue }).toFormat('$0.00')}
            valueBold
          />
          <LabelValue
            label={
              <Text presetFont="txt--s" color={theme.branding.gray[700]}>
                Fees (customers)
              </Text>
            }
            value={`- ${Dinero({ amount: customerFeeDifference.changeValue }).toFormat('$0.00')}`}
            valueBold
          />
          <LabelValue
            label={
              <Text presetFont="txt--s" color={theme.branding.gray[700]}>
                Fees (you)
              </Text>
            }
            value={`- ${Dinero({ amount: artisanFeeDifference.changeValue }).toFormat('$0.00')}`}
            valueBold
          />
          <Divider
            orientation="horizontal"
            variant="middle"
            style={{ marginTop: 12, marginBottom: 9, marginLeft: 0, marginRight: 0 }}
          />
          <LabelValue
            label={
              <Text presetFont="txt--s" color={theme.branding.gray[700]} style={{ position: 'relative' }}>
                Total payout
                <Tooltip
                  placement="bottom"
                  style={{ position: 'absolute', right: -35, top: -7 }}
                  title={
                    <Grid style={{ padding: 16 }}>
                      <Typography style={{ fontSize: 12, marginBottom: 8 }}>
                        <strong>Gross sales</strong> = product + fulfillment + taxes + fees
                      </Typography>
                      <Typography style={{ fontSize: 12, marginBottom: 8 }}>
                        <strong>Fees (paid by customer)</strong> = total amount of fees paid at checkout when your
                        customers have placed an order. This amount is determined by your fee settings.
                      </Typography>
                      <Typography style={{ fontSize: 12, marginBottom: 8 }}>
                        <strong>Fees (paid by you)</strong> = fees owed by you on every order based on your fee settings
                        + Stripe payout fees (.25% + $0.25 per payout)
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Total payout</strong> = product + fulfillment + taxes - fees. This amount is deposited
                        directly to your connected account through Stripe.
                      </Typography>
                    </Grid>
                  }
                />
              </Text>
            }
            value={amount ? Dinero({ amount }).toFormat('$0.00') : '--'}
            valueBold
          />
        </Grid> */}
      </Grid>
      <Grid
        className={classes.smallBoxContainer}
        container
        direction="column"
        justify="center"
        xs={12}
        lg={4}
        wrap="nowrap"
      >
        <Grid container alignItems="center" className={classes.smallBox}>
          <Box className={classes.smallStatBox}>
            <div className={classes.smallValueContainer}>
              <Typography className={classes.subTitle}>Total sales</Typography>
              <Typography className={classes.smallValue}>{currentTransactions.length}</Typography>
            </div>

            {!isTransactionsLoading && (
              <Typography
                className={`${classes.infoText} ${salesDifference.valueIncreased ? classes.success : classes.error}`}
              >
                {salesDifference.changeValue} (last {period} days{')'}
                {salesDifference.valueIncreased ? (
                  <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
                ) : (
                  <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
                )}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid container alignItems="center" className={classes.smallBox}>
          <Box className={classes.smallStatBox}>
            <div className={classes.smallValueContainer}>
              <Typography className={classes.subTitle}>Avg Rev/Sale</Typography>
              <Typography className={classes.smallValue}>
                {Dinero({ amount: currentTransactionTotal }).toFormat('$0.00')}
              </Typography>
            </div>
            {!isTransactionsLoading && (
              <Typography
                className={`${classes.infoText} ${
                  averagePerSaleDifference.valueIncreased ? classes.success : classes.error
                }`}
              >
                {Dinero({ amount: averagePerSaleDifference.changeValue }).toFormat('$0.00')} (last {period} days{')'}
                {averagePerSaleDifference.valueIncreased ? (
                  <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
                ) : (
                  <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
                )}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stats;
