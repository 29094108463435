import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export interface Color {
  primary: React.CSSProperties['color'];
  light?: React.CSSProperties['color'];
  dark?: React.CSSProperties['color'];
}

export interface Grays {
  800: React.CSSProperties['color'];
  700: React.CSSProperties['color'];
  600: React.CSSProperties['color'];
  500: React.CSSProperties['color'];
  400: React.CSSProperties['color'];
  300: React.CSSProperties['color'];
  200: React.CSSProperties['color'];
  100: React.CSSProperties['color'];
}

export const Colors = {
  gray: {
    800: '#1F1F1F',
    700: '#525252',
    600: '#8F8F8F',
    500: '#B8B8B8',
    400: '#E0E0E0',
    300: '#F0F0F0',
    200: '#F8F8F8',
    100: '#FFFFFF',
  },
  green: {
    primary: '#008000',
    light: '#E5FFE5',
    dark: '#006600',
  },
  red: {
    primary: '#D51A1A',
    light: '#FCE8E8',
    dark: '#A81515',
  },
  blue: {
    primary: '#005FFF',
    light: '#E5EFFF',
    dark: '#004CCC',
  },
  orange: {
    primary: '#FF8341',
    light: '#FFEEE5',
    dark: '#CC4700',
  },
  purple: {
    primary: '#7454C9',
    light: '#EFECF9',
    dark: '#4E319B',
  },
  yellow: {
    primary: '#FFDA26',
    light: '#FFF6CC',
    dark: '#806A00',
    900: '#F57F17',
  },
};

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    body3: true;
    body4: true;
    placeholder1: true;
    placeholder2: true;
    button2: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption4: true;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    placeholder1: React.CSSProperties;
    placeholder2: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    placeholder1: React.CSSProperties;
    placeholder2: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  subtitle3: React.CSSProperties;
  subtitle4: React.CSSProperties;
  body3: React.CSSProperties;
  body4: React.CSSProperties;
  placeholder1: React.CSSProperties;
  placeholder2: React.CSSProperties;
  button2: React.CSSProperties;
  caption1: React.CSSProperties;
  caption2: React.CSSProperties;
  caption3: React.CSSProperties;
  caption4: React.CSSProperties;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    branding: {
      castiron: React.CSSProperties['color'];
      seasoned: React.CSSProperties['color'];
      damascus: React.CSSProperties['color'];
      ghostWhite: React.CSSProperties['color'];
      graphite: React.CSSProperties['color'];
      iron: React.CSSProperties['color'];
      salt: React.CSSProperties['color'];
      cocoa: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      blueberry: React.CSSProperties['color'];
      orangepeel: React.CSSProperties['color'];
      supergreen: React.CSSProperties['color'];
      radish: React.CSSProperties['color'];
      lemonzest: React.CSSProperties['color'];
      peach: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
      callout: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      elderberry: React.CSSProperties['color'];
      shadow: React.CSSProperties['color'];
      /* For quote statuses:
       * I think there is probably a better way to both organize and name these, but this will do for now
       */
      lightBlue: React.CSSProperties['color'];
      lightOrange: React.CSSProperties['color'];
      lightRed: React.CSSProperties['color'];
      lightYellow: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];

      // New standardized color approach
      blue: Color;
      green: Color;
      orange: Color;
      yellow: Color;
      red: Color;
      purple: Color;
      gray: Grays;
    };
  }
  interface ThemeOptions {
    branding: {
      castiron: React.CSSProperties['color'];
      seasoned: React.CSSProperties['color'];
      damascus: React.CSSProperties['color'];
      ghostWhite: React.CSSProperties['color'];
      graphite: React.CSSProperties['color'];
      iron: React.CSSProperties['color'];
      salt: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      cocoa: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      blueberry: React.CSSProperties['color'];
      orangepeel: React.CSSProperties['color'];
      supergreen: React.CSSProperties['color'];
      radish: React.CSSProperties['color'];
      lemonzest: React.CSSProperties['color'];
      peach: React.CSSProperties['color'];
      link: React.CSSProperties['color'];
      callout: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      elderberry: React.CSSProperties['color'];
      shadow: React.CSSProperties['color'];
      /* For quote statuses:
       * I think there is probably a better way to both organize and name these, but this will do for now
       */
      lightBlue: React.CSSProperties['color'];
      lightOrange: React.CSSProperties['color'];
      lightRed: React.CSSProperties['color'];
      lightYellow: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];

      // New standardized color approach
      blue: Color;
      green: Color;
      orange: Color;
      yellow: Color;
      red: Color;
      purple: Color;
      gray: Grays;
    };
  }
}

const defaultTheme = createMuiTheme();
const appTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1023,
      lg: 1200,
      // @ts-ignore
      xl: 1920, // HACK: This must be ignored because the type package doesn't match:
      // SIMILIAR ISSUE: https://github.com/vuetifyjs/vuetify/issues/11786
      //xxl:
    },
  },
  branding: {
    // Named Colors are deprecated in favor of the standardized pallet
    castiron: Colors.gray[800],
    seasoned: Colors.gray[800],
    damascus: Colors.gray[700],
    ghostWhite: Colors.gray[200],
    graphite: Colors.gray[600],
    iron: Colors.gray[700],
    salt: Colors.gray[200],
    darkGray: Colors.gray[600],
    cocoa: Colors.orange.light,
    mint: Colors.green.primary,
    blueberry: Colors.blue.primary,
    orangepeel: Colors.orange.primary,
    supergreen: Colors.green.primary,
    radish: Colors.red.dark,
    lemonzest: Colors.yellow.primary,
    peach: '#ffb592',
    link: Colors.blue.primary,
    callout: Colors.orange.primary,
    error: Colors.red.primary,
    elderberry: Colors.blue.primary,
    shadow: Colors.gray[300],
    /* For quote statuses:
     * I think there is probably a better way to both organize and name these, but this will do for now
     */
    lightBlue: '#E1F6FD',
    lightOrange: '#FDE3D2',
    lightRed: '#F7D5D5',
    lightYellow: '#FEF4C4',
    lightGreen: '#D2F8D8',

    // New standardized color approach
    ...Colors,
  },
  palette: {
    primary: {
      main: '#275bee',
    },
    secondary: {
      main: '#004e8e',
    },
    success: {
      main: '#275bee',
      light: '#CDE5C8',
      dark: '#266617',
    },
    warning: {
      main: '#ffaf00',
      light: '#FFEEC6',
      dark: '#776000',
    },
    error: {
      main: '#ff2b26',
      light: '#E7B3B3',
      dark: '#833838',
    },
    text: {
      secondary: '#868686',
    },
    action: { selected: '#F8F8F9' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '36px',
      fontWeight: 800,
      lineHeight: '40px',
      color: Colors.gray[800],
    },
    h2: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px',
      color: Colors.gray[800],
    },
    h3: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '26px',
      color: Colors.gray[800],
    },
    h4: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
      color: Colors.gray[800],
    },
    h5: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    h6: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      color: Colors.gray[800],
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    subtitle3: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    subtitle4: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[800],
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      color: Colors.gray[800],
    },
    body3: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    body4: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[700],
    },
    placeholder1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[500],
    },
    placeholder2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      color: Colors.gray[500],
    },
    button: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      color: Colors.gray[800],
      textTransform: 'none',
    },
    button2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.gray[800],
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.gray[800],
    },
    caption2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.green.primary,
    },
    caption3: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: Colors.red.primary,
    },
  } as ExtendedTypographyOptions,
  overrides: {
    // Style sheet name ⚛️
    MuiFormControlLabel: {
      root: {
        '& .MuiCheckbox-root': {
          color: '#dadae9',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
          color: '#275bee',
        },
      },
      label: {
        fontSize: 14,
        color: Colors.gray[800],
        fontWeight: 600,
        paddingRight: 30,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#FC6E26',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#E6E6EA',
      },
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiSlider: {
      thumb: {
        color: '#05ba89',
      },
      track: {
        color: '#05ba89',
      },
      rail: {
        color: Colors.gray[600],
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableCell-head': {
          textTransform: 'uppercase',
          fontSize: 12,
          color: '#656579',
          fontWeight: 900,
          marginRight: 8,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${Colors.gray[400]}`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px',
      },
      notchedOutline: {
        borderColor: Colors.gray[400],
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      root: {
        borderRadius: '12px',
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
      },
      sizeLarge: {
        paddingLeft: defaultTheme.spacing(4),
        paddingRight: defaultTheme.spacing(4),
        /* I would like to use spacing here,
         * but spacing is in increments of 8, and 1.375 seems weird
         */
        paddingTop: '11px',
        paddingBottom: '11px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        minWidth: defaultTheme.spacing(4),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '12px',
      },
    },
    MuiTabs: {
      root: {
        '& .MuiTabs-flexContainer': {
          boxSizing: 'border-box',
          borderBottom: '2px solid #E6E6EA',
        },
      },
    },
    MuiTab: {
      root: {
        opacity: 1,
        fontSize: 16,
        fontWeight: 700,
        color: '#0D0C21',
        '&.Mui-selected': {
          color: '#275bee',
        },
        '&.MuiTab-textColorInherit': {
          opacity: 1,
        },
      },
    },
  },
  props: {
    MuiButton: {
      size: 'large',
    },
    MuiFilledInput: {
      margin: 'none',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiListItem: {
      dense: true,
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiToolbar: {
      variant: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiOutlinedInput: {
      margin: 'none',
    },
    MuiTextField: {
      margin: 'dense',
    },
  },
});

type Props = {
  children: any;
};

const Theme: React.FC<Props> = (props: Props) => {
  return <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>;
};

export default Theme;
