import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: 48,
    width: '100%',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 4,
    padding: 12,
    fontSize: 14,
    fontWeight: 400,
  },
  inputError: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:focus': {
      outline: `1px solid ${theme.palette.error.main}`,
    },
  },
}));

const PhoneInput: React.FC<Props> = (props: Props) => {
  const { error, label, secondaryLabel, name, required } = props;
  const classes = useStyles();

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <Field
        style={{ margin: '0px' }}
        as={MaskedInput}
        className={`${classes.input} ${error && classes.inputError}`}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        name={name}
        placeholderChar={'\u2000'}
        placeholder="(123) 456-7890"
      >
      </Field>
    </InputWrapper>
  );
}

export default PhoneInput;