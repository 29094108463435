import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import _ from 'lodash';
import { Formik, FormikProps } from 'formik';
import { nanoid } from 'nanoid';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { Box, ButtonBase, FormControl, Grid, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import {
  ImageUploader,
  Typography,
  Chip,
  TrashIcon,
  CustomSwitch,
  Button,
  FormErrorList,
  SaveButton,
  DiscardButton,
} from '@castiron/components';
import { Shop } from '@castiron/domain';
import { shopRepository } from '@castiron/client-admin/src/domain';
import { useTracking } from '@castiron/utils';
import { upload } from '@castiron/castiron-firebase';
import { getCastIronAssetsAction } from '../../../../store/reducers/assets';
import { openModal } from '../../../../store/reducers/modalConductor';
import { getShopAction, updateShopAction } from '../../../../store/reducers/shops';
import CoverImageUploader from './components/CoverImageUploader';
import AdminForm from '../../../AdminForm';
import UnsavedChangesPrompt from '../../../UnsavedChangesPrompt.tsx';
import ViewShopButton from '../../../Layout/Header/ViewShopButton';
import { LayoutPageProps } from '../../../Layout';

import obModalDesktopScreen1 from '../../../../assets/img/onboardingModals/appearanceModal/AppearanceDesktopScreen1.png';
import obModalDesktopScreen2 from '../../../../assets/img/onboardingModals/appearanceModal/AppearanceDesktopScreen2.png';
import obModalMobileScreen1 from '../../../../assets/img/onboardingModals/appearanceModal/AppearanceMobileScreen1.png';
import obModalMobileScreen2 from '../../../../assets/img/onboardingModals/appearanceModal/AppearanceMobileScreen2.png';

const useStyles = makeStyles((theme: Theme) => ({
  appearanceModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  appearanceModalContentAdjustments: {
    maxWidth: '100% !important',
  },
  button: {
    minWidth: 0,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBox: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '8px',
    display: 'inline-block',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  imagePreview: {
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '16px',
    maxWidth: '300px',
    width: 'calc(100% - 32px)',
  },
  imageTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  logoAndShop: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '8px',
    padding: '13px 16px 16px 16px',
    marginTop: '15px',
  },
  panelContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 22,
  },
  switch: {
    marginTop: 8,
  },
  tabPanel: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  trashIcon: {
    right: '0px',
    margin: '4px',
    position: 'absolute',
    '& svg': {
      color: theme.branding.gray[600],
    },
  },
}));

const Appearance: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const formikRef = useRef<FormikProps<Shop>>();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const focus = urlParams.get('focus');
  const fromChecklist = urlParams.get('fromChecklist');
  const history = useHistory();

  const { account, shop, castIronAssets } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    castIronAssets: state.assets.castIronAssets,
    user: state.users.me,
  }));

  const [profileImageObj, setProfileImageObj] = useState(undefined);
  const [logoImageObj, setLogoImageObj] = useState(undefined);
  const [coverImageObj, setCoverImageObj] = useState(undefined);
  const [toggle, setToggle] = useState(shop.useLogoAndName);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profileImageObj === undefined && shop?.profileImageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setProfileImageObj(shop.profileImageObj.downloadUrl);
    }
  }, [shop]);

  useEffect(() => {
    if (logoImageObj === undefined && shop?.logoImageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setLogoImageObj(shop.logoImageObj.downloadUrl);
    }
  }, [shop]);

  useEffect(() => {
    if (coverImageObj === undefined && shop?.coverImageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setCoverImageObj(shop.coverImageObj.downloadUrl);
    }
  }, [shop]);

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  useEffect(() => {
    if (castIronAssets.length) {
      if (focus === 'profile-picture') {
        document.getElementById('profile-picture').scrollIntoView();
      } else if (focus === 'about') {
        setTimeout(() => {
          document.getElementById('about').scrollIntoView();
        }, 300);
      }
    }
  }, [focus, castIronAssets]);

  useEffect(() => {
    dispatch(getCastIronAssetsAction());
  }, [dispatch]);

  useEffect(() => {
    setPageTitle('Appearance');
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton />]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/store/dashboard" />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />,
    ]);
  }, [submitting]);

  const appearanceModalContent = [
    {
      header: 'Brand Your Shop',
      body: 'Add your logo, profile image, and cover image to make your shop feel uniquely yours!',
      media: isMobileXs ? (
        <img src={obModalMobileScreen1} alt="Welcome Appearance Screen 1" />
      ) : (
        <img src={obModalDesktopScreen1} alt="Welcome Appearance Screen 1" />
      ),
      mediaClassName: classes.appearanceModalMediaAdjustments,
      contentClassName: classes.appearanceModalContentAdjustments,
    },
    {
      header: 'See Your Shop Come to Life!',
      body: (
        <>
          After editing, click <b>View My Shop</b> to see your brand come to life.
        </>
      ),
      media: isMobileXs ? (
        <img src={obModalMobileScreen2} alt="Welcome Appearance Screen 2" />
      ) : (
        <img src={obModalDesktopScreen2} alt="Welcome Appearance Screen 2" />
      ),
      mediaClassName: classes.appearanceModalMediaAdjustments,
      contentClassName: classes.appearanceModalContentAdjustments,
    },
  ];

  const openWelcomeAppearanceModal = () => {
    dispatch(
      openModal({
        modalType: 'ONBOARDING_MODAL',
        modalProps: {
          show: true,
          stepContent: appearanceModalContent,
          onboardingModalType: 'appearanceModal',
        },
      }),
    );
  };

  useEffect(() => {
    if (shop?.status === 'prelaunch' && account) {
      if (!account.onboardingModals || (account.onboardingModals && !account.onboardingModals?.appearanceModalShown)) {
        openWelcomeAppearanceModal();
      }
    }
  }, [account]);

  const handleFile = async (item, assetType) => {
    const { file } = item;
    const id = nanoid();
    //TODO: this was failing when a file did not exist and was not updating products
    //this seemed to fix it (prevents upload) but I'm not sure if that is what should actually be done
    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
        assetType,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      await upload(file, metadata, options, callbacks, context);
    }
    return id;
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    console.debug('handleFileUploadSuccess: ', downloadUrl);

    const newProps = {};
    newProps[`${metadata.assetType}ImageObj`] = {
      id: metadata.id,
      shopId: shop.id,
      downloadUrl,
      metadata: metadata,
      options,
    };

    await shopRepository.updateProps(shop.id, newProps);
    dispatch(getShopAction(shop.id));
  };

  const storeSchema = yup.object().shape({
    logo: yup.object().nullable(),
    useLogoAndName: yup.boolean(),
    profileImage: yup.object().nullable(),
    bannerEnabled: yup.boolean(),
    bannerMessage: yup.string().nullable(),
  });

  const emptyValues = {
    logo: '',
    useLogoAndName: false,
    profileImage: '',
    castIronCoverImage: '',
    bannerMessage: '',
    bannerEnabled: false,
  };

  const initialValues = {
    ...emptyValues,
    ...shop,
    logo: shop?.logoImageObj,
    profileImage: shop?.profileImageObj,
    coverImage: shop?.coverImageObj?.id || '',
    bannerMessage: shop?.config?.banner?.message || '',
    bannerEnabled: shop?.config?.banner?.enabled || false,
  };

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      const {
        logo,
        profileImage,
        coverImage,
        castIronCoverImage,
        bannerMessage,
        bannerEnabled,
        ...restOfValues
      } = values;

      const config = {
        ...shop?.config,
        banner: {
          enabled: bannerEnabled,
          message: bannerMessage,
        },
      };

      const newShop: Shop = {
        ...shop,
        ...restOfValues,
        config,
      };

      if (!logo) newShop.logoImageObj = null;
      if (!coverImage) newShop.coverImageObj = null;
      if (!profileImage) newShop.profileImageObj = null;

      if (castIronCoverImage) {
        newShop.coverImageObj = castIronAssets.find(asset => asset.id === castIronCoverImage);
      }

      const newCompletions = [];

      await dispatch(updateShopAction({ shop: newShop, newCompletions })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                celebrate: true,
                content: 'Changes have been saved!',
              },
            }),
          );
        }
      });

      if (logo) {
        if (!shop || logo !== shop.logoImageObj?.id) {
          await handleFile(logo, 'logo');
        }
      }

      if (profileImage) {
        if (!shop || profileImage !== shop.profileImageObj?.id) {
          await handleFile(profileImage, 'profile');
        }
      }

      if (coverImage) {
        if (!shop || coverImage !== shop.coverImageObj?.id) {
          trackEvent('Shop Cover Image Updated', {
            shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email']),
          });
          await handleFile(coverImage, 'cover');
        }
      }

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  return (
    <Grid container>
      <Helmet>
        <title>Appearance | Shop | Castiron</title>
      </Helmet>
      <Grid item xs={12} className={classes.tabPanel}>
        <Formik
          initialValues={initialValues}
          validationSchema={storeSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ setFieldValue, values, isSubmitting, errors, submitCount, dirty }) => (
            <AdminForm>
              <Grid className={classes.panelContainer} spacing={!isMobile && 6} container>
                <Grid className={classes.section} item xs={12} md={8}>
                  <FormControl>
                    <Grid container direction="column">
                      <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">Announcement banner</Typography>
                        <FormControl>
                          <CustomSwitch
                            name="bannerEnabled"
                            checked={values.bannerEnabled}
                            onChange={e => {
                              setFieldValue('bannerEnabled', !values.bannerEnabled);
                            }}
                            className={classes.switch}
                          />
                        </FormControl>
                      </Grid>
                      <Typography variant="body4">
                        Show a message to all shoppers in a bar at the top of your shop page. You can highlight a new
                        promotion, when a new product will be launching, a vacation, or anything else that you want to
                        make sure shoppers don’t miss.
                      </Typography>
                    </Grid>
                    <TextField
                      value={values.bannerMessage || ''}
                      placeholder="We are currently on vacation. Any orders placed will ship after November 15th. 🏝"
                      rows={1}
                      onChange={e => setFieldValue('bannerMessage', e.target.value)}
                      variant="outlined"
                      inputProps={{ maxLength: 144 }}
                    />
                  </FormControl>
                  <CoverImageUploader
                    image={coverImageObj}
                    onDeleteClick={(): void => {
                      setCoverImageObj('');
                      setFieldValue('coverImage', '');
                    }}
                    onFileDrop={(files): void => {
                      const newURL = URL.createObjectURL(files[0]);
                      setCoverImageObj(newURL);
                      setFieldValue('coverImage', { file: files[0], preview: newURL });
                    }}
                  />
                </Grid>

                <Grid className={classes.section} item xs={12} md={4}>
                  <Grid id="profile-picture">
                    <Grid className={classes.imageTitle}>
                      <Typography variant="subtitle1">Profile Image</Typography>
                      <Chip colorScheme="info" className={classes.chip}>
                        <Typography variant="h6">Recommended</Typography>
                      </Chip>
                    </Grid>
                    <Typography variant="body4" style={{ marginBottom: '8px' }}>
                      Primary photo in your shop. Size recommended: 512px x 512px.
                    </Typography>
                    {profileImageObj ? (
                      <Grid className={classes.imageBox}>
                        <ButtonBase
                          className={classes.trashIcon}
                          onClick={(): void => {
                            setProfileImageObj('');
                            setFieldValue('profileImage', '');
                          }}
                        >
                          <TrashIcon />
                        </ButtonBase>
                        <img className={classes.imagePreview} src={profileImageObj} />
                      </Grid>
                    ) : (
                      <ImageUploader
                        onFileDrop={(files): void => {
                          const newURL = URL.createObjectURL(files[0]);
                          setProfileImageObj(newURL);
                          setFieldValue('profileImage', { file: files[0], preview: newURL });
                        }}
                      />
                    )}
                  </Grid>
                  <Grid id="logo">
                    <Grid className={classes.imageTitle}>
                      <Typography variant="subtitle1">Shop Logo</Typography>
                      <Chip colorScheme="info" className={classes.chip}>
                        <Typography variant="h6">Recommended</Typography>
                      </Chip>
                    </Grid>
                    <Typography variant="body4" style={{ marginBottom: '8px' }}>
                      Appears on the top of your shop’s homepage, checkout process, and shop emails. Don’t have a logo
                      yet? We’ll use your shop name instead.
                    </Typography>
                    {logoImageObj ? (
                      <Grid item className={classes.imageBox}>
                        <ButtonBase
                          className={classes.trashIcon}
                          onClick={(): void => {
                            setLogoImageObj('');
                            setFieldValue('logo', '');
                          }}
                        >
                          <TrashIcon />
                        </ButtonBase>
                        <img className={classes.imagePreview} src={logoImageObj} />
                      </Grid>
                    ) : (
                      <ImageUploader
                        onFileDrop={(files): void => {
                          const newURL = URL.createObjectURL(files[0]);
                          setLogoImageObj(newURL);
                          setFieldValue('logo', { file: files[0], preview: newURL });
                        }}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.logoAndShop}>
                    <Grid container direction="row" alignItems="center" style={{ marginBottom: '5px' }}>
                      <FormControl>
                        <CustomSwitch
                          name="useLogoAndName"
                          checked={values.useLogoAndName}
                          onChange={e => {
                            setToggle(!toggle);
                            setFieldValue('useLogoAndName', !values.useLogoAndName);
                          }}
                          className={classes.switch}
                        />
                      </FormControl>
                      <Typography variant="h5" style={{ margin: '8px 0px 0px 16px ' }}>
                        Use Logo & Shop Name
                      </Typography>
                    </Grid>
                    <Typography variant="body4">
                      If left unselected, only the logo will be displayed at the top of your shop’s homepage.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={classes.buttonsContainer}>
                {!!submitCount && !!errors && !isSubmitting && <FormErrorList errors={errors} />}
              </Box>
              <UnsavedChangesPrompt when={dirty} />
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Appearance;
