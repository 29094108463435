import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY).then(r => {
  console.debug("Stripe Loaded");
  return r;
});

type Props = {
  children?: any;
};

const StripeElementsProvider: React.FC<Props> = (props: Props) => {
  return <Elements stripe={ stripePromise }>{ props.children }</Elements>;
};

export default StripeElementsProvider;
