import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import TrashIcon from '../../../../assets/img/trash.svg';
import ChevronUpIcon from '../../../../assets/img/chevron-up.svg';
import ChevronDownIcon from '../../../../assets/img/chevron-down.svg';
import { nanoid } from '@reduxjs/toolkit';
import PriceInput from './PriceInput';
import { CustomSwitch, Input, SvgIcon, Typography } from '@castiron/components';
import CloseIcon from '@material-ui/icons/Close';
import { InputField, ProductType } from '@castiron/domain/src';
import DragIndicator from "@material-ui/icons/DragIndicator";

const useStyles = makeStyles((theme: Theme) => ({
  addOption: {
    border: `1px solid ${ theme.branding.gray[400] }`,
    borderRadius: 4,
    padding: '13px 32px',
    margin: '8px 0 24px 0',
  },
  checkboxLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    wordBreak: 'break-word',
  },
  closeIcon: {
    width: '50px',
  },
  collapseHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  decorator: {
    color: '#FC6E26',
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  deleteButton: {
    width: 40,
    height: 40,
  },
  expandIcon: {
    width: 40,
    height: 40
  },
  formControl: {
    width: '100%',
  },
  optionContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '4px',
    margin: '8px 0px',
    padding: '16px',
  },
  optionRow: {
    marginTop: '-20px',
  },
  photoSubLabel: {
    color: theme.branding.gray[700],
    margin: '4px 0 26px 0',
  },
  priceContainer: {
    width: '115px',
  },
  priceInput: {
    margin: 0,
  },
  requiredSwitch: {
    marginLeft: 25,
    display: 'flex',
    alignItems: 'center'
  },
  settingsLabel: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    marginBottom: '8px',
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[600],
    marginTop: '5px',
  },
  totalPriceContainer: {
    background: theme.branding.gray[200],
  },
  whatCustomerWillSee: {
    backgroundColor: theme.branding.gray[200],
    padding: '32px',
    marginTop: '48px',
  },
  willSeeLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  willSeeInput: {
    backgroundColor: theme.branding.gray[200],
    height: '11px',
  },
  variationContainer: {
    border: `1px solid ${ theme.branding.gray[400] }`,
    borderRadius: 4,
    marginTop: '16px',
    position: 'relative',
    backgroundColor: theme.branding.gray[100]
  },
  variationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px',
  },
  variationIndexContainer: {
    width: '32px',
    height: '32px',
    paddingTop: '3px',
    textAlign: 'center',
    backgroundColor: theme.branding.blue.light,
    borderRadius: '8px',
    minWidth: '32px'
  },
  variationIndex: {
    color: theme.branding.blue.dark,
  },
  variationTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    marginLeft: '16px',
    marginBottom: '3px',
    maxWidth: '85%',
  },
  variationActions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  variationContent: {
    margin: '21px 24px',
  },
  dragHandle: {
    paddingTop: '2px',
    marginRight: '10px'
  },
  dragDots: {
    color: theme.branding.gray[400]
  }
}));

const typeOptions = [
  { label: 'Select one option', value: 'select' },
  { label: 'Select many options', value: 'multiselect' },
  { label: 'Fill-in answer', value: 'text' },
  { label: 'Photo upload', value: 'photo' },
];

const nameOptions = [
  'Flavor',
  'Topping',
  'Filling',
  'Color',
  'Theme',
  'Size',
  'Servings',
  'Add-ons',
  'Allergens',
  'Dietary',
  'Custom',
];

export const initialVariationOptionValues = { name: '', value: '', cost: undefined };

interface Props {
  variation: InputField;
  index: number;
  type: ProductType;
  isEditMode: boolean;
  placeholders?: Record<string, string>;
  variationExpanded: boolean;
  dragHandleProps: any;
}

const SingleVariation: React.FC<Props> = (props: Props) => {
  const { variation, index, type, isEditMode, variationExpanded, placeholders, dragHandleProps } = props;
  const { values, setFieldValue, setFieldError, errors, setFieldTouched, submitCount }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [nameValue, setNameValue] = useState(nameOptions.includes(variation.name) ? variation.name : 'Custom');
  const [typeValue, setTypeValue] = useState(variation.type);
  const [isText, setIsText] = useState<boolean>(variation.type === 'text');
  const [expanded, setExpanded] = useState(variationExpanded || !isEditMode);

  const deleteVariation = (id: string) => {
    setFieldValue(
      'variations',
      values.variations.filter((v: InputField) => v.id !== id).map((v,i) => ({
        ...v,
        position: i
      })),
    );
  };

  const handleNameChange = event => {
    setNameValue(event.target.value);
    if (event.target.value !== 'Custom') {
      setFieldValue(`variations[${ index }].name`, event.target.value);
    } else {
      setFieldValue(`variations[${ index }].name`, '');
    }
    setFieldValue(`variations[${ index }].type`, typeValue);
  };

  const handleTypeChange = event => {
    setFieldValue(`variations[${ index }].type`, event.target.value);
    setTypeValue(event.target.value);
    setIsText(event.target.value === 'text');
    if (event.target.value === 'text')
      setFieldValue(`variations[${ index }].values`, [{ ...initialVariationOptionValues, id: nanoid() }]);

    if (event.target.value === 'photo' && !values.variations[index].name) {
      setFieldValue(`variations[${ index }].name`, 'Share your inspiration photos');
    }
  };

  const addOptionValue = () => {
    setFieldValue(`variations[${ index }].values`, [
      ...variation.values,
      { ...initialVariationOptionValues, id: nanoid() },
    ]);
  };

  const deleteVariationValue = (id: string) => {
    setFieldValue(
      `variations[${ index }].values`,
      variation.values.filter(v => v.id !== id),
    );
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  const isCustom = type === 'custom';

  const variationTitle = () => {
    if (variation.name !== '') {
      return variation.name;
    } else if (isCustom) {
      return `Question #${ index + 1 }`;
    } else {
      return `Variation #${ index + 1 }`;
    }
  };

  const helperText = {
    multiselect: 'Provide customers with a list of options, of which they can select one or more options.',
    photo: 'Let customers upload photos of inspiration (up to 15). Accepted file types: PNG or JPEG.',
    select: 'Provide customers with a list of options, of which they can select only one option.',
    text: 'Let customers provide a written response to a question.',
  }


  const checkVariationErrors = (loc: string) => {
    if (submitCount > 0) {
      if (errors.variations && errors.variations && errors.variations[index]) {
        return errors.variations[index][loc];
      } else {
        return false;
      }
    }
  };

  const checkVariationValuesErrors = (loc: string, i: number, isPhoto?: boolean) => {
    if (submitCount > 0) {
      if (errors.variations && errors.variations[index] && errors.variations[index].values && errors.variations[index].values[i]) {
        return errors.variations[index].values[i][loc];
      } else {
        return false;
      }
    }
  };

  return (
    <Grid key={ variation.id } className={ classes.variationContainer }>
      <Box>
        <Grid direction='row' className={ classes.variationHeader }>
          <ButtonBase onClick={ handleExpand } className={ classes.collapseHeader } disableRipple>
            <Box style={ { display: 'flex', width: '100%' } }>
              <span { ...dragHandleProps } className={classes.dragHandle}>
              <SvgIcon className={classes.dragDots}>
                <DragIndicator/>
              </SvgIcon>
                </span>
              <Box className={ classes.variationIndexContainer }>
                <Typography variant='h4' className={ classes.variationIndex }>{ index + 1 }</Typography>
              </Box>
              <Typography variant='h4' className={ classes.variationTitle } id={`${type === 'custom' ? 'Question' : 'Variation'}${index + 1}`}>{ variationTitle() }</Typography>
            </Box>
            <SvgIcon onClick={ handleExpand } className={ classes.expandIcon }>
              { expanded ? <ChevronUpIcon/> : <ChevronDownIcon/> }
            </SvgIcon>
          </ButtonBase>
        </Grid>
        <Collapse in={ expanded } timeout='auto' unmountOnExit>
          <Divider/>
          <Grid className={ classes.variationContent }>
            { isCustom ? (
              <>
                <Typography variant="subtitle2">Title<span className={ classes.decorator }>*</span></Typography>
                <Grid style={ { marginTop: '-17px' } }>
                  <Input 
                    value={ variation.name } 
                    type="text" 
                    name={ `variations[${ index }].name` } 
                    error={checkVariationErrors('name')}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Typography variant="subtitle2">Title<span className={ classes.decorator }>*</span></Typography>
                <Grid style={ { marginTop: '-17px' } }>
                  <Input
                    type="text"
                    name={ `variations[${ index }].name` }
                    error={checkVariationErrors('name')}
                  />
                </Grid>
              </>
            ) }

            <FormControl variant="outlined" className={ classes.formControl }>
              <Typography variant="subtitle2">
                { isCustom ? 'Question' : 'Variation' } Type<span className={ classes.decorator }>*</span>
              </Typography>
              <Select
                value={ typeValue }
                onChange={ handleTypeChange }
                style={ {
                  height: '40px',
                } }
                placeholder="Customers can select only one option"
              >
                { typeOptions.map((option, index) => {
                  if (type === 'standard' && option.value === 'photo') {
                    return null;
                  }
                  ;
                  return (
                    <MenuItem key={ index } value={ option.value }>
                      { option.label }
                    </MenuItem>
                  );
                }) }
              </Select>
              <Typography className={ classes.subLabel }>
                { helperText[typeValue] }
              </Typography>
            </FormControl>
            <Grid>
              { variation.type != 'photo' ? (
                <>
                  {type === 'standard' && (
                    <FormControl variant="outlined" className={ classes.formControl }>
                      <Typography variant="subtitle2">Category<span className={ classes.decorator }>*</span></Typography>
                      <Select
                        placeholder="Select a category"
                        value={ nameValue }
                        onChange={ handleNameChange }
                        style={ {
                          height: '40px',
                        } }
                      >
                        { nameOptions.map(option => (
                          <MenuItem key={ option } value={ option }>
                            { option }
                          </MenuItem>
                        )) }
                      </Select>
                    </FormControl>
                  )}
                  {isMobile && (type !== 'custom') ?
                    <Grid container style={{marginTop: '21px'}}>
                      <Typography variant="subtitle2">
                        {isText ? 'Placeholder Text' : 'Options'}
                        {!isText && <span className={classes.decorator}>*</span>}
                      </Typography>
                      {variation.values.map((value, i) => {
                        return (
                          <>
                            <Grid container key={value.id} className={!isText && classes.optionContainer}>
                              {!isText && (
                                <Grid container item alignItems="center" style={{marginBottom: '8px'}}>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle2">Option {i + 1}</Typography>
                                  </Grid>
                                {(i !== 0 || variation.values.length > 1) && (
                                  <Grid container item xs={6} justify="flex-end">
                                    <IconButton onClick={() => deleteVariationValue(value.id)}>
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Grid>
                                )}
                                </Grid>
                              )}
                              <Grid container item>
                                <Grid item xs={12} className={!isText && classes.optionRow}>
                                  <Input
                                    value={value.name}
                                    placeholder={placeholders && placeholders[value.id]}
                                    type="text"
                                    name={`variations[${index}].values[${i}].name`}
                                    style={{margin: isText && '-10px 0px'}}
                                    error={checkVariationValuesErrors('name', i)}
                                  />
                                </Grid>
                                {isText && (
                                  <Grid item xs={12}>
                                    <Typography className={classes.subLabel}>What customers see before providing a response.</Typography>
                                  </Grid>
                                )}
                              </Grid>
                              <Grid container item spacing={1} className={classes.optionRow}>
                                <Grid item xs={6}>
                                  <PriceInput
                                    name={`variations[${index}].values[${i}].cost`}
                                    placeholder="0.00"
                                    hideArrows
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Input
                                    disabled={true}
                                    value={`$${((values.price || 0) + (value.cost || 0)).toFixed(2)}`}
                                    className={classes.totalPriceContainer}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container item>
                                <Grid item xs={6}>
                                  <Typography variant="caption">Add’l Cost</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="caption">Total Cost</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  : <Grid container style={{marginTop: '21px'}}>
                    <Grid container alignItems="center" spacing={1} className={classes.optionRow}>
                      <Grid item xs>
                        <Typography variant="subtitle2">
                          {isText ? 'Placeholder Text' : 'Options'}
                          {!isText && <span className={classes.decorator}>*</span>}
                        </Typography>
                      </Grid>
                      {(type !== 'custom') && (
                        <>
                          <Grid item className={classes.priceContainer}>
                            <Typography variant="subtitle2">Add’l Cost</Typography>
                          </Grid>
                          <Grid item className={classes.priceContainer}>
                            <Typography variant="subtitle2">Total Cost</Typography>
                          </Grid>
                          {/* empty container to align Add'l Cost and Total Cost headers */}
                          {(variation.values.length > 1) &&
                            <Grid item className={classes.closeIcon}>
                            </Grid>
                          }
                        </>
                      )}
                    </Grid>
                    {variation.values.map((value, i) => {
                      return (
                        <>
                          <Grid container key={value.id} alignItems="center" spacing={1} className={classes.optionRow}>
                            <Grid item xs>
                              <Input
                                value={ value.name }
                                placeholder={ placeholders && placeholders[value.id] }
                                type="text"
                                name={`variations[${index}].values[${i}].name`}
                                error={checkVariationValuesErrors('name', i)}
                              />
                            </Grid>
                            {type !== 'custom' && (
                              <>
                                <Grid item className={classes.priceContainer}>
                                  <PriceInput
                                    name={`variations[${index}].values[${i}].cost`}
                                    placeholder="0.00"
                                  />
                                </Grid>
                                <Grid item className={classes.priceContainer}>
                                  <Input
                                      disabled={true}
                                      value={`$${((values.price || 0) + (value.cost || 0)).toFixed(2)}`}
                                      className={classes.totalPriceContainer}
                                    />
                                </Grid>
                              </>
                            )}
                            {(i !== 0 || variation.values.length > 1) && (
                              <Grid item className={classes.closeIcon}>
                                <IconButton onClick={() => deleteVariationValue(value.id)}>
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                          {isText && (<Typography className={classes.subLabel} style={{ margin: '0 0 24px 0' }}>What customers see before providing a response.</Typography>)}
                        </>
                      );
                    })}
                    </Grid>}
                  {!isText && (
                    <Button onClick={addOptionValue} fullWidth={isMobile} color="primary" className={classes.addOption}>
                      Add Option
                    </Button>
                  ) }
                </>
              ) : (
                <Grid container alignItems='center' justify='space-between'>
                  <Grid item xs={6} sm={7}>
                    <Typography variant='subtitle2'>Photo Notes</Typography>
                    <Typography variant='h6' className={classes.photoSubLabel}>
                      A field for notes is provided for each photo uploaded.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        defaultValue='optional'
                        value={variation.notesRequired ? 'required' : 'optional'}
                        onChange={(e) => setFieldValue(`variations[${ index }].notesRequired`, e.target.value === 'required' ? true : false)}
                      >
                        <MenuItem key={ 'optional' } value={ 'optional' }>
                          Optional
                        </MenuItem>
                        <MenuItem key={ 'required' } value={ 'required' }>
                          Required
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider/>
            <Grid className={ classes.variationActions }>

              <IconButton style={ { marginRight: 16 } } focusRipple
                          onClick={ () => deleteVariation(variation.id) }>
                <SvgIcon className={ classes.deleteButton }>
                  <TrashIcon/>
                </SvgIcon>
              </IconButton>

              <Divider orientation='vertical' flexItem/>

              <Grid className={ classes.requiredSwitch }>
                <FormControl>
                  <CustomSwitch
                    checked={ variation.required }
                    onChange={ (e, checked) => setFieldValue(`variations[${ index }].required`, checked) }
                    name="Required Switch"
                  />
                </FormControl>

                <Typography variant='body4' style={ { marginLeft: 9 } }>Required</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Collapse>
      </Box>
    </Grid>
  );
};

export default SingleVariation;
