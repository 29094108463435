import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyOrdersIcon = (props: SvgIconProps) => (
  <SvgIcon width="189" height="104" viewBox="0 0 189 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_941_38511)">
      <path
        d="M70.3047 71.4695C73.2617 71.4695 75.6589 69.069 75.6589 66.108C75.6589 63.1469 73.2617 60.7465 70.3047 60.7465C67.3476 60.7465 64.9504 63.1469 64.9504 66.108C64.9504 69.069 67.3476 71.4695 70.3047 71.4695Z"
        fill="#005FFF"
      />
      <path
        d="M69.3136 68.3711L67.7112 66.308L68.643 65.5821L69.4019 66.559L71.9652 63.8493L72.8225 64.6626L69.3136 68.3711Z"
        fill="white"
      />
      <path
        d="M103.337 12.9886C105.724 12.9886 107.659 11.051 107.659 8.66095C107.659 6.27088 105.724 4.33334 103.337 4.33334C100.951 4.33334 99.0156 6.27088 99.0156 8.66095C99.0156 11.051 100.951 12.9886 103.337 12.9886Z"
        fill="#005FFF"
      />
      <path
        d="M113.318 5.63284C113.127 5.63284 112.944 5.70884 112.809 5.8441C112.674 5.97936 112.598 6.16282 112.598 6.35411C112.598 6.5454 112.674 6.72886 112.809 6.86413C112.944 6.99939 113.127 7.07538 113.318 7.07538H165.908C166.099 7.07493 166.282 6.99874 166.416 6.86352C166.551 6.72831 166.627 6.54511 166.627 6.35411C166.627 6.16311 166.551 5.97992 166.416 5.8447C166.282 5.70949 166.099 5.6333 165.908 5.63284H113.318Z"
        fill="#71717A"
      />
      <path
        d="M113.318 9.96045C113.127 9.96005 112.944 10.0357 112.809 10.1706C112.673 10.3056 112.597 10.4889 112.597 10.6802C112.596 10.8715 112.672 11.0551 112.807 11.1907C112.941 11.3262 113.124 11.4026 113.315 11.403H146.563C146.658 11.4031 146.752 11.3846 146.839 11.3484C146.927 11.3123 147.006 11.2591 147.073 11.1922C147.14 11.1252 147.193 11.0456 147.23 10.958C147.266 10.8704 147.285 10.7765 147.285 10.6817C147.285 10.5869 147.266 10.493 147.23 10.4054C147.193 10.3178 147.14 10.2383 147.073 10.1713C147.006 10.1043 146.927 10.0512 146.839 10.015C146.752 9.97883 146.658 9.96029 146.563 9.96045H113.318Z"
        fill="#71717A"
      />
      <path
        d="M172.619 13.0358C172.619 15.245 170.828 17.0358 168.619 17.0358H97.0234C94.8143 17.0358 93.0234 15.245 93.0234 13.0358V4C93.0234 1.79086 94.8143 0 97.0234 0H168.619C170.828 0 172.619 1.79086 172.619 4V13.0358ZM94.2386 12.819C94.2386 14.4759 95.5818 15.819 97.2386 15.819H168.404C170.061 15.819 171.404 14.4759 171.404 12.819V4.21685C171.404 2.55999 170.061 1.21685 168.404 1.21685H97.2386C95.5818 1.21685 94.2386 2.55999 94.2386 4.21685V12.819Z"
        fill="#ADCCFF"
      />
      <path
        d="M103.337 34.1404C105.724 34.1404 107.659 32.2028 107.659 29.8127C107.659 27.4227 105.724 25.4851 103.337 25.4851C100.951 25.4851 99.0156 27.4227 99.0156 29.8127C99.0156 32.2028 100.951 34.1404 103.337 34.1404Z"
        fill="#E4E4E7"
      />
      <path
        d="M113.318 26.9277C113.127 26.9277 112.944 27.0037 112.809 27.1389C112.674 27.2742 112.598 27.4576 112.598 27.6489C112.598 27.8402 112.674 28.0237 112.809 28.159C112.944 28.2942 113.127 28.3702 113.318 28.3702H165.908C166.099 28.3698 166.282 28.2936 166.416 28.1583C166.551 28.0231 166.627 27.8399 166.627 27.6489C166.627 27.4579 166.551 27.2747 166.416 27.1395C166.282 27.0043 166.099 26.9281 165.908 26.9277H113.318Z"
        fill="#E4E4E7"
      />
      <path
        d="M113.318 31.2553C113.127 31.2548 112.944 31.3305 112.809 31.4654C112.673 31.6004 112.597 31.7837 112.597 31.975C112.596 32.1663 112.672 32.3499 112.807 32.4855C112.941 32.621 113.124 32.6974 113.315 32.6978H146.563C146.658 32.6979 146.752 32.6794 146.839 32.6432C146.927 32.6071 147.006 32.5539 147.073 32.487C147.14 32.42 147.193 32.3404 147.23 32.2528C147.266 32.1652 147.285 32.0713 147.285 31.9765C147.285 31.8817 147.266 31.7878 147.23 31.7002C147.193 31.6126 147.14 31.5331 147.073 31.4661C147.006 31.3991 146.927 31.346 146.839 31.3098C146.752 31.2736 146.658 31.2551 146.563 31.2553H113.318Z"
        fill="#E4E4E7"
      />
      <path
        d="M172.619 34.3306C172.619 36.5398 170.828 38.3306 168.619 38.3306H97.0234C94.8143 38.3306 93.0234 36.5398 93.0234 34.3306V25.2948C93.0234 23.0857 94.8143 21.2948 97.0234 21.2948H168.619C170.828 21.2948 172.619 23.0857 172.619 25.2948V34.3306ZM94.2386 34.1138C94.2386 35.7707 95.5818 37.1138 97.2386 37.1138H168.404C170.061 37.1138 171.404 35.7707 171.404 34.1138V25.5116C171.404 23.8548 170.061 22.5116 168.404 22.5116H97.2386C95.5818 22.5116 94.2386 23.8548 94.2386 25.5116V34.1138Z"
        fill="#E4E4E7"
      />
      <path
        d="M103.373 55.3589C105.76 55.3589 107.695 53.4214 107.695 51.0313C107.695 48.6412 105.76 46.7037 103.373 46.7037C100.986 46.7037 99.051 48.6412 99.051 51.0313C99.051 53.4214 100.986 55.3589 103.373 55.3589Z"
        fill="#E4E4E7"
      />
      <path
        d="M113.318 48.2225C113.127 48.2225 112.944 48.2985 112.809 48.4337C112.674 48.569 112.598 48.7524 112.598 48.9437C112.598 49.135 112.674 49.3185 112.809 49.4538C112.944 49.589 113.127 49.665 113.318 49.665H165.908C166.099 49.6646 166.282 49.5884 166.416 49.4531C166.551 49.3179 166.627 49.1347 166.627 48.9437C166.627 48.7527 166.551 48.5695 166.416 48.4343C166.282 48.2991 166.099 48.2229 165.908 48.2225H113.318Z"
        fill="#E4E4E7"
      />
      <path
        d="M113.318 52.5501C113.127 52.5496 112.944 52.6253 112.809 52.7602C112.673 52.8952 112.597 53.0785 112.597 53.2698C112.596 53.4611 112.672 53.6447 112.807 53.7803C112.941 53.9158 113.124 53.9922 113.315 53.9926H146.563C146.658 53.9927 146.752 53.9742 146.839 53.938C146.927 53.9019 147.006 53.8487 147.073 53.7818C147.14 53.7148 147.193 53.6352 147.23 53.5476C147.266 53.46 147.285 53.3661 147.285 53.2713C147.285 53.1765 147.266 53.0826 147.23 52.995C147.193 52.9074 147.14 52.8279 147.073 52.7609C147.006 52.6939 146.927 52.6408 146.839 52.6046C146.752 52.5684 146.658 52.5499 146.563 52.5501H113.318Z"
        fill="#E4E4E7"
      />
      <path
        d="M172.619 55.6255C172.619 57.8346 170.828 59.6255 168.619 59.6255H97.0234C94.8143 59.6255 93.0234 57.8346 93.0234 55.6255V46.5896C93.0234 44.3805 94.8143 42.5896 97.0234 42.5896H168.619C170.828 42.5896 172.619 44.3805 172.619 46.5896V55.6255ZM94.2386 55.4086C94.2386 57.0655 95.5818 58.4086 97.2386 58.4086H168.404C170.061 58.4086 171.404 57.0655 171.404 55.4086V46.8065C171.404 45.1496 170.061 43.8065 168.404 43.8065H97.2386C95.5818 43.8065 94.2386 45.1496 94.2386 46.8065V55.4086Z"
        fill="#E4E4E7"
      />
      <path
        d="M40.6602 63.6673C40.4787 63.4337 40.3506 63.1632 40.2848 62.8748C40.2191 62.5863 40.2173 62.2869 40.2795 61.9977C40.3418 61.7085 40.4667 61.4364 40.6453 61.2007C40.8239 60.9651 41.052 60.7715 41.3134 60.6336L40.8914 56.028L43.6238 55.0886L44.1286 61.6009C44.3308 62.056 44.3593 62.5697 44.2086 63.0444C44.0579 63.5191 43.7385 63.922 43.311 64.1767C42.8835 64.4314 42.3775 64.5203 41.8889 64.4264C41.4004 64.3325 40.9632 64.0624 40.6602 63.6673Z"
        fill="#FFB8B8"
      />
      <path
        d="M43.5936 37.2339C43.5936 37.2339 45.4687 33.2934 47.1349 34.6796C48.8011 36.0657 44.8506 47.717 44.8506 47.717L44.2497 59.0277L41.0831 58.7108L40.1877 47.691L43.5936 37.2339Z"
        fill="#E0ECFF"
      />
      <path d="M18.4419 95.532L20.7925 97.4374L29.2505 89.265L25.7813 86.4529L18.4419 95.532Z" fill="#FFB8B8" />
      <path
        d="M18.4635 94.2774L23.0929 98.0299L23.0931 98.03C23.8754 98.6643 24.3741 99.5838 24.4795 100.586C24.5849 101.589 24.2883 102.592 23.6549 103.376L23.5773 103.472L15.9978 97.3277L18.4635 94.2774Z"
        fill="#27272A"
      />
      <path d="M51.1107 100.821H54.1349L55.5737 89.1401L51.1101 89.1403L51.1107 100.821Z" fill="#FFB8B8" />
      <path
        d="M50.3391 99.8325L56.2951 99.8323H56.2953C57.3019 99.8323 58.2673 100.233 58.9791 100.946C59.6909 101.658 60.0908 102.625 60.0909 103.633V103.756L50.3393 103.757L50.3391 99.8325Z"
        fill="#27272A"
      />
      <path
        d="M56.4175 46.0597C56.4175 46.0597 62.0912 52.9762 60.8578 59.8927C59.6244 66.8092 58.6376 71.9966 58.6376 71.9966L56.6642 87.5587C56.6642 87.5587 57.4042 91.797 56.4175 92.8891C56.4175 92.8891 57.8976 95.4633 55.1841 96.4514C52.4705 97.4395 51.0064 96.11 51.0064 96.11C51.0064 96.11 49.0169 93.2402 50.2504 92.4991L50.7699 61.9588L31.009 87.8058C31.009 87.8058 30.6458 90.9498 28.1139 90.9834L25.0886 93.2402L21.635 91.264L23.3618 89.0408C23.3618 89.0408 22.3751 86.5707 24.5952 86.0766L37.4228 69.2794L40.6297 60.6337C40.6297 60.6337 42.3565 53.9642 47.0435 51.247V48.3558L56.4175 46.0597Z"
        fill="#27272A"
      />
      <path
        d="M46.6734 34.0793C47.4203 33.1954 48.4658 32.6174 49.6108 32.4555C50.7557 32.2937 51.9202 32.5591 52.8823 33.2014L53.0872 33.3382C55.2768 35.8212 56.7485 38.8554 57.3437 42.1138L58.2676 47.1713L46.6734 49.6862L43.9554 40.2994C43.6104 39.108 43.6934 37.8332 44.19 36.6967C44.6866 35.5602 45.5654 34.634 46.6734 34.0793V34.0793Z"
        fill="#E0ECFF"
      />
      <path
        d="M65.722 64.7425C65.4006 64.6373 65.1061 64.4628 64.8593 64.2313C64.6125 63.9998 64.4194 63.7169 64.2936 63.4026C64.1678 63.0883 64.1123 62.7502 64.1311 62.4121C64.1499 62.074 64.2425 61.7441 64.4024 61.4457L61.0579 57.3469L63.0496 54.7041L67.6916 60.5589C68.1744 60.8611 68.5297 61.3301 68.6902 61.8771C68.8507 62.4241 68.8053 63.0111 68.5625 63.5267C68.3197 64.0424 67.8965 64.451 67.3729 64.6751C66.8494 64.8992 66.262 64.9232 65.722 64.7425H65.722Z"
        fill="#FFB8B8"
      />
      <path
        d="M51.607 37.7846C51.607 37.7846 50.8669 32.8442 53.3338 33.0912C55.8007 33.3382 59.5009 46.9242 59.5009 46.9242L66.1614 58.0401L62.9545 59.7692L55.0606 49.8885L51.607 37.7846Z"
        fill="#E0ECFF"
      />
      <path
        d="M56.1244 30.5619H44.3775C44.1358 30.5617 43.9042 30.4654 43.7333 30.2943C43.5624 30.1232 43.4663 29.8913 43.4661 29.6493V24.5791C43.4661 22.7772 44.1809 21.0491 45.4533 19.775C46.7257 18.5009 48.4515 17.7851 50.2509 17.7851C52.0504 17.7851 53.7761 18.5009 55.0486 19.775C56.321 21.0491 57.0358 22.7772 57.0358 24.5791V29.6493C57.0355 29.8913 56.9394 30.1232 56.7686 30.2943C56.5977 30.4654 56.366 30.5617 56.1244 30.5619Z"
        fill="#27272A"
      />
      <path
        d="M51.6453 30.4439C54.3926 30.4439 56.6198 28.2138 56.6198 25.4627C56.6198 22.7117 54.3926 20.4816 51.6453 20.4816C48.898 20.4816 46.6709 22.7117 46.6709 25.4627C46.6709 28.2138 48.898 30.4439 51.6453 30.4439Z"
        fill="#FFB8B8"
      />
      <path
        d="M58.641 25.0861H52.3807L52.3165 24.0535L51.9956 25.0861H51.0316L50.9044 23.0394L50.2683 25.0861H48.4031V24.9847C48.4031 22.0212 50.5014 19.6103 53.0807 19.6103H53.9633C56.5426 19.6103 58.641 22.0212 58.641 24.9847V25.0861Z"
        fill="#27272A"
      />
      <path
        d="M52.6398 31.5084C52.5633 31.5083 52.4869 31.5024 52.4113 31.4908L44.96 30.3415V22.5699C44.96 21.776 45.2749 21.0147 45.8355 20.4533C46.3961 19.8919 47.1565 19.5765 47.9493 19.5765H53.1624L52.9594 19.7835C50.1339 22.664 52.2626 27.3347 53.7829 29.8624C53.8945 30.0441 53.9449 30.2569 53.9267 30.4694C53.9085 30.6819 53.8226 30.883 53.6818 31.043C53.5524 31.1919 53.3921 31.3106 53.2121 31.391C53.0322 31.4714 52.8368 31.5115 52.6398 31.5084Z"
        fill="#27272A"
      />
      <path
        d="M183.318 13.8721C186.275 13.8721 188.673 11.4717 188.673 8.5106C188.673 5.54953 186.275 3.14911 183.318 3.14911C180.361 3.14911 177.964 5.54953 177.964 8.5106C177.964 11.4717 180.361 13.8721 183.318 13.8721Z"
        fill="#005FFF"
      />
      <path
        d="M182.327 10.7738L180.725 8.71059L181.656 7.98474L182.415 8.96167L184.979 6.25195L185.836 7.06521L182.327 10.7738Z"
        fill="white"
      />
      <path
        d="M77.3677 104H0.202533C0.148818 104 0.0973029 103.979 0.0593205 103.941C0.0213381 103.903 0 103.851 0 103.797C0 103.743 0.0213381 103.692 0.0593205 103.654C0.0973029 103.616 0.148818 103.594 0.202533 103.594H77.3677C77.4214 103.594 77.473 103.616 77.5109 103.654C77.5489 103.692 77.5703 103.743 77.5703 103.797C77.5703 103.851 77.5489 103.903 77.5109 103.941C77.473 103.979 77.4214 104 77.3677 104Z"
        fill="#E0ECFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_38511">
        <rect width="188.673" height="104" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
