import React, {useEffect, useState} from 'react';
import { Grid, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { PageContainer, PageContent } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {ArtisanList, PackageList} from '.';
import { getCommunityPackagesAction } from '../../store/reducers/community';
import Spinner from '../Spinner';
import {TabContext, TabPanel} from "@material-ui/lab";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {FeatureFlag} from "@castiron/castiron-firebase";
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Layout/AvatarMenu';

const useStyles = makeStyles((theme: Theme) => ({
  listHeaderWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  inactiveTab: {
    color: theme.branding.gray[800],
    opacity: 1,
  },
  activeTab: {
    maxWidth: '48%',
    minWidth: 0,
  },
  tabPanel: {
  },
}));

type CommunityTab = 'products' | 'artisans';

// Main community wrapper
const Community: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { type: urlType } = useParams<{type: string}>();

  const type = (urlType === 'products' || urlType === 'artisans')
    ? urlType
    : 'products';

  const [tab, setTab] = useState<CommunityTab>(type);
  const { isCommunityPackagesLoading, shop } = useAppSelector(state => ({
    isCommunityPackagesLoading: state.community.loading,
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle('Community');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([]);
    }
  }, [isMobile]);

  // Get Community packages
  useEffect(() => {
    if (shop) {
      dispatch(getCommunityPackagesAction(shop.id));
    }
  }, [shop]);

  const onTabChange = (event, selectedTab: CommunityTab): void => {
    setTab(selectedTab);
    history.push(`/community/${selectedTab}`);
  };

  return (
    <PageContainer helmetTitle="Community | Castiron">
      <Spinner show={isCommunityPackagesLoading} />

      <PageContent direction="column">
        <FeatureFlag name="community_artisan_list" offState={PackageList}>
          <Grid item>
            <TabContext value={tab}>
              <Tabs
                variant={isMobile ? 'fullWidth' : 'standard'}
                indicatorColor="primary"
                onChange={onTabChange}
                value={tab}
                aria-label="view-standard-orders"
              >
                <Tab
                  fullWidth={isMobile}
                  role="button"
                  tabIndex={0}
                  id="standard-orders-tab"
                  aria-pressed={false}
                  className={`${tab !== 'products' && classes.inactiveTab} ${classes.activeTab}`}
                  value="products"
                  label="Products"
                />
                <Tab
                  fullWidth={isMobile}
                  role="button"
                  tabIndex={0}
                  id="custom-orders-tab"
                  aria-pressed={false}
                  className={`${tab !== 'artisans' && classes.inactiveTab}  ${classes.activeTab}`}
                  value="artisans"
                  label="Artisans"
                />
              </Tabs>
              <TabPanel className={classes.tabPanel} value="products">
                <PackageList/>
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="artisans">
                <ArtisanList/>
              </TabPanel>
            </TabContext>
          </Grid>
        </FeatureFlag>
      </PageContent>
    </PageContainer>
  );
};

export default Community;
