import React, {ReactNode, useContext, useEffect, useState} from "react";
import {User} from "../../models/User";
import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {Link, Logo, Text} from "@castiron/components";

import {FirebaseContext} from "@castiron/castiron-firebase";
import firebase from 'firebase/compat/app';
import {useHistory} from "react-router";

interface Props {
  user: User;
}

const useStyles = makeStyles((theme: Theme) => ({
  onboardingContainer: {},
  header: {
    backgroundColor: theme.branding.gray[800],
    display: 'flex',
    padding: 16,
    width: '100%',
  },
  headerText: {
    color: '#fff',
    fontSize: '.875em',
    fontWeight: 'bold',
  },
  logo: {
    '& path': {
      fill: '#fff', // logo text
    },
    '& #Group-3': {
      fill: '#fff', // logo pan outline
    },
  },
  content: {
    marginTop: '180px',
    textAlign: 'center'
  },
  title: {
    marginBottom: '24px'
  },
  resendBox: {
    marginTop: '24px',
    padding: '8px 24px 8px 24px',
    background: theme.branding.gray[200],
  }
}));

const RequireValidEmail: React.FC<Props> = (props: Props) => {
  const {user} = props;
  const classes = useStyles();
  const FirebaseApp = useContext(FirebaseContext);
  const [resendText, setResendText] = useState<ReactNode>();
  const history = useHistory();

  const onClick = () => {
    FirebaseApp.auth().currentUser.sendEmailVerification();
    setResendText(<Text variant="body2">Email sent, please check your inbox!</Text>);
  }

  const linkText = <Text variant="body2">Didn't get the email? <Link onClick={onClick} underline={true} notBlue={true} >Re-send verification</Link></Text>;

  useEffect(() => {
    const poll = setInterval(() => {
      FirebaseApp.auth().currentUser.reload().then(() => {
        const user = FirebaseApp.auth().currentUser;
        console.debug(`User Verified? [${user.emailVerified}]`);
        if (user.emailVerified) {
          history.push('/');
        }
      });
    }, 10000);
    setResendText(linkText);

    return () => {
      clearInterval(poll);
    }
  }, []);

  return (
    <Grid className={classes.onboardingContainer} container direction="column" justify="center" alignContent="center">
      <Grid item className={classes.header} container component="header" justify="space-between" alignItems="center"
            xs={12}>
        <Logo width={144} className={classes.logo}/>
        <Typography className={classes.headerText} variant="h1" align="left">
          A Shop for Your Food Biz
        </Typography>
      </Grid>
      <Grid item container component="article" className={classes.content} xs={12} justify="center"
            alignContent="center" direction="column" alignItems="center">
        <Grid item className={classes.title}>
          <Text variant="h4">Verify your email</Text>
        </Grid>
        <Grid item>
          <Text variant="body1">
            We've sent a verification email to you at
            <br/>
            <Text bold>{user.email}</Text>
            Click the link in the email and you're all set!
          </Text>
        </Grid>
        <Grid item className={classes.resendBox}>
          {resendText}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RequireValidEmail;
