import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const DeliveryTruckIcon = (props: SvgIconProps) => (
  <SvgIcon width="249" height="104" viewBox="0 0 249 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M195.104 48.2305H198.449V42.789H195.104V48.2305Z" fill="#E0ECFF" />
    <path
      d="M21.0343 81.7257L17.8047 62.8664C16.3697 54.4862 22.5655 46.7315 31.0474 46.2917L77.0875 43.9046L88.0754 25.7752C89.5321 23.3718 92.1366 21.9041 94.9448 21.9041H128.026C130.626 21.9041 132.823 23.8344 133.161 26.4156L134.001 40.9219L216.476 41.1691L226.851 75.3978L217.22 74.9277L217.714 85.31L205.121 85.8044L200.923 73.9212C200.132 71.6836 196.668 69.1606 193.068 67.0497C184.996 62.3175 174.627 63.8363 168.518 70.928C167.078 72.601 166.07 74.3441 165.789 75.9785L163.883 87.0404L71.5315 85.5572L59.4279 68.6079C55.852 64.7123 38.9713 63.2813 37.4511 68.6079L28.3187 82.0965L21.0343 81.7257H21.0343Z"
      fill="#E0ECFF"
    />
    <rect x="155.06" y="35.5378" width="26.4177" height="4.58554" rx="1" fill="#ADCCFF" />
    <rect x="155.06" y="29.8059" width="26.4177" height="4.58554" rx="1" fill="#ADCCFF" />
    <rect x="155.06" y="24.074" width="26.4177" height="4.58554" rx="1" fill="#ADCCFF" />
    <rect x="182.626" y="35.5378" width="26.4177" height="4.58554" rx="1" fill="#ADCCFF" />
    <rect x="182.626" y="29.8059" width="26.4177" height="4.58554" rx="1" fill="#ADCCFF" />
    <path
      d="M121.41 32.9354L124.127 45.7585L89.5571 46.5001L96.8585 30.8067C97.5208 29.3831 98.9748 28.4992 100.542 28.5674L117.108 29.2884C119.205 29.3797 120.975 30.8799 121.41 32.9354V32.9354Z"
      fill="#F5F9FF"
    />
    <path
      d="M84.8656 45.5114L91.7797 27.7133L89.9695 25.9011L78.6924 43.0394L80.9838 46.5002L84.8656 45.5114Z"
      fill="#F5F9FF"
    />
    <path
      d="M183.885 103.355C192.886 103.355 200.182 96.0508 200.182 87.0403C200.182 78.0298 192.886 70.7253 183.885 70.7253C174.884 70.7253 167.587 78.0298 167.587 87.0403C167.587 96.0508 174.884 103.355 183.885 103.355Z"
      fill="#ADCCFF"
    />
    <path
      d="M172.649 87.0404C172.649 80.8385 177.69 75.793 183.885 75.793C190.08 75.793 195.12 80.8385 195.12 87.0404C195.12 93.2423 190.08 98.2879 183.885 98.2879C177.69 98.2879 172.649 93.2423 172.649 87.0404ZM172.896 87.0404C172.896 93.1059 177.826 98.0407 183.885 98.0407C189.944 98.0407 194.873 93.1059 194.873 87.0404C194.873 80.9749 189.944 76.0402 183.885 76.0402C177.826 76.0402 172.896 80.9749 172.896 87.0404Z"
      fill="#F5F9FF"
    />
    <path
      d="M174.254 87.0403C174.254 92.3647 178.566 96.681 183.885 96.681C189.203 96.681 193.515 92.3647 193.515 87.0403C193.515 81.7159 189.203 77.3997 183.885 77.3997C178.566 77.3997 174.254 81.7159 174.254 87.0403ZM190.145 84.7317L188.438 85.845L187.783 84.8381L190.145 84.7317ZM187.547 90.3937L188.001 89.2812L189.432 91.1653L187.547 90.3937ZM183.178 94.209L184.379 92.1701V94.209H183.178ZM183.178 82.4049L184.379 80.366V82.4049H183.178ZM177.843 91.1653L179.274 89.2812L179.728 90.3937L177.843 91.1653ZM177.131 84.7317L179.493 84.8381L178.837 85.845L177.131 84.7317Z"
      fill="#E0ECFF"
    />
    <path
      d="M47.085 103.355C56.0858 103.355 63.3824 96.0508 63.3824 87.0403C63.3824 78.0298 56.0858 70.7253 47.085 70.7253C38.0842 70.7253 30.7876 78.0298 30.7876 87.0403C30.7876 96.0508 38.0842 103.355 47.085 103.355Z"
      fill="#ADCCFF"
    />
    <path
      d="M35.8501 87.0404C35.8501 80.8385 40.8902 75.793 47.0854 75.793C53.2806 75.793 58.3208 80.8385 58.3208 87.0404C58.3208 93.2423 53.2806 98.2879 47.0854 98.2879C40.8902 98.2879 35.8501 93.2423 35.8501 87.0404ZM36.097 87.0404C36.097 93.1059 41.0264 98.0407 47.0854 98.0407C53.1445 98.0407 58.0738 93.1059 58.0738 87.0404C58.0738 80.9749 53.1445 76.0402 47.0854 76.0402C41.0264 76.0402 36.097 80.9749 36.097 87.0404Z"
      fill="#F5F9FF"
    />
    <path
      d="M37.4551 87.0403C37.4551 92.3647 41.7667 96.681 47.0854 96.681C52.404 96.681 56.7157 92.3647 56.7157 87.0403C56.7157 81.7159 52.404 77.3997 47.0854 77.3997C41.7667 77.3997 37.4551 81.7159 37.4551 87.0403ZM53.3454 84.7317L51.6391 85.845L50.9835 84.8381L53.3454 84.7317ZM50.7477 90.3937L51.2021 89.2812L52.6329 91.1653L50.7477 90.3937ZM46.3786 94.209L47.5792 92.1701V94.209H46.3786ZM46.3786 82.4049L47.5792 80.366V82.4049H46.3786ZM41.044 91.1653L42.4747 89.2812L42.9291 90.3937L41.044 91.1653ZM40.3315 84.7317L42.6933 84.8381L42.0378 85.845L40.3315 84.7317Z"
      fill="#E0ECFF"
    />
    <path d="M115.115 52.3093H124.498V50.3318H115.115V52.3093Z" fill="#E0ECFF" />
    <path
      d="M0 103.447C0 103.61 0.22848 103.741 0.513 103.741H245.411C245.696 103.741 245.924 103.61 245.924 103.447C245.924 103.284 245.696 103.153 245.411 103.153H0.513C0.22848 103.153 0 103.284 0 103.447Z"
      fill="#E0ECFF"
    />
    <path d="M124.438 101.589L127.867 101.589L129.498 88.3503L124.438 88.3507L124.438 101.589Z" fill="#FFB6B6" />
    <path
      d="M136.185 101.512C136.292 101.692 136.348 102.272 136.348 102.482C136.348 103.125 135.827 103.647 135.184 103.647L124.566 103.647C124.128 103.647 123.773 103.291 123.773 102.852V102.409C123.773 102.409 123.247 101.079 124.329 99.4401C124.329 99.4401 125.673 100.724 127.681 98.7132L128.273 97.6392L132.56 100.778L134.937 101.071C135.456 101.135 135.917 101.061 136.185 101.512H136.185L136.185 101.512Z"
      fill="#27272A"
    />
    <path d="M109.034 101.589L112.462 101.589L114.093 88.3503L109.033 88.3507L109.034 101.589Z" fill="#FFB6B6" />
    <path
      d="M120.78 101.512C120.887 101.692 120.944 102.272 120.944 102.482C120.944 103.125 120.423 103.647 119.78 103.647L109.162 103.647C108.724 103.647 108.368 103.291 108.368 102.852V102.409C108.368 102.409 107.843 101.079 108.924 99.4401C108.924 99.4401 110.268 100.724 112.277 98.7132L112.869 97.6392L117.156 100.778L119.532 101.071C120.052 101.135 120.513 101.061 120.78 101.512H120.78L120.78 101.512Z"
      fill="#27272A"
    />
    <path d="M123.895 11.192L124.546 17.2735L116.953 16.6219L118.254 10.9749L123.895 11.192Z" fill="#FFB6B6" />
    <path
      opacity="0.1"
      d="M123.895 11.192L124.546 17.2735L116.953 16.6219L118.254 10.9749L123.895 11.192Z"
      fill="#27272A"
    />
    <path
      d="M121.183 11.9521C123.939 11.9521 126.173 9.71556 126.173 6.95664C126.173 4.19772 123.939 1.96118 121.183 1.96118C118.427 1.96118 116.193 4.19772 116.193 6.95664C116.193 9.71556 118.427 11.9521 121.183 11.9521Z"
      fill="#FFB6B6"
    />
    <path
      d="M124.437 14.7757L132.79 19.6626L130.054 32.9114L131.922 50.7213L130.621 94.3773L122.593 93.5085L121.508 56.3683L115.217 94.8117H107.421L108.708 53.3276L112.613 35.7349L110.226 16.6218L117.495 14.3413L124.437 14.7757Z"
      fill="#003080"
    />
    <path
      d="M124.763 8.36844C124.763 8.36844 125.197 6.84808 126.173 6.95668C127.15 7.06528 127.367 6.1965 127.15 5.76212C126.933 5.32773 126.499 3.15579 126.499 3.15579C126.499 3.15579 126.716 1.63543 125.414 1.41824C124.112 1.20104 123.678 0.983849 123.461 0.549462C123.244 0.115073 119.99 -0.319313 118.905 0.332268C117.82 0.983849 116.953 2.234 116.193 2.5863C115.434 2.9386 114.783 3.59018 115.217 4.67615C115.651 5.76212 116.28 7.88604 116.28 7.88604C116.28 7.88604 117.387 7.49967 117.603 8.15125C117.82 8.80283 116.953 7.93405 118.037 6.1965C119.122 4.45895 119.122 2.7214 120.858 3.37298C122.594 4.02457 124.546 3.80737 124.329 5.11053C124.112 6.4137 124.763 8.36844 124.763 8.36844L124.763 8.36844Z"
      fill="#27272A"
    />
    <path
      d="M128.483 46.8251C127.439 47.932 127.163 49.3687 127.867 50.0339C128.57 50.6991 129.987 50.3409 131.032 49.2334C131.455 48.7968 131.768 48.265 131.944 47.6825L136.309 42.9365L134.041 40.9331L129.978 45.8246C129.407 46.0338 128.894 46.3768 128.483 46.8251Z"
      fill="#FFB6B6"
    />
    <path
      d="M132.703 19.554L140.054 32.0926L141.143 37.8167L134.064 46.8118L131.069 42.9957L134.609 37.8167L128.891 27.7313L132.703 19.554Z"
      fill="#003080"
    />
    <path
      d="M114.317 44.0016C115.361 45.1085 115.637 46.5452 114.933 47.2104C114.23 47.8756 112.813 47.5174 111.768 46.41C111.345 45.9733 111.032 45.4415 110.856 44.8591L106.491 40.113L108.759 38.1096L112.822 43.0011C113.393 43.2103 113.906 43.5534 114.317 44.0016Z"
      fill="#FFB6B6"
    />
    <path
      d="M110.098 16.7305L102.746 29.2691L101.657 34.9932L108.736 43.9883L111.731 40.1722L108.192 34.9932L113.91 24.9078L110.098 16.7305Z"
      fill="#003080"
    />
    <rect x="114.859" y="38.9771" width="17.2289" height="10.3175" rx="1" fill="#005FFF" />
    <path
      d="M121.751 38.9771H125.197V41.8397C125.197 42.7912 124.425 43.5626 123.474 43.5626C122.522 43.5626 121.751 42.7912 121.751 41.8397V38.9771Z"
      fill="white"
    />
  </SvgIcon>
);
