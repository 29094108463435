import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PastriesIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.25 5.82318C23.25 4.53041 22.6439 3.38973 21.5076 2.55323C19.6894 1.26046 16.5076 0.576045 12.1136 0.5H11.6591C2.79544 0.5 0.901505 3.23764 0.825747 5.59505V5.67109V5.74714C0.825747 8.56082 11.5833 8.71291 12.0379 8.71291H12.5682C15.2197 8.71291 17.7197 8.48477 19.6136 8.10455C22.0379 7.64827 23.25 6.96387 23.25 5.97527V5.89923C23.2121 5.89923 23.2121 5.89923 23.2216 5.88972C23.2311 5.88022 23.25 5.86121 23.25 5.82318ZM22.5682 7.35665C22.1894 7.35665 21.9621 7.7133 21.9621 8.30772C21.6591 9.25878 18.0985 10.8043 11.9621 10.6854C5.82576 10.5665 2.26515 8.90213 1.96212 7.95107C1.96212 7.47554 1.65909 7 1.35606 7C1.05303 7 0.75 7.47554 0.75 7.95107C0.75 12.3497 11.5076 12.5875 11.9621 12.5875H12.2652C14.0076 12.5875 23.25 12.3497 23.25 8.30772C23.1742 7.83218 22.947 7.35665 22.5682 7.35665ZM21.9621 12.1827C21.9621 11.5883 22.1894 11.2317 22.5682 11.2317C22.947 11.2317 23.1742 11.7072 23.25 12.1827C23.25 16.2247 14.0076 16.4625 12.2652 16.4625H11.9621C11.5076 16.4625 0.75 16.2247 0.75 11.8261C0.75 11.3505 1.05303 10.875 1.35606 10.875C1.65909 10.875 1.96212 11.3505 1.96212 11.8261C2.26515 12.7771 5.82576 14.4415 11.9621 14.5604C18.0985 14.6793 21.6591 13.1338 21.9621 12.1827Z"
      fill="#004CCC"
    />
  </SvgIcon>
);
