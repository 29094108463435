import React from 'react';
import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import { SvgIcon } from '@castiron/components';
import ChevronLeft from '../../assets/img/chevron-left.svg';

type Props = {
  label?: string;
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 32,
    width: 32,
    marginLeft: -10,
  },
  backButton: {
    paddingRight: 16,
  },
}));

const MobileBackButton: React.FC<Props> = (props: Props) => {
  const { label, onClick } = props;
  const classes = useStyles();

  return (
    <ButtonBase onClick={onClick} className={classes.backButton} focusRipple>
      <SvgIcon className={classes.icon}>
        <ChevronLeft />
      </SvgIcon>
      <Typography>{label}</Typography>
    </ButtonBase>
  );
};

export default MobileBackButton;
