import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import _ from 'lodash';
import moment from 'moment';
import { BaseDocument, BaseRepository, Condition, FieldFunctions, Order } from '../base/repository';
import { Asset } from '../asset/model';
import { Address } from '../address';
import { TakeRateLevel } from '../tier';
import dataOnly from '../_lib/dataOnly';
import { AvailabilitySubtype, CalendarEvent, CalendarEventType, FulfillmentOptionSchedule, ShopSeoMetadata } from './model';
import { CalendarEventRepositoryFactory } from './calendarEvent';
import { stripHtml } from '../_lib/textUtils';

const SITE_CONFIG_COLLECTION = 'site_configs';
const GALLERY_PHOTOS_COLLECTION = 'gallery_photos';
const FULFILLMENT_OPTIONS_COLLECTION = 'fulfillment_options';

export type FulfillmentType = 'pickup' | 'delivery' | 'shipping' | 'custom';

export enum ChecklistValues {
  Brand = 'BRAND',
  Customize = 'CUSTOMIZE',
  ProductAdded = 'PRODUCT_ADDED',
  FulfillmentAdded = 'FULFILLMENT_ADDED',
  StripeConnection = 'STRIPE_CONNECTION',
  SelectPlan = 'PLAN_SELECTED',
  GoLive = 'GO_LIVE',
  ShareShopLink = 'SHARE_SHOP_LINK',
  CustomerList = 'CUSTOMER_LIST',
  AnnounceNewShop = 'ANNOUNCE_NEW_SHOP',
  GalleryPhotoAdded = 'GALLERY_PHOTO_ADDED',
  VisitHelpCenter = 'VISIT_HELP_CENTER',
  JoinCommunity = 'JOIN_COMMUNITY',
  /* TODO: remove deprecated values after migration script is run */
  StoreName = 'STORE_NAME',
  SignUpPage = 'SIGN_UP_PAGE',
  ProfilePhoto = 'PROFILE_PHOTO',
  AboutInfo = 'ABOUT_INFO',
  Logo = 'LOGO',
  SocialLinks = 'SOCIAL_LINKS',
  AllergenInfo = 'ALLERGEN_INFO',
  EmailMarketing = 'EMAIL_MARKETING',
  EmailMarketingSingleSend = 'EMAIL_MARKETING_SINGLE_SEND',
  CouponCreate = 'COUPON_CREATE',
}

export interface Category {
  id: string;
  name: string;
}

export interface SubCategory {
  name: string;
}

export type Allergens =
  | 'wheat'
  | 'milk'
  | 'eggs'
  | 'fish'
  | 'shellfish'
  | 'treeNuts'
  | 'peanuts'
  | 'soyBeans'
  | 'sesame';

export interface SocialMediaInfo {
  facebookLink?: string;
  instagramLink?: string;
  tiktokLink?: string;
}

export interface Owner {
  firstName: string;
  lastName: string;
}

export interface PaymentSettings {
  takeRateLevels?: TakeRateLevel[];
  castironTakeRate: number;
  customerRate: number;
  isCustomerPayingStripeFee: boolean;
  taxRate?: number;
  paymentAccountId?: string;
}
export interface Banner {
  enabled: boolean;
  message: string;
}

export interface TippingPresets {
  tipPercentage: number;
  tipMultiplier: number;
}
export interface ShopConfig {
  banner?: Banner;
  timeZone?: string;
  tipping?: boolean;
  tippingPresetPercentages?: TippingPresets[];
}

export type MadeBadges = 'home' | 'commercial';
export type CertificationBadges = 'licensed-cotttage' | 'licensed-establishment' | 'certified-food-handler';
export type SpecialDietsBadges = 'gluten-free' | 'allergen-friendly' | 'plant-based';
export type MoreBadges = 'minority-owned' | 'woman-owned';
export type AwardBadge = '2022-food-entrepreneur' | '';

export interface Badges {
  made?: MadeBadges;
  certifications: CertificationBadges[];
  specialDiets: SpecialDietsBadges[];
  more: MoreBadges[];
  award: AwardBadge;
}

export interface ArtisanCategory {
  name: string;
  subcategories: string[];
}

export interface EditModeConfig {
  productFilterBy?: string;
}

export interface ShopSummary {
  id: string;
  businessName: string;
  owner: Owner;
  websiteUrl: string;
  logoImageObj?: Asset;
  photoGalleryImages?: Asset[];
  profileImageObj?: Asset;
  artisanCategory?: ArtisanCategory;
  fulfillmentTypes?: FulfillmentType[];
  location?: Address;
}

export interface FriendBuyIntegration {
  referralCode: string;
}

export interface ShopIntegrations {
  friendBuy?: FriendBuyIntegration;
}

export interface SiteConfig extends BaseDocument<ShopConfig> {
  title?: string;
  description?: string;
  keywords?: string;
  head?: string;
  bodyEnd?: string;
}

export interface GalleryPhoto extends BaseDocument<GalleryPhoto> {
  photo: Asset;
  caption?: string;
  category?: string;
  position?: number;
}

export const fulfillmentTypeDisplayName = (ffType: FulfillmentType, variant: 'long' | 'short' = 'long'): string => {
  switch (ffType) {
    case 'pickup':
      return variant === 'long' ? 'Local Pickup' : 'Pickup';
    case 'delivery':
      return variant === 'long' ? 'Local Delivery' : 'Delivery';
    case 'shipping':
      return 'Shipping';
    default:
      return null;
  }
};

export interface ProcessingTime {
  increments: number;
  incrementType: 'day' | 'week';
};

export interface FulfillmentOption extends BaseDocument<FulfillmentOption> {
  status?: 'active' | 'inactive';
  type: FulfillmentType;
  displayName: string;
  description: string;
  isExpired?: boolean;
  afterPurchaseDetails?: string;
  postalCode?: string;
  fee?: number;
  minimum?: number;
  date?: number; // legacy, needs deprecated
  fulfillmentNotes?: string;
  notes?: string;
  startDate?: number; // legacy, needs deprecated
  endDate?: number; // legacy, needs deprecated
  recipient?: string;
  address?: Address;
  processingTime?: ProcessingTime;
  schedule?: FulfillmentOptionSchedule;
  sendPickupReminderEmail?: boolean;
};

export type ShopStatus = 'active' | 'inactive' | 'deleted' | 'prelaunch';

export interface Shop extends BaseDocument<Shop> {
  status?: ShopStatus;
  owner?: Owner;
  websiteUrl: string;
  aboutTitle: string;
  allergens?: Allergens[];
  businessName: string;
  phoneNumber?: string;
  castIronCoverImage?: string;
  castIronCoverImageObj?: Asset;
  coverImage?: string;
  coverImageObj?: Asset;
  description?: string;
  email: string;
  socialMedia?: SocialMediaInfo;
  logo?: string;
  logoImageObj?: Asset;
  photoGalleryImages?: Asset[];
  profileImage?: string;
  profileImageObj?: Asset;
  useLogoAndName?: boolean;
  categories?: Category[];
  physicalAddress?: Address;
  canAcceptPayments?: boolean;
  productTypes?: string[];
  checklistCompletions?: ChecklistValues[];
  hasEverLaunched?: boolean;
  paymentSettings: PaymentSettings;
  config?: ShopConfig;
  badges?: Badges;
  artisanCategory?: ArtisanCategory;
  tags?: string[];
  editModeConfig?: EditModeConfig;
  integrations?: ShopIntegrations;
  statusAtCancel?: ShopStatus;
  seoMetadata?: ShopSeoMetadata;

  addToChecklist?: (checklistValue: ChecklistValues) => Promise<void>;

  setSiteConfig?: (siteConfig: SiteConfig) => Promise<void>;
  getSiteConfig?: () => Promise<SiteConfig>;

  getGalleryPhotos?: () => Promise<GalleryPhoto[]>;
  getGalleryPhotoById?: (id: string) => Promise<GalleryPhoto>;
  addGalleryPhoto?: (photo: GalleryPhoto) => Promise<GalleryPhoto>;
  updateGalleryPhoto?: (photoId: string, photoProps: Partial<GalleryPhoto> | Record<string, any>) => Promise<void>;
  deleteGalleryPhoto?: (id: string) => Promise<void>;

  getFulfillmentOptions?: () => Promise<FulfillmentOption[]>;
  getFulfillmentOptionById?: (id: string) => Promise<FulfillmentOption>;
  addFulfillmentOption?: (fulfillmentOption: FulfillmentOption) => Promise<FulfillmentOption>;
  updateFulfillmentOption?: (
    fulfillmentOptionId: string,
    fulfillmentOptionProps: Partial<FulfillmentOption>,
  ) => Promise<void>;
  deleteFulfillmentOptionProperties?: (fulfillmentOptionId: string, properties: string[]) => Promise<void>;
  deleteFulfillmentOption?: (id: string) => Promise<void>;

  findCalendarEvents?: (startTime: number, endTime: number) => Promise<CalendarEvent>;
  findCalendarEventsByType: (type: CalendarEventType, startTime: number, endTime: number) => Promise<void>;
  addCalendarAvailability: (subtype: AvailabilitySubtype, startTime: number, endTime: number) => Promise<void>;
}

export const toShopSummary = async (s: Shop) => {
  const fulfillmentOptions = await s.getFulfillmentOptions();
  return {
    id: s.id,
    businessName: s.businessName,
    websiteUrl: s.websiteUrl,
    owner: s.owner,
    logoImageObj: s.logoImageObj,
    photoGalleryImages: s.photoGalleryImages,
    profileImageObj: s.profileImageObj,
    artisanCategory: s.artisanCategory,
    fulfillmentTypes: _.uniq(fulfillmentOptions?.map(f => f.type)),
    location: s.physicalAddress,
  };
};

export const generateShopSeoMetadata = (shop: Shop): ShopSeoMetadata => {
  const shopCategory = shop.artisanCategory?.name;
  const shopLocation = shop.physicalAddress && {
    addressLocality: shop.physicalAddress.city,
    addressRegion: shop.physicalAddress.region,
    addressCountry: shop.physicalAddress.country,
    postalCode: shop.physicalAddress.postalCode,
  };

  let pageTitle = shop.businessName;
  if (shopCategory && shopLocation) {
    pageTitle = `${shopCategory} in ${shopLocation.addressLocality}, ${shopLocation.addressRegion} | ${shop.businessName}`;
  } else if (shopCategory) {
    pageTitle = `${shopCategory} | ${shop.businessName}`;
  } else if (shopLocation) {
    pageTitle = `${shop.businessName} | ${shopLocation.addressLocality}, ${shopLocation.addressRegion}`;
  }

  const sanitizedDesc = stripHtml(shop.description);

  return {
    address: shopLocation,
    shopTitle: pageTitle,
    shopDescription: sanitizedDesc,
    socialImage: shop.logoImageObj?.downloadUrl,
    socialPageTitle: pageTitle,
    socialPageDescription: sanitizedDesc,
  };
};

export interface ArtisanActiveShopsSearchLocation {
  region?: string;
  city?: string;
}

export interface ArtisanActiveShopsSearch {
  artisanCategories?: string[];
  fulfillment?: string[];
  region?: string[];
}

export class ShopRepository extends BaseRepository<Shop> {
  private calendarEventRepositoryFactory: CalendarEventRepositoryFactory;

  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'shops', fieldFunctions);
    this.calendarEventRepositoryFactory = new CalendarEventRepositoryFactory(firestore, fieldFunctions);
  }

  public async findByWebsiteUrl(websiteUrl: string): Promise<Shop | null> {
    const result = await this.find({
      where: [
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
        { field: 'websiteUrl', operator: '==', value: websiteUrl },
      ],
    });
    return this.firstOrNull(result);
  }

  public async findWebsiteUrlsStartsWith(startWith: string): Promise<Shop[]> {
    return this.findFieldStartsWith('websiteUrl', startWith, [
      { field: 'status', operator: 'in', value: ['active', 'inactive', 'prelaunch'] },
    ]);
  }

  public async findArtisanActiveShops(search: ArtisanActiveShopsSearch): Promise<Shop[]> {
    const where: Condition<Shop>[] = [{ field: 'status', operator: '==', value: 'active' }];

    if (search.region && search.region.length > 0) {
      where.push({ field: 'physicalAddress.region', operator: 'in', value: search.region });
    } else if (search.artisanCategories && search.artisanCategories.length > 0) {
      where.push({ field: 'artisanCategory.name', operator: 'in', value: search.artisanCategories });
    }

    let results = await this.find({
      where,
      orderBy: [{ field: 'businessName', direction: 'asc' }],
    });

    if (search.artisanCategories && search.artisanCategories.length > 0 && search.region && search.region.length > 0) {
      results = results.filter(s => search.artisanCategories.includes(s.artisanCategory?.name));
    }

    if (search.fulfillment && search.fulfillment.length > 0) {
      const resultPromises = results.filter(async s => {
        const fulfillmentOptions = await s.getFulfillmentOptions();
        return fulfillmentOptions.map(f => f.type).some(f => search.fulfillment.includes(f));
      });
      results = await Promise.all(resultPromises);
    }

    return results;
  }

  public async addChecklistCompletion(shop: Shop, checklistCompletion: ChecklistValues): Promise<void> {
    if (!shop.checklistCompletions?.includes(checklistCompletion)) {
      await this.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(checklistCompletion),
      });
    }
  }

  public async addTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayUnion(tags),
    });
    return {
      ...shop,
      tags: [...shop.tags, ...tags],
    };
  }

  public async removeTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayRemove(tags),
    });
    return {
      ...shop,
      tags: shop.tags.filter(t => !tags.includes(t)),
    };
  }

  public async getSiteConfig(shopId: string): Promise<SiteConfig> {
    return this.collection()
      .doc(shopId)
      .collection(SITE_CONFIG_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? undefined
          : {
              ...c.docs[0].data(),
              id: c.docs[0].id,
            },
      );
  }

  public async setSiteConfig(shopId: string, siteConfig: SiteConfig): Promise<void> {
    const createdAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(SITE_CONFIG_COLLECTION)
      .add({ ...siteConfig, createdAt })
      .then(() => {});
  }

  public async getGalleryPhotos(shopId: string): Promise<GalleryPhoto[]> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async getGalleryPhotoById(shopId: string, photoId: string): Promise<GalleryPhoto> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(photoId)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
  }

  public async addGalleryPhoto(shopId: string, photo: GalleryPhoto): Promise<GalleryPhoto> {
    const createdAt = moment().unix();
    const p = {
      ...photo,
      createdAt,
    };
    if (photo.id) {
      return this.collection()
        .doc(shopId)
        .collection(GALLERY_PHOTOS_COLLECTION)
        .doc(photo.id)
        .set(p)
        .then(() => ({
          ...p,
          id: photo.id,
        }));
    } else {
      return this.collection()
        .doc(shopId)
        .collection(GALLERY_PHOTOS_COLLECTION)
        .add(p)
        .then(doc => ({
          ...p,
          id: doc.id,
        }));
    }
  }

  public async updateGalleryPhoto(
    shopId: string,
    photoId: string,
    photoProps: Partial<GalleryPhoto> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(photoId)
      .update({ ...photoProps, updatedAt })
      .then(() => {});
  }

  public async deleteGalleryPhoto(shopId: string, id: string): Promise<void> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {});
  }

  public async getFulfillmentOptions(shopId: string): Promise<FulfillmentOption[]> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async getFulfillmentOptionById(shopId, fulfillmentId): Promise<FulfillmentOption> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentId)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
  }

  public async addFulfillmentOption(shopId: string, fulfillmentOption: FulfillmentOption): Promise<FulfillmentOption> {
    const createdAt = moment().unix();
    const f = {
      ...fulfillmentOption,
      createdAt,
    };
    if (fulfillmentOption.id) {
      return this.collection()
        .doc(shopId)
        .collection(FULFILLMENT_OPTIONS_COLLECTION)
        .doc(fulfillmentOption.id)
        .set(f)
        .then(() => ({
          ...f,
          id: fulfillmentOption.id,
        }));
    } else {
      return this.collection()
        .doc(shopId)
        .collection(FULFILLMENT_OPTIONS_COLLECTION)
        .add(f)
        .then(doc => ({
          ...f,
          id: doc.id,
        }));
    }
  }

  public async updateFulfillmentOption(
    shopId: string,
    fulfillmentOptionId: string,
    fulfillmentOptionProps: Partial<FulfillmentOption> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentOptionId)
      .update({ ...fulfillmentOptionProps, updatedAt })
      .then(() => {});
  }

  public async deleteFulfillmentOptionProperties(
    shopId: string,
    fulfillmentOptionId: string,
    properties: string[],
  ): Promise<void> {
    const propDeletes = _.assign({}, ...properties.map(prop => ({ [prop]: firebase.firestore.FieldValue.delete() })));
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentOptionId)
      .update({ ...propDeletes, updatedAt })
      .then(() => {});
  }

  public async deleteFulfillmentOption(shopId: string, id: string): Promise<void> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {});
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      addToChecklist(checklistValue: ChecklistValues) {
        return this._repository.addChecklistCompletion(this, checklistValue);
      },
      getSiteConfig() {
        return this._repository.getSiteConfig(this.id);
      },
      setSiteConfig(siteConfig: SiteConfig) {
        return this._repository.setSiteConfig(this.id, siteConfig);
      },
      getGalleryPhotos() {
        return this._repository.getGalleryPhotos(this.id);
      },
      getGalleryPhotoById(photoId: string): Promise<GalleryPhoto> {
        return this._repository.getGalleryPhotoById(this.id, photoId);
      },
      addGalleryPhoto(photo: GalleryPhoto): GalleryPhoto {
        return this._repository.addGalleryPhoto(this.id, photo);
      },
      updateGalleryPhoto(photoId: string, photoProps: Partial<GalleryPhoto> | Record<string, any>) {
        return this._repository.updateGalleryPhoto(this.id, photoId, photoProps);
      },
      deleteGalleryPhoto(id: string): Promise<void> {
        return this._repository.deleteGalleryPhoto(this.id, id);
      },
      getFulfillmentOptions() {
        return this._repository.getFulfillmentOptions(this.id);
      },
      getFulfillmentOptionById(id: string) {
        return this._repository.getFulfillmentOptionById(this.id, id);
      },
      addFulfillmentOption(fulfillmentOption: FulfillmentOption): FulfillmentOption {
        return this._repository.addFulfillmentOption(this.id, fulfillmentOption);
      },
      updateFulfillmentOption(
        fulfillmentOptionId: string,
        fulfillmentOptionProps: Partial<FulfillmentOption> | Record<string, any>,
      ) {
        return this._repository.updateFulfillmentOption(this.id, fulfillmentOptionId, fulfillmentOptionProps);
      },
      deleteFulfillmentOptionProperties(fulfillmentOptionId: string, properties: string[]) {
        return this._repository.deleteFulfillmentOptionProperties(this.id, fulfillmentOptionId, properties);
      },
      deleteFulfillmentOption(id: string): Promise<void> {
        return this._repository.deleteFulfillmentOption(this.id, id);
      },
      findCalendarEvents(startTime: number, endTime: number): Promise<CalendarEvent> {
        return this._repository.calendarEventRepositoryFactory.getRepository(this.id).findCalendarEvents(startTime, endTime);
      },
      findCalendarEventsByType(type: CalendarEventType, startTime: number, endTime: number): Promise<void> {
        return this._repository.calendarEventRepositoryFactory.getRepository(this.id).findCalendarEventsByType(type, startTime, endTime);
      },
      addCalendarAvailability(subtype: AvailabilitySubtype, startTime: number, endTime: number): Promise<void> {
        return this._repository.calendarEventRepositoryFactory.getRepository(this.id).addAvailability(subtype, startTime, endTime);
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export interface ShopRelatedDocument<T> extends BaseDocument<T> {
  shopId: string;
}

export class ShopRelatedRepository<T extends ShopRelatedDocument<T>> extends BaseRepository<T> {
  constructor(
    firestore: firebase.firestore.Firestore | _firestore.Firestore,
    collectionName: string,
    fieldFunctions?: FieldFunctions,
  ) {
    super(firestore, collectionName, fieldFunctions);
  }

  public async findByShopId(shopId: string, orderBy: Order<T>[] = []): Promise<T[]> {
    return await this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: '!=', value: 'deleted' }],
      orderBy,
    });
  }

  protected whereShopIs(shopId: string): Condition<T> {
    return { field: 'shopId', operator: '==', value: shopId };
  }
}
