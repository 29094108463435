import React, { useEffect, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Button, Input, Typography, WarningIcon } from '@castiron/components';
import { useTracking } from "@castiron/utils";
import { Box, Grid, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingStepFooter from './OnboardingStepFooter';
import { OnboardingInfo } from './Onboarding';

type Props = {
  progressStep: (values: OnboardingInfo) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 'inherit',
    padding: '32px',
    flexWrap: 'nowrap',
    maxHeight: 540,
    [theme.breakpoints.down('sm')]: {
      padding: '24px'
    },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: theme.branding.gray[500],
      '-webkit-box-shadow': `0 0 1px ${theme.branding.gray[100]}`
    },
  },
  active: {
    border: `2px solid ${theme.branding.blue.primary} !important`
  },
  button: {
    color: theme.branding.gray[800],
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
  },
  error: {
    backgroundColor: theme.branding.red.light,
  },
  errorInner: {
    display: 'flex',
    alignItems: 'center'
  },
  errorText: {
    color: theme.branding.red.dark,
  },
  footerContainer: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: '0 0 12px 12px',
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    marginTop: '10px',
    padding: 24,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      marginTop: '0px'
    },
  },
  link: {
    color: 'inherit',
  },
  formContainer: {
    border: `2px solid ${theme.branding.blue.primary}`,
    borderRadius: 8,
    padding: 15,
  },
  formWrapper: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    maxWidth: '588px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
  offerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '4px 8px 4px 8px',
    textAlign: 'center',
    width: 105,
    height: 24,
    background: theme.branding.blue.light,
    borderRadius: 15,
  },
  offerColor: {
    color: theme.branding.blue.dark,
  },
  optionButton: {
    height: 64,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 0,
    borderRadius: '8px',
    marginTop: 5,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      minWidth: 290,
    },
  },
  optionsHeader: {
    margin: '16px 0px',
  },
  subtitle: {
    color: theme.branding.gray[600],
  },
  warningIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '24.5px 0px 6px 24px'
  },
}));

const MigrationOffer: React.FC<Props> = (props: Props) => {
  const [migrate, setMigrate] = useState(false);
  const [buildMyself, setBuildMyself] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('Please select at least one option.');

  const { progressStep } = props;
  const { errors, touched, values, setFieldValue, setFieldTouched, submitForm, validateField }: any = useFormikContext();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const myRef = useRef(null);


  useEffect(() => {
    trackEvent('Migration Offered');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMigrateClick = () => {
    setError(false);
    setBuildMyself(false);
    setMigrate(true);
  };

  const handleBuildMyselfClick = () => {
    setError(false);
    setMigrate(false);
    setBuildMyself(true);
  };

  const executeScroll = () => myRef.current.scrollIntoView();

  const onContinue = () => {
    setError(false);
    if (!migrate && !buildMyself) {
      setError(true);
      executeScroll();
    }
    else if (migrate) {
      if (values.migrationInformation?.existingShopUrl ||
        values.migrationInformation?.instagramUrl ||
        values.migrationInformation?.facebookUrl) {
        submitForm();
      } else {
        setErrorText('Please provide at least one URL Existing Shop, Facebook, or Instagram');
        setError(true);
        executeScroll();
      }
    }
    else if (buildMyself) {
      setFieldTouched('businessName');
      setFieldTouched('name');
      if (
        values.businessName &&
        values.name &&
        !errors.businessName &&
        !errors.name &&
        !errors.migrationInformation?.existingShopUrl &&
        !errors.migrationInformation?.instagramUrl &&
        !errors.migrationInformation?.facebookUrl &&
        !errors.mobilePhone
      ) {
        window.localStorage.setItem('castironOnboardingForm', JSON.stringify(values));
        progressStep(values);
        trackEvent('Business Questions Captured', {
          ...values
        });
      } else {
        setErrorText('Please provide Shop Name and Your Name');
        setError(true);
        executeScroll();
      }
    } else {
      executeScroll();
    }
  };

  return (
    <Grid container className={classes.formWrapper}>
      <Grid className={classes.content}>
        <Grid xs={12}>
          <Box className={classes.optionsHeader}>
            <Grid className={classes.offerContainer} ref={myRef}>
              <Typography variant='caption' className={classes.offerColor}>One-Time Offer</Typography>
            </Grid>
            <Typography variant='h2'>Let us build your shop for you <span className={classes.offerColor}>(for free).</span></Typography>
            <Typography variant='body1' className={classes.subtitle}>We’ll take care of the details so you can focus on what you do best. Just answer a few questions and we’ll have your shop ready to begin accepting orders within a business day.</Typography>
          </Box>
        </Grid>
        <Box>
          {error && (
            <Box className={`${classes.optionButton} ${classes.error}`}>
              <Grid xs={12} direction='row' className={classes.errorInner}>
                <Grid className={classes.warningIcon}>
                  <WarningIcon htmlColor={theme.branding.red.dark} fontSize='large' />
                </Grid>
                <Grid>
                  <Typography variant='body2' className={classes.errorText}>{errorText}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {!migrate && (
            <Grid className={classes.buttonContainer} xs={12}>
              <Button className={classes.button} variant='outlined' fullWidth onClick={handleMigrateClick}>Yes, build my shop for me!</Button>
            </Grid>
          )}
          <Grid container style={{ flexGrow: 1 }} xs={12}>
            {migrate && (
              <Grid className={classes.formContainer}>
                <Typography variant='h4'>Yes, build my shop for me!</Typography>
                <Typography variant='body2'>Amazing! Fill in the form below and we’ll get cooking.</Typography>
                <Grid item xs={12}>
                  <Input
                    label='Business Name'
                    type='text'
                    name='businessName'
                    error={touched?.businessName && errors?.businessName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input label='Your Name' type='text' name='name' error={touched.name && errors.name} />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label='Existing Shop URL'
                    type='text'
                    name='migrationInformation.existingShopUrl'
                    error={touched.migrationInformation?.existingShopUrl && errors.migrationInformation?.existingShopUrl}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label='Instagram URL'
                    type='text'
                    name='migrationInformation.instagramUrl'
                    error={touched.migrationInformation?.instagramUrl && errors.migrationInformation?.instagramUrl}
                    placeholder='https://www.instagram.com/'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label='Facebook URL'
                    type='text'
                    name='migrationInformation.facebookUrl'
                    error={touched.migrationInformation?.facebookUrl && errors.migrationInformation?.facebookUrl}
                    placeholder='https://www.facebook.com/'
                  />
                </Grid>
                <Grid item xs={12} >
                  <Input type="tel" phoneMask={true} error={touched?.mobilePhone && errors?.mobilePhone} placeholder="(555) 555-5555" name="mobilePhone" label='Phone Number' opaque={true} />
                </Grid >
                <Grid item xs={12}>
                  <Typography variant="caption">By signing up via text, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. help setting up your store) from Castiron at the cell number used when signing up. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <a className={classes.link} target="_blank" href="https://www.castiron.me/terms-conditions">Terms</a> & <a className={classes.link} target="_blank" href="https://www.castiron.me/privacy-policy">Privacy</a>.</Typography>
                </Grid>
              </Grid>
            )}
            {!buildMyself && (
              <Grid className={classes.buttonContainer} xs={12}>
                <Button className={classes.button} variant='outlined' fullWidth onClick={handleBuildMyselfClick}>No thanks, I’ll build it on my own.</Button>
              </Grid>
            )}
            {buildMyself && (
              <Grid className={classes.formContainer}>
                <Typography variant='h4'>No thanks, I’ll build it on my own.</Typography>
                <Typography variant='body2'>Sounds great! We’ll still be available to support you along the way. What would you like us to call your shop (and you)?</Typography>
                <Grid item xs={12}>
                  <Input
                    label='Shop Name'
                    type='text'
                    name='businessName'
                    error={touched?.businessName && errors?.businessName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input label='Your Name' type='text' name='name' error={touched.name && errors.name} />
                </Grid>
                <Grid item xs={12} >
                  <Input type="tel" phoneMask={true} error={touched?.mobilePhone && errors?.mobilePhone} placeholder="(555) 555-5555" name="mobilePhone" label='Phone Number' opaque={true} />
                </Grid >
                <Grid item xs={12}>
                  <Typography variant="caption">By signing up via text, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. help setting up your store) from Castiron at the cell number used when signing up. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <a className={classes.link} target="_blank" href="https://www.castiron.me/terms-conditions">Terms</a> & <a className={classes.link} target="_blank" href="https://www.castiron.me/privacy-policy">Privacy</a>.</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box >
      </Grid >
      <Box className={classes.footerContainer}>
        <OnboardingStepFooter stepIndex={2} totalSteps={3} onContinueClick={onContinue} />
      </Box>
    </Grid >
  )
}

export default MigrationOffer;
