import React, { useEffect, useState } from 'react';
import { Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Button, Card, TextAreaInput } from '@castiron/components';
import { useAppSelector } from '../../../../../hooks';
import { FulfillmentOption, FulfillmentOptionSchedule, fulfillmentTypeDisplayName } from '@castiron/domain';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { EmptyTimePeriod } from '../index';
import DateTimePickers from './DateTimePickers';
import { formatTimeZone } from '@castiron/utils';

type Props = {
  fulfillmentType?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  addDateButton: {
    marginTop: 24,
    padding: 16,
  },
  recommendationText: {
    marginBottom: 24,
    fontWeight: 500,
  },
  timeZoneNote: {
    color: theme.branding.gray[500],

    '& button': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      padding: 0,
      margin: 0,
    },
  },
  title: {
    marginBottom: 6,
  },
  toggleButtonGroup: {
    maxHeight: 65,
    [theme.breakpoints.down(380)]: {
      maxHeight: '100%',
    },
  },
  toggleButton: {
    border: 'none',
    color: theme.branding.gray[800],
    fontSize: 16,
    width: '50%',

    '&.Mui-selected': {
      backgroundColor: theme.branding.blue.light,
      color: theme.branding.blue.primary,
    },

    '&.MuiToggleButton-root': {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
    },
  },
  toggleGroup: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 4,
    width: '100%',
  },
}));

const DateTimeOptions: React.FC<Props> = (props: Props) => {
  const { fulfillmentType } = props;
  const formik = useFormikContext<FulfillmentOption>();
  const classes = useStyles();

  const [viewType, setViewType] = useState('fixed');
  const [timeZone, setTimeZone] = useState('');
  const [dateTimeError, setDateTimeError] = useState(['']);
  const pageType = formik.values.type;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    if (formik.values.schedule.type) {
      setViewType(formik.values.schedule.type);
    }
    if (shop?.config?.timeZone) {
      setTimeZone(shop.config.timeZone);
    } else {
      // get the timezone from moment
      setTimeZone(moment.tz.guess());
    }
  }, []);

  useEffect(() => {
    if (formik.values.schedule.type === 'fixed' && dateTimeError.length > 0) {
      setTimeout(() => {
        setDateTimeError(['']);
      }, 5000);
    }
  }, [dateTimeError]);

  const timeZoneElement = (
    <Typography variant="body2" className={classes.timeZoneNote}>
      All options are in {formatTimeZone(timeZone)}.{' '}
      <Link href="/account-settings" target="_blank">
        Change
      </Link>
    </Typography>
  );

  const onButtonToggle = (event, selectedView: FulfillmentOptionSchedule['type']): void => {
    setViewType(selectedView);
    formik.setFieldValue('schedule.type', selectedView);
    formik.setFieldValue('sendPickupReminderEmail', (selectedView === 'fixed' ? true : false));
  };

  const handleAddDate = (): void => {
    formik.setFieldValue('schedule.dates', [...formik.values.schedule.dates, EmptyTimePeriod]);
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    formik.setFieldValue('schedule.description', e.target.value);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Card title={`${fulfillmentTypeDisplayName(pageType, 'short')} Options`} sideMessage={timeZoneElement}>
          <Grid container direction="column">
            <Grid item className={classes.toggleButtonGroup}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                onChange={onButtonToggle}
                size="large"
                value={viewType}
                className={classes.toggleGroup}
                aria-label={`${fulfillmentTypeDisplayName(pageType, 'short')} Options`}
              >
                <ToggleButton
                  color="primary"
                  value="fixed"
                  aria-label="Specific Date(s)"
                  role="button"
                  className={classes.toggleButton}
                >
                  Specific Date(s)
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="flexible"
                  aria-label="I'm Flexible"
                  role="button"
                  className={classes.toggleButton}
                >
                  I'm Flexible
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item className={classes.recommendationText}>
              <Typography variant="caption">
                {viewType === 'fixed' ? (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Recommended Option.</span> Specific dates integrate into your
                    calendar, enable reporting, and more.
                  </>
                ) : (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Not Recommended.</span> These orders will not appear on your
                    calendar or in reporting.
                  </>
                )}
              </Typography>
            </Grid>
            {formik.values.schedule.type === 'fixed' ? (
              <Grid container direction="column">
                <Typography variant="subtitle2" className={classes.title}>
                  Options
                </Typography>
                {formik.values.schedule?.dates?.length > 0 && (
                  <>
                    {formik.values.schedule?.dates?.map((date, index) => (
                      <DateTimePickers date={date} index={index} key={index} />
                    ))}
                  </>
                )}
                {formik.values.schedule?.dates?.some(date => moment.unix(date.endTime).isBefore(moment())) && (
                  <Grid item>
                    <Typography variant="body2">
                      Dates in the past will not show as available options at checkout.
                    </Typography>
                  </Grid>
                )}
                <Grid container item direction="column" justify="flex-start" md={4} xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleAddDate()}
                    className={classes.addDateButton}
                  >
                    Add Another Date
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column">
                <TextAreaInput
                  label="Availability"
                  name="schedule.description"
                  rowCount={4}
                  required={formik.values.schedule.type === 'flexible' ? true : false}
                  value={formik.values.schedule.description}
                  secondaryLabel={`Provide details on your availability to help coordinate a ${fulfillmentType} time. Please note: customers will be asked to submit preferred ${fulfillmentType} times based on your availability.`}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DateTimeOptions;
