import React, { useCallback, useEffect } from 'react';
import { Box, Button, Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { Card, ProductImage } from '@castiron/components';
import Close from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import { CustomProduct, InputField, SelectedInputFieldValue } from '@castiron/domain';
import _ from 'lodash';
import Dinero from 'dinero.js';
import { Formik, FormikProps } from 'formik';
import CustomerDetailsPreview from './CustomerDetailsPreview';
import FulfillmentDetailsPreview from './FulfillmentDetailsPreview';
import PoliciesPreview from './PoliciesPreview';
import VariationsPreview from './VariationsPreview';

export type Props = {
  show: boolean;
  product: CustomProduct;
  initialAmount: number;
  imageObj: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: '24px',
    minWidth: '45vw',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: '87vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '67vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    [theme.breakpoints.down('sm')]: {
      padding: 4,
      borderRadius: 4,
    },
  },
  footer: {
    backgroundColor: theme.branding.gray[100],
    bottom: '0',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    minHeight: 72,
    position: 'sticky',
    width: '100%',
    zIndex: 1000,
  },
  /* column names really only apply to desktop view */
  leftColumn: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '63px',
    },
  },
  rightColumn: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '30px',
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    },
  },
  productImageContainer: {
    maxHeight: '350px',
    width: '100%',
    position: 'relative',
    marginBottom: '23px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '252px',
    },
  },
  mobileImg: {
    width: '100%',
    maxHeight: '252px',
    objectFit: 'cover',
  },
  imgClass: {
    maxHeight: '350px',
  },
  productTitle: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 800,
  },
  startAndMinimum: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    marginTop: '8px',
  },
  description: {
    color: 'RGB(143, 143, 143)',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    marginTop: '24px',
    '& p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
}));

const CustomProductPreviewModal: React.FC<Props> = (props: Props) => {
  const { imageObj, show, product } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleClose = useCallback((): void => {
    if (shop && product) {
      dispatch(closeModal());
    }
  }, [shop, product]);

  const customPriceLabel = (product) => {
    const bothStartingPriceAndMinimumExist = !!product?.startingPrice.intValue && !!product?.minimum;
    const minimumDoesNotExist = !!product?.startingPrice.intValue && !product?.minimum;
    const startingPriceDoesNotExist = !product?.startingPrice.intValue && !!product?.minimum;

    const formattedStartsAtAmount = startingPrice => {
      return Dinero({
        amount: product?.startingPrice.intValue,
      }).toFormat('$0.00');
    };

    return bothStartingPriceAndMinimumExist
      ? `Starts at ${formattedStartsAtAmount(product.startingPrice.intValue)} • ${product.minimum} min`
      : minimumDoesNotExist
      ? `Starts at ${formattedStartsAtAmount(product.startingPrice.intValue)}`
      : startingPriceDoesNotExist
      ? `${product.minimum} min`
      : '';
  };

  // This allows us to use useFormikContext for preview inputs without running into undefined errors
  const initialValues = {
    fullName: '',
    email: '',
    mobileNumber: '',
    subscribed: true,
    type: '',
    date: 0,
    fullAddress: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    selectedFields: product?.variations?.map((variation: InputField): SelectedInputFieldValue => {
      return {
        inputFieldId: variation.id,
        inputFieldName: variation.name,
        selectedValues: [],
      };
    }) || [],
  };

  return !product ? (
    <></>
  ) : (
    <ModalWrapper fullWidth={isMobile} show={show} size="lg">
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {return;}}
      >
        {(formikProps: FormikProps<any>) =>
        <>
          <Grid className={classes.gridContainer} container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
            <Grid className={classes.leftColumn} container direction="column" item xs={12} md={4} wrap="nowrap">
              {product && (
                <>
                  {imageObj && (
                    <Grid container className={classes.productImageContainer}>
                      {isMobile ? (
                        <img className={classes.mobileImg} src={imageObj} />
                      ) : (
                        <ProductImage
                          alt={product?.title}
                          src={imageObj}
                          className={classes.imgClass}
                          width="100%"
                          height="auto"
                        />
                      )}
                    </Grid>
                  )}
                </>
              )}
              {!isMobile && <PoliciesPreview text={product.policies} />}
            </Grid>
            <Grid
              id="right-column"
              container
              item
              xs={12}
              md={8}
              direction="column"
              wrap="nowrap"
              className={classes.rightColumn}
            >
              <Typography className={classes.productTitle}>{product?.title}</Typography>
              {(!!product?.startingPrice || !!product?.minimum) && (
                <Typography className={classes.startAndMinimum}>{customPriceLabel(product)}</Typography>
              )}
              <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: product?.description }} />
              {isMobile && <PoliciesPreview text={product.policies} />}
              <CustomerDetailsPreview />
              <FulfillmentDetailsPreview />
              {product?.variations && product.variations.length > 0 && (
                <Card title="Request Details">
                  <Box>
                    {product.variations.map((variation: InputField, index: number) => (
                      <VariationsPreview product={product} key={variation.id} index={index} variation={variation} />
                    ))}
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.footer} justify='flex-end' alignItems='center'>
            <Button disabled={true} variant="contained" color="primary" style={{ marginRight: '64px' }}>
              Submit Request
            </Button>
          </Grid>
        </>
        }
      </Formik>
    </ModalWrapper>
  );
};

export default CustomProductPreviewModal;
