import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { HeaderText, Link, Logo, Text } from '@castiron/components';

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    height: '100vh',
  },
  content: {
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentText: {
    maxWidth: 740,
    textAlign: 'center',
    padding: '0px 16px',
  },
  header: {
    paddingTop: 16,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    maxWidth: 136,
    margin: '1em 0',
  },
}));

const ThanksNotUS: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <Grid className={classes.header} container>
        <div className={classes.logo}>
          <Logo width={144} />
        </div>
      </Grid>
      <Grid className={classes.content}>
        <HeaderText presetFont="heading--l" style={{ marginBottom: 8 }}>
          Thanks!
        </HeaderText>
        <Text presetFont="txt--m" className={classes.contentText}>
          We’ll let you know when Castiron makes it to your area. Until then, feel free to{' '}
          <Link underline href="https://www.castiron.me/blog" target="_blank">
            check out our blog
          </Link>{' '}
          for business tips and{' '}
          <Link underline href="https://www.castiron.me/community" target="_blank">
            join The Kitchen community
          </Link>{' '}
          to connect with other artisans.
        </Text>
      </Grid>
    </div>
  );
};

export default ThanksNotUS;
