import React, { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { FormControl, FormLabel, Grid, makeStyles, MenuItem, Select, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Theme } from '@material-ui/core/styles';
import { useAppSelector } from '../../../hooks';
import { useFormikContext } from 'formik';
import MomentUtils from '@date-io/moment';
import _ from "lodash";
import moment from "moment-timezone";
import { FrontendTransactionState, Order, ShippingInfo, TimePeriod, FulfillmentOptionSchedule } from '@castiron/domain';
import { AddressAutoComplete, Input, Typography, Button } from '@castiron/components';
import { defaultTimeZone } from '@castiron/utils';
import Tooltip from '../../Tooltip';

type Props = {
  order: Order;
  shippingInfo: ShippingInfo;
  isPaid: boolean;
  state: FrontendTransactionState;
};

const useStyles = makeStyles((theme: Theme) => ({
  addTime: {
    marginTop: 8,
    '& :hover': {
      cursor: 'pointer'
    },
    '& h6': {
      color: theme.branding.blue.primary,
      fontWeight: 600
    }
  },
  apply: {
    '& span': {
      color: theme.branding.blue.dark,
    },
    padding: 0
  },
  closeTime: {
    width: 20,
    height: 20,
    marginLeft: 6
  },
  disabled: {
    backgroundColor: theme.branding.gray[300],
    borderRadius: 12,
    '& .MuiInputBase-input.Mui-disabled:hover': {
      cursor: 'default'
    }
  },
  error: {
    textAlign: 'center',
  },
  formControl: {
    margin: '24px 0',
    width: '100%',
    '& label': {
      color: '#000',
      fontWeight: 700,
      fontSize: '14px',
      marginBottom: 0,
    }
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  hyphen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 4px 0px'
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
  },
  itemBoxInner: {
    padding: '18px 16px',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: 16,
  },
  pickerMargin: {
    marginTop: 4
  },
  requestedInformation: {
    backgroundColor: theme.branding.blue.light,
    padding: '8px 12px',
    borderRadius: '8px',
    width: '100%',
  },
  requestedValue: {
    fontWeight: 700
  },
  selectBox: {
    marginTop: '16px',
    marginBottom: '8px'
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  timePicker: {
    width: '100%',
    '& input:hover': {
      cursor: 'pointer',
    },
    '& .MuiTextField-root': {
      width: '100%',
      '& input:hover': {
        borderBottom: 'none',
      },
      '& input::placeholder': {
        color: theme.branding.blue.primary,
        opacity: 1,
      },
      '& input:disabled::placeholder': {
        color: theme.branding.gray[600],
      },
    },

    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  tooltip: {
    marginBottom: '-3px',
  },
  truncate: {
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all',
    }
  }
}));

const QuoteFulfillment: React.FC<Props> = (props: Props) => {
  const { order, isPaid, shippingInfo, state } = props;
  const { values, setFieldValue, errors, touched }: any = useFormikContext();
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState<string>('');
  const [fulfillmentTypes, setFulfillmentTypes] = useState<string[]>([]);
  const [fulfillmentName, setFulfillmentName] = useState<string>('Fulfillment');
  const [date, setDate] = useState<TimePeriod>(null);
  const [requestedType, setRequestedType] = useState<string>(order?.requestedFulfillment?.type);
  const [requestedDate, setRequestedDate] = useState<number>(order?.requestedFulfillment?.date || null);
  const [schedule, setSchedule] = useState<FulfillmentOptionSchedule>();
  const [dateTimeError, setDateTimeError] = useState('');
  const [day, setDay] = useState<moment.Moment | null>(null);


  const { shop, fulfillments } = useAppSelector(state => ({
    shop: state.shops.shop,
    fulfillments: state.shops.fulfillments,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  useEffect(() => {
    if (order?.fulfillmentOption) {
      setSelectedType(order.fulfillmentOption.type);
      setFulfillmentName(order.fulfillmentOption.type ? _.capitalize(order.fulfillmentOption.type) : 'Fulfillment');

      if (!_.isEmpty(order.fulfillmentOption.schedule?.dates)) {
        const date = order.fulfillmentOption.schedule.dates[0];

        setDate(date);
        setSchedule(order.fulfillmentOption.schedule);
      } else if (order.fulfillmentOption.date) {
        //if there is only a date (which reads as a start time), correctly format the start and end times so they're not random numbers
        const startTime = moment.unix(order.fulfillmentOption.date);
        startTime.hour(12);
        startTime.minute(0);
        startTime.second(0);
        const formattedStart = startTime.unix();
        const endTime = parseInt(startTime.add(30, 'minutes').format('X'));

        const date: TimePeriod = {
          id: nanoid(),
          startTime: formattedStart,
          endTime
        };

        const schedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: [date]
        };

        setDate(date);
        setSchedule(schedule);
        setFieldValue('order.fulfillmentOption.schedule', schedule);
      } else {
        const schedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: []
        };

        setSchedule(schedule);
        setFieldValue('order.fulfillmentOption.schedule', schedule);
      }
    }

    if (order?.requestedFulfillment) {
      setRequestedDate(order.requestedFulfillment.date);
      setRequestedType(order.requestedFulfillment.type);
    }
  }, [order]);

  useEffect(() => {
    if (shop) {
      setFulfillmentTypes(_.uniq(fulfillments.map(f => f.type) || []));
    }
  }, [shop]);

  useEffect(() => {
    date?.startTime ? setDay(moment.unix(date.startTime).tz(timeZone)) : null;
  }, [date]);

  const handleFulfillmentChange = (type, formikValues) => {
    setSelectedType(type);
    setFulfillmentName(type ? _.capitalize(type) : 'Fulfillment');
    /* shipping info has been pulled out of fulfillmentOption in data,
     * but is maintained in fulfillmentOption in formik form,
     * so set it explicitly here,
     * submission separates them
     */
    const fulfillment = {
      ...order.fulfillmentOption,
      status: order.fulfillmentOption?.status || 'active',
      type,
      /* additional hack to make the hack in edit quote to track user selection work */
      displayName: _.capitalize(type),
      fee: formikValues.fulfillmentOption.fee || 0,
      date: date?.startTime || formikValues.fulfillmentOption.date,
      schedule: schedule || formikValues.fulfillmentOption.schedule,
      notes: formikValues.fulfillmentOption.fulfillmentNotes || '',
      address: formikValues.fulfillmentOption.address,
    };

    setFieldValue('order.fulfillmentOption', fulfillment);
  };

  const handleDayChange = (day: moment.Moment | null) => {
    if (day && day.isValid()) {
      setDay(day);
      let newStartTime;
      let newEndTime;
      let newTimePeriod;

      // if a startTime exists, we're editing an existing date
      // merge the date and time
      if (date?.startTime) {
        newStartTime = moment.unix(date.startTime).tz(timeZone);
        newStartTime.year(day.year());
        newStartTime.month(day.month());
        newStartTime.date(day.date());
      } else {
        // if no startTime exists, we're adding a new date at 12:00pm
        newStartTime = moment(day);
        newStartTime.hour(12);
        newStartTime.minute(0);
        newStartTime.second(0);
        newStartTime.millisecond(0);
      }

      if (date?.endTime) {
        newEndTime = moment.unix(date.endTime).tz(timeZone);
        newEndTime.year(day.year());
        newEndTime.month(day.month());
        newEndTime.date(day.date());
      } else {
        newEndTime = moment(day);
        newEndTime.hour(12);
        newEndTime.minute(30);
        newEndTime.second(0);
        newEndTime.millisecond(0);
      }

      if (date?.startTime) {
        newTimePeriod = {
          id: date.id,
          startTime: newStartTime.tz(timeZone, true).unix(),
          endTime: newEndTime.tz(timeZone, true).unix(),
        };
      } else {
        newTimePeriod = {
          id: nanoid(),
          startTime: newStartTime.tz(timeZone, true).unix(),
          endTime: newEndTime.tz(timeZone, true).unix(),
        };
      }

      if (!schedule) {
        const newSchedule: FulfillmentOptionSchedule = {
          id: nanoid(),
          type: 'flexible',
          dates: [newTimePeriod]
        };

        setSchedule(newSchedule);
        setFieldValue('order.fulfillmentOption.schedule', newSchedule);
      } else {
        setSchedule({
          ...schedule,
          dates: [newTimePeriod]
        })
        setFieldValue('order.fulfillmentOption.schedule.dates[0]', newTimePeriod);
      }

      setDate(newTimePeriod);
      setFieldValue('order.fulfillmentOption.date', newTimePeriod.startTime);
    }
  };

  const handleTimeChange = (time: moment.Moment | null, field: string) => {
    if (time && time.isValid()) {
      const newTimePeriod = {
        id: date.id,
        startTime: date.startTime,
        endTime: date.endTime,
      };

      if (field === 'startTime') {
        const newStartTime = moment.unix(date.startTime);
        newStartTime.hour(time.hour());
        newStartTime.minute(time.minute());
        newStartTime.tz(timeZone, true);
        newTimePeriod.startTime = newStartTime.unix();
        day ? setDay(newStartTime) : null;

        /* make end time 30 mins after new start time */
        const newEndTime = newStartTime.add(30, 'minutes')
        newTimePeriod.endTime = newEndTime.unix();
      } else if (field === 'endTime') {
        const newEndTime = moment.unix(date.endTime);
        newEndTime.hour(time.hour());
        newEndTime.minute(time.minute());
        newEndTime.tz(timeZone, true);
        newTimePeriod.endTime = newEndTime.unix();
        day ? setDay(newEndTime) : null;
      }

      if (newTimePeriod.startTime < newTimePeriod.endTime) {
        setSchedule({
          ...schedule,
          dates: [newTimePeriod]
        })

        setDate(newTimePeriod);
        setDateTimeError('');

        setFieldValue('order.fulfillmentOption.date', newTimePeriod.startTime);
        setFieldValue('order.fulfillmentOption.schedule.dates[0]', newTimePeriod);
      } else {
        setDateTimeError('Time not saved. Start time must be before end time.');
      }
    }
  };

  const handleToggleTime = (type) => {
    const newSchedule: FulfillmentOptionSchedule = {
      ...schedule,
      type
    };

    setSchedule(newSchedule);
    setFieldValue('order.fulfillmentOption.schedule.type', type);
  }

  return (
    <Grid className={classes.itemBox}>
      <Grid container className={classes.itemHeader} alignItems="center">
        <Typography className={classes.title}>Fulfillment</Typography>
      </Grid>
      <Grid className={classes.itemBoxInner} container justify="space-between" direction="column">
        <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <FormLabel required>Fulfillment method</FormLabel>
            <Tooltip tooltipClass={classes.tooltip}
              title="How will you get this order to your customer? Customers can only request a method that you currently have active in your storefront but you can select any method." />
          </Grid>
          <Select
            displayEmpty={true}
            value={selectedType}
            onChange={event => handleFulfillmentChange(event.target.value, values.order)}
            placeholder="Select a method"
            variant="outlined"
            className={classes.selectBox}
            disabled={isPaid}
          >
            {(state === 'new' || state === 'draft') &&
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            }
            {fulfillmentTypes.map(type => (
              <MenuItem className={classes.selectOptionContainer} key={type} value={type}>
                <Typography className={classes.selectOption} variant="body1">
                  {_.capitalize(type)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          {requestedType && (
            <Grid className={classes.requestedInformation} container direction='row' justify='space-between'>
              <Typography variant='body2' >Customer requested: <span className={classes.requestedValue}>{_.capitalize(requestedType)}</span></Typography>
              {requestedType !== selectedType && !isPaid && (
                <Button
                  size='small'
                  onClick={() => {
                    handleFulfillmentChange(requestedType, values.order);
                    setFulfillmentName(_.capitalize(requestedType));
                  }}
                  className={classes.apply}
                >
                  <Typography variant='button'>Apply</Typography>
                </Button>
              )}
            </Grid>
          )}
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <FormLabel required>{fulfillmentName} date</FormLabel>
            <Tooltip tooltipClass={classes.tooltip}
              title="The date this order needs to be in your customer’s hands. This may not always be the date of the event." />
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid>
              <KeyboardDatePicker
                autoOk
                disablePast={false}
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="date-picker-inline"
                inputVariant="outlined"
                value={day}
                style={{ width: '100%' }}
                onChange={date => handleDayChange(date.tz(timeZone, true))}
                KeyboardButtonProps={{
                  'aria-label': 'Fulfillment due date',
                }}
                disabled={isPaid}
              />
              {requestedDate && (
                <Grid className={classes.requestedInformation} container direction='row' justify='space-between' alignContent='center'>
                  <Typography variant='body2'>Customer requested: <span className={classes.requestedValue}>{moment(requestedDate, 'X').tz(timeZone).format('L')}</span></Typography>
                  {moment(requestedDate, 'X').tz(timeZone).format('L') !== moment(date?.startTime, 'X').tz(timeZone).format('L') && !isPaid && (
                    <Button
                      size='small'
                      onClick={() => handleDayChange(moment(requestedDate, 'X').tz(timeZone))}
                      className={classes.apply}
                    >
                      <Typography variant='button'>Apply</Typography>
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
            {(fulfillmentName == 'Pickup' || fulfillmentName == 'Delivery') &&
              (!!schedule && schedule?.type === 'fixed' ?
                <Grid container item xs={12} wrap='nowrap'>
                  <Grid container item xs={isPaid ? 12 : 11} wrap="nowrap" style={{ marginTop: 16 }}>
                    <Grid item className={classes.timePicker} xs={6}>
                      <FormLabel className={classes.truncate}>{fulfillmentName} Start Time</FormLabel>
                      <TimePicker
                        margin="normal"
                        minutesStep={5}
                        id="time-picker"
                        inputVariant="outlined"
                        value={date?.startTime ? moment.unix(date.startTime).tz(timeZone) : null}
                        onChange={time => handleTimeChange(time, 'startTime')}
                        className={clsx([classes.pickerMargin, !date?.startTime && classes.disabled])}
                        disabled={!date?.startTime || isPaid}
                      />
                    </Grid>
                    <Typography className={classes.hyphen}>-</Typography>
                    <Grid item className={classes.timePicker} xs={6}>
                      <FormLabel className={classes.truncate}>{fulfillmentName} End Time</FormLabel>
                      <TimePicker
                        margin="normal"
                        minutesStep={5}
                        id="time-picker"
                        inputVariant="outlined"
                        value={date?.endTime ? moment.unix(date.endTime).tz(timeZone) : null}
                        onChange={time => handleTimeChange(time, 'endTime')}
                        className={clsx([classes.pickerMargin, !date?.startTime && classes.disabled])}
                        disabled={!date?.startTime || isPaid}
                      />
                    </Grid>
                  </Grid>
                  {!isPaid &&
                    <Grid container item xs={1} alignItems='center' style={{ marginTop: 30 }}>
                      <IconButton className={classes.closeTime} onClick={() => handleToggleTime('flexible')}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  }
                </Grid>
                :
                (schedule && schedule?.type === 'flexible') && !isPaid ?
                  <Grid onClick={() => handleToggleTime('fixed')} className={classes.addTime}>
                    <Typography variant='subtitle1' >+ Add {fulfillmentName} Time</Typography>
                  </Grid>
                  :
                  <></>
              )}
          </MuiPickersUtilsProvider>
          {dateTimeError && (
            <Typography variant="caption" color="error" className={classes.error}>
              {dateTimeError}
            </Typography>
          )}
        </FormControl>
        {(!isPaid || shippingInfo?.recipientName) && <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <FormLabel>{fulfillmentName} recipient</FormLabel>
            <Tooltip tooltipClass={classes.tooltip}
              title="If the contact for pickup, delivery, or shipping is different from your contact for the order, add them here for easier fulfillment." />
          </Grid>
          <Input
            type="text"
            value={values.order.fulfillmentOption.recipient}
            name="order.fulfillmentOption.recipient"
            placeholder="Name if not Customer name"
            variant="outlined"
            disabled={isPaid}
          />
        </FormControl>}
        <FormControl className={classes.formControl} variant="outlined" required>
          <FormLabel>{fulfillmentName} address</FormLabel>
          <AddressAutoComplete
            fulfillmentType={selectedType}
            error={touched.fulfillmentOption?.address?.fullAddress && errors?.fulfillmentOption?.address?.fullAddress}
            useLabel={false}
            addressFields={{
              address: 'order.fulfillmentOption.address.fullAddress',
              addressLine1: 'order.fulfillmentOption.address.addressLine1',
              city: 'order.fulfillmentOption.address.city',
              region: 'order.fulfillmentOption.address.region',
              regionName: 'order.fulfillmentOption.address.regionName',
              postalCode: 'order.fulfillmentOption.address.postalCode',
              country: 'order.fulfillmentOption.address.country'
            }}
            isExpanded={selectedType != 'pickup'}
            disabled={isPaid}
          />
        </FormControl>
        {(!isPaid || shippingInfo?.address?.addressLine2) &&
          <FormControl className={classes.formControl} variant="outlined">
            <FormLabel>Apt/Floor/Ste</FormLabel>
            <Input
              name="order.fulfillmentOption.address.addressLine2"
              type="text"
              placeholder='Example "Suite 200"'
              disabled={isPaid}
              variant="outlined"
              value={values.order.fulfillmentOption.address.addressLine2}
            />
          </FormControl>}
        {(!isPaid || order.fulfillmentOption?.notes) && <>
          {selectedType === 'pickup' &&
            <Typography variant="body2">We will only show the city, state, and zip before purchase. After purchase, your
              customer will receive the full pickup address.</Typography>}
          <FormControl className={classes.formControl} variant="outlined">
            <FormLabel>{fulfillmentName} notes</FormLabel>
            <Input
              type="text"
              value={values.order.fulfillmentOption.notes}
              name="order.fulfillmentOption.notes"
              placeholder="Delivery before noon"
              variant="outlined"
              disabled={isPaid}
              multiline
            />
          </FormControl>
        </>}
      </Grid>
    </Grid >
  );
};

export default QuoteFulfillment;