import React from 'react';
import { ButtonProps, Grid, makeStyles, Theme } from '@material-ui/core';
import { Button } from '@castiron/components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 8,
    textAlign: 'left',
    padding: '24px',
    color: theme.branding.gray[800],
    '& span': {
      height: '100%',
    },
  },
}));

export const handleMoreInfoRouting = (history, communityId) => {
  history.push(`/community/details/${communityId}`);
};

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type Props = Override<
  ButtonProps,
  {
    communityId: string;
  }
>;

// Regular community package card
const PackageCardContainer: React.FC<Props> = (props: Props) => {
  const { children, className, onClick, communityId, ...restOfProps } = props;

  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      onClick={() => handleMoreInfoRouting(history, communityId)}
      variant="outlined"
      className={`${classes.container} ${className}`}
      {...restOfProps}
    >
      <Grid container direction="column" style={{ height: '100%' }}>
        {children}
      </Grid>
    </Button>
  );
};

export default PackageCardContainer;
