import React, { ReactNode } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { FormikErrors } from 'formik';
import InputLabel from './InputLabel';

export interface BaseInputProps {
  label?: ReactNode;
  secondaryLabel?: ReactNode;
  required?: boolean;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
};

interface Props extends BaseInputProps {
  variant?: 'label-top' | 'label-left' | 'label-right';
  children: ReactNode;
}

const InputWrapper: React.FC<Props> = (props: Props) => {
  const { variant = 'label-top', label, secondaryLabel, required, error, children } = props;

  /* mui 4 doesn't support 0.5 increment spacing, so manually put in 4px margins */
  return variant === 'label-top' ? (
    <Grid container direction='column'>
      {
        label &&
        <Grid item style={{ marginBottom: '4px' }}>
          <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} />
        </Grid>
      }
      <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  ) : variant === 'label-right' ? (
    <Grid container direction='column'>
        <Grid item>
          <Grid container wrap='nowrap'>
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </Grid>
            {
              label &&
              <Grid item>
                <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} />
              </Grid>
            }
          </Grid>
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  ) : (
    <Grid container direction='column'>
        <Grid item>
          <Grid container>
            {
              label &&
              <Grid item style={{ marginBottom: '4px' }}>
                <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} />
              </Grid>
            }
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </Grid>
          </Grid>
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  );
};

export default InputWrapper;