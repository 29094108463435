import React from 'react';
import { Button } from '@castiron/components';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from '@castiron/components/src/Button/Button';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type AddToShopButtonProps = Override<
  ButtonProps,
  {
    communityPackageId: string;
  }
>;

// Button for adding Community package's product to shop
const AddToShopButton: React.FC<AddToShopButtonProps> = (props: AddToShopButtonProps) => {
  const { communityPackageId, ...restOfProps } = props;

  const history = useHistory();

  // links to add product page and prefills community package's product
  const handleAddToShop = e => {
    e.stopPropagation();
    history.push(`/products/standard/${communityPackageId}`);
  };

  return (
    <Button onClick={handleAddToShop} {...restOfProps}>
      + Add to shop
    </Button>
  );
};

export default AddToShopButton;
