import { Button, Dialog, DialogActions, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    padding: '40px',
  },
  modalHeader: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '16px',
    marginLeft: '8px',
  },
  buttonsWrapper: {
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',

      '& button': {
        padding: 8,
        width: 175,
        margin: 0,
      },

      '& button:nth-of-type(2)': {
        margin: '8px 0 0 0',
      },
    },
  },
  proceedButton: { marginRight: '8px' },
}));

interface Props {
  when: boolean;
  allowSamePage?: boolean;
}

const UnsavedChangesPrompt: React.FC<Props> = (props: Props) => {
  const { when, allowSamePage } = props;
  const classes = useStyles();
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const [blockNavigation, setBlockNavigation] = useState(when);

  useEffect(() => {
    setBlockNavigation(when);
  }, [when]);

  const handleBlockedNavigation = location => {
    if (allowSamePage && location.pathname === window.location.pathname) {
      return true;
    }
    setModalOpen(true);
    setNextLocation(location.pathname);
    setBlockNavigation(false);
    return false;
  };

  const handleProceed = () => {
    history.push(nextLocation);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setBlockNavigation(when);
  };

  return (
    <>
      <Prompt when={blockNavigation} message={handleBlockedNavigation} />
      <Dialog open={modalOpen}>
        <Grid className={classes.modalWrapper}>
          <Typography className={classes.modalHeader}>You have unsaved changes.</Typography>
          <DialogActions className={classes.buttonsWrapper}>
            <Button className={classes.proceedButton} onClick={handleProceed} color="primary" variant="outlined">
              Exit and don’t save
            </Button>
            <Button onClick={handleCancel} color="primary" variant="contained">
              Keep editing
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

export default UnsavedChangesPrompt;
