import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, ButtonBase, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import { CalendarIcon, Typography } from '@castiron/components';
import { Shop, Transaction } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch } from '../../hooks';
import { getService } from '../../firebase';
import { openModal } from '../../store/reducers/modalConductor';
import { listTransactionsAction } from '../../store/reducers/transactions';
import Spinner from '../Spinner';

type Props = {
  shop: Shop;
  transaction: Transaction;
  tz: string;
};

const printOrderService = getService('orders', 'printOrder');

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 10,
    height: 46
  },
  dropdownIcon: {
    color: theme.branding.gray[600]
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  menuOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
    '& span': {
      color: theme.branding.gray[600],
      paddingRight: 10
    }
  },
  orderActions: {
    '& .MuiPopover-paper': {
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 8,
      '& .MuiButton-sizeLarge': {
        width: '100%',
        justifyContent: 'flex-start',
        padding: '15px 24px',
        borderRadius: 0,
        borderBottom: `1px solid ${theme.branding.gray[300]}`
      },
    },
  },
}));

const OrderActionsMenu: React.FC<Props> = (props: Props) => {
  const { transaction, tz } = props;
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [showSpinner, setShowSpinner] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const printOrder = async (event, transaction: Transaction) => {
    event.stopPropagation();
    const printFrame = document.createElement('iframe');
    printFrame.style.display = 'none';
    document.title = `Order ${transaction?.order?.orderNumber || ''}`;

    const response = await printOrderService({
      transactionId: transaction.id,
      timeZone: tz,
      title: `Order ${transaction?.order?.orderNumber || ''}`,
    });

    printFrame.srcdoc = response.html;

    document.body.appendChild(printFrame);

    printFrame.onload = () => {
      setTimeout(() => {
        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();
      });
    };

    trackEvent('Print Order', { ordersPrinted: 1 });
    handlePopoverClose(event);
  };

  const markFulfilled = (event, transaction: Transaction) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'FULFILL_ORDER',
        modalProps: {
          show: true,
          transaction: transaction,
          updateList: true
        },
      }),
    );

    handlePopoverClose(event);
  };

  const sendMessage = (event, transaction: Transaction) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'BULK_CONTACT_MODAL',
        modalProps: {
          customerList: [
            {
              email: transaction.customerObj?.email,
              id: transaction.customerObj?.id,
              name: `${transaction.customerObj?.firstName} ${transaction.customerObj?.lastName ? transaction.customerObj?.lastName : ''}`
            }
          ],
          show: true,
        },
      }),
    );

    handlePopoverClose(event);
  };

  const showDateTimeModal = (event, transaction: Transaction) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'FULFILLMENT_DATE_TIME',
        modalProps: {
          transaction,
          onUpdate: handlePopoverClose,
        },
      }));
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Spinner show={showSpinner} size="fullscreen" />
      <ButtonBase className={classes.dropdown} onClick={handlePopoverClick}>
        <MoreVertIcon className={classes.dropdownIcon} />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        onClose={(e) => handlePopoverClose(e)}
        anchorEl={anchorEl}
        className={classes.orderActions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button onClick={(e) => printOrder(e, transaction)} className={classes.menuOption} startIcon={<PrintOutlinedIcon />}>
          <Typography variant='subtitle2'>Print</Typography>
        </Button>
        {transaction.status !== 'fulfilled' &&
          <Button onClick={(e) => markFulfilled(e, transaction)} className={classes.menuOption} startIcon={<DriveEtaOutlinedIcon />}>
            <Typography variant='subtitle2'>Mark as Fulfilled</Typography>
          </Button>
        }
        <Button onClick={(e) => sendMessage(e, transaction)} className={classes.menuOption} startIcon={<MailOutlinedIcon />}>
          <Typography variant='subtitle2'>Send Message</Typography>
        </Button>
        <Button onClick={(e) => showDateTimeModal(e, transaction)} className={classes.menuOption} startIcon={<CalendarIcon />}>
          <Typography variant='subtitle2'>{transaction.order.fulfillmentOption?.date || !_.isEmpty(transaction.order.fulfillmentOption?.schedule?.dates) ? 'Update Due Date' : 'Add Due Date'}</Typography>
        </Button>
      </Popover>
    </>
  );
};

export default OrderActionsMenu;
