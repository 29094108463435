import { IconButton, makeStyles, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Typography } from '@castiron/components';
import React from 'react';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Input, Button } from '@castiron/components';

export type Props = {
  show: boolean;
  noteContent?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  container: {
    padding: '22px 32px',
  },
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    gap: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
  },
}));

const NoteModal: React.FC<Props> = (props: Props) => {
  const { show, noteContent } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const noteSchema = yup.object().shape({
    content: yup.string(),
  });

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <div className={classes.container}>
        <Formik
          onSubmit={() => console.log('submitted')}
          validationSchema={noteSchema}
          initialValues={{ content: noteContent || '' }}
        >
          {() => (
            <Form>
              <Typography className={classes.title}>Add note</Typography>
              <Input placeholder="Add your note here" name="content" multiline rows={16} />
              <div className={classes.actionsContainer}>
                <Button type="submit" variant="contained">
                  Save
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Discard
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalWrapper>
  );
};

export default NoteModal;
