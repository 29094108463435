import React from 'react';
import CancelOrderModal from '../CancelOrderModal';
import ContactModal from '../ContactModal';
import DeleteModal from '../DeleteModal';
import FulfillmentDateTimeModal from '../FulfillmentDateTimeModal';
import ResetPasswordModal from '../ResetPasswordModal';
import NoteModal from '../NoteModal';
import SimpleAlert from '../SimpleAlert';
import FulfillOrderModal from '../Orders/OrderFulfilledModal';
import CustomOrderRejectModal from '../Orders/CustomOrders/CustomOrderRejectModal';
import AddProductModal from '../Products/AddProductModal/AddProductModal';
import SelectProductTypeModal from '../Products/AddProductModal/SelectProductTypeModal';
import { ExportOrdersModal } from '../Orders/ExportOrdersModal';
import BulkContactModal from '../BulkContactModal';
import QuoteSendModal from '../Quotes/QuoteSendModal';
import QuoteSentModal from '../Quotes/QuoteSentModal';
import ProductPreviewModal from '../Products/PreviewModals/ProductPreviewModal';
import CustomProductPreviewModal from '../Products/PreviewModals/CustomProductPreviewModal';
import ExportContactListModal from '../Customers/ExportContactListModal';
import AddFulfillmentModal from '../Store/Fulfillment/AddFulfillmentModal/AddFullfillmentModal';
import PaymentModal from '../Store/Payments/PaymentModal';
import ChangePaymentModal from '../Store/SubscriptionPlans/ChangePaymentModal';
import GeneralModal from './GeneralModal';
import AvailabilityModal from '../Calendar/AvailabilityModal';
import OnboardingModal from '../Onboarding/OnboardingModal';
import SelectPlanModal from '../SelectPlan/Modal/SelectPlanModal';
import CancelPlanModal from '../Store/SubscriptionPlans/CancelPlan/CancelPlanModal';
import CancelSuccessModal from '../Store/SubscriptionPlans/CancelPlan/CancelSuccessModal';
import ResumeSubscriptionModal from '../Store/SubscriptionPlans/ResumeSubscriptionModal';
import BusinessCTAModal from '../SelectPlan/BusinessCTAModal/BusinessCTAModal';
import IndividualListingModal from '../Calendar/Listing/IndividualListingModal';
import SeoLocationModal from '../SeoLocationModal';

const MODAL_COMPONENTS = {
  ADD_FULFILLMENT_MODAL: AddFulfillmentModal,
  ADD_PRODUCT_MODAL: AddProductModal,
  BULK_CONTACT_MODAL: BulkContactModal,
  CANCEL_PLAN_MODAL: CancelPlanModal,
  CANCEL_SUCCESS_MODAL: CancelSuccessModal,
  CHANGE_PLANS_MODAL: SelectPlanModal,
  CHANGE_PAYMENT_MODAL: ChangePaymentModal,
  CONTACT_MODAL: ContactModal,
  CUSTOM_PRODUCT_MODAL: CustomProductPreviewModal,
  DELETE_MODAL: DeleteModal,
  RESET_PASSWORD_MODAL: ResetPasswordModal,
  NOTE_MODAL: NoteModal,
  CANCEL_ORDER_MODAL: CancelOrderModal,
  SELECT_PRODUCT_TYPE_MODAL: SelectProductTypeModal,
  SIMPLE_ALERT: SimpleAlert,
  FULFILL_ORDER: FulfillOrderModal,
  FULFILLMENT_DATE_TIME: FulfillmentDateTimeModal,
  PAYMENT_MODAL: PaymentModal,
  PRODUCT_MODAL: ProductPreviewModal,
  REJECT_CUSTOM_ORDER: CustomOrderRejectModal,
  EXPORT_ORDERS: ExportOrdersModal,
  EXPORT_CONTACT_LIST_MODAL: ExportContactListModal,
  QUOTE_SEND_MODAL: QuoteSendModal,
  QUOTE_SENT_MODAL: QuoteSentModal,
  AVAILABILITY_MODAL: AvailabilityModal,
  GENERAL_MODAL: GeneralModal,
  ONBOARDING_MODAL: OnboardingModal,
  RESUME_SUBSCRIPTION_MODAL: ResumeSubscriptionModal,
  BUSINESS_CTA_MODAL: BusinessCTAModal,
  INDIVIDUAL_LISTING_MODAL: IndividualListingModal,
  SEO_LOCATION_MODAL: SeoLocationModal,
};

export type ModalType = keyof typeof MODAL_COMPONENTS;

type Props = {
  modalType: ModalType;
  modalProps: Record<string, any>;
};

const RootModal: React.FC<Props> = (props: Props) => {
  if (!props.modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[props.modalType];

  // @ts-ignore
  return <SpecificModal {...props.modalProps} />;
};

export default RootModal;
