import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 26,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  actionButton: {
    color: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
  },
  button: {
    height: 40,
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 6,
    paddingRight: 8,
    paddingLeft: 16,
  },
  deleteMenuItem: {
    color: theme.palette.error.main,
    paddingRight: 16,
  },
  actionText: {
    fontSize: 14,
    fontWeight: 600,
  },
  buttonLabel: {
    fontWeight: 600,
    fontSize: 14,
  },
  star: {
    color: theme.branding.yellow.primary,
  },
  icon: {
    height: 40,
    width: 40,
  },
  popoverPaper: {
    marginTop: 14,
  },
}));

const CustomerActions: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (): void => {
    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          itemName: 'customer',
        },
      }),
    );
    handlePopoverClose();
  };

  return (
    <Box className={classes.actions}>
      {/* <ButtonBase onClick={handleMoreClick} className={classes.button}>
        <Typography className={classes.buttonLabel}>Actions</Typography>
        <SvgIcon className={classes.icon}>
          <ChevronDownIcon />
        </SvgIcon>
      </ButtonBase>
      <Popover
        paperClass={classes.popoverPaper}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <ButtonBase onClick={handleDeleteClick} className={classes.deleteMenuItem}>
          <SvgIcon className={classes.icon}>
            <TrashIcon />
          </SvgIcon>
          <Typography className={classes.actionText}>Delete</Typography>
        </ButtonBase>
      </Popover> */}
    </Box>
  );
};

export default CustomerActions;
