import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Chip, CustomersIcon, SvgIcon, Typography } from '@castiron/components';
import { useFormikContext } from 'formik';
import { InputField, ProductType } from '@castiron/domain/src';
import { nanoid } from '@reduxjs/toolkit';
import SingleVariation, { initialVariationOptionValues } from './SingleVariation';
import LocationOn from '@material-ui/icons/LocationOn';

type Props = {
  type: ProductType;
  placeholders?: Record<string, string>;
  isEditMode: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  defaultInformationContainer: {
    background: theme.branding.gray[200],
    borderRadius: 8,
    padding: 24,
    marginTop: 16,
    minHeight: 190,
  },
  gridContainer: {
    width: '100%',
    marginTop: '37px',
  },
  icon: {
    height: 12,
    color: theme.branding.gray[600],
    margin: '2px 4px 0 0',
  },
  label: {
    marginRight: 8
  },
  subLabel: {
    color: theme.branding.gray[600],
    fontWeight: 600,
    marginTop: '8px',
  },
  addVariationButton: {
    marginTop: '16px',
    padding: '13px 32px',
    border: `1px solid ${ theme.branding.gray[400] }`
  },
  previewButton: {
    border: `1px solid ${ theme.branding.gray[400] }`,
    color: theme.branding.blue.primary,
    marginLeft: 'auto',
    '& svg path': {
      fill: theme.branding.blue.primary,
    },

  },
  variationHeader: {
    alignItems: 'center',
    display: 'flex'
  }
}));

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const Variations: React.FC<Props> = (props: Props) => {
  const { type, isEditMode, placeholders } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [variationExpanded, setVariationExpanded] = useState(false);

  const addVariation = () => {
    setVariationExpanded(true);
    setFieldValue('variations', [
      ...values.variations,
      {
        id: nanoid(),
        type: 'select',
        name: '',
        values: [{ ...initialVariationOptionValues, id: nanoid() }],
        required: false,
        position: values.variations.length - 1
      },
    ]);
  };

  const title = type === 'standard' ? 'Variations' : 'Order Form';
  const chipType = type === 'standard' ? 'Variant' : 'Question';

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sortedVariations = reorder<InputField>(
      values.variations,
      result.source.index,
      result.destination.index
    );

    setFieldValue('variations', sortedVariations.map((v, index) => ({
      ...v,
      position: index
    })));
  }

  return <DragDropContext onDragEnd={ onDragEnd }>
    <Grid className={ classes.gridContainer }>
      <Grid direction="row" className={ classes.variationHeader }>
        <Typography variant="h5" className={ classes.label }> { title } </Typography>
        <Chip colorScheme='info'>
          <Typography
            variant='caption'>{values.variations.length} {chipType}{values.variations.length === 1 ? '' : 's'}
          </Typography>
        </Chip>
      </Grid>
      { type === 'standard' ? <Typography variant="h6" className={ classes.subLabel }>
        Create variations of this product for customers to select from when adding this product to their cart.
      </Typography> : <Typography variant="h6" className={ classes.subLabel }>
        Manage what your customers are asked when they submit a quote request for this product.
      </Typography> }
      { type === 'custom' && 
        <Grid container className={classes.defaultInformationContainer}>
          <Typography variant="h5">We collect the following by default:</Typography>
          <Grid container item style={{marginTop: 12}}>
            <SvgIcon className={classes.icon}>
              <CustomersIcon />
            </SvgIcon>
            <Grid item xs direction="column">
              <Typography variant="h6" style={{marginBottom: 4}}>Customer Contact Info</Typography>
              <Typography variant="body2">Full name, Email address, Phone number, Marketing opt-in</Typography>
            </Grid>
          </Grid>
          <Grid container item style={{marginTop: 22}}>
            <SvgIcon className={classes.icon}>
              <LocationOn/>
            </SvgIcon>
            <Grid item xs direction="column">
              <Typography variant="h6" style={{marginBottom: 4}}>Fulfillment Info</Typography>
              <Typography variant="body2">Order fulfillment date; Pickup, delivery, or shipping preference; Address</Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      { values.variations?.length > 0 && <Droppable droppableId="variations" type="VARIATION">
        { (provided, snapshot) => (<div ref={ provided.innerRef }>
          { _.sortBy(values.variations, v => v.position).map((variation: InputField, index: number) => (
            <Draggable draggableId={ variation.id } index={ index } key={ variation.id }>
              { (provided, snapshot) => (
                <div ref={ provided.innerRef } {...provided.draggableProps}>
                  <SingleVariation variation={ variation } index={ index } type={ type }
                                   isEditMode={ isEditMode } variationExpanded={ variationExpanded }
                                   placeholders={ placeholders } dragHandleProps={provided.dragHandleProps}/>
                </div>) }
            </Draggable>
          )) }
          {provided.placeholder}
        </div>)
        }
      </Droppable>
      }
      <Button
        onClick={ addVariation }
        className={ classes.addVariationButton }
        fullWidth={ isMobile }
        variant="outlined"
        color="primary"
      >
        Add { type === 'standard' ? 'Variation' : 'Question' }
      </Button>
    </Grid>
  </DragDropContext>;
};

export default Variations;
