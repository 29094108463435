import { Attribution } from "@castiron/domain";
import { clientSide } from "../ssr";
import React, { useEffect, useState } from "react";
import { propagateAttribution } from "./propagateAttribution";

export const SESSION_ATTRIBUTION_KEY = 'castiron-session-attribution';

export const determineAttribution = (): Attribution => clientSide(() => {
  try {
    const attribJson = sessionStorage.getItem(SESSION_ATTRIBUTION_KEY);
    return attribJson 
      ? JSON.parse(attribJson) 
      : {
        source: "unknown",
        referringSourceUrl: window?.document.referrer,
      } as Attribution;
  } catch (err) {
    console.error('Error getting attribution', err);
    return {
      source: "unknown",
      referringSourceUrl: window?.document.referrer,
    } as Attribution;
  }
});

export const AttributionContext = React.createContext<Attribution>(undefined);

export const AttributionConfig: React.FC = (props) => {
  const [attribution, setAttribution] = useState<Attribution>(determineAttribution());

  console.debug('Attribution', attribution);

  useEffect(() => {
    if (!attribution) {
      propagateAttribution().then(a => setAttribution(a));
    }
  }, [attribution]);

  return <AttributionContext.Provider value={attribution}>{props.children}</AttributionContext.Provider>
}
