import React, { MouseEvent, ReactNode, useState } from 'react';
import { Button, ButtonBase, Grid, Popover, PopoverOrigin } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import { Typography } from '@castiron/components';

export interface EllipsisMenuOption {
  display: string | ReactNode;
  color?: 'error';
  icon?: ReactNode;
  action: (event?: MouseEvent<HTMLButtonElement>) => void;
};

interface Props {
  disabled?: boolean;
  options: EllipsisMenuOption[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  addBorder?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  addBorder: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 14,
  },
  error: {
    color: theme.branding.red.primary,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 12,
    height: 32,
  },
  disabled: {
    color: theme.branding.gray[500],
  },
  popoverContent: {
    padding: '8px 0',
  },
}));

const EllipsisMenu: React.FC<Props> = (props: Props) => {
  const {
    disabled,
    options,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'center',
    },
    addBorder = false,
  } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* this is some messy typing :-/ */
  const actionWrapper: (action: (event: MouseEvent<HTMLButtonElement>) => any) => (event: MouseEvent<HTMLButtonElement>) => void =
    (action: (event: MouseEvent<HTMLButtonElement>) => any) => {
      return (event: MouseEvent<HTMLButtonElement>) => {
        /* quick and dirty solution a one off feature */
        const actionResponse = action(event);
        if (typeof actionResponse === 'object' && typeof actionResponse.then === 'function') {
          actionResponse.then(() => setAnchorEl(null));
        } else {
          setAnchorEl(null);
        }
      };
    };

  return (
    <>
      <ButtonBase className={`${disabled && classes.disabled} ${addBorder && classes.addBorder}`} disabled={disabled} onClick={handleClick}>
        <MoreVert />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ borderRadius: 8 }}
      >
        <Grid container direction='column' className={classes.popoverContent}>
          {options?.map((option, index) => {
            return (
              <Grid item key={`ellispesOption${index}`}>
                <Button
                  startIcon={option.icon}
                  onClick={actionWrapper(option.action)}
                  className={`${classes.menuItem} ${option.color == 'error' && classes.error}`}
                  fullWidth
                >
                  <Typography variant='subtitle2' className={option.color == 'error' && classes.error}>{option.display}</Typography>
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Popover>
    </>
  );
};

export default EllipsisMenu;
