import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import _ from 'lodash';
import moment from 'moment';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { FieldFunctions } from '../base/repository';
import dataOnly from '../_lib/dataOnly';
import { MessageType } from "../message";

const COMM_LOG_COLLECTION = 'communication_logs';

export interface ContactInfo {
  email?: string;
  mobileNumber?: string;
}

export type TypesOfPlaceToReach = 'phone' | 'email' | 'no-preference';

export type SubscriberOrigination =
  | 'admin-individual-upload'
  | 'admin-bulk-upload'
  | 'admin-update-customer-form'
  | 'prelaunch'
  | 'quote-checkout'
  | 'signup'
  | 'snackbar-notification'
  | 'standard-checkout'
  | 'transaction-email'
  | '';

export interface CustomerMetrics {
  totalOrders?: number;
  totalRevenue?: number;
  dateLastPurchased?: number;
  totalTips?: number;
}

export interface SelectedCustomerProps {
  email: string,
  id: string,
  name: string,
}

/* make sure that you also update LastCommunications, it should align
 * If you know a better way to force alignment, please do so, or let me know
 */
export type CommunicationType =
  | 'artisanUploadSubscriberConfirmation'
  | 'directMessageArtisanToCustomer'
  | 'directMessageCustomerToArtisan'
  | 'paymentReminder';

export interface CommunicationLogEntry {
  id?: string;
  sender: 'castiron' | 'artisan' | 'customer' | 'castironInternal';
  from: string;
  to: string;
  subject?: string;
  body?: string;
  type: MessageType;
  dateSent: number;
}

/* contains the last date of a customer sending/receiving these communcation types */
export interface LastCommunications {
  artisanUploadSubscriberConfirmation?: number;
  directMessageArtisanToCustomer?: number;
  directMessageCustomerToArtisan?: number;
  paymentReminder?: number;
};

export interface Customer extends ShopRelatedDocument<Customer>, ContactInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  // address?: Address;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  instructions?: string;
  subscribed?: boolean;
  starred?: boolean;
  notes?: string;
  placeToReach?: TypesOfPlaceToReach;
  hasEverSubscribed?: boolean;
  subscriberOrigination?: SubscriberOrigination;
  dateLastSubscribed?: number;
  dateLastUnsubscribed?: number;
  metrics?: CustomerMetrics;
  subscriptionInviteSent?: boolean;
  lastCommunications?: LastCommunications;
  logCommunication?: (entry: CommunicationLogEntry) => Promise<void>;
  getCommunicationLog?: () => Promise<CommunicationLogEntry[]>;
  getCommunicationLogByType?: (type: string) => Promise<CommunicationLogEntry[]>;
  subscribe?: (origination: SubscriberOrigination) => Promise<Customer>;
  mobileNumber?: string;
  fullName?: () => string;
}

export class CustomerRepository extends ShopRelatedRepository<Customer> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'customers', fieldFunctions);
  }

  public async findByContactInfo(shopId: string, info: ContactInfo): Promise<Customer | null> {
    let customer = null;
    if (info.email) {
      const result = await this.find({
        where: [this.whereShopIs(shopId), { field: 'email', operator: '==', value: info.email }],
      });

      customer = this.firstOrNull(result);
    } else if (info.mobileNumber) {
      const result = await this.find({
        where: [this.whereShopIs(shopId), { field: 'mobileNumber', operator: '==', value: info.mobileNumber }],
      });
      customer = this.firstOrNull(result);
    } else {
      return null;
    }

    return customer;
  }

  public async findByEmails(shopId: string, emails: string[]): Promise<Customer[] | null> {
    return new Promise(res => {
      const emailBatches = [];

      while (emails.length) {
        const emailBatch = emails.splice(0, 10);

        emailBatches.push(
          new Promise(response => {
            this.find({
              where: [
                this.whereShopIs(shopId),
                // firestore is limited to 10 queries with operator "in" and "array-contains-any"
                // so we create promise batches of 10 queries then flatten all of the results back to a single array
                { field: 'email', operator: 'in', value: [...emailBatch] },
              ],
            }).then(results => response(results.map(result => ({ ...result }))));
          }),
        );
      }
      Promise.all(emailBatches).then(content => {
        res(content.flat());
      });
    });
  }

  public async batchUploadCustomers(shopId: string, itemBatch: Array<Customer>) {
    const emailList = itemBatch.map(function (customer) {
      return customer.email;
    });

    const customersToUpdate = await this.findByEmails(shopId, emailList);

    customersToUpdate.forEach(customer => {
      const validKeys = ['email', 'id'];
      Object.keys(customer).forEach(key => validKeys.includes(key) || delete customer[key]);
    });

    customersToUpdate.forEach(customer => {
      itemBatch.forEach(item => {
        if (item.email === customer.email) item.id = customer.id;
      });
    });

    return await this.batch(itemBatch);
  }

  public async createCustomer(shopId: string, customer: Customer) {
    const shouldUpdate = await this.findByEmails(shopId, [customer.email]);
    if (shouldUpdate.length) customer.id = shouldUpdate[0].id;

    // don't overwrite the existing origination on updates or if we don't know first origination
    if (shouldUpdate.length && shouldUpdate[0].subscriberOrigination) {
      customer.subscriberOrigination = shouldUpdate[0].subscriberOrigination;
    } else if (shouldUpdate.length && shouldUpdate[0].hasEverSubscribed && !shouldUpdate[0].subscriberOrigination) {
      customer.subscriberOrigination = '';
    }
    shouldUpdate.length ? this.update(customer) : this.create(customer);
  }

  public async findSubscribed(shopId: string): Promise<Customer[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'subscribed', operator: '==', value: true }],
    });
  }

  /* eligible meaning email has been sent before and not opted out last contacted about it before date */
  public async paginateEligiblePotentialSubscribers(beforeDate: number, limit: number, after: Customer = null): Promise<Customer[]> {
    return this.find({
      where: [
        { field: 'subscriptionInviteSent', operator: '==', value: true },
        { field: 'subscribed', operator: '==', value: false },
        { field: 'hasEverSubscribed', operator: '==', value: false },
        { field: 'lastCommunications.artisan_upload_subscriber_list', operator: '<', value: beforeDate },
      ],
      orderBy: [{ field: 'lastCommunications.artisan_upload_subscriber_list', direction: 'asc' }],
      limit,
      startAfter: after,
    });
  }

  public async logCommunication(customerId: string, entry: CommunicationLogEntry): Promise<void> {
    await this.collection()
      .doc(customerId)
      .collection(COMM_LOG_COLLECTION)
      .add(entry);
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      async logCommunication(entry: CommunicationLogEntry): Promise<void> {
        await this._repository.logCommunication(this.id, entry);
      },
      async getCommunicationLog(): Promise<CommunicationLogEntry[]> {
        const results = await this._repository
          .collection()
          .doc(this.id)
          .collection(COMM_LOG_COLLECTION)
          .get();
        return results.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      },
      async getCommunicationLogByType(type: string): Promise<CommunicationLogEntry[]> {
        const results = await this._repository
          .collection()
          .doc(this.id)
          .collection(COMM_LOG_COLLECTION)
          .where('type', '==', type)
          .orderBy('dateSent', 'desc')
          .get();
        return results.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      },
      async subscribe(origination: SubscriberOrigination): Promise<Customer> {
        const subscribedCustomer = {
          ...this,
          subscribed: true,
          hasEverSubscribed: true,
          dateLastSubscribed: moment().unix(),
          subscriberOrigination: origination,
        };
        return this._repository.update(subscribedCustomer);
      },
      fullName() {
        return this.lastName ? `${this.firstName} ${this.lastName}` : this.firstName;
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export type SubscribedState = 'opted out' | 'pending' | 'yes' | 'error';

export const subscriberStatusState = (customer: Customer): SubscribedState => {
  if (customer) {
    const { subscribed, subscriptionInviteSent, hasEverSubscribed } = customer;

    if (!subscribed && !hasEverSubscribed && subscriptionInviteSent) return 'pending';
    if (!subscribed) return 'opted out';
    if (subscribed) return 'yes';
    return 'error';
  }
};
