import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type RightColumnProps = Override<GridProps, {}>;

// Right Column wrapper
const RightColumn: React.FC<RightColumnProps> = (props: RightColumnProps) => {
  const { xs, children, ...restOfProps } = props;

  return (
    <Grid item xs={xs || 5} {...restOfProps}>
      {children}
    </Grid>
  );
};

export default RightColumn;
