import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Card, QualifiedNumberInput, TextAreaInput } from '@castiron/components';
import { FulfillmentOption } from '@castiron/domain';

const ShippingDetails: React.FC = () => {
  const formik = useFormikContext<FulfillmentOption>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card title='Shipping Details'>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <QualifiedNumberInput
            label='Standard Processing Time (Optional)'
            fieldMappings={{
              text: 'processingTime.increments',
              qualifier: 'processingTime.incrementType'
            }}
            qualifierOptions={[
              { display: 'Days', value: 'day' },
              { display: 'Weeks', value: 'week' }
            ]}
            error={formik.touched.processingTime?.increments && formik.errors.processingTime?.increments}
          />
        </Grid>
        <Grid item>
          <TextAreaInput
            label='Details'
            secondaryLabel='Provide your customers with the info they need to make an informed selection at checkout.'
            required
            name='description'
            rowCount={5}
            error={formik.touched.description && formik.errors.description}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShippingDetails;