import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const MegaphoneOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="-2 0 24 20" fill="none" {...props}>
    <g >
      <path d="M20 7.99329C20 6.09222 18.7 4.59138 17 4.0911V0.989371C17 0.288979 16.3 -0.2113 15.6 0.0888674C8.3 2.79038 8.2 2.99049 3 2.99049C1.3 2.99049 0 4.29122 0 5.99217V9.99441C0 11.6954 1.3 12.9961 3 12.9961V17.0984C3 18.6993 4.3 20 6 20C7.7 20 8.9 18.6993 8.9 17.0984V13.5964C10.5 13.9966 11.6 14.4969 15.6 15.9978C16.3 16.1979 17 15.7977 17 15.0973V11.9955C18.7 11.3952 20 9.89435 20 7.99329ZM2 9.99441V5.99217C2 5.39183 2.4 4.99161 3 4.99161C4.7 4.99161 5.3 4.99161 6.6 4.89155C7.1 6.89267 7.2 8.99385 6.6 11.095C5.3 10.995 4.7 10.995 3 10.995C2.4 10.995 2 10.5947 2 9.99441ZM6 17.9989C5.4 17.9989 5 17.5987 5 17.0984V13.0961C5.7 13.0961 6.3 13.1962 6.9 13.1962V17.0984C6.9 17.5987 6.5 17.9989 6 17.9989ZM15 13.5964C12.3 12.5959 10.6 11.8955 8.6 11.4952C9.2 9.29401 9.2 6.89267 8.6 4.69144C10.6 4.29122 12.4 3.49077 15 2.59027V13.5964ZM17 9.69424V6.29234C17.6 6.5925 18 7.2929 18 7.99329C18 8.69368 17.6 9.39407 17 9.69424Z" />
    </g>
  </SvgIcon>
);
