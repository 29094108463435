import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CookieOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="-2 0 22 22" fill="none" {...props}>
    <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.27 4.78943C16.25 7.05653 18.16 8.97408 19.95 9.00405C20.54 14.9265 15.89 20 10 20C4.48 20 0 15.5257 0 10.0128C0 4.42989 5.11 -1.23288 12.08 0.235249C11.49 3.20146 14.3 5.78816 17.27 4.78943ZM2 10.0128C2 14.4171 5.59 18.0026 10 18.0026C14.06 18.0026 17.7 14.8666 17.99 10.5621C15.59 9.48343 15.14 7.29623 15.07 6.93669C12.52 6.55717 10.5 4.53975 10.08 2.00299C4.73 1.84319 2 6.70698 2 10.0128ZM10 6.51707C10 7.34444 9.32843 8.01515 8.5 8.01515C7.67157 8.01515 7 7.34444 7 6.51707C7 5.6897 7.67157 5.01898 8.5 5.01898C9.32843 5.01898 10 5.6897 10 6.51707ZM8 11.5107C8 12.3381 7.32843 13.0088 6.5 13.0088C5.67157 13.0088 5 12.3381 5 11.5107C5 10.6833 5.67157 10.0126 6.5 10.0126C7.32843 10.0126 8 10.6833 8 11.5107ZM13 14.0075C13.5523 14.0075 14 13.5604 14 13.0088C14 12.4572 13.5523 12.01 13 12.01C12.4477 12.01 12 12.4572 12 13.0088C12 13.5604 12.4477 14.0075 13 14.0075Z" />
    </g>
  </SvgIcon>
);
