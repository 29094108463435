import { Cake, Cookie, Cupcake, Macaron, TieredCake } from '@castiron/components';
import React, { ReactNode } from 'react';

/* this allows us to store icon mappings in firestore
 * so that we don't have to hard code dynamic elements to support icons
 */
export const iconsByName = (iconName: string): ReactNode => {
  switch (iconName) {
    case 'cake':
      return <Cake />;
    case 'cookie':
      return <Cookie />;
    case 'cupcake':
      return <Cupcake />;
    case 'macaron':
      return <Macaron />;
    case 'tieredCake':
      return <TieredCake />;
    default:
      return null;
  }
}