import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { Button, Chip, OrderedList } from "@castiron/components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Spinner from "../../Spinner";
import { getCustomersAction } from '../../../store/reducers/customers';
import { getService } from "../../../firebase";

interface Props {
  newCustomerEmails: String[];
  invalidCustomerEmails: String[];
  duplicateCustomerEmails: String[];
  subscribedNewCustomers: boolean;
  reset: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 20,
  },
  loadedInviteToSubscribe: {
    /* should this go into branding? */
    backgroundColor: '#F2F7FF',
    padding: '16px',
    /* spacing inside of this container causes margin changes */
    margin: 0,
  },
  loadedInviteDetails: {
    fontSize: '14px',
  },
  loadedInviteConfirmationHeader: {
    fontSize: '16px',
    fontWeight: 700,
  },
  loadedGroupListContainer: {
    marginTop: '8px',
    border: `solid 1px ${ theme.branding.gray }`,
    borderRadius: '4px',
    padding: '15px',
    position: 'relative',
  },
  loadedGroupListHeader: {
    fontSize: '14px',
    fontWeight: 700,
    marginRight: '6px',
  },
  loadedGroupListCountPill: {
    fontSize: '10px',
    fontWeight: 600,
    padding: '0 8px',
  },
  loadedGroupSubscriberPill: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  loadedAddMore: {
    marginTop: '24px',
  },
}));

const bulkSendInvites = getService('customers', 'bulkSendSubscriberInvites');

const CustomerBulkAdded: React.FC<Props> = (props) => {
  const { newCustomerEmails, invalidCustomerEmails, duplicateCustomerEmails, subscribedNewCustomers, reset } = props;

  const [justArrived, setJustArrived] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [showSubscribeInviteConfirmation, setShowSubscribeInviteConfirmation] = useState(false);
  const [subscribeInvitesSent, setSubscribeInvitesSent] = useState(false);
  const [showSubscribeInvitesSent, setShowSubscribeInvitesSent] = useState(false);

  const numNewCustomerEmails = newCustomerEmails.length;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop
  }));

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const confirmBulkUplooadNewContacts = () => {
    setShowSubscribeInviteConfirmation(true);
  };

  const bulkSendSubscriberInvites = async () => {
    setIsSending(true);
    setShowSubscribeInviteConfirmation(false);
    await bulkSendInvites({ shopId: shop.id, customerEmails: newCustomerEmails });
    await dispatch(getCustomersAction(shop.id));
    setIsSending(false);
    setSubscribeInvitesSent(true);
    setShowSubscribeInvitesSent(true);
  };

  /* this might be worth breaking out into a separate component at some point
   * but I don't feel like that is now
   */
  const groupedList = (group: String[], groupTitle: String, includeSubscribedPill: boolean, subscribed: boolean = false) => {
    return (
      <>
        {
          group.length > 0 &&
          <Grid item container xs={ 12 } className={ classes.loadedGroupListContainer }>
            {
              includeSubscribedPill &&
              <Box className={ classes.loadedGroupSubscriberPill }>
                {
                  subscribeInvitesSent ? (
                    <Chip colorScheme='warning'>
                      INVITE PENDING
                    </Chip>
                  ) : (
                    <Chip colorScheme={ subscribed ? 'success' : 'error' }>
                      { !subscribed && 'NOT' } SUBSCRIBED
                    </Chip>
                  )
                }
              </Box>
            }
            <Grid item xs={ 12 }>
              <Typography display='inline' className={ classes.loadedGroupListHeader }>{ groupTitle }</Typography>
              <Chip colorScheme='info' display='inline'
                    className={ classes.loadedGroupListCountPill }>{ group.length }</Chip>
            </Grid>
            <Grid item xs={ 12 }>
              <OrderedList items={ group }/>
            </Grid>
          </Grid>
        }
      </>
    );
  };

  return (
    <Grid container className={ classes.container }>
      <Spinner show={ isSending } size='content' label='Please wait. This may take some time to process'/>
      <Dialog open={ justArrived } onClose={ () => setJustArrived(false) } fullWidth>
        <DialogContent>
          <Typography>{ numNewCustomerEmails } contact{ numNewCustomerEmails > 1 && 's' } successfully
            added!</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={ () => setJustArrived(false) }>Done</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ showSubscribeInviteConfirmation } onClose={ () => setShowSubscribeInviteConfirmation(false) }
              fullWidth>
        <DialogTitle>
          <Typography>Ready to send email invites?</Typography>
        </DialogTitle>
        <DialogActions>
          <Button variant='outlined' onClick={ () => setShowSubscribeInviteConfirmation(false) }>Cancel</Button>
          <Button variant='contained' onClick={ bulkSendSubscriberInvites }>Send</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ showSubscribeInvitesSent } onClose={ () => setShowSubscribeInvitesSent(false) } fullWidth>
        <DialogContent>
          <Typography>
            { numNewCustomerEmails } invite{ numNewCustomerEmails > 1 && 's' } sent!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={ () => setShowSubscribeInvitesSent(false) }>Done</Button>
        </DialogActions>
      </Dialog>
      {
        !subscribedNewCustomers && numNewCustomerEmails > 0 && !subscribeInvitesSent &&
        <Grid item container xs={ 12 } spacing={ 2 } className={ classes.loadedInviteToSubscribe }>
          <Grid item xs={ 12 }>
            <Typography display='inline' className={ classes.loadedGroupListHeader }>
              Invite your new contacts to subscribe?
            </Typography>
            <Chip display='inline' colorScheme='mint'>Recommended</Chip>
          </Grid>
          <Grid item xs={ 12 }>
            <Typography className={ classes.loadedInviteDetails }>
              We will automatically send an email inviting your new contacts to subscribe to your list and stay up to
              date with your shop.
            </Typography>
          </Grid>
          <Grid item xs={ 12 }>
            <Button variant='contained'
                    onClick={ confirmBulkUplooadNewContacts }>Send { numNewCustomerEmails } invite{ numNewCustomerEmails > 1 && 's' }</Button>
          </Grid>
        </Grid>
      }
      { groupedList(newCustomerEmails, 'Successfully added', true, subscribedNewCustomers) }
      { groupedList(invalidCustomerEmails, 'Not a valid email address', false) }
      { groupedList(duplicateCustomerEmails, `Duplicate${ duplicateCustomerEmails.length === 1 ? '' : 's' }`, false) }
      <Grid item container direction='row-reverse' className={ classes.loadedAddMore }>
        <Grid item>
          <Button variant='contained' onClick={ reset }>
            Add more contacts
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerBulkAdded;
