import React from 'react';
import { ButtonProps } from '@material-ui/core';
import { Button } from '@castiron/components';
import { getServiceUrl } from "../../../firebase";

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Props = Override<
  ButtonProps,
  {
    packageId: string;
  }
>;

const DownloadAllResourcesButton: React.FC<Props> = (props: Props) => {
  const { packageId, ...restOfProps } = props;

  // loops through resources to find files to download
  const handleDownloadAll = () => {
    const baseUrl = getServiceUrl('packages','downloadAllResources');
    window.location.href = `${baseUrl}?id=${packageId}`;
  };

  return (
    <Button onClick={() => handleDownloadAll()} {...restOfProps}>
      Download all resources
    </Button>
  );
};

export default DownloadAllResourcesButton;
