import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Button } from '@castiron/components';

interface Props {
  stepIndex: number;
  totalSteps: number;
  onContinueClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  currentStep: {
    backgroundColor: theme.branding.blue.primary,
    borderRadius: '38%',
    height: 8,
    marginRight: 8,
    width: 16,
  },
  footerContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
    },
  },
  step: {
    backgroundColor: theme.branding.gray[400],
    borderRadius: '100%',
    height: 8,
    marginRight: 8,
    width: 8,
  },
  stepContainer: {},
}));

const OnboardingStepFooter: React.FC<Props> = (props: Props) => {
  const { stepIndex, totalSteps, onContinueClick } = props;
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <Grid container className={classes.footerContainer} item alignItems="center" justify="space-between" xs={12}>
      <Grid container item className={classes.stepContainer} alignItems="center" xs={6}>
        {Array.from({ length: totalSteps }, (_, i) => (
          <span key={i} className={`${i === stepIndex ? classes.currentStep : classes.step}`}></span>
        ))}
      </Grid>
      <Grid container item justify="flex-end" alignItems="center" xs={6}>
        <Button variant="contained" color="primary" onClick={onContinueClick}>
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default OnboardingStepFooter;
