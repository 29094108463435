import React from 'react';
import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  rowCount: number;
  value?: string;
};

const TextAreaInput: React.FC<Props> = (props: Props) => {
  const { label, secondaryLabel, name, rowCount, required, error, value } = props;

  const formik = useFormikContext();

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <TextField
        style={{ margin: '0px' }}
        variant='outlined'
        fullWidth
        name={name}
        multiline
        rows={rowCount}
        value={value ? value : formik.values[name]}
        onChange={formik.handleChange}
        error={!!error}
      />
    </InputWrapper>
  );
};

export default TextAreaInput;