import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@castiron/components';
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";

type Props = {
  message: ReactNode;
  onSuccess?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    marginBottom: 32,
  },
  wrapper: {
    padding: '32px 24px 40px'
  }
}));

const PaymentSuccess: React.FC<Props> = (props: Props) => {
  const { message, onSuccess } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onSuccess && onSuccess();
    dispatch(closeModal());
  };

  return (
    <Grid container direction='column' className={classes.wrapper}>
      <Grid container item justify='center' className={classes.message}>
        {typeof message === 'string' ?
          <Typography variant='body1'>{message}</Typography>
        :
          message
        }
      </Grid>
      <Grid container item justify='flex-end'>
        <Button variant='contained' onClick={() => handleClose()} style={{ padding: 16 }}>Done</Button>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
