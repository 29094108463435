import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const CustomersRedirect: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const contactPathname = pathname.replace('customers', 'contacts');
  history.push(contactPathname);
  return (
    <></>
  );
};

export default CustomersRedirect;
