import React, { ReactNode, useEffect } from 'react';
import { Button, Grid, makeStyles, useMediaQuery, useTheme, } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles'
import {
  CreditCardOutlined,
  ListRounded,
  LocalOfferOutlined,
  LocalShippingOutlined,
  PaletteOutlined
} from '@material-ui/icons/';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { Typography, useFeatures, } from '@castiron/components';
import { FeatureName } from '@castiron/domain';
import { Link } from 'react-router-dom';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Layout/AvatarMenu';
import ViewShopButton from '../Layout/Header/ViewShopButton';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '16px 16px 18px 16px',
    border: `1px solid ${ theme.branding.gray[400] }`,
    '&:hover': {
      backgroundColor: theme.branding.gray[100],
      border: `1px solid ${ theme.branding.blue.primary }`
    },
    justifyContent: 'flex-start',
    height: '100%'
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px'
    }
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  buttonText: {
    marginLeft: '16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  icon: {
    height: '30px',
    width: '30px'
  },
  iconContainer: {
    backgroundColor: theme.branding.blue.light,
    color: theme.branding.blue.dark,
    borderRadius: 8,
    height: '70px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

interface SettingLink {
  name: string;
  subtitle: string;
  icon: ReactNode;
  path: string;
  feature?: FeatureName;
}

const SettingsDashboard: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const features = useFeatures();

  const links = ([
    {
      name: 'Appearance',
      subtitle: 'Manage the look and feel of your shop.',
      icon: <PaletteOutlined className={ classes.icon }/>,
      path: '/store/appearance'
    },
    {
      name: 'Photo Gallery',
      subtitle: 'Showcase your current and past work.',
      icon: <PhotoLibraryOutlinedIcon className={ classes.icon }/>,
      path: '/store/gallery',
      feature: 'shop.gallery'
    },
    {
      name: 'Business Details',
      subtitle: 'Manage dietary info, links, and more.',
      icon: <ListRounded className={ classes.icon }/>,
      path: '/store/business-details'
    },
    {
      name: 'Fulfillment',
      subtitle: 'Manage how your customers retrieve their items.',
      icon: <LocalShippingOutlined className={ classes.icon }/>,
      path: '/store/fulfillment'
    },
    {
      name: 'Payments',
      subtitle: 'Manage how you get paid and your fees.',
      icon: <CreditCardOutlined className={ classes.icon }/>,
      path: '/store/payments'
    },
    {
      name: 'Coupons',
      subtitle: 'Manage your coupons and associated discounts.',
      icon: <LocalOfferOutlined className={ classes.icon }/>,
      path: '/store/coupons',
      feature: "admin.coupons"
    },
    {
      name: 'Plans & Pricing',
      subtitle: 'Manage your Castiron plan.',
      icon: <ReceiptIcon className={ classes.icon }/>,
      path: '/store/plans'
    },
  ] as SettingLink[]).filter(s => s.feature ? features.includes(s.feature) : true);

  useEffect(() => {
    setPageTitle('Shop Settings');
    setFooterCTAs([]);

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<ViewShopButton/>, <AvatarMenu/>])
    } else {
      setHeaderCTAs([<ViewShopButton/>]);
    }
  }, [isMobile]);

  return (
    <Grid container className={ classes.buttonsWrapper }>
      <Helmet>
        <title>Settings | Castiron</title>
      </Helmet>
      { links.map((link, index) => (
        <Grid xs={ 12 } sm={ 6 } lg={ 4 } item key={ index } className={ classes.buttonContainer }
              justify={ !isMobile ? 'flex-start' : 'center' }>
          <Button component={ Link } to={ link.path } variant='outlined' className={ classes.button } fullWidth>
            <Grid className={ classes.buttonContent }>
              <Grid className={ classes.iconContainer }>
                { link.icon }
              </Grid>
              <Grid container item direction='column' className={ classes.buttonText }>
                <Typography variant='subtitle1'>{ link.name }</Typography>
                <Typography variant='body4'>{ link.subtitle }</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )) }
    </Grid>
  );
};

export default SettingsDashboard;
