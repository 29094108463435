import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import * as yup from 'yup';
import Container from '../Container';
import { Formik, FormikProps } from 'formik';
import DesktopForm from './DesktopForm';
import MobileForm from './MobileForm';
import { couponRepository, marketingSendRepository } from '@castiron/client-admin/src/domain';
import Spinner from '../../Spinner';
import { SubscriberCouponMarketingSend } from '@castiron/domain/src/marketing';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Coupon } from '@castiron/domain/src';
import { useTracking } from '@castiron/utils';
import { defaultMsg } from '../EditUpdateEmail';
import { ChecklistValues } from '@castiron/domain';
import { updateChecklistAction } from '@castiron/client-admin/src/store/reducers/shops';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import { LayoutPageProps } from '../../Layout';

export type FormValues = {
  status: string;
  artisanMessage: string;
};

const EditNewSubscriberEmail: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [subscriberMarketingSend, setSubscriberMarketingSend] = useState<SubscriberCouponMarketingSend | null>(null);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [getMarketingSendLoading, setGetMarketingSendLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle('Edit Email');
    setBackLocation(true);
    setHeaderCTAs([]);
    setFooterCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    const getSubscriberMarketingSend = async () => {
      setGetMarketingSendLoading(true);
      const marketingSendResponse = await marketingSendRepository.listByType(shop.id, 'subscriber_coupon');

      if (!!marketingSendResponse.length) {
        const marketingSend = marketingSendResponse[0] as SubscriberCouponMarketingSend;
        setSubscriberMarketingSend(marketingSend);

        const getCouponResponse = await couponRepository.get(marketingSend.couponId);
        setCoupon(getCouponResponse);
      }
      setGetMarketingSendLoading(false);
    };

    if (shop?.id) {
      getSubscriberMarketingSend();
    }
  }, [shop]);

  const onSubmit = async (values: FormValues) => {
    setSubmitLoading(true);
    if (subscriberMarketingSend && subscriberMarketingSend.id) {
      const newMarketingSend = {
        id: subscriberMarketingSend.id,
        shopId: subscriberMarketingSend.shopId,
        type: subscriberMarketingSend.type,
        templateId: subscriberMarketingSend.templateId || 'bfihusWJ8zjV5rxbcQQB',
        couponId: subscriberMarketingSend.couponId,
        ...values,
      } as SubscriberCouponMarketingSend;
      await marketingSendRepository.update(newMarketingSend);
      trackEvent('Subscriber Coupon Updated', { newsletterMarketingSend: newMarketingSend });
    } else {
      const newCoupon = {
        code: 'SUBSCRIBERTHANKYOU',
        discount: {
          type: 'percent' as 'percent',
          value: 10,
        },
        duration: {
          startDate: moment().unix(),
        },
        metrics: {
          totalRevenue: 0,
          totalUses: 0,
        },
        maximumPerCustomer: 1,
        shopId: shop.id,
        status: 'active',
      };
      const createCouponResponse = await couponRepository.create(newCoupon);
      if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
        dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] }));
      }
      trackEvent('Coupon Created', { coupon: newCoupon });

      const newMarketingSend = {
        shopId: shop.id,
        type: 'subscriber_coupon',
        templateId: 'bfihusWJ8zjV5rxbcQQB',
        couponId: createCouponResponse.id,
        ...values,
      } as SubscriberCouponMarketingSend;
      await marketingSendRepository.create(newMarketingSend);
      if (!shop.checklistCompletions?.includes(ChecklistValues.EmailMarketing)) {
        dispatch(updateChecklistAction({ shop, items: [ChecklistValues.EmailMarketing] }));
      }
      trackEvent('Subscriber Coupon Created', { newsletterMarketingSend: newMarketingSend });
    }
    setSubmitLoading(false);
    history.push('/marketing');
  };

  const formSchema = yup.object().shape({
    status: yup.string().required('Active value required'),
    artisanMessage: yup.string().required('Personal sign off is required'),
  });

  return (
    <Container>
      {getMarketingSendLoading || submitLoading ? (
        <Grid justify="center" container>
          <Spinner show={true} size={'relative'} />
        </Grid>
      ) : (
        <Formik
          validateOnMount
          onSubmit={onSubmit}
          validationSchema={formSchema}
          initialValues={{
            status: subscriberMarketingSend?.status || 'inactive',
            artisanMessage: subscriberMarketingSend?.artisanMessage || defaultMsg,
          }}
        >
          {(formikProps: FormikProps<FormValues>): ReactElement => {
            return (
              <>
                <UnsavedChangesPrompt when={formikProps.dirty} />
                {isMobile ? (
                  <MobileForm
                    {...{
                      formikProps,
                      coupon,
                      marketingSendId: subscriberMarketingSend?.id,
                    }}
                  />
                ) : (
                  <DesktopForm
                    {...{
                      formikProps,
                      coupon,
                      marketingSendId: subscriberMarketingSend?.id,
                    }}
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};

export default EditNewSubscriberEmail;
