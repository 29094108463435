import React from 'react';
import { Avatar, Grid, Hidden, makeStyles, Theme } from '@material-ui/core';
import { HeaderText, NewChip, PriceTagChip, DateTagChip, AddedTag } from '@castiron/components';
import Dinero from 'dinero.js';
import moment from 'moment';
import { CommunityPackage, Product } from '@castiron/domain';
import { AddToShopButton } from '../CommunityPackageDetails';
import { PackageCardContainer } from '..';

const useStyles = makeStyles((theme: Theme) => ({
  packageCardContainer: {
    maxWidth: 475,
    minWidth: 255,
    [theme.breakpoints.up('sm')]: {
      minWidth: 475,
    },
  },
  header: {
    gap: 16,
  },
  image: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    borderRadius: 8,
  },
  nameText: {
    marginLeft: 16,
    overflowWrap: 'break-word',
  },
  tagsContainer: {
    marginTop: 8,
  },
  priceTag: {
    marginRight: 8,
  },
  xsMobileAddToShopButton: {
    marginTop: 16,
  },
}));

type Props = {
  communityPackage: CommunityPackage;
};

// Small community package card
const SmallCommmunityPackageCard: React.FC<Props> = (props: Props) => {
  const { communityPackage } = props;

  const classes = useStyles();

  const product = communityPackage?.products?.length && (communityPackage.products[0] as Product);

  const fromDateFormatted =
    !communityPackage.activeFrom || moment().unix() > communityPackage.activeFrom
      ? 'Now'
      : moment.unix(communityPackage.activeFrom).format('MM/DD');
  const toDateFormatted = !communityPackage.activeTo ? '?' : moment.unix(communityPackage.activeTo).format('MM/DD');

  return (
    <PackageCardContainer communityId={communityPackage.id} className={classes.packageCardContainer}>
      {/* Header */}
      <Grid container justify="space-between" wrap="nowrap" className={classes.header}>
        {/* Avatar and title */}
        <Grid container alignItems="center" wrap="nowrap">
          <Avatar
            variant="square"
            classes={{ root: `${classes.image}`, img: `${classes.image}` }}
            src={product?.imageObj?.downloadUrl}
          />
          <HeaderText presetFont="heading--xs" lineHeight={20} className={classes.nameText}>
            {communityPackage.title}
            <NewChip />
          </HeaderText>
        </Grid>

        {/* AddToShopButton */}
        <Hidden xsDown>
          <Grid>
            {!!communityPackage.addedProductId ? (
              <AddedTag added={!!communityPackage.addedProductId} />
            ) : (
              <AddToShopButton
                variant="outlined"
                size="small"
                style={{ width: 110, zIndex: 1 }}
                communityPackageId={communityPackage?.id}
              />
            )}
          </Grid>
        </Hidden>
      </Grid>

      {/* Tags */}
      <Grid className={classes.tagsContainer} container>
        {product?.price && (
          <PriceTagChip className={classes.priceTag}>
            {Dinero({ amount: product?.price }).toFormat('$0.00')}
          </PriceTagChip>
        )}

        {/* Date tag */}
        <DateTagChip>
          {fromDateFormatted} - {toDateFormatted}
        </DateTagChip>
      </Grid>

      {/* AddToShopButton */}
      <Hidden smUp>
        {!!communityPackage.addedProductId ? (
          <AddedTag className={classes.xsMobileAddToShopButton} added={!!communityPackage.addedProductId} />
        ) : (
          <AddToShopButton
            variant="outlined"
            size="small"
            fullWidth
            communityPackageId={communityPackage?.id}
            className={classes.xsMobileAddToShopButton}
          />
        )}
      </Hidden>
    </PackageCardContainer>
  );
};

export default SmallCommmunityPackageCard;
