import React from 'react';
import { Avatar, Grid, makeStyles, Theme } from '@material-ui/core';
import { Text, HeaderText, NewChip, PriceTagChip, DateTagChip, AddedTag } from '@castiron/components';
import Dinero from 'dinero.js';
import moment from 'moment';
import { CommunityPackage, Product } from '@castiron/domain';
import { AddToShopButton } from '../CommunityPackageDetails';
import { PackageCardContainer } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  packageCardContainer: {
    maxWidth: 335,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
      borderRadius: 0,
    },
  },
  image: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    borderRadius: 8,
  },
  nameText: {
    marginLeft: 16,
    overflowWrap: 'break-word',
  },
  tagsContainer: {
    marginTop: 8,
  },
  priceTag: {
    marginRight: 8,
  },
  descriptionContainer: {
    marginTop: 24,
    flex: 1,
  },
  actionsContainer: {
    marginTop: 24,
  },
}));

type Props = {
  communityPackage: CommunityPackage;
};

// Regular community package card
const CommunityPackageCard: React.FC<Props> = (props: Props) => {
  const { communityPackage } = props;

  const classes = useStyles();

  const imgProps = {
    height: 64,
    width: 64,
  };

  // only grab first 160 characters of description
  const modifiedDescription =
    communityPackage.description.length > 160
      ? communityPackage.description.slice(0, 160) + '...'
      : communityPackage.description;

  const product = communityPackage?.products?.length && (communityPackage.products[0] as Product);

  const fromDateFormatted =
    !communityPackage.activeFrom || moment().unix() > communityPackage.activeFrom
      ? 'Now'
      : moment.unix(communityPackage.activeFrom).format('MM/DD');
  const toDateFormatted = !communityPackage.activeTo ? '?' : moment.unix(communityPackage.activeTo).format('MM/DD');

  return (
    <PackageCardContainer communityId={communityPackage.id} className={classes.packageCardContainer}>
      {/* Main section */}
      <Grid container direction="column" style={{ flex: 1 }}>
        {/* Header */}
        <Grid container alignItems="center" wrap="nowrap">
          {/* Product image */}
          <Avatar
            variant="square"
            classes={{ root: `${classes.image}`, img: `${classes.image}` }}
            imgProps={imgProps}
            src={product?.imageObj?.downloadUrl}
          />

          {/* Community package title */}
          <HeaderText presetFont="heading--xs" lineHeight={20} className={classes.nameText}>
            {communityPackage.title}

            <NewChip />
          </HeaderText>
        </Grid>

        {/* Tags */}
        <Grid className={classes.tagsContainer} container>
          {product?.price && (
            <PriceTagChip className={classes.priceTag}>
              {Dinero({ amount: product?.price }).toFormat('$0.00')}
            </PriceTagChip>
          )}
          {/* Date tag */}
          <DateTagChip>
            {fromDateFormatted} - {toDateFormatted}
          </DateTagChip>
        </Grid>

        {/* Description  */}
        <Grid container className={classes.descriptionContainer}>
          <Text
            presetFont="txt--m"
            dangerouslySetInnerHTML={{
              __html: modifiedDescription,
            }}
          />
        </Grid>
      </Grid>

      {/* Buttons container */}
      <Grid container justify="flex-end" className={classes.actionsContainer}>
        {!!communityPackage.addedProductId ? (
          <AddedTag added={!!communityPackage.addedProductId} />
        ) : (
          <AddToShopButton variant="contained" size="small" communityPackageId={communityPackage?.id} />
        )}
      </Grid>
    </PackageCardContainer>
  );
};

export default CommunityPackageCard;
