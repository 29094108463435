import { Button, CalendarMonthIcon, FlourAndEggs, Typography } from '@castiron/components';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: '48px',
    marginTop: '8px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
      height: 'fit-content',
    },
  },
  container: {
    background: theme.branding.gray[100],
    padding: '32px',
    textAlign: 'center',
    height: '100%',
    maxWidth: '588px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '8px',
      minHeight: '648px',
    },
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderRadius: '12px',
    },
  },
  header: {
    marginTop: '24px',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '12px',
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    margin: '16px',
    width: '428px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px 0px',
    }
  },
}));

const OnboardingMigrationSubmitted: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container item className={classes.container} direction="column" alignItems="center" justify="space-between">
      <FlourAndEggs />
      <Typography className={classes.header} variant="h2">We’ve received your ingredients!</Typography>
      <Typography variant="body3">What now? We’ll get to work and reach out within a business day
with your freshly baked shop.</Typography>
      <div className={classes.videoContainer}>
        <iframe src="https://www.loom.com/embed/4b00a719588047e0b7ec8b6489aed588" frameBorder="0" allowFullScreen className={classes.video}></iframe>
      </div>
      <Typography variant="body3">Want a personal walk through of your new shop?</Typography>

      <Button
        startIcon={<CalendarMonthIcon />}
        variant="contained"
        className={classes.button}
        onClick={() => {
          window.open('https://calendly.com/lindsay-at-castiron/30min', '_blank').focus();

        }}
      >
        Schedule a Walk Through
      </Button>
    </Grid>
  );
};

export default OnboardingMigrationSubmitted;
