import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { Box, ButtonBase, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import { Typography } from '@castiron/components';
import ChevronDownIcon from '../../../../assets/img/chevron-down.svg';
import TrashIcon from '../../../../assets/img/trash.svg';
import { SvgIcon } from '@castiron/components';
import Popover from '../../../Popover/Popover';
import AddCircle from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import { productRepository, shopRepository } from '@castiron/client-admin/src/domain';
import { updateStoreShopAction } from '@castiron/client-admin/src/store/reducers/shops';
import { nanoid } from 'nanoid';

type Props = {
  options?: string[];
  onChange: (e) => void;
  value?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 48,
    border: `1px solid ${theme.branding.gray[700]}3D`,
    borderRadius: 4,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 14,
  },
  value: {
    fontSize: 14,
  },
  chevron: {
    height: 32,
    width: 32,
  },
  buttonBase: {
    width: '100%',
  },
  paper: {
    padding: 8,
    minWidth: 300,
  },
  addNew: {
    color: theme.branding.link,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 8,
    height: 38,
    alignItems: 'center',
  },
  categoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 38,
    alignItems: 'center',
  },
  selectableCategory: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: 38,
    paddingLeft: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}3D`,
    },
  },
  categoryText: {
    fontSize: 14,
  },
  addNewText: {
    fontSize: 14,
  },
  deleteButton: {
    width: 38,
    height: 38,
  },
  trash: {
    color: theme.palette.error.main,
  },
  input: {
    height: 38,
    fontSize: 14,
  },
  addButtonBase: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  icon: {
    marginLeft: 8,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
}));

const DynamicSelect: React.FC<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { onChange, value } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setEditMode(false);
    setAnchorEl(null);
  };

  const onCategoryClick = (category): void => {
    onChange(category);
    handleClose();
  };

  const deleteCategory = async (category): Promise<void> => {
    if (value && category && value.name === category.name) onChange('');

    const updatedShop = {
      ...shop,
      categories: [...shop.categories?.filter(cat => cat.id !== category.id)],
    };

    await shopRepository.updateProps(shop.id, {
      categories: updatedShop.categories
    });

    await productRepository.batch(
      products.filter(p => p.category?.id === category.id).map(product => ({ ...product, category: null })),
    );

    await dispatch(updateStoreShopAction({ shop: updatedShop }));
  };

  const onEditFinished = async (): Promise<void> => {
    const newCategory = {
      shopId: shop.id,
      name: inputValue,
      id: nanoid(),
    };

    const newCategories = [...(shop?.categories || []), newCategory].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    const updatedShop = {
      ...shop,
      categories: shop.categories ? newCategories : [newCategory],
    };

    await dispatch(updateStoreShopAction({ shop: updatedShop }));
    await shopRepository.updateProps(shop.id, {
      categories: updatedShop.categories
    });

    onChange(newCategory);
    setInputValue('');
    handleClose();
  };

  const selectedCategory = value ? shop?.categories?.find(item => item.id === value.id) : null;

  return (
    <>
      <ButtonBase focusRipple className={classes.buttonBase} onClick={handleClick}>
        <Box className={classes.container}>
          <Typography className={classes.value}>
            {selectedCategory ? selectedCategory.name : 'Select category'}
          </Typography>
          <SvgIcon className={classes.chevron}>
            <ChevronDownIcon />
          </SvgIcon>
        </Box>
      </ButtonBase>
      <Popover
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        paperClass={classes.paper}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {shop.categories &&
          shop.categories.length > 0 &&
          shop.categories.map((category, idx) => {
            return (
              <div className={classes.categoryContainer} key={idx}>
                <ButtonBase
                  focusRipple
                  onClick={(): void => onCategoryClick(category)}
                  className={classes.selectableCategory}
                  key={idx}
                >
                  <Typography className={classes.categoryText}>{category.name}</Typography>
                </ButtonBase>
                <IconButton className={classes.deleteButton} focusRipple onClick={() => deleteCategory(category)}>
                  <SvgIcon className={classes.trash}>
                    <TrashIcon />
                  </SvgIcon>
                </IconButton>
              </div>
            );
          })}
        {editMode ? (
          <div className={classes.inputContainer}>
            <TextField
              fullWidth
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Category name"
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
              variant="outlined"
              autoFocus
            />
            <IconButton className={classes.icon} onClick={onEditFinished}>
              <Check />
            </IconButton>
          </div>
        ) : (
          <ButtonBase focusRipple onClick={(): void => setEditMode(true)} className={classes.addNew}>
            <AddCircle />
            <Typography className={classes.addNewText}>Add new</Typography>
          </ButtonBase>
        )}
      </Popover>
    </>
  );
};

export default DynamicSelect;
