import * as yup from 'yup';
import { phoneRegExp } from '../base/model';

export const customerDetailsSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Please enter your full name'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter your email'),
  mobileNumber: yup
    .string()
    .matches(phoneRegExp, 'Must be a valid 10-digit phone number format')
    .required('Please enter your phone number'),
  subscribed: yup.boolean(),
});