import React from "react";
import { useHistory } from "react-router-dom";
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonV2, Card, OrderStatusPill, Typography } from "@castiron/components";
import { LineItem, Transaction, transactionToEventModel } from "@castiron/domain";
import { useTracking } from '@castiron/utils';
import Close from "@material-ui/icons/Close";
import ModalWrapper from "../../RootModal/ModalWrapper";
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import FulfillmentDetailsBox from "../../FulfillmentDetailsBox";
import GiftDetailsBox from "../../GiftDetailsBox";
import DetailsBox from "../../Orders/DetailsBox";
import QuoteRequestDetailsBox from "../../Orders/QuoteRequestDetailsBox";

export type Props = {
  transaction: Transaction;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  customerDetails: {
    color: '#71717A',
  },
  emptyContent: {
    backgroundColor: '#F5F9FF',
    borderRadius: 12,
    padding: '24px 16px',
    textAlign: 'center',
  },
  footer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '12px 24px',
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '22px 16px',
    maxHeight: 71,
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  modalWrapper: {
    [theme.breakpoints.up('md')]: {
      height: 656,
      width: 571,
    },
  }
}));

const IndividualListingModal: React.FC<Props> = (props: Props) => {
  const { transaction } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { trackEvent } = useTracking();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isQuote = transaction?.order?.type === 'custom';
  const customer = transaction?.customerObj;

  let headerText;
  let ctaText;
  let context: 'quote' | 'order';

  if (isQuote) {
      if (transaction.frontendState('quote') === 'paid') {
        headerText = `Custom Order #${transaction?.order?.orderNumber}`;
        ctaText = `View Order`;
        context = 'order';
      } else {
        headerText = `Quote #${transaction?.order?.orderNumber}`;
        ctaText = `View Quote`;
        context = 'quote';
      }
    } else {
      headerText = `Instant Checkout Order #${transaction?.order?.orderNumber}`;
      ctaText = `View Order`;
      context = 'order';
    }

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleIconClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Icon Clicked',
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
  };

  const handleBackgroundClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Background Clicked',
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
  };

  const updateCalendar =  () => {
    /* TODO: refresh calendar once we allow artisans to edit fulfillment dates and times */
  };

  const orderedItems: LineItem[] = transaction?.order?.items
    ? transaction?.order.items
    : transaction?.products.map(p => ({
      id: p.product.id,
      title: p.product.title,
      description: p.product.description,
      category: p.product.category,
      price: p.product.price,
      quantity: p.quantity,
      selections: p.selectedVariationValues,
      type: transaction?.order?.type,
    }));

  const emptyContent = {
    customer: {
      header: 'No Customer Added',
      body: `View ${context} to add a new or existing customer to the ${context}.`,
    },
    fulfillment: {
      header: 'No Fulfillment Added',
      body: `View ${context} to add fulfillment details including method, location, and, due date and time.`,
    },
    products: {
      header: 'No Products Added',
      body: `View ${context} to add products and quickly calculate ${isQuote ? 'a quote' : 'an order'} for your customer.`,
    },
  };

  const getEmptyContent = (contentType: 'customer' | 'fulfillment' | 'products') => {
    return (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyContent}>
        <Typography variant="subtitle1">{emptyContent[contentType].header}</Typography>
        <Typography variant="body1">{emptyContent[contentType].body}</Typography>
      </Grid>
    );
  };

  const customerDetails = (
    <Card title="Customer">
      {transaction?.customerObj
        ?
        <Grid container direction="column">
          {(customer?.firstName || customer?.lastName) && <Typography variant="body1">{`${customer?.firstName || ''} ${customer?.lastName || ''}`}</Typography>}
          {customer?.email && <Typography variant="body2" className={classes.customerDetails}>{customer?.email}</Typography>}
          {customer?.mobileNumber && <Typography variant="body2" className={classes.customerDetails}>{customer?.mobileNumber}</Typography>}
        </Grid>
        : getEmptyContent('customer')
      }
    </Card>
  );

  const fulfillmentDetails = transaction?.order?.fulfillmentOption
    ? <FulfillmentDetailsBox customer={customer} transaction={transaction} onUpdate={updateCalendar} readonly/>
    : <Card title="Fulfillment">{getEmptyContent('fulfillment')}</Card>;

  const productDetails = orderedItems?.length > 0 && orderedItems[0]?.id
    ?
    <>
      <DetailsBox overrideTitle="Products" transaction={transaction} removePaymentDetails />
      {isQuote && transaction?.order?.items[0]?.selections?.length > 0 && (
        <QuoteRequestDetailsBox orderedItems={orderedItems} />
      )}
    </>
    : <Card title="Products">{getEmptyContent('products')}</Card>;

  const goToItemDetails = () => {
    trackEvent('Calendar View Item Clicked', {
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
    history.push(`/${context}s/edit/${transaction?.id}`);
  };


  return (
    <ModalWrapper fullScreen={isMobile} size="lg" show={true} paperClass={classes.modalWrapper} onClose={handleBackgroundClose}>
      <DialogTitle className={classes.header}>
        <Grid container direction='row' justify='flex-start' alignItems='center'>
          <Grid item style={{ marginRight: 8 }}>
            <Typography variant="h3">
              {headerText}
            </Typography>
          </Grid>
          <Grid item>
            <OrderStatusPill transaction={transaction} context={context} />
          </Grid>
        </Grid>
        <IconButton className={classes.closeIcon} onClick={handleIconClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 24 }}>
        {customerDetails}
        {fulfillmentDetails}
        {productDetails}
        <GiftDetailsBox order={transaction} />
      </DialogContent>
      <DialogActions className={classes.footer}>
        <ButtonV2
          onClick={goToItemDetails}
          variant="contained"
        >
          {ctaText}
        </ButtonV2>
      </DialogActions>
    </ModalWrapper>
  );
}

export default IndividualListingModal;
