import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FiberManualRecord, FiberManualRecordOutlined } from '@material-ui/icons';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import clsx from "clsx";
import { Banner, Button, Card, MoneyInput, TitleInput, Typography } from '@castiron/components';
import {
  addressSchema,
  ChecklistValues,
  FulfillmentOption,
  FulfillmentType,
  fulfillmentTypeDisplayName,
  FulfillmentOptionSchedule,
  TimePeriod,
  shopToEventModel,
} from '@castiron/domain';
import { defaultTimeZone, isset, removeEmpty, useTracking } from '@castiron/utils';
import { shopRepository } from '../../../../domain';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getShopAction, updateChecklistAction } from '../../../../store/reducers/shops';
import AdminForm from '../../../AdminForm';
import Dropdown from '../../../Dropdown';
import { LayoutPageProps } from '../../../Layout';
import DeleteButton from '../DeleteButton';
import DeleteDialog from '../DeleteDialog';
import PickupDetails from './PickupDetails';
import DeliveryDetails from './DeliveryDetails';
import ShippingDetails from './ShippingDetails';

interface Params {
  id?: string,
  type?: FulfillmentType
};

export const EmptyTimePeriod: TimePeriod = {
  id: nanoid(),
  startTime: null,
  endTime: null,
}

export const EmptyFulfillmentSchedule: FulfillmentOptionSchedule = {
  id: nanoid(),
  type: 'fixed',
  dates: [EmptyTimePeriod],
  description: '',
}

const fulfillmentSchema = (pageType: FulfillmentType) => yup.object().shape({
  status: yup.string().oneOf(['active', 'inactive']).required(),
  type: yup.string().required(),
  displayName: yup.string().required('Please enter a title.'),
  description: yup
    .string()
    .max(2500, 'Must be less than 2500 characters.')
    .required('Please enter details.'),
  afterPurchaseDetails: yup.string().max(2500, 'Must be less than 2500 characters.'),
  fee: yup.number().nullable(),
  minimum: yup.number().nullable(),
  postalCode: yup
    .string()
    .nullable(), // legacy field saved as "Zip Code, Neighborhood, Borough".
  address: addressSchema(pageType === 'pickup'),
  processingTime: yup.object({
    increments: yup.number().typeError('Please enter a number.'),
    incrementType: yup.string().oneOf(['day', 'week']),
  }),
  schedule: yup.array().of(
    yup.object({
      id: yup.string().required(),
      type: yup.string().oneOf(['fixed', 'flexible']).required(),
      dates: yup.array().of(
        yup.object({
          id: yup.string().nullable(),
          startTime: yup.number().nullable(),
          endTime: yup.number().nullable(),
        })
      ).nullable(),
      description: yup
        .string()
        .when('type', {
          is: (val) => val === 'flexible',
          then: (schema) => schema.required('Please enter flexible fulfillment details.'),
        }),
    })
  ).nullable(),
  sendPickupReminderEmail: yup.boolean(),
});

const errorToFieldName = (error: string, fulfillmentType: FulfillmentType) => {
  switch (error) {
    case 'displayName': return 'Title';
    case 'description': return 'Details';
    case 'postalCode': return 'Neighborhood/Borough/Zip Code';
    case 'address': return `${fulfillmentTypeDisplayName(fulfillmentType)} Address`;
  }
  return null;
};

const parentPage = '/store/fulfillment';
const useStyles = makeStyles((theme: Theme) => ({
  activeIcon: {
    color: theme.branding.green.primary,
  },
  container: {
    /* hack until we get the main container aligned to the header margins */
    padding: '0 41px 0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  errorFields: {
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'underline',
    display: 'inline',
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    }
  },
  inactiveIcon: {
    color: theme.branding.gray[600],
  },
  mobileIcon: {
    [theme.breakpoints.down('sm')]: {
      height: '8px',
      width: '8px',
    },
  },
}));

const EditFulfillment: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const { id, type } = useParams<Params>();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { shop, fulfillments } = useAppSelector(state => ({
    shop: state.shops.shop,
    fulfillments: state.shops.fulfillments,
  }));

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const formRef = useRef<FormikProps<any>>();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditing = isset(id);
  const existingFulfillment = isEditing ? fulfillments.find(ff => ff.id === id) : null;
  const pageType = existingFulfillment?.type || type;

  /* have to maintain this locally, since changes to form values won't trigger rerender */
  const [ffStatus, setFFStatus] = useState(existingFulfillment?.status || 'active');

  useEffect(() => {
    if (isEditing && !existingFulfillment) {
      console.error('No fulfillment found for id: ', id);
      history.push(parentPage);
    }
    setBackLocation(true);
    setFooterCTAs([
      isEditing && <DeleteButton variant='footer' onDelete={() => setDeleteDialogOpen(true)} />,
      <Button
        variant='outlined'
        onClick={() => history.push(parentPage)}
        className={classes.footerButton}
      >
        Discard
      </Button>,
      <Button
        variant='contained'
        onClick={async () => {
          if (!formRef.current.isSubmitting) {
            setHasSubmitted(true);

            await formRef.current.validateForm();
            if (!formRef.current.isValid) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }

            formRef.current.submitForm();
          }
        }}
        className={classes.footerButton}
      >
        Save
      </Button>,
    ]);

    return () => {
      setBackLocation(false);
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    const basePageTitle = `${fulfillmentTypeDisplayName(pageType, 'short')} Option`;
    if (isMobile) {
      setPageTitle(basePageTitle);
    } else {
      setPageTitle(`${isEditing ? 'Edit' : 'Add'} ${basePageTitle}`);
    }
    setHeaderCTAs([
      <Dropdown
        variant={isMobile ? 'no-arrow' : 'arrow'}
        title={
          <Typography variant='button'>
            {
              ffStatus === 'active' ? (
                <FiberManualRecord className={clsx(classes.mobileIcon, classes.activeIcon)} />
              ) : (
                <FiberManualRecordOutlined className={clsx(classes.mobileIcon, classes.inactiveIcon)} />
              )
            }
            {` ${_.capitalize(formRef.current.values.status)}`}
          </Typography>
        }
        options={[
          {
            label: 'Active',
            icon: <FiberManualRecord className={classes.activeIcon} />,
            onClick: () => {
              formRef.current.setFieldValue('status', 'active');
              setFFStatus('active');
            }
          },
          {
            label: 'Inactive',
            icon: <FiberManualRecordOutlined className={classes.inactiveIcon} />,
            onClick: () => {
              formRef.current.setFieldValue('status', 'inactive');
              setFFStatus('inactive');
            }
          },
        ]}
      />
    ]);
  }, [ffStatus, isMobile]);

  const initialValues: FulfillmentOption = {
    status: existingFulfillment?.status || 'active',
    type: existingFulfillment?.type || type,
    displayName: existingFulfillment?.displayName || '',
    description: existingFulfillment?.description || '',
    afterPurchaseDetails: existingFulfillment?.afterPurchaseDetails || '',
    fee: existingFulfillment?.fee || 0,
    minimum: existingFulfillment?.minimum || 0,
    postalCode: existingFulfillment?.postalCode || '',
    address: existingFulfillment?.address || {
      fullAddress: '',
      addressLine1: '',
      city: '',
      region: '',
      postalCode: '',
      country: ''
    },
    processingTime: existingFulfillment?.processingTime || {
      increments: -1,
      incrementType: 'day',
    },
    schedule: existingFulfillment?.schedule || EmptyFulfillmentSchedule,
    sendPickupReminderEmail: existingFulfillment?.sendPickupReminderEmail === false ? false : true,
  };

  const onSubmit = async (values: FulfillmentOption) => {
    const saveProcessingTime = values.processingTime.increments && values.processingTime.increments >= 0;

    let tempSave = {
      ...existingFulfillment,
      ...values,
      processingTime: saveProcessingTime ? values.processingTime : null,
    } as FulfillmentOption;

    // clean up schedule date/times if shipping or empty'
    if (tempSave.schedule && tempSave.schedule.dates.length > 0) {
      if (tempSave.type === 'shipping' || (tempSave.schedule.type === 'fixed' && (!tempSave.schedule.dates[0].startTime && !tempSave.schedule.description))) {
        delete tempSave.schedule;
      } else {
        tempSave = {
          ...tempSave,
          schedule: {
            ...tempSave.schedule,
            dates: tempSave.schedule.dates.filter(date => date.startTime),
          },
        };
        /* we are saving dates, let's check and see if a timezone is set in the config */
        if (!shop.config?.timeZone) {
          /* if not, set one */
          await shopRepository.updateProps(shop.id, {
            config: {
              ...shop.config,
              timeZone: defaultTimeZone,
            },
          });
        }
      };
    };

    /* HACK: remove empty call is blowing up here due to read only props for reasons I don't understand at the moment, make a copy */
    const copyOfTemp = JSON.parse(JSON.stringify(tempSave));
    const toSave = removeEmpty(copyOfTemp) as FulfillmentOption;

    // remove empty dates from schedule.dates
    if (toSave.schedule) {
      toSave.schedule = removeEmpty(toSave.schedule) as FulfillmentOptionSchedule;
    };

    if (isEditing) {
      await shop.updateFulfillmentOption(id, toSave);
      if (existingFulfillment.processingTime && !saveProcessingTime) {
        await shop.deleteFulfillmentOptionProperties(id, ['processingTime']);
      }
      await dispatch(getShopAction(shop.id));
      history.push(`/store/fulfillment`);
    } else {
      await shop.addFulfillmentOption(toSave);
      if (!shop.checklistCompletions?.includes(ChecklistValues.FulfillmentAdded)) {
        await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.FulfillmentAdded] }));
      }
      await dispatch(getShopAction(shop.id));
      history.push(`/store/fulfillment`);
    }

    trackEvent('Shop Fulfillment Action', {
      shop: shopToEventModel(shop),
      action: isEditing ? 'updated' : 'created',
      fulfillment: toSave,
    });
  };

  return (
    <Box className={classes.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={fulfillmentSchema(pageType)}
        onSubmit={onSubmit}
        innerRef={formRef}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
      >
        {
          (formikProps: FormikProps<FulfillmentOption>) =>
            <AdminForm>
              <DeleteDialog open={deleteDialogOpen} option={existingFulfillment} onClear={() => setDeleteDialogOpen(false)} />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container direction='column' spacing={3}>
                    {
                      hasSubmitted && _.keys(formikProps.errors).length > 0 &&
                      <Grid item>
                        <Banner variant='error'>
                          <Typography variant='body2' style={{ color: 'inherit', display: 'inline' }} >
                            Please fill out all of the required information:&nbsp;
                          </Typography>
                          <Typography variant='body2' className={classes.errorFields}>
                            {_.keys(formikProps.errors).map(err => errorToFieldName(err, pageType)).join(', ')}
                          </Typography>
                        </Banner>
                      </Grid>
                    }
                    <Grid item>
                      <TitleInput name='displayName' placeholder='Title' error={formikProps.touched.displayName && formikProps.errors.displayName} />
                    </Grid>
                    {
                      formikProps.values.type === 'pickup' &&
                      <Grid item>
                        <PickupDetails />
                      </Grid>
                    }
                    {
                      formikProps.values.type === 'delivery' &&
                      <Grid item>
                        <DeliveryDetails />
                      </Grid>
                    }
                    {
                      formikProps.values.type === 'shipping' &&
                      <Grid item>
                        <ShippingDetails />
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container direction='column'>
                    <Grid item>
                      <Card title='Fees & Minimums'>
                        <Grid container direction='column' spacing={3}>
                          <Grid item>
                            <MoneyInput
                              label={`${fulfillmentTypeDisplayName(formikProps.values.type, 'short')} Fee (Optional)`}
                              name='fee'
                            />
                          </Grid>
                          <Grid item>
                            <MoneyInput
                              label={`Order Minimum (Optional)`}
                              name='minimum'
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Banner variant='info-blue'>
                        <Typography variant='body2' style={{ color: 'inherit' }}>
                          Please note: This option will only apply to instant checkout products.
                        </Typography>
                      </Banner>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AdminForm >
        }
      </Formik >
    </Box>
  );
};

export default EditFulfillment;
