import { Grid, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { Button, Typography } from '@castiron/components';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { useAppDispatch } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';

export type Props = {
  show?: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    margin: '16px 16px 0 0',
    cursor: 'pointer',
    color: theme.branding.gray[800],
    fontSize: 32,
  },
  modalEmoji: {
    marginBottom: 32,
    '& p': {
      fontSize: 63,
    },
  },
  modalFooter: {
    borderTop: `1px solid ${theme.branding.gray[200]}`,
    padding: '8px 24px',
    marginTop: 64,

    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
  modalHeader: {
    fontWeight: 700,
    fontSize: 21,
    lineHeight: '32px',
    marginBottom: 8,
  },
  modalText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  modalWrapper: {
    marginTop: 46,
    maxWidth: '60%',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
}));

const BusinessCTAModal: React.FC<Props> = (props: Props) => {
  const { show, onClose } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isXSMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper fullScreen={isXSMobile} show={show} onClose={handleClose} size="sm">
      <Grid container direction="column">
        <Grid container justify="flex-end">
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.modalWrapper}
        >
          <div className={classes.modalEmoji}>
            <Typography variant="body1">📬</Typography>
          </div>
          <Typography variant="h5" className={classes.modalHeader}>
            We’ll Be in Touch Soon!
          </Typography>
          <Typography variant="body1" className={classes.modalText}>
            Our team has been notified of your interest in the Business Plan and will reach out to you shortly.
          </Typography>
        </Grid>
        <Grid container item justify="flex-end" className={classes.modalFooter}>
          <Button variant="contained" onClick={() => handleClose()} style={{ padding: 16 }}>
            Got It!
          </Button>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default BusinessCTAModal;
