import _firestore from '@google-cloud/firestore';
import firebase from 'firebase/compat/app';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { SendGridConfig } from '../account';
import { FieldFunctions } from '../base/repository';
import { MessageType } from '../message';

export interface MarketingSend extends ShopRelatedDocument<MarketingSend> {
  type: MessageType;
  templateId: string;
  artisanMessage: string;
  lastSentDate?: number;
}

export type MarketingSendFrequency = 'weekly' | 'biweekly' | 'monthly';

export interface NewsletterMarketingSend extends MarketingSend {
  type: 'newsletter';
  frequency: MarketingSendFrequency;
  includedProductIds: string[];
  lastSentProducts?: string[];
  contactListId: string;
}

export interface SubscriberCouponMarketingSend extends MarketingSend {
  type: 'subscriber_coupon';
  couponId: string;
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export class MarketingSendRepository extends ShopRelatedRepository<MarketingSend> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'marketing_sends', fieldFunctions);
  }

  public listByType<T extends MarketingSend>(shopId: string, type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'type', operator: '==', value: type }],
    }) as Promise<T[]>;
  }

  public getAsType<T extends MarketingSend>(id: string): Promise<T> {
    return this.get(id) as Promise<T>;
  }

  public findActiveOfType<T extends MarketingSend>(type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [
        { field: 'status', operator: '==', value: 'active' },
        { field: 'type', operator: '==', value: type },
      ],
    }) as Promise<T[]>;
  }

  public findActiveOfTypeForShop<T extends MarketingSend>(shopId: string, type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'status', operator: '==', value: 'active' },
        { field: 'type', operator: '==', value: type },
      ],
    }) as Promise<T[]>;
  }
}

export interface SendLog extends ShopRelatedDocument<SendLog> {
  marketingSend: MarketingSend;
  batchId?: string;
  sentAt: number;
  subject: string;
  body: string;
  sendGridSingleSendId?: string;
  customerId?: string;
  sendGridConfig?: SendGridConfig;
}

export class SendLogRepository extends ShopRelatedRepository<SendLog> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'send_log', fieldFunctions);
  }
}
