import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Nav from './Nav/Nav';
import { Drawer, Hidden } from '@material-ui/core';
import { useLocation } from 'react-router';

type Props = {
  open: boolean;
  setDrawerOpen: (status: boolean) => void;
};

const SideDrawer: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const { open, setDrawerOpen } = props;
  const { me } = useAppSelector(state => ({
    me: state.users.me,
  }));

  const classes = makeStyles((theme: Theme) => ({
    paper: {
      width: '280px',
      borderRightColor: theme.branding.gray[200],
      minHeight: '100%',
    },
    drawer: {
      width: '280px',
      position: 'relative',
      '&:after': {
        content: '""',
        background:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAJOCAYAAAC+6IfPAAAD0ElEQVR4nO1d25LDIAi1/f9vbncys+mm2ahcFcnhsU2OiAoIaB6v1+v9eDyKlJ7iN38JAKU8txF4v98QYiliOdhxIF0PT83LH4Ct/yoZYDkDwAxAqpkyjIJGI9twcP6By1GWtRDHNkqUKzQSAC4BuMr1o5Hm+0gAiAJwnAvUeeHTBY5uhGUKKQO1g8G1j5ldHKosfPfOFIHCW69wwLLOquYpAD1uugC9GVk1LFSCfzBiGEkcxLVMFL0YeBjFTpbYtM1z982UqiqmWrDlaVJvHxm8C+M8lHNLscw7CwDWWQgwJKrbWpGZUmXxAOaFQMx3rmP8xAQASJ0bArSEWfPiyEqVpROvHq4pVgxjGCerRyRP1cy0IWU6i4OkAOMcDLWnqqkEKhS7cPztqjG2DM6NkAGqhiWWEFtUNa6q5k2EyHm4CYAdixIggVo3rU80Ne8kHyleVZgKgNKd84jdxbwvHFM901XlEAJRlgDqDIeKg3h2YbHYesJiX3UtDpe6XUBEMxKAf4LCBaDG9nG6f1kmdaoMRd80Eu/ezTjwAZCU1e3/w7QBoAoQz0dKtOVZAEBinWwtE1WNVQEkFBDA3Fc2D43bA3BmHnKuDQDJMt7nBIQIAH+A5IdAAjnbkpc29v31wdzDgQAAgAkAp9TMh4OY51z9k/dSugwLJzgsPH/3fscT12qARJVxosh2rHCgOIKBa66MpnKS5D0ORLVJXV5Yu3yGvRqzlhOtYViCl5kmUOuLOxjDCp79AJDxTAEwfybuE2lucZ8aAL7yH4k3HCq1nkCpAiANgLk+QCjMQAZzu1Brff5+ISnAJti7ywAA4QFaWmn/zy/XVjqK9eigBx6FjT2KgsViCl/IEvv2wsUrYUwAvkppWiuvy4H0RYzCSIDjKAUttT3/MPdWmhjnmeIdT2QDzO+CC0CrW+O7cJfbB1pSz3iyjhtwuOQAkW0ZBT1+MOw2kst7MFyyPAvedk7Z8vty4Aow/9z7QkI0d3G636CImSpTB+fZ6YFzi3ct9sXlQhE+n0DhYH9mXkSze0y11w1W/MD0A0+xNl132nCIORDZRsr1NWQOuIRyIgDYAvSCr/4cxAcwc/f/bX17t0z0qKsTz/8vZBeo8aVgHoo7B1UA6VK24wAARgCu9crzPNV5PpKbaVMlLP0iWdT+jolkcVIjXwDwVJ0BWF+UlZC/f8C+lHZ8SJTVXAtg8XMsPWpdLIEjGN5aefHv+rIAan1X1yfGEaLKzVPf9L2QYamxSpaBODgfR6WZGRYz42oanJ8HkEihdAHieusmZee+Kq2U8gOgl9aGmi6z6gAAAABJRU5ErkJggg==")',
        display: 'block',
        height: '590px',
        width: '16px',
        position: 'fixed',
        top: '20px',
        left: '264px',
      },
    },
  }))();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  return (
    <nav>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={(): void => setDrawerOpen(false)}
          classes={{
            paper: classes.paper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Nav role={me.role} />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          classes={{ paper: classes.paper }}
          ModalProps={{ keepMounted: true }}
          anchor="left"
          variant="permanent"
        >
          <Nav role={me.role} />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideDrawer;
