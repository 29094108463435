import React, { useEffect, useState } from 'react';
import { Discount } from '@castiron/domain';
import { couponRepository } from '@castiron/client-admin/src/domain';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Select } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '@castiron/client-admin/src/hooks';
import moment from 'moment';
import { getCouponAction } from '@castiron/client-admin/src/store/reducers/coupons';
import { useTracking } from '@castiron/utils';
import { ChecklistValues } from '@castiron/domain';
import { updateChecklistAction } from '@castiron/client-admin/src/store/reducers/shops';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  header: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    marginBottom: '8px',
  },
  optionButton: {
    padding: '8px 16px',
    marginRight: '8px',
    color: theme.branding.blue.primary,
    borderColor: 'RGB(224, 224, 224)',
    marginTop: '8px',
  },
  success: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.branding.green.primary,
    marginTop: '8px',
  },
  error: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.branding.error,
    marginTop: '8px',
  },
  select: {
    height: 38,
    borderRadius: 4,
    width: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface QuickCouponOption {
  code: string;
  discount: Discount;
  label: string;
}

const Coupons: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));
  const [resultMsg, setResultMsg] = useState<{ type: string; msg: string } | null>(null);

  const handleCouponOptionClick = (quickCouponOption: QuickCouponOption) => {
    const timestamp = moment().unix();
    couponRepository
      .create({
        code: quickCouponOption.code,
        discount: quickCouponOption.discount,
        duration: {
          startDate: timestamp,
        },
        shopId: shop.id,
        status: 'active',
        metrics: {
          totalRevenue: 0,
          totalUses: 0,
        },
      })
      .then(c => {
        console.debug(`Created new Coupon with ID [${c.id}]`);
        setResultMsg({ type: 'success', msg: `Coupon Created! ${c.code}` });
        setTimeout(() => {
          setResultMsg(null);
        }, 3000);
        dispatch(getCouponAction(shop.id));
        if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
          dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] }));
        }
        trackEvent('Quick Coupon Created', { coupon: c });
      })
      .catch(err => {
        setResultMsg({ type: 'error', msg: 'Failed to create coupon.' });
        setTimeout(() => {
          setResultMsg(null);
        }, 3000);
      });
  };

  useEffect(() => {
    if (shop) {
      dispatch(getCouponAction(shop.id));
    }
  }, [shop]);

  const couponOptions: QuickCouponOption[] = [
    {
      code: `10PERCENTOFF`,
      discount: { type: 'percent', value: 10 },
      label: '10% off',
    },
    {
      code: `15PERCENTOFF`,
      discount: { type: 'percent', value: 15 },
      label: '15% off',
    },
    {
      code: `DOLLAROFF`,
      discount: { type: 'amount', value: 100 },
      label: '$1 off',
    },
    {
      code: `5DOLLARSOFF`,
      discount: { type: 'amount', value: 500 },
      label: '$5 off',
    },
  ];

  return (
    <Grid className={classes.container}>
      <Select
        selectClass={classes.select}
        value={'Quick Add Coupons'}
        options={[
          { label: 'Quick Add Coupons', value: 'Quick Add Coupons' },
          ...couponOptions.map(option => ({
            label: option.label,
            value: option.code,
          })),
        ]}
        onChange={e => {
          const selectedOption = couponOptions.find(option => option.code === e.target.value);
          if (selectedOption) {
            handleCouponOptionClick(selectedOption);
          }
        }}
      />
    </Grid>
  );
};

export default Coupons;
