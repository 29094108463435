import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, DialogTitle, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Button, SvgIcon, Typography } from '@castiron/components';
import { useAppDispatch } from '../../../../hooks';
import { useHistory } from 'react-router-dom';
import { closeModal } from '../../../../store/reducers/modalConductor';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import StorefrontOutlined from '@material-ui/icons/StorefrontOutlined';
import ModalWrapper from '../../../RootModal/ModalWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '24px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    padding: '18px 16px',
    width: '100%',

    '& p': {
      textAlign: 'left',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  header: {
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  icon: {
    background: theme.branding.blue.light,
    borderRadius: '12px',
    height: 70,
    marginRight: 16,
    width: 70,
    '& svg': {
      color: theme.branding.blue.primary,
      fontSize: '2em',
    },
  },
}));

export type Props = {
  open: boolean;
};

const AddFulfillmentModal: React.FC<Props> = (props: Props) => {
  const { open } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="md" show={open}>
      <DialogTitle className={classes.header}>
        <Typography variant="h6">Select a Fulfillment Option</Typography>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Grid container direction="column" item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(closeModal());
              history.push(`/store/fulfillment/create/pickup`);
            }}
            fullWidth={isMobile}
            className={classes.button}
          >
            <Grid container alignItems="center" justify="flex-start" wrap="nowrap">
              <Grid alignItems="center" justify="center" className={classes.icon} container>
                <SvgIcon>
                  <StorefrontOutlined />
                </SvgIcon>
              </Grid>
              <Grid container direction="column" alignItems="flex-start" item sm={10} xs={9}>
                <Typography variant="subtitle1">Local Pick Up</Typography>
                <Typography variant="body2">Pick up from farmer's markets, home, etc.</Typography>
              </Grid>
            </Grid>
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(closeModal());
              history.push(`/store/fulfillment/create/delivery`);
            }}
            fullWidth={isMobile}
            className={classes.button}
          >
            <Grid container alignItems="center" justify="flex-start" wrap="nowrap">
              <Grid alignItems="center" justify="center" className={classes.icon} container>
                <SvgIcon>
                  <DriveEtaOutlinedIcon />
                </SvgIcon>
              </Grid>
              <Grid container direction="column" alignItems="flex-start" item sm={10} xs={9}>
                <Typography variant="subtitle1">Local Delivery</Typography>
                <Typography variant="body2">Deliver to local cities, zip codes, or boroughs</Typography>
              </Grid>
            </Grid>
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(closeModal());
              history.push(`/store/fulfillment/create/shipping`);
            }}
            fullWidth={isMobile}
            className={classes.button}
          >
            <Grid container alignItems="center" justify="flex-start" wrap="nowrap">
              <Grid alignItems="center" justify="center" className={classes.icon} container>
                <SvgIcon>
                  <LocalShippingOutlined />
                </SvgIcon>
              </Grid>
              <Grid container direction="column" alignItems="flex-start" item sm={10} xs={9}>
                <Typography variant="subtitle1">Shipping</Typography>
                <Typography variant="body2">Ship locally or nationally</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </DialogContent>
    </ModalWrapper>
  );
};

export default AddFulfillmentModal;
