import firebase from 'firebase/compat/app';
import loginWithProvider from './loginWithProvider';

async function loginWithTwitter() {
  const provider = new firebase.auth.TwitterAuthProvider();
  const user = await loginWithProvider(provider);
  console.log('twitter user: ', user);
  return user;
}

export default loginWithTwitter;
