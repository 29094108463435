import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import {Condition, FieldFunctions} from '../base/repository';

export type EffectiveStatus = 'active' | 'scheduled' | 'expired' | 'deleted';

export interface Duration {
  endDate?: number;
  startDate: number;
}

export interface Discount {
  type: 'amount' | 'percent';
  value: number;
}

export interface QualifyingMinimum {
  type: 'orderValue' | 'orderQuantity';
  value: number;
}

export interface Metrics {
  totalRevenue: number;
  totalUses: number;
}

export interface Coupon extends ShopRelatedDocument<Coupon> {
  code: string;
  discount: Discount;
  duration: Duration;
  metrics?: Metrics;
  minimum?: QualifyingMinimum;
  maximumPerCustomer?: number;
}

export class CouponRepository extends ShopRelatedRepository<Coupon> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'coupons', fieldFunctions);
  }

  public findActiveCoupons(shopId: string) {
    const comparisons: Condition<Coupon>[] = [
      {
        field: 'shopId',
        operator: '==',
        value: shopId,
      },
      {
        field: 'status',
        operator: '==',
        value: 'active',
      },
    ];
    return this.find({ where: comparisons });
  }
}
