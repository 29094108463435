import React, { useEffect, useState } from "react";
import Confetti from 'react-confetti'
import { Grid, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ConfettiIcon, Logo, OvenIcon, ProgressBar, RecipeBookIcon, RecipeIcon, Typography } from '@castiron/components';

type Props = {
  start: boolean;
  end: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  containerAbsolute: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    zIndex: 10000,
  },
}));

const SignUpSpinner: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [seconds, setSeconds] = useState(0);

  const { start, end } = props;


  const getInnerHeight = () => {
    const { innerHeight } = window;
    return innerHeight;
  }

  const getInnerWidth = () => {
    const { innerWidth } = window;
    return innerWidth;
  }


  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [start]);

  const icons = (seconds) => {
    switch (seconds) {
      case 0:
        return <RecipeIcon />;
      case 1:
        return <RecipeBookIcon />;
      default:
        return <OvenIcon />;
    }
  }

  const text = (seconds) => {
    switch (seconds) {
      case 0:
        return "Gathering ingredients....";
      case 1:
        return "Following grandma’s secret recipe...";
      default:
        return "Putting your store in the oven...";
    }
  }

  return (
    <>
      {start && (
        <Grid container xs={12} alignItems="center" justify="center" className={classes.containerAbsolute} >
          <Confetti width={getInnerWidth()} height={getInnerHeight()} run={end} />
          <Grid alignItems="center" justify="center" style={{ height: 640, width: 360, marginBottom: getInnerHeight() < 775 ? 0 : 250 }}>
            <Grid item xs={12}>
              <Logo width={220} style={{ margin: 60 }} />
            </Grid>
            <Grid container item xs={12} alignItems="center" justify="center" style={{ height: 300, backgroundColor: theme.branding.blue.light, borderRadius: '50% 25%' }}>
              {!end ?
                (icons(seconds))
                : (<ConfettiIcon />)
              }
            </Grid>
            <Grid item xs={12} style={{ margin: '50px 15px 30px 15px' }}>
              {!end ? (<ProgressBar percentage={seconds < 3 ? (seconds + 1) / 4 : 3 / 4} fullwidth={true} barHeight={16} />)
                : (<ProgressBar percentage={1} fullwidth={true} barHeight={16} />)}
            </Grid>
            <Grid item xs={12} style={{ margin: '30px 15px 30px 15px' }}>
              <Typography variant='subtitle1'>{!end ? text(seconds) : "Done! Your store is baked to perfection.\n(And in record time. Beat that, Bobby Flay!)"}</Typography>
            </Grid>
          </Grid>
        </Grid>)
      }
    </>
  );
}

export default SignUpSpinner;