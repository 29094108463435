import React from 'react';
import { Avatar, Grid, makeStyles, Theme, GridProps } from '@material-ui/core';
import { Link, Text } from '@castiron/components';
import { ShopSummary } from '@castiron/domain';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: 24,
    height: 24,
  },
  infoText: {
    wordBreak: 'break-word',
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Props = Override<
  GridProps,
  {
    author: ShopSummary;
  }
>;

// Community package creator information
const CreatorInfo: React.FC<Props> = (props: Props) => {
  const { author, ...restOfProps } = props;

  const classes = useStyles();

  if (!author) return null;

  const ownerNamePhrase = `By ${author?.owner?.firstName || ''} ${author?.owner?.lastName || ''} of `;

  return (
    <Grid container wrap="nowrap" alignItems="center" {...restOfProps}>
      {/* Small circle avatar picture of creator */}
      <Avatar
        variant="circle"
        classes={{ root: `${classes.image}`, img: `${classes.image}` }}
        style={{ marginRight: 8 }}
        src={author?.profileImageObj?.downloadUrl}
      />

      <Text presetFont="txt--xs" lineHeight={16} className={classes.infoText}>
        {/* By {firstName} {lastName} of  */}
        {ownerNamePhrase}

        {/* Link to shop */}
        <Link underline notBlue href={`${process.env.REACT_APP_SHOP_URL}${author.websiteUrl}`} target="_blank">
          {author.businessName}
        </Link>
      </Text>
    </Grid>
  );
};

export default CreatorInfo;
