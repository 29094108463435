import React from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Dinero from 'dinero.js';

type Props = {
  currentValue: number;
  oldValue: number;
  unit?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  differenceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 9,
  },
  difference: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
  },
  success: {
    color: theme.branding.green.primary,
  },
  error: {
    color: theme.branding.error,
  },
  arrowIcon: {
    fontSize: 20,
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 8,
  },
  successBg: {
    backgroundColor: `${theme.branding.green.primary}0D`,
  },
  errorBg: {
    backgroundColor: `${theme.branding.error}0D`,
  },
}));

const Difference: React.FC<Props> = ({ currentValue, oldValue, unit }: Props) => {
  const classes = useStyles();
  let isZero = false;
  if (!currentValue || currentValue === 0) isZero = true;

  const changeValue = isZero ? 0 : Math.abs(currentValue - oldValue);
  const outputValue = unit === '$' ? Dinero({ amount: changeValue }).toFormat('$0.00') : changeValue;
  const valueIncreased = currentValue >= oldValue;

  return (
    <div className={classes.differenceContainer}>
      <Typography className={`${classes.difference} ${valueIncreased ? classes.success : classes.error}`}>
        {isZero ? 0 : outputValue}
      </Typography>
      <div
        className={`${classes.arrowContainer} ${valueIncreased ? classes.successBg : classes.errorBg} ${
          valueIncreased ? classes.success : classes.error
        }`}
      >
        {valueIncreased ? (
          <ArrowUpward className={classes.arrowIcon} />
        ) : (
          <ArrowDownward className={classes.arrowIcon} />
        )}
      </div>
    </div>
  );
};

export default Difference;
