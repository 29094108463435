import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { TextField } from '@material-ui/core';

interface Props extends BaseInputProps {
  autoFocus?: boolean;
  name?: string;
  onChange?: (event: any) => void;
  className?: string;
  placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
  },
  inputRoot: {
    height: 48,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
}));

const TextInput: React.FC<Props> = (props: Props) => {
  const { autoFocus, error, label, secondaryLabel, name, onChange, required, className, placeholder } = props;
  const classes = useStyles();

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <Field
        style={{ margin: '0px' }}
        as={TextField}
        autoFocus={autoFocus}
        error={!!error}
        InputProps={{
          classes: {
            root: `${className && className} ${classes.inputRoot}`,
            input: classes.input,
          },
        }}
        name={name}
        placeholder={placeholder}
        {...(onChange ? { onChange } : {})}
        type='text'
        variant='outlined'
      >
      </Field>
    </InputWrapper>
  );
}

export default TextInput;
