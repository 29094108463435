import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getService } from "../../../../firebase";
import {
  ButtonBase,
  List,
  Grid,
  Hidden,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import PeopleOutlineOutlined from '@material-ui/icons/PeopleOutlineOutlined';
import PieChartOutlined from '@material-ui/icons/PieChartOutlined';
import ReceiptOutlined from '@material-ui/icons/ReceiptOutlined';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import StoreOutlined from '@material-ui/icons/StoreOutlined';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import { FeatureFlag, useConfig } from '@castiron/castiron-firebase';
import {
  BulbIcon,
  CalendarIcon,
  CookieOutlinedIcon,
  ChecklistIcon,
  MegaphoneOutlinedIcon,
  Logo, useFeatures, RocketOutlinedIcon,
} from '@castiron/components';
import { backendStateToFrontendState } from '@castiron/domain';
import { useAppSelector } from '../../../../hooks';
import AvatarMenu from '../../AvatarMenu';
import Spinner from '../../../Spinner';
import NavItem from './NavItem/NavItem';

type Props = {
  role: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    // padding to help it match the sticky header height
    padding: '30px 0 25px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 19px 10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  rocketIcon: {
    height: 12,
    width: 12,
  },
  rocketIconContainer: {
    borderRadius: 100,
    color: theme.branding.orange.primary,
    backgroundColor: theme.branding.yellow.light,
    /* TODO: Once Calendar includes Standard Orders, change height and width to 32px and remove marginLeft*/
    height: 24,
    width: 24,
    marginLeft: 8,
  },
  upperWrapper: {
    padding: '0 24px',
    overflowY: 'auto',
    height: '100%',
  },
}));

const createCustomOrderService = getService('orders', 'createcustomorderv2', { version: 2 });

const Nav: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const ffconfig = useConfig();
  const history = useHistory();

  const [showSpinner, setShowSpinner] = useState(false);

  const { account, shop, transactions, communityPackages } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    transactions: state.transactions?.transactions,
    communityPackages: state.community.communityPackages,
  }));

  const features = useFeatures() || [];
  const calendarEnabled = features.includes('admin.calendar');

  const onLogoClick = () => {
    history.push('/');
  };

  const openedOrderCount = transactions?.filter((tx) => backendStateToFrontendState(tx, 'order') === 'open').length;
  const newQuotes = transactions?.filter((tx) => tx.order?.type === 'custom' && backendStateToFrontendState(tx, 'quote') === 'new');
  const newQuoteCount = newQuotes?.length || 0;

  return (
    <>
      <Spinner show={showSpinner} size='fullscreen' />
      <Grid className={classes.upperWrapper} container direction='column' wrap='nowrap'>
        <ButtonBase focusRipple onClick={onLogoClick} className={classes.logoContainer}>
          <Logo width={134} style={{ marginRight: 19 }} />
        </ButtonBase>
        <List disablePadding>
          <NavItem label="Home" path="/" Icon={HomeOutlined} />
          <NavItem feature="admin.orders" label="Orders" path="/orders" Icon={ShoppingCartOutlined} itemCount={openedOrderCount || 0} />
          <NavItem feature="admin.quotes" label="Quotes" path="/quotes" Icon={ReceiptOutlined} itemCount={newQuoteCount} />
          <NavItem feature="admin.products" label="Products" path="/products" Icon={CookieOutlinedIcon} />
          {account.tier?.name !== 'Unpaid' && (
            <NavItem
              label="Calendar"
              path={calendarEnabled ? "/calendar" : "/calendar/preview"}
              Icon={CalendarIcon}
              endIcon={ calendarEnabled
                ?
                  null
                :
                  <Grid container item className={classes.rocketIconContainer} justify='center' alignContent='center'>
                    <RocketOutlinedIcon className={classes.rocketIcon} />
                  </Grid>
              } />
          )}
          <NavItem feature="admin.contacts" label="Contacts" path="/contacts/overview" Icon={PeopleOutlineOutlined} />
          {
            communityPackages.length > 0 &&
            <FeatureFlag name="feature_community_packages">
              <NavItem label="Community" path="/community/products" Icon={BulbIcon} />
            </FeatureFlag>
          }
          <NavItem feature="admin.marketing" label="Marketing" path="/marketing" Icon={MegaphoneOutlinedIcon} />
          <NavItem feature="admin.analytics" label="Analytics" path="/analytics" Icon={PieChartOutlined} />
          <NavItem label="Shop Settings" path="/store/dashboard" Icon={StoreOutlined} />
        </List>
        <List disablePadding style={{ marginTop: 'auto' }}>
          <NavItem label="The Kitchen" path="https://www.facebook.com/groups/thekitchencommunity" external
            Icon={ChatOutlined} />
          <NavItem label="Help Center" path="https://castiron.helpscoutdocs.com/" external Icon={HelpOutlineOutlined} />
        </List>
      </Grid>

      <Hidden smDown>
        <AvatarMenu
          variant='large'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        />
      </Hidden>
    </>
  );
};

export default Nav;
