import {useAppDispatch, useAppSelector} from "../../../hooks";
import React, {useEffect} from "react";
import {getCommunityPackagesAction} from "../../../store/reducers/community";
import {Grid, makeStyles, Theme} from "@material-ui/core";
import {CommunityPackageCard, ReachOut} from "../index";

const useStyles = makeStyles((theme: Theme) => ({
  reachOutContainer: {
    marginBottom: 32,
  },
  communityListingsContainer: {
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
}));

const PackageList: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { communityPackages, isCommunityPackagesLoading, shop } = useAppSelector(state => ({
    communityPackages: state.community.communityPackages,
    isCommunityPackagesLoading: state.community.loading,
    shop: state.shops.shop,
  }));

  // Get Community packages
  useEffect(() => {
    if (shop) {
      dispatch(getCommunityPackagesAction(shop.id));
    }
  }, [shop]);

  return (
    <Grid container className={classes.communityListingsContainer}>
      {communityPackages?.map(cp => (
        <CommunityPackageCard communityPackage={cp} />
      ))}
      <ReachOut />
    </Grid>
  );

};

export default PackageList;
