import { useSelector } from "react-redux";
import { ActiveVariant } from "./reducer";

const useVariant = (experimentName: string): ActiveVariant => {
  const { experiments } = useSelector(state => ({
    //@ts-ignore
    experiments: state.experiments.active
  }));

  return experiments?.find(ex => ex.name === experimentName)?.activeVariant;
}

export default useVariant;