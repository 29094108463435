import momentTimezone, { Moment } from "moment-timezone";

export const defaultTimeZone = momentTimezone.tz.guess() || 'America/Chicago';

export const timeFormat = 'h:mm A';

const backendToFrontendTimeZoneMap = {
  'America/Chicago': 'Central Time',
  'America/Denver': 'Mountain Time',
  'America/Juneau': 'Alaska Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/New_York': 'Eastern Time',
  'America/Phoenix': 'Arizona Time',
  'America/Puerto_Rico': 'Puerto Rico Time',
  'Pacific/Honolulu': 'Hawaii-Aleutian Time',
};

export const formatTimeZone = (timeZone: string): string => {
  return backendToFrontendTimeZoneMap[timeZone];
}

export const timeZoneOptions = momentTimezone.tz.names().map(timeZone => ({value: timeZone, label: formatTimeZone(timeZone)})).sort((a, b) => a.label > b.label ? 1 : -1);

export const sharesDayWithRange = (timeInQuestion: Moment, startTime: Moment, endTime: Moment) => {
  return timeInQuestion.isSameOrAfter(momentTimezone(startTime).startOf('day'))
    && timeInQuestion.isSameOrBefore(momentTimezone(endTime).endOf('day'));
};