import React, { useCallback, useState } from 'react';
import { Box, Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { AmountButtons, Button, ProductImage } from '@castiron/components';
import Close from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import { InputField, Product } from '@castiron/domain';
import VariationsPreview from './VariationsPreview';
import _ from 'lodash';

export type Props = {
  show: boolean;
  product: Product;
  initialAmount: number;
  imageObj: any;
};

const breakpointSmall = 749;
const breakpointLarge = 750;

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    height: '100%',
    display: 'flex',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.up(breakpointLarge)]: {
      flexDirection: 'row',
      padding: 16,
    },
    [theme.breakpoints.down(breakpointSmall)]: {
      flexDirection: 'column',
    },
  },
  description: {
    '& p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    [theme.breakpoints.down(breakpointSmall)]: {
      padding: 4,
      borderRadius: 4,
    },
  },
  mobileImgContainer: {
    position: 'relative',
    height: '40%',
    width: '100%',
  },
  mobileImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    [theme.breakpoints.up(breakpointLarge)]: {
      margin: 12,
    },
    [theme.breakpoints.down(breakpointSmall)]: {
      margin: 16,
      flexGrow: 0.6,
      height: '100%',
    },
  },
  modalContent: {
    marginBottom: 65,
    [theme.breakpoints.up(breakpointSmall)]: {
      minWidth: 450,
    },
  },
  extraMargin: {
    [theme.breakpoints.up(breakpointLarge)]: {
      marginTop: 24,
      marginLeft: 42,
    },
  },
  footer: {
    alignItems: 'center',
    backgroundColor: '#fff',
    bottom: '0',
    borderTop: '1px solid #B8B8B8',
    display: 'flex',
    flexDirection: 'row',
    height: 65,
    justifyContent: 'flex-end',
    paddingRight: 15,
    position: 'sticky',
    width: '100%',
    zIndex: 1000,
    [theme.breakpoints.down(breakpointSmall)]: {
      height: 90,
      justifyContent: 'space-around',
    },
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down(breakpointSmall)]: {
      justifyContent: 'space-around',
      marginBottom: 24,
    },
  },
  amountButtons: {
    marginRight: 24,
    [theme.breakpoints.up(breakpointLarge)]: {
      marginLeft: 24,
    },
  },
  productImageContainer: {
    maxWidth: 350,
    maxHeight: 350,
    position: 'relative',
  },
  soldOutBanner: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 15,
    backgroundColor: theme.branding.gray[800],
    width: 87,
    height: 31,
  },
  soldOutBannerFont: {
    color: theme.branding.gray[200],
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 8,
    color: theme.palette.common.white,
  },
  variationsContainer: {
    marginTop: '2em',
  },
}));

const ProductPreviewModal: React.FC<Props> = (props: Props) => {
  const { imageObj, show, product, initialAmount } = props;
  const [amount, setAmount] = useState(initialAmount || 1);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpointSmall));

  let allergens = [];
  let dietary = [];

  if (product && product.allergen) {
    allergens = product.allergen;
  }

  if (product && product.dietary) {
    dietary = product.dietary;
  }

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleClose = useCallback((): void => {
    if (shop && product) {
      dispatch(closeModal());
    }
  }, [shop, product]);

  const dietaryOptions = [
    { label: 'Dairy Free', value: 'dairyFree' },
    { label: 'Gluten Free', value: 'glutenFree' },
    { label: 'Keto', value: 'keto' },
    { label: 'Kosher', value: 'kosher' },
    { label: 'Low/No Carb', value: 'lowNoCarb' },
    { label: 'Paleo', value: 'paleo' },
    { label: 'Wheat', value: 'wheat' },
    { label: 'Whole30', value: 'whole30' },
    { label: 'Vegan', value: 'vegan' },
    { label: 'Vegetarian', value: 'vegetarian' },
    { label: 'Organic', value: 'organic' },
    { label: 'Local', value: 'local' },
  ];
  const allergenOptions = [
    { label: 'Milk', value: 'milk' },
    { label: 'Eggs', value: 'eggs' },
    { label: 'Fish', value: 'fish' },
    { label: 'Shellfish', value: 'shellfish' },
    { label: 'Tree Nuts', value: 'treeNuts' },
    { label: 'Wheat', value: 'wheat' },
    { label: 'Peanuts', value: 'peanuts' },
    { label: 'Soy Beans', value: 'soyBeans' },
    { label: 'Sesame', value: 'sesame' },
  ];

  const getList = (array, obj): string => {
    const arrayLength = array.length;
    const tempArray = [];
    let listStr = '';
    for (let i = 0; i < arrayLength; i++) {
      const strVal = array[i];
      const result = obj.filter(item => {
        if (item.value === strVal) {
          return item.label;
        }
      });
      if (result.length > 0 && result[0].label) {
        tempArray.push(result[0].label);
      }
    }
    listStr = tempArray.join(', ');
    return listStr;
  };

  return !product ? (
    <></>
  ) : (
    <ModalWrapper fullWidth={isMobile} size="md" show={show}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <Grid className={classes.gridContainer} container>
        {product && (
          <>
            <Box className={classes.container}>
              {imageObj &&
                (isMobile ? (
                  <Box className={classes.mobileImgContainer}>
                    <img className={classes.mobileImg} src={imageObj} />
                    {!product.inventory && (
                      <div className={classes.soldOutBanner}>
                        <Typography variant="body1" className={classes.soldOutBannerFont}>
                          {'Sold Out'}
                        </Typography>
                      </div>
                    )}
                  </Box>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <div className={classes.productImageContainer}>
                      <ProductImage alt={product?.title} src={imageObj} width="100%" height="auto" />
                      {!product.inventory && (
                        <div className={classes.soldOutBanner}>
                          <Typography variant="body1" className={classes.soldOutBannerFont}>
                            {'Sold Out'}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                ))}
              <Grid className={classes.modalContent} sm={imageObj ? 8 : 12} item xs={12}>
                <Box className={`${classes.content} ${imageObj && classes.extraMargin}`}>
                  <Typography variant="subtitle1">{product.title}</Typography>
                  {product.description && (
                    <Typography
                      paragraph
                      variant="body2"
                      className={classes.description}
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  )}
                  {allergens.length > 0 && (
                    <Typography variant="body1">Allergen information: {getList(allergens, allergenOptions)}</Typography>
                  )}
                  {dietary.length > 0 && (
                    <Typography variant="body1">Dietary information: {getList(dietary, dietaryOptions)}</Typography>
                  )}
                  {isMobile ? (
                    <>
                      {product.variations && product.variations.length > 0 && (
                        <Box className={classes.variationsContainer}>
                          {product.variations.map((variation: InputField, index: number) => (
                            <VariationsPreview
                              product={product}
                              key={variation.id}
                              index={index}
                              variation={variation}
                            />
                          ))}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Grid item>
                      {product.variations && product.variations.length > 0 && (
                        <Box className={classes.variationsContainer}>
                          {product.variations.map((variation: InputField, index: number) => (
                            <VariationsPreview
                              product={product}
                              key={variation.id}
                              index={index}
                              variation={variation}
                            />
                          ))}
                        </Box>
                      )}
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Box>
            <Box className={classes.footer}>
              <Box className={classes.footerContent}>
                <AmountButtons
                  containerClass={classes.amountButtons}
                  onIncreaseClick={(e): void => {
                    if (e) e.preventDefault();
                    setAmount(amount + 1);
                  }}
                  onDecreaseClick={(e): void => {
                    if (e) e.preventDefault();
                    if (amount >= 1) {
                      setAmount(amount - 1);
                    }
                  }}
                  value={amount}
                  product={product}
                />
                <Button disabled type="button" variant="contained">
                  <Typography className={classes.text}>Add to cart</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </ModalWrapper>
  );
};

export default ProductPreviewModal;
