import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { BaseDocument, BaseRepository, BaseStatus, FieldFunctions } from '../base/repository';
import { removeEmpty } from '../_lib/removeEmpty';
import { areSubscriptionFeaturesActive, Subscription } from '../subscription';
import dataOnly from '../_lib/dataOnly';
import { TakeRateLevel, Tier } from '../tier';
import { Address } from '../address';

const PAYMENT_HISTORY_COLLECTION = 'payment_history';

export interface PaymentHistoryStripe {
  invoiceId: string;
}

export interface PaymentHistoryIntegrations {
  stripe?: PaymentHistoryStripe;
}

interface PaymentHistory extends BaseDocument<PaymentHistory> {
  chargeDate: number;
  subscription: Subscription;
  paymentType: 'credit-card' | 'ACH';
  status: 'succeeded' | 'failed';
  amount: number;
  integrations?: PaymentHistoryIntegrations;
}

export interface Recipient {
  customerId: string;
  name: string;
  email?: string;
  phoneNumber?: string;
}

interface SendGridMessageIntegration {
  messageId: string;
  batchId?: string;
}

interface MessageLogIntegrations {
  sendgrid: SendGridMessageIntegration;
}

export interface MessageLog {
  to: Recipient[];
  createdAt: number;
  scheduledToSendAt?: number;
  sentAt: number;
  subject?: string;
  body: string;
  integrations: MessageLogIntegrations;
}

export interface SendGridConfig {
  contactListId: string;
  unsubscribeGroupId: number;
  senderId: number;
}

export type BusinessStage =
  | 'brand-new' // onboarding v3 & v4
  | 'no-online-ordering' // onboarding v3
  | 'selling-online-unhappy' // onboarding v3
  | 'compairing' // onboarding v3
  | 'just-looking' // onboarding v3
  | 'part-time-entrepreneur' // onboarding v4
  | 'full-time-entrepreneur' // onboarding v4
  | 'enterprise-business'; // onboarding v4

export type SellingLocationOption = 'online' | 'local-markets' | 'retail-store' | 'other-pop-up';
export type BusinessGoal = 'custom-order' | 'manage-business' | 'online-shopping' | 'marketing-automation' | 'other';

export interface AccountTier {
  id: string;
  name: string;
  castironTakeRate: number;
  takeRateLevels?: TakeRateLevel[];
  payoutFrequency: 'daily' | 'weekly' | 'monthly';
}

export interface AccountMetrics {
  numSales?: number;
  totalRevenue?: number;
  lastSaleDate?: number;
  numCustomers?: number;
  numSubscribers?: number;
  numActiveProducts?: number;
  totalTips?: number;
}

export interface HubSpotIntegration {
  id: string;
}

export interface SendGridIntegration {
  config: SendGridConfig;
}

export interface StripeIntegration {
  accountId: string;
  customerId?: string;
  testClockId?: string;
  locationId?: string;
  lastStripeConnectionAttempt?: number;
}

export interface Integrations {
  hubspot?: HubSpotIntegration;
  sendgrid?: SendGridIntegration;
  stripe?: StripeIntegration;
}

export interface MigrationInformation {
  existingShopUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
}

export interface ContactOptions {
  instagramUrl?: string;
  facebookUrl?: string;
  mobilePhone?: string;
}

export interface OnboardingStep {
  version?: number;
  step?: number;
}

export interface OnboardingQuestions {
  onboardingStep?: OnboardingStep;
  orderVolume?: string;
  salesMethod?: string[];
  categories?: string[];
  contactOptions?: ContactOptions;
  migrationInformation?: MigrationInformation;
  goal?: BusinessGoal;
  sellingLocations?: SellingLocationOption[];
  businessStage?: BusinessStage;
}

export interface OnboardingModals {
  appearanceModalShown?: boolean;
  businessDetailsModalShown?: boolean;
  contactsModalShown?: boolean;
  couponModalShown?: boolean;
  fulfillmentModalShown?: boolean;
  paymentModalShown?: boolean;
  productModalShown?: boolean;
  welcomeModalShown?: boolean;
}

interface CommsDefaults {
  sendCustomerFulfilledConfirmation: boolean;
  sendMeFulfilledConfirmation: boolean;
}

interface AccountConfig {
  commsDefaults?: CommsDefaults;
  wasShopGenerated?: boolean;
}

export interface Account extends BaseDocument<Account> {
  status?: 'active' | 'inactive' | 'deleted' | 'onboarding';
  lastStripeConnectionAttempt?: number; // TODO: Migrate to Integrations
  stripeAccountId?: string; // TODO: Migrate to Integrations
  isReady?: boolean;
  sendGridConfig?: SendGridConfig; // TODO: Migrate to Integrations
  tier: AccountTier;
  legacyTier?: AccountTier;
  businessStage?: BusinessStage; // TODO: Migrate to OnboardingQuestions
  metrics?: AccountMetrics;
  orderNumberCounter: number;
  integrations?: Integrations;
  onboardingQuestions?: OnboardingQuestions;
  onboardingModals?: OnboardingModals;
  subscription?: Subscription;
  billingAddress?: Address;
  config?: AccountConfig;

  addPaymentHistory?: (paymentHistory: PaymentHistory) => Promise<PaymentHistory>;
  getPaymentHistory?: () => Promise<PaymentHistory[]>;
  /* subscription functions */
  areSubscriptionFeaturesActive?: () => boolean;
  /* utility functions */
  isNewUnsubscribedUser?: () => boolean;
  isPaidSubscriptionEnded?: () => boolean;
  isInTrial?: () => boolean;
  isTrialCompleted?: () => boolean;
  isLegacyTrialCompleted?: () => boolean;
  hasLegacySubscription?: () => boolean;
}

export class AccountRepository extends BaseRepository<Account> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'accounts', fieldFunctions);
  }

  public async findByStripeId(stripeAccountId: string): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'stripeAccountId', operator: '==', value: stripeAccountId }],
    });

    return this.firstOrNull(results);
  }

  public async findBySendGridUnsubscribeGroup(unsubscribeGroupId: number): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'sendGridConfig.unsubscribeGroupId', operator: '==', value: unsubscribeGroupId }],
    });

    return this.firstOrNull(results);
  }

  public async findArtisanActiveAccounts(): Promise<Account[]> {
    const sixtyDaysAgo = moment()
      .subtract(60, 'days')
      .unix();
    return this.find({
      where: [{ field: 'metrics.numSales', operator: '>=', value: 10 }],
    }).then(accounts =>
      accounts.filter(a => a.metrics.lastSaleDate >= sixtyDaysAgo && a.metrics.numActiveProducts >= 2),
    );
  }

  public async updateMetrics(id: string, metrics: AccountMetrics) {
    return this.updateProps(
      id,
      removeEmpty({
        'metrics.lastSaleDate': metrics.lastSaleDate,
        'metrics.numCustomers': metrics.numCustomers && this.fieldFunctions.increment(metrics.numCustomers),
        'metrics.numSubscribers': metrics.numSubscribers && this.fieldFunctions.increment(metrics.numSubscribers),
        'metrics.numProducts': metrics.numActiveProducts && this.fieldFunctions.increment(metrics.numActiveProducts),
        'metrics.numSales': metrics.numSales && this.fieldFunctions.increment(metrics.numSales),
        'metrics.totalRevenue': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalRevenue),
        'metrics.totalTips': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalTips),
      }),
    );
  }

  public async findSubsExpiring(days: number) {
    const nowM = moment.utc().hours(0).minutes(0).seconds(0).milliseconds(0);
    const now = nowM.unix();
    const startM = nowM.add(days, 'days');
    const start = startM.unix();
    const end = startM.add(1, 'days').unix();

    console.debug(`Looking for subs that expire in [${days}] days`, {
      now, start, end
    });

    return this.find({
      where: [
        { field: 'subscription.trialEndDate', operator: '>=', value: start },
        { field: 'subscription.trialEndDate', operator: '<', value: end },
      ]
    });
  }

  public async addPaymentHistory(id: string, paymentHistory: PaymentHistory): Promise<PaymentHistory> {
    const createdAt = moment().unix();
    const ph = {
      ...paymentHistory,
      createdAt,
    };

    return this.collection()
      .doc(id)
      .collection(PAYMENT_HISTORY_COLLECTION)
      .add(ph)
      .then(doc => ({
        ...ph,
        id: doc.id,
      }));
  }

  public async getPaymentHistory(id: string): Promise<PaymentHistory> {
    return this.collection()
      .doc(id)
      .collection(PAYMENT_HISTORY_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          })),
      );
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      addPaymentHistory(paymentHistory: PaymentHistory) {
        return this._repository.addPaymentHistory(this.id, paymentHistory);
      },
      getPaymentHistory() {
        return this._repository.getPaymentHistory(this.id);
      },
      areSubscriptionFeaturesActive() {
        return areSubscriptionFeaturesActive(this.subscription);
      },
      isNewUnsubscribedUser() {
        return this.tier?.name === 'Unpaid' && !this.subscription;
      },
      isPaidSubscriptionEnded() {
        return this.subscription?.cancellation !== undefined && this.subscription?.cancellation?.reason !== 'trial-expired';
      },
      isInTrial() {
        return this.subscription?.status === 'trial';
      },
      isTrialCompleted() {
        return this.subscription?.cancellation?.reason === 'trial-expired';
      },
      isLegacyTrialCompleted() {
        return this.subscription?.isTrialOver;
      },
      hasLegacySubscription() {
        return (this.tier.name === 'Founder' || this.tier.name === 'Starter') || this.legacyTier;
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export const toAccountTier = (tier: Tier): AccountTier =>
  _.pick(tier, ['id', 'name', 'castironTakeRate', 'takeRateLevels', 'payoutFrequency']) as AccountTier;
