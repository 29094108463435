import React, { ReactNode } from "react";
import { FeatureName as FeatureName } from '@castiron/domain';
import useFeatures from "./useFeatures";

interface Props {
  name: FeatureName | undefined;
  disabled?: ReactNode;
}

const FeatureGate: React.FC<Props> = (props) => {
  const { name, disabled, children } = props;
  const features = useFeatures();

  const show = !name || features.includes(name);

  return show ? <>{ children }</> : (disabled) ? <>{ disabled }</> : <></>;
}

export default FeatureGate;
