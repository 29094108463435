import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { useTracking } from '@castiron/utils';
import { getTaxonomyAction } from '../../../store/reducers/taxonomy';
import { BreadIcon, Button, CakeIcon, CharcuterieIcon, CookieIcon, CupcakeIcon, PastriesIcon, PreparedMealsIcon, SauceIcon, SnacksIcon, Typography, WarningIcon } from '@castiron/components';
import { TaxonomyCategory } from '@castiron/domain';
import OnboardingStepFooter from './OnboardingStepFooter';
import { useFormikContext } from 'formik';
import { trackHubSpotContactPage } from "../../../lib/trackHubSpotContactEvent";

type Props = {
  setCategories?: (category) => void;
  urlCategory?: TaxonomyCategory[];
};

const getInnerHeight = () => {
  const { innerHeight } = window;
  return innerHeight;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    margin: '16px 0',
  },
  categoryButton: {
    border: `1px solid ${theme.branding.gray[400]}`,
    backgroundColor: theme.branding.gray[100],
    opacity: '100%',
    display: 'flex',
    height: 64,
    justifyContent: 'flex-start',
    margin: '8px 0',
    marginLeft: 0,
    padding: 12,
    width: '96%',

    '& svg': {
      position: 'relative',
      top: 6,
      left: 5,
      fontSize: '2rem',
    },

    [theme.breakpoints.down('sm')]: {
      margin: '4px 8px',
    },
  },
  categoryImg: {
    maxWidth: '100%',
  },
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    maxWidth: '588px',
    borderBottom: 'none',
    borderRadius: '12px 12px 0 0',
    padding: '32px',
    margin: 'auto',
    height: getInnerHeight() - 175,
    maxHeight: getInnerHeight() - 175,
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: theme.branding.gray[500],
      '-webkit-box-shadow': `0 0 1px ${theme.branding.gray[100]}`
    },

    [theme.breakpoints.down('sm')]: {
      padding: '24px',
      border: 'none',
      marginTop: 16,
      height: getInnerHeight() - 168,
      maxHeight: getInnerHeight() - 168,
    },
  },
  errorContainer: {
    padding: '21px 16px',
    backgroundColor: theme.branding.red.light,
    borderRadius: 8,
    margin: '0 10px 0 2px',
    '& svg': {
      position: 'relative',
      top: 3,
    },
    '& p': {
      color: theme.branding.red.dark,
    },
  },
  name: {
    alignSelf: 'flex-start',
    marginLeft: 8,
    marginTop: 16,
    color: theme.branding.gray[600],
  },
  footerContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '0 0 12px 12px',
    margin: 'auto',
    padding: 24,
    maxWidth: '588px',

    [theme.breakpoints.down('sm')]: {
      border: 'none',
      borderTop: `1px solid ${theme.branding.gray[400]}`,
    },
  },
  selected: {
    border: `2px solid ${theme.branding.blue.primary}`,
  },
  subtitle: {
    color: theme.branding.gray[600],
  },
  svgContainer: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: 4,
    marginRight: 12,
    height: 40,
    width: 40,
  },
  title: {
    color: theme.branding.gray[800],
    marginBottom: 8,
  },
  titleContainer: {
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  breadIcon: {
    '& svg': {
      top: 10,
    }
  },
  cupcakeIcon: {
    '& svg': {
      top: 4,
    }
  },
  pastriesIcon: {
    '& svg': {
      top: 8,
    }
  },
  viewMoreButton: {
    fontSize: '14px',
    padding: '16px 13.5px',
    height: '48px',
    width: '102px',
    marginTop: '16px',
  },
}));

const OnboardingCategories: React.FC<Props> = (props: Props) => {
  const { urlCategory, setCategories } = props;
  const dispatch = useAppDispatch();
  const { setFieldValue, values }: any = useFormikContext();
  const classes = useStyles();
  const [selectedCategories, setSelectedCategories] = useState<TaxonomyCategory[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const { trackEvent } = useTracking();

  const { taxonomy } = useAppSelector(state => ({
    taxonomy: state.taxonomy.taxonomy,
  }));

  useEffect(() => {
    dispatch(getTaxonomyAction());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (urlCategory) {
      setSelectedCategories(urlCategory);
      setFieldValue('categories', urlCategory);
    }
  }, [urlCategory]);

  const handleCategoryClick = (category: TaxonomyCategory) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter(c => c.name !== category.name));
    }
    else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleParentCategoryClick = (categories: TaxonomyCategory[]) => {
    if (selectedCategories.includes(categories[0])) {
      const filteredCats = selectedCategories.filter((c) => !categories.includes(c));
      setSelectedCategories(filteredCats);
    }
    else {
      setSelectedCategories([...selectedCategories, ...categories]);
    }
  };

  const handleSubmit = () => {
    if (selectedCategories.length) {
      setCategories(selectedCategories);

      trackEvent('Onboarding Category Selection', {
        onboardingForm: {
          categories: selectedCategories.map(c => c.name),
          numberOfCategoriesPresented: selectedCategories.length,
          email: values.email,
        },
      });
      trackHubSpotContactPage({
        email: values.email,
        food_type: selectedCategories.map(c => c.name).join(',')
      }, '/signup/food');

    } else {
      setErrorMsg('Please select at least one option.');
      const el = document.getElementById('categoryContainer');
      el.scrollTop = 0;
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setErrorMsg('');
    }
  }, [selectedCategories]);

  const iconCheck = (categoryName) => {
    switch (categoryName) {
      case 'Cookies':
        return <Grid container justify="center" alignItems="center" className={classes.svgContainer}><CookieIcon /></Grid>;
      case 'Cakes':
        return <Grid container justify="center" alignItems="center" className={classes.svgContainer}><CakeIcon /></Grid>;
      case 'Cupcakes':
        return <Grid container justify="center" alignItems="center" className={`${classes.svgContainer} ${classes.cupcakeIcon}`}><CupcakeIcon /></Grid>;
      case 'Breads':
        return <Grid container justify="center" alignItems="center" className={`${classes.svgContainer} ${classes.breadIcon}`}><BreadIcon /></Grid>;
      case 'Sweet Pastries':
        return <Grid container justify="center" alignItems="center" className={`${classes.svgContainer} ${classes.pastriesIcon}`}><PastriesIcon /></Grid>;
      case 'Charcuterie Boards':
        return <Grid container justify="center" alignItems="center" className={classes.svgContainer}><CharcuterieIcon /></Grid>;
      default:
        return <Grid container justify="center" alignItems="center" className={classes.svgContainer}><CookieIcon /></Grid>;
    }
  };

  return (
    <Grid container style={{ maxHeight: '569px' }} item direction="column" wrap="nowrap">
      <Grid className={classes.container} id="categoryContainer" container item justify="center" wrap="wrap" xs={12} md={6}>
        <Grid
          container
          className={classes.titleContainer}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <Typography className={classes.title} variant="h2">What kind of food do you sell?</Typography>
          <Typography className={classes.subtitle} variant="body1">
            Please select all that apply. This will help us customize your experience.
          </Typography>
        </Grid>
        {errorMsg && (
          <Grid className={classes.errorContainer} container item wrap="wrap" xs={12}>
            <Typography variant="body2">
              <WarningIcon viewBox='0 0 24 24' /> {errorMsg}
            </Typography>
          </Grid>
        )}
        {taxonomy?.categories.map(category => (
          category.urlParam === 'main' &&
          category.subcategories.map(subcategory => (
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className={`${classes.categoryButton} ${selectedCategories.includes(subcategory) ? classes.selected : ''}`}
                onClick={(): void => {
                  handleCategoryClick(subcategory);
                }}
                variant="outlined"
                key={subcategory.name}
              >
                {category.urlParam === 'main' && iconCheck(subcategory.name)}<Typography variant="button">{subcategory.name}</Typography>
              </Button>
            </Grid>
          )) ||
          category.urlParam === 'saucesspices' && (
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className={`${classes.categoryButton} ${selectedCategories.includes(category.subcategories[0]) ? classes.selected : ''}`}
                onClick={(): void => {
                  handleParentCategoryClick(category.subcategories);
                }}
                variant="outlined"
                key='saucesspices'
              >
                <Grid container justify="center" alignItems="center" className={classes.svgContainer}><SauceIcon /></Grid><Typography variant="button">{'Sauce & Spices'}</Typography>
              </Button>
            </Grid>
          ) ||
          category.urlParam === 'mealprep' && (
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className={`${classes.categoryButton} ${selectedCategories.includes(category.subcategories[0]) ? classes.selected : ''}`}
                onClick={(): void => {
                  handleParentCategoryClick(category.subcategories);
                }}
                variant="outlined"
                key='mealprep'
              >
                <Grid container justify="center" alignItems="center" className={classes.svgContainer}><PreparedMealsIcon /></Grid><Typography variant="button">Prepared Meals</Typography>
              </Button>
            </Grid>
          ) ||
          category.urlParam === 'snacks' && (
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className={`${classes.categoryButton} ${selectedCategories.includes(category.subcategories[0]) ? classes.selected : ''}`}
                onClick={(): void => {
                  handleParentCategoryClick(category.subcategories);
                }}
                variant="outlined"
                key='snacks'
              >
                <Grid container justify="center" alignItems="center" className={classes.svgContainer}><SnacksIcon /></Grid><Typography variant="button">Snacks</Typography>
              </Button>
            </Grid>
          ) ||
          category.urlParam === 'other' && (
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className={`${classes.categoryButton} ${selectedCategories.includes(category.subcategories[0]) ? classes.selected : ''}`}
                onClick={(): void => {
                  handleParentCategoryClick(category.subcategories);
                }}
                variant="outlined"
                key='other'
              >
                <Typography variant="button">Other</Typography>
              </Button>
            </Grid>
          )
        ))}
      </Grid>
      <Grid container className={classes.footerContainer} item xs={12} md={6}>
        <OnboardingStepFooter stepIndex={0} totalSteps={3} onContinueClick={handleSubmit} />
      </Grid>
    </Grid >
  );
};

export default OnboardingCategories;
