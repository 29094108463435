import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Typography, WarningIcon } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { Box, Grid, Radio, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingStepFooter from './OnboardingStepFooter';
import { OnboardingInfo } from './Onboarding';
import { trackHubSpotContactPage } from '../../../lib/trackHubSpotContactEvent';

type Props = {
  progressStep: (values: OnboardingInfo) => void;
};

const getInnerHeight = () => {
  const { innerHeight } = window;
  return innerHeight;
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 'inherit',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      height: getInnerHeight() - 50,
      maxHeight: getInnerHeight() - 50,
      padding: '24px',
    },
  },
  active: {
    border: `2px solid ${theme.branding.blue.primary}`,
    margin: '-1px !important',
  },
  dollarIconContainer: {
    background: theme.branding.blue.light,
    borderRadius: 12,
    width: 40,
    height: 40,
  },
  dollarIconText: {
    color: theme.branding.blue.primary,
    fontSize: 14,
  },
  error: {
    backgroundColor: theme.branding.red.light,
  },
  errorInner: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: theme.branding.red.dark,
  },
  footerContainer: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: '0 0 12px 12px',
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    marginTop: '10px',
    padding: 24,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      marginTop: '0px',
    },
  },
  formWrapper: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    maxWidth: '588px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
  icon: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px',
  },
  mobileWrapper: {
    border: 'none',
  },
  numbers: {
    display: 'flex',
    alignItems: 'center',
  },
  optionButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 2,
    borderRadius: '8px',
  },
  optionsHeader: {
    margin: '16px 0px',
  },
  subtitle: {
    color: theme.branding.gray[600],
  },
  zeroIcon: {
    color: theme.branding.blue.dark,
  },
  warningIcon: {
    margin: '24.5px 0px 6px 24px',
  },
  radio: {
    color: theme.branding.gray[400],
    '&$checked': {
      color: theme.branding.blue.primary,
    },
  },
  checked: {},
}));

const OrderVolume: React.FC<Props> = (props: Props) => {
  const [selectedVolume, setSelectedVolume] = useState('');
  const [active, setActive] = useState(0);
  const [error, setError] = useState(false);

  const { progressStep } = props;
  const { errors, touched, values, setFieldValue, setFieldTouched }: any = useFormikContext();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const DollarIcon = ({ text }) => (
    <Grid container alignItems="center" justify="center" className={classes.dollarIconContainer}>
      <Typography className={classes.dollarIconText} variant="subtitle1">
        {text}
      </Typography>
    </Grid>
  );

  const volumeOptions = [
    {
      value: '$0-$300/mo',
      icon: <DollarIcon text={'$'} />,
    },
    {
      value: '$301-$1,000/mo',
      icon: <DollarIcon text={'$$'} />,
    },
    {
      value: '$1,001-$5,000/mo',
      icon: <DollarIcon text={'$$$'} />,
    },
    {
      value: '$5,001+/mo',
      icon: <DollarIcon text={'$$$$'} />,
    },
  ];

  const onContinue = () => {
    if (selectedVolume !== '' && !errors.orderVolume) {
      progressStep(values);
    } else {
      setError(true);
      window.scrollTo(0, 0);
    }

    trackEvent('Order Volume Captured', {
      ...values,
    });

    trackHubSpotContactPage(
      {
        email: values.email,
        volume_of_orders_per_week: values.orderVolume,
      },
      '/signup/volume',
    );
  };

  return (
    <Grid container className={classes.formWrapper}>
      <Grid item container direction="column" className={classes.content}>
        <Box className={classes.optionsHeader}>
          <Typography variant="h2">In an average month, what is your order volume?</Typography>
          <Typography variant="body1" className={classes.subtitle}>
            This will help us pick the most effective plan for you.
          </Typography>
        </Box>
        <Box>
          {error && (
            <Box className={`${classes.optionButton} ${classes.error}`}>
              <Grid container direction="row" className={classes.errorInner}>
                <Box className={classes.warningIcon}>
                  <WarningIcon htmlColor={theme.branding.red.dark} fontSize="large" />
                </Box>
                <Typography variant="body2" className={classes.errorText}>
                  Please select an option.
                </Typography>
              </Grid>
            </Box>
          )}
          <Grid container xs={12} spacing={2}>
            {volumeOptions.map((option, index) => (
              <Grid item xs={12} sm={12} md={6}>
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    setActive(index + 1);
                    setSelectedVolume(option.value);
                    setFieldValue('orderVolume', option.value);
                    error && setError(false);
                  }}
                  className={`${active === index + 1 ? classes.active : null} ${classes.optionButton}`}
                >
                  <Grid container direction="row" xs={12} justify="space-between">
                    <Grid container item justify="flex-start" alignItems="center" xs={10}>
                      <Box className={classes.icon}>{option.icon}</Box>
                      <Typography variant="button" className={classes.numbers}>
                        {option.value}
                      </Typography>
                    </Grid>
                    <Grid container item justify="center" alignItems="center" xs={2}>
                      <Radio
                        checked={active === index + 1}
                        classes={{ root: classes.radio, checked: classes.checked }}
                      />
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Box className={classes.footerContainer}>
        <OnboardingStepFooter stepIndex={1} totalSteps={3} onContinueClick={onContinue} />
      </Box>
    </Grid>
  );
};

export default OrderVolume;
