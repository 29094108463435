import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const OnlineStoreIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 16" {...props}>
    <path
      d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM2 5H12.5V8.5H2V5ZM2 10.5H12.5V14H2V10.5ZM18 14H14.5V5H18V14Z"
      fill="#005FFF"
    />
  </SvgIcon>
);
