import React, { SetStateAction } from "react";
import { Plan, PlanComparisonItem, Price } from "@castiron/domain";

export type CurrentPlanStatus = 'trial' | 'active' | 'expired' | 'cancelled';

export interface PlanCardData {
  plan: Plan;
  takeRate: number;
  features?: string[];
  isRecommended?: boolean;
  isCurrentPlan?: boolean;
  ctaText?: string;
  notes?: string[];
}

export interface PlanContextData {
  planCardData: PlanCardData[];
  planCardDataIsLoading: boolean;
  step: number;
  setStep?: React.Dispatch<SetStateAction<number>>;
}

export const PlanContext = React.createContext<PlanContextData>({
  planCardData: [],
  planCardDataIsLoading: false,
  step: 0
});
