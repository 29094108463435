import axios from "axios";
import firebase from 'firebase/compat/app';

const loginService = async (token: string) => {
  return axios.post('/auth/login', {token}, {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });
}

const setDomainAuth = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  if (!token) return ;
  const resp = await loginService(token);
  return resp;
}

export default setDomainAuth;