import React, { useRef } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { AfterPurchaseDetails, Link, Button, Card, Chip, Typography } from '@castiron/components';
import { Customer, determineOrderFulfillmentDateTime, FulfillmentOption, fulfillmentTypeDisplayName, ShippingInfo, Transaction } from '@castiron/domain';
import { defaultTimeZone } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

type Props = {
  customer: Customer;
  transaction: Transaction;
  onUpdate: () => void;
  readonly?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  copyButton: {
    padding: 0,
  },
  /*
   * our current grid doesn't support half steps in spacing until we upgrade MUI :(
   * kill this with fire if/when we ever upgrade MUI
   */
  data: {
    marginTop: '4px',
  },
  editButton: {
    /* override what we have in the theme that is counter to design :( */
    minWidth: 0,
    /* need 4 padding on bottom to align it with text it is next to */
    padding: '0 0 4px',
  },
}));

const FulfillmentDetailsBox: React.FC<Props> = (props: Props) => {
  const { customer, transaction, onUpdate, readonly = false } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const copyAddr = useRef();

  const isQuote = transaction?.order?.type === 'custom';
  const fulfillment: FulfillmentOption = transaction?.order?.fulfillmentOption;
  const trackingNumber = transaction?.fulfillOrderInfo?.trackingNumber;
  const trackingNumberUrl = transaction?.fulfillOrderInfo?.trackingNumberUrl;
  /* 
   * we were supporting addresses via the customer before when no shipping info was present,
   * so I'm assuming this is still needed for legacy order purposes
   */
  const shippingInfo: ShippingInfo =
    (fulfillment?.type === 'shipping' || fulfillment?.type === 'delivery')
    && (
      transaction?.shippingInfo
      || (!!customer?.address && {
        recipientName: `${customer?.firstName || ''} ${customer?.lastName || ''}`.trim(),
        address: {
          fullAddress: customer.address,
          addressLine1: customer.addressOne,
          addressLine2: customer.addressTwo,
          city: customer.city,
          region: customer.state,
          postalCode: customer.postalCode,
          country: customer.country,
        },
      })
    );

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleCopyAddr = () => {
    const addrDiv = copyAddr.current;

    if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(addrDiv);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    document.execCommand('copy');
  };

  if (!fulfillment) return null;

  const timezone = shop?.config?.timeZone || defaultTimeZone;
  const fulfillmentDates = determineOrderFulfillmentDateTime(transaction.order);
  const startDate = fulfillmentDates.length > 0 && moment.unix(fulfillmentDates[0]).tz(timezone);
  const dateFormatted = startDate ? startDate.format('MM/DD/YYYY') : null;
  const startTime = fulfillmentDates.length > 1 && startDate.format('h:mma');
  const endTime = fulfillmentDates.length > 1 && moment.unix(fulfillmentDates[1]).tz(timezone).format('h:mma');
  const fulfillmentDatesFormatted = startTime && endTime ? `${dateFormatted}, ${startTime} - ${endTime}` : dateFormatted;

  const showDateTimeModal = () => {
    dispatch(openModal({
      modalType: 'FULFILLMENT_DATE_TIME',
      modalProps: {
        transaction,
        onUpdate,
      },
    }));
  };

  return (
    <Card title='Fulfillment'>
      {
        fulfillment?.type === 'custom' ? ( // Legacy support for "custom" fulfillments
          <>
            <Chip colorScheme="info" uppercase bold>
              Custom
            </Chip>
            <Typography variant="body1" style={{ marginTop: '1em' }}>
              {fulfillment.description ? fulfillment.description : fulfillment.notes}
            </Typography>
          </>
        ) : (
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <Typography variant='subtitle2'>Fulfillment</Typography>
              <Chip colorScheme="info" bold style={{ marginTop: '8px' }} >
                {fulfillmentTypeDisplayName(fulfillment.type)}
              </Chip>
            </Grid>

            { !isQuote &&
              <Grid item>
                <Typography variant='subtitle2'>
                  {fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Option
                </Typography>
                <Typography variant='body1' className={classes.data}>{fulfillment.displayName}</Typography>
              </Grid>
            }

            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant='subtitle2'>
                    {fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Date {fulfillment.type === 'shipping' ? '' : '& Time'}
                  </Typography>
                </Grid>
                {
                  startDate && !readonly &&
                  <Grid item>
                    <Button variant='text' onClick={showDateTimeModal} className={classes.editButton}>Edit</Button>
                  </Grid>
                }
              </Grid>
              {
                startDate &&
                <Typography variant='body1' className={classes.data}>{fulfillmentDatesFormatted}</Typography>
              }
              {
                !startDate && !readonly &&
                <Button variant='outlined' onClick={showDateTimeModal} className={classes.data}>+ Add</Button>
              }
            </Grid>

            {
              fulfillment?.schedule?.type === 'flexible' && !isQuote &&
              <Grid item>
                <Typography variant='subtitle2'>
                  {fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Flexible Fulfillment
                </Typography>
                <Typography variant='body1' className={classes.data}>{fulfillment.schedule.description}</Typography>
              </Grid>
            }

            {
              !!shippingInfo &&
              <Grid item>
                <Typography variant="subtitle2">
                  {fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Details
                </Typography>
                <Typography variant="body1" className={classes.data}>
                  {shippingInfo?.recipientName}
                </Typography>
                <div ref={copyAddr}>
                  <Typography variant="body1" className={classes.data}>
                    {shippingInfo?.address?.addressLine1}
                  </Typography>
                  <Typography variant="body1" className={classes.data}>
                    {`${shippingInfo?.address?.city}, ${shippingInfo?.address?.region}, ${shippingInfo?.address?.postalCode}${shippingInfo?.address?.country ? ', ' + shippingInfo?.address?.country : ''}`}
                  </Typography>
                  {
                    shippingInfo?.address?.addressLine2 &&
                    <Typography variant="body1" className={classes.data}>
                      {shippingInfo?.address?.addressLine2}
                    </Typography>
                  }
                </div>
                <Button onClick={handleCopyAddr} variant="text" className={`${classes.copyButton} ${classes.data}`}>
                  Copy Address
                </Button>
              </Grid>
            }

            {
              fulfillment.type === 'shipping' &&
              <Grid item>
                <Typography variant="subtitle2">Tracking</Typography>
                {
                  trackingNumber && trackingNumberUrl ? (
                    <Typography variant="body1" className={classes.data} style={{ wordBreak: 'break-word' }}>
                      <Link href={trackingNumberUrl}>{trackingNumber}</Link>
                    </Typography>
                  ) : (
                    <Typography variant="body1" className={classes.data}>
                      None
                    </Typography>
                  )
                }
              </Grid>
            }

            {
              (fulfillment?.fulfillmentNotes || fulfillment?.notes) && // I have no idea why both of these exist
              <Grid item>
                <Typography variant='subtitle2'>{fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Notes</Typography>
                <Typography variant='body1' className={classes.data}>
                  {fulfillment.fulfillmentNotes || fulfillment.notes}
                </Typography>
              </Grid>
            }

            {
              customer?.instructions && // should never be possible to have both fulfillment notes and customer instructions
              <Grid item>
                <Typography variant='subtitle2'>{fulfillmentTypeDisplayName(fulfillment?.type, 'short')} Notes</Typography>
                <Typography variant='body1' className={classes.data}>{customer.instructions}</Typography>
              </Grid>
            }

            {
              fulfillment.afterPurchaseDetails &&
              <Grid item>
                <AfterPurchaseDetails content={fulfillment?.afterPurchaseDetails} />
              </Grid>
            }
          </Grid>
        )
      }
    </Card >
  );
};

export default FulfillmentDetailsBox;