
import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const WebsiteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4167 3.6665H4.58333C3.56583 3.6665 2.75 4.4915 2.75 5.49984V16.4998C2.75 17.5082 3.56583 18.3332 4.58333 18.3332H17.4167C18.425 18.3332 19.25 17.5082 19.25 16.4998V5.49984C19.25 4.4915 18.4342 3.6665 17.4167 3.6665ZM16.5 16.4998H5.5C4.99583 16.4998 4.58333 16.0873 4.58333 15.5832V7.33317H17.4167V15.5832C17.4167 16.0873 17.0042 16.4998 16.5 16.4998Z" fill="#004CCC" />
    </svg>
  </SvgIcon>
);
