import React from 'react';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { Button } from '@castiron/components';
import { ButtonProps } from '@castiron/components';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type AddProductButtonProps = Override<ButtonProps, {}>;

const AddProductButton: React.FC<AddProductButtonProps> = (props: AddProductButtonProps) => {
  const { ...restOfProps } = props;
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          createStandardProductLink: '/products/add/standard',
          createCustomProductLink: '/products/add/custom',
          modalOpen: true,
        },
      }),
    );
  };

  return (
    <Button onClick={handleClick} {...restOfProps}>
      Add a Product
    </Button>
  );
};

export default AddProductButton;
