import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, FileCopy, Link, VisibilityOutlined } from '@material-ui/icons';
import { BaseProduct } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import { deleteProductAction, duplicateProductAction } from '../../../../store/reducers/products';
import Dropdown, { DropDownOption } from '../../../Dropdown';
import EllipsisMenu, { EllipsisMenuOption } from '../../../Layout/Header/EllipsisMenu';

interface Props {
  product: BaseProduct;
  isEditMode: boolean;
  previewProduct: () => void;
};

const ProductActionsDropdown: React.FC<Props> = (props: Props) => {
  const { product, isEditMode, previewProduct } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [copyLinkLabel, setCopyLinkLabel] = useState('Copy Link');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDuplicate = async () => {
    if (!product?.id) return;

    dispatch(duplicateProductAction(product.id)).then(res => {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                {/* @ts-ignore */}
                Product <strong>{res.payload.title}</strong> was duplicated.
              </>
            ),
          },
        }),
      );
      trackEvent('Product Duplicated', { product: { id: product.id } });
      /* this doesn't work today, not going to worry about it atm, something else to come back to */
      //@ts-ignore
      history.push(`/products/edit/${res.payload.id}`);
    });
  };

  const sleep = async (msec) => {
    return new Promise(resolve => setTimeout(resolve, msec));
  };

  const handleCopy = useCallback(async () => {
    setCopyLinkLabel('Link Copied!');
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}/product/${product?.id}`);

    setTimeout(() => {
      setCopyLinkLabel('Copy Link');
    }, 1500);

    /* sleep for a bit before letting the drop down close */
    await sleep(1200);
  }, [product, shop]);

  const onDelete = useCallback(
    id => async (): Promise<void> => {
      dispatch(deleteProductAction(id)).then(res => {
        trackEvent('Product Deleted', { product: { id: id } });
        history.push('/products');
        dispatch(closeModal());
      });
    },
    [],
  );

  const onDeleteClick = (): void => {
    if (!product?.id) return;

    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          onDelete: onDelete(product.id),
        },
      }),
    );
  };

  const options: DropDownOption[] = [
    {
      label: 'Preview',
      icon: <VisibilityOutlined />,
      onClick: previewProduct,
    },
    isEditMode && {
      label: 'Duplicate',
      icon: <FileCopy />,
      onClick: handleDuplicate,
    },
    isEditMode && {
      label: copyLinkLabel,
      icon: <Link />,
      onClick: handleCopy,
    },
    isEditMode && {
      label: 'Delete',
      color: 'error',
      icon: <Delete />,
      onClick: onDeleteClick,
    },
  ].filter(a => !!a).map(a => a as DropDownOption);
  const ellipsisOptions: EllipsisMenuOption[] = options.map(option => ({
    display: option.label,
    color: option.color,
    icon: option.icon,
    action: option.onClick,
  }));

  return isMobile ?
    (
      <EllipsisMenu
        options={ellipsisOptions}
      />
    ) : (
      <Dropdown
        title='Actions'
        options={options}
      />
    );
};

export default ProductActionsDropdown;