import React from 'react';
import clsx from 'clsx';
import { Radio } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

interface Props {
  checked: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'transparent',
    '$root.Mui-focusVisible &': {
      outline: `2px auto ${theme.branding.blue.primary}`,
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.branding.blue.light,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.branding.gray[300],
    },
  },
  checkedIcon: {
    backgroundColor: theme.branding.blue.primary,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.branding.blue.primary,
    },
  },
}))

const SimpleStyledRadio: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      <Radio
        className={classes.root}
        disableRipple
        color="primary"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        disabled={props.disabled}
        onChange={props.onChange}
        {...props}
      />
    </>
  );
};

export default SimpleStyledRadio;