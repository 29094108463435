import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Theme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { Button, Typography, WarningIcon } from '@castiron/components';

interface Props {
  errors: any;
  className?: string;
  productType: 'standard' | 'custom';
  text: string;
  scrollToError: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.branding.red.light,
    borderRadius: '8px',
  },
  errorInner: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 16,

    '& svg': {
      height: 32,
      width: 32,
    },
  },
  errorHeading: {
    color: theme.branding.red.dark,
    display: 'inline',
    fontSize: '14px',
    lineHeight: '22px',
  },
  errorText: {
    color: theme.branding.red.dark,
    display: 'inline',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,

    '& button': {
      color: theme.branding.red.dark,
      textDecoration: 'none',
      display: 'inline',
      fontSize: '14px',
      lineHeight: '22px',
      padding: 0,
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        textDecoration: 'underline',
      },

      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
  },
  textContainer: {
    marginLeft: 8,
    marginTop: -8,
  },
}));

const ProductErrorBox: React.FC<Props> = (props: Props) => {
  const { errors, text, className, productType, scrollToError } = props;
  const theme = useTheme();
  const classes = useStyles();
  const ref = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [errorList, setErrorList] = React.useState<any>([]);
  const [varErrors, setVarErrors] = React.useState<any>([]);

  useEffect(() => {
    if (errors) {
      const errorArray = Object.keys(errors).map(key => {
        if (key !== 'variations') {
          return errors[key];
        }
      });
      setErrorList(errorArray);

      if (errors.variations) {
        let varErrorsCount = 0;

        const variationErrors = errors.variations.map((variation: any, index: number) => {
          if (variation) {
            if (variation.values) varErrorsCount = variation.values.filter((value: any) => {
              if (value) return value.name;
            }).length;
            variation.name && varErrorsCount++;
            return { [`${productType === 'custom' ? 'Question' : 'Variation'} ${index + 1}`]: varErrorsCount };
          }
        });

        setVarErrors(variationErrors);
      }
    }
  }, [errors]);

  return (
    <Box className={clsx([classes.error, className])}>
      <Grid container direction="row" className={classes.errorInner} justify="center" wrap="nowrap">
        <Grid item xs={2}>
          <WarningIcon htmlColor={theme.branding.red.dark} fontSize="large" />
        </Grid>
        <Grid item xs={10} className={classes.textContainer}>
          <Typography variant="body2" className={classes.errorHeading}>
            {text}
          </Typography>
          {errorList.length > 0 && (
            <Typography variant="body2" className={classes.errorText}>
              {errorList.join(', ')}
            </Typography>
          )}
          {varErrors.length > 0 &&
            varErrors.map((error: any, index: number) => {
              if (error) {
                const key = Object.keys(error)[0];
                return (
                  <Typography variant="body2" className={classes.errorText}>
                    <Button onClick={() => scrollToError(`${key.split(' ').join('')}`)} variant="text">
                      {key}: {error[key]} errors{varErrors.length > 1 && index < varErrors.length - 1 && ','}
                    </Button>
                  </Typography>
                );
              };
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductErrorBox;
