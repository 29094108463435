import React, { useEffect, useState } from 'react';
import { Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import { Button, Input, Link, Typography } from '@castiron/components';
import { loginWithGoogle, loginWithFacebook } from '@castiron/castiron-firebase';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { shopRepository } from '../../../domain';
import { useAppDispatch } from '../../../hooks';
import { getShopAction } from '../../../store/reducers/shops';
import setDomainAuth from '../../Auth/domainAuth';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    margin: '0 auto',
    padding: '24px',
    maxWidth: '588px',
    [theme.breakpoints.up('sm')]: {
      border: `1px solid ${theme.branding.gray[400]}`,
      borderRadius: '12px',
    },
  },
  headerText: {
    padding: '4px',
  },
  separator: {
    width: '100%',
    height: '1px',
    marginTop: '10px',
    backgroundColor: theme.branding.gray[300],
  },
  separatorContainer: {
    marginTop: '16px',
  },
  separatorText: {
    textAlign: 'center',
  },
  socialSignUpContainer: {
    marginTop: '16px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '8px',
    padding: '12px 0',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  socialSignupLogo: {
    height: '20px',
    width: '20px',
    marginRight: '8px',
  },
  termsAndConditionsContainer: {
    textAlign: 'center',
  },
}));

interface Props {
  socialSubmit: (user) => void;
  showSpinner: () => void;
}

const Signup: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { socialSubmit, showSpinner } = props;

  const { errors, setTouched, touched, values } = useFormikContext();

  const loginWithProvider = async user => {
    // check for shop
    const shopExists = await shopRepository.get(user.uid);

    if (shopExists) {
      dispatch(getShopAction(user.uid));
    } else {
      showSpinner();
      socialSubmit(user);
    }
  };

  const googleSignUp = async () => {
    const result = await loginWithGoogle(undefined);
    await setDomainAuth();
    await loginWithProvider(result);
  };

  const facebookSignUp = async () => {
    const result = await loginWithFacebook(undefined);
    await setDomainAuth();
    await loginWithProvider(result);
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    // clear touched on this page from previous page's form submit
    setTouched(
      {
        ...touched,
        name: false,
        signup: {
          password: false,
        },
      }
    )
  }, [setTouched]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Grid item container justify='center' xs={12} sm={6} spacing={1} className={classes.contentContainer}>
      <Grid item container xs={12}>
        <Grid item className={classes.headerText}>
          <Typography variant='h2'>Let's get this relationship cooking!</Typography>
        </Grid>
        <Grid item className={classes.headerText}>
          <Typography variant='body3'>Tell us how you would like to log in to your new store.</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} justify='space-between'>
        <Grid item container xs={12} justify='center' className={classes.socialSignUpContainer} onClick={googleSignUp}>
          <Grid item>
            <img src="/assets/img/google-g-logo.svg" alt="Google logo" className={classes.socialSignupLogo} />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>Sign up with Google</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify='center' className={classes.socialSignUpContainer} onClick={facebookSignUp}>
          <Grid item>
            <img src="/assets/img/facebook-f-logo.svg" alt="Facebook logo" className={classes.socialSignupLogo} />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>Sign up with Facebook</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} justify='space-between' className={classes.separatorContainer}>
        <Grid item xs={5} className={classes.separator} />
        <Grid item xs={2} className={classes.separatorText}>
          <Typography variant='body2'>or</Typography>
        </Grid>
        <Grid item xs={5} className={classes.separator} />
      </Grid>
      <Grid item xs={12}>
        <Input
          label='Email Address'
          type='text'
          required={true}
          name='email'
          value={getIn(values, 'email')}
          error={getIn(touched, 'email') && getIn(errors, 'email')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Input
          label="Password"
          type={passwordVisibility ? 'text' : 'password'}
          required={true}
          name='signup.password'
          error={getIn(touched, 'signup.password') && getIn(errors, 'signup.password')}
          endAdornment={(
            <IconButton onClick={handlePasswordVisibility}>
              {(!passwordVisibility ? <Visibility style={{ color: 'black' }} /> : <VisibilityOff style={{ color: 'black' }} />)}
            </IconButton>
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '65px' }}>
        <Button type='submit' variant='contained' fullWidth={true} style={{ height: '48px' }}>Finish Sign Up</Button>
      </Grid>
      <Grid item xs={12} className={classes.termsAndConditionsContainer}>
        <Typography variant='caption'>
          By signing up, you agree to our&nbsp;
          <Link target="_blank" href="https://www.castiron.me/terms-conditions">Terms of Service</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Signup;
