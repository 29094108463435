import React from 'react';
import { Typography, TypographyVariant } from '@material-ui/core';
import Dinero from 'dinero.js';

type Props = {
  amount: number;
  className?: any;
  variant?: TypographyVariant;
};

const Price: React.FC<Props> = (props: Props) => {
  const { amount, className, variant } = props;

  return (
    <Typography variant={variant || 'subtitle1'} className={className && className}>
      {amount ? Dinero({ amount }).toFormat('$0.00') : '-'}
    </Typography>
  );
};

export default Price;
