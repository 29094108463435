import React, { useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import {
  Box,
  Grid,
  Hidden,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  InputAdornment,
  TextField
} from '@material-ui/core';
import CheckboxSelector from './FormComponents/CheckboxSelector';
import InventoryInput from './FormComponents/InventoryInput';
import ProductStatus from './FormComponents/ProductStatus';
import PriceInput from './FormComponents/PriceInput';
import CategoryInput from './FormComponents/CategoryInput';
import PencilIcon from '../../../assets/img/pencil.svg';
import { Field, useFormikContext } from 'formik';
import { assets } from '@castiron/castiron-firebase';
import { Forms, Input, ImageUploader, SaveButton, DiscardButton, SvgIcon } from '@castiron/components';
import { MAX_UPLOAD_FILE_SIZE } from '../../../constants';
import RichTextInput from '../../RichTextEditor';
import Variations from './FormComponents/Variations';
import { ProductType } from '@castiron/domain';
import TermsModal from './FormComponents/TermsModal';
import ProductErrorBox from './ProductErrorBox';
import _, { initial } from 'lodash';

type Props = {
  onCancelClick?: () => void;
  onImageAdded?: () => void;
  onImageDeleted?: () => void;
  localImageUrl?: (url: string) => void;
  categories: any[];
  prodImageObj?: any;
  type: ProductType;
  placeholders?: Record<string, string>;
  isEditMode: boolean;
  setFooterCTAs: (ctas: ReactNode[]) => void;
  fromChecklist?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    width: '100%',
  },
  inventoryContainer: {
    marginTop: 17,
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[600],
  },
  infoIcon: {
    color: theme.branding.gray[700],
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  decorator: {
    color: '#FC6E26',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  categoryLabel: {
    marginBottom: 0,
    position: 'relative',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 52,
  },
  policyLabel: {
    color: theme.palette.common.black,
    marginBottom: 30,
    fontSize: 14,
    fontWeight: 700,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  editIcon: {
    width: 32,
    height: 32,
  },
  title: {
    textAlign: 'start',
    fontSize: 30,
    lineHeight: '36px',
    fontWeight: 700,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const EditProductForm: React.FC<Props> = (props: Props) => {
  const { onImageAdded, localImageUrl, onImageDeleted, categories, prodImageObj, type, isEditMode, placeholders, setFooterCTAs, fromChecklist } = props;
  const { values, setFieldValue, errors, handleChange, touched, submitCount, handleSubmit, isSubmitting, handleBlur }: any = useFormikContext();
  const [imageObj, setImageObj] = useState(prodImageObj?.downloadUrl);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dietaryOptions = [
    { label: 'Dairy Free', value: 'dairyFree' },
    { label: 'Gluten Free', value: 'glutenFree' },
    { label: 'Keto', value: 'keto' },
    { label: 'Kosher', value: 'kosher' },
    { label: 'Low/No Carb', value: 'lowNoCarb' },
    { label: 'Paleo', value: 'paleo' },
    { label: 'Wheat', value: 'wheat' },
    { label: 'Whole30', value: 'whole30' },
    { label: 'Vegan', value: 'vegan' },
    { label: 'Vegetarian', value: 'vegetarian' },
    { label: 'Organic', value: 'organic' },
    { label: 'Local', value: 'local' },
  ];
  const allergenOptions = [
    { label: 'Milk', value: 'milk' },
    { label: 'Eggs', value: 'eggs' },
    { label: 'Fish', value: 'fish' },
    { label: 'Shellfish', value: 'shellfish' },
    { label: 'Tree Nuts', value: 'treeNuts' },
    { label: 'Wheat', value: 'wheat' },
    { label: 'Peanuts', value: 'peanuts' },
    { label: 'Soy Beans', value: 'soyBeans' },
    { label: 'Sesame', value: 'sesame' },
  ];

  const getAssetById = async (id: string) => {
    const response = await assets.get(id);
    //@ts-ignore
    setImageObj(response?.downloadUrl);
  };

  useEffect(() => {
    if (imageObj === undefined) {
      getAssetById(values.image);
    }
  }, [values.image]);

  useEffect(() => {
    if (imageObj) {
      localImageUrl(imageObj);
    }
  }, [imageObj]);

  const handleCategoryChange = useCallback(category => {
    setFieldValue('category', category);
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation='/products' fromChecklist={fromChecklist} />,
      <SaveButton 
        handleSubmit={() => {
          // Scroll to the error box at the bottom of the page, if mobile, and top of the page for desktop
          if (isMobile) {
            window.scrollTo(0, document.body.scrollHeight);
          } else {
            window.scrollTo(0, 0);
          }
          handleSubmit();
        }}
        isSubmitting={isSubmitting}
      />
    ])
  }, [isSubmitting, values, isMobile]);

  const editorHeight = type === 'custom' ? 150 : 250;

  const handleErrorScroll = useCallback((id: string) => {
    const element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);


  return (
    <Grid className={classes.gridContainer} spacing={isMobile ? 0 : 7} container>
      <Grid className={classes.section} item xs={12} md={8}>
        <Box className={classes.titleContainer}>
          <Field
            as={TextField}
            fullWidth
            InputProps={{
              classes: { root: classes.title },
              endAdornment: (
                <InputAdornment position="start">
                  <SvgIcon className={classes.editIcon}>
                    <PencilIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Title your product"
            name="title"
            onBlur={handleBlur}
            variant="standard"
            autoFocus
            error={touched.title && errors.title}
          />
        </Box>
        <RichTextInput
          label="Description"
          fieldDescription="Some items you may want to include in your description: what makes your product unique, what a customer needs to provide, date cutoffs."
          name="description"
          placeholder="What do you want customers to know about this product when they are shopping?"
          height={editorHeight}
        />
        {type === 'custom' && (
          <>
            <Input
              type="text"
              label={
                <>
                  <InputLabel variant="standard" className={classes.policyLabel}>
                    Policies
                  </InputLabel>
                  <Typography className={classes.subLabel}>
                    All custom orders purchased through your shop come with standard{' '}
                    <span className={classes.link} onClick={e => setTermsModalOpen(true)}>
                      terms & conditions
                    </span>{' '}
                    to help protect your business. Use policies to give detailed information about how you take and
                    fulfill custom orders for this product.
                  </Typography>
                  <TermsModal open={termsModalOpen} onClose={() => setTermsModalOpen(false)} />
                </>
              }
              name="policies"
              multiline={true}
              rows={5}
              placeholder="What details do customers need to know about your custom product like lead time, cancellations, and design types?"
            />
          </>
        )}
        <Hidden smDown>
          <CheckboxSelector
            options={allergenOptions}
            name="allergen"
            label="Allergen Information"
            subLabel="Let your customers know if your product contains any of the following major food allergens:"
            optionalStyling={true}
          />
          <CheckboxSelector
            options={dietaryOptions}
            name="dietary"
            label="Dietary Information"
            subLabel="Let your customers know if your product is compatible with any of the following diets:"
            optionalStyling={true}
          />
          <Variations type={type} isEditMode={isEditMode} placeholders={placeholders} />
        </Hidden>
      </Grid>

      <Grid className={classes.section} item xs={12} md={4}>
        {!_.isEmpty(errors) && submitCount > 0 && !isMobile && (
          <Grid item>
            <ProductErrorBox errors={errors} text="Please fill out all required information: " productType={type} scrollToError={(id: string) => handleErrorScroll(id)} />
          </Grid>
        )}
        <div>
          <ImageUploader
            primaryLabel="Image"
            secondaryLabel="512x512px Recommended"
            onDeleteClick={(): void => {
              setImageObj('');
              setFieldValue('image', '');
              setFileSizeError(false);
              onImageDeleted();
            }}
            onFileDrop={(files): void => {
              if (files[0].size > MAX_UPLOAD_FILE_SIZE) {
                setFileSizeError(true);
              } else {
                setFileSizeError(false);
                setImageObj(URL.createObjectURL(files[0]));
                setFieldValue('image', { file: files[0], preview: URL.createObjectURL(files[0]) });
                onImageAdded();
              }
            }}
            preview={imageObj}
            height="340px"
            width="340px"
          />
          {errors.image && <Forms.SubmissionError msg={errors.image} />}
          {fileSizeError && <Forms.SubmissionError msg="Images must be under 10MB" />}
        </div>
        <ProductStatus
          classes={classes}
          value={values.status}
          onChange={(status): void => setFieldValue('status', status)}
        />
        {type === 'standard' && (
          <InventoryInput
            classes={classes}
            isUnlimitedInventory={values.unlimitedInventory}
            primaryLabel={
              <Typography className={classes.label}>
                Inventory<span className={classes.decorator}>*</span>
              </Typography>
            }
            value={values.inventory}
            placeholder="0"
            error={touched.inventory && errors.inventory}
          />
        )}
        {type === 'standard' && (
          <PriceInput
            primaryLabel={
              <Typography className={classes.label}>
                Price<span className={classes.decorator}>*</span>
              </Typography>
            }
            onChange={handleChange}
            name="price"
            placeholder="0.00"
            error={touched.price && errors.price}
          />
        )}
        {type === 'custom' && (
          <>
            <PriceInput
              primaryLabel={<Typography className={classes.label}>Starting Price</Typography>}
              name="startingPrice"
              placeholder="0.00"
              error={touched.startingPrice && errors.startingPrice}
            />
            <Input label="Minimum" placeholder="e.g. two dozen or $100" name="minimum" />
          </>
        )}
        <CategoryInput
          classes={classes}
          value={values.category}
          categories={categories}
          onChange={handleCategoryChange}
        />
        <Hidden mdUp>
          <CheckboxSelector
            options={allergenOptions}
            name="allergen"
            label="Allergen Information"
            subLabel="Let your customers know if your product contains any of the following major food allergens:"
            optionalStyling={true}
          />
          <CheckboxSelector
            options={dietaryOptions}
            name="dietary"
            label="Dietary Information"
            subLabel="Let your customers know if your product is compatible with any of the following diets:"
            optionalStyling={true}
          />
          <Variations type={type} isEditMode={isEditMode} placeholders={placeholders} />
          {!_.isEmpty(errors) && submitCount > 0 && isMobile && (
            <Grid item style={{ marginTop: '16px' }}>
              <ProductErrorBox errors={errors} text="Please fill out all required information: " productType={type} scrollToError={(id: string) => handleErrorScroll(id)} />
            </Grid>
          )}
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default EditProductForm;