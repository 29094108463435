import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Theme, Typography} from '@material-ui/core';
import SocialLoginButtons, {ExistingAccountError} from '../../Auth/SocialLoginButtons/SocialLoginButtons';
import {Button, Logo} from '@castiron/components';
import {useTracking} from '@castiron/utils';
import OnboardingSignUpForm from './OnboardingSignUpForm';
import CastironArtisans from '../../../assets/img/castiron-artisans.png';
import firebase from 'firebase/compat/app';
import {shopRepository} from '../../../domain';
import {createShopAction, setIsOnboarding} from '../../../store/reducers/shops';
import {OnboardingInfo} from './OnboardingV2';
import Spinner from '../../Spinner';
import {useLocation} from 'react-router';
import {FirebaseContext} from "@castiron/castiron-firebase";
import {Helmet} from "react-helmet";

type Props = {
  onboardingInfo?: OnboardingInfo;
};

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  onboardingContainer: {},
  subCategoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '16px 0 32px',
    width: '100%',
  },
  emailSignup: {
    color: theme.branding.gray[800],
    fontSize: '.875em',
    marginTop: 16,
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'none',
      color: theme.branding.blue.primary,
    },
  },
  header: {
    backgroundColor: theme.branding.gray[800],
    display: 'flex',
    padding: 16,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  headerText: {
    color: '#fff',
    fontSize: '.875em',
    fontWeight: 'bold',
  },
  helpText: {
    color: theme.branding.gray[600],
    fontSize: '0.75rem',
    marginTop: 8,
  },
  img: {
    height: 'auto',
    marginRight: 26,
    maxWidth: 250,
    width: '100%',
  },
  imgWrapper: {
    alignSelf: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    flexDirection: 'column',
    marginBottom: 32,
    marginTop: 88,
    padding: 24,
  },
  logo: {
    '& path': {
      fill: '#fff', // logo text
    },
    '& #Group-3': {
      fill: '#fff', // logo pan outline
    },
  },
  main: {
    marginTop: 108,
    '& h2': {
      marginBottom: 32,
    },
  },
  orText: {
    fontSize: '.875em',
  },
  signupContainer: {},
  subHeader: {
    color: theme.branding.damascus,
    fontSize: '1.25em',
  },
}));

const OnboardingSignUp: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {onboardingInfo: onboardingInfoProps} = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<OnboardingInfo>();
  const FirebaseApp = useContext(FirebaseContext);

  const onboardingInfo = location.state ? location.state : onboardingInfoProps;

  const [emailSelected, setEmailSelected] = useState(false);
  const [info, setInfo] = useState<OnboardingInfo>(onboardingInfo);
  const [facebookRerequest, setFacebookRerequest] = useState<boolean>(false);
  const [missingEmail, setMissingEmail] = useState<boolean>(false);
  const {trackUser, trackEvent, trackPage} = useTracking();
  const {urls, loading} = useAppSelector(state => ({
    urls: state.shops.blockedWebsiteUrls,
    loading: state.shops.loading,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!onboardingInfo) {
      const infoJson = window.localStorage.getItem('castironOnboardingForm');
      if (infoJson) {
        setInfo(JSON.parse(infoJson));
      }
    }
  }, [onboardingInfo]);

  const onSignup = useCallback(
    async (user: firebase.User) => {
      if (!user?.email) {
        setMissingEmail(true);
        const isFacebook = !!user.providerData?.find(i => i.providerId === 'facebook.com');
        setFacebookRerequest(isFacebook);
        await user.delete();
      } else {
        const shop = await shopRepository.get(user.uid);
        if (!shop) {
          trackUser(user.uid);

          trackEvent('Shop Login Created', {
            user: {
              id: user.uid,
              email: user.email,
              name: user.displayName,
            },
          });

          const nameParts = user.displayName.split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : undefined;

          await dispatch(
            createShopAction({
              businessName: `${firstName}'s Shop`,
              businessStage: info.businessStage,
              email: user.email,
              firstName,
              lastName,
              artisanCategory: {
                name: info.category,
                subcategories: info.subCategories.map(sc => sc.name),
              },
              tags: ['MadLibsOnboarding'],
            }),
          );
          await dispatch(setIsOnboarding(false));
          history.push('/');
        } else {
          await dispatch(setIsOnboarding(false));
          history.push('/');
        }
      }
    },
    [info],
  );

  const onExistingAccount = useCallback(async (err: ExistingAccountError) => {
    history.push('/signup/account/existing', {
      existingAccountEmail: err.email
    });
  }, [history]);

  const text = (missingEmail)
    ? <>We were not able to retrieve your email. Please try again or select a different login option.</>
    : <>Hi {info?.name}!<br/> It's great to see you here.</>;

  return loading ? (
    <Spinner show={true} size="fullscreen" label="Setting up your new Shop . . ."/>
  ) : (
    <>
      <Helmet>
        <link rel="stylesheet" href="/no-helpscout.css"/>
      </Helmet>
      <Grid className={classes.onboardingContainer} container direction="column" justify="center" alignContent="center">
        <Grid className={classes.header} container component="header" justify="space-between" alignItems="center">
          <Logo width={144} className={classes.logo}/>
          <Typography className={classes.headerText} variant="h1" align="left">
            A Shop for Your Food Biz
          </Typography>
        </Grid>
        <Grid className={classes.signupContainer} container direction="column" justify="center" alignContent="center">
          {emailSelected ? (
            <Grid className={classes.main} component="main" container direction="column" item xs={10} lg={6}>
              <OnboardingSignUpForm
                onboardingInfo={info}
                onSignup={onSignup}
                onExistingAccount={onExistingAccount}
                rerequestFacebook={facebookRerequest}
              />
              <Grid container justify="center" className={classes.helpText}>
                <Typography className={classes.emailSignup}>
                  Already have an account? <Link to="/">Log in</Link>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.main} component="main" container direction="column" item xs={10}>
              <Typography variant="h2" align="center">{text}</Typography>
              <Typography align="center" className={classes.subHeader}>
                <strong>Sign up with Facebook or Google</strong>
              </Typography>
              <Typography align="center">One-click, secure sign up. No new password needed</Typography>
              <SocialLoginButtons
                onAuth={onSignup}
                onExistingAccount={onExistingAccount}
                rerequestFacebook={facebookRerequest}
              />
              <Typography align="center" className={classes.orText}>
                ––– or –––
              </Typography>
              <Button variant="text" className={classes.emailSignup} onClick={() => setEmailSelected(true)}>
                Sign up with email address and password
              </Button>
              <Grid container className={classes.imgWrapper} item xs={10} md={4}>
                <div className={classes.center}>
                  <img className={classes.img} src={CastironArtisans}/>
                  <Typography align="right" style={{whiteSpace: 'nowrap'}}>
                    + more
                  </Typography>
                </div>
                <div className={classes.center}>
                  <Typography align="left">
                    Join other makers like you taking their business to the moon on Castiron!
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OnboardingSignUp;
