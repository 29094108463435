import * as yup from 'yup';
import { Address } from '../address';
import { FulfillmentType } from '../shop';

export interface RequestedFulfillment {
  type: FulfillmentType;
  date: number;
  address?: Address;
}
export const requestedFulfillmentSchema = yup.object({
  type: yup
    .string()
    .oneOf(['pickup', 'delivery', 'shipping', 'custom'])
    .required('Fulfillment Method is required'),
  date: yup
    .number()
    .moreThan(0, 'Fulfillment Date is required')
    .required('Fulfillment Date is required'),
  /* I went flat to simplify formik interactions
   * only requiring full address as that will be the trigger on the autocomplete
   * I really wanted to use a nested address component defined alongside the address interface,
   * but an optional address with required fields is just not doable with formik.  /sigh
   */
  fullAddress: yup.string().when('type', {
    is: value => value === 'delivery' || value === 'shipping',
    then: schema => schema.required('Address is required'),
  }),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  city: yup.string(),
  region: yup.string(),
  regionName: yup.string().nullable(),
  postalCode: yup.string(),
  country: yup.string(),
});
