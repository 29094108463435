import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const OvenIcon = (props: SvgIconProps) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_34_205)">
      <path d="M67.254 68.9625H4.7445C2.1285 68.9625 0 66.3465 0 63.135V5.8275C0 2.6145 2.1285 0 4.7445 0H67.254C69.8715 0 72 2.6145 72 5.8275V63.135C72 66.3465 69.8715 68.9625 67.254 68.9625ZM4.7445 3C3.921 3 3 4.209 3 5.8275V63.135C3 64.7535 3.921 65.9625 4.7445 65.9625H67.254C68.079 65.9625 69 64.7535 69 63.135V5.8275C69 4.209 68.079 3 67.254 3H4.7445Z" fill="#005FFF" />
      <path d="M54.5941 56.0415H17.4061C14.7901 56.0415 12.6616 53.6385 12.6616 50.6865V38.151C12.6616 35.199 14.7901 32.7975 17.4061 32.7975H54.5941C57.2086 32.7975 59.3371 35.199 59.3371 38.151V50.6865C59.3386 53.64 57.2101 56.0415 54.5941 56.0415ZM17.4061 35.796C16.4611 35.796 15.6616 36.8745 15.6616 38.1495V50.685C15.6616 51.963 16.4611 53.04 17.4061 53.04H54.5941C55.5391 53.04 56.3371 51.9615 56.3371 50.685V38.1495C56.3371 36.873 55.5391 35.796 54.5941 35.796H17.4061Z" fill="#005FFF" />
      <path d="M70.5 23.3205H1.5C0.672 23.3205 0 22.6485 0 21.8205C0 20.9925 0.672 20.3205 1.5 20.3205H70.5C71.3295 20.3205 72 20.9925 72 21.8205C72 22.6485 71.3295 23.3205 70.5 23.3205Z" fill="#005FFF" />
      <path d="M12.3285 16.128C9.95854 16.128 8.02954 13.9635 8.02954 11.3025C8.02954 8.64149 9.95854 6.47699 12.3285 6.47699C14.7 6.47699 16.6275 8.64149 16.6275 11.3025C16.6275 13.9635 14.6985 16.128 12.3285 16.128ZM12.3285 9.47699C11.625 9.47699 11.0295 10.314 11.0295 11.3025C11.0295 12.291 11.625 13.128 12.3285 13.128C13.0335 13.128 13.6275 12.2925 13.6275 11.3025C13.6275 10.3125 13.032 9.47699 12.3285 9.47699Z" fill="#005FFF" />
      <path d="M35.9999 16.128C33.6284 16.128 31.7009 13.9635 31.7009 11.3025C31.7009 8.64149 33.6284 6.47699 35.9999 6.47699C38.3699 6.47699 40.2989 8.64149 40.2989 11.3025C40.2989 13.9635 38.3699 16.128 35.9999 16.128ZM35.9999 9.47699C35.2949 9.47699 34.7009 10.314 34.7009 11.3025C34.7009 12.291 35.2949 13.128 35.9999 13.128C36.7049 13.128 37.2989 12.2925 37.2989 11.3025C37.2989 10.3125 36.7049 9.47699 35.9999 9.47699Z" fill="#005FFF" />
      <path d="M59.6686 16.128C57.2986 16.128 55.3711 13.9635 55.3711 11.3025C55.3711 8.64149 57.2986 6.47699 59.6686 6.47699C62.0401 6.47699 63.9691 8.64149 63.9691 11.3025C63.9691 13.9635 62.0401 16.128 59.6686 16.128ZM59.6686 9.47699C58.9651 9.47699 58.3711 10.314 58.3711 11.3025C58.3711 12.291 58.9651 13.128 59.6686 13.128C60.3736 13.128 60.9691 12.2925 60.9691 11.3025C60.9691 10.3125 60.3736 9.47699 59.6686 9.47699Z" fill="#005FFF" />
      <path d="M7.62305 72C6.79505 72 6.12305 71.3295 6.12305 70.5V67.4625C6.12305 66.633 6.79505 65.9625 7.62305 65.9625C8.45105 65.9625 9.12305 66.633 9.12305 67.4625V70.5C9.12305 71.3295 8.45105 72 7.62305 72Z" fill="#005FFF" />
      <path d="M64.377 72C63.5475 72 62.877 71.3295 62.877 70.5V67.4625C62.877 66.633 63.5475 65.9625 64.377 65.9625C65.2065 65.9625 65.877 66.633 65.877 67.4625V70.5C65.877 71.3295 65.2065 72 64.377 72Z" fill="#005FFF" />
    </g>
    <defs>
      <clipPath id="clip0_34_205">
        <rect width="72" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>

);