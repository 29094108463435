import React, { useState } from 'react';
import { ButtonBase, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Close, CloseOutlined } from '@material-ui/icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment, { Moment } from 'moment-timezone';
import { Button, DateInput, DateTimeRangeInput, Typography } from '@castiron/components';
import { determineOrderFulfillmentDateTime, fulfillmentTypeDisplayName, Transaction } from '@castiron/domain';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import { listTransactionsAction } from '../../store/reducers/transactions';
import ModalWrapper from '../RootModal/ModalWrapper';
import AdminForm from '../AdminForm';

export interface Props {
  show?: boolean;
  transaction: Transaction;
  onUpdate: () => void;
}

interface SingleDateFormData {
  date: number;
};
const singleDateFormSchema = yup.object({
  date: yup.number().min(1, 'Please set a date'),
});

interface DateRangeFormData {
  startTime: number;
  endTime: number;
};
const dateRangeFormSchema = yup.object({
  startTime: yup.number().min(1, 'Please add a start time.'),
  endTime: yup.number().min(1, 'Please add an end time').moreThan(yup.ref('startTime'), 'End time must be after start time'),
});

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    padding: '8px 24px 40px',
  },
  completedActionContainer: {
    padding: '8px 24px 32px',
  },
  completedContainer: {
    padding: '64px 140px 16px 24px',
  },
  contentContainer: {
    padding: '32px 24px',
    midWidth: '400px',
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '20px',
    height: '24px',
    width: '24px',
    cursor: 'pointer',
    zIndex: 10,
  },
  titleContainer: {
    padding: '16px 56px 16px 16px',
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
  },
}));

const FulfillmentDateTimeModal: React.FC<Props> = (props: Props) => {
  const { show = true, transaction, onUpdate } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const fulfillment = transaction?.order?.fulfillmentOption;
  if (!fulfillment) { return null; }

  const timeZone = shop?.config?.timeZone || defaultTimeZone;
  const fulfillmentDates = determineOrderFulfillmentDateTime(transaction.order);

  const [updated, setUpdated] = useState(false);
  const [dateBeforeModeChange, setDateBeforeModeChange] = useState(0);
  const [showTimes, setShowTimes] = useState(fulfillmentDates.length > 1);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleDateUpdate = async (startTime: number, endTime?: number) => {
    await transaction.updateDueDate(startTime, endTime);
    await dispatch(listTransactionsAction(shop.id));
    onUpdate();
    setUpdated(true);

    trackEvent('Order Datetime Updated', {
      order_id: transaction?.id,
      orderType: transaction?.order?.type,
      fulfillmentType: transaction?.order?.fulfillmentOption?.type,
      scheduleType: transaction?.order?.fulfillmentOption?.schedule?.type,
      updatedData: endTime ? 'Date and Time' : 'Date',
    });
  };

  const handleDateRangeSubmit = (values: DateRangeFormData) => {
    handleDateUpdate(values.startTime, values.endTime);
  };

  const handleSingleDateSubmit = async (values: SingleDateFormData) => {
    handleDateUpdate(values.date);
  };

  const defaultStartTime = (toDefault: Moment): number => moment(toDefault).hour(12).minute(0).second(0).millisecond(0).unix();
  const defaultEndTime = (toDefault: Moment): number => moment(toDefault).hour(12).minute(30).second(0).millisecond(0).unix();

  let initialDateRangeValues: DateRangeFormData;
  if (dateBeforeModeChange) {
    const date = moment.unix(dateBeforeModeChange)
    initialDateRangeValues = {
      startTime: defaultStartTime(date),
      endTime: defaultEndTime(date),
    };
  } else if (fulfillmentDates.length > 1) {
    initialDateRangeValues = {
      startTime: fulfillmentDates[0],
      endTime: fulfillmentDates[1],
    };
  } else if (fulfillmentDates.length > 0) {
    const date = moment.unix(fulfillmentDates[0]).tz(timeZone);
    initialDateRangeValues = {
      startTime: defaultStartTime(date),
      endTime: defaultEndTime(date),
    };
  } else {
    const today = moment().tz(timeZone);
    initialDateRangeValues = {
      startTime: defaultStartTime(today),
      endTime: defaultEndTime(today),
    };
  }

  const initialSingleDateValues: SingleDateFormData = {
    date: dateBeforeModeChange || (fulfillmentDates.length > 0 ? fulfillmentDates[0] : 0),
  };

  return (
    <ModalWrapper size="sm" show={show}>
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <Close />
      </IconButton>
      {
        !updated && showTimes &&
        <>
          <DialogTitle className={classes.titleContainer}>
            <Typography variant='h3'>
              {fulfillmentDates.length > 0 ? 'Edit ' : 'Add '}
              {fulfillmentTypeDisplayName(fulfillment.type, 'short')}
              {fulfillment.type === 'shipping' ? ' Date' : ' Date & Time'}
            </Typography>
          </DialogTitle>
          <Formik
            initialValues={initialDateRangeValues}
            validationSchema={dateRangeFormSchema}
            onSubmit={handleDateRangeSubmit}
          >
            {({ errors, values }) => (
              <AdminForm>
                <DialogContent className={classes.contentContainer}>
                  <Grid container>
                    <Grid item xs={11}>
                      <DateTimeRangeInput
                        variant='two-row'
                        label={`${fulfillmentTypeDisplayName(fulfillment.type, 'short')} Date`}
                        startTimeName='startTime'
                        startTimeError={errors.startTime}
                        endTimeName='endTime'
                        endTimeError={errors.endTime}
                        timeZone={timeZone}
                        disablePast={false}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid container alignItems='flex-end' style={{ height: '100%' }}>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              setShowTimes(false)
                              setDateBeforeModeChange(values.startTime)
                            }}
                            style={{ padding: '16px' }}
                          >
                            <CloseOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                  <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                  <Button variant='contained' type='submit'>Save</Button>
                </DialogActions>
              </AdminForm>
            )}
          </Formik>
        </>
      }
      {
        !updated && !showTimes &&
        <>
          <DialogTitle className={classes.titleContainer}>
            <Typography variant='h3'>
              {fulfillmentDates.length > 0 ? 'Edit ' : 'Add '}
              {fulfillmentTypeDisplayName(fulfillment.type, 'short')}
              {fulfillment.type === 'shipping' ? ' Date' : ' Date & Time'}
            </Typography>
          </DialogTitle>
          <Formik
            initialValues={initialSingleDateValues}
            validationSchema={singleDateFormSchema}
            onSubmit={handleSingleDateSubmit}
          >
            {({ errors, setFieldValue, values }) => (
              <AdminForm>
                <DialogContent className={classes.contentContainer}>
                  <Grid container direction='column' spacing={2}>
                    <Grid item>
                      <DateInput
                        label={`${fulfillmentTypeDisplayName(fulfillment.type, 'short')} Date`}
                        selectedDate={values.date ? moment.unix(values.date).tz(timeZone) : null}
                        disablePast={false}
                        onChange={(date: Moment) => {
                          setFieldValue('date', moment(date).tz(timeZone, true).hour(0).minute(0).second(0).millisecond(0).unix());
                        }}
                        error={errors.date}
                      />
                    </Grid>
                    {
                      fulfillment.type !== 'shipping' &&
                      <Grid item>
                          <Button
                            variant='text'
                            onClick={() => {
                              setShowTimes(true)
                              setDateBeforeModeChange(values.date)
                            }}
                            style={{ padding: 0 }}
                          >
                            + Add Pickup Time
                          </Button>
                      </Grid>
                    }
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                  <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                  <Button variant='contained' type='submit'>Save</Button>
                </DialogActions>
              </AdminForm>
            )}
          </Formik>
        </>
      }
      {
        updated &&
        <>
          <DialogContent className={classes.completedContainer}>
            <Typography variant='body1'>
              {fulfillmentTypeDisplayName(fulfillment.type, 'short')} info updated!
            </Typography>
          </DialogContent>
          <DialogActions className={classes.completedActionContainer}>
            <Button variant='contained' onClick={handleClose}>OK</Button>
          </DialogActions>
        </>
      }
    </ModalWrapper >
  );
};

export default FulfillmentDateTimeModal;
