import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Card, Typography } from '@castiron/components';
import { Transaction } from '@castiron/domain';

type Props = {
  order?: Transaction;
};

const useStyles = makeStyles((theme: Theme) => ({
}));

const GiftDetailsBox: React.FC<Props> = (props: Props) => {
  const { order } = props;
  const classes = useStyles();

  const giftMessage = order?.shippingInfo?.message;

  if (order?.isGift && giftMessage) {
    return (
      <Card title="&#127873; Order gift message">
        <Typography variant="body1">{giftMessage}</Typography>
      </Card>
    );
  }
  return null;
};

export default GiftDetailsBox;
