import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import PlanFlowConfiguration from "../PlanFlowConfiguration";
import ModalContent from "./ModalContent";
import { SelectedSubscription } from "../PlanFlow";


export type Props = {
  open: boolean;
  fullscreen: boolean;
  onSuccess?: () => void;
  step?: number;
  selectedPlan?: SelectedSubscription;
};

const useStyles = makeStyles((theme: Theme) => ({
  backArrow: {
    height: '20px',
    width: '20px',
    color: theme.branding.gray[800],
  },
  backArrowContainer: {
    height: '40px',
    width: '40px',
    padding: '9px 11px 10px 9px',
    border: `1px solid ${ theme.branding.gray[300] }`,
    borderRadius: '12px',
    marginRight: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backArrowHeader: {
    padding: '16px !important'
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  header: {
    borderBottom: `1px solid ${ theme.branding.gray[400] }`,
    padding: '20px 16px',
    maxHeight: 71,
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  pcWrapper: {},
}));


const SelectPlanModal: React.FC<Props> = (props: Props) => {
  const { open, onSuccess, fullscreen, step, selectedPlan } = props;

  return <PlanFlowConfiguration startingStep={ step }>
    <ModalContent open={ open } fullscreen={ fullscreen } onSuccess={ onSuccess } selectedPlan={ selectedPlan }/>
  </PlanFlowConfiguration>;
}

export default SelectPlanModal;
