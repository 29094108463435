import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CakeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M23.25 9.55076C23.2495 9.51391 23.2451 9.47706 23.2383 9.44115C23.2368 9.43123 23.233 9.42178 23.2305 9.41233C23.2242 9.38729 23.2164 9.36178 23.2067 9.33863C23.2018 9.32493 23.1959 9.31359 23.1901 9.30178C23.1799 9.27958 23.1687 9.26021 23.156 9.24084C23.1487 9.22902 23.1419 9.21863 23.1341 9.20776C23.119 9.18745 23.1024 9.16808 23.0849 9.14966C23.0776 9.14115 23.0708 9.13312 23.0625 9.12556C23.0362 9.10052 23.0089 9.07737 22.9777 9.058L22.9768 9.05706C22.9768 9.05706 22.9763 9.05611 22.9753 9.05611L9.74766 0.353718C9.57281 0.238442 9.34877 0.218128 9.15395 0.298443C3.81302 2.52318 0.75 5.89783 0.75 9.55737V17.1476C0.75 17.4807 1.02859 17.75 1.37196 17.75H22.6285C22.9719 17.75 23.2495 17.4807 23.2495 17.1476V9.55737C23.25 9.55454 23.25 9.55359 23.25 9.55076ZM22.0071 12.3226H1.99391V10.1597H22.0071V12.3226ZM1.99391 16.5443V14.4939H22.0071V16.5443H1.99391Z"
      fill="#004CCC"
    />
  </SvgIcon>
);
