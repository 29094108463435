import React from "react";
import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {

}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%'
  },
  error: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '50px'
  }
}));

const Error: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();

  return <Grid container className={classes.container} justify="center" alignItems="center">
    <Grid item xs={12} className={classes.error}>
      Oops! Looks like something unexpected has happened!
    </Grid>
    <Grid item xs={12} className={classes.error}>
      <img src="/assets/img/cake-mistake.gif" alt="Oops!"/>
    </Grid>
    <Grid item xs={12} className={classes.error}>
      Try refreshing your browser. If the issue persists, please contact <a href="mailto:support@castiron.me">Castiron Support</a>
    </Grid>
  </Grid>;
}

export default Error;
