import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { Typography } from '@castiron/components';
import PlanFlowConfiguration from '../SelectPlan/PlanFlowConfiguration';
import PlansDisplay from '../SelectPlan/PlanComponents/PlansDisplay';
import { SelectedSubscription } from '../SelectPlan/PlanFlow';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import Spinner from '../Spinner';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 16,
  },
  titleContainer: {
    textAlign: 'center',
    margin: '40px auto 0',
    [theme.breakpoints.up('md')]: {
      maxWidth: '62%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '86%',
      margin: '24px auto 0',
    },
  },
  title: {
    color: theme.branding.gray[800],
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '56px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '36px',
    },
  },
}));

const Unpaid: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { account, fromOnboarding } = useAppSelector(state => ({
    account: state.shops.account,
    fromOnboarding: state.shops.fromOnboarding
  }));

  const nextStep = (curStep: number, plan?: SelectedSubscription) => {
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          step: 1,
          selectedPlan: plan,
        },
      }),
    );
  };

  return (
    <div>
      <Spinner show={fromOnboarding} />
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
        justify="center"
        className={classes.titleContainer}
      >
        <Typography variant="h2" className={classes.title}>
          Your Castiron {account?.subscription?.plan?.name} Plan Has Expired.
        </Typography>
        <Typography variant="body1">Reactivate your plan to continue using your Castiron account.</Typography>
      </Grid>
      <Grid container className={classes.container}>
        <PlanFlowConfiguration>
          <PlansDisplay nextStep={nextStep} />
        </PlanFlowConfiguration>
      </Grid>
    </div>
  );
};

export default Unpaid;
