import {
  FormControl,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { calculateTotals, Order, TransactionTotals } from '@castiron/domain';
import { useFormikContext } from 'formik';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Dinero from 'dinero.js';
import Tooltip from '../../Tooltip';

type Props = {
  order: Order;
  isPaid: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: '24px 0',
    width: '100%',
  },
  formLabel: {
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: 0,
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
  },
  itemBoxInner: {
    padding: '18px 16px',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: 16,
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  tooltip: {
    marginBottom: '-1px',
  },
}));

const QuotePayment: React.FC<Props> = (props: Props) => {
  const { order, isPaid } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const [totals, setTotals] = useState<TransactionTotals>();
  const [optionChecked, setOptionChecked] = useState<string>('24hours');

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    if (order?.paymentDueDate) {
      setOptionChecked('date');
    } else if (order?.paymentSentDuePeriod) {
      setOptionChecked(values.order.paymentSentDuePeriod);
    }
  }, [order]);

  useEffect(() => {
    if (shop) {
      const totals = calculateTotals({
        order: values.order,
        paymentSettings: shop?.paymentSettings,
      });
      setTotals(totals);
    }
  }, [shop, values.order]);

  const onPaymentChange = (event) => {
    const selection = event.target.value;
    switch (selection) {
      case "none":
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', 'none');
        setFieldValue('order.paymentDueDate', null);
        break;
      case "24hours":
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '24hours');
        setFieldValue('order.paymentDueDate', null);
        break;
      case '7days':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', '7days');
        setFieldValue('order.paymentDueDate', null);
        break;
      case 'date':
        setOptionChecked(selection);
        setFieldValue('order.paymentSentDuePeriod', undefined);
    }
  }

  const onDateChange = (value) => {
    setOptionChecked('date');
    setFieldValue('order.paymentDueDate', value ? value.set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }).unix() : null);
    setFieldValue('order.paymentSentDuePeriod', undefined);
  }

  return (
    <Grid className={classes.itemBox}>
      <Grid container className={classes.itemHeader} alignItems="center">
        <Typography className={classes.title}>Payment</Typography>
      </Grid>
      <Grid className={classes.itemBoxInner} container justify="space-between" direction="column">
        <Typography>Balance due</Typography>
        <Typography>{Dinero({ amount: totals?.total || 0 }).toFormat('$0.00')}</Typography>
        <FormControl className={classes.formControl} variant="outlined">
          <Grid container item xs={12} alignItems="flex-end">
            <Typography>Payment due date</Typography>
            <Tooltip tooltipClass={classes.tooltip} title="Orders do not automatically become inactive after this date, but we will send automated reminders to you and your customer to secure their order before the due date has passed." />
          </Grid>
          <RadioGroup
            aria-label="payment due date"
            name="order.paymentDueDate"
            value={optionChecked}
            onChange={onPaymentChange}
          >
            <FormControlLabel value="none" control={<Radio color="primary" />} label="None" disabled={isPaid} />
            <FormControlLabel
              value="24hours"
              control={<Radio color="primary" />}
              label="Within 24 hours of sending quote"
            />
            <FormControlLabel value="7days" control={<Radio color="primary" />} label="Within 7 days of sending quote" disabled={isPaid} />
            <FormControlLabel
              label={undefined}
              value="date"
              disabled={isPaid}
              control={
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Radio color="primary" value="date" checked={optionChecked === 'date'} disabled={isPaid} />
                  <KeyboardDatePicker
                    disablePast={true}
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    inputVariant="outlined"
                    value={values.order.paymentDueDate ? moment.unix(values.order.paymentDueDate) : null}
                    onChange={onDateChange} // ternary prevents error when deleting last character
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isPaid}
                  />
                </MuiPickersUtilsProvider>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default QuotePayment;
