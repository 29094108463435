import React from "react";
import _ from 'lodash';
import {ShopSummary} from "@castiron/domain";
import {Avatar, Grid, makeStyles, Theme} from "@material-ui/core";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PinDropIcon from '@material-ui/icons/PinDrop';
import {Chip, ResultCard, SvgIcon, Text} from "@castiron/components";
import {useAppSelector} from "../../../hooks";

const SHOP_URL_BASE = process.env.REACT_APP_SHOP_URL;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '280px'
  },
  name: {
    marginBottom: '12px',
  },
  profile:  {
    marginBottom: '15px',
    alignItems: 'flex-end',
  },
  avatar: {
    height: '24px',
    width: '24px',
    marginRight: '8px'
  },
  productTypes: {
    marginBottom: '16px'
  },
  chip: {
    marginLeft: '2px',
    marginRight: '2px'
  },
  fulfillment: {
    alignItems: 'center',
    marginBottom: '16px'
  },
  icon: {
    height: '14px',
    width: '14px',
    color: theme.branding.gray[600],
    marginRight: '4px'
  },
  iconText: {
    color: theme.branding.gray[800],
    display: 'inline'
  },
  location: {
    alignItems: 'center',
  }
}));

interface Props {
  shop: ShopSummary;
}

const ArtisanCard: React.FC<Props> = (props:Props) => {
  const { shop } = props;
  const classes = useStyles();
  const { taxonomy } = useAppSelector(state => ({
    taxonomy: state.taxonomy.taxonomy
  }));

  const onClick = () => {
    window.open(`${SHOP_URL_BASE}${shop.websiteUrl}`, '_blank');
  };

  const ownerName = `${shop.owner?.firstName} ${shop.owner?.lastName}`;
  const catColor = taxonomy?.categories.find(c => c.name === shop.artisanCategory?.name)?.color;

  return (
    <ResultCard onClick={onClick} className={classes.card}>
      <Grid item className={classes.name}>
        <Text variant="h5" bold>{shop.businessName}</Text>
      </Grid>
      {shop.owner && <Grid item container className={classes.profile} direction="row">
        <Avatar src={shop.profileImageObj?.smallVersion?.downloadUrl} alt={ownerName} variant="circle" className={classes.avatar}/>
        <Text variant="subtitle2">{ownerName}</Text>
      </Grid>}
      <Grid item container className={classes.productTypes}>
        <Grid item>
          <strong>Products</strong>
        </Grid>
        <Grid item container direction="row">
          {shop.artisanCategory?.subcategories?.map(c => <Chip className={classes.chip}  key={c} style={{background: catColor}}>{c}</Chip>)}
        </Grid>
      </Grid>
      <Grid item container className={classes.fulfillment}>
        <SvgIcon className={classes.icon}>
          <ShoppingBasketIcon/>
        </SvgIcon>
        <Text variant="body1" className={classes.iconText}>{shop.fulfillmentTypes.map(t => _.upperFirst(t)).join(', ')}</Text>
      </Grid>
      {shop.location && (
        <Grid item container className={classes.location}>
          <SvgIcon className={classes.icon}>
            <PinDropIcon/>
          </SvgIcon>
          <Text variant="body1" className={classes.iconText}>{shop.location.city}, {shop.location.region}</Text>
        </Grid>
      )}
    </ResultCard>
  );
};

export default ArtisanCard;
