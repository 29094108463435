import React, { useState } from 'react';
import { DialogContent, DialogTitle, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Typography } from '@castiron/components';
import { useAppDispatch } from '../../../hooks';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { closeModal } from '../../../store/reducers/modalConductor';
import SubscriptionPayment from "../../SelectPlan/PlanComponents/SubscriptionPayment";
import PaymentSuccess from "../../SelectPlan/PlanComponents/PaymentSuccess";
import { SelectedSubscription } from "../../SelectPlan/PlanFlow";
import PlanFlowConfiguration from "../../SelectPlan/PlanFlowConfiguration";

export type Props = {
  open: boolean;
  forcePlanDetails?: SelectedSubscription;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  header: {
    borderBottom: `1px solid ${ theme.branding.gray[400] }`,
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
}));

const ChangePaymentModal: React.FC<Props> = (props: Props) => {
  const { open, forcePlanDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [step, setStep] = useState(0);
  const [planDetails, setPlanDetails] = useState<SelectedSubscription>(
    forcePlanDetails,
  );

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const nextStep = (curStep: number, plan) => {
    plan !== planDetails && setPlanDetails(plan);
    setStep(curStep + 1);
  };

  return (
    <ModalWrapper fullScreen={ isMobile } size="sm" show={ open }>
      <DialogTitle className={ classes.header }>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item>
            <Typography variant="h3">{ step < 1 ? 'Update Payment Info' : 'Success!' }</Typography>
          </Grid>
        </Grid>
        <IconButton className={ classes.closeIcon } onClick={ handleClose }>
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent style={ { padding: 0 } }>
        <PlanFlowConfiguration>
          { step === 0 && (
            <SubscriptionPayment
              changePaymentMethod
              nextStep={ nextStep }
              selectedSub={ planDetails }
            />
          ) }
          { step === 1 && <PaymentSuccess message="You’ve successfully updated your payment info."/> }
        </PlanFlowConfiguration>
      </DialogContent>
    </ModalWrapper>
  );
};

export default ChangePaymentModal;
