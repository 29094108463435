import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CupcakeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M3.19149 13.875H16.8085C21.0638 13.875 21.0638 6.375 16.8085 6.375H3.19149C-1.06383 6.375 -1.06383 13.875 3.19149 13.875ZM3.83629 22.5229C3.95833 22.9531 4.35115 23.25 4.79833 23.25H15.2017C15.6488 23.25 16.0417 22.9531 16.1637 22.5229L17.724 17.0229C17.9051 16.3845 17.4256 15.75 16.762 15.75H3.23804C2.57444 15.75 2.0949 16.3845 2.27601 17.0229L3.83629 22.5229ZM5.74468 8.25H13.8298C17.6596 8.25 17.6596 2.625 13.8298 2.625C10 2.625 7.44681 3.5625 7.44681 0.75C1.91489 0.75 1.91489 8.25 5.74468 8.25Z"
      fill="#004CCC"
    />
  </SvgIcon>
);
