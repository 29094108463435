import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import { Button, RocketOutlinedIcon, Typography, useFeatures } from "@castiron/components";
import { useTracking } from "@castiron/utils";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { openModal } from "../../store/reducers/modalConductor";
import { LayoutPageProps } from "../Layout";

interface Props extends LayoutPageProps {
};

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    height: '14px',
    width: '14px',
    marginBottom: '2px',
  },
  blueText: {
    color: theme.branding.blue.primary,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  descriptionText: {
    color: theme.branding.gray[600],
  },
  detailsSection: {
    [theme.breakpoints.up('md')]: {
      /* not sure why this breaks column flex at small resolutions :shrug: */
      height: '100%',
    },
  },
  footerContainer: {
    padding: '32px',
    marginTop: '96px',
    textAlign: 'center',
  },
  grayBox: {
    backgroundColor: theme.branding.gray[300],
    borderRadius: '16px',
    padding: '24px 24px 0',
    overflow: 'hidden',
    height: '324px',
  },
  heroContainer: {
    padding: '32px 0 0 32px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '32px',
    },
  },
  highlightThreeImageContainer: {
    position: 'relative',
    /* width of the two stacked images */
    width: '225px',
  },
  highlightThreeImageOne: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  highlightThreeImageTwo: {
    position: 'absolute',
    top: '96px',
    left: '32px',
  },
  hoverCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    maxWidth: '75vw',
  },
  innerContainer: {
    marginTop: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    }
  },
  /* we have nothing close to these in the current theme :( */
  newH3: {
    color: theme.branding.gray[800],
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '44px',
  },
  newH4: {
    color: theme.branding.gray[800],
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
  },
  newH5: {
    color: theme.branding.gray[800],
    fontWeight: 700,
    fontSize: '21px',
    lineHeight: '32px',
  },
  rocketIcon: {
    color: theme.branding.yellow.primary,
    height: '20px',
    width: '20px',
    marginRight: '10px'
  },
  yellowBox: {
    backgroundColor: theme.branding.yellow.light,
    borderRadius: '16px',
  },
}));

const CalendarPreview: React.FC<Props> = (props: Props) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const features = useFeatures();
  const { trackEvent } = useTracking();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, account, subscription } = useAppSelector(state => ({
    shop: state.shops.shop,
    account: state.shops.account,
    subscription: state.shops.account.subscription,
  }));

  const [completedUpgrade, setCompletedUpgrade] = useState(false);

  const openInfoPage = () => {
    /* not sure if this should be parameterized, but as far as I can tell it is fine as static for now */
    window.open('https://www.castiron.me/blog/custom-order-calendar', '_blank');
  };

  const openUpgradeModal = async () => {
    trackEvent('Gated Feature Upgrade Clicked', { feature: 'calendar' });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          onSuccess: () => setCompletedUpgrade(true),
        },
      }),
    );
  };

  useEffect(() => {
    setPageTitle('Calendar');
    setFooterCTAs([]);
    return () => {
      setPageTitle('');
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    setHeaderCTAs(isMobile ? [] : [
      <Button variant="contained" onClick={openUpgradeModal}>
        <RocketOutlinedIcon className={classes.rocketIcon} /> Upgrade
      </Button>
    ]);

    return () => {
      setHeaderCTAs([]);
    };
  }, [isMobile]);

  useEffect(() => {
    if (completedUpgrade && features?.includes('admin.calendar')) {
      history.push('/calendar');
    }
  }, [completedUpgrade, features]);

  return (
    <>
      <Helmet>
        <title>{`Calendar | ${shop ? shop.businessName : ''}`}</title>
      </Helmet>
      <Box className={classes.container}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Box className={clsx([classes.yellowBox, classes.heroContainer])}>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={6}>
                      <Grid container direction="column" spacing={4} alignItems={isMobile ? 'center' : 'flex-start'}>
                        <Grid item>
                          <Grid container direction="column" spacing={2} style={{ textAlign: isMobile ? 'center' : 'left' }}>
                            <Grid item>
                              <Typography className={classes.newH3}>Plan, manage, and streamline your orders and quotes from one place.</Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" className={classes.descriptionText}>
                                It's time to ditch the paper calendar. Whether you're planning your day, week, month, or year, we've got you covered.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button variant='contained' onClick={openUpgradeModal}>
                            <RocketOutlinedIcon className={classes.rocketIcon} /> Upgrade to Pro
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {
                      !isMobile &&
                      <Grid item md={6}>
                        <Box style={{ overflow: 'hidden', marginBottom: '-16px' }}>
                          <img src="/assets/img/calendar/desktop-preview.png" />
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.innerContainer}>
                  <Grid container direction='column' spacing={4}>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography className={classes.newH4} style={{ textAlign: 'center' }}>
                            A few reasons we think you'll 😍 your Castiron calendar:
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Box style={{ paddingTop: '44px', paddingBottom: '44px' }}>
                            <Grid container style={{ width: '100%' }} justify='center'>
                              <Grid item>
                                <img src="/assets/img/calendar/availability-preview.png" className={classes.image} />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container direction="column" justify="center" spacing={1} className={classes.detailsSection}>
                            <Grid item>
                              <Typography variant="caption" className={classes.blueText}>01</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.newH5}>
                                Update your availability in a few clicks.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body1' className={classes.descriptionText}>
                                Whether planning a vacation or just needing a day off, easily manage and update your availability in seconds.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='button' className={clsx([classes.blueText, classes.hoverCursor])} onClick={openUpgradeModal}>
                                Upgrade&nbsp;<ArrowForwardIosOutlined className={classes.arrowIcon} />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={4} direction={isMobile ? 'row' : 'row-reverse'}>
                        <Grid item xs={12} md={6}>
                          <Box style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                            <Grid container style={{ width: '100%' }} justify='center'>
                              <Grid item>
                                <img src="/assets/img/calendar/mobile-preview.png" className={classes.image} />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container direction="column" justify="center" spacing={1} className={classes.detailsSection}>
                            <Grid item>
                              <Typography variant="caption" className={classes.blueText}>02</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.newH5}>
                                Manage your calendar anywhere.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body1' className={classes.descriptionText}>
                                Whether you're prepping orders, making deliveries, or anywhere in between, easily access your calendar on your computer, tablet, or phone.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='button' className={clsx([classes.blueText, classes.hoverCursor])} onClick={openUpgradeModal}>
                                Upgrade&nbsp;<ArrowForwardIosOutlined className={classes.arrowIcon} />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Box className={classes.grayBox}>
                            <Grid container style={{ width: '100%' }} justify='center'>
                              <Grid item>
                                <Box className={classes.highlightThreeImageContainer}>
                                  <img src="/assets/img/calendar/quote-mobile-preview.png" className={classes.highlightThreeImageTwo} />
                                  <img src="/assets/img/calendar/quote-mobile-preview.png" className={classes.highlightThreeImageOne} />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container direction="column" justify="center" spacing={1} className={classes.detailsSection}>
                            <Grid item>
                              <Typography variant="caption" className={classes.blueText}>03</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.newH5}>
                                Search, sort, and plan your quotes & orders.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='body1' className={classes.descriptionText}>
                                Effortlessly approve quotes, view upcoming orders, monitor availability, and review your schedule on a daily, weekly, or monthly basis.
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='button' className={clsx([classes.blueText, classes.hoverCursor])} onClick={openUpgradeModal}>
                                Upgrade&nbsp;<ArrowForwardIosOutlined className={classes.arrowIcon} />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box className={clsx([classes.yellowBox, classes.footerContainer])}>
              <Grid container direction="column" spacing={3} alignItems='center'>
                <Grid item>
                  <Typography className={classes.newH4}>Ready to upgrade your plan?</Typography>
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={openUpgradeModal}>
                    <RocketOutlinedIcon className={classes.rocketIcon} /> Upgrade to Pro
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="button" className={clsx([classes.blueText, classes.hoverCursor])} onClick={openInfoPage}>
                    See Additional Benefits
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CalendarPreview;
