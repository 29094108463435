import React, {ReactNode} from 'react';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import Spinner from '../../Spinner';
import {Button, Forms, Input} from '@castiron/components';
import {Box, Grid, Link, Theme, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SocialLoginButtons, {ExistingAccountError} from '../../Auth/SocialLoginButtons/SocialLoginButtons';
import {OnboardingInfo} from './OnboardingV2';
import firebase from 'firebase/compat/app';
import {useHistory} from "react-router";
import domainAuth from "../../Auth/domainAuth";

const {SubmissionError} = Forms;

type Props = {
  onboardingInfo: OnboardingInfo;
  onSignup: (user: firebase.User) => Promise<void>;
  onExistingAccount: (err: ExistingAccountError) => Promise<void>;
  rerequestFacebook?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  emailSignup: {
    fontSize: '.875em',
    marginTop: 16,
  },
  inputContainer: {
    marginTop: -30,
  },
  helpText: {
    fontSize: '.875em',
  },
  signupButton: {
    color: '#fff',
    backgroundColor: '#000',
  },
  termsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 30,
  },
  termsLink: {
    color: theme.palette.text.secondary,
  },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8),
  passwordConfirm: yup
    .string()
    .required('Password Confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const OnboardingSignUpForm: React.FC<Props> = (props: Props) => {
  const {onboardingInfo, onSignup, onExistingAccount, rerequestFacebook} = props;
  const classes = useStyles();
  const history = useHistory<{existingAccountEmail: string}>();

  const onSubmit = async (values: any, {setSubmitting, setFieldError}: any): Promise<any> => {
    try {
      const result = await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
      await domainAuth();
      const user = result.user;
      await user.updateProfile({
        displayName: onboardingInfo.name,
      });
      await user.sendEmailVerification();

      await onSignup(user);

      setSubmitting(false);
    } catch (err) {
      console.error('Error Setting up Email User', err);
      if (err.code === 'auth/email-already-in-use') {
        history.push('/signup/account/existing', {
          existingAccountEmail: values.email
        });
      }
      setFieldError('general', err.message);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{email: '', password: '', passwordConfirm: ''}}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({isSubmitting, errors, touched, handleSubmit}): ReactNode => (
        <>
          <Spinner show={isSubmitting} size={'fullscreen'} />
          <>
            {errors?.general && <SubmissionError msg={errors.general} />}
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Typography variant="h2">Sign up with email</Typography>
                <Grid className={classes.inputContainer} item xs={12}>
                  <Input type="email" error={touched.email && errors.email} name="email" label="Email address" />
                </Grid>
                <Grid className={classes.inputContainer} item xs={6}>
                  <Input label="Password" error={touched.password && errors.password} type="password" name="password" />
                </Grid>
                <Grid className={classes.inputContainer} item xs={6}>
                  <Input
                    type="password"
                    error={touched.passwordConfirm && errors.passwordConfirm}
                    label="Confirm password"
                    name="passwordConfirm"
                  />
                </Grid>
                <Typography className={classes.helpText}>Must be at least 8 characters</Typography>
                <Grid container direction="column" item justify="center">
                  <Box my={3} mx="auto">
                    <Button className={classes.signupButton} variant="contained" type="submit">
                      Create Shop
                    </Button>
                  </Box>
                  <Typography align="center" className={classes.helpText}>
                    ––– or –––
                  </Typography>
                  <Typography align="center" className={classes.emailSignup}>
                    Sign up with
                  </Typography>
                  <SocialLoginButtons
                    onAuth={onSignup}
                    onExistingAccount={onExistingAccount}
                    rerequestFacebook={rerequestFacebook}
                  />
                  <Grid container item justify="center">
                    <div className={classes.termsContainer}>
                      <Typography align="center" variant="caption" component="p" color="textSecondary">
                        By signing up, you agree to our{' '}
                        <Link
                          className={classes.termsLink}
                          target="_blank"
                          href="https://www.castiron.me/terms-conditions"
                        >
                          Terms of Service
                        </Link>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </>
        </>
      )}
    </Formik>
  );
};

export default OnboardingSignUpForm;
