import React, { ReactNode, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { accountRepository } from '../../../domain';
import { useAppSelector } from '../../../hooks';
import { LayoutPageProps } from '../../Layout';
import OnboardingWrapper from './OnboardingWrapper';
import BusinessInfo from './BusinessInfo';
import BusinessTier from './BusinessTier';
import Categories from './Categories';
import SellingLocation from './SellingLocation';
import CastironGoal from './CastironGoal';
// import Subscribe from "./Subscribe";

const Onboarding: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  console.debug('Onboarding V4');
  const history = useHistory();

  const [step, setStep] = useState(1);
  const { setDisplayNav } = props;
  const [header, setHeader] = useState('');
  const [subHeader, setSubHeader] = useState('');
  const [childNode, setChildNode] = useState<ReactNode>('');

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  if (account?.status !== 'onboarding') {
    history.push('/');
  }

  useEffect(() => {
    setStep(account?.onboardingQuestions?.onboardingStep?.step || 1);
  }, []);

  const nextStep = async () => {
    await accountRepository.updateProps(account.id, {
      'onboardingQuestions.onboardingStep': {
        version: 4,
        step: step + 1,
      },
    });
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setDisplayNav(false);

    /* reenable nav when we are done with onboarding */
    return () => setDisplayNav(true);
  }, []);

  useEffect(() => {
    const pageRoute = `/signup/info/${step}`;
    switch (step) {
      case 1:
        setChildNode(
          <BusinessInfo
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
          />,
        );
        history.push(pageRoute);
        break;
      case 2:
        setChildNode(
          <BusinessTier
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
          />,
        );
        history.push(pageRoute);
        break;
      case 3:
        setChildNode(
          <Categories
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
          />,
        );
        history.push(pageRoute);
        break;
      case 4:
        setChildNode(
          <SellingLocation
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
          />,
        );
        history.push(pageRoute);
        break;
      case 5:
        setChildNode(
          <CastironGoal
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            setHeader={setHeader}
            setSubHeader={setSubHeader}
          />,
        );
        history.push(pageRoute);
        break;
        // --- Subscriptions are no longer in onboarding, this is legacy code just to save for a bit. ---
      // case 6:
      //   setChildNode(
      //     <Subscribe
      //       step={step}
      //       nextStep={nextStep}
      //       prevStep={prevStep}
      //       setHeader={setHeader}
      //       setSubHeader={setSubHeader}
      //     />,
      //   );
      //   history.push(pageRoute);
      //   break;
      default:
    }
  }, [step]);

  return (
    <OnboardingWrapper step={step} header={header} subHeader={subHeader}>
      {childNode}
    </OnboardingWrapper>
  );
};

export default Onboarding;
