import firebase from 'firebase/compat/app';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Typography,
  Button,
  EmailInput,
  TextInput,
  CheckboxInput,
  PasswordInput,
  Link,
  Logo,
  Banner,
} from '@castiron/components';
import { Grid, makeStyles, Theme, useTheme, useMediaQuery, Divider } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { useTracking } from '@castiron/utils';
import { trackHubSpotContactPage } from '../../../lib/trackHubSpotContactEvent';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { loginWithGoogle } from '@castiron/castiron-firebase';

import OnboardingDesktopImage from '../../../assets/img/Onboarding-Desktop-With-Typography-v4.png';
import { shopRepository } from '../../../domain';
import { useAppDispatch } from '../../../hooks';
import { createShopAction, getShopAction, setIsOnboarding } from '../../../store/reducers/shops';
import setDomainAuth from '../../Auth/domainAuth';
import domainAuth from '../../Auth/domainAuth';
import momentTimezone from 'moment-timezone';

export interface SignupInfo {
  email: string;
  fullName: string;
  password: string;
  termsOfService: boolean;
}

const signupSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter an email address.')
    .required('Please enter an email address.'),
  fullName: yup.string().required('Please enter your full name.'),
  password: yup
    .string()
    .required('Please enter a password.')
    .min(8, 'Password must be at least 8 characters'),
  termsOfService: yup
    .boolean()
    .oneOf([true], 'Please agree to our Terms of Service and Privacy Policy to continue.')
    .required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minHeight: 56,
    padding: '16px 24px',
  },
  caption: {
    marginTop: 4,
  },
  checkbox: {
    paddingLeft: 0,
  },
  container: {
    minHeight: '100vh',
  },
  containerLeft: {
    padding: '40px 104px 40px 80px',
    [theme.breakpoints.down('lg')]: {
      padding: 40,
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 168px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 16px',
    },
  },
  desktopImage: {
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  desktopImageContainer: {
    backgroundColor: theme.branding.gray[400],
    overflow: 'hidden',
    position: 'relative',
  },
  desktopImageLink: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: 12,
    cursor: 'pointer',
    height: 64,
    margin: 24,
    opacity: '72%',
    padding: '8px 24px',
    position: 'absolute',
    width: 193,
  },
  divider: {
    flex: 1,
  },
  dividerContainer: {
    margin: '16px 0px',
  },
  dividerText: {
    margin: '0px 16px',
  },
  googleIcon: {
    height: 20,
    width: 20,
  },
  inputContainer: {
    marginBottom: 16,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  loadingIcon: {
    color: theme.branding.gray[100],
  },
  logo: {
    width: 130,
    marginBottom: 40,
  },
  tosErrorBanner: {
    color: theme.branding.red.primary,
    margin: '16px 0',
  },
}));

const Signup: React.FC = () => {
  const classes = useStyles();
  const { trackEvent, trackUser } = useTracking();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const showDesktopImage = useMediaQuery(theme.breakpoints.up('lg'));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initialValues = {
    email: '',
    fullName: '',
    password: '',
    termsOfService: true,
  };

  useEffect(() => {
    trackEvent('Onboarding Session Started');
    dispatch(setIsOnboarding(true));
  }, []);

  const loginWithProvider = async (values, user) => {
    // check for shop
    const shopExists = await shopRepository.get(user.uid);

    if (shopExists) {
      dispatch(getShopAction(user.uid));
    } else {
      handleSubmit(values, user);
    }
  };

  const googleSignUp = async values => {
    const result = await loginWithGoogle(undefined);
    await setDomainAuth();
    await loginWithProvider(values, result);
  };

  const createShop = async (email, fullName, firstName, lastName, user?) => {
    trackEvent('Shop Login Created', {
      user: {
        id: user?.uid,
        email,
        name: fullName,
      },
    });

    const shopResponse = await dispatch(
      createShopAction({
        businessName: `${firstName}'s Shop`,
        email: email,
        firstName,
        lastName,
        onboardingQuestions: {
          onboardingStep: {
            version: 4,
            step: 1,
          },
        },
        tags: ['Day1Onboarding'],
        timeZone: momentTimezone.tz.guess() || 'America/Chicago',
      }),
    );
    return shopResponse.payload;
  };

  const handleSubmit = async (values, socialUser) => {
    const isSocialUser = !!socialUser?.uid;
    const email = isSocialUser ? socialUser?.email : values.email;
    const fullName = isSocialUser ? socialUser?.displayName : values.fullName;
    const password = values.password;

    const nameParts = fullName.split(' ');
    const firstName = nameParts.shift();
    const lastName = nameParts.length > 0 ? nameParts.join(' ') : '';
    const createTx = Sentry.startTransaction({
      op: 'submit',
      name: 'Create Shop Request',
      tags: {
        transactionType: 'business',
      },
    });
    Sentry.getCurrentHub().configureScope(scope => {
      scope.setSpan(createTx);
      scope.setUser({
        email,
      });
    });
    setIsSubmitting(true);
    try {
      let user;
      if (!isSocialUser) {
        const span = createTx.startChild({
          op: 'auth-create',
          description: 'Create Auth Account',
        });
        console.debug('Creating Auth Account');
        const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
        await domainAuth();
        user = result.user;
        await user.sendEmailVerification();
        await user.updateProfile({
          displayName: fullName,
        });
        span.finish();
      } else {
        user = socialUser;
      }

      const shop = await shopRepository.get(user.uid);
      if (!shop) {
        const span = createTx.startChild({
          op: 'shop-create',
          description: 'Create Shop',
        });

        trackUser(user.uid);

        const { shop } = await createShop(email, fullName, firstName, lastName, user);

        dispatch(getShopAction(shop?.id));

        trackHubSpotContactPage(
          {
            email: email,
            migration: false,
            firstName,
            lastName,
          },
          '/signup/create',
        );

        span.finish();
        createTx.finish();
        trackEvent('Agreed To Terms Of Service');
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        await dispatch(setIsOnboarding(false));
        history.push('/');
      }      
    } catch (err) {
      setIsSubmitting(false);
      console.error('Error Setting up Email User', err);
      if (err.code === 'auth/email-already-in-use') {
        history.push('/signup/account/existing', {
          existingAccountEmail: email,
        });
      }
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid container item direction="column" xs={showDesktopImage ? 6 : 12} className={classes.containerLeft}>
        <Logo className={classes.logo} />
        <Typography style={{ marginBottom: 8 }} variant="h1">
          Let’s Get Started
        </Typography>
        <Typography style={{ marginBottom: 24 }} variant="body1">
          {showDesktopImage
            ? 'Join Lexie and other owners like you launching, running, and growing their food businesses with Castiron. 💕'
            : 'Join other owners like you launching, running, and growing their food businesses with Castiron. 💕'}
        </Typography>
        <Formik onSubmit={handleSubmit} validationSchema={signupSchema} initialValues={initialValues}>
          {({ errors, touched, values }: FormikProps<SignupInfo>) => (
            <Form>
              <Button className={classes.button} fullWidth onClick={() => googleSignUp(values)} variant="outlined">
                <Grid container item>
                  <Grid item xs={1} style={{ justifySelf: 'flex-start' }}>
                    <img src="/assets/img/google-g-logo.svg" alt="Google logo" className={classes.googleIcon} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="button">Continue with Google</Typography>
                  </Grid>
                </Grid>
              </Button>
              <Grid container item className={classes.dividerContainer} alignItems="center">
                <Divider className={classes.divider} />
                <Typography className={classes.dividerText} variant="body2">
                  or
                </Typography>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item className={classes.inputContainer}>
                <EmailInput
                  error={touched.email && errors.email}
                  label="Email Address"
                  name="email"
                  placeholder="email@domain.com"
                  required
                />
              </Grid>
              <Grid item className={classes.inputContainer}>
                <TextInput
                  error={touched.fullName && errors.fullName}
                  label="Full Name"
                  name="fullName"
                  placeholder="Jackie Blue"
                  required
                />
              </Grid>
              <Grid item className={classes.inputContainer}>
                <PasswordInput
                  error={touched.password && errors.password}
                  label="Password"
                  name="password"
                  placeholder="••••••••••••••"
                  required
                />
              </Grid>
              <Grid item className={classes.inputContainer}>
                <CheckboxInput
                  className={classes.checkbox}
                  label={
                    <Typography variant="caption">
                      I have read and agree to Castiron's&nbsp;
                      <Link className={classes.link} target="_blank" href="https://www.castiron.me/terms-conditions">
                        Terms of Service
                      </Link>
                      &nbsp; and&nbsp;
                      <Link className={classes.link} target="_blank" href="https://www.castiron.me/privacy-policy">
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  }
                  name="termsOfService"
                  variant="label-right"
                />
                {errors.termsOfService && (
                  <Banner className={classes.tosErrorBanner} variant="error">
                    <Typography variant="body2">{errors.termsOfService}</Typography>
                  </Banner>
                )}
              </Grid>
              <Button
                className={classes.button}
                fullWidth
                loading={isSubmitting}
                loadingClassName={classes.loadingIcon}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? '' : 'Start Your Free 14-Day Trial'}
              </Button>
              <Grid container justify="center" className={classes.caption}>
                <Typography variant="caption">No Credit Card Required.</Typography>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      {showDesktopImage && (
        <Grid container item xs={6} className={classes.desktopImageContainer} justify="flex-end" alignItems="flex-end">
          <img className={classes.desktopImage} alt="Colony House Cookies" src={OnboardingDesktopImage} />
          <Link
            className={`${classes.desktopImageLink} ${classes.link}`}
            target="_blank"
            href="https://shop.castiron.me/colonyhousecookies"
          >
            <Typography variant="subtitle1">Lexie</Typography>
            <Typography style={{ color: theme.branding.blue.primary }} variant="body2">
              Colony House Cookies
            </Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default Signup;
