import React from 'react';
import { Button } from '@castiron/components';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from '@castiron/components/src/Button/Button';
import { useAppSelector } from '@castiron/client-admin/src/hooks';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type PackageAddedButtonsProps = Override<
  ButtonProps,
  {
    addedProductId: string;
  }
>;

// Buttons that show after community package has been added
const PackageAddedButtons: React.FC<PackageAddedButtonsProps> = (props: PackageAddedButtonsProps) => {
  const { addedProductId, fullWidth, ...restOfProps } = props;

  const history = useHistory();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  // links to edit product in admin
  const handleEditProductClick = () => {
    history.push(`/products/edit/${addedProductId}`);
  };

  return (
    <>
      <Button
        variant="outlined"
        href={`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}/product/${addedProductId}`}
        fullWidth={fullWidth}
        {...restOfProps}
      >
        View in shop
      </Button>
      <Button variant="outlined" onClick={handleEditProductClick} fullWidth={fullWidth} {...restOfProps}>
        Edit
      </Button>
    </>
  );
};

export default PackageAddedButtons;
