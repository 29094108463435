import firebase from 'firebase/compat/app';
import React, { useEffect, useState } from 'react';
import { Typography, Input, Button } from '@castiron/components';
import { Grid, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { useFormikContext } from 'formik';
import CastironArtisans from '../../../assets/img/castiron-artisans.png';
import { OnboardingInfo } from './Onboarding';
import { useTracking } from "@castiron/utils";
import { trackHubSpotContactPage } from "../../../lib/trackHubSpotContactEvent";
import { useHistory } from "react-router";

type Props = {
  progressStep: (values: OnboardingInfo) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: '8px 0px 8px 0px',
  },
  castironArtisans: {
    maxWidth: 250,
    width: '50%',
  },
  container: {
    maxWidth: '588px',
  },
  textContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  titleColor: {
    color: theme.branding.blue.primary,
  },
}));

const OnboardingEmailCaptureV2: React.FC<Props> = (props: Props) => {
  const { progressStep } = props;
  const classes = useStyles();
  const { trackEvent, trackUser } = useTracking();
  const { errors, touched, values, setFieldValue, validateField, setFieldTouched }: any = useFormikContext();
  const history = useHistory();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const onClick = () => {
    if (!errors.email) {
      firebase.auth().fetchSignInMethodsForEmail(values.email).then((methods) => {
        if (methods?.length > 0) {
          history.push('/signup/account/existing');
        } else {
          setFieldValue('email', values.email);
          progressStep(values);

          trackUser(undefined, {
            email: values.email
          });

          trackEvent('Lead Captured', {
            ...values
          });

          // Force HubSpot contact creation.
          trackHubSpotContactPage({ email: values.email }, '/signup');
        }
      });
    }
  }

  useEffect(() => {
    trackEvent('Onboarding Session Started');
  }, []);

  return (
    <Grid container item className={classes.container} direction="column">
      <Grid xs={12}>
        {!isMobile && (<Typography variant="h1" className={classes.textContainer}>Welcome to Castiron!</Typography>)
          || isMobile && (<Typography variant="h2" component="h1">Welcome to Castiron! You’re bites away from launching <span className={classes.titleColor}>your new shop.</span></Typography>)}
      </Grid>
      <Grid xs={12}>
        <Typography variant="body1" className={classes.textContainer}>
          {!isMobile ? "Launch your shop in minutes. Simply enter your email to begin. We’ll be with you, every step of the way." :
            "Simply enter your email to begin. We’ll be with you, every step of the way."}</Typography>
      </Grid>
      <Grid xs={12}>
        <Input
          type="email"
          error={!isFocused && touched.email && errors.email}
          fullWidth
          label="Email Address"
          name={'email'}
          placeholder="Enter your email"
          onChange={(e) => {
            setFieldValue('email', e.target.value);
            validateField('email');
            setFieldTouched('email');
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onClick();
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Button
          disabled={!touched.email || errors.email !== undefined}
          fullWidth
          variant="contained"
          className={classes.button}
          onClick={onClick}
        >
          Get Started
        </Button>
      </Grid>
      <Grid container item justify="center" xs={12}>
        <Typography variant='caption'>By signing up, you agree to our <a href='https://castiron.me/privacy-policy' className={classes.titleColor}>Privacy Policy.</a></Typography>
      </Grid>
      {!isMobile && (
        <Grid className={classes.textContainer}>
          <Typography variant="body3">Join other makers like you taking their business to the moon on Castiron!</Typography>
          <Grid container item alignItems="center">
            <img src={CastironArtisans} className={classes.castironArtisans} />
            <Typography variant="subtitle2">+ many more</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OnboardingEmailCaptureV2;
