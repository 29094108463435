import firebase from 'firebase/compat/app';
import loginWithProvider from './loginWithProvider';
// Add the Firebase products that you want to use
import 'firebase/compat/auth';

async function loginWithGoogle(onExistingAccount) {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  const user = await loginWithProvider(provider, onExistingAccount);
  return user;
}

export default loginWithGoogle;
