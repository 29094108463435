import _firestore from '@google-cloud/firestore';
import firebase from 'firebase/compat/app';
import { BaseDocument, BaseRepository, FieldFunctions } from '../base/repository';
import { BaseProduct } from '../product';
import { ShopSummary } from "../shop";

export interface Metrics {
  numShopsUsing: number;
  revenueGenerated: number;
}

export interface PackageResource {
  type: 'link' | 'image' | 'pdf';
  name: string;
  url: string;
  filename?: string;
}

export type IconTypes = 'tools' | 'gift' | 'image' | 'star'

export interface ResourceSection {
  name: string;
  icon: IconTypes;
  resources: PackageResource[];
}

export interface CommunityPackage extends BaseDocument<CommunityPackage> {
  title: string;
  description: string;
  included: string;
  resources: ResourceSection[];
  products: BaseProduct[];
  tags: string[];
  author: ShopSummary;
  metrics: Metrics;
  activeFrom?: number;
  activeTo?: number;
  artisansUsing: ShopSummary[];
  priority?: number;
  addedProductId?: string;
  allowDownloadAll: boolean;
}

export class CommunityPackageRepository extends BaseRepository<CommunityPackage> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'packages', fieldFunctions);
  }

  public async findActivePriorityOrdered(): Promise<CommunityPackage[]> {
    return this.find({
      where: [{ field: 'status', operator: '==', value: 'active' }],
      orderBy: [{ field: 'priority', direction: 'asc' }],
    });
  }
}