import React, {useState} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Button, Grid, useMediaQuery } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Typography } from "@castiron/components";

const breakpointSmall = 749;

interface Props {
  text?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  policiesWrapper: {
    borderRadius: '4px',
    border: `1px solid ${theme.branding.gray[400]}`,
  },
  policiesHeader: {
    backgroundColor: theme.branding.gray[200],
    fontWeight: 700,
    padding: '15px',
  },
  policiesDescription: {
    padding: '24px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    [theme.breakpoints.down(breakpointSmall)]: {
      minHeight: '300px',
      overflow: 'auto',
    },
    [theme.breakpoints.up(breakpointSmall)]: {
      minHeight: '86%',
      overflow: 'auto',
    },
  },
}));

const PoliciesPreview: React.FC<Props> = (props: Props) => {
  const { text } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpointSmall));
  const [mobilePoliciesOpen, setMobilePoliciesOpen] = useState(false);

  const mobilePolicies = text && <Grid container className={classes.policiesWrapper}>
    <Grid
      container
      justify="space-between"
      item
      xs={12}
      className={classes.policiesHeader}
      style={{cursor: 'pointer'}}
      onClick={() => setMobilePoliciesOpen(!mobilePoliciesOpen)}
    >
      <Typography variant='h5'>Policies</Typography>
      <div>{mobilePoliciesOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
    </Grid>
    {mobilePoliciesOpen && (
      <>
        <Grid item xs={12} className={classes.policiesDescription}>
          <Typography variant='body4'>{text}</Typography>
        </Grid>
        <Grid container justify="center">
          <Button
            onClick={() => setMobilePoliciesOpen(!mobilePoliciesOpen)}
            color="primary"
            style={{margin: '21px', textDecoration: 'underline'}}
          >
            Hide policies
          </Button>
        </Grid>
      </>
    )}
  </Grid>;

  const desktopPolicies = text && <Grid container className={classes.policiesWrapper}>
    <Grid item xs={12} className={classes.policiesHeader}>
      Policies
    </Grid>
    <Grid item xs={12} className={classes.policiesDescription}>
      {text?.split('\n').map((item, key) => {
        return ( <span key={key}>{item}<br /></span>);
      })}
    </Grid>
    <Grid style={{height: '100%'}}></Grid>
  </Grid>;

  return <>{text && (isMobile) ? mobilePolicies : desktopPolicies}</>;
}

export default PoliciesPreview;