import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import _, { initial } from "lodash";
import clsx from "clsx";
import {
  ButtonBase,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/ErrorOutline';
import { ChecklistValues, GalleryPhoto, galleryPhotoToEventModel, shopToEventModel } from "@castiron/domain";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { nanoid } from "nanoid";
import { upload } from "@castiron/castiron-firebase";
import {
  Button,
  ErrorBox,
  HackedImageUploader,
  SvgIcon,
  TrashIcon,
  Typography,
  SaveButton
} from "@castiron/components";
import Spinner from "../../../Spinner";
import { closeModal, openModal } from "../../../../store/reducers/modalConductor";
import { useTracking } from '@castiron/utils';
import { getShopAction, updateChecklistAction } from "@castiron/client-admin/src/store/reducers/shops";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicator from "@material-ui/icons/DragIndicator";
import { shopRepository } from "../../../../domain";
import { LayoutPageProps } from "../../../Layout";
import ViewShopButton from "../../../Layout/Header/ViewShopButton";
import EllipsisMenu from "../../../Layout/Header/EllipsisMenu";

const useStyles = makeStyles((theme: Theme) => ({
  galleryContainer: {
    boxSizing: 'content-box',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  imageContainer: {
    height: '160px',
  },
  imageContainerTiny: {
    height: '250px',
  },
  uploadContainer: {
    height: 144,
    width: '100%',
    borderRadius: 12,
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
  },
  uploadInlineContainer: {
    height: '160px',
    width: '160px',
  },
  uploadInlineContainerTiny: {
    height: '244px',
    width: '244px',
  },
  imageBox: {
    height: '152px',
    width: '152px',
    borderRadius: 12,
  },
  imageBoxTiny: {
    height: '244px',
    width: '244px',
    borderRadius: 12,
  },
  galleryImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '12px'
  },
  photoTipsContainer: {
    background: '#F5F9FF',
    borderRadius: 12,
    //weird offset happening with the header
    marginTop: '-2px',
    marginBottom: 8,
    padding: '24px',
    marginLeft: 0,
    marginRight: 0
  },
  labelContainerClass: {
    marginTop: 0,
  },
  error: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  icon: {
    color: theme.branding.castiron,
    width: 38,
    height: 38,
  },
  iconButton: {
    zIndex: 25,
    top: '-146px',
    backgroundColor: theme.palette.common.white,
    opacity: 0.8,
    right: '-6px',
    borderRadius: 4,
    width: 32,
    height: 32,
  },
  iconButtonTiny: {
    top: '-236px',
    right: '-6px',
  },
  infoIcon: {
    color: theme.branding.blue.primary
  },
  tabPanel: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 0px',
    },
  },
  tipsOne: {
    listStyle: 'inside',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  wrapper: {
    paddingLeft: '20px',
    paddingRight: '41px'
  },
  dragHandle: {
    color: theme.branding.gray[400],
  },
  dragDots: {
    '& svg > path': {
      fill: theme.branding.gray[800],
    },
  },
  draggableImageCard: {
    background: theme.branding.gray[100],
    height: 102,
    padding: '8px 16px',
    border: `1px solid ${theme.branding.gray[200]}`,
    borderRadius: 8,
    marginBottom: 8,
  },
  draggableImageBox: {
    height: 86,
    width: 86,
    borderRadius: 12,
    [theme.breakpoints.down('sm')]: {
      height: 86,
      width: 86,
    },
  },
}));

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item, index) => {
    (item as any).position = index;
  });

  return result;
}

const Gallery: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTinyMobile = useMediaQuery('(max-width:320px)')
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { me, shop } = useAppSelector(state => ({
    me: state.users.me,
    shop: state.shops.shop
  }));

  const [errorMessage, setErrorMessage] = useState<string>();
  const [galleryPhotos, setGalleryPhotos] = useState<GalleryPhoto[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploading, setUploading] = useState({ isUploading: false, id: '' });
  const [isRearranging, setIsRearranging] = useState(false);
  const [originalPositions, setOriginalPositions] = useState([]);
  const [newPositions, setNewPositions] = useState([]);

  const getGalleryPhotos = async () => {
    const photos = await shop.getGalleryPhotos();
    // arrange photos by position & set original positions
    const originalPositions = photos.map(p => ({ id: p.id, position: p.position }));
    setOriginalPositions(originalPositions);

    photos.sort((a, b) => a.position - b.position);
    setGalleryPhotos(photos);
    return photos;
  }

  useEffect(() => {
    getGalleryPhotos();
  }, [shop]);


  const resetPhotos = () => {
    const photos = [...galleryPhotos]

    for (let photo of photos) {
      const original = originalPositions.find(i => i.id === photo.id);
      photo.position = original.position;
    }

    photos.sort((a, b) => a.position - b.position);
    setGalleryPhotos(photos);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle('Photo Gallery');
    setBackLocation(true);

    isRearranging ? setFooterCTAs([
      <Button
        variant='outlined'
        disabled={isSubmitting}
        onClick={() => {
          resetPhotos();
          setIsRearranging(false);
        }}
      >
        Discard
      </Button>,
      <SaveButton handleSubmit={onSubmit} isSubmitting={isSubmitting} />
    ]) : setFooterCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setFooterCTAs([]);
    };
  }, [isSubmitting, isRearranging, newPositions]);


  useEffect(() => {
    const headerCTAs = isMobile ?
      [
        <EllipsisMenu
          disabled={isSubmitting || galleryPhotos.length === 0 || isRearranging}
          options={[{
            display: 'Rearrange Photos',
            action: () => setIsRearranging(true),
          }]}
        />,
        <ViewShopButton subdirectory='gallery' />,
      ] : [
        <ViewShopButton subdirectory='gallery' size={isXlScreen ? null : 'small'} />,
        <Button
          disabled={isSubmitting || galleryPhotos.length === 0 || isRearranging}
          onClick={() => setIsRearranging(true)}
          variant="outlined"
          size={isXlScreen ? 'large' : 'small'}
          style={isXlScreen ? {} : { height: 48 }}
        >
          Rearrange Photos
        </Button>,
      ];
    setHeaderCTAs(headerCTAs);
  }, [galleryPhotos, isMobile, isXlScreen, isRearranging, isSubmitting]);

  const handleUploadSuccess = async (downloadUrl, metadata, options, context) => {
    setUploading({ isUploading: false, id: metadata.id });


    const asset = {
      downloadUrl: downloadUrl,
      shopId: shop.id,
      smallVersion: {
        downloadUrl: downloadUrl,
        width: 250,
        height: 250
      },
      mediumVersion: {
        downloadUrl: downloadUrl,
        width: 500,
        height: 500
      },
      metadata,
      options,
    };

    const photo = {
      id: metadata.id,
      photo: asset,
      position: galleryPhotos.length > 0 ? galleryPhotos[0].position - 1 : 0,
    }

    shop.addGalleryPhoto(photo).then(p => {
      setGalleryPhotos([p, ...galleryPhotos]);
      setUploading({ isUploading: false, id: '' });
      trackEvent("Shop Gallery Photo Action", {
        action: 'add',
        photo: galleryPhotoToEventModel(p)
      });
    });

    if (!shop?.checklistCompletions?.includes(ChecklistValues.GalleryPhotoAdded)) {
      await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.GalleryPhotoAdded] }));
    }
    await dispatch(getShopAction(shop.id));

    setErrorMessage(undefined);
  };

  const handleUploadFailure = (error, callbacks, metadata) => {
    console.error('Error Uploading Image', error);
    setErrorMessage(`An error occured uploading your photo: ${metadata.originalFilename}.`);
    setUploading({ isUploading: false, id: '' });
  };

  const handleFileDrop = files => {
    setUploading({ isUploading: true, id: '' });
    files.map(file => {
      const id = nanoid();
      const downloadUrl = URL.createObjectURL(file);
      console.debug(downloadUrl);
      const metadata = {
        id,
        contentType: file.type,
        assetType: 'galleryImage',
        shopId: shop.id,
        originalFilename: file.name,
        type: file.type
      };

      const callbacks = {
        success: handleUploadSuccess,
        error: handleUploadFailure,
      };

      const options = {
        folder: `/user/${shop.id}/galleryPhoto`,
        filename: file.name
      };

      const context = {
        shop
      }

      upload(file, metadata, options, callbacks, context);
    })
  };

  const onDelete = useCallback((galleryPhoto: GalleryPhoto) => {
    const doDelete = async () => {
      if (shop) {
        dispatch(
          openModal({
            modalType: 'DELETE_MODAL',
            modalProps: {
              show: true,
              itemName: 'this photo',
              onDelete: async () => {
                await shop.deleteGalleryPhoto(galleryPhoto.id);
                setGalleryPhotos(photos => photos.filter(p => p.id !== galleryPhoto.id).map((p, i) => ({
                  ...p,
                  position: i
                })));
                trackEvent("Shop Gallery Photo Action", {
                  action: 'delete',
                  photo: galleryPhotoToEventModel(galleryPhoto)
                });
                dispatch(closeModal());
              }
            },
          }),
        );
      }
    }

    doDelete();
  }, [shop]);

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (newPositions.length > 0) {
        //only update photos whose position has changed
        const positionsToUpdate = newPositions.map((p) => {
          const originalPosition = originalPositions.find(op => op.id === p.id);
          if (originalPosition.position !== p.position) {
            return { id: p.id, position: p.position };
          }
        }).filter(p => p);

        const promises = positionsToUpdate.map(p => shopRepository.updateGalleryPhoto(shop.id, p.id, { position: p.position }));
        await Promise.all(promises).then(() => {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                celebrate: true,
                content: 'Your photos have been updated!',
              },
            }),
          );

          if (positionsToUpdate) {
            trackEvent("Shop Gallery Photo Action", {
              action: 'reorder',
              shop: shopToEventModel(shop),
            });
          };
        });
      } else {
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: 'Your changes have been saved!',
            },
          }),
        )
      }

      setIsRearranging(false);
      setIsSubmitting(false);
      setNewPositions([]);
    } catch (err) {
      setIsSubmitting(false);
      setErrorMessage('An error occured saving your changes.');
      console.error('Error Editing Gallery Photos: ', err);
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sortedPhotos = reorder<GalleryPhoto>(
      galleryPhotos,
      result.source.index,
      result.destination.index
    );
    const newPositions = sortedPhotos.map(p => ({ id: p.id, position: p.position }));

    setNewPositions(newPositions);
    setGalleryPhotos(sortedPhotos);
  }

  const DraggableImageCard = (): ReactElement => {
    return (
      <Droppable droppableId="galleryPhotos" type="PHOTOS">
        {(provided, snapshot) => (
          <Grid item container xs={12} alignItems="center" direction="column" wrap="nowrap" ref={provided.innerRef}>
            {_.sortBy(galleryPhotos, p => p.position).map((galleryPhoto: GalleryPhoto, index: number) =>
              <Draggable draggableId={galleryPhoto.id} index={index} key={galleryPhoto.id}>
                {(provided, snapshot) => (
                  <Grid ref={provided.innerRef} {...provided.draggableProps} container item key={galleryPhoto.id} className={classes.draggableImageCard} justify="space-between" alignItems="center" xs={12} sm={8}>
                    <Grid container item className={classes.draggableImageBox}>
                      <img src={galleryPhoto?.photo?.smallVersion?.downloadUrl || galleryPhoto?.photo?.downloadUrl} className={classes.galleryImage} />
                      <Typography variant="h6">
                        {galleryPhoto.photo.metadata.originalFileName}
                      </Typography>
                    </Grid>
                    <ButtonBase {...provided.dragHandleProps} className={classes.dragHandle}>
                      <SvgIcon className={classes.dragDots}>
                        <DragIndicator />
                      </SvgIcon>
                    </ButtonBase>
                  </Grid>
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container className={!isXsMobile && classes.wrapper}>
        <Helmet>
          <title>Gallery | Shop | Castiron</title>
        </Helmet>
        <Grid item xs={12} className={classes.tabPanel}>
          <Grid container item direction={isXsMobile ? 'column' : 'row-reverse'}>
            <Grid item container xs={12} sm={4} direction="column">
              <Spinner show={uploading.isUploading} size="fullscreen" />
              <Grid item container className={classes.photoTipsContainer}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <SvgIcon className={classes.infoIcon}>
                      <InfoIcon />
                    </SvgIcon>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" style={{ marginTop: '3px' }}>Photo Tips</Typography>
                  </Grid>
                </Grid>
                <ul className={classes.tipsOne}>
                  <li>
                    <Typography variant="caption">Square photos work best. We recommend 900px x 900px.</Typography>
                  </li>
                  <li>
                    <Typography variant="caption">JPG or PNG files only.</Typography>
                  </li>
                  <li>
                    <Typography variant="caption">Find that natural light! Well lit, clear photos really let your products shine.</Typography>
                  </li>
                </ul>
              </Grid>
              {errorMessage && <Grid item xs={12}><ErrorBox text={errorMessage} className={classes.error} /></Grid>}
            </Grid>
            {isRearranging ? (
              <Grid item container xs={12} sm={8} alignItems="center" justify={isXsMobile ? 'center' : 'flex-start'} >
                <DraggableImageCard />
              </Grid>
            ) : (
              <Grid item container xs={12} sm={8} alignItems="flex-start" justify={isXsMobile ? 'center' : 'flex-start'} >
                <Grid item container xs={12} spacing={1} alignItems="flex-start" justify='flex-start' className={classes.galleryContainer}>
                  <Grid item
                    className={galleryPhotos.length > 0 ? clsx([classes.uploadInlineContainer, isTinyMobile ? classes.uploadInlineContainerTiny : undefined]) : classes.uploadContainer}>
                    <HackedImageUploader
                      imageInProduct={false}
                      dropZoneText={isXsMobile ? 'Select Photos to Upload' : 'Drag and drop photos here'}
                      onFileDrop={handleFileDrop}
                      labelContainerClass={classes.labelContainerClass}
                      maxSize="100%"
                      maxFiles={100}
                      hackSmUploadedContainerSize='-webkit-fill-available'
                    />
                  </Grid>
                  {_.sortBy(galleryPhotos, p => p.position).map(galleryPhoto =>
                    <Grid item key={galleryPhoto.id}
                      className={clsx([classes.imageContainer, isTinyMobile ? classes.imageContainerTiny : undefined])}>
                      <div className={clsx([classes.imageBox, isTinyMobile ? classes.imageBoxTiny : undefined])}>
                        <img src={galleryPhoto?.photo?.smallVersion?.downloadUrl || galleryPhoto?.photo?.downloadUrl} className={classes.galleryImage} />
                      </div>
                      <ButtonBase className={clsx([classes.iconButton, isTinyMobile ? classes.iconButtonTiny : undefined])}
                        onClick={() => onDelete(galleryPhoto)}>
                        <SvgIcon className={classes.icon}>
                          <TrashIcon />
                        </SvgIcon>
                      </ButtonBase>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

          </Grid>
        </Grid>
      </Grid>
    </DragDropContext>
  )
};

export default Gallery;
