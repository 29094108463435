import React, { useState } from "react";
import { Customer, subscriberStatusState } from "@castiron/domain";
import Chip from "../Chip";

interface Props {
    customer: Customer;
};


const statusToColorMapping = {
    'opted out': 'error',
    pending: 'warning',
    yes: 'success',
    error: 'info'
};


const SubscriberStatusPill: React.FC<Props> = (props: Props) => {
    const { customer } = props;

    const subscriberStatus = subscriberStatusState(customer)?.toLowerCase();
    const colorScheme = statusToColorMapping[subscriberStatus];

    return <Chip bold colorScheme={colorScheme}>{subscriberStatus?.toUpperCase()}</Chip>;
};

export default SubscriberStatusPill;
