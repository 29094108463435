import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { VisibilityOutlined } from '@material-ui/icons';
import { Button } from '@castiron/components';
import { toQueryString, useTracking } from '@castiron/utils';
import { createOpenShopButtonClickedEvent } from '../../../lib/events/commonEvents';
import { useAppSelector } from '../../../hooks';

type Props = {
  subdirectory?: string;
  size?: "small" | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileIcon: {
    color: theme.branding.gray[500],
  },
}));

const ViewShopButton: React.FC<Props> = (props: Props) => {
  const { size, subdirectory } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let event;
  let buttonText;
  switch (subdirectory) {
    case 'gallery':
      event = 'View Gallery Button Clicked';
      buttonText = 'View My Gallery';
      break;
    case 'signup':
      event = 'Open Signup Button Clicked';
      buttonText = 'View My Sign Up Page';
      break;
    default:
      event = 'Open Shop Button Clicked';
      buttonText = `${shop.status === 'active' ? 'V' : 'Prev'}iew My Shop`;
  }

  const handleViewShop = () => {
    trackEvent(event, createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}${subdirectory ? `/${subdirectory}` : ''}?${toQueryString(queryParams)}`, '_blank');
  }

  return isMobile ? (
    <VisibilityOutlined onClick={handleViewShop} className={classes.mobileIcon} />
  ) : (
    <Button
      variant='outlined'
      onClick={handleViewShop}
      size={size || 'large'}
      style={size === 'small' ? { height: 48 } : {}}
    >
      <VisibilityOutlined />&nbsp;&nbsp; {buttonText}
    </Button>
  );
};

export default ViewShopButton;