import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik, getIn, useFormikContext } from 'formik';
import { Grid, IconButton, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button, Input } from '@castiron/components';
import { Shop } from '@castiron/domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import ModalWrapper from '../RootModal/ModalWrapper';
import { openModal } from '../../store/reducers/modalConductor';
import { updateShopAction } from '../../store/reducers/shops';
import states from '../Store/states';

export interface Props {
  show: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    padding: '32px 24px',
  },
  textContainer: {
    textAlign: 'center',
    margin: '8px 0px',
  },
  buttonContainer: {
    marginTop: '24px',
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '24px',
    cursor: 'pointer',
    zIndex: 10,
  },
}));

const SeoLocationModal: React.FC<Props> = (props: Props) => {
  const { show } = props;

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleClose = (): void => {
    localStorage.setItem('seenSeoLocationModal', 'true');
    dispatch(closeModal());
  };

  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef() as any;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const seoLocationSchema = yup.object().shape({
    seoMetadata: yup.object().shape({
      address: yup.object().shape({
        addressLocality: yup.string().required('Please enter a City'),
        addressRegion: yup.string().required('Please select a State'),
        postalCode: yup
          .string()
          .min(5, 'Zip Code must be at least 5 numbers')
          .required('Please enter a Zip Code'),
      }),
    }),
  });

  const initialValues = {
    seoMetadata: {
      address: {
        addressLocality: shop?.seoMetadata?.address?.addressLocality || '',
        addressRegion: shop?.seoMetadata?.address?.addressRegion || '',
        postalCode: shop?.seoMetadata?.address?.postalCode || '',
      },
    },
  };

  const onPostalCodeChange = (event, setFieldValue) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 5);
    setFieldValue('seoMetadata.address.postalCode', sanitizedValue);
  };

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      if (
        values.seoMetadata.address.addressLocality !== shop?.seoMetadata?.address?.addressLocality ||
        values.seoMetadata.address.addressRegion !== shop?.seoMetadata?.address?.addressRegion
      ) {
        values.seoMetadata.manuallyUpdated = true;
      }
      const newShop: Shop = {
        ...shop,
        ...values,
      };

      const newCompletions = [];

      await dispatch(updateShopAction({ shop: newShop, newCompletions })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                celebrate: true,
                content: 'Changes have been saved!',
              },
            }),
          );
        }
      });

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <Grid container className={classes.modalContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={seoLocationSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ handleSubmit, errors, setFieldValue, touched }) => (
            <>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography variant="h2" style={{ fontSize: 63, height: 60, marginTop: 45 }}>
                  📍
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography variant="h2">Increase Visitors to Your Shop</Typography>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography variant="body1">
                  Add your primary service area to receive more visitors to your shop when they search for local
                  businesses like yours!
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">City*</Typography>
                  <Input
                    error={
                      getIn(touched, 'seoMetadata.address.addressLocality') &&
                      getIn(errors, 'seoMetadata.address.addressLocality')
                    }
                    name="seoMetadata.address.addressLocality"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">State*</Typography>
                  <Input
                    error={
                      getIn(touched, 'seoMetadata.address.addressRegion') &&
                      getIn(errors, 'seoMetadata.address.addressRegion')
                    }
                    name="seoMetadata.address.addressRegion"
                    fullWidth
                    select
                  >
                    {states.map(state => {
                      return (
                        <MenuItem key={state.name} value={state.abbreviation}>
                          {state.name}
                        </MenuItem>
                      );
                    })}
                  </Input>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Zip Code*</Typography>
                  <Input
                    onChange={e => onPostalCodeChange(e, setFieldValue)}
                    error={
                      getIn(touched, 'seoMetadata.address.postalCode') &&
                      getIn(errors, 'seoMetadata.address.postalCode')
                    }
                    name="seoMetadata.address.postalCode"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1} justify="flex-end" className={classes.buttonContainer}>
                <Grid item>
                  <Button variant="outlined" onClick={handleClose}>
                    Not Now
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit" onClick={() => handleSubmit()}>
                    Add Location
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </ModalWrapper>
  );
};

export default SeoLocationModal;
