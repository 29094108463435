import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { communityPackageRepository, productRepository } from '../../domain';
import { CommunityPackage } from '@castiron/domain';
import { handleReducerError } from './errorHandler';

export interface CommunityState {
    communityPackages: CommunityPackage[];
    loading: boolean;
    error: string;
    haveLoaded: boolean;
}

const initialState: CommunityState = {
    communityPackages: [],
    loading: false,
    error: '',
    haveLoaded: false,
};

const getCommunityPackages = async (shopId: string) => {
    const response = await communityPackageRepository.findActivePriorityOrdered()
    console.debug('response from getCommunityPackages: ', response);
    const productsResponse = await productRepository.findByShopId(shopId);
    console.debug('response from getProducts: ', response);

    const modifiedCommunityPackages = response.map(
        (cp: CommunityPackage) => {
            const product = productsResponse.find(
                product => product.communityPackageId === cp.id
            )
            return product ? { ...cp, addedProductId: product.id } : cp
        })
    return modifiedCommunityPackages;
};

export const getCommunityPackagesAction = createAsyncThunk('community/getCommunityPackages', getCommunityPackages);

const communitySlice = createSlice({
    name: 'community',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCommunityPackagesAction.rejected, handleReducerError('Error Getting Community Packages'))
            .addCase(getCommunityPackagesAction.pending, state => {
                state.loading = true;
            })
            .addCase(getCommunityPackagesAction.fulfilled, (state, action) => {
                state.communityPackages = [...action.payload];
                state.loading = false;
                state.haveLoaded = true;
            })
    },
});

export default communitySlice.reducer;
