import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { FulfillmentOption } from "@castiron/domain";
import { Button, Typography } from "@castiron/components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getShopAction, updateShopAction } from "../../../store/reducers/shops";

interface Props {
  open: boolean;
  option?: FulfillmentOption;
  onClear: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&:first-child': {
      paddingTop: '32px'
    },
    padding: '32px 24px'
  },
  cancel: {
    textAlign: 'end'
  },
  cancelButton: {
    ...theme.typography.button
  },
  deleteButton: {
    ...theme.typography.button,
    color: theme.branding.gray[100],
    backgroundColor: theme.branding.red.primary
  }
}));

const DeleteDialog: React.FC<Props> = (props: Props) => {
  const { open, option, onClear } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop
  }));

  const onDelete = async () => {
    await shop.deleteFulfillmentOption(option.id);
    await dispatch(getShopAction(shop.id));
    history.push('/store/fulfillment');
    onClear();
  };

  return <Dialog open={open} maxWidth="xs" fullWidth={true}>
    <DialogContent className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Delete {option?.displayName.trim()}?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.cancel}>
          <Button
            variant="outlined"
            onClick={ onClear }
            fullWidth={ isMobile }
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            onClick={ onDelete }
            fullWidth={ isMobile }
            className={classes.deleteButton}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>;
}

export default DeleteDialog;
