import React, {ReactNode, useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import _ from "lodash";
import 'firebase/compat/remote-config';

interface Props {
  remoteConfig: firebase.remoteConfig.RemoteConfig;
  children: ReactNode;
}

interface ShopList {
  shopIds: string[];
}

interface PropertyMatch {
  path: string;
  values: any[];
}

interface PropertyList {
  properties: PropertyMatch[];
}

export class FeatureFlagConfig {
  private remoteConfig: firebase.remoteConfig.RemoteConfig;

  constructor(config) {
    this.remoteConfig = config;
  }

  public config() {
    return this.remoteConfig;
  }

  private isShopInList(value: string, shopId: string): boolean {
    const shopList: ShopList = JSON.parse(value);
    return shopList.shopIds.includes(shopId);
  }

  private shopPropertyIs(shop: string, value: string): boolean {
    const propList: PropertyList = JSON.parse(value);
    return propList.properties.every(pm => {
      const val = _.get(shop, pm.path);
      if (Array.isArray(val)) {
        return pm.values.every(v => val.includes(v));
      }
      return pm.values.includes(val);
    });
  };

  public featureFlag(key: string, shop): boolean {
    const value = this.remoteConfig.getString(key);
    let flag: boolean;

    if (value.includes('shopIds')) {
      flag = this.isShopInList(value, shop.id);
    } else if (value.includes('properties')) {
      flag = this.shopPropertyIs(shop, value);
    } else {
      flag = value === 'true';
    }
    return flag;
  }
}

export const ConfigContext = React.createContext<FeatureFlagConfig>(undefined);

export const Configuration: React.FC<Props> = (props) => {
  const { remoteConfig, children } = props;
  const defaultConfig = new FeatureFlagConfig(remoteConfig);

  const [config, setConfig] = useState(defaultConfig);

  useEffect(() => {
    const loadConfig = async () => {
      await remoteConfig.fetch();
      await remoteConfig.activate();
      setConfig(new FeatureFlagConfig(remoteConfig));
    };
    loadConfig();
  }, []);


  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
