import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { BlockOutlined, DateRangeOutlined, DescriptionOutlined, PeopleOutlined, ShoppingCartOutlined } from '@material-ui/icons';
import { Banner, RadioOrCheckboxGroup, Typography } from '@castiron/components';
import { BusinessGoal } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { accountRepository, planRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import AdminForm from '../../AdminForm';
import OnboardingFooter from './OnboardingFooter';
import { getService } from '../../../firebase';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Spinner';
import { setFromOnboarding } from '../../../store/reducers/shops';

const createSubscriptionService = getService('subscriptions', 'createsubscription', { version: 2 });
const createShopContentService = getService('shops', 'generateshopcontent', { version: 2 });

interface Props {
  step: number;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  nextStep?: () => void;
  prevStep?: () => void;
};

const businessStageSchema = yup.object().shape({
  goal: yup
    .string()
    .required('Required')
    .oneOf(['custom-order', 'manage-business', 'online-shopping', 'marketing-automation', 'other']),
});

const CastironGoal: React.FC<Props> = (props: Props) => {
  const { step, nextStep, prevStep, setHeader, setSubHeader } = props;
  const formRef = useRef<FormikProps<any>>();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { account, shop, testClock, userState } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    testClock: state.debug.stripe?.testClock,
    userState: state.shops.userState,
  }));

  const [showError, setShowError] = useState(false);
  const [subCreationLoading, setSubCreationLoading] = useState(false);

  const radioList = [
    {
      icon: <DescriptionOutlined />,
      label: 'I want to create and send seamless custom food order forms.',
      value: 'custom-order',
    },
    {
      icon: <DateRangeOutlined />,
      label: 'I want to streamline & manage my food business in one place.',
      value: 'manage-business',
    },
    {
      icon: <ShoppingCartOutlined />,
      label: 'I want to provide a more professional customer shopping experience.',
      value: 'online-shopping',
    },
    {
      icon: <PeopleOutlined />,
      label: 'I want to grow by improving my marketing.',
      value: 'marketing-automation',
    },
    {
      icon: <BlockOutlined />,
      label: 'Other/Not Listed',
      value: 'other',
    }
  ];

  const subscribe = async () => {
    setSubCreationLoading(true);

    try {
      await createShopContentService({});
      await createSubscriptionService({
        planId: '3q7m0AMRBOiZjTr1yXz6', // Pro V2
        priceId: 'D0b8mADwraJX4JlvKCDHR', // Pro V2 Monthly
        testClock,
      });
      trackEvent('Onboarding Sub Created', {
        billingFrequency: 'monthly',
        subscription: 'Pro V2 - 3q7m0AMRBOiZjTr1yXz6',
        shopId: shop?.id,
        userState: userState,
      });

      setSubCreationLoading(false);
      await dispatch(setFromOnboarding(true));
      history.push('/new');
    } catch (err) {
      console.error('Error creating subscription', err);
      setSubCreationLoading(false);
    }
  };

  const submit = async (values) => {
    trackEvent('Primary Goal Selected', { goal: values.goal });
    await accountRepository.updateProps(account.id, {
      'onboardingQuestions.goal': values.goal,
    });
    await subscribe();
  };

  const onNextClick = async () => {
    await formRef.current.validateForm();
    if (formRef.current.isValid) {
      formRef.current.submitForm();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    setHeader('What’s your primary goal with Castiron?');
    setSubHeader('We’ll use this answer to help discover and implement features to help you meet your goal.');
  }, []);

  useEffect(() => {
    if (account.onboardingQuestions?.goal && !formRef?.current?.values.goal) {
      formRef?.current?.setFieldValue('goal', account.onboardingQuestions.goal);
    }
  }, [account]);

  return (
    <Grid container direction='column'>
      <Spinner label="Baking Your Shop..." show={subCreationLoading} size="fullscreen" />
      {
        showError &&
        <Grid item style={{ marginTop: '24px' }}>
          <Banner variant='error'>
            <Typography variant='body2' style={{ color: 'inherit' }}>
              Please select an option.
            </Typography>
          </Banner>
        </Grid>
      }
      <Grid item>
        <Formik
          initialValues={{
            goal: '',
          }}
          validationSchema={businessStageSchema}
          onSubmit={submit}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<any>) => (
            <AdminForm>
              <RadioOrCheckboxGroup
                inputType="radio"
                items={radioList}
                defaultSelected={false}
                selectedItem={formikProps.values.goal}
                setSelection={(value: BusinessGoal) => {
                  setShowError(false);
                  formikProps.setFieldValue('goal', value)
                }}
              />
            </AdminForm>
          )}
        </Formik>
      </Grid>
      <Grid item>
        <OnboardingFooter step={step} nextStep={onNextClick} prevStep={prevStep} />
      </Grid>
    </Grid>
  );
};

export default CastironGoal;
