import { Grid, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import * as yup from 'yup';
import { AvailabilityStatusLabel, Banner, Button, DateInput, Select, Typography } from '@castiron/components';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import { Formik, FormikProps} from 'formik';
import { AvailabilitySubtype } from '@castiron/domain';
import AdminForm from '../AdminForm';
import moment, { Moment } from 'moment';
import ModalWrapper from '../RootModal/ModalWrapper';
import Close from '@material-ui/icons/Close';
import { shopRepository } from '../../domain';
import UpdatedAvailability from './index';

export type Props = {
  show?: boolean;
  onSubmit?: (type: AvailabilitySubtype, start: number, end: number) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: 8,
    padding: 16,
  },
  buttonsContainer: {
    padding: '12px 24px',
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    minHeight: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 32,
  },
  content: {
    margin: '32px 40px',
    width: 'auto',
  },
  header: {
    padding: 24,
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  paperClass: {
    borderRadius: 32,
    width: 800,
  },
  select: {
    margin: '4px 0',
    width: '100%',
  },
}));

const AvailabilityModal: React.FC<Props> = (props: Props) => {
  const { show, onSubmit } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const formRef = useRef<FormikProps<any>>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { shop, tier } = useAppSelector(state => ({
    shop: state.shops.shop,
    tier: state.shops.account.tier,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const initialValues = {
    status: 'unavailable',
    startDate: null,
    endDate: null,
  };

  const availabilitySchema = yup.object().shape({
    status: yup
      .mixed<AvailabilitySubtype>()
      .oneOf(['available', 'limited', 'unavailable']),
    startDate: yup
      .number()
      .test({
        message: 'Please select a date after your start date.',
        test: (value, context) => value <= context.parent.endDate,
      }),
    endDate: yup
      .number()
      .test({
        message: 'Please select a date after your start date.',
        test: (value, context) => value >= context.parent.startDate,
      }),
  });

  const displayContent = {
    available: {
      banner:
        <Banner variant='info-white' noIcon={true}>
          <Typography variant='body2'>I currently have availability for your selected date but my calendar fills up fast! Once submitted, I’ll get back to you as soon as possible on your request.</Typography>
        </Banner>
      ,
      displayText: 'Available',
      helperText: <Typography variant='caption'>Customers <b>can</b> choose a date in this timeframe for their custom order request and will see this message:</Typography>,
      label: <AvailabilityStatusLabel status='available' />,
    },
    limited: {
      banner:
        <Banner variant='info-yellow' noIcon={true}>
          <Typography variant='body2'>I have limited availability for your selected date but my calendar fills up fast! Once submitted, I’ll get back to you as soon as possible on your request.</Typography>
        </Banner>
      ,
      displayText: 'Limited Availability',
      helperText: <Typography variant='caption'>Customers <b>can</b> choose a date in this timeframe for their custom order request and will see this message:</Typography>,
      label: <AvailabilityStatusLabel status='limited' />,
    },
    unavailable: {
      displayText: 'Not Available',
      helperText: <Typography variant='caption'>Customers <b>cannot</b> choose a date in this timeframe for their custom order request.</Typography>,
      label: <AvailabilityStatusLabel status='unavailable' />,
    },
  };

  const onStatusChange = async (e) => {
    formRef.current.setFieldValue('status', e.target.value);
  };

  const onStartDateChange = async (startDate: Moment) => {
    /* if it's the first time entering a value, set both start and end to the same date */
    if (formRef.current.values.endDate === null && startDate.isValid()) {
      /* await here to ensure downstream validation happens with new values */
      await formRef.current.setFieldValue('endDate', moment(startDate).startOf('day').unix());
    }
    await formRef.current.setFieldValue('startDate', moment(startDate).startOf('day').unix());
    await formRef.current.setFieldTouched('startDate');
  };

  const onEndDateChange = async (endDate: Moment) => {
    /* if it's the first time entering a value, set both start and end to the same date */
    if (formRef.current.values.startDate === null && endDate.isValid()) {
      /* await here to ensure downstream validation happens with new values */
      await formRef.current.setFieldValue('startDate', moment(endDate).startOf('day').unix());
    }
    await formRef.current.setFieldValue('endDate', moment(endDate).startOf('day').unix());
    await formRef.current.setFieldTouched('endDate');
  };

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const {
        status,
        startDate,
        endDate,
      } = values;
      const startDateMoment = moment.unix(startDate).tz(timeZone, true);
      const updatedStartDate = startDateMoment.unix();
      const endDateMoment = moment.unix(endDate).tz(timeZone, true);
      const updatedEndDate = endDateMoment.unix();

      await shop.addCalendarAvailability(status, updatedStartDate, updatedEndDate);

      /* we are saving dates, let's check and see if a timezone is set in the config */
      if (!shop.config?.timeZone) {
        /* if not, set one */
        await shopRepository.updateProps(shop.id, {
          config: {
            ...shop.config,
            timeZone: defaultTimeZone,
          },
        });
      }

      trackEvent('Calendar Availability Set', {
        status,
        updatedStartDate,
        updatedEndDate,
        tier,
      });

      onSubmit && onSubmit(status, updatedStartDate, updatedEndDate);

      setIsSubmitting(false);

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            content: `${startDateMoment.format('MM/DD/YYYY')} to ${endDateMoment.format('MM/DD/YYYY')} updated to ${displayContent[status].displayText}.`,
            show: true,
          },
        }),
      );
    } catch (err) {
      console.debug(err);
      setIsSubmitting(false);
    }
  };

  return (
    <ModalWrapper paperClass={!isMobile && classes.paperClass} fullScreen={isMobile} show={show} onClose={handleClose}>
      <Grid container direction='column'>
        <Grid container item justify='space-between' alignItems='center' className={classes.header}>
          <Grid item>
            <Typography variant='h3'>Update Availability</Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item>
          <Formik
            validationSchema={availabilitySchema}
            initialValues={initialValues}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {({dirty, errors, isValid, touched, values }) => (
              <AdminForm style={{width: '100%'}}>
                <Grid container item className={classes.content} direction='column'>
                  <Grid item>
                    <Typography variant='subtitle2'>Show Dates As:</Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      options={[{label: displayContent.available.label, value: 'available'}, {label: displayContent.limited.label, value: 'limited'}, {label: displayContent.unavailable.label, value: 'unavailable'}]}
                      value={values.status}
                      onChange={onStatusChange}
                      selectClass={classes.select}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='caption'>{displayContent[values.status].helperText}</Typography>
                  </Grid>
                  {displayContent[values.status].banner &&
                    <Grid item style={{marginTop: 8}}>
                      {displayContent[values.status].banner}
                    </Grid>
                  }
                  <Grid container item wrap='nowrap' alignItems='flex-start' style={{margin: '24px 0'}}>
                    <DateInput
                      label='Start Date'
                      onChange={onStartDateChange}
                      placeholder='Select Start Date'
                      selectedDate={values.startDate ? moment.unix(values.startDate) : null}
                    />
                    <Typography variant='body1' style={{padding: '48px 16px 0 16px'}}>to</Typography>
                    <DateInput
                      error={touched.endDate && errors.endDate}
                      label='End Date'
                      onChange={onEndDateChange}
                      placeholder='Select End Date'
                      selectedDate={values.endDate ? moment.unix(values.endDate) : null}
                    />
                  </Grid>
                  <Grid item>
                    <Banner variant='info-blue'>
                      <Typography variant='body2' style={{color: theme.branding.blue.primary}}>Please note: Availability only impacts custom orders.</Typography>
                    </Banner>
                  </Grid>
                </Grid>
                <Grid container item justify='flex-end' className={classes.buttonsContainer}>
                  <Button
                    className={classes.button}
                    onClick={handleClose}
                    variant='outlined'
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.button}
                    disabled={!dirty || !isValid || isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </Grid>
              </AdminForm>
            )}
          </Formik>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default AvailabilityModal;
