import React, { useEffect } from 'react';
import { Box, Button, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '@castiron/client-admin/src/hooks';
import { Helmet } from 'react-helmet';
import CouponTable from './CouponTable';
import { getCouponAction } from '@castiron/client-admin/src/store/reducers/coupons';
import { LayoutPageProps } from '../Layout';
import { EmptyCouponsIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../EmptyPage';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '16px',
    },
    '&:hover': {
      color: theme.branding.gray[100],
      backgroundColor: theme.branding.blue.dark,
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  icon: {
    height: 104,
    width: 108,
  },
}));

interface Props extends LayoutPageProps {
  editing?: boolean;
}

const Coupons: React.FC<Props> = (props: Props) => {
  const { editing = false, setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { shop, storeCoupons, isCouponsLoading } = useAppSelector(state => ({
    shop: state.shops.shop,
    storeCoupons: state.coupons.coupons,
    isCouponsLoading: state.coupons.loading,
  }));

  useEffect(() => {
    setPageTitle('Coupons');
    const headerCTAs = isMobile
      ? []
      : [
          <Button variant="contained" color="primary" href="/coupons/new" className={classes.addButton}>
            Add a Coupon
          </Button>,
        ];
    setHeaderCTAs(headerCTAs);

    isMobile
      ? setFooterCTAs([
          <Button fullWidth variant="contained" color="primary" href="/coupons/new" className={classes.addButton}>
            Add a Coupon
          </Button>,
        ])
      : setFooterCTAs([]);

    setBackLocation(true);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, [isMobile]);

  useEffect(() => {
    dispatch(getCouponAction(shop.id));
  }, [shop]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get('fromChecklist');

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'article',
      title: 'View Our Guide to Coupons',
      description: 'Learn how coupons can help you grow your business!',
      ctaAction: () =>
        window
          .open('https://castiron.helpscoutdocs.com/article/42-how-can-i-create-a-new-coupon-code', '_blank')
          .focus(),
    },
  ];

  const emptyCoupon = () => (
    <EmptyPage
      icon={<EmptyCouponsIcon className={classes.icon} />}
      title="Add a Coupon"
      description="Add a coupon to incentivize customers to shop and join your marketing list!"
      ctaAction={() => window.open('/coupons/new', '_self').focus()}
      ctaText="Add a Coupon"
      tipsForYou={tipsForYou}
      page="Coupons"
    />
  );

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>Coupons | {shop.businessName}</title>
      </Helmet>
      <Spinner show={isCouponsLoading} />
      {storeCoupons.length > 0 ? (
        <Grid
          container
          direction={isMobile ? 'column-reverse' : 'row'}
          justify="flex-start"
          alignItems={isMobile ? 'center' : 'flex-start'}
          alignContent="stretch"
          wrap="nowrap"
        >
          <CouponTable editing={editing} />
        </Grid>
      ) : (
        emptyCoupon()
      )}
    </Box>
  );
};

export default Coupons;
