import React from 'react';
import { useConfig } from '@castiron/castiron-firebase';
import { LayoutPageProps } from '../Layout';
import OnboardingV3 from './v3/Onboarding';
import Signup from './v4/Signup';

const Onboarding: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {

  const config = useConfig();
  const onboardingVersion = config && config.config().getNumber('feature_onboarding_version');

  switch (onboardingVersion) {
    case 3:
      return <OnboardingV3 />;
    case 4:
      return <Signup />;
    default:
      return <Signup />;
  }
};

export default Onboarding;
