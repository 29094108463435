import React, { ReactNode } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircleOutline, ErrorOutline, InfoOutlined, StarBorder } from '@material-ui/icons';
import clsx from 'clsx';
import { MoneyIcon } from '../Icons';

interface Props {
  variant: 'success' | 'error' | 'info-blue' | 'info-white' | 'info-yellow' | 'star-blue' | 'money';
  children: ReactNode;
  className?: string;
  noIcon?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: 16,
    borderRadius: '12px',
  },
  error: {
    color: theme.branding.red.primary,
    backgroundColor: theme.branding.red.light,
  },
  errorIcon: {
    color: theme.branding.red.primary,
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  infoBlue: {
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.blue.light,
  },
  infoBlueIcon: {
    color: theme.branding.blue.primary,
  },
  infoWhite: {
    color: theme.branding.gray[800],
    backgroundColor: theme.branding.gray[200],
  },
  infoWhiteIcon: {
    color: theme.branding.gray[800],
  },
  infoYellow: {
    color: theme.branding.orange.primary,
    backgroundColor: theme.branding.yellow.light,
  },
  infoYellowIcon: {
    color: theme.branding.orange.primary,
  },
  money: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  noIcon: {
    paddingLeft: 16,
  },
  starBlue: {
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.blue.light,
  },
  starIcon: {
    top: '16px',
  },
  success: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  successIcon: {
    color: theme.branding.green.primary,
  },
}));

const Banner: React.FC<Props> = (props: Props) => {
  const { variant, children, className, noIcon } = props;

  const classes = useStyles();

  let icon, containerClass;
  switch (variant) {
    case 'success':
      icon = <CheckCircleOutline className={clsx(classes.icon, classes.successIcon)} />;
      containerClass = classes.success;
      break;
    case 'error':
      icon = <ErrorOutline className={clsx(classes.icon, classes.errorIcon)} />;
      containerClass = classes.error;
      break;
    case 'info-blue':
      icon = <InfoOutlined className={clsx(classes.icon, classes.infoBlueIcon)} />;
      containerClass = classes.infoBlue;
      break;
    case 'info-white':
      icon = <InfoOutlined className={clsx(classes.icon, classes.infoWhiteIcon)} />;
      containerClass = classes.infoWhite;
      break;
    case 'info-yellow':
      icon = <InfoOutlined className={clsx(classes.icon, classes.infoYellowIcon)} />;
      containerClass = classes.infoYellow;
      break;
    case 'money':
      icon = <MoneyIcon className={clsx(classes.icon, classes.money)} />;
      containerClass = classes.money;
      break;
    case 'star-blue':
      icon = <StarBorder className={clsx(classes.icon, classes.starIcon)} />;
      containerClass = classes.starBlue;
      break;
  }

  return (
    <Grid
      container
      item
      wrap="nowrap"
      alignItems="center"
      className={clsx(classes.container, containerClass, className, noIcon && classes.noIcon)}
    >
      {!noIcon && <Grid style={{ marginRight: 16 }}>{icon}</Grid>}
      {children}
    </Grid>
  );
};

export default Banner;
