import React from 'react';

import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowY: 'auto',
    padding: '24px',
    overflowWrap: 'break-word',
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  headerContainer: {
    marginBottom: '40px',
  },
  headerTitle: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 800,
    marginBottom: '8px',
  },
  headerSubtext: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  contentContainer: {
    padding: '0 40px',
  },
  title: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '24px',
  },
  sectionTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  content: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    whiteSpace: 'break-spaces',
    marginBottom: '24px',
  },
}));

type Props = {
  onClose: () => void;
  open: boolean;
};

const TermsModal: React.FC<Props> = (props: Props) => {
  const { onClose, open } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid className={classes.container}>
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <Close />
        </IconButton>
        <div className={classes.headerContainer}>
          <Typography className={classes.headerTitle}>Custom order Terms & Conditions</Typography>
          <Typography className={classes.headerSubtext} style={{ marginBottom: '16px' }}>
            These custom order Terms & Conditions were built to protect you and your business and are automatically
            added to all custom orders purchased through your shop so you don’t have to think about it or hire a lawyer.
            When a customer purchases the custom order through your shop, they are asked to agree to the terms and
            conditions.
          </Typography>
          <Typography className={classes.headerSubtext}>
            You can add more details relevant to your custom orders in the “Policies” section of the custom product.
          </Typography>
        </div>
        <DialogContent className={classes.contentContainer}>
          <Typography className={classes.title}>Terms & Conditions</Typography>
          <Typography className={classes.sectionTitle}>Order Confirmation</Typography>
          <Typography className={classes.content}>
            Orders are confirmed upon receipt of payment. Artisan reserves the right to cancel the order with
            appropriate lead time. Performance of this agreement is contingent upon our ability to complete the
            Agreement and is subject to labor disputes or strikes, accidents, Acts of God, and other causes beyond our
            control. Sometimes, things are out of our control.
          </Typography>

          <Typography className={classes.sectionTitle}>Care and handling</Typography>
          <Typography className={classes.content}>
            We are not responsible for any damage to your custom order after it has left our hands. You are responsible
            for providing a safe and appropriate environment for your custom order.
          </Typography>

          <Typography className={classes.sectionTitle}>Fulfillment</Typography>
          <Typography className={classes.content}>
            All fulfillment details will be worked out before purchase and are included in the total purchase price.
          </Typography>

          <Typography className={classes.sectionTitle}>Photography and marketing</Typography>
          <Typography className={classes.content}>
            We reserve the right to use photos and videos of your order at our discretion without compensation.
          </Typography>

          <Typography className={classes.sectionTitle}>Payment</Typography>
          <Typography className={classes.content}>
            Full payment is required at the time of purchase. All sales are final.
          </Typography>

          <Typography className={classes.sectionTitle}>Allergens</Typography>
          <Typography className={classes.content}>
            The product may come into contact with common allergens. You are responsible for notifying anyone enjoying
            your custom order of this risk and we cannot be held responsible for any adverse reactions.
          </Typography>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TermsModal;
