import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  Coupon,
  MarketingSend,
  NewsletterMarketingSend,
  Product,
  SubscriberCouponMarketingSend,
} from '@castiron/domain/src';
import { couponRepository, marketingSendRepository } from '../../domain';
import Spinner from '../Spinner';
import { Button, Chip, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { defaultMsg } from './EditUpdateEmail';
import moment from 'moment';
import Dinero from 'dinero.js';
import { ChecklistValues } from '@castiron/domain';
import { updateChecklistAction } from '../../store/reducers/shops';
import { FeatureFlag } from '@castiron/castiron-firebase';

const useStyles = makeStyles((theme: Theme) => ({
  emailContainer: {
    padding: '24px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '16px',
    maxWidth: 328,
    marginBottom: 8,
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      justifySelf: 'center',
    },
  },
  activateButton: {
    marginRight: '8px',
  },
  sectionHeader: {
    marginBottom: 8,
  },
  singleSendEmailsContainer: {
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
}));

export const sendFrequencyLabel = frequency =>
  frequency === 'weekly' ? 'weekly' : frequency === 'biweekly' ? 'biweekly' : 'monthly';

const Emails: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracking();

  const { shop, sendGridConfig, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    sendGridConfig: state.shops?.account?.sendGridConfig,
    products: state.products.products,
  }));

  const [newsletterMarketingSend, setNewsletterMarketingSend] = useState<NewsletterMarketingSend | null>(null);
  const [subscriberMarketingSend, setSubscriberMarketingSend] = useState<SubscriberCouponMarketingSend | null>(null);
  const [getNewsletterMarketingSendLoading, setGetNewsletterMarketingSendLoading] = useState<boolean>(true);
  const [getSubscriberMarketingSendLoading, setGetSubscriberMarketingSendLoading] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const getNewsletterMarketingSend = async () => {
    setGetNewsletterMarketingSendLoading(true);
    const newsLetterMarketingSendResponse = await marketingSendRepository.listByType(shop.id, 'newsletter');
    (await !!newsLetterMarketingSendResponse.length) &&
      setNewsletterMarketingSend(newsLetterMarketingSendResponse[0] as NewsletterMarketingSend);

    setGetNewsletterMarketingSendLoading(false);
  };

  const getSubscriberMarketingSend = async () => {
    setGetSubscriberMarketingSendLoading(true);
    const subscriberMarketingSendResponse = await marketingSendRepository.listByType(shop.id, 'subscriber_coupon');
    if (!!subscriberMarketingSendResponse.length) {
      const marketingSend = subscriberMarketingSendResponse[0] as SubscriberCouponMarketingSend;
      setSubscriberMarketingSend(marketingSend);

      const getCouponResponse = await couponRepository.get(marketingSend.couponId);
      setCoupon(getCouponResponse);
    }

    setGetSubscriberMarketingSendLoading(false);
  };

  useEffect(() => {
    if (shop?.id) {
      getNewsletterMarketingSend();
      getSubscriberMarketingSend();
    }

    return function cleanup() {
      getNewsletterMarketingSend();
      getSubscriberMarketingSend();
    };
  }, [shop]);

  const generateEmailContainer = ({
    name,
    subheader,
    route,
    handleActivateClick,
    marketingSend,
    loading = false,
    newEmail = false,
  }: {
    name: string;
    subheader: string;
    route: string;
    handleActivateClick?: () => void;
    marketingSend?: MarketingSend;
    loading?: boolean;
    newEmail?: boolean;
  }): ReactNode => {
    return (
      <Grid
        container
        direction='column'
        justify='space-between'
        item
        className={classes.emailContainer}
      >
        {!loading && (
          <Chip colorScheme={newEmail ? 'quotesLightBlue' : (marketingSend?.status === 'active' ? 'success' : 'error')} uppercase bold>
            {newEmail ? 'New' : (marketingSend?.status === 'active' ? 'Active' : 'Inactive')}
          </Chip>
        )}
        <Typography variant='subtitle1' style={{ margin: '12px 0 8px 0' }}>{name}</Typography>
        <Typography variant='body2' style={{ marginBottom: 35 }}>{subheader}</Typography>
        <Grid container justify='flex-end'>
          {(!newEmail && (!marketingSend || marketingSend?.status === 'inactive')) && (
            <Button
              className={classes.activateButton}
              variant="contained"
              color="primary"
              onClick={handleActivateClick}
            >
              Activate
            </Button>
          )}
          <Button variant={newEmail ? "contained" : "outlined"} color="primary" onClick={() => history.push(route)}>
            {newEmail ? 'New Email' : 'Edit'}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Spinner show={getNewsletterMarketingSendLoading || getSubscriberMarketingSendLoading} size={'fullscreen'} />
      <FeatureFlag name='feature_marketing_email_templates'>
        <Grid container direction='column' className={classes.singleSendEmailsContainer}>
          <Typography variant='h3' className={classes.sectionHeader}>Single-Send Emails</Typography>
          <Grid container>
            {generateEmailContainer({
              name: 'Product Announcement',
              subheader: 'Manually send an email to your customers about a new product in your shop.',
              route: 'marketing/new-product-email',
              newEmail: true,
            })}
            {generateEmailContainer({
              name: 'Custom Order Availability',
              subheader: 'Manually send an email to your customers about new availability on your calendar.',
              route: 'marketing/new-custom-availability',
              newEmail: true,
            })}
          </Grid>
        </Grid>
      </FeatureFlag>
      <Grid container direction='column'>
        <Typography variant='h3' className={classes.sectionHeader}>Automated Emails</Typography>
        <Grid container>
          {generateEmailContainer({
            name: newsletterMarketingSend
              ? `Shop update - ${sendFrequencyLabel(newsletterMarketingSend.frequency)}`
              : 'Shop update',
            subheader: `Automatically send a ${newsletterMarketingSend ? sendFrequencyLabel(newsletterMarketingSend.frequency) : 'regular'
              } shop update to your subscribers featuring your latest products.`,
            route: 'marketing/edit-update-email',
            handleActivateClick: newsletterMarketingSend
              ? async () => {
                await marketingSendRepository.activate(newsletterMarketingSend.id);
                await getNewsletterMarketingSend();
                trackEvent('Newsletter Status Changed', {
                  newsletterMarketingSend: {
                    ...newsletterMarketingSend,
                    status: 'active',
                  },
                });
              }
              : async () => {
                const newMarketingSend = {
                  shopId: shop.id,
                  type: 'newsletter',
                  templateId: 'E0tFpPyxOU3Mq0kSPYYc',
                  ...(sendGridConfig?.contactListId && { contactListId: sendGridConfig?.contactListId }),
                  status: 'active',
                  frequency: 'weekly',
                  artisanMessage: defaultMsg,
                  includedProductIds: products
                    .filter((product: Product) => !!product.imageObj?.downloadUrl)
                    .map((p: Product) => p.id),
                } as NewsletterMarketingSend;
                await marketingSendRepository.create(newMarketingSend);
                if (!shop.checklistCompletions?.includes(ChecklistValues.EmailMarketing)) {
                  dispatch(updateChecklistAction({ shop, items: [ChecklistValues.EmailMarketing] }));
                }
                trackEvent('Newsletter Created', { newsletterMarketingSend: newMarketingSend });
                await getNewsletterMarketingSend();
              },
            marketingSend: newsletterMarketingSend,
            loading: getNewsletterMarketingSendLoading,
          })}
          {generateEmailContainer({
            name: `New subscriber coupon${coupon
              ? ` - ${coupon.discount.type === 'amount'
                ? `${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')} off`
                : `${coupon.discount.value}% off`
              }`
              : ' - 10% off'
              }`,
            subheader:
              'Automatically send a coupon to new email subscribers when they sign up.',
            route: 'marketing/edit-new-subscriber-email',
            handleActivateClick: subscriberMarketingSend
              ? async () => {
                await marketingSendRepository.activate(subscriberMarketingSend.id);
                await getSubscriberMarketingSend();
                trackEvent('Newsletter Status Changed', {
                  newsletterMarketingSend: {
                    ...subscriberMarketingSend,
                    status: 'active',
                  },
                });
              }
              : async () => {
                const newCoupon = {
                  code: 'THANKYOU',
                  discount: {
                    type: 'percent' as 'percent',
                    value: 10,
                  },
                  duration: {
                    startDate: moment().unix(),
                  },
                  metrics: {
                    totalRevenue: 0,
                    totalUses: 0,
                  },
                  maximumPerCustomer: 1,
                  shopId: shop.id,
                  status: 'active',
                };
                const createCouponResponse = await couponRepository.create(newCoupon);
                if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
                  dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] }));
                }
                trackEvent('Coupon Created', { coupon: newCoupon });

                const newMarketingSend = {
                  shopId: shop.id,
                  type: 'subscriber_coupon',
                  templateId: 'bfihusWJ8zjV5rxbcQQB',
                  couponId: createCouponResponse.id,
                  status: 'active',
                  artisanMessage: defaultMsg,
                } as SubscriberCouponMarketingSend;
                await marketingSendRepository.create(newMarketingSend);
                if (!shop.checklistCompletions?.includes(ChecklistValues.EmailMarketing)) {
                  dispatch(updateChecklistAction({ shop, items: [ChecklistValues.EmailMarketing] }));
                }
                trackEvent('Subscriber Coupon Created', { newsletterMarketingSend: newMarketingSend });
                await getSubscriberMarketingSend();
              },
            marketingSend: subscriberMarketingSend,
            loading: getSubscriberMarketingSendLoading,
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Emails;
