import React, { useState } from 'react';
import { Box, Grid, IconButton, MenuItem, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Button, Input } from '@castiron/components';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import LaunchIcon from '@material-ui/icons/Launch';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useAppSelector } from '../../../hooks';
import { getService } from '../../../firebase';
import states from '../states';
import Tooltip from '../../Tooltip';
import InputLabel from '../../InputLabel';
import Spinner from '../../Spinner';

const exportSalesTaxService = getService('orders', 'exportsalestax', { type: 'request' });

const useStyles = makeStyles((theme: Theme) => ({
  inputsContainer: {
    padding: '12px 0px',
  },
  input: {
    marginTop: 0,
    marginBottom: -12,
  },
  launchIcon: {
    color: theme.branding.gray[600],
    marginLeft: 11,
    fontSize: 16,
  },
  taxContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
    margin: '24px 0',
  },
  taxDivider: {
    borderTop: `1px solid ${theme.branding.gray[300]}`,
  },
  taxHeader: {
    height: 64,
    padding: '0 24px',
  },
  taxBody: {
    margin: '12px 24px 15px 0px',
    padding: '0 24px',
  },
  taxReporting: {
    margin: '6px 0',
  },
  taxReportingContainer: {
    margin: '10px 0',
  },
  taxReportingButton: {
    paddingLeft: 14,
    paddingRight: 14,
  },
  downloadIcon: {
    marginRight: 5,
  },
}));

const TaxRate: React.FC = () => {
  const { setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const salesTaxCSVhandler = async () => {
    try {
      setIsSubmitting(true);
      const csv = await exportSalesTaxService({
        timeZone: shop?.config?.timeZone || defaultTimeZone,
      });
      const date = moment().format('MMDDYYYY');
      const filename = `${shop.websiteUrl}-tax-report-${date}.csv`;
      download(filename, 'text/csv', csv);
      trackEvent('Finances Tax Report Downloaded', {});
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.debug(err);
    }
  };

  return (
    <Grid container className={classes.taxContainer}>
      <Spinner show={isSubmitting} size={'fullscreen'} label="Gathering your sales tax data…" />
      <Grid container className={classes.taxHeader} justify="space-between" alignItems="center" xs={12}>
        <Grid item>
          <Typography variant="subtitle1">
            Tax Rate
            <Tooltip title="Make sure to research and understand the tax rate specific to you. State and local laws for cottage cooks are constantly changing and can be different county to county." />
          </Typography>
        </Grid>
        <Grid item>
          <a href="https://www.avalara.com/taxrates/en/download-tax-tables.html" target="_blank">
            <Grid container wrap="wrap" alignItems="center" xs={12}>
              <Typography variant="body2">Learn About Taxes</Typography>
              <LaunchIcon className={classes.launchIcon} />
            </Grid>
          </a>
        </Grid>
      </Grid>
      <Grid className={classes.taxDivider} xs={12}>
        <Grid container className={classes.taxBody} xs={12} spacing={2}>
          <Grid item xs={12} sm={6} className={classes.inputsContainer}>
            <Input
              id="sales-tax-input"
              label={<InputLabel containerClass={classes.input} primaryLabel="Tax Rate" />}
              name="taxRate"
              fullWidth
              endAdornment="%"
              onChange={e => {
                setFieldValue('taxRate', e.target.value.replace(/(\.\d{3})\d+/g, '$1'));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.inputsContainer}>
            <Input
              label={<InputLabel containerClass={classes.input} primaryLabel="State" />}
              name="state"
              select
              fullWidth
            >
              {states.map(state => {
                return (
                  <MenuItem key={state.name} value={state.name}>
                    {state.name}
                  </MenuItem>
                );
              })}
            </Input>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant="h4">Tax Reporting</Typography>
            <Grid item xs={12} className={classes.taxReporting}>
              <Typography variant="body1">
                Your download will include full historical order information for easier tax reporting.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.taxReportingContainer}>
              <Button variant="outlined" className={classes.taxReportingButton} onClick={salesTaxCSVhandler}>
                <span className={classes.downloadIcon}>
                  <GetAppIcon />
                </span>
                Download Tax Report CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaxRate;
