import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type LeftColumnProps = Override<GridProps, {}>;

// Left Column wrapper
const LeftColumn: React.FC<LeftColumnProps> = (props: LeftColumnProps) => {
  const { xs, children, ...restOfProps } = props;

  return (
    <Grid item xs={xs || 7} {...restOfProps}>
      {children}
    </Grid>
  );
};

export default LeftColumn;
