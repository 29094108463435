import React, {ReactNode} from "react";
import { useLocation } from "react-router";

interface Props {
  children: ReactNode;
}

const Flibble: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const location = useLocation<{flibble: string}>();
  const params = new URLSearchParams(location.search);
  const flibble = params.get('flibble');

  if (flibble === 'flobble') throw new Error('Flibble Flobble!!!!!');

  return <>{children}</>;
}

export default Flibble;
