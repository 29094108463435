import React, { ReactElement } from 'react';
import { IconButton, Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { SvgIcon } from '@castiron/components';
import InfoIcon from '../../assets/img/info.svg';
import clsx from "clsx";

type Props = {
  title?: string | ReactElement<any, any>;
  tooltipClass?: string;
  iconClass?: string;
  style?: React.CSSProperties;
  placement?:
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start';
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: 8
  },
  infoIcon: {
    color: theme.branding.gray[800],
    width: 16,
    height: 16,
    cursor: 'pointer'
  },
  tooltips: {
    fontSize: 12,
    lineHeight: 1.3,
  },
}));

const Tooltip: React.FC<Props> = (props: Props) => {
  const { title, tooltipClass, style, placement, iconClass } = props;
  const classes = useStyles();

  return (
    <MUITooltip
      style={style}
      className={`${classes.container} ${tooltipClass}`}
      title={<span className={classes.tooltips}>{title}</span>}
      placement={placement}
    >
      <IconButton>
        <SvgIcon className={clsx([classes.infoIcon, iconClass])}>
          <InfoOutlinedIcon />
        </SvgIcon>
      </IconButton>
    </MUITooltip>
  );
};

export default Tooltip;
