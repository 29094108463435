import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';

import CardImage1 from '../../assets/img/help-card-1.png';
import CardImage2 from '../../assets/img/help-card-2.png';
import CardImage3 from '../../assets/img/help-card-3.png';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    padding: 10,
    minHeight: 0,
    minWidth: 0,
  },
  container: {
    marginTop: 15,
  },
  cardContainer: {
    height: 360,
    [theme.breakpoints.down('md')]: {
      height: 370,
    },
    [theme.breakpoints.down('sm')]: {
      height: 360,
    },
    [theme.breakpoints.down('xs')]: {
      height: 335,
    },
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 14,
  },
  cardTextContainer: {
    padding: '0px 16px 10px 16px',
  },
  imageContainer: {
    height: 160,
    borderRadius: '14px 14px 0px 0px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
}));

const HelpCards: React.FC = () => {
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const trackChecklistClick = (ctaName) => {
    trackEvent('Setup Checklist Click', { description: ctaName });
  };

  const content = [
    {
      icon: CardImage1,
      title: 'Book Time With an Expert',
      body: 'Questions? Troubleshooting? Whatever you need, we can help.',
      buttonText: 'Book a Time',
      href: 'https://calendly.com/lindsay-at-castiron/30min',
    },
    {
      icon: CardImage2,
      title: 'Get Inspired By Others',
      body: 'See how similar owners like you are building their stores with Castiron.',
      buttonText: 'View Stores',
      href: 'https://castiron.me/example-shops',
    },
    {
      icon: CardImage3,
      title: 'Join Our Community',
      body: 'Grow your business alongside thousands of other passionate food entrepreneurs.',
      buttonText: 'Join The Kitchen',
      href: 'https://www.facebook.com/groups/thekitchencommunity',
    },
  ]


  return (
    <Grid container spacing={2} className={classes.container} xs={12}>
      <Grid item xs={12}>
        <Typography variant={isMobile ? 'h3' : 'h2'}>{isMobile ? 'Whatever You Need, We’re Here ❤️' : 'Whatever You Need, We’re Here For You ❤️'}</Typography>
      </Grid>
      {content.map(item =>
        <Grid key={item.title} item xs={12} sm={4} >
          <a href={item.href} onClick={() => trackChecklistClick(item.title)} target='_blank' style={{ textDecoration: 'none' }}>
            <Grid container justify='center' className={classes.cardContainer} xs={12}>
              <Grid container item className={classes.imageContainer} xs={12} style={{ backgroundImage: `url(${item.icon})` }} />
              <Grid item className={classes.cardTextContainer} xs={12}>
                <Typography variant='subtitle1'>{item.title}</Typography>
                <Typography variant='body2'>{item.body}</Typography>
              </Grid>
              <Grid container item alignItems='center' justify='center' className={classes.cardTextContainer} xs={12}>
                <Button variant='outlined' className={classes.button}>{item.buttonText}</Button>
              </Grid>
            </Grid>
          </a>
        </Grid>
      )
      }
    </Grid >
  );
};

export default HelpCards;
