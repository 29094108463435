import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Button, Typography, SaveButton, DiscardButton } from '@castiron/components';
import {
  backendStateToFrontendState,
  calculateTotals,
  Customer,
  enrichLineItemWithCustomProductInformation,
  FrontendTransactionState,
  LineItem,
  Order,
  SubLineItem,
  Transaction,
  updateOrderTotals,
  SubscriberOrigination,
  FulfillmentOption
} from '@castiron/domain';
import { removeEmpty, removeEmptyStrings, useTracking } from '@castiron/utils';
import { customerRepository, transactionRepository } from '../../../domain';
import { getService } from "../../../firebase";
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createCustomerAction, updateCustomerAction } from '../../../store/reducers/customers';
import { getProductsAction } from '../../../store/reducers/products';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import Spinner from '../../Spinner';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import QuoteActionsDropdown from '../QuoteActionsDropdown';
import { prepareQuoteSegmentData, quoteNextSteps, quoteSendErrors, validateQuote } from '../QuoteUtils';
import QuoteCustomer from './QuoteCustomer/QuoteCustomer';
import QuoteDetails from './QuoteDetails';
import QuoteFulfillment from './QuoteFulfillment';
import QuotePayment from './QuotePayment';
import QuoteRequestDetails from './QuoteRequestDetails';

const sendQuoteUpdatedService = getService('orders', 'sendQuoteUpdatedEmail');

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    display: 'inline',
    color: theme.branding.orange.primary,
  },
  errorContainer: {
    marginTop: '32px',
    marginBottom: '24px',
  },
  errorHeading: {
    fontWeight: 600,
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    padding: '18px 16px',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
  },
  mainContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
}));

export interface QuoteValues {
  order: Order;
  customer: Customer;
}

const orderSchema = yup.object().shape({
  order: yup.object().shape({
    orderNumber: yup.string(),
    type: yup.string(),
    items: yup.array<LineItem>().of(
      yup.object().shape({
        id: yup.string(),
        title: yup.string(),
        description: yup.string(),
        category: yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
        price: yup.number(),
        quantity: yup.number(),
        subLineItems: yup.array<SubLineItem>().of(
          yup.object().shape({
            id: yup.string().nullable(),
            title: yup.string().nullable(),
            price: yup.number().nullable(),
            quantity: yup.number().nullable(),
            notes: yup.string().nullable(),
          }),
        ),
        subtotal: yup.number(),
        total: yup.number(),
        type: yup.string(),
        policies: yup.string(),
        communityPackageId: yup.string(),
        notes: yup.string(),
      }),
    ),
    fulfillmentOption: yup.object().shape({
      status: yup.string().oneOf(['active', 'inactive']),
      type: yup.string(),
      displayName: yup.string(),
      description: yup.string(),
      id: yup.string(),
      afterPurchaseDetails: yup.string().nullable(),
      recipient: yup.string().nullable(),
      deliveryAddress: yup
        .object()
        .shape({
          fullAddress: yup.string().nullable(),
          addressLine1: yup.string().nullable(),
          addressLine2: yup.string().nullable(),
          city: yup.string().nullable(),
          region: yup.string().nullable(),
          postalCode: yup.string().nullable(),
          country: yup.string().nullable(),
        })
        .nullable(),
      fee: yup.number().nullable(),
      minimum: yup.number().nullable(),
      date: yup.number().nullable(),
    }),
    orderTotal: yup.number().nullable(),
    origination: yup.string().nullable(),
    paymentSentDuePeriod: yup
      .string()
      .oneOf(['24hours', '7days', 'none'])
      .nullable(),
    paymentDueDate: yup.number().nullable(),
  }),
  customer: yup.object().shape({
    email: yup.string().nullable(),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    mobileNumber: yup.string().nullable(),
    notes: yup.string().nullable(),
    shopId: yup.string().nullable(),
    subscribed: yup.boolean().nullable(),
  }),
});

const EditQuote: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [order, setOrder] = useState<Order>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [transaction, setTransaction] = useState<Transaction>();
  const [transactionState, setTransactionState] = useState<FrontendTransactionState>();
  const [transactionChanged, setTransactionChanged] = useState(false);
  const [showSavingSpinner, setShowSavingSpinner] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef<FormikProps<QuoteValues>>();
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const errorCode = query.get('error');

  const { trackEvent } = useTracking();

  const { shop, isProductsLoading, customers, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    isProductsLoading: state.products.loading,
    customers: state.customers.customers,
    products: state.products.products,
  }));

  const initialDummyFulfillmentKey = '--Initial--';
  const initialDummyCustomerKey = '--Initial--';
  const initialPopulatedAddress =
    (transaction?.shippingInfo?.address?.fullAddress && transaction.shippingInfo.address) ||
    (transaction?.order?.fulfillmentOption?.address?.fullAddress && transaction.order.fulfillmentOption.address) ||
    (transaction?.order?.requestedFulfillment?.address?.fullAddress && transaction.order.requestedFulfillment.address);
  const initialValues: QuoteValues = {
    order: {
      orderNumber: transaction?.order.orderNumber || 'unknown',
      stage: 'quote',
      type: 'custom',
      items: [
        {
          id: transaction?.order.items[0]?.id || '',
          title: transaction?.order.items[0]?.title || '',
          description: transaction?.order.items[0]?.description || '',
          category: {
            id: transaction?.order.items[0]?.category.id || '',
            name: transaction?.order.items[0]?.category.name || '',
          },
          price: transaction?.order.items[0]?.price || 0,
          quantity: transaction?.order.items[0]?.quantity || 1,
          subLineItems: transaction?.order.items[0]?.subLineItems || [],
          subtotal: transaction?.order.items[0]?.subtotal || 0,
          total: transaction?.order.items[0]?.total || 0,
          type: transaction?.order.items[0]?.type || 'custom',
          policies: transaction?.order.items[0]?.policies || '',
          communityPackageId: transaction?.order.items[0]?.communityPackageId || '',
          notes: transaction?.order.items[0]?.notes || '',
        },
      ],
      fulfillmentOption: {
        status: transaction?.order.fulfillmentOption?.status || 'active',
        type: transaction?.order.fulfillmentOption?.type || 'delivery',
        displayName: transaction?.order.fulfillmentOption?.displayName || initialDummyFulfillmentKey,
        description: transaction?.order.fulfillmentOption?.description || '',
        id: transaction?.order.fulfillmentOption?.id || nanoid(),
        afterPurchaseDetails: transaction?.order.fulfillmentOption?.afterPurchaseDetails || '',
        recipient: transaction?.shippingInfo?.recipientName,
        address: initialPopulatedAddress || {
          fullAddress: transaction?.shippingInfo?.address?.fullAddress || '',
          addressLine1: transaction?.shippingInfo?.address?.addressLine1 || '',
          addressLine2: transaction?.shippingInfo?.address?.addressLine2 || '',
          city: transaction?.shippingInfo?.address?.city || '',
          region: transaction?.shippingInfo?.address?.region || '',
          postalCode: transaction?.shippingInfo?.address?.postalCode || '',
          country: transaction?.shippingInfo?.address?.country || '',
        },
        fee: transaction?.order.fulfillmentOption?.fee || 0,
        minimum: transaction?.order.fulfillmentOption?.minimum || 0,
        notes: transaction?.order.fulfillmentOption?.notes || '',
        //@ts-ignore
        date: transaction?.order.fulfillmentOption?.date || null,
        schedule: transaction?.order.fulfillmentOption?.schedule || null,
      },
      orderTotal: transaction?.order.orderTotal || 0,
      origination: transaction?.order.origination || '',
      paymentSentDuePeriod: transaction?.order.paymentSentDuePeriod || '24hours',
      paymentDueDate: transaction?.order.paymentDueDate || null,
      requestedFulfillment: {
        address: transaction?.order.requestedFulfillment?.address || null,
        type: transaction?.order.requestedFulfillment?.type || null,
        date: transaction?.order.requestedFulfillment?.date || null,
      }
    },
    customer: {
      email: transaction?.customerObj?.email || initialDummyCustomerKey,
      firstName: transaction?.customerObj?.firstName || '',
      id: transaction?.customerObj?.id || undefined,
      lastName: transaction?.customerObj?.lastName || '',
      mobileNumber: transaction?.customerObj?.mobileNumber || '',
      notes: transaction?.customerObj?.notes || '',
      shopId: shop.id,
      subscribed: transaction?.customerObj?.subscribed || false,
    },
  };

  const getOrder = async id => {
    const response = await transactionRepository.get(id);
    if (response == null) history.push('/quotes');
    if (!initialTransaction.current) {
      initialTransaction.current = response;
    }
    setTransaction(response);
    setOrder(response.order);
    setPageTitle(`Quote ${response.order.orderNumber}`);
    setBackLocation(true);
  };

  const { id } = useParams<{ id: string }>();
  const initialTransaction = useRef<Transaction>();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder(id);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);


  useEffect(() => {
    if (transaction) {
      setIsPaid(transaction.transactionStatus === 'succeeded');
      setTransactionState(backendStateToFrontendState(transaction, 'quote'));
    }
    if (!_.isEqual(initialTransaction.current, transaction)) {
      setTransactionChanged(true);
    }
    setHeaderCTAs([
      <QuoteActionsDropdown
        transaction={transaction}
        formikRef={formikRef}
        editing
      />
    ]);
  }, [transaction]);

  useEffect(() => {
    !isPaid && (transactionState !== 'canceled' && transactionState !== 'archived' && transactionState !== 'rejected') ? setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation='/quotes' />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} customName={transactionState === 'pending' ? 'Save and Send' : 'Save'} />
    ]) : setFooterCTAs([])
  }, [transactionState, submitting])

  useEffect(() => {
    const getProducts = async id => {
      dispatch(getProductsAction(id));
    };

    if (shop?.id) {
      getProducts(shop.id);
    }
  }, [shop]);



  const handleSubmit = (sendUpdateEmailAfterSave: boolean) => async (values: QuoteValues) => {
    setSubmitting(true);
    if (
      (values.customer.firstName ||
        values.customer.lastName ||
        values.customer.mobileNumber ||
        values.customer.notes ||
        values.customer.subscribed) &&
      (!values.customer.email || values.customer.email === initialDummyCustomerKey)
    ) {
      history.push(`/quotes/edit/${transaction.id}?error=missingEmail`);
      window.scrollTo(0, 0);
      return;
    }

    let updatedCustomer: Customer;

    if (values.customer.email && values.customer.email != initialDummyCustomerKey) {
      setShowSavingSpinner(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });

      const newCustomerData = removeEmpty(removeEmptyStrings({
        ...values.customer,
        name: undefined,
        existingCustomers: undefined,
        subscriberOrigination: values.customer.subscribed ? 'admin-update-customer-form' as SubscriberOrigination : undefined
      }));

      const response = values.customer.id ? await dispatch(updateCustomerAction(newCustomerData)) : await dispatch(createCustomerAction(newCustomerData as Customer));
      updatedCustomer = await customerRepository.get(values.customer.id ? values.customer.id : response.payload['id']);

      if (values.customer.id) {
        trackEvent(`Customer Updated`, {
          action: 'update',
          url: window.location.href,
          customer: response.payload,
          shopId: shop.id,
        });
      }

      if (!values.order.fulfillmentOption.recipient && updatedCustomer?.firstName) {
        // if we don't have a fulfillment recipient, set it while we're updating customer
        values.order.fulfillmentOption.recipient = updatedCustomer.firstName + (updatedCustomer.lastName ? ` ${updatedCustomer.lastName}` : '');
      }
    }

    try {
      /* HACK: for some reason js is not letting us remove attribution or any properties of attribution, causing errors in removeEmpty below
       * just omit it out here for now
       */
      const unsafeCustomerObj = updatedCustomer
        ? updatedCustomer
        : (values.customer.email === initialDummyCustomerKey)
          ? undefined
          : customers.find(cust => cust.id === values.customer.id)

      const customerObj = _.omit(unsafeCustomerObj, 'attribution');
      /* transaction in state isn't updated when the formik form is updated so compare the orders directly */
      const newTransaction = {
        ...transaction,
        order: {
          ...values.order,
        },
        customerObj
      };

      if (transactionChanged || !_.isEqual(initialTransaction.current.order, values.order) || !_.isEqual(initialTransaction.current.customerObj, updatedCustomer)) {
        if (!showSavingSpinner) setShowSavingSpinner(true);
        trackEvent('Quote Action Clicked', {
          action: 'save',
          url: window.location.href,
          ...prepareQuoteSegmentData(newTransaction),
        });
        const previousStatus = backendStateToFrontendState(initialTransaction.current, 'quote');
        const newStatus = previousStatus === 'pending' ? 'agreed' : 'proposed';
        const newFrontendStatus = newStatus === 'agreed' ? 'pending' : 'draft';

        const formItem = values.order.items[0];
        const txItem = transaction.order?.items[0] || {};
        const combinedItem = { ...txItem, ...formItem };
        const enrichedLineItem = enrichLineItemWithCustomProductInformation(combinedItem, products);

        /* forgive me this sin, best way I can come up with to avoid saving the default data that isn't displayed to the user */
        const fulfillmentOption: FulfillmentOption = values.order.fulfillmentOption?.displayName === initialDummyFulfillmentKey ? undefined : {
          ...values.order.fulfillmentOption,
          /* since none of these are actual fulfillment options from the subcollection,
           * just set the status to 'active' always
           * this will help with transition from array property to subcollection
           */
          status: 'active',
          address: undefined,
        };
        const order = {
          ...values.order,
          items: [enrichedLineItem],
          fulfillmentOption,
        };

        const totals = calculateTotals({ order, paymentSettings: shop.paymentSettings })
        const customerName = customerObj?.firstName ? `${customerObj.firstName} ${customerObj.lastName}` : undefined;

        const updatedOrder = updateOrderTotals(order);

        const txUpdateProps = removeEmpty({
          order: updatedOrder,
          shippingInfo: {
            recipientName: (values.order.fulfillmentOption.recipient) ? values.order.fulfillmentOption.recipient : customerName,
            address: values.order.fulfillmentOption.address?.fullAddress ? values.order.fulfillmentOption.address : undefined,
          },
          totals,
          status: newStatus,
          customer: customerObj ? customerObj.id : '',
          customerObj,
        });

        await transactionRepository.updateProps(transaction.id, txUpdateProps);

        if (previousStatus !== newFrontendStatus) {
          trackEvent('Quote Status Changed', {
            url: window.location.href,
            previousStatus,
            newStatus: newFrontendStatus,
            ...prepareQuoteSegmentData(newTransaction),
          });
        }

        if (initialTransaction?.current?.customerObj && !customerObj.id) {
          await transactionRepository.deleteFields(transaction.id, ['customer', 'customerObj']);
        }

        if (sendUpdateEmailAfterSave) {
          if (validateQuote(transaction).length > 0) {
            history.push(`/quotes/edit/${transaction.id}?error=missingRequired`);
            window.scrollTo(0, 0);
            return;
          }
          const subtotal = totals?.subtotal || 0;
          if (subtotal < 50) {
            history.push(`/quotes/edit/${transaction.id}?error=illegalTotal`);
            window.scrollTo(0, 0);
            return;
          }

          await sendQuoteUpdatedService({ transactionId: transaction.id });
        }
        setShowSavingSpinner(false);
      }

      setSubmitting(false);
      history.push('/quotes');
    } catch (error) {
      console.error(error);
      setShowSavingSpinner(false);
      setSubmitting(false);
    }
  };


  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Spinner show={isProductsLoading || showSavingSpinner} />
      <Formik
        initialValues={initialValues}
        validationSchema={orderSchema}
        onSubmit={handleSubmit(transactionState === 'pending')}
        enableReinitialize
        innerRef={formikRef}
        validateOnMount={true}
      >
        {(formikProps: FormikProps<QuoteValues>) => (
          <AdminForm>
            {
              !formikProps.isSubmitting &&
              <UnsavedChangesPrompt when={formikProps.dirty && !isPaid && transactionState !== 'canceled'} allowSamePage />
            }
            {errorCode && (
              <Grid item className={classes.errorContainer}>
                <Typography className={`${classes.error} ${classes.errorHeading}`}>Error:&nbsp;</Typography>
                <Typography className={classes.error}>
                  {quoteSendErrors[errorCode]} {errorCode === 'missingRequired' && validateQuote(transaction).join(', ')}
                </Typography>
              </Grid>
            )}
            {!isMobile ? (
              <Grid container item justify="space-between" spacing={2}>
                <Grid container item direction="column" xs={6}>
                  <QuoteDetails order={order} transaction={transaction} isPaid={isPaid} />
                  {order?.items[0]?.selections?.length > 0 && <QuoteRequestDetails order={order} />}
                  {!order?.items[0]?.selections && <QuotePayment order={order} isPaid={isPaid} />}
                </Grid>
                <Grid container item direction="column" xs={6}>
                  <QuoteCustomer transaction={transaction} />
                  <QuoteFulfillment order={order} shippingInfo={transaction?.shippingInfo} isPaid={isPaid} state={transactionState} />
                  {order?.items[0]?.selections && <QuotePayment order={order} isPaid={isPaid} />}
                </Grid>
              </Grid>
            ) : (
              <Grid container item direction="column">
                <QuoteDetails order={order} transaction={transaction} isPaid={isPaid} />
                {order?.items[0]?.selections?.length > 0 && <QuoteRequestDetails order={order} />}
                <QuoteCustomer transaction={transaction} />
                <QuoteFulfillment order={order} shippingInfo={transaction?.shippingInfo} isPaid={isPaid} state={transactionState} />
                <QuotePayment order={order} isPaid={isPaid} />
              </Grid>
            )}
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default EditQuote;
