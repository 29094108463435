import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Spinner from '../../Spinner';
import { Button, Forms } from '@castiron/components';
import { Box, Grid } from '@material-ui/core';
import { loginWithPassword } from '@castiron/castiron-firebase';
import setDomainAuth from "../domainAuth";

const { Input, SubmissionError } = Forms;

type Props = {};

const schema = yup.object().shape({
  email: yup.string().email(),
  password: yup
    .string()
    .required()
    .min(8)
    .max(128),
});

const LoginForm: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line
  const onSubmit = async (values: any, { setSubmitting, setFieldError }: any): Promise<any> => {
    try {
      const result = await loginWithPassword(values.email, values.password);
      await setDomainAuth();
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setFieldError('general', e.message);
    }
  };

  return (
    <Formik initialValues={ { email: '', password: '' } } validationSchema={ schema } onSubmit={ onSubmit }>
      { ({ isSubmitting, errors, touched }): ReactNode => (
        <>
          <Spinner show={ isSubmitting } size={ 'fullscreen' }/>
          <>
            { errors?.general && <SubmissionError msg={ errors.general }/> }
            <Form noValidate>
              <Grid container spacing={ 1 }>
                <Grid item xs={ 12 }>
                  <Input type="email" name="email" error={ errors.email || '' } touched={ touched.email }
                         label="Email"/>
                </Grid>
                <Grid item xs={ 12 }>
                  <Input type="password" name="password" error={ errors.password || '' } touched={ touched.password }/>
                </Grid>
                <Grid container item justify="center">
                  <Box my={ 3 }>
                    <Button variant="contained" type="submit" disabled={ isSubmitting }>
                      LOG IN
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </>
        </>
      ) }
    </Formik>
  );
};

export default LoginForm;
