import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Taxonomy } from '@castiron/domain';
import axios from 'axios';
import { handleReducerError } from './errorHandler';
import { getCustomersAction } from './customers';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

export interface TaxonomyState {
  taxonomy: Taxonomy;
}

const initialState: TaxonomyState = {
  taxonomy: undefined,
};

const getTaxonomy = async (): Promise<Taxonomy> => {
  const resp = await axios.get(`${ADMIN_URL}assets/data/artisan-taxonomy.json`, {
    responseType: 'json',
  });
  return resp.data as Taxonomy;
};

export const getTaxonomyAction = createAsyncThunk('taxonomy/getTaxonomy', getTaxonomy);

const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTaxonomyAction.fulfilled, (state, action) => {
        state.taxonomy = action.payload;
      })
      .addCase(getTaxonomyAction.rejected, handleReducerError('Error Getting Taxonomy'));
  },
});

export default taxonomySlice.reducer;
