import _ from 'lodash';
import { backendStateToFrontendState, Transaction } from '.';
import { OrderSearchResult } from '../order';

export const transactionToEventModel = (tx: Transaction) => ({
  id: tx.id,
  status: tx.status,
  transactionStatus: tx.transactionStatus,
  order: {
    orderNumber: tx.order.orderNumber,
    fulfillmentOption:
      (tx.order.fulfillmentOption && {
        date: tx.order.fulfillmentOption?.date,
      }) ||
      null,
  },
});

export const toOrderSearchResult = (tx: Transaction): OrderSearchResult => ({
  id: tx.id,
  orderNumber: tx.order?.orderNumber,
  status: tx.status,
  frontendStatus: tx.frontendState(),
  stage: tx.order?.stage,
  transactionStatus: tx.transactionStatus,
  createdAt: tx.createdAt,
  customerName: `${tx.customerObj.firstName} ${tx.customerObj.lastName}`,
  type: tx.order?.type,
  fulfillmentOption: {
    id: tx.order?.fulfillmentOption?.id,
    type: tx.order?.fulfillmentOption?.type,
    displayName: tx.order?.fulfillmentOption?.displayName,
    date: tx.order?.fulfillmentOption.date,
  },
  total: tx.totals?.total,
  numItems: _.reduce(tx.order?.items, (x, i) => i.quantity + x, 0),
});
