import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProps } from 'formik';
import { Button } from '../';


type Props = {
  isSubmitting: boolean;
  backLocation?: string;
  fromChecklist?: string;
  onClick?: () => void;
  className?: string;
};


const DiscardButton: React.FC<Props> = (props: Props) => {
  const { backLocation, className, isSubmitting, fromChecklist, onClick } = props;

  const history = useHistory();

  const click = () => {
    if (onClick) {
      return onClick
    } else {
      return () => {
        !fromChecklist ? history.push(backLocation) : history.push('/')
      }
    }
  }

  return (
    <Button
      variant='outlined'
      disabled={isSubmitting}
      onClick={click()}
      className={className}
    >
      Discard
    </Button>
  );
};

export default DiscardButton;
