import { ExperimentConfig } from '@castiron/components';

/**
 * Experiment Configuration
 *
 * Should be an array of objects in this format:
 * {
 *   name: Descriptive name of the Experiment,
 *   id: process.env.Env var containing the Optimize Experiment ID for this environment,
 *   variants: ['Array of descriptive variant names in the same order as they are listed in Optimize']
 * }
 *
 */
const experiments: ExperimentConfig[] = [];

export default experiments;
