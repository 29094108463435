import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Button, ButtonBase, Dialog, DialogContent, Grid, Popover, PopoverOrigin, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Close, HelpOutline, Link, MoreVert, Settings, VisibilityOutlined } from '@material-ui/icons';
import { LogoutOutlinedIcon, Typography } from '@castiron/components';
import { toQueryString, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createOpenShopButtonClickedEvent } from '../../lib/events/commonEvents';
import { signOutAction } from '../../store/reducers/users';

interface Props {
  variant?: 'small' | 'large';
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const useStyles = makeStyles((theme: Theme) => ({
  accountMenu: {
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[200]}`,
    padding: '16px 8px 19px 16px',
  },
  avatarLarge: {
    border: `1px solid ${theme.branding.gray[400]}`,
    background: theme.branding.blue.primary,
    height: '36px',
    width: '36px',
    fontSize: '16px',
    '& img': {
      borderRadius: '50%',
    },
  },
  avatarSmall: {
    border: `1px solid ${theme.branding.gray[400]}`,
    background: theme.branding.blue.primary,
    height: '36px',
    width: '36px',
    fontSize: '16px',
  },
  businessName: {
    //there's not really a good typography option for this currently, so I have to adjust it manually. it will be caption2 after the design overhaul, though
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.branding.gray[700],
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        borderRadius: '16px'
      },
    },
  },
  displayName: {
    textOverflow: 'ellipsis',
    wrap: 'nowrap',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  helpIcon: {
    color: theme.branding.gray[600],
    height: 14,
    width: 14,
    marginLeft: '8px'
  },
  launchDialogCloseButton: {
    height: 42,
    width: 42,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: '13px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '456px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px 4px 16px',
      width: '100%',
    },
  },
  linksContent: {
    padding: '24px'
  },
  linksTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 12px 14px 24px',
    borderBottom: `1px solid ${theme.branding.gray[300]}`
  },
  menuActive: {
    backgroundColor: theme.branding.gray[200]
  },
  menuOption: {
    '& span': {
      color: theme.branding.gray[800]
    }
  },
  moreIcon: {
    margin: '8px',
    color: theme.branding.gray[600]
  },
  moreIconActive: {
    color: theme.branding.gray[800]
  },
  shareShopLinkButton: {
    padding: '16px',
    minWidth: '80px',
    borderRadius: '12px',
    backgroundColor: theme.branding.blue.primary,
    color: theme.branding.gray[100],
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('sm')]: {
      padding: '12px 16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '12px 13px',
    },
  },
  shopLink: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  signOutButton: {
    marginLeft: 'auto',
  },
  storeName: {
    whiteSpace: 'nowrap',
    marginRight: 10,
    maxWidth: '80%',
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.text.secondary,
    position: 'relative',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  tooltip: {
    padding: 16,
    borderRadius: 12
  },
  userActions: {
    display: 'flex',
    alignItems: 'center',
  },
  userActionsButton: {
    padding: 0,
    bottom: 0,
  },
  userActionsPopup: {
    '& .MuiPopover-paper': {
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiButton-sizeLarge': {
        border: `2px solid ${theme.branding.gray[100]}`,
        width: '100%',
        justifyContent: 'flex-start',
      },
    },
  },
  userName: {
    margin: '0px 8px',
    textAlign: 'left'
  },
}));

const AvatarMenu: React.FC<Props> = (props: Props) => {
  const {
    variant = 'small',
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showLinkShareDialog, setShowLinkShareDialog] = useState(false);
  const [isShopLinkCopied, setIsShopLinkCopied] = useState(false);
  const [isSignupLinkCopied, setIsSignupLinkCopied] = useState(false);

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const splittedDisplayName = me.displayName?.split(' ');
  const initials = splittedDisplayName?.slice(0, 2).map((name) => name.length > 0 ? name.charAt(0) : '').join('');

  const shopLink = `${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewShopClick = () => {
    trackEvent('Open Shop Button Clicked', createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}?${toQueryString(queryParams)}`, '_blank');
    setAnchorEl(null);
  }

  const handleAccountClick = () => {
    history.push('/account-settings');
    setAnchorEl(null);
  };

  const handleShareLinksClick = () => {
    setShowLinkShareDialog(true);
    setAnchorEl(null);
  };

  const handleSignOutClick = (): void => {
    dispatch(signOutAction('/'));
  };

  const handleCopyShopLink = async () => {
    navigator.clipboard.writeText(shopLink);
    setIsShopLinkCopied(true);
    setTimeout(() => {
      setIsShopLinkCopied(false);
    }, 2000);
  };

  const handleCopySignupLink = async () => {
    navigator.clipboard.writeText(`${shopLink}/signup`);
    setIsSignupLinkCopied(true);
    setTimeout(() => {
      setIsSignupLinkCopied(false);
    }, 2000);
  };

  const accountMenuOptions = [
    {
      text: `${shop.status === 'active' ? 'V' : 'Prev'}iew My Shop`,
      icon: <VisibilityOutlined />,
      action: handleViewShopClick,
      class: null
    },
    {
      text: 'Account Settings',
      icon: <Settings />,
      action: handleAccountClick,
      class: null
    },
    {
      text: 'Share Links',
      icon: <Link />,
      action: handleShareLinksClick,
      class: null
    },
    {
      text: 'Sign Out',
      icon: <LogoutOutlinedIcon />,
      action: handleSignOutClick,
      class: classes.signOutButton
    },
  ];

  return (
    <>
      {
        variant == 'small' &&
        <ButtonBase onClick={handleClick}>
          <Avatar src={me.profileImg || undefined} className={classes.avatarSmall}>
            {!me.profileImg && initials}
          </Avatar>
        </ButtonBase>
      }
      {
        variant == 'large' &&
        <Button onClick={handleClick} className={classes.userActionsButton} disableRipple>
          <Grid className={`${anchorEl && classes.menuActive} ${classes.accountMenu}`}>
            <Grid className={classes.userActions} container alignItems='center' direction='row' wrap='nowrap'>
              <Grid container item wrap='nowrap' direction='row'>
                <Avatar src={me.profileImg || undefined} className={classes.avatarLarge}>
                  {!me.profileImg && initials?.toUpperCase()}
                </Avatar>
                <Grid container justify='center' alignItems='flex-start' direction='column' className={classes.userName}>
                  <Typography variant='button' className={classes.displayName}>{me.displayName}</Typography>
                  <Typography variant='caption2' className={classes.businessName}>{shop?.businessName}</Typography>
                </Grid>
              </Grid>
              <MoreVert className={`${classes.moreIcon} ${anchorEl && classes.moreIconActive}`} />
            </Grid>
          </Grid>
        </Button>
      }
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.userActionsPopup}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {accountMenuOptions?.map((option, index) => {
          return (
            <Button key={index} onClick={option.action} className={`${classes.menuOption} ${option.class}`} startIcon={option.icon}>
              <Typography variant='subtitle2'>{option.text}</Typography>
            </Button>
          );
        })}
      </Popover>
      <Dialog
        fullScreen={isMobile}
        open={showLinkShareDialog}
        onClose={() => setShowLinkShareDialog(false)}
        className={classes.dialog}
      >
        <DialogContent style={{ padding: 0 }}>
          <Grid className={classes.linksTitle}>
            <Typography variant='h2'>Shop Links</Typography>
            <Close className={classes.launchDialogCloseButton} onClick={() => setShowLinkShareDialog(false)} />
          </Grid>
          <Grid className={classes.linksContent}>
            <Grid container justify='flex-start' alignItems='center'>
              <Typography variant='subtitle2'>My Shop Link </Typography>
              <Tooltip
                PopperProps={{
                  style: {
                    marginTop: '-12px',
                  }
                }}
                classes={{
                  tooltip: classes.tooltip
                }}
                title={
                  <Typography variant='body2' style={{ color: theme.branding.gray[100] }}>This link navigates directly to your main shop page.</Typography>
                }
              >
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </Grid>
            <Grid className={classes.linkContainer}>
              <Typography variant='body2' className={classes.shopLink}>{shopLink}</Typography>
              <Button variant='contained' className={classes.shareShopLinkButton} onClick={handleCopyShopLink}>{isShopLinkCopied ? 'Link Copied!' : 'Copy Link'}</Button>
            </Grid>
            <Grid container justify='flex-start' alignItems='center' style={{ marginTop: 24 }}>
              <Typography variant='subtitle2'>My Email Signup Link </Typography>
              <Tooltip
                PopperProps={{
                  style: {
                    marginTop: '-12px',
                  }
                }}
                classes={{
                  tooltip: classes.tooltip
                }}
                title={
                  <Typography variant='body2' style={{ color: theme.branding.gray[100] }}>This link navigates customers to a page where they can join your email list.</Typography>
                }
              >
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </Grid>
            <Grid className={classes.linkContainer}>
              <Typography variant='body2' className={classes.shopLink}>{shopLink}/signup</Typography>
              <Button variant='contained' className={classes.shareShopLinkButton} onClick={handleCopySignupLink}>{isSignupLinkCopied ? 'Link Copied!' : 'Copy Link'}</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AvatarMenu;