import * as yup from 'yup';
import { idField } from '../base/model';
import { BaseDocument } from '../base/repository';

export interface TimePeriod {
  id: string;
  startTime: number;
  endTime: number;
};
export const timePeriodSchema = yup.object({
  id: idField.required(),
  startTime: yup.number().positive().required(),
  endTime: yup.number().moreThan(yup.ref('startTime')).required(),
});

export interface FulfillmentOptionSchedule {
  id: string;
  type: 'flexible' | 'fixed';
  dates?: TimePeriod[];
  description?: string;
};
export const fulfillmentScheduleSchema = yup.object({
  id: idField,
  type: yup.string().oneOf(['flexible', 'fixed']).required(),
  dates: yup.array(timePeriodSchema),
  description: yup.string(),
});

export type CalendarEventType = 'availability';
/* available exists, but will not actually ever be stored in the db */
export type AvailabilitySubtype = 'available' | 'limited' | 'unavailable';
export interface CalendarEvent extends BaseDocument<CalendarEvent> {
  type: CalendarEventType;
  subtype: AvailabilitySubtype;
  startTime: number;
  endTime: number;
  title?: string;
  description?: string;
}

/* SEO, property naming reflects structured data props */
export interface ShopSeoLocation {
  /* basically the city */
  addressLocality: string;
  /* state abbreviation */
  addressRegion: string;
  addressCountry: string;
  postalCode: string;
};

export interface ShopSeoMetadata {
  manuallyUpdated?: boolean;
  address: ShopSeoLocation;
  shopTitle: string;
  shopDescription: string;
  socialImage: string;
  socialPageTitle: string;
  socialPageDescription: string;
};