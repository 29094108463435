import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Typography from '../Typography';
import SvgIcon from '../SvgIcon';
import InfoIcon from '../assets/info.svg';

type Props = {
  content: string;
  infoIcon?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  afterPurchaseDetailsContainer: {
    backgroundColor: theme.palette.grey[100],
    padding: 16,
    borderRadius: 8,
  },
  infoIcon: {
    color: theme.branding.blue.primary,
    width: 37,
    height: 37,
  },
  text: {
    whiteSpace: 'pre-line',
    position: 'relative',
  },
}));

const AfterPurchaseDetails: React.FC<Props> = (props: Props) => {
  const { content, infoIcon } = props;
  const classes = useStyles();

  if (!content) return null;

  return (
    <Grid className={classes.afterPurchaseDetailsContainer}>
      <Typography variant='body1' className={classes.text}>
        {infoIcon && (
          <SvgIcon className={classes.infoIcon}>
            <InfoIcon />
          </SvgIcon>
        )}
        {content}
      </Typography>
    </Grid>
  );
};

export default AfterPurchaseDetails;
