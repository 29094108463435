import React from 'react';
import {
  ButtonBase,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { CommentBubbleIcon, HandHoldingMoneyIcon, SvgIcon, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { productTemplateRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { useHistory } from 'react-router';
import ModalWrapper from '../../RootModal/ModalWrapper';

export interface Props {
  createStandardProductLink: string;
  createCustomProductLink: string;
  modalOpen: boolean;
}

const xxs = 300

// Legacy categories include:
// - Other Baked Goods
const DESSERT_SUBCATS = ['Sweet Pastries', 'Other Baked Goods', 'Cakes', 'Cupcakes', 'Cookies', 'Other Desserts', 'Desserts & Baked Goods'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '140px',
  },
  buttonLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  buttonBase: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '4px',
    margin: 8,
    width: '425px',
    height: '328px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: theme.branding.gray[100],
      border: `1px solid ${theme.branding.blue.primary}`
    },
    [theme.breakpoints.down('sm')]: {
      height: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '328px',
      width: '343px',
    },
    [theme.breakpoints.down(xxs)]: {
      width: 270,
    }
  },
  buttonContent: {
    textAlign: 'left',
    marginBottom: 8,
    lineHeight: '3em',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.75em',
    },
    [theme.breakpoints.down('xs')]: {
      lineHeight: '3em',
    },
  },
  buttonContentWrapper: {
    padding: 24,
    maxWidth: '23em',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonsWrapper: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column',
      '& > button:first-child': {
        marginBottom: 8,
      },
      '& > button:not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  icon: {
    height: 36,
    width: 'auto',
  },
  instantCheckout: {
    color: theme.branding.green.dark,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  customIcon: {
    color: theme.branding.blue.dark,
  },
  customHeaderWrapper: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: '3px 3px 0px 0px',
    padding: '1.5em',
    maxHeight: 114,
    width: '100%',
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0',
      marginTop: 16
    }
  },
  menuLabel: {
    marginBottom: 8,
    fontWeight: 700,
    fontSize: 16,
    marginTop: 20
  },
  modalWrapper: {
    padding: '26px 48px 54px 48px',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    }
  },
  quoteRequest: {
    color: theme.branding.blue.dark,
  },
  seeExamples: {
    color: theme.branding.blue.primary,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  standardIcon: {
    color: theme.branding.green.dark,
  },
  standardHeaderWrapper: {
    backgroundColor: theme.branding.green.light,
    borderRadius: '3px 3px 0px 0px',
    padding: '1.5em',
    maxHeight: 114,
    width: '100%',
  },
  title: {
    marginBottom: '0.5em',
  },
}));

const AddProductModal: React.FC<Props> = (props: Props) => {
  const { createCustomProductLink, createStandardProductLink, modalOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop
  }))

  const showProductTemplates = shop?.artisanCategory?.subcategories?.some(subcat => DESSERT_SUBCATS.includes(subcat));

  const getProductTemplates = async () => {
    const productTemplates = await productTemplateRepository.findActiveByCategory('desserts');
    return productTemplates;
  }

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleStandard = () => {
    trackEvent('Standard Product Button Pressed', { shopId: shop.id });
    history.push(createStandardProductLink); //'/products/add/standard'
    dispatch(closeModal());
  };

  const handleCustom = async () => {
    const productTemplates = await getProductTemplates();
    trackEvent('Custom Product Button Pressed', { shopId: shop.id });
    dispatch(closeModal());
    if (showProductTemplates) {
      dispatch(
        openModal({
          modalType: 'SELECT_PRODUCT_TYPE_MODAL',
          modalProps: {
            modalOpen: true,
            productTemplates: productTemplates,
          },
        }),
      );
    } else {
      history.push(createCustomProductLink);
    }
  };

  return (
    <ModalWrapper fullScreen={isMobile} size='md' show={modalOpen}>
      <Grid className={classes.modalWrapper}>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.header}>
          <Typography variant='h2' className={classes.title} >Which option best matches the product you want to add?</Typography>
          <Typography variant='body3'> Not sure what the difference is? <span className={classes.seeExamples}>See examples</span></Typography>
        </DialogTitle>
        <DialogActions className={classes.buttonsWrapper}>
          <ButtonBase focusRipple className={classes.buttonBase} onClick={handleStandard}>
            <div className={classes.standardHeaderWrapper}>
              <SvgIcon className={classes.standardIcon}>
                <HandHoldingMoneyIcon />
              </SvgIcon>
              <Typography className={`${classes.menuLabel} ${classes.instantCheckout}`} id="product-modal-instance-checkout-text">Instant Checkout</Typography>
            </div>
            <div className={classes.buttonContentWrapper}>
              <div className={classes.buttonContent}>
                <Typography variant='body2'>Perfect for products that: </Typography>
                <ul>
                  <li><Typography variant='body2'>Have standard pricing</Typography></li>
                  <li><Typography variant='body2'>Are mostly the same for all customers</Typography></li>
                  <li><Typography variant='body2'>Don't require a manual review</Typography></li>
                </ul>
              </div>
            </div>
          </ButtonBase>
          <ButtonBase focusRipple className={classes.buttonBase} onClick={handleCustom}>
            <div className={classes.customHeaderWrapper}>
              <SvgIcon className={classes.customIcon}>
                <CommentBubbleIcon />
              </SvgIcon>
              <Typography className={`${classes.menuLabel} ${classes.quoteRequest}`} id="product-modal-quote-request-text">Custom Order Form</Typography>
            </div>
            <div className={classes.buttonContentWrapper}>
              <div className={classes.buttonContent}>
                <Typography variant='body2'>Perfect for products that: </Typography>
                <ul>
                  <li><Typography variant='body2'>Require an order form to customize</Typography></li>
                  <li><Typography variant='body2'>Require detailed requests and invoicing</Typography></li>
                  <li><Typography variant='body2'>Require significant customization</Typography></li>
                </ul>
              </div>
            </div>
          </ButtonBase>
        </DialogActions>
      </Grid>
    </ModalWrapper>
  );
};

export default AddProductModal;
