import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowDownward, ArrowForward, ArrowUpward } from '@material-ui/icons';
import React from 'react';

type Props = {
  direction: 'up' | 'down' | 'left' | 'right';
  containerClass?: string;
  arrowClass?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    fontSize: 20,
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 8,
  },
  successBg: {
    backgroundColor: `${theme.branding.mint}0D`,
  },
  errorBg: {
    backgroundColor: `${theme.branding.error}0D`,
  },
  success: {
    color: theme.branding.mint,
  },
  error: {
    color: theme.branding.error,
  },
}));

const ArrowIcon: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { direction, containerClass, arrowClass } = props;

  return (
    <div
      className={`${classes.arrowContainer} ${containerClass} ${
        direction === 'up' ? classes.successBg : classes.errorBg
      } ${direction === 'up' ? classes.success : classes.error}`}
    >
      {direction === 'up' && <ArrowUpward className={`${classes.arrowIcon} ${arrowClass}`} />}
      {direction === 'down' && <ArrowDownward className={`${classes.arrowIcon} ${arrowClass}`} />}
      {direction === 'left' && <ArrowBack className={`${classes.arrowIcon} ${arrowClass}`} />}
      {direction === 'right' && <ArrowForward className={`${classes.arrowIcon} ${arrowClass}`} />}
    </div>
  );
};

export default ArrowIcon;
