import { FirebaseApp } from '@castiron/castiron-firebase';
import {
  CustomerRepository,
  ProductRepository,
  TransactionRepository,
  ShopRepository,
  AccountRepository,
  CouponRepository,
  MarketingSendRepository,
  CommunityPackageRepository,
  ProductTemplateRepository, 
  TierRepository,
  PlanRepository,
  AssetRepository,
} from '@castiron/domain';

const db = FirebaseApp.firestore();

export const couponRepository = new CouponRepository(db);
export const customerRepository = new CustomerRepository(db);
export const productRepository = new ProductRepository(db);
export const productTemplateRepository = new ProductTemplateRepository(db);
export const transactionRepository = new TransactionRepository(db);
export const shopRepository = new ShopRepository(db);
export const accountRepository = new AccountRepository(db);
export const marketingSendRepository = new MarketingSendRepository(db);
export const communityPackageRepository = new CommunityPackageRepository(db);
export const tierRepository = new TierRepository(db);
export const planRepository = new PlanRepository(db);
export const assetRepository = new AssetRepository(db);
