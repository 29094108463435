import React from 'react';
import {
  Button,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  ButtonBase
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import { Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { ProductTemplate } from '@castiron/domain/src/productTemplate/model';
import { iconsByName } from '../../../lib/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { useHistory } from 'react-router';
import ModalWrapper from '../../RootModal/ModalWrapper';


export interface Props {
  modalOpen: boolean;
  productTemplates: ProductTemplate[];
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '15px 0px 15px 30px',
    justifyContent: 'left',
    '&:hover': {
      backgroundColor: theme.branding.gray[100],
      border: `1px solid ${theme.branding.blue.primary}`,
      color: theme.branding.blue.primary,
      '& path': {
        fill: theme.branding.blue.primary
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0',
      marginTop: 16
    }
  },
  modalWrapper: {
    padding: '26px 48px 54px 48px',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    }
  },
  title: {
    marginBottom: '0.5em',
  },
}));

const SelectProductTypeModal: React.FC<Props> = (props: Props) => {
  const { modalOpen, productTemplates } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop
  }))

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handlePrevious = () => {
    dispatch(closeModal());
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          createCustomProductLink: '/products/add/custom',
          createStandardProductLink: '/products/add/standard',
          modalOpen: true,
        },
      }),
    );
  };

  const handleTemplateCustom = (id: string) => {
    history.push(`/products/custom/${id}`); //'/products/custom/:templateId'
    dispatch(closeModal());
  };

  const handleCustom = () => {
    history.push(`/products/add/custom`); //'/products/add/custom/'
    dispatch(closeModal());
  };

  return (
    <ModalWrapper fullScreen={isMobile} size='md' show={modalOpen}>
      <Grid className={classes.modalWrapper}>
        <Button size='medium' onClick={handlePrevious}>
          <ArrowBack />
          <Typography variant='body3'>Previous Step</Typography>
        </Button>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
        <DialogTitle className={classes.header}>
          <Typography variant='h2' className={classes.title} >What type of product do you want to add?</Typography>
        </DialogTitle>
        <Grid container spacing={3}>
          {productTemplates.map(productTemplate =>
            <Grid item xs={12} sm={6}>
              <Button className={classes.button} onClick={() => handleTemplateCustom(productTemplate.id)} variant="outlined" fullWidth={true} startIcon={iconsByName(productTemplate.icon)}>
                {productTemplate.displayName}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Button className={classes.button} onClick={handleCustom} variant="outlined" fullWidth={true}>
              Other/Not Listed
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalWrapper >
  );
};

export default SelectProductTypeModal;
