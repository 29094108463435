import React from 'react';
import SortUpIcon from '../../assets/img/sort-up.svg';
import SortDownIcon from '../../assets/img/sort-down.svg';
import { SvgIcon } from '@castiron/components';
import { makeStyles } from '@material-ui/core';

type Props = {
  direction: 'up' | 'down' | 'default';
  iconClass?: string;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 32,
    paddingLeft: 5,
    paddingRight: 5,
  },
  svgIcon: {
    width: 25,
    height: 25,
  },
  upIcon: {
    position: 'absolute',
    top: 0,
  },
  downIcon: {
    position: 'absolute',
    bottom: 0,
  },
  inactive: {
    color: '#80838B80',
  },
  active: {
    color: '#80838B',
  },
}));

const SortIcon: React.FC<Props> = (props: Props) => {
  const { direction, iconClass } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${iconClass}`}>
      <SvgIcon
        className={`${classes.svgIcon} ${classes.upIcon} ${direction === 'up' ? classes.active : classes.inactive}`}
      >
        <SortUpIcon />
      </SvgIcon>
      <SvgIcon
        className={`${classes.svgIcon} ${classes.downIcon} ${direction === 'down' ? classes.active : classes.inactive}`}
      >
        <SortDownIcon />
      </SvgIcon>
    </div>
  );
};

export default SortIcon;
