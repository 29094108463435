import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import CardHeader, { CardHeaderProps } from './CardHeader';

interface Props extends CardHeaderProps {
  sections: ReactNode[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
  },
  section: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '24px',
  },
}));

const SectionedCard: React.FC<Props> = (props: Props) => {
  const { sections } = props;

  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item>
        <CardHeader {...props} />
      </Grid>
      {
        sections.map((section, idx) => (
          <Grid key={`section-${idx}`} item className={classes.section}>
            {section}
          </Grid>
        ))
      }
    </Grid>
  );
};

export default SectionedCard;
