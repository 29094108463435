import React from 'react';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import _ from 'lodash';

interface QualifierOption {
  display: string,
  value: string,
};

interface Props extends BaseInputProps {
  fieldMappings: {
    text: string,
    qualifier: string,
  },
  qualifierOptions: QualifierOption[],
  allowNegative?: boolean,
};

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  text: {
    /* bit of a hack to access the actual input with the border here, but it works */
    '& div': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

const QualifiedNumberInput: React.FC<Props> = (props: Props) => {
  const { label, secondaryLabel, required, error, fieldMappings, qualifierOptions, allowNegative } = props;

  const classes = useStyles();

  const formik = useFormikContext();

  const numberField = fieldMappings.text;
  const qualifierField = fieldMappings.qualifier;

  /* a bit of a hack here to allow 0 to be a real value but not prepopluate the field at 0 */
  const initialNumber = _.get(formik.values, numberField);
  const initialValue = allowNegative || initialNumber >= 0 ? initialNumber : "";

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <Grid container>
        <Grid item xs={6} sm={8}>
          <TextField
            style={{ margin: '0px' }}
            variant='outlined'
            fullWidth
            className={classes.text}
            name={numberField}
            defaultValue={initialValue}
            onChange={formik.handleChange}
            error={!!error}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            variant='outlined'
            fullWidth
            className={classes.select}
            name={qualifierField}
            value={_.get(formik.values, qualifierField)}
            onChange={formik.handleChange}
            error={!!error}
          >
            {
              qualifierOptions.map((option, index) => (
                <MenuItem key={`selectOption-${option.value}-${index}`} value={option.value}>{option.display}</MenuItem>
              ))
            }
          </Select>
        </Grid>
      </Grid>
    </InputWrapper>
  );
};

export default QualifiedNumberInput;