import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { calculateTotals, Order, SubLineItem, TransactionTotals, updateOrderTotals, Transaction } from '@castiron/domain';
import { useFormikContext } from 'formik';
import { Button, Input, MoneyInput, SvgIcon, Typography } from '@castiron/components';
import clsx from "clsx";
import Dinero from 'dinero.js';
import { nanoid } from '@reduxjs/toolkit';
import TrashIcon from '../../../assets/img/trash.svg';
import Tooltip from '../../Tooltip';
import _ from "lodash";
import { listAllTransactionsAction } from "../../../store/reducers/transactions";
import { Autocomplete } from "@material-ui/lab";
import { FeatureFlag } from '@castiron/castiron-firebase';

type Props = {
  order: Order;
  transaction: Transaction;
  isPaid: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    textAlign: 'left',
    width: '100%',
  },
  addSubLineItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    marginBottom: 24,
    padding: '16px 0',
    width: '100%',
    '& span': {
      color: theme.branding.gray[100],
      cursor: 'pointer',
      backgroundColor: theme.branding.blue.primary,
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '1em',
      padding: '0 5px',
    },
  },
  hasTip: {
    width: '100%',
    padding: 16,
    borderRadius: 8,
    backgroundColor: theme.branding.blue.light,
    [theme.breakpoints.down('sm')]: {
      padding: '12px 12px 8px',
    }
  },
  icon: {
    height: 36,
    width: 'auto',
  },
  delete: {
    color: theme.palette.error.main,
  },
  detailInput: {
    maxWidth: '90%',
  },
  dashedButton: {
    backgroundImage: `linear-gradient(to right, #B8B8B8 50%, transparent 0%), linear-gradient(to right, #B8B8B8 50%, transparent 0%), linear-gradient(to bottom, #B8B8B8 50%, transparent 0%), linear-gradient(to bottom, #B8B8B8 50%, transparent 0%)`,
    backgroundPosition: 'left top, left bottom, left top, right top',
    backgroundSize: '10px 1px, 10px 1px, 1px 10px, 1px 10px',
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    color: '#B8B8B8',
    marginBottom: 24,
  },
  fakeInputMargins: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  feeContainer: {
    '& >div': {
      marginBottom: 16,
    },
  },
  formControl: {
    margin: '24px 0',
    maxWidth: '100%',
  },
  fulfillmentFeeInput: {
    maxWidth: '155px',

    '& input': {
      textAlign: 'right',
    },
  },
  header: {
    '& h1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    maxWidth: '100%',
  },
  itemBoxInner: {
    padding: '18px 16px',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: 16,
  },
  subItemContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: '24px 0',
  },
  select: {
    maxWidth: '100%',
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
}));

const QuoteDetails: React.FC<Props> = (props: Props) => {
  const { order, transaction, isPaid } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const [quoteNoteOpen, setQuoteNoteOpen] = useState(false);
  const [quoteSubLineNoteOpen, setQuoteSubLineNoteOpen] = useState(false);
  const [customProducts, setCustomProducts] = useState([]);
  const [subLineItems, setSubLineItems] = useState<SubLineItem[]>([]);
  const [totals, setTotals] = useState<TransactionTotals>();
  const [existingSubLineItems, setExistingSubLineItems] = useState<string[]>([]);
  const [tip, setTip] = useState(0);

  const dispatch = useAppDispatch();

  const { shop, products, transactions } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
    transactions: state.transactions.transactions
  }));

  useEffect(() => {
    const customProducts = products.filter(product => product.type === 'custom');
    setCustomProducts(customProducts);
  }, [products]);

  useEffect(() => {
    if (transactions) {
      const selections = _.uniq(_.flattenDeep(transactions.map(t => t.order.items?.map(i => i.subLineItems?.map(sli => sli.title))))).filter(s => !!s);
      selections.sort();
      setExistingSubLineItems(selections);
    } else if (shop) {
      dispatch(listAllTransactionsAction(shop.id));
    }
  }, [shop, transactions]);

  useEffect(() => {
    setSubLineItems(values.order.items[0].subLineItems);

    if (shop) {
      if (!isPaid) {
        const totals = calculateTotals({
          order: values.order,
          paymentSettings: shop.paymentSettings,
        });
        setTotals(totals);
      } else {
        setTotals(transaction?.totals)
      }
    }
  }, [shop, values.order]);

  useEffect(() => {
    if (order && shop && !isPaid) {
      const newTotal = calculateTotals({ order: order, paymentSettings: shop.paymentSettings });
      setTotals(newTotal);
      if (newTotal) {
        const updatedOrderTotal = updateOrderTotals(values.order);
        setFieldValue('order.orderTotal', updatedOrderTotal.orderTotal);
      }
    }
  }, [order, shop]);

  const handleDeleteNote = (noteLoc: string) => {
    setQuoteNoteOpen(false);
    setFieldValue(noteLoc, '');
  };

  useEffect(() => {
    totals && setTip(totals.tip);
  }, [totals])

  const addSubLineItem = () => {
    const newItem = {
      id: nanoid(),
      title: '',
      price: 0,
      quantity: 1,
      notes: '',
    };
    subLineItems ? setSubLineItems([...subLineItems, newItem]) : setSubLineItems([newItem]);
    subLineItems
      ? setFieldValue('order.items[0].subLineItems', [...subLineItems, newItem])
      : setFieldValue('order.items[0].subLineItems', [newItem]);
  };

  const handlePriceChange = (value: number, type: string) => {
    if (type === 'quantity') {
      setFieldValue('order.items[0].total', value * values.order.items[0].price);
      setFieldValue('order.items[0].subtotal', value * values.order.items[0].price);
      setFieldValue('order.items[0].quantity', value);
    } else if (type === 'price') {
      setFieldValue('order.items[0].total', value * values.order.items[0].quantity);
      setFieldValue('order.items[0].subtotal', value * values.order.items[0].quantity);
      setFieldValue('order.items[0].price', value);
    }
  };

  const handleSubLineItemPriceChange = (value: number, type: string, index: number) => {
    if (type === 'quantity') {
      setFieldValue(`order.items[0].subLineItems[${index}].total`, value * values.order.items[0].subLineItems[index]?.price);
      setFieldValue(`order.items[0].subLineItems[${index}].quantity`, value);
    } else if (type === 'price') {
      setFieldValue(`order.items[0].subLineItems[${index}].total`, value * values.order.items[0].subLineItems[index]?.quantity);
      setFieldValue(`order.items[0].subLineItems[${index}].price`, value);
    }
  };

  const deleteSubLineItem = (id: string) => {
    const newSubLineItems = subLineItems.filter(item => item.id !== id);
    setSubLineItems(newSubLineItems);
    setFieldValue('order.items[0].subLineItems', newSubLineItems);
  };

  const handleProductSelection = id => {
    setFieldValue('order.items[0].id', id);
    const product = customProducts.find(product => product.id === id);
    if (product && values.order.items[0].price === 0) {
      setFieldValue('order.items[0].price', product.startingPrice);
      handlePriceChange(product.startingPrice, 'price');
    } else if (!product) {
      setFieldValue('order.items[0].price', 0);
      handlePriceChange(0, 'price');
    }
  };

  const blurOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      /* types not playing nice */
      (e.target as any).blur();
    }
  };

  return (
    <Grid container className={classes.itemBox} direction="column">
      <Grid container className={classes.itemHeader} alignItems="center">
        <Typography className={classes.title}>Quote details</Typography>
      </Grid>
      <Grid className={classes.itemBoxInner} container justify="space-between" direction="column" wrap="nowrap">
        <FormControl className={classes.formControl}>
          <FormLabel required>Product</FormLabel>
          <Select
            className={classes.select}
            displayEmpty={true}
            value={values.order.items[0].id}
            onChange={event => handleProductSelection(event.target.value)}
            placeholder="Select a custom product"
            variant="outlined"
            disabled={isPaid || order?.origination === "customer"}
          >
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>
            {customProducts.map(option => (
              <MenuItem className={classes.selectOptionContainer} key={option.id} value={option.id}>
                <Typography className={classes.selectOption} variant="body1">
                  {option.title}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" item xs={12}>
          <FormControl className={classes.formControl}>
            <FormLabel required>Qty</FormLabel>
            <Input
              type="number"
              hideArrows={true}
              onChange={event => handlePriceChange(event.target.valueAsNumber, 'quantity')}
              value={values.order.items[0].quantity}
              name="order.items[0].quantity"
              placeholder="Quantity"
              variant="outlined"
              containerClass={classes.detailInput}
              onWheel={e => e.target.blur()}
              disabled={isPaid}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel required>Price</FormLabel>
            <Box className={clsx([classes.detailInput, classes.fakeInputMargins])}>
              <MoneyInput
                name="order.items[0].price"
                placeholder="Price"
                disabled={isPaid}
                onChanged={(newValue) => handlePriceChange(newValue, 'price')}
                onKeyPress={blurOnEnter}
                variant='small'
              />
            </Box>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel>Total</FormLabel>
            <Box className={clsx([classes.detailInput, classes.fakeInputMargins])}>
              <MoneyInput
                name="order.items[0].subtotal"
                placeholder="Price"
                disabled
                variant='small'
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid container item xs={12} direction="column" wrap="nowrap">
          {values.order.items[0].notes || quoteNoteOpen ? (
            <Grid container item xs={12}>
              <Grid item xs={12} container justify="space-between">
                <FormLabel>Note</FormLabel>
                <IconButton onClick={() => handleDeleteNote('order.items[0].notes')}>
                  <Close />
                </IconButton>
              </Grid>
              <Input
                type="text"
                value={values.order.items[0].notes}
                multiline={true}
                rows={3}
                name="order.items[0].notes"
                placeholder="Add note"
                variant="outlined"
                fullWidth
                disabled={isPaid}
              />
            </Grid>
          ) : (
            !isPaid && (
              <Button className={`${classes.addButton} ${classes.dashedButton}`} onClick={() => setQuoteNoteOpen(true)}>
                + Add note
              </Button>
            )
          )}
          <Grid container item xs={12}>
            {subLineItems &&
              subLineItems.map((subItem, index) => (
                <Grid className={classes.subItemContainer} container item xs={12} key={subItem.id}>
                  <Grid container item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={existingSubLineItems}
                      placeholder="Line Item Title"
                      disabled={isPaid}
                      fullWidth
                      value={values.order.items[0].subLineItems[index]?.title}
                      onChange={(event, value) => {
                        setFieldValue(`order.items[0].subLineItems[${index}].title`, value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params}
                          name={`order.items[0].subLineItems[${index}].title`}
                          placeholder="Line Item Title"
                          margin="normal"
                          variant="outlined"
                          onChange={event => {
                            setFieldValue(`order.items[0].subLineItems[${index}].title`, event.target.value);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <SvgIcon
                              className={`${classes.icon} ${classes.delete}`}
                              onClick={() => deleteSubLineItem(subItem.id)}
                            >
                              <TrashIcon />
                            </SvgIcon>
                          }}
                        />
                      )}
                    />

                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    item
                    xs={12}
                  >
                    <FormControl className={classes.formControl}>
                      <FormLabel required>Qty</FormLabel>
                      <Input
                        type="number"
                        hideArrows={true}
                        onChange={event => handleSubLineItemPriceChange(event.target.valueAsNumber, 'quantity', index)}
                        value={values.order.items[0].subLineItems[index]?.quantity}
                        name={`order.items[0].subLineItems[${index}].quantity`}
                        placeholder="Quantity"
                        variant="outlined"
                        containerClass={classes.detailInput}
                        onWheel={e => e.target.blur()}
                        disabled={isPaid}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel required>Price</FormLabel>
                      <Box className={clsx([classes.detailInput, classes.fakeInputMargins])}>
                        <MoneyInput
                          name={`order.items[0].subLineItems[${index}].price`}
                          placeholder="Price"
                          disabled={isPaid}
                          onChanged={(newValue) => handleSubLineItemPriceChange(newValue, 'price', index)}
                          onKeyPress={blurOnEnter}
                          variant='small'
                        />
                      </Box>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel>Total</FormLabel>
                      <Box className={clsx([classes.detailInput, classes.fakeInputMargins])}>
                        <MoneyInput
                          name={`order.items[0].subLineItems[${index}].total`}
                          placeholder="Price"
                          disabled
                          variant='small'
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  {values.order.items[0].subLineItems[index]?.notes || quoteSubLineNoteOpen ? (
                    <Grid container item xs={12}>
                      <Grid item xs={12} container justify="space-between">
                        <FormLabel>Note</FormLabel>
                        <IconButton onClick={() => handleDeleteNote(`order.items[0].subLineItems[${index}].notes`)}>
                          <Close />
                        </IconButton>
                      </Grid>
                      <Input
                        type="text"
                        value={values.order.items[0].subLineItems[index]?.notes}
                        multiline={true}
                        rows={3}
                        name={`order.items[0].subLineItems[${index}].notes`}
                        placeholder="Add note"
                        variant="outlined"
                        fullWidth
                        disabled={isPaid}
                      />
                    </Grid>
                  ) : (
                    !isPaid && (
                      <Button className={`${classes.addButton} ${classes.dashedButton}`} onClick={() => setQuoteSubLineNoteOpen(true)}>
                        + Add note
                      </Button>
                    )
                  )}
                </Grid>
              ))}
            {!isPaid && (
              <Typography className={classes.addSubLineItem} onClick={() => addSubLineItem()}>
                <span>+</span> Add item
              </Typography>
            )}
          </Grid>
          <Grid className={classes.feeContainer} container alignItems="center">
            <Grid container item xs={12} justify="space-between">
              <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                <Typography>Subtotal</Typography>
              </Grid>
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Typography>{Dinero({ amount: totals?.subtotal || 0 }).toFormat('$0.00')}</Typography>
              </Grid>
              <Divider />
              <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                <Typography>Fulfillment Fee</Typography>
              </Grid>
              <Grid container item xs={4} sm={6} justify="flex-end" alignItems="center">
                <Box className={classes.fulfillmentFeeInput}>
                  <MoneyInput
                    name="order.fulfillmentOption.fee"
                    placeholder="Fulfillment Fee"
                    disabled={isPaid}
                    onKeyPress={blurOnEnter}
                  />
                </Box>
              </Grid>
              <FeatureFlag name="feature_tipping">
                {(shop.config?.tipping && !isPaid) && (
                  <>
                    <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center" direction="row">
                      <Typography>Tip &#128151;</Typography>
                      <Tooltip title="Customers will have the option to add a tip when checking out." />
                    </Grid>
                    <Grid container item xs={4} sm={6} justify="flex-end" alignItems="center">
                      <Typography> - </Typography>
                    </Grid>
                  </>
                )}
                {tip > 0 && (
                  <>
                    <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                      <Typography>Tip</Typography>
                    </Grid>
                    <Grid container item xs={4} sm={6} justify="flex-end" alignItems="center">
                      <Typography>{Dinero({ amount: tip }).toFormat('$0.00')}</Typography>
                    </Grid>
                    <div className={classes.hasTip}>
                      <Typography variant='body2'><span>&#128151;</span> You received a tip on this order! Keep up the great work.</Typography>
                    </div>
                  </>
                )}
              </FeatureFlag>
              <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                <Typography>Sales Tax ({shop.paymentSettings.taxRate}%)</Typography>
              </Grid>
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Typography>{Dinero({ amount: totals?.taxes || 0 }).toFormat('$0.00')}</Typography>
              </Grid>
              <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                <Typography>Convenience Fee</Typography>
                <Tooltip title="This fee helps cover the cost of building high-quality tools that allow artisans to offer their handmade products to customers like you. Thank you for supporting your favorite artisans!" />
              </Grid>
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Typography>{Dinero({ amount: totals?.totalCustomerFees || 0 }).toFormat('$0.00')}</Typography>
              </Grid>
              <Grid container item xs={7} sm={6} justify="flex-end" alignItems="center">
                <Typography>Total</Typography>
              </Grid>
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Typography>{Dinero({ amount: totals?.total || 0 }).toFormat('$0.00')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoteDetails;
