import firebase from 'firebase/compat/app';
import _firestore, { CollectionReference, DocumentData, QueryDocumentSnapshot } from '@google-cloud/firestore';
import _ from 'lodash';
import moment from 'moment';
import { BaseRepository, FieldFunctions } from "../base/repository";
import { Shop } from ".";
import { AvailabilitySubtype, CalendarEvent, CalendarEventType } from './model';

type FirebaseCollectionRef = firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
type FirebaseQueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

const SUBCOLLECTION_NAME = 'calendar_events';

const ONE_DAY = 60 * 60 * 24;

export class CalendarEventRepositoryFactory {
  private readonly firestore: firebase.firestore.Firestore | _firestore.Firestore;
  private readonly fieldFunctions: FieldFunctions;

  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    this.firestore = firestore;
    this.fieldFunctions = fieldFunctions;
  }

  public getRepository(collectionId: string) {
    return new CalendarEventRepository(this.firestore, collectionId, this.fieldFunctions);
  }
}

export class CalendarEventRepository extends BaseRepository<Shop> {
  private readonly parentCollectionId: string;

  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, parentCollectionId: string, fieldFunctions?: FieldFunctions) {
    super(firestore, 'shops', fieldFunctions);
    this.parentCollectionId = parentCollectionId;
  }

  private subCollection():
    | FirebaseCollectionRef
    | CollectionReference<DocumentData> {
    return this.collection().doc(this.parentCollectionId).collection(SUBCOLLECTION_NAME);
  }

  private toDocument(firestoreDoc: QueryDocumentSnapshot<DocumentData> | FirebaseQueryDocumentSnapshot): CalendarEvent {
    const base = {
      ...firestoreDoc.data(),
      id: firestoreDoc.id,
    } as CalendarEvent;
    return (base.status ? base : { ...base, status: 'active' });
  }

  public async findEvents(startTime: number, endTime: number): Promise<CalendarEvent[]> {
    /* firestore sucks at this.  Can't filter two inequalities at once. So filter after the fact  I'm open to other ideas here */
    const results = await this
      .subCollection()
      .where('endTime', '>=', startTime)
      .get();

    return results.docs.map(doc => this.toDocument(doc)).filter(cd => cd.startTime <= endTime);
  }

  public async findEventsByType(type: CalendarEventType, startTime: number, endTime: number): Promise<CalendarEvent[]> {
    /* see comment in findEvents */
    const results = await this
      .subCollection()
      .where('type', '==', type)
      .where('endTime', '>=', startTime)
      .get();

    return results.docs.map(doc => this.toDocument(doc)).filter(cd => cd.startTime <= endTime);
  }

  private async saveNewAvailability(subtype: AvailabilitySubtype, startTime: number, endTime: number) {
    if (subtype === 'available') {
      /* do nothing, as no data for the date range assumes available */
    } else {
      const now = moment().unix();
      const doc: CalendarEvent = {
        status: 'active',
        createdAt: now,
        updatedAt: now,
        type: 'availability',
        subtype,
        startTime,
        endTime,
      };
      await this.subCollection().add(doc);
    }
  };

  public async addAvailability(subtype: AvailabilitySubtype, startTime: number, endTime: number): Promise<void> {
    if (startTime > endTime) {
      throw new Error('start time must be before end time');
    }
    const now = moment().unix();

    const existingAvailabilities = await this.findEventsByType('availability', startTime, endTime);
    if (existingAvailabilities.length === 0) {
      /* nothing conflicting, just save */
      await this.saveNewAvailability(subtype, startTime, endTime);
    } else if (existingAvailabilities.length === 1 && existingAvailabilities[0].subtype === subtype) {
      /* expand existing to include both ranges, safe to assume subtype does not equal available as we found something */
      const existingDoc = existingAvailabilities[0];
      const doc: CalendarEvent = {
        ...existingDoc,
        startTime: _.min([existingDoc.startTime, startTime]),
        endTime: _.max([existingDoc.endTime, endTime]),
        updatedAt: now,
      };

      await this.subCollection().doc(existingDoc.id).set(doc);
    } else {
      /* we need to update existing docs, then add the new one */
      const existingUpdatesP = existingAvailabilities.map(async ea => {
        if (ea.startTime < startTime && ea.endTime > endTime) {
          /* this wraps the new range, update to before, and create new on the other side */
          const updated = {
            ...ea,
            endTime: startTime - ONE_DAY,
            updatedAt: now,
          }
          await this.subCollection().doc(ea.id).set(updated);
          await this.saveNewAvailability(ea.subtype, endTime + ONE_DAY, ea.endTime);
        } else if (ea.startTime >= startTime && ea.endTime <= endTime) {
          /* the new range wraps this, delete this */
          await this.subCollection().doc(ea.id).delete();
        } else if (ea.startTime < startTime) {
          /* overlap with this on the early side */
          const updated = {
            ...ea,
            endTime: startTime - ONE_DAY,
            updatedAt: now,
          };
          await this.subCollection().doc(ea.id).set(updated);
        } else {
          /* overlap with this on the late side */
          const updated = {
            ...ea,
            startTime: endTime + ONE_DAY,
            updatedAt: now,
          };
          await this.subCollection().doc(ea.id).set(updated);
        }
      });
      await Promise.all(existingUpdatesP);
      /* now save our new one */
      await this.saveNewAvailability(subtype, startTime, endTime);
    }
  }
}