import React, { MouseEvent, ReactNode, useState } from 'react';
import { Box, Button, ButtonBase, Grid, Popover, PopoverOrigin, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import Typography from '../Typography';

export interface DropDownOption {
  label: string | ReactNode;
  color?: 'error';
  icon?: ReactNode;
  tooltipText?: ReactNode;
  isDropdownBanner?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

type Props = {
  variant?: 'arrow' | 'no-arrow' | 'ellipsis';
  options: DropDownOption[];
  title?: ReactNode;
  fullWidth?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  actions: {
    width: props => props.fullWidth ? '100%' : undefined,
  },
  button: {
    height: '56px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    padding: (props) => {
      switch (props.variant) {
        case 'ellipsis': return '0px';
        case 'no-arrow': return '8px';
        default: return '16px';
      }
    },
    width: props => props.fullWidth ? '100%' : undefined,
  },
  error: {
    color: theme.branding.red.primary,
  },
  firstMenuItem: {
    borderTop: 'none !important',
  },
  icon: {
    height: '24px',
    width: '24px',
    color: theme.branding.gray[800],
  },
  menuItem: {
    padding: '16px 24px',
    borderTop: `1px solid ${theme.branding.gray[200]}`,
    borderRadius: 0,
    justifyContent: 'flex-start',
    /* tried using the classes prop to do this, but it wasn't doing anything at all :shrug: */
    '& .MuiButton-startIcon': {
      height: '20px',
      width: '20px',
      margin: '0 16px 0 0',
      color: theme.branding.gray[800],
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  tooltip: {
    borderRadius: 12,
    maxWidth: '188px',
    padding: 8
  }
}));

const Dropdown: React.FC<Props> = (props: Props) => {
  const {
    variant = 'arrow',
    options,
    title,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    transformOrigin = { vertical: 'top', horizontal: 'center' }
  } = props;

  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);

  const toggleTooltip = (index: number) => {
    setTooltipOpen(tooltipOpen === index ? null : index);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    setTooltipOpen(null);
  };

  const handleClick = action => {
    toggleTooltip(null);
    /* quick and dirty solution a one off feature to allow for promises on action */
    const actionResponse = action();
    if (typeof actionResponse === 'object' && typeof actionResponse.then === 'function') {
      actionResponse.then(() => handlePopoverClose());
    } else {
      handlePopoverClose();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={`${classes.actions}`}>
      {
        variant === 'ellipsis' ? (
          <ButtonBase onClick={handleMoreClick}>
            <MoreVert />
          </ButtonBase>
        ) : (
          <ButtonBase focusRipple onClick={handleMoreClick} className={`${classes.button}`}>
            <Grid container spacing={variant === 'arrow' ? 1 : 0} justify='space-between'>
              <Grid item>
                <Typography variant={variant === 'arrow' ? 'button' : 'subtitle2'}>{title}</Typography>
              </Grid>
              {
                variant === 'arrow' &&
                <Grid item>
                  {
                    anchorEl ? (
                      <KeyboardArrowUp className={classes.icon} />
                    ) : (
                      <KeyboardArrowDown className={classes.icon} />
                    )
                  }
                </Grid>
              }
            </Grid>
          </ButtonBase>
        )
      }
      <Popover
        open={open}
        style={{ marginTop: '8px' }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Grid container direction='column'>
          {
            options.map((option, i) => (
              <Grid item key={`dropdownOption${i}`}>
                {option.isDropdownBanner ?
                  <Grid
                    onClick={() => toggleTooltip(null)}
                    className={clsx([classes.menuItem, i === 0 && classes.firstMenuItem, option.color == 'error' && classes.error])}
                  >
                    {option.label}
                  </Grid>
                  : option.tooltipText ?
                    <Tooltip
                      placement='bottom'
                      onClose={() => toggleTooltip(null)}
                      open={tooltipOpen === i}
                      title={option.tooltipText}
                      PopperProps={{
                        style: {
                          marginTop: '-18px',
                        }
                      }}
                      classes={{
                        tooltip: classes.tooltip
                      }}
                      disableFocusListener
                      disableHoverListener
                      arrow
                    >
                      <Button
                        startIcon={option.icon}
                        onClick={() => toggleTooltip(i)}
                        className={clsx([classes.menuItem, i === 0 && classes.firstMenuItem, option.color == 'error' && classes.error])}
                        fullWidth
                      >
                        <Typography
                          variant={variant === 'arrow' ? 'button' : 'subtitle2'}
                          className={option.color == 'error' && classes.error}
                        >
                          {option.label}
                        </Typography>
                      </Button>
                    </Tooltip>
                    :
                    <Button
                      startIcon={option.icon}
                      onClick={() => handleClick(option.onClick)}
                      className={clsx([classes.menuItem, i === 0 && classes.firstMenuItem, option.color == 'error' && classes.error])}
                      fullWidth
                    >
                      <Typography
                        variant={variant === 'arrow' ? 'button' : 'subtitle2'}
                        className={option.color == 'error' && classes.error}
                      >
                        {option.label}
                      </Typography>
                    </Button>
                }
              </Grid>
            ))
          }
        </Grid>
      </Popover>
    </Box>
  );
};

export default Dropdown;
