/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { useAppSelector } from '../../hooks';
import { GridColumns, GridCellParams } from '@material-ui/data-grid';
import { Link, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DataGrid from '../DataGrid/DataGrid';
import Dinero from 'dinero.js';
import ProductItem from '../Products/ProductItem';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { BaseProduct, Product, Transaction } from '@castiron/domain';
import { Chip, OrderStatusPill } from '@castiron/components';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  labelContainer: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 32,
  },
  link: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
  },
  out: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.error.main,
  },
}));

type Props = {
  ordersData: Transaction[];
  productsData: BaseProduct[];
};

const Tables: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isProductsLoading } = useAppSelector(state => ({
    isProductsLoading: state.products.loading,
  }));

  const sortedOrderData = _.orderBy(props.ordersData, item => item.order.orderNumber, ['desc']);

  const getChip = (value: string): ReactElement => {
    let status;
    if (value === 'completed' || value === 'active' || value === 'succeeded') status = 'success';
    if (value === 'inactive' || value === 'open') status = 'warning';
    if (value === 'canceled') status = 'error';
    if (value === 'fulfilled') status = 'fulfilled';

    return (
      <Chip colorScheme={status} uppercase bold>
        {value}
      </Chip>
    );
  };

  const orders: GridColumns = [
    {
      field: 'id',
      headerName: 'Order #',
      flex: 1,
      renderCell: (params): ReactElement => <Typography variant="subtitle1">{params.row.order.orderNumber}</Typography>,
    },
    {
      field: 'customerName',
      headerName: 'customer',
      flex: 1,
      renderCell: (params): ReactElement => (
        <Typography variant="body1">{params.row.customerName?.replaceAll('undefined', '') || ''}</Typography>
      ),
    },
    {
      field: 'status',
      flex: 1,
      renderCell: (params): ReactElement => {
        const order = params.row as Transaction;
        return <OrderStatusPill transaction={order} context="order" />;
      },
    },
    {
      field: 'total',
      flex: 1,
      renderCell: (params): ReactElement => {
        //console.log('total field params', params);

        return (
          <Typography variant="body1">{Dinero({ amount: params.row.totals?.total || 0 }).toFormat('$0.00')}</Typography>
        );
      },
      align: isMobile ? 'right' : 'left',
    },
  ];

  const lowInventory: GridColumns = [
    {
      field: 'item',
      headerName: 'Item',
      flex: isMobile ? 2 : 1.5,
      renderCell: (params: GridCellParams): ReactElement => {
        return <ProductItem product={params.row as BaseProduct} />;
      },
    },
    {
      field: 'status',
      flex: 1,
      renderCell: (params): ReactElement => getChip(params.value.toString()),
    },
    {
      field: 'inventory',
      flex: 1,
      valueGetter: (params: GridCellParams): number => {
        const baseProduct = params.row as BaseProduct;
        if (baseProduct.type === 'custom') {
          /* always want these last in ASC order */
          return Number.MAX_SAFE_INTEGER;
        } else {
          const product = baseProduct as Product;
          return product.inventory;
        }
      },
      renderCell: (params): ReactElement => {
        const product = params.row as BaseProduct;
        const isOutOfStock = params.value === 0;
        let stock = params.value?.toString();
        let clazz = undefined;
        if (product.type === 'custom') {
          stock = '-';
        } else if (isOutOfStock) {
          stock = 'Out of Stock';
          clazz = classes.out;
        }
        return (
          <Typography className={clazz} variant="body1">
            {stock}
          </Typography>
        );
      },
      align: isMobile ? 'right' : 'left',
    },
    {
      field: 'price',
      flex: 1,
      renderCell: (params): ReactElement => (
        <Typography variant="body1">
          {params.value ? Dinero({ amount: params.value as number }).toFormat('$0.00') : ''}
        </Typography>
      ),
    },
  ];

  const navigate = (path: string): void => {
    history.push(path);
  };

  const handleInventoryCellClick = (params: GridCellParams): void => {
    if (params.field !== 'actionMenu') history.push(`/products/edit/${params.id}`);
  };

  const ordersMobile = orders.filter(column => column.field !== 'status');
  const lowInventoryMobile = lowInventory.filter(column => column.field === 'item' || column.field === 'inventory');

  const setData = (data, limit) => {
    let curArray = [];
    if (data && limit) {
      if (data.length > limit) {
        curArray = data.slice(0, limit);
      } else {
        curArray = data;
      }
    }
    return curArray;
  };

  return (
    <div className={classes.container}>
      <div className={classes.labelContainer}>
        <Typography variant="h2">Latest orders</Typography>
        <Link onClick={(): void => navigate('/orders')} className={classes.link}>
          View all orders
        </Link>
      </div>
      <DataGrid
        onCellClick={params => history.push(`/orders/edit/${params.row.id}`)}
        hideFooter
        rows={setData(
          _(sortedOrderData)
            .map(item => ({
              ...item,
              customerName: `${item.customerObj?.firstName} ${item.customerObj?.lastName}`,
              total: item?.totals?.total,
            }))
            .value(),
          5,
        )}
        columns={isMobile ? ordersMobile : orders}
        rowHeight={72}
      />
      <div className={classes.labelContainer}>
        <Typography variant="h2">Current inventory</Typography>
        <Link onClick={(): void => navigate('/products')} className={classes.link}>
          View all products
        </Link>
      </div>
      <DataGrid
        hideFooter
        rows={setData(props.productsData, 5)}
        onCellClick={handleInventoryCellClick}
        columns={isMobile ? lowInventoryMobile : lowInventory}
        rowHeight={96}
        loading={isProductsLoading}
        sortModel={[{ field: 'inventory', sort: 'asc' }]}
      />
    </div>
  );
};

export default Tables;
