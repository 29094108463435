import React from 'react';
import { ButtonBase, Grid, makeStyles, Theme } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { SvgIcon, Typography } from '@castiron/components';
import TrashIcon from '../../../../../assets/img/trash.svg';

type Props = {
  onFileDrop: (files) => void;
  onDeleteClick?: () => void;
  image: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: '8px'
  },
  imageContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden',
    marginTop: 9,
  },
  image: {
    width: '100%',
    maxHeight: 216,
  },
  iconButton: {
    zIndex: 20,
    top: 0,
    backgroundColor: theme.palette.common.white,
    left: 0,
    position: 'absolute',
    borderBottomRightRadius: 4,
    width: 32,
    height: 32,
  },
  icon: {
    color: theme.branding.gray[800],
    width: 38,
    height: 38,
  },
  uploadContainer: {
    padding: 16,
  },
}));

const CoverImageUploader: React.FC<Props> = (props: Props) => {
  const { onFileDrop, onDeleteClick, image } = props;
  const classes = useStyles();
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: onFileDrop,
    maxFiles: 1,
    noClick: true,
  });

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography variant="subtitle1">Cover Photo</Typography>
      <Typography variant="body4">Photo to style your shop. Size recommended: 1440px x 500px.</Typography>
      <Grid container className={classes.imageContainer}>
        {image &&
          <Grid container item xs={12}>
            <ButtonBase className={classes.iconButton} onClick={onDeleteClick}>
              <SvgIcon className={classes.icon}>
                <TrashIcon />
              </SvgIcon>
            </ButtonBase>
            <img className={classes.image} src={image} />
          </Grid>
        }
        <Grid container item xs={12} justify="center" {...getRootProps()} className={classes.uploadContainer}>
          <input {...getInputProps()} />
          <ButtonBase onClick={open}>
            <Typography variant="subtitle2" color="primary">Replace Photo</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoverImageUploader;
