import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

type Props = {
  msg: string;
};

const useStyles = makeStyles(() => ({
  error: {
    color: '#ff2b26',
    marginTop: '10px;',
  },
}));

const ValidationError: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" className={classes.error}>
      {props.msg}
    </Typography>
  );
};

export default ValidationError;
