import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Customer } from '@castiron/domain';
import { customerRepository } from '../../domain';
import { handleReducerError } from './errorHandler';
import { getService } from "../../firebase";

const createOrUpdateCustomerService = getService('customers', 'createOrUpdateCustomer');
const bulkCreateOrUpdateCustomerService = getService('customers', 'bulkCreateOrUpdateCustomer');

export interface CustomersState {
  customers: Customer[];
  loading: boolean;
  error: string;
}

const initialState: CustomersState = {
  customers: [],
  loading: false,
  error: '',
};

const getCustomers = async (shopId: string): Promise<Customer[]> => {
  const response = await customerRepository.findByShopId(shopId);
  return response;
};

const getCustomerById = async (id: string): Promise<Customer> => {
  const response = await customerRepository.get(id);
  return response;
};

const createCustomer = async (customer: Customer): Promise<Customer> => {
  const response = await createOrUpdateCustomerService(customer);
  return response as Customer;
};

const updateCustomer = async (customer: Partial<Customer>): Promise<Customer> => {
  const data = customer.data ? customer.data() : customer;
  const response = await createOrUpdateCustomerService(data);
  return response as Customer;
};

const batchCustomer = async (customerList: Customer[]): Promise<void> => {
  await bulkCreateOrUpdateCustomerService({
    customers: customerList,
  });
  return;
};

export const getCustomersAction = createAsyncThunk('customers/getCustomers', getCustomers);
export const getCustomerByIdAction = createAsyncThunk('customers/getCustomerById', getCustomerById);
export const createCustomerAction = createAsyncThunk('customers/createCustomer', createCustomer);
export const updateCustomerAction = createAsyncThunk('customers/updateCustomer', updateCustomer);
export const batchCustomerAction = createAsyncThunk('customers/batchCustomer', batchCustomer);

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCustomersAction.pending, state => {
        state.loading = true;
      })
      .addCase(getCustomersAction.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.loading = false;
      })
      .addCase(getCustomersAction.rejected, handleReducerError('Error Getting Customers'))
      .addCase(createCustomerAction.rejected, handleReducerError('Error creating customer'));
  },
});

export default customersSlice.reducer;
