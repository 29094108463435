import { SvgIcon as MUISvgIcon, SvgIconProps as MUISvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type SvgIconProps = Override<MUISvgIconProps, {}>;

const useStyles = makeStyles(() => ({
  svg: {
    '& > svg > path': {
      fill: 'currentColor',
    },
  },
}));

// Svg icon that inherits the color assigned on parent's 'color' style property
const SvgIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const { children, className, viewBox, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <MUISvgIcon viewBox={props.viewBox || "0 0 32 32"} className={`${classes.svg} ${className}`} {...restOfProps}>
      {children}
    </MUISvgIcon>
  );
};

export default SvgIcon;
