import { FeatureName } from "@castiron/domain";
import React from "react";

export interface FeatureContextData {
  features: FeatureName[];
}

const FeatureContext = React.createContext<FeatureContextData>({
  features: []
});

export default FeatureContext;
