import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyContactsIcon = (props: SvgIconProps) => (
  <SvgIcon width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="80" cy="60" r="60" fill="#E0ECFF" fill-opacity="0.48" />
    <g clip-path="url(#clip0_941_38578)">
      <path
        d="M104.075 53.4108C103.892 54.3681 103.671 55.3156 103.414 56.2534C103.386 56.3623 103.355 56.4696 103.324 56.5785H99.7491C99.4446 56.5788 99.1527 56.6988 98.9374 56.912C98.7221 57.1253 98.601 57.4145 98.6007 57.7161V67.8123C98.4923 68.0122 98.3824 68.2105 98.2725 68.4088C97.7049 69.4327 97.1143 70.4452 96.5105 71.4447C86.6658 87.6902 72.9352 101.285 56.5308 111.028L57.5021 108.857C57.146 108.832 56.7884 108.808 56.4324 108.784C48.6806 108.259 40.9025 107.677 33.3328 105.951C25.4136 104.145 17.6668 101.013 11.4604 95.8205C5.2523 90.6261 0.701322 83.2084 0.0696756 75.1829C-0.140945 72.4247 0.140638 69.6513 0.901509 66.9899C2.35666 61.8671 5.5362 57.2578 10.1233 54.5306C17.3288 50.2481 26.7278 51.1208 34.3237 54.6834C41.918 58.2444 48.0998 64.1392 54.1356 69.9316C48.0416 59.5791 39.5568 50.8055 29.3773 44.3305C26.0042 42.1916 22.4245 40.2868 19.5567 37.5157C16.6906 34.7463 14.5758 30.8635 15.0828 26.9287C15.6586 22.4658 19.3484 19.0787 23.0446 16.4669C24.5211 15.4235 26.0453 14.4337 27.617 13.5236C32.0515 10.9491 36.8486 9.00696 41.9147 8.28697C48.7774 7.31018 56.1896 8.79242 61.451 13.2652C67.3784 18.3051 69.8098 26.433 69.5785 34.2083C73.2304 28.949 78.8626 24.9232 85.2232 24.7525C91.8266 24.577 98.1036 28.71 101.411 34.3757C104.717 40.0398 105.311 46.9829 104.075 53.4108Z"
        fill="#E0ECFF"
      />
      <path
        d="M62.296 75.2537C66.1636 74.9162 69.7364 74.522 73.4842 73.4714C77.1171 72.453 80.6128 70.7482 83.199 67.9646C85.9806 64.9706 87.4821 61.0824 88.5415 57.2117C89.1321 55.0537 89.6175 52.8655 90.0754 50.6765C90.5723 48.3015 91.0138 45.916 91.3998 43.5199C92.1684 38.7506 92.7171 33.9531 93.0459 29.1276C93.0856 28.5419 93.122 27.9559 93.1551 27.3698C93.1757 27.0082 92.6079 27.0093 92.5875 27.3698C92.3174 32.1263 91.8315 36.8644 91.1298 41.5839C90.4374 46.2208 89.5514 50.8376 88.4274 55.3914C87.4751 59.2491 86.2422 63.1671 83.7999 66.3786C81.5097 69.3901 78.2019 71.358 74.6302 72.5347C70.9959 73.732 67.1639 74.21 63.366 74.5655C62.9001 74.6091 62.7622 74.6506 62.296 74.6913C61.9349 74.7228 61.9316 75.2854 62.296 75.2536L62.296 75.2537Z"
        fill="white"
      />
      <path
        d="M1.22916 67.4048C6.10889 67.6729 10.939 68.5161 15.6173 69.9165C20.2977 71.314 24.7953 73.2535 29.0171 75.6946C33.0964 78.0553 36.8834 80.8799 40.3011 84.1112C43.618 87.249 46.5742 90.7408 49.1156 94.5229C51.6686 98.3145 53.7843 102.378 55.4231 106.637C55.6229 107.158 55.8154 107.682 56.0006 108.208C56.1202 108.548 56.6688 108.402 56.5479 108.059C55.0147 103.71 52.9935 99.5457 50.5223 95.6438C48.0515 91.7416 45.1518 88.1227 41.8763 84.8534C38.5482 81.5334 34.8444 78.6052 30.8383 76.127C22.4267 70.9363 12.8923 67.7962 3.01798 66.9645C2.42234 66.915 1.82607 66.8743 1.22917 66.8424C0.864132 66.8226 0.865253 67.3851 1.22917 67.4048L1.22916 67.4048Z"
        fill="white"
      />
      <path
        d="M27.7703 14.1827C30.8903 15.1657 33.8477 16.5974 36.5477 18.4318C39.3067 20.3191 41.8229 22.5329 44.0406 25.0239C46.3777 27.6445 48.4554 30.4812 50.2453 33.495C52.1438 36.6772 53.7931 39.999 55.1786 43.4311C56.6053 46.9548 57.8052 50.5644 58.7712 54.2384C59.7532 57.9558 60.5202 61.7258 61.0685 65.5299C61.6141 69.2936 61.9442 73.0849 62.0574 76.8855C62.1714 80.5198 62.063 84.1575 61.7326 87.7788C61.4305 91.1572 60.8787 94.5091 60.0817 97.8076C59.3739 100.787 58.3764 103.691 57.1032 106.479C56.8083 107.112 56.4953 107.736 56.1644 108.352C55.9927 108.671 56.4825 108.956 56.6546 108.636C58.0616 105.972 59.1851 103.171 60.0069 100.277C60.929 97.0557 61.5974 93.7682 62.0061 90.4443C62.4591 86.8382 62.6798 83.207 62.6666 79.573C62.6585 75.7553 62.4291 71.9411 61.9796 68.1494C61.5299 64.3104 60.8622 60.4995 59.9797 56.7349C59.0993 52.9641 57.9826 49.2513 56.636 45.6182C55.3381 42.1011 53.7841 38.682 51.9863 35.3879C50.2739 32.2445 48.2784 29.2608 46.0241 26.4731C43.8832 23.8189 41.439 21.4194 38.7405 19.3226C36.1383 17.3068 33.2629 15.6637 30.1986 14.4415C29.4498 14.1462 28.6907 13.8792 27.9212 13.6405C27.5719 13.5316 27.4224 14.0743 27.7703 14.1827H27.7703Z"
        fill="white"
      />
      <path
        d="M61.4409 112C83.8663 112 102.046 111.272 102.046 110.375C102.046 109.477 83.8663 108.75 61.4409 108.75C39.0156 108.75 20.8362 109.477 20.8362 110.375C20.8362 111.272 39.0156 112 61.4409 112Z"
        fill="#E0ECFF"
      />
      <path
        d="M97.3157 59.4697C97.2956 59.5574 97.2825 59.6465 97.2765 59.7363L90.2289 63.764L88.5159 62.787L86.6897 65.1554L90.3543 67.7429L98.3945 61.4592C98.7225 61.5794 99.0796 61.5979 99.4183 61.5122C99.7571 61.4264 100.062 61.2406 100.291 60.9793C100.521 60.718 100.664 60.3936 100.703 60.0494C100.742 59.7051 100.675 59.3573 100.509 59.0522C100.344 58.747 100.088 58.499 99.7771 58.3411C99.4658 58.1832 99.1135 58.1229 98.7668 58.1682C98.4202 58.2136 98.0956 58.3624 97.8364 58.5948C97.5772 58.8273 97.3956 59.1325 97.3157 59.4697Z"
        fill="#FFB8B8"
      />
      <path
        d="M81.2654 61.9408L86.8146 65.8589L89.2965 62.7762L83.9021 58.4883C83.6701 58.3039 83.4024 58.1683 83.1156 58.0899C82.8288 58.0115 82.5288 57.9919 82.2341 58.0323C81.9393 58.0728 81.656 58.1724 81.4015 58.3252C81.1471 58.4779 80.9267 58.6805 80.754 58.9205C80.4175 59.3882 80.2824 59.9692 80.3783 60.5356C80.4742 61.102 80.7933 61.6075 81.2654 61.9408Z"
        fill="#005FFF"
      />
      <path d="M69.8105 106.647L71.7563 107.152L74.6487 99.9575L71.7771 99.212L69.8105 106.647Z" fill="#FFB8B8" />
      <path
        d="M75.0352 109.915L68.9202 108.328L69.5393 105.988L73.2916 106.962C73.9182 107.124 74.4539 107.527 74.7809 108.081C75.1079 108.635 75.1993 109.295 75.0352 109.915Z"
        fill="#27272A"
      />
      <path d="M87.1061 108.019L89.0861 107.669L88.6642 99.936L85.7419 100.453L87.1061 108.019Z" fill="#FFB8B8" />
      <path
        d="M93.2332 108.814L87.0103 109.915L86.5808 107.534L90.3994 106.858C90.7151 106.802 91.0389 106.808 91.3522 106.876C91.6654 106.944 91.9621 107.073 92.2253 107.255C92.4884 107.436 92.7128 107.668 92.8858 107.935C93.0587 108.203 93.1768 108.501 93.2332 108.814Z"
        fill="#27272A"
      />
      <path
        d="M83.2073 51.1522C83.6952 51.2698 84.0921 51.7623 84.3305 52.3329C84.5504 52.9322 84.6992 53.5547 84.7741 54.1881C84.9614 55.3675 85.1413 56.6443 84.7539 57.7366C84.2998 59.0169 83.09 59.6874 82.0405 59.3723C80.9911 59.0572 80.1564 57.8744 79.8712 56.5128C79.6116 55.1371 79.7789 53.7157 80.351 52.436C80.6491 51.7636 81.06 51.1433 81.6061 50.8399C81.874 50.6794 82.1933 50.6254 82.4997 50.6886C82.8062 50.7519 83.0771 50.9278 83.2579 51.1809"
        fill="#27272A"
      />
      <path
        d="M85.968 72.5061L89.7375 105.953L86.6242 107.124L80.8821 84.6956L72.8878 105.203L69.48 105.39C69.48 105.39 74.1557 73.4813 77.6209 72.7561L85.968 72.5061Z"
        fill="#27272A"
      />
      <path
        d="M82.8017 55.4206C85.0271 55.4206 86.8312 53.6335 86.8312 51.4288C86.8312 49.2242 85.0271 47.437 82.8017 47.437C80.5763 47.437 78.7722 49.2242 78.7722 51.4288C78.7722 53.6335 80.5763 55.4206 82.8017 55.4206Z"
        fill="#FFB8B8"
      />
      <path
        d="M84.5313 57.0953L80.1768 56.5326C80.1768 56.5326 77.337 59.9086 77.5263 65.7227C77.7156 71.5369 77.053 73.3187 77.053 73.3187L86.1321 73.4812C84.8196 71.6934 90.3093 61.4254 84.5313 57.0953Z"
        fill="#005FFF"
      />
      <path
        d="M86.8702 74.2938C86.7795 74.2945 86.6889 74.3022 86.5993 74.317L81.0287 68.4423L81.5966 66.5667L78.8514 65.3477L77.1475 69.4738L85.1621 75.7895C85.119 76.1333 85.1826 76.4819 85.3444 76.789C85.5062 77.096 85.7585 77.3471 86.0678 77.5086C86.3771 77.6702 86.7287 77.7346 87.0759 77.6934C87.423 77.6522 87.7493 77.5072 88.0113 77.2778C88.2733 77.0484 88.4586 76.7454 88.5425 76.4092C88.6264 76.0729 88.6051 75.7193 88.4812 75.3954C88.3573 75.0715 88.1368 74.7926 87.8491 74.5958C87.5613 74.399 87.2199 74.2937 86.8702 74.2938Z"
        fill="#FFB8B8"
      />
      <path
        d="M80.7689 59.3809L78.1887 65.6289L81.7859 67.3169L84.7649 61.1336C84.893 60.8675 84.965 60.5784 84.9764 60.2838C84.9878 59.9893 84.9384 59.6955 84.8312 59.4206C84.724 59.1456 84.5613 58.8952 84.353 58.6847C84.1446 58.4743 83.8951 58.3081 83.6198 58.1965C83.3541 58.0888 83.0696 58.0341 82.7825 58.0353C82.4954 58.0365 82.2114 58.0938 81.9467 58.2038C81.682 58.3137 81.4417 58.4743 81.2396 58.6763C81.0375 58.8782 80.8776 59.1177 80.7689 59.3809Z"
        fill="#005FFF"
      />
      <path
        d="M81.7478 55.058C82.3792 54.6971 82.4465 53.8267 82.3607 53.1096C82.2749 52.3926 82.1316 51.5932 82.5571 51.0069C83.0649 50.3071 84.0851 50.2932 84.9547 50.2703C85.8244 50.2475 86.8834 49.9952 87.1087 49.1628C87.2576 48.6128 86.9391 48.0226 86.482 47.6773C86.0248 47.3321 85.4529 47.1794 84.8971 47.0343C83.6536 46.7098 82.3697 46.3826 81.0964 46.5609C79.8353 46.7376 78.6951 47.399 77.9225 48.4022C77.15 49.4053 76.8072 50.6694 76.9683 51.9211C77.1743 53.5221 77.4732 54.2212 77.277 54.9272C76.9116 56.2421 75.336 57.0868 75.3047 58.4502C75.2801 59.5167 76.231 60.3394 77.1588 60.881C77.8947 61.3105 78.6889 61.675 79.537 61.7755C80.3851 61.8761 81.2982 61.6836 81.93 61.1141C82.7448 60.3797 82.9464 59.1526 82.7127 58.0861C82.479 57.0196 81.8797 56.0725 81.2868 55.1527"
        fill="#27272A"
      />
      <rect x="98.4333" y="56.1666" width="60.5333" height="40.6" rx="5.5" fill="white" stroke="#D4D4D8" />
      <path
        d="M112.18 74.6994H102.993C102.623 74.699 102.269 74.5533 102.008 74.2943C101.746 74.0353 101.599 73.6842 101.599 73.3179V64.2164C101.599 63.8502 101.746 63.499 102.008 63.2401C102.269 62.9811 102.623 62.8354 102.993 62.835H112.18C112.55 62.8354 112.905 62.9811 113.166 63.2401C113.427 63.499 113.575 63.8502 113.575 64.2164V73.3179C113.575 73.6842 113.427 74.0353 113.166 74.2943C112.905 74.5533 112.55 74.699 112.18 74.6994Z"
        fill="#005FFF"
      />
      <path
        d="M154.957 84.3713H102.786C102.503 84.3713 102.232 84.26 102.032 84.0618C101.832 83.8637 101.719 83.595 101.719 83.3148C101.719 83.0347 101.832 82.766 102.032 82.5678C102.232 82.3697 102.503 82.2584 102.786 82.2584H154.957C155.24 82.2584 155.511 82.3697 155.711 82.5678C155.911 82.766 156.023 83.0347 156.023 83.3148C156.023 83.595 155.911 83.8637 155.711 84.0618C155.511 84.26 155.24 84.3713 154.957 84.3713Z"
        fill="#D4D4D8"
      />
      <path
        d="M154.992 79.2518H102.821C102.538 79.2518 102.267 79.1405 102.067 78.9423C101.867 78.7442 101.754 78.4755 101.754 78.1953C101.754 77.9152 101.867 77.6465 102.067 77.4483C102.267 77.2502 102.538 77.1389 102.821 77.1389H154.992C155.275 77.1389 155.546 77.2502 155.746 77.4483C155.946 77.6465 156.058 77.9152 156.058 78.1953C156.058 78.4755 155.946 78.7442 155.746 78.9423C155.546 79.1405 155.275 79.2518 154.992 79.2518Z"
        fill="#D4D4D8"
      />
      <path
        d="M129.234 72.2615H121.524C121.241 72.2615 120.97 72.1502 120.77 71.9521C120.57 71.754 120.457 71.4853 120.457 71.2051C120.457 70.9249 120.57 70.6562 120.77 70.4581C120.97 70.26 121.241 70.1487 121.524 70.1487H129.234C129.517 70.1487 129.789 70.26 129.989 70.4581C130.189 70.6562 130.301 70.9249 130.301 71.2051C130.301 71.4853 130.189 71.754 129.989 71.9521C129.789 72.1502 129.517 72.2615 129.234 72.2615Z"
        fill="#D4D4D8"
      />
      <path
        d="M154.992 67.0622H121.524C121.241 67.0622 120.97 66.9509 120.77 66.7528C120.57 66.5546 120.457 66.2859 120.457 66.0058C120.457 65.7256 120.57 65.4569 120.77 65.2588C120.97 65.0606 121.241 64.9493 121.524 64.9493H154.992C155.275 64.9493 155.546 65.0606 155.746 65.2588C155.946 65.4569 156.058 65.7256 156.058 66.0058C156.058 66.2859 155.946 66.5546 155.746 66.7528C155.546 66.9509 155.275 67.0622 154.992 67.0622Z"
        fill="#D4D4D8"
      />
      <path
        d="M127.922 89.5721H102.786C102.503 89.5721 102.232 89.4608 102.032 89.2627C101.832 89.0645 101.719 88.7958 101.719 88.5157C101.719 88.2355 101.832 87.9668 102.032 87.7686C102.232 87.5705 102.503 87.4592 102.786 87.4592H127.922C128.205 87.4592 128.476 87.5705 128.676 87.7686C128.876 87.9668 128.988 88.2355 128.988 88.5157C128.988 88.7958 128.876 89.0645 128.676 89.2627C128.476 89.4608 128.205 89.5721 127.922 89.5721Z"
        fill="#D4D4D8"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_38578">
        <rect width="159.467" height="104" fill="white" transform="translate(0 8)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
