import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton as MUIToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab';
import clsx from 'clsx';

export interface ToggleButtonOption {
  value: string;
  label: string;
}

export interface CustomToggleButtonGroupProps extends ToggleButtonGroupProps {
  buttonClassName?: string;
  buttonOptions: ToggleButtonOption[];
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: 'none',
  },
  buttonGroup: {
    border: `1px solid ${theme.branding.gray[400]}`,
    padding: 4,
    borderRadius: 12,
    'border-top-left-radius': 12,
    'border-bottom-left-radius': 12,
    'border-top-right-radius': 12,
    'border-bottom-right-radius': 12,

    '& button.MuiToggleButton-root.Mui-selected': {
      //active button
      backgroundColor: theme.branding.blue.light,
      color: theme.branding.blue.dark,
      '&:hover': {
        backgroundColor: theme.branding.blue.light,
        color: theme.branding.blue.dark,
      },
    },
    '& button.MuiToggleButton-root': {
      //inactive button & button root
      backgroundColor: theme.branding.gray[100],
      color: theme.branding.gray[800],
      borderRadius: 12,
      'border-top-left-radius': 12,
      'border-bottom-left-radius': 12,
      'border-top-right-radius': 12,
      'border-bottom-right-radius': 12,
      padding: '12px 8px',

      '&:hover': {
        backgroundColor: theme.branding.gray[300],
        color: theme.branding.gray[800],
      },
    },
  },
}));

const ToggleButton: React.FC<CustomToggleButtonGroupProps> = (props: CustomToggleButtonGroupProps) => {
  const { buttonClassName, buttonOptions, className, onChange, ...restOfProps } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState<string | null>(null);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  return (
    <ToggleButtonGroup
      className={clsx([classes.buttonGroup, className])}
      value={value}
      exclusive
      onChange={handleChange}
      {...restOfProps}
    >
      {buttonOptions.map(opt => (
        <MUIToggleButton
          key={opt.value}
          value={opt.value}
          aria-label={opt.label}
          className={clsx([classes.button, buttonClassName])}
        >
          {opt.label}
        </MUIToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButton;
