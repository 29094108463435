import React, { ReactNode } from 'react';
import {
  Grid,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

export interface FooterProps {
  optFooterFormat: string;
  footerCTAs: ReactNode[];
};


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    backgroundColor: theme.branding.gray[100],
    position: 'fixed',
    bottom: 0,
    /* for some reason the window is pushed over by the side drawer,
     * fastest way I could fix it
     */
    width: 'calc(100% - 280px)',
    zIndex: 50,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cta: {
    padding: 4
  },
  doubleCta: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '8px 32px 16px 0px',
    },
  },
  doubleCtaSpaced: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 16
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px 20px'
    }
  },
  singleCta: {
    padding: '16px 16px 36px',
    width: '100%'
  },
  tripleCTA: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px'
    },
  },
}));

const StickyFooter: React.FC<FooterProps> = (props: FooterProps) => {
  const { optFooterFormat, footerCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderFooterFormat = () => {
    if (optFooterFormat !== '') {
      return (
        <Grid container alignItems='center'>
          <Grid item className={classes.doubleCtaSpaced}>
            {footerCTAs.map((cta, index) => <div className={classes.cta} key={`footerCTA${index}`}>{cta}</div>)}
          </Grid>
        </Grid>
      )
    } else {
      switch (footerCTAs.length) {
        case 0:
          break;
        case 1:
          return (
            <Grid container justify='flex-start' alignItems='center'>
              <Grid item className={classes.singleCta}>
                {footerCTAs.map((cta, index) => <div className={classes.cta} key={`footerCTA${index}`}>{cta}</div>)}
              </Grid>
            </Grid>
          )
        case 2:
          return (
            <Grid container wrap='nowrap' justify='flex-end' alignItems='center'>
              <Grid item className={classes.doubleCta}>
                {footerCTAs.map((cta, index) => <div className={classes.cta} key={`footerCTA${index}`}>{cta}</div>)}
              </Grid>
            </Grid>
          )
        case 3:
          return (
            <Grid container wrap='nowrap' justify='space-between' alignItems='center' className={classes.tripleCTA}>
              <Grid container item wrap='nowrap' xs={3}>
                {footerCTAs[0]}
              </Grid>
              <Grid spacing={2} container item alignItems='center' justify='flex-end' xs={9}>
                {footerCTAs[1]}
                {footerCTAs[2]}
              </Grid>
            </Grid>
          )
      }
    }
  }


  return (
    <div className={classes.container}>
      {renderFooterFormat()}
    </div>
  );
};

export default StickyFooter;
