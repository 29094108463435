import { FeatureName } from "@castiron/domain";
import useFeatures from './useFeatures';

const useRequireFeature = (feature: FeatureName, redirect?: string) => {
  const features = useFeatures();

  if (!features.includes(feature)) {
    window.location.href = redirect || '/';
  }
};

export default useRequireFeature;