import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";
import Dinero from "dinero.js";
import clsx from "clsx";

export interface Props {
  enabled?: boolean;
  onEdit: (value: string) => void;
  type?: 'text' | 'money' | 'number';
  value: string | number | boolean;
  placeholder?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  editHover: {
    cursor: 'text',
    backgroundImage: 'linear-gradient(to right, #B8B8B8 50%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x',
  }
}));


const Editable: React.FC<Props> = (props: Props) => {
  const {enabled, value, onEdit, placeholder, className} = props;
  const type = props.type || 'text';
  const classes = useStyles();

  const onBlur = (event) => {
    let value;
    switch (type) {
      case 'money':
        value = Math.round(parseFloat(event.target.innerText) * 100);
        break;
      case 'number':
        value = parseInt(event.target.innerText);
        break;
      default:
        value = event.target.innerText;
        break;
    }

    onEdit(value);
  }

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which
    const string = String.fromCharCode(keyCode);
    if (type === 'number' || type === 'money') {

      const regex = /[0-9,]|\./

      if (!regex.test(string)) {
        event.returnValue = false
        if (event.preventDefault) event.preventDefault();
      }
    }

    if (keyCode == 13) {
      if (event.preventDefault) event.preventDefault();
      onBlur(event);
    }
  };

  const onClick = (event) => {
    if (enabled) event.stopPropagation();
    if (enabled && event.target.innerText === placeholder) {
      event.target.innerHTML = '';
    }
  }

  const formattedValue = (type === 'money')
    ? value ? Dinero({amount: value as number}).toFormat('0.00') : '-'
    : value;

  const finalValue = (formattedValue) ? formattedValue : (enabled && placeholder) ? <i>{placeholder}</i> : undefined;

  let finalClasses;
  if (enabled) {
    finalClasses = clsx([classes.editHover, className]);
  } else if (className) {
    finalClasses = className;
  }

  return (
    <>
      {type === 'money' && '$'}
      <div
        contentEditable={enabled}
        onBlur={onBlur}
        onClick={onClick}
        className={finalClasses}
        onKeyPress={onKeyPress}
        suppressContentEditableWarning={true}
        disable-click="true"
      >
        {finalValue}
      </div>
    </>
  );

};

export default Editable;
