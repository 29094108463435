import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Account, areSubscriptionFeaturesActive, ChecklistValues, Shop } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { listAllTransactionsAction } from '../../store/reducers/transactions';
import queryString from 'query-string';
import './App.scss';
import Layout from '../Layout/index';
import * as Sentry from '@sentry/react';

// Routes
import AuthLayout from '../Layout/AuthLayout';
import Login from '../Auth/Login/index';
import ForgotPassword from '../Auth/ForgotPassword';
import Dashboard from '../Dashboard';
import Products from '../Products/Products';
import EditProduct from '../Products/EditProduct';
import Orders from '../Orders';
import Marketing from '../Messaging';
import EditUpdateEmail from '../Messaging/EditUpdateEmail';
import NewProductEmail from '../Messaging/SingleSendTemplates/NewProductEmail';
import CustomOrderAvailableForm from '../Messaging/SingleSendTemplates/CustomOrderAvailableEmail';
import Customers from '../Customers/Customers';
import Analytics from '../Analytics';
import CustomerView from '../Customers/CustomerView';
import AddCustomers from '../Customers/AddCustomers';
import Payments from '../Store/Payments';
import SettingsDashboard from '../Store/SettingsDashboard';
import Appearance from '../Store/Website/Forms/Appearance';
import BusinessDetails from '../Store/Website/Forms/BusinessDetails';
import Gallery from '../Store/Website/Forms/Gallery';
import { Community } from '../Community';
import Resources from '../Resources/Resources';
import Coupons from '../Coupons';
import EditCoupon from '../Coupons/EditCoupon';
import SubscriptionPlans from '../Store/SubscriptionPlans';
// ------------------------------------------------------
import { getMyselfAction, setJustLoggedIn, signOutAction } from '../../store/reducers/users';
import { getCommunityPackagesAction } from '../../store/reducers/community';
import RootModal from '../RootModal/RootModal';
import { FeatureFlag, FirebaseContext } from '@castiron/castiron-firebase';
import AccountSettings from '../AccountSettings';
import ContactUs from '../ContactUs';
import OrderView from '../Orders/OrderView';
import { getShopAction, setAccount, setFromOnboarding, setRedirectUrl, setShop, setStripeRefresh } from '../../store/reducers/shops';
import Spinner from '../Spinner';
import EditNewSubscriberEmail from '../Messaging/EditNewSubscriberEmail';
import SetupChecklist from '../SetupChecklist';
import Onboarding from '../Onboarding';
import OnboardingV4 from '../Onboarding/v4/Onboarding';
import V2OnboardingSignup from '../Onboarding/v2/OnboardingSignUp';
import CategoryAndAllergens from '../Onboarding/v1/CategoryAndAllergens';
import ShopLaunchStep from '../Onboarding/v1/ShopLaunchStep';
import SegmentPageTracking from '../SegmentPageTracking';
import ThanksNotUS from '../ThanksNotUS';
import { CommunityPackageDetails } from '../Community/CommunityPackageDetails';
import { Flibble } from '@castiron/components';
import { User } from '../../models/User';
import RequireValidEmail from '../Auth/RequireValidEmail';
import ExistingAccount from '../Auth/ExistingAccount';
import QuotesView from '../Quotes/QuotesView';
import EditQuote from '../Quotes/EditQuote';
import CustomersRedirect from '../CustomersRedirect';
import Calendar from '../Calendar';
import Fulfillment from '../Store/Fulfillment';
import EditFulfillment from '../Store/Fulfillment/Edit';
import SearchTest from '../SearchTest';
import CalendarPreview from '../Calendar/Preview';
import Home from '../Home';
import GatedRoute from "../GatedRoute";
import Unpaid from "../Unpaid";
import { setDebugParam } from "../../store/reducers/debug";
import GenerateMenuTest from "../GenerateMenuTest";

const requireValidEmail = (user: User, shop: Shop): boolean => false; //Disabling for now until we can take on the full validation flow from Google
//shop?.tags?.includes('MadLibsOnboarding') && !user?.isEmailValid;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const FirebaseApp = useContext(FirebaseContext);
  const { trackUser } = useTracking();
  const {
    me,
    fromOnboarding,
    hasLoaded,
    modalProps,
    modalType,
    shop,
    account,
    isOnboarding,
    justLoggedIn,
    tier,
    subscription,
    transactions
  } = useAppSelector(state => ({
    hasLoaded: state.shops.hasLoaded,
    me: state.users.me,
    fromOnboarding: state.shops.fromOnboarding,
    modalProps: state.modal.modalProps,
    modalType: state.modal.modalType,
    shop: state.shops.shop,
    account: state.shops.account,
    isOnboarding: state.shops.isOnboarding,
    justLoggedIn: state.users.justLoggedIn,
    transactions: state.transactions.transactions,
    tier: state.shops.account?.tier,
    subscription: state.shops.account?.subscription
  }));

  const [pageTitle, setPageTitle] = useState('');
  const [headerCTAs, setHeaderCTAs] = useState<ReactNode[]>([]);
  const [footerCTAs, setFooterCTAs] = useState<ReactNode[]>([]);
  const [backLocation, setBackLocation] = useState<boolean | string>(false);
  const [optFooterFormat, setOptFooterFormat] = useState('');
  const [longTitle, setLongTitle] = useState(false);
  const [displayNav, setDisplayNav] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);

  const baseLayoutPageProps = {
    setPageTitle: setPageTitle,
    setBackLocation: setBackLocation,
    setLongTitle: setLongTitle,
    setOptFooterFormat: setOptFooterFormat,
    setHeaderCTAs: setHeaderCTAs,
    setFooterCTAs: setFooterCTAs,
    setDisplayNav: setDisplayNav,
  };

  const [setupChecklistMode, setSetupChecklistMode] = useState<'prelaunch' | 'postlaunch' | 'selling'>(null);
  const [isShopListenerStarted, setIsShopListenerStarted] = useState<boolean>(false);
  const [isAccountListenerStarted, setIsAccountListenerStarted] = useState<boolean>(false);
  const [isUnpaid, setIsUnpaid] = useState<boolean>(false);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search));

    if (params.logout === 'true') {
      dispatch(signOutAction('/'));
    }

    if (params.referralCode) {
      sessionStorage.setItem('friendbuy-referralCode', params.referralCode);
    }

    const debugParams = Object.keys(params).filter(p => p.startsWith('debug.'));

    debugParams.forEach(p => {
      const path = p.substring(6);
      const value = params[p];
      dispatch(setDebugParam({
        path,
        value
      }));
    });

  }, []);

  useEffect(() => {
    setIsUnpaid((tier?.name !== 'Starter' && tier?.name !== 'Founder') && !areSubscriptionFeaturesActive(subscription));
  }, [tier, subscription, account]);

  useEffect(() => {
    if (shop?.id) {
      Sentry?.setContext('shop', {
        id: shop.id,
        name: shop.businessName,
        websiteUrl: shop.websiteUrl,
      });
      Sentry?.withScope(scope => {
        scope.setTag('shopId', shop.id);
      });
      const isActive = shop?.status === 'active';
      const hasSold = transactions?.some(tx => tx.transactionStatus === 'succeeded');
      if (isActive && hasSold) {
        setSetupChecklistMode('selling');
      } else if (isActive) {
        setSetupChecklistMode('postlaunch');
      } else {
        setSetupChecklistMode('prelaunch');
      }
      dispatch(getCommunityPackagesAction(shop.id));

      if (!isShopListenerStarted && shop.listen) {
        console.debug('Starting Shop Listener!');
        setIsShopListenerStarted(true);
        shop.listen((s: Shop, source) => {
          if (source === 'remote') {
            dispatch(setShop(s));
          }
        });
      }
    }
  }, [shop, transactions]);

  useEffect(() => {
    if (account && !isAccountListenerStarted && account.listen) {
      console.debug('Starting Account Listener!');
      setIsAccountListenerStarted(true);
      account.listen((a: Account) => {
        dispatch(setAccount(a));
      });
    }
  }, [account]);

  const getToFromQueryString = () => {
    const qs = queryString.parse(location.search);
    console.log('qs: ', qs);
    if (qs.to) {
      return qs.to;
    }

    return '';
  };

  const getStripeRefreshFromQueryString = () => {
    const qs = queryString.parse(location.search);
    console.log('qs: ', qs);
    if (qs.refresh) {
      return qs.refresh;
    }

    return '';
  };

  useEffect(() => {
    const redirectUrl = getToFromQueryString().toString();
    const isStripeRefresh = getStripeRefreshFromQueryString();
    dispatch(setRedirectUrl(redirectUrl));
    dispatch(setStripeRefresh(isStripeRefresh));

    FirebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.metadata.creationTime)
          dispatch(
            getMyselfAction({
              email: user.email,
              uid: user.uid,
              displayName: user.displayName,
              isEmailValid: user.emailVerified,
              createdAt: Date.parse(user.metadata.creationTime),
            }),
          );
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (me) {
      if (!shop && !isOnboarding) dispatch(getShopAction(me.uid));
      trackUser();
      Sentry?.setUser({
        id: me.uid,
        email: me.email,
      });
    }
    if (shop) {
      dispatch(listAllTransactionsAction(shop.id));
    }
  }, [me, shop]);

  const isAuthed = useCallback(() => {
    return isOnboarding ? false : !!me;
  }, [me, isOnboarding]);

  useEffect(() => {
    if (me && shop && justLoggedIn && setupChecklistMode != null) {
      dispatch(setJustLoggedIn(false));

      if (account.status === 'onboarding' && account?.onboardingQuestions?.onboardingStep?.version === 4) {
        history.push(`/signup/info/${account?.onboardingQuestions?.onboardingStep?.step || 1}`);
      }
    }
  }, [me, shop, setupChecklistMode]);

  const authenticatedRoutes = (
    <SegmentPageTracking isUnauthed={ false }>
      <Layout
        pageTitle={ pageTitle }
        backLocation={ backLocation }
        longTitle={ longTitle }
        optFooterFormat={ optFooterFormat }
        headerCTAs={ headerCTAs }
        footerCTAs={ footerCTAs }
        displayNav={ displayNav }
      >
        <Route path="/" exact>
          {setupChecklistMode === 'prelaunch' && <Home {...baseLayoutPageProps} />}
          {setupChecklistMode === 'postlaunch' && <SetupChecklist {...baseLayoutPageProps} />}
          {setupChecklistMode === 'selling' && <Dashboard {...baseLayoutPageProps} />}
        </Route>
        <Route path="/genmenu">
          <GenerateMenuTest/>
        </Route>
        <Route path="/new" exact>
          <Home {...baseLayoutPageProps} />
        </Route>
        <Route path="/search" exact>
          <SearchTest { ...baseLayoutPageProps } />
        </Route>
        <GatedRoute feature="admin.products" path="/products" exact>
          <Products { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/add/:type">
          <EditProduct { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/standard/:communityPackageId">
          <EditProduct { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/custom/:productTemplateId">
          <EditProduct { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.products" path="/products/edit/:id?">
          <EditProduct { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders" exact>
          <Orders { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders/add" exact>
          <OrderView { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.orders" path="/orders/edit/:id?" exact>
          <OrderView { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute
          feature="admin.quotes"
          path="/orders/customorderedit/:id?"
          exact
          render={ ({ match }) => <Redirect to={ `/quotes/edit/${ match.params.id }` }/> }
        />
        <GatedRoute feature="admin.quotes" path="/quotes" exact>
          <QuotesView { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.quotes" path="/quotes/edit/:id?" exact>
          <EditQuote { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts" exact>
          <Redirect path="/contacts" to="/contacts/overview"/>
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/overview" exact>
          <Customers { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/details/:id?" exact>
          <CustomerView { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/contacts/add" exact>
          <AddCustomers { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.contacts" path="/customers">
          <CustomersRedirect/>
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/overview">
          <Redirect path="/marketing/overview" to="/marketing"/>
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing" exact>
          <Marketing { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/edit-update-email">
          <EditUpdateEmail { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/edit-new-subscriber-email">
          <EditNewSubscriberEmail { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/new-product-email/:id?">
          <NewProductEmail { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.marketing" path="/marketing/new-custom-availability">
          <CustomOrderAvailableForm { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.analytics" path="/analytics" exact>
          <Analytics { ...baseLayoutPageProps } />
        </GatedRoute>
        <Route exact path="/store">
          <Redirect path="/store" to="/store/dashboard"/>
        </Route>
        <Route path="/store/settings" exact>
          <Redirect to="/store/dashboard"/>
        </Route>
        <Route path="/store/dashboard">
          <SettingsDashboard { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/appearance">
          <Appearance { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/gallery">
          <Gallery { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/business-details">
          <BusinessDetails { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/fulfillment" exact>
          <Fulfillment { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/fulfillment/create/:type?" exact>
          <EditFulfillment { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/fulfillment/edit/:id" exact>
          <EditFulfillment { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/payments" exact>
          <Payments { ...baseLayoutPageProps } />
        </Route>
        <Route path="/store/plans" exact>
          <SubscriptionPlans { ...baseLayoutPageProps } />
        </Route>
        <FeatureFlag name="feature_community_packages">
          <GatedRoute feature="admin.community" path="/community" exact>
            <Community { ...baseLayoutPageProps } />
          </GatedRoute>
          <GatedRoute feature="admin.community" path="/community/:type" exact>
            <Community { ...baseLayoutPageProps } />
          </GatedRoute>
          <GatedRoute feature="admin.community" path="/community/details/:id" exact>
            <CommunityPackageDetails { ...baseLayoutPageProps } />
          </GatedRoute>
        </FeatureFlag>
        <Route path="/resources" exact>
          <Resources { ...baseLayoutPageProps } />
        </Route>
        <Route path="/account-settings" exact>
          <AccountSettings { ...baseLayoutPageProps } />
        </Route>
        <Route path="/contact-us" exact>
          <ContactUs { ...baseLayoutPageProps } />
        </Route>
        <GatedRoute feature="admin.coupons" path="/store/coupons" exact>
          <Coupons { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.coupons" path="/coupons/new" exact>
          <EditCoupon {...baseLayoutPageProps} />
        </GatedRoute>
        <GatedRoute feature="admin.coupons" path="/coupons/edit/:id" exact>
          <Coupons editing { ...baseLayoutPageProps } />
        </GatedRoute>
        <GatedRoute feature="admin.calendar" path="/calendar" exact>
          <Calendar { ...baseLayoutPageProps } />
        </GatedRoute>
        <Route path="/calendar/preview" exact>
          <CalendarPreview { ...baseLayoutPageProps } />
        </Route>
      </Layout>
    </SegmentPageTracking>
  );

  const unauthenticatedRoutes = (
    <SegmentPageTracking isUnauthed={ true }>
      <Switch>
        <Route path="/signup" exact>
          <Onboarding { ...baseLayoutPageProps } />
        </Route>
        <Route path="/signup/:step" exact>
          <Onboarding { ...baseLayoutPageProps } />
        </Route>
        <Route path="/signup/account" exact>
          <V2OnboardingSignup/>
        </Route>
        <Route path="/signup/account/existing" exact>
          <ExistingAccount/>
        </Route>
        <Route path="/welcome" exact>
          <CategoryAndAllergens/>
        </Route>
        <Route path="/artisan-details" exact>
          <ShopLaunchStep/>
        </Route>
        <Route path="/thanks-not-us" exact>
          <ThanksNotUS/>
        </Route>
        <Route path="/forgot-password" exact>
          <AuthLayout>
            <ForgotPassword/>
          </AuthLayout>
        </Route>
        <Route>
          <AuthLayout>
            <Login setupChecklistMode={ setupChecklistMode }/>
          </AuthLayout>
        </Route>
      </Switch>
    </SegmentPageTracking>
  );

  const unpaidRoutes = (
    <SegmentPageTracking isUnauthed={ false }>
      <Switch>
        <Route path="/signup" exact>
          <Onboarding { ...baseLayoutPageProps } />
        </Route>
        <Route path="/signup/info/:step" exact>
          <OnboardingV4 { ...baseLayoutPageProps } />
        </Route>
        <Route>
          <Unpaid { ...baseLayoutPageProps } />
        </Route>
      </Switch>
    </SegmentPageTracking>
  )


  useEffect(() => {
    const pendingTrialStart = subscription?.status === 'pending';
    const show = !hasLoaded || pendingTrialStart;
    setShowSpinner(show);
    if (!show) {
      dispatch(setFromOnboarding(false));
    }
  }, [subscription, hasLoaded]);

  const content = isAuthed() ? (
    showSpinner ? (
      <Spinner label={subscription?.status === 'pending' ? "Baking Your Shop..." : ""} show={ showSpinner } size={ 'fullscreen' }/>
    ) : requireValidEmail(me, shop) ? (
      <RequireValidEmail user={ me }/>
    ) : (
      isUnpaid ? unpaidRoutes : authenticatedRoutes
    )
  ) : (
    unauthenticatedRoutes
  );

  return (
    <Flibble>
      <RootModal modalType={ modalType } modalProps={ modalProps }/>
      { content }
    </Flibble>
  );
};

export default App;
