import FirebaseApp from '../config/initFirebase';
import 'firebase/compat/auth';

function getCurrentUser() {
  try {
    const user = FirebaseApp.auth().currentUser;
    return user;
  } catch (err) {
    console.log('getCurrentUser Error: ', err);
  }
}

export default getCurrentUser;
