import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, DollarSignIcon, FormIcon, MessageIcon, Typography, ShopIcon, WarningIcon, WebsiteIcon } from '@castiron/components';
import { useTracking } from "@castiron/utils";
import { Box, Grid, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingStepFooter from './OnboardingStepFooter';
import { OnboardingInfo } from './Onboarding';
import { trackHubSpotContactPage } from "../../../lib/trackHubSpotContactEvent";

type Props = {
  progressStep: (values: OnboardingInfo) => void;
};

const getInnerHeight = () => {
  const { innerHeight } = window;
  return innerHeight;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 'inherit',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      height: getInnerHeight() - 50,
      maxHeight: getInnerHeight() - 50,
      padding: '24px'
    },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: theme.branding.gray[500],
      '-webkit-box-shadow': `0 0 1px ${theme.branding.gray[100]}`
    },
  },
  active: {
    border: `2px solid ${theme.branding.blue.primary} !important`
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  error: {
    backgroundColor: theme.branding.red.light,
  },
  errorInner: {
    display: 'flex',
    alignItems: 'center'
  },
  errorText: {
    color: theme.branding.red.dark,
  },
  footerContainer: {
    backgroundColor: theme.branding.gray[100],
    borderRadius: '0 0 12px 12px',
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    marginTop: '10px',
    padding: 24,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      marginTop: '0px'
    },
  },
  formWrapper: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    maxWidth: '588px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
  icon: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px',
  },
  otherIcon: {
    borderRadius: '8px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px',
  },
  mobileWrapper: {
    border: 'none',
  },
  numbers: {
    display: 'flex',
    alignItems: 'center'
  },
  optionButton: {
    height: 64,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: 0,
    borderRadius: '8px',
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      minWidth: 290,
    },
  },
  optionsHeader: {
    margin: '16px 0px',
  },
  subtitle: {
    color: theme.branding.gray[600],
  },
  zeroIcon: {
    color: theme.branding.blue.dark,
  },
  warningIcon: {
    margin: '24.5px 0px 6px 24px'
  },
}));

const HowToSell: React.FC<Props> = (props: Props) => {
  const [selectedSales, setSelectedSales] = useState([]);
  const [active, setActive] = useState(0);
  const [error, setError] = useState(false);

  const { progressStep } = props;
  const { errors, touched, values, setFieldValue, setFieldTouched }: any = useFormikContext();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sellOptions = [
    {
      displayText: 'DM to Order',
      icon: (<MessageIcon />),
      value: 'directMessageToOrder',
    },
    {
      displayText: 'Form (i.e. Google, Jotforms)',
      icon: (<FormIcon />),
      value: 'form',
    },
    {
      displayText: 'E-Commerce Apps',
      icon: (<ShopIcon />),
      value: 'eCommerceApps',
    },
    {
      displayText: 'Custom Website',
      icon: (<WebsiteIcon />),
      value: 'customWebsite',
    },
    {
      displayText: 'Not Selling Yet',
      icon: (<DollarSignIcon />),
      value: 'notSelling',
    },
    {
      displayText: 'Other',
      icon: null,
      value: 'other',
    },
  ];

  const handleClick = (value) => {
    if (selectedSales.includes(value)) {
      setSelectedSales(selectedSales.filter(sale => sale !== value));
    } else {
      setSelectedSales([...selectedSales, value])
    }
    setError(false);
  };


  const onContinue = () => {
    if (selectedSales.length !== 0 && !errors?.salesMethod) {
      setFieldValue('salesMethod', selectedSales);
      progressStep(values);
    } else {
      setError(true);
      window.scrollTo(0, 0);
    }

    trackEvent('Selling Method Captured', {
      ...values
    });

    trackHubSpotContactPage({
      email: values.email,
      selling_method__new_: selectedSales.join(',')
    }, '/signup/howtosell');
  };

  return (
    <Grid container className={classes.formWrapper}>
      <Grid item container direction='column' className={classes.content}>
        <Box className={classes.optionsHeader}>
          <Typography variant='h2'>How do you primarily sell your products today?</Typography>
          <Typography variant='body1' className={classes.subtitle}>This will help us support your transition to Castiron.</Typography>
        </Box>
        <Box>
          {error && (
            <Box className={`${classes.optionButton} ${classes.error}`}>
              <Grid container direction='row' className={classes.errorInner}>
                <Box className={classes.warningIcon}>
                  <WarningIcon htmlColor={theme.branding.red.dark} fontSize='large' />
                </Box>
                <Typography variant='body2' className={classes.errorText}>Please select at least one option.</Typography>
              </Grid>
            </Box>
          )}
          <Grid container spacing={2}>
            {sellOptions.map((option) => (
              <Grid item xs={12} sm={6}>
                <Button
                  size='medium'
                  variant='outlined'
                  onClick={(): void => {
                    handleClick(option.value);
                  }}
                  className={`${classes.optionButton} ${selectedSales.includes(option.value) ? classes.active : ''}`}
                >
                  <Grid container direction='row' className={classes.buttonContent}>
                    {option.value !== 'other' ? (
                      <Box className={classes.icon}>
                        {option.icon}
                      </Box>) : (<Box className={classes.otherIcon}>
                        {option.icon}
                      </Box>)}
                    <Typography variant='button' className={classes.numbers}>{option.displayText}</Typography>
                  </Grid>
                </Button>
              </Grid>
            ))
            }
          </Grid>
        </Box>
      </Grid>
      <Box className={classes.footerContainer}>
        <OnboardingStepFooter stepIndex={1} totalSteps={3} onContinueClick={onContinue} />
      </Box>
    </Grid >
  )
}

export default HowToSell;
