import React from 'react';
import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core';
import { FormikErrors } from 'formik';
import InputLabel from '../../InputLabel';

interface RadioOption {
  display: string;
  value: string;
}

interface Props {
  label?: string;
  required?: boolean;
  options: RadioOption[];
  value?: string;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  onChange: (value: string) => void;
}

const RadioInput: React.FC<Props> = (props: Props) => {
  const { label, required, options, value, error, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Grid container direction='column'>
      {
        label &&
        <Grid item>
          <InputLabel primaryLabel={label} required={required} />
        </Grid>
      }
      <Grid item>
        <RadioGroup value={value} onChange={handleChange}>
          {
            options.map(option => {
              return (
                <FormControlLabel
                  key={`option${option.value}`}
                  value={option.value}
                  control={<Radio />}
                  label={option.display}
                />
              );
            })
          }
        </RadioGroup>
      </Grid>
      {
        error &&
        <Grid item>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  );
};

export default RadioInput;