import React from 'react';
import { Checkbox } from '@castiron/components';
import { Box, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from '@castiron/components';
import { Field, useFormikContext } from 'formik';
import InputLabel from '../../../InputLabel';
import Tooltip from '../../../Tooltip';

type Props = {
  options: { label: string; value: string }[];
  label?: string;
  subLabel?: string;
  tooltipLabel?: string;
  columns?: number;
  name?: string;
  optionalStyling?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[700],
  },
  checkboxSelectionContainer: {
    border: '1px solid #7777773D',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkboxSelectorContainer: {
    marginTop: 12,
    marginBottom: 8,
  },
  checkboxSelectionColumn: {
    flexGrow: 0.3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 8,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraMargin: {
    padding: 26,
  },
  modifiedMargin: {
    padding: 12,
  },
  optionalSubLabelStyling: {
    color: theme.branding.gray[600],
    lineHeight: '18px'
  }
}));

const CheckboxSelector: React.FC<Props> = (props: Props) => {
  const { options, label, subLabel, columns, name, tooltipLabel, optionalStyling } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setFieldValue, values } = useFormikContext();

  let nOfColumns;
  if (columns) nOfColumns = columns;
  else nOfColumns = isMobile ? 2 : 3;

  const splitToChunks = (array, parts) => {
    let result = [];
    let placeholderArray = array.slice();
    for (let i = parts; i > 0; i--) {
      result.push(placeholderArray.splice(0, Math.ceil(placeholderArray.length / i)));
    }
    return result;
  };

  // Distribute options evenly across columns
  const getPortionOfArray = (idx): { label: string; value: string }[] => {
    const itemsPerColumn = options.length / nOfColumns;
    let start = idx * nOfColumns;
    if (options.length % nOfColumns <= idx) start++;
    let end = start + itemsPerColumn;
    if (options.length % nOfColumns === idx + 1) end++;
    return options.slice(start, end);
  };

  const handleCheckboxChange = (checked, optionValue): void => {
    if (checked) setFieldValue(name, [...values[name], optionValue]);
    else
      setFieldValue(
        name,
        values[name].filter(item => item !== optionValue),
      );
  };

  const optionsArray = splitToChunks(options, nOfColumns);

  return (
    <Box className={classes.checkboxSelectorContainer}>
      {(label || subLabel) && <InputLabel
        primaryLabel={
          <Box className={classes.textContainer}>
            {label && <Typography variant="subtitle1" className={classes.label}>
              {label}
            </Typography>}
            {subLabel && <Typography variant="subtitle1" className={`${optionalStyling && classes.optionalSubLabelStyling} ${classes.subLabel} `}>
              {subLabel}
            </Typography>}
          </Box>
        }
      />}
      <Box
        className={`${classes.checkboxSelectionContainer} ${isMobile ? classes.modifiedMargin : classes.extraMargin}`}
      >
        {/* Array(nOfColumns)
          .fill('') */}
        {optionsArray.map((optionColumn, idx) => (
          <Box key={idx} className={`${classes.checkboxSelectionColumn}`}>
            <Field name={name}>
              {({ field: { value }, form: { setFieldValue } }) =>
                //getPortionOfArray(idx).map(option => {
                optionColumn.map(option => {
                  return (
                    <Checkbox
                      key={option.value}
                      checked={value ? value.includes(option.value) : false}
                      onChange={(e, checked) => handleCheckboxChange(checked, option.value)}
                      label={option.label}
                    />
                  );
                })
              }
            </Field>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CheckboxSelector;
