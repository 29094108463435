import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { ButtonBase, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { VisibilityOutlined } from '@material-ui/icons';
import { Field, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { assets, upload } from '@castiron/castiron-firebase';
import { Button, CopyIcon, Typography } from '@castiron/components';
import { BaseProduct, ChecklistValues, CommunityPackage, CustomProduct, Product, ProductType } from '@castiron/domain';
import { isset, removeEmpty, stripHtml, useTracking } from '@castiron/utils';
import EditProductForm from './EditProductForm';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createProductAction, updateProductAction } from '../../../store/reducers/products';
import Spinner from '../../Spinner';
import { openModal } from '../../../store/reducers/modalConductor';
import currency from 'currency.js';
import { communityPackageRepository, productRepository, productTemplateRepository } from '../../../domain';
import { nanoid } from 'nanoid';
import { updateChecklistAction } from '@castiron/client-admin/src/store/reducers/shops';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import ProductActionsDropdown from './FormComponents/ProductActionsDropdown';
import GeneralModal from '../../RootModal/GeneralModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  linkCopiedText: {
    border: `1px solid ${theme.branding.gray[300]}`,
    padding: '12px 16px',
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.gray[100],
    borderRadius: 12,
    position: 'fixed',
    bottom: 24,
    [theme.breakpoints.down('xs')]: {
      boxShadow: `8px 8px 24px ${theme.branding.gray[500]}`,
    },
  },
  linkIcon: {
    border: `1px solid ${theme.branding.gray[300]}`,
    padding: 16,
    borderRadius: 12,
  },
  linkText: {
    border: `1px solid ${theme.branding.gray[300]}`,
    padding: '16px 8px 16px 16px',
    borderRadius: 12,
    marginRight: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  modalIndex: {
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.blue.light,
    borderRadius: 12,
    padding: '8px 16px',
    marginRight: 8,
  },
  postLaunchModalContainer: {
    textAlign: 'center',
  }
}));

interface Params {
  id?: string;
  type?: ProductType;
  communityPackageId?: string;
  productTemplateId?: string;
}

const numOrZero = (num?: number): number => {
  return num || 0;
};

const EditProduct: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { id, type: urlType, communityPackageId, productTemplateId } = useParams<Params>();
  const dispatch = useAppDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get('fromChecklist');
  const { trackEvent } = useTracking();

  const formikRef = useRef<FormikProps<any>>();

  const [type, setType] = useState<ProductType>(urlType);
  const [product, setProduct] = useState<BaseProduct>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false);
  const [isGettingCommunityPackage, setIsGettingCommunityPackage] = useState<boolean>(false);
  const [isGettingProductTemplate, setIsGettingProductTemplate] = useState<boolean>(false);
  const [communityPackage, setCommunityPackage] = useState<CommunityPackage>(null);
  const [placeholders, setPlaceholders] = useState(null);
  const [localImageUrl, setLocalImageUrl] = useState<string>(null);
  const [isProductLinkCopied, setIsProductLinkCopied] = useState<boolean>(false);
  const [showProductCreatedModal, setShowProductCreatedModal] = useState<boolean>(false);

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const isLive = shop.status === 'active';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditMode = isset(id);

  useEffect(() => {
    const getProduct = async id => {
      const product = await productRepository.get(id);
      setProduct({ ...product, type: product?.type || 'standard' });
      setType(product?.type || 'standard');
    };

    if (id) {
      getProduct(id);
    }

    setPageTitle('Edit Product');
    setBackLocation(true);
    
    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    const getCommunityPackageAndProduct = async () => {
      setIsGettingCommunityPackage(true);
      const result = await communityPackageRepository.get(communityPackageId);
      setCommunityPackage(result);
      if (result?.products?.length) {
        const product = result?.products[0];
        setProduct({ ...product, type: product?.type || 'standard', category: null });
        setType(product?.type || 'standard');
      }
      setIsGettingCommunityPackage(false);
    };

    if (communityPackageId) {
      getCommunityPackageAndProduct();
    }
  }, [communityPackageId]);

  useEffect(() => {
    const getProductTemplate = async () => {
      setIsGettingProductTemplate(true);
      const result = await productTemplateRepository.get(productTemplateId);
      if (result?.template) {
        const product = result?.template;
        setProduct({ ...product, type: product?.type || 'standard', category: null });
        setType(product?.type || 'standard');
      }
      setPlaceholders(result?.placeholders || null);
      setIsGettingProductTemplate(false);
    };

    if (productTemplateId) {
      getProductTemplate();
    }
  }, [productTemplateId]);

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  const handleUpdateOrCreateSuccess = (product, isEditMode) => {
    if (isEditMode || product?.status === 'inactive') {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                Product <strong>{product?.title}</strong> was {isEditMode ? 'updated' : 'created'}
              </>
            ),
          },
        }),
      );
      if (fromChecklist) {
        history.push('/');
      } else {
        history.push('/products');
      }
    } else {
      setShowProductCreatedModal(true);
    }
    setIsSubmitting(false);
  };

  const handleUpdateProduct = async (values, price) => {
    const productToEdit = {
      shopId: shop.id,
      id: product.id,
      ...values,
      price,
    };

    await dispatch(updateProductAction({ product: productToEdit, deleteImage: isImageDeleted }));
    trackEvent('Product Edited', { product: productToEdit });

    return productToEdit;
  };

  const handleCreateProduct = async (values, price) => {
    const newProduct = {
      shopId: shop.id,
      ...values,
      price,
      seoMetadata: {
        title: values.title,
        description: stripHtml(values.description),
      },
    };
    const isFirstProduct = products.length === 0;
    const createProductResponse = await dispatch(createProductAction(newProduct));
    trackEvent('Product Created', {
      product: removeEmpty({
        ...newProduct,
        productTemplateId: productTemplateId,
        communityPackage: communityPackage && {
          id: communityPackage.id,
          title: communityPackage.title,
          tags: communityPackage.tags,
          author: {
            id: communityPackage.author.id,
            owner: communityPackage.author.owner,
            businessName: communityPackage.author.businessName,
            websiteUrl: communityPackage.author.websiteUrl,
          }
        },
        method: 'admin'
      })
    });
    if (isFirstProduct) trackEvent('First Product Created', {
      product: removeEmpty({
        ...newProduct,
        productTemplateId: productTemplateId,
        communityPackage: communityPackage && {
          id: communityPackage.id,
          title: communityPackage.title,
          tags: communityPackage.tags,
          author: {
            id: communityPackage.author.id,
            owner: communityPackage.author.owner,
            businessName: communityPackage.author.businessName,
            websiteUrl: communityPackage.author.websiteUrl,
          }
        }
      })
    });
    setProduct({...newProduct, id: (createProductResponse.payload as any).id});
    if (!shop.checklistCompletions?.includes(ChecklistValues.ProductAdded)) {
      dispatch(updateChecklistAction({ shop, items: [ChecklistValues.ProductAdded] }));
    }

    return createProductResponse.payload;
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options, context) => {
    const updatedProduct: Product = {
      ...context.product,
      imageObj: { id: metadata.id, downloadUrl: downloadUrl, metadata, options },
    };

    console.debug('Updating Product with Image', updatedProduct);

    await productRepository.update(updatedProduct);

    handleUpdateOrCreateSuccess(context.product, context.isEditMode);
  };

  const handleFile = async (item, product, isEditMode) => {
    const { file, preview } = item;
    const id = nanoid();

    if (file) {
      const metadata = {
        assetType: 'product',
        id,
        shopId: shop.id,
        originalFilename: id,
        productId: product.id,
        type: file.type
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        success: handleFileUploadSuccess,
      };
      const context = {
        product,
        isEditMode,
      };

      upload(file, metadata, options, callbacks, context);
    } else {
      handleUpdateOrCreateSuccess(product, isEditMode);
    }
  };

  const submit = async values => {
    try {
      setIsSubmitting(true);
      const safePrice = currency(values.price);
      // @ts-ignore
      const price = Math.round(safePrice * 100);

      const safeStartingPrice = currency(values.startingPrice);
      // @ts-ignore
      const startingPrice = Math.round(safeStartingPrice * 100);

      const { image = '', ...newProductValues } = values;

      if (!values.image) {
        newProductValues.imageObj = null;
      }

      if (communityPackageId) {
        newProductValues.imageObj = product.imageObj;
        newProductValues.communityPackageId = communityPackageId;
      }

      const modifiedNewValues = {
        ...newProductValues,
        inventory: newProductValues.inventory || 0,
        variations: newProductValues.variations.map(variation => ({
          ...variation,
          values: variation.values.map(val => ({ ...val, cost: Math.round((val.cost || 0) * 100) })),
        })),
        type,
        startingPrice,
      };

      const responseProduct = !!isEditMode
        ? await handleUpdateProduct(modifiedNewValues, price)
        : await handleCreateProduct(modifiedNewValues, price);

      if (values.image) {
        if (!communityPackageId && (!product || values.image !== product.imageObj)) {
          handleFile(values.image, responseProduct, isEditMode);
        } else {
          handleUpdateOrCreateSuccess(modifiedNewValues, isEditMode);
        }
      } else {
        handleUpdateOrCreateSuccess(modifiedNewValues, isEditMode);
      }
    } catch (err) {
      setIsSubmitting(false);
      console.log('Error Submitting Product: ', err);
    }
  };

  const variationValuesSchema = yup.object().shape({
    name: yup.string(),
    cost: yup.number(),
  });

  const variationValuesRequiredNameSchema = yup.object({
    name: yup.string().required("Please fill in this field or remove the option if no longer needed."),
    cost: yup.number(),
  });

  const variationSchema = yup.object().shape({
    type: yup.string().required('Please choose option select type'),
    name: yup.string().required('Please enter a title'),
    values: yup
      .array()
      .when('type', (type) => {
        const requireName = type === 'select' || type === 'multiselect';
        const valuesSchema = requireName ? variationValuesRequiredNameSchema : variationValuesSchema;
        return yup.array()
          .of(valuesSchema)
          .min(1, 'Must have at least one value');
      }),
    required: yup.boolean(),
  });

  const productSchema = yup.object().shape({
    title: yup.string().required('Please enter a title'),
    description: yup.string(),
    allergen: yup.array().of(yup.string()),
    dietary: yup.array().of(yup.string()),
    inventory: yup
      .number()
      .min(0, 'Inventory amount must be positive')
      .integer('Inventory amount must be a whole number'),
    status: yup.string(),
    price: yup
      .number()
      .min(0.5, 'Price needs to be greater than $.50')
      .when('type', {
        is: 'standard',
        then: yup
          .number()
          .min(0.5, 'Price needs to be greater than $.50')
          .required('Price is required'),
      }),
    category: yup.object(),
    image: yup.object().nullable(),
    variations: yup.array().of(variationSchema),
  });

  const goBack = (): void => {
    history.push('/products');
  };

  const initalState = {
    title: product?.title || '',
    description: product?.description || '',
    allergen: product?.allergen || [],
    dietary: product?.dietary || [],
    inventory: product && product.type !== 'custom' ? (product as Product).inventory : '',
    status: product?.status || 'active',
    // @ts-ignore
    price:
      product && product.type !== 'custom' ? currency(numOrZero((product as Product).price) / 100).value : undefined,
    startingPrice:
      product && product.type === 'custom' ? currency(numOrZero((product as CustomProduct).startingPrice) / 100) : '',
    minimum: product && product.type === 'custom' ? (product as CustomProduct).minimum : '',
    policies: product && product.type === 'custom' ? (product as CustomProduct).policies : undefined,
    category: product?.category || '',
    image: product?.imageObj?.id || '',
    variations: product?.variations
      ? product.variations.map((variation, index) => ({
        ...variation,
        values: variation.values.map(val => ({
          ...val,
          cost: val.cost ? currency(val.cost / 100).value : undefined,
        })),
        position: variation.position || index
      }))
      : [],
    type: product?.type || type,
  };

  const previewProduct = useCallback(async () => {
    const { values } = formikRef.current;
    if (values) {
      let imageUrl;
      if (product?.imageObj?.downloadUrl) {
        imageUrl = product.imageObj.downloadUrl;
      } else if (values.image) {
        const firebaseResponse = await assets.get(values.image);
        imageUrl = firebaseResponse?.downloadUrl;
      }

      dispatch(
        openModal({
          modalType: values.type === 'custom' ? 'CUSTOM_PRODUCT_MODAL' : 'PRODUCT_MODAL',
          modalProps: {
            show: true,
            product: values,
            customProduct: values.type === 'custom' ? values : undefined,
            initialAmount: 1,
            imageObj: imageUrl,
          },
        }),
      );

      trackEvent('Product Preview Modal Opened', {
        product_values: values,
        shop: shop,
      });
    }
  }, [product]);

  useEffect(() => {
    const productActionsDropdown = (
      <ProductActionsDropdown
        product={product}
        isEditMode={isEditMode}
        previewProduct={previewProduct}
      />
    );
    if (isMobile) {
      setHeaderCTAs([
        productActionsDropdown,
        <ButtonBase onClick={previewProduct}>
          <VisibilityOutlined />
        </ButtonBase>
      ]);
    } else {
      setHeaderCTAs([
        <Button
          variant='outlined'
          onClick={previewProduct}
          startIcon={<VisibilityOutlined />}
        >
          Preview
        </Button>,
        productActionsDropdown
      ]);
    }
    /* need to listen to product as well, so that we get the updated `previewProduct` */
  }, [isMobile, product]);

  const productCreatedPreLaunchModal = (
    <GeneralModal 
      icon='🎉'
      title='Your product has been created!'
      content={<Typography variant='body2'>Preview your product to view what your customers will experience. Already have? Then let’s add another product!</Typography>}
      actions={[
        <Button
          variant='outlined'
          style={{padding: 16}}
          onClick={() => {
            setShowProductCreatedModal(false);
            history.push(`/products/edit/${product?.id}`);
            previewProduct();
          }}
          startIcon={<VisibilityOutlined />}
        >
          Preview
        </Button>,
        <Button
          variant='contained'
          style={isMobile ? {marginRight: 4, padding: 16, height: '100%'} : {marginRight: 12, padding: 16, height: '100%'}}
          onClick={() => {
            history.push('/products');
            dispatch(
              openModal({
                modalType: 'ADD_PRODUCT_MODAL',
                modalProps: {
                  createStandardProductLink: '/products/add/standard',
                  createCustomProductLink: '/products/add/custom',
                  modalOpen: true,
                },
              }),
            );
          }}
        >
          Add Another Product
        </Button>
      ]}
      onClose={() => {
        history.push(`/products`);
      }}
      show={showProductCreatedModal}
    />
  );

  const productCreatedPostLaunchModal = (
    <GeneralModal 
      icon='🎉'
      title='Your product has been created! What now?'
      content={
        <>
          <Grid container style={{margin: '28px 0 24px'}} justify='flex-start' wrap='nowrap'>
            <Grid item>
              <Typography variant='subtitle1' className={classes.modalIndex}>1</Typography>
            </Grid>
            <Grid container item style={{width: '85%'}}>
              <Grid item>
                <Typography variant='subtitle1' style={{marginBottom: 8, textAlign: 'left'}}>Share a link to this product on your social accounts.</Typography>
              </Grid>
              <Grid container item wrap='nowrap'>
                <Typography variant='body1' className={classes.linkText}>{process.env.REACT_APP_SHOP_URL}{shop?.websiteUrl}/product/{product?.id}</Typography>
                <IconButton
                  className={classes.linkIcon}
                  onClick={() => {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}/product/${product?.id}`);
                    setIsProductLinkCopied(true);
                    setTimeout(() => {
                      setIsProductLinkCopied(false);
                    }, 2000);
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container wrap='nowrap'>
            <Grid item>
              <Typography variant='subtitle1' className={classes.modalIndex}>2</Typography>
            </Grid>
            <Grid container item justify='flex-start'>
              <Typography variant='subtitle1' align='left'>Send an email to your customers.</Typography>
              <Typography variant='body2' align='left' style={{margin: '8px 0 16px'}}>Utilize and customize a product announcement template and send it to your existing customers. </Typography>
              <Button
                variant='contained'
                style={isMobile ? {padding: '16px 22px'} : {padding: '16px'}}
                onClick={() => {
                  history.push(`/marketing/new-product-email/${product?.id}`);
                }}
              >
                Build & Customize Email
              </Button>
            </Grid>
          </Grid>
          {isProductLinkCopied && <Typography variant='button' component='p' className={classes.linkCopiedText}>Link Copied!</Typography>}
        </>
      }
      onClose={() => {
        history.push(`/products`);
      }}
      show={showProductCreatedModal}
      containerClass={classes.postLaunchModalContainer}
    />
  );

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Add Product | Products | Castiron</title>
      </Helmet>
      {(isEditMode && !product && !type) || isSubmitting || isGettingCommunityPackage || isGettingProductTemplate ? (
        <Spinner size="relative" show={(isEditMode && !product) || isSubmitting} />
      ) : (
        <Formik
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={submit}
          validationSchema={productSchema}
          initialValues={initalState}
          innerRef={formikRef}
        >
          {({ dirty }): ReactElement => {
            return (
              <AdminForm>
                <UnsavedChangesPrompt when={dirty} />
                <Field type="hidden" name="type" value={type} />
                <EditProductForm
                  setFooterCTAs={setFooterCTAs}
                  categories={shop?.categories || []}
                  fromChecklist={fromChecklist}
                  onCancelClick={goBack}
                  prodImageObj={product?.imageObj}
                  type={type}
                  onImageAdded={() => setIsImageDeleted(false)}
                  onImageDeleted={() => setIsImageDeleted(true)}
                  localImageUrl={(url: string) => setLocalImageUrl(url)}
                  placeholders={placeholders}
                  isEditMode={isEditMode}
                />
                {isLive ? productCreatedPostLaunchModal : productCreatedPreLaunchModal}
              </AdminForm>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditProduct;
