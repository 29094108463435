
import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ShopIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.58334 5.49984H17.4167C17.9208 5.49984 18.3333 5.08734 18.3333 4.58317C18.3333 4.079 17.9208 3.6665 17.4167 3.6665H4.58334C4.07917 3.6665 3.66667 4.079 3.66667 4.58317C3.66667 5.08734 4.07917 5.49984 4.58334 5.49984ZM18.48 7.14984C18.3975 6.72817 18.0217 6.4165 17.5817 6.4165H4.41834C3.97834 6.4165 3.60251 6.72817 3.52001 7.14984L2.60334 11.7332C2.49334 12.3015 2.92417 12.8332 3.50167 12.8332H3.66667V17.4165C3.66667 17.9207 4.07917 18.3332 4.58334 18.3332H11.9167C12.4208 18.3332 12.8333 17.9207 12.8333 17.4165V12.8332H16.5V17.4165C16.5 17.9207 16.9125 18.3332 17.4167 18.3332C17.9208 18.3332 18.3333 17.9207 18.3333 17.4165V12.8332H18.4983C19.0758 12.8332 19.5067 12.3015 19.3967 11.7332L18.48 7.14984V7.14984ZM11 16.4998H5.50001V12.8332H11V16.4998Z" fill="#004CCC" />
    </svg>
  </SvgIcon>
);
