import React, { ReactNode } from 'react';
import { Chip, Logo, ProgressBar, Typography } from '@castiron/components';
import { Grid, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  children: ReactNode;
  step: number;
  header: string;
  subHeader: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
  },
  childContainer: {
    width: 588,
    margin: '0px 16px',
  },
  chip: {
    color: theme.branding.blue.primary,
    background: theme.branding.blue.light,
    marginTop: 10,
    marginBottom: 10,
  },
  header: {
    fontSize: 24,
  },
  logo: {
    height: 24,
    width: 135,
  },
  logoContainer: {
    paddingTop: 24,
  },
  progressBar: {
    padding: '24px 0px',
  },
  headerContainer: {

  }
}));

const OnboardingWrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { children, step, header, subHeader } = props;

  const numOfSteps = 5;

  return (
    <Grid container>
      <Grid container item justify="center" alignItems="center" className={ classes.logoContainer }>
        <Logo className={ classes.logo }/>
      </Grid>
      <Grid item xs={ 12 } className={ classes.progressBar }>
        <ProgressBar
          fullwidth
          barHeight={ 10 }
          borderRadiusRight={ step == numOfSteps ? 1 : 100 }
          borderRadiusLeft={ 1 }
          percentage={ step / numOfSteps }
        />
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid container item className={classes.childContainer}>
          <Grid item container className={classes.headerContainer} xs={ 12 }>
            <Grid item xs={ 12 }>
              <Chip className={ classes.chip }>{ `Question ${ step } of ${numOfSteps}` }</Chip>
            </Grid>
            <Grid container item xs={ 12 } alignContent="center">
              <Typography variant="h2" className={ classes.header }>
                { header }
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography variant="body2">{ subHeader }</Typography>
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            { children }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardingWrapper;
