import { removeEmpty } from "@castiron/utils";

export interface Contact {
  email: string;
  id?: string;
  [ field: string ]: string | number | boolean;
}


export const trackHubSpotContactEvent = (contact: Contact, event?: string) => {
  const anyWindow = window as any;
  if (anyWindow._hsq) {
    anyWindow._hsq.push(["identify", removeEmpty(contact)]);
    if (event)
      anyWindow._hsq.push(["trackCustomBehavioralEvent", {
        name: event,
        properties: {
        }
      }]);
  }
}

export const trackHubSpotContactPage = (contact: Contact, page: string) => {
  const anyWindow = window as any;
  if (anyWindow._hsq) {
    anyWindow._hsq.push(["identify", removeEmpty(contact)]);
    if (page) {
      anyWindow._hsq.push(['setPath', page]);
      anyWindow._hsq.push(['trackPageView']);
    }
  }
}