import React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Diners, GenericCard, Jcb, UnionPay } from '@castiron/components';
import visa from '../../../assets/img/payments/visa.png';
import mastercard from '../../../assets/img/payments/mastercard.png';
import amex from '../../../assets/img/payments/amex.png';
import discover from '../../../assets/img/payments/discover.png';

type Props = {
  brand?: string;
  last4?: string;
  expirationDate?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  paymentIcon: {
    height: 48,
    maxWidth: 72,
    width: 72,
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: '8px 8px 8px 0px',
  },
  paymentIconContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    width: 72,
    alignItems: 'center',
  },
}));

const PaymentMethodDisplay: React.FC<Props> = (props: Props) => {
  const { brand, last4, expirationDate } = props;
  const classes = useStyles();

  const getPaymentIcon = (brand) => {
    switch (brand) {
      case 'mastercard':
        return (<Grid item className={classes.paymentIcon} style={{ backgroundImage: `url(${mastercard})` }} />);
        break;
      case 'visa':
        return (<Grid item className={classes.paymentIcon} style={{ backgroundImage: `url(${visa})` }} />);
        break;
      case 'amex':
        return (<Grid item className={classes.paymentIcon} style={{ backgroundImage: `url(${amex})` }} />);
        break;
      case 'discover':
        return (<Grid item className={classes.paymentIcon} style={{ backgroundImage: `url(${discover})` }} />);
        break;
      case 'diners':
        return (<Grid item className={classes.paymentIconContainer}><SvgIcon viewBox="0 0 750 500" fontSize="large"><Diners /></SvgIcon></Grid>);
        break;
      case 'jcb':
        return (<Grid item className={classes.paymentIconContainer}><SvgIcon viewBox="0 0 750 500" fontSize="large"><Jcb /></SvgIcon></Grid>);
        break;
      case 'unionpay':
        return (<Grid item className={classes.paymentIconContainer}><SvgIcon viewBox="0 0 750 500" fontSize="large"><UnionPay /></SvgIcon></Grid>);
        break;
      case 'unknown':
      default:
        return (<Grid item className={classes.paymentIconContainer}><SvgIcon viewBox="0 0 750 500" fontSize="large"><GenericCard /></SvgIcon></Grid>);
    }
  };

  return (
    <Grid container direction="row" spacing={3} alignItems="center">
      {getPaymentIcon(brand)}
      <Grid item>**** **** **** {last4}</Grid>
      { expirationDate &&
        <Grid item>
          <strong>Expires:</strong> {expirationDate}
        </Grid>
      }
    </Grid>
  );
};

export default PaymentMethodDisplay;
