import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddressAutoComplete, TextInput } from '@castiron/components';
import { Formik, Form, FormikProps } from 'formik';
import { Address, Shop } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { accountRepository, shopRepository } from '../../../domain';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { updateShopAction, updateAccountBillingAddressAction } from '../../../store/reducers/shops';
import OnboardingFooter from './OnboardingFooter';
import { trackHubSpotContactPage } from '../../../lib/trackHubSpotContactEvent';

const getShopNameFromBusinessName = async (businessName: string): Promise<string> => {
  let proposedShopUrl = businessName
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^0-9a-zA-Z_-]/gi, '')
    .toLowerCase();
  const existingShops = await shopRepository.findWebsiteUrlsStartsWith(proposedShopUrl);
  const numExisting = existingShops.length;
  if (numExisting > 0) proposedShopUrl = `${proposedShopUrl}-${numExisting}`;
  return proposedShopUrl;
};

interface Props {
  step: number;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  nextStep?: () => void;
  prevStep?: () => void;
}

export interface FormProps {
  businessName: string;
  city: string;
  country: string;
  postalCode: string;
  regionName: string;
  region: string;
}

const businessInfoSchema = yup.object().shape({
  businessName: yup
    .string()
    .min(1)
    .required('Please enter your business name.'),
  city: yup.string().required(),
  country: yup.string().required(),
  postalCode: yup
    .string()
    .min(5)
    .max(5)
    .required(),
  regionName: yup.string().required(),
  region: yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    color: theme.branding.gray[700],
  },
  container: {
    paddingTop: 12,
  },
  inputContainer: {
    paddingBottom: 6,
  },
}));

const BusinessInfo: React.FC<Props> = (props: Props) => {
  const { step, nextStep, prevStep, setHeader, setSubHeader } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setHeader(`Welcome ${shop?.owner.firstName}! 👋`);
    setSubHeader(
      'We have a few questions for you so we can support your business and help you meet your goals. To begin, tell us about your business.',
    );
  }, []);

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const displayAddressErrors = (touched, errors) => {
    return (
      (touched?.city && errors?.city) ||
      (touched?.country && errors?.country) ||
      (touched?.postalCode && errors?.postalCode) ||
      (touched?.region && errors?.region) ||
      (touched?.regionName && errors?.regionName)
    );
  };

  const initialFullAddress = account => {
    return account?.billingAddress?.city && account?.billingAddress?.postalCode && account?.billingAddress?.region
      ? `${account?.billingAddress?.city}, ${account?.billingAddress?.region} ${account?.billingAddress?.postalCode}, USA`
      : '';
  };
  const isValidZip = value => {
    return /\b\d{5}\b/.test(value);
  };

  const initialValues = {
    businessName: shop?.businessName ? shop?.businessName : '',
    city: account?.billingAddress?.city ? account?.billingAddress?.city : '',
    country: account?.billingAddress?.country ? account?.billingAddress?.country : '',
    postalCode: account?.billingAddress?.postalCode ? account?.billingAddress?.postalCode : '',
    region: account?.billingAddress?.region ? account?.billingAddress?.region : '',
    regionName: account?.billingAddress?.regionName ? account?.billingAddress?.regionName : '',
    fullAddress: initialFullAddress(account),
  };

  const submit = async (values, errors) => {
    if (
      values?.businessName &&
      values?.city &&
      values?.country &&
      values?.postalCode &&
      values?.region &&
      values?.regionName &&
      !errors?.businessName &&
      !errors?.city &&
      !errors?.country &&
      !errors?.postalCode &&
      !errors?.region &&
      !errors?.regionName
    ) {
      try {
        setErrorMessage('');
        const newCompletions = [];

        let newShop: Shop = {
          ...shop,
          businessName: values.businessName,
          physicalAddress: {
            city: values?.city,
            country: values?.country,
            postalCode: values?.postalCode,
            region: values?.region,
            regionName: values?.regionName,
          },
        };

        if (values.businessName !== initialValues.businessName) {
          newShop.websiteUrl = await getShopNameFromBusinessName(values.businessName);
        }

        const newBillingAddress: Address = {
          ...account.billingAddress,
          city: values.city,
          country: values.country,
          postalCode: values.postalCode,
          region: values.region,
          regionName: values.regionName,
        };

        const shopUpdate = await dispatch(updateShopAction({ shop: newShop, newCompletions }));

        await accountRepository.updateProps(account.id, {
          billingAddress: newBillingAddress,
        });

        if (shopUpdate.meta.requestStatus === 'fulfilled') {
          trackEvent('Business Questions Captured', {
            ...values,
          });

          trackHubSpotContactPage(
            {
              email: shop.email,
              company: values.businessName,
              zip: values.postalCode,
              city: values.city,
              state: values.regionName,
            },
            '/signup/1',
          );

          nextStep();
        }
      } catch (error) {
        console.error('Error Submitting Shop Form: ', error);
      }
    } else {
      isValidZip(values.fullAddress)
        ? setErrorMessage('Please select a zip code from the options provided.')
        : setErrorMessage('Please enter and select a zip code from the options provided.');
    }
  };

  return (
    <Grid container>
      <Formik onSubmit={submit} initialValues={initialValues} validationSchema={businessInfoSchema}>
        {({ values, errors, touched, validateForm }: FormikProps<FormProps>) => (
          <Form noValidate>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.inputContainer}>
                <TextInput
                  label="Business Name"
                  placeholder="Enter Business Name"
                  error={touched.businessName && errors.businessName}
                  name="businessName"
                  required
                />
                {!(touched.businessName && errors.businessName) && (
                  <Typography variant="caption" className={classes.caption}>
                    We'll use this for your shop name. Don’t worry, you can always change this later.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <AddressAutoComplete
                  error={displayAddressErrors(touched, errors) ? errorMessage : ''}
                  label="Business Zip Code"
                  required
                  useLabel={false}
                  addressFields={{
                    address: 'fullAddress',
                    addressLine1: 'addressLine1',
                    city: 'city',
                    region: 'region',
                    regionName: 'regionName',
                    postalCode: 'postalCode',
                    country: 'country',
                  }}
                  isExpanded
                  regionsOnly={true}
                />
                {!displayAddressErrors(touched, errors) && (
                  <Typography variant="caption" className={classes.caption}>
                    This helps us automate your business’s SEO (search engine optimization) strategy, connect you
                    with partners, and more. This can be your business, commercial kitchen, or home address.{' '}
                    <b>Please note:</b> Castiron is currently only available for US-based businesses.
                  </Typography>
                )}
              </Grid>
              <OnboardingFooter step={step} nextStep={() => submit(values, errors)} prevStep={prevStep} />
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default BusinessInfo;
