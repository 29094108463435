import { Box, Typography } from '@material-ui/core';
import React from 'react';
import ButtonGroup from '../../../ButtonGroup';

type Props = {
  value: boolean;
  onChange: (status: boolean) => void;
  classes: any;
};

const ProductStatus: React.FC<Props> = (props: Props) => {
  const { value, onChange, classes } = props;

  return (
    <Box>
      <ButtonGroup
        value={value}
        label="Product Status"
        buttons={['active', 'inactive']}
        onChange={(value): void => onChange(value)}
      />
      <Typography variant="subtitle1" className={classes.subLabel}>
        Setting your product to active makes it immediately available for purchase through your store.{' '}
      </Typography>
    </Box>
  );
};

export default ProductStatus;
