import React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { FormikErrors } from 'formik';
import clsx from 'clsx';
import Typography from '../../Typography';
import { BaseProduct } from '@castiron/domain';

export interface ImageRadioOption {
  label: string;
  url: string;
  value: BaseProduct;
}

interface Props {
  label?: string;
  required?: boolean;
  options?: ImageRadioOption[];
  value?: string;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkedIcon: {
    backgroundColor: `${theme.branding.blue.primary} !important`,
    border: 'none !important',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.branding.blue.primary,
    },
  },
  formControl: {
    maxWidth: '100%',
    width: '100%',
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      padding: 0,
      width: '100%'
    },
    '& .MuiFormControlLabel-root': {
      width: '100%'
    }
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: theme.branding.gray[100],
    border: `1px solid ${theme.branding.gray[600]}`
  },
  imageSelected: {
    border: `4px solid ${theme.branding.blue.primary}`,
    borderRadius: 16
  },
  individualWrapper: {
    position: 'relative',
    alignItems: 'flex-start',
  },
  label: {
    wordBreak: 'break-word',
    fontWeight: 600,
  },
  labelContainer: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      '-webkit-line-clamp': 2,
    }
  },
  radio: {
    position: 'absolute',
    left: 3,
    top: 3,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    aspectRatio: '1/1',
    alignItems: 'center',
    objectFit: 'contain',
    minHeight: '70px',
    minWidth: '70px',
    width: '100%',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    aspectRatio: '1/1',
    border: `2px solid ${theme.branding.gray[100]}`,
    borderRadius: 12,
    objectFit: 'cover',
  },

}))

const ImageRadioInput: React.FC<Props> = (props: Props) => {
  const { options, value, onChange } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <Grid container className={classes.gridWrapper} >
        {options.map(option => {
          return (
            <Grid container item className={classes.individualWrapper} xs={6} sm={4} md={6} lg={4} >
              <FormControlLabel
                className={classes.formControl}
                // the extra margin on .MuiFormControlLabel-root is super hard to get rid of
                style={{ margin: 0 }}
                key={`option${option.value}`}
                value={option.value.id}
                control={
                  <Radio
                    className={classes.radio}
                    color='primary'
                    checkedIcon={<span className={clsx(classes.checkedIcon, classes.icon)} />}
                    icon={<span className={classes.icon} />}
                  />
                }
                label={
                  <Grid style={{ width: '100%' }}>
                    <div className={`${classes.radioContainer} ${option.value.id === value && classes.imageSelected}`}>
                      <img className={classes.thumbnail} src={option.url} />
                    </div>
                    <div style={{ marginLeft: '8px' }} className={classes.labelContainer}>
                      <Typography variant='subtitle3' className={classes.label}>{option.label}</Typography>
                    </div>
                  </Grid >
                }
              />
            </Grid >
          )
        })}
      </Grid >
    </RadioGroup >
  );
};

export default ImageRadioInput;