import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LogoutOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="-2 -2 24 24" fill="none" {...props}>
    <g >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09386 2H2.02347V16H8.09386C8.65032 16 9.1056 16.45 9.1056 17C9.1056 17.55 8.65032 18 8.09386 18H2.02347C0.91056 18 0 17.1 0 16V2C0 0.9 0.91056 0 2.02347 0H8.09386C8.65032 0 9.1056 0.45 9.1056 1C9.1056 1.55 8.65032 2 8.09386 2ZM15.0344 5.86003L17.8571 8.65003C18.0493 8.84003 18.0493 9.16003 17.847 9.35003L15.0242 12.14C14.7106 12.46 14.1643 12.24 14.1643 11.79V10H7.08213C6.52568 10 6.0704 9.55003 6.0704 9.00003C6.0704 8.45003 6.52568 8.00003 7.08213 8.00003H14.1643V6.21003C14.1643 5.76003 14.7106 5.54003 15.0344 5.86003Z" />
    </g>
  </SvgIcon>
);
