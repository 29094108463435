import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Text, TextProps } from '../';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type ChipColorScheme =
  'blue' | 'gray' | 'red' | 'yellow' | 
  /* hoping to deprecate all of these, see comment below */
  'info' | 'infoDark' | 'success' | 'error' | 'warning' | 'fulfilled' | 'mint' | 'lightBlue' |
  'quotesLightBlue' | 'quotesLightGreen' | 'quotesLightOrange' | 'quotesLightRed' | 'quotesLightYellow';

export type ChipProps = Override<
  TextProps,
  {
    colorScheme?: ChipColorScheme;
  }
>;

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    borderRadius: 50,
    padding: '0 18px',
    width: 'fit-content',
  },
  blue: {
    backgroundColor: theme.branding.blue.light,
    color: theme.branding.blue.primary,
  },
  gray: {
    backgroundColor: theme.branding.gray[300],
    color: theme.branding.gray[800],
  },
  red: {
    backgroundColor: theme.branding.red.light,
    color: theme.branding.red.primary,
  },
  yellow: {
    backgroundColor: theme.branding.yellow.light,
    color: theme.branding.orange.primary,
  },
  /* I like the idea of consistent info/success/error/etc,
   * but the designs haven't been consistent with what we have below to this point,
   * so I am hoping to deprecate everything below here
   * and only using branding going forward
   */
  info: {
    backgroundColor: '#F0F0F0',
    color: '#515151',
  },
  infoDark: {
    backgroundColor: '#515151',
    color: '#F0F0F0',
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  fulfilled: {
    backgroundColor: '#ddeafe',
    color: '#1a192e',
  },
  mint: {
    color: theme.branding.mint,
    backgroundColor: 'RGBA(0, 189, 133, 0.1)',
  },
  lightBlue: {
    backgroundColor: 'RGBA(0, 95, 255, 0.15)',
    color: '#515151',
  },
  /* I hate doing this, but I think this whole thing can be saved with a consistent color palette */
  quotesLightBlue: {
    backgroundColor: theme.branding.lightBlue,
  },
  quotesLightGreen: {
    backgroundColor: theme.branding.lightGreen,
  },
  quotesLightOrange: {
    backgroundColor: theme.branding.lightOrange,
  },
  quotesLightRed: {
    backgroundColor: theme.branding.lightRed,
  },
  quotesLightYellow: {
    backgroundColor: theme.branding.lightYellow
  },
}));

const Chip: React.FC<ChipProps> = (props: ChipProps) => {
  const { colorScheme, className = '', children, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Text
      presetFont="txt--xs"
      className={`${colorScheme ? classes[colorScheme] : ''} ${classes.default} ${className}`}
      {...restOfProps}
    >
      {children}
    </Text>
  );
};

export default Chip;
