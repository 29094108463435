import React, { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Emails from './Emails';
import Container from './Container';
import ProductShare from './ProductShare';
import CouponShare from './CouponShare';
import { FeatureFlag } from '@castiron/castiron-firebase';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Layout/AvatarMenu';
import ViewShopButton from '../Layout/Header/ViewShopButton';

const Marketing: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageTitle('Marketing');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    const headerCTAs = isMobile
      ? [<ViewShopButton subdirectory="signup" />, <AvatarMenu />]
      : [<ViewShopButton subdirectory="signup" />];
    setHeaderCTAs(headerCTAs);
    setFooterCTAs([]);
  }, [isMobile]);

  return (
    <Container>
      <>
        <FeatureFlag name="feature_marketing_emails">
          <Emails />
        </FeatureFlag>
        <FeatureFlag name="feature_marketing_product_share">
          <ProductShare />
        </FeatureFlag>
        <FeatureFlag name="feature_marketing_coupon_share">
          <CouponShare />
        </FeatureFlag>
      </>
    </Container>
  );
};

export default Marketing;
