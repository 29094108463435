import React, { useEffect } from 'react';
import { Box, Checkbox, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleStyledRadio } from '../RadioInput';
import Typography from '../../Typography';

interface Item {
  description?: string;
  icon?: React.ReactNode;
  label: string;
  value: string;
}

interface Props {
  items: Item[];
  defaultSelected?: boolean;
  selectedItem?: string | string[];
  setSelection: (value: string) => void;
  inputType: 'radio' | 'checkbox';
}

const useStyles = makeStyles((theme: Theme) => ({
  radioGroupContainer: {
    width: '100%',
    margin: '24px 0',
    cursor: 'pointer',

    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  radioGroupItem: {
    padding: '16px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    marginBottom: 8,
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.branding.blue.primary,
    backgroundColor: theme.branding.blue.light,
    padding: 4,
    borderRadius: 12,
    marginRight: 16,
    height: 40,
    width: 40,
    '& svg': {
      fontSize: 20,
    },
  },
  itemDescription: {
    color: theme.branding.gray[600],
  },
  itemRadio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
}));

const RadioOrCheckboxGroup: React.FC<Props> = (props: Props) => {
  const { inputType, items, defaultSelected = true, selectedItem, setSelection } = props;
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = React.useState(selectedItem || (defaultSelected && items[0].value) || null);

  useEffect(() => {
    selectedItem && setSelectedValue(selectedItem);
  }, [selectedItem]);

  return (
    <Grid container className={classes.radioGroupContainer}>
      {items.map((item, index) => (
        <Grid
          container
          item
          key={index}
          className={classes.radioGroupItem}
          wrap="nowrap"
          onClick={() => setSelection(item.value)}
        >
          <Grid item>
            <Grid container alignItems='center' style={{ height: '100%' }}>
              <Grid item>
                <Box className={classes.itemIcon}>
                  {item.icon}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={8} sm={10} direction="column" justify="center">
            <Typography variant="body1">{item.label}</Typography>
            {item.description &&
              <Typography variant="body1" className={classes.itemDescription}>{item.description}</Typography>
            }
          </Grid>
          <Grid item xs={2} sm={1} className={classes.itemRadio}>
            {inputType === 'radio' ? (
              <SimpleStyledRadio
                checked={item.value === selectedValue}
                value={item.value}
                onChange={event => setSelection(event.target.value)}
              />
            ) : (
              <Checkbox
                checked={selectedValue.includes(item.value)}
                value={item.value}
                onChange={event => setSelection(event.target.value)}
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default RadioOrCheckboxGroup;
