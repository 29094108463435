import { CommunityPackage } from '@castiron/domain';
import React from 'react';

export interface CommunityPackageContextData {
  communityPackage?: CommunityPackage;
}

export const CommunityPackageContext = React.createContext<CommunityPackageContextData>({
  communityPackage: null,
});
