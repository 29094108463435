import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, Grid, makeStyles, Theme, Typography, useTheme, } from '@material-ui/core';
import { useAppSelector } from '../../hooks';
import { getService } from "../../firebase";

const sendPreviewEmailService = getService('marketing','sendPreviewEmail');

const useStyles = makeStyles((theme: Theme) => ({
  previewButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
    },
  },
  successMsg: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    marginLeft: '16px',
  },
  container: {
    padding: '40px',
  },
  value: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  buttons: {
    marginTop: '24px',
  },
  cancelButton: {
    marginRight: '16px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '200px',
    },
  },
  spinner: {
    marginLeft: '16px',
  },
}));

interface Props {
  marketingSendId: string;
}

const PreviewButton: React.FC<Props> = (props: Props) => {
  const { marketingSendId } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { me } = useAppSelector(state => ({
    me: state.users.me,
  }));

  const [open, setOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<null | string>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async () => {
    setIsSubmitting(true);
    try {
      await sendPreviewEmailService({ to: me.email, marketingSendId });
      setSuccessMsg('Test email sent!');
    } catch (err) {
      setSuccessMsg('You must have at least 4 active products selected for an email to send.');
    }
    setOpen(false);
    setIsSubmitting(false);
    setTimeout(() => {
      setSuccessMsg(null);
    }, 2000);
  };

  return (
    <Grid container alignItems="center" className={ classes.previewButton }>
      <Button onClick={ () => setOpen(true) } variant="outlined">
        Send a test email
      </Button>
      { successMsg && (
        <Typography
          className={ classes.successMsg }
          style={ {
            color: successMsg === 'Test email sent!' ? theme.branding.green.primary : theme.branding.error,
          } }
        >
          { successMsg }
        </Typography>
      ) }
      <Dialog open={ open } onClose={ handleClose }>
        <Grid container direction="column" className={ classes.container }>
          <Typography className={ classes.label }>Test email will send to:</Typography>
          <Typography className={ classes.value }>{ me?.email }</Typography>
          <Grid container justify="flex-end" className={ classes.buttons }>
            <Button className={ classes.cancelButton } onClick={ () => setOpen(false) } variant="outlined"
                    color="primary">
              Cancel
            </Button>
            <Button disabled={ isSubmitting } color="primary" variant="contained" onClick={ handleSendEmail }>
              Send { isSubmitting && <CircularProgress className={ classes.spinner } size={ 20 }/> }
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default PreviewButton;
