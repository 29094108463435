import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Chip, ImageUploader, Typography } from '@castiron/components';
import { BaseProduct, InputField } from '@castiron/domain';
import Dinero from 'dinero.js';
import { useAppSelector } from '../../../hooks';

type Props = {
  product: BaseProduct;
  variation: InputField;
  index: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  ast: {
    color: theme.branding.red.primary,
  },
  chip: {
    maxHeight: 20,
    maxWidth: 48,
    padding: '0px 8px',
    margin: '0 0 7px 24px',
    lineHeight: '22px',
  },
  input: {
    minWidth: 150,
    width: '100%',
    padding: '0 16px 0 0',
  },
  inputInner: {
    width: '100%',
    fontSize: 14,
  },
  optional: {
    color: theme.branding.gray[400],
    fontSize: 14,
  },
  photoSubtitle: {
    fontSize: '14px',
  },
  photoTitle: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  radio: {
    alignSelf: 'flex-start',
  },
  required: {
    color: theme.branding.green.primary,
    fontSize: 14,
  },
  select: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'break-spaces',
    },
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'start',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '100%',
    },
  },
  variationLabel: {
    color: theme.branding.gray[800],
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    margin: '1.5em 0 1em',
  },
}));

const VariationsPreview: React.FC<Props> = (props: Props) => {
  const { index, variation, product } = props;
  const classes = useStyles();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleChange = () => {};

  const handleFileDrop = files => {};

  return (
    <>
      {variation.type !== 'photo' && (
        <Typography className={classes.variationLabel} variant="h4">
          {variation.name}{' '}
          <span className={variation.required ? classes.required : classes.optional}>
            {variation.required ? '(Required)' : '(Optional)'}
          </span>
        </Typography>
      )}
      {variation.type === 'select' &&
        (variation.required ? (
          <>
            <RadioGroup className={classes.twoColumns} aria-label={variation.name} name={variation.name} onChange={event => handleChange()} row>
              {variation.values.map(option => (
                <FormControlLabel
                  key={option.id}
                  value={option.name}
                  control={<Radio className={classes.radio} value={option.id} size="small" color="primary" />}
                  label={
                    <>
                      {option.cost ? (
                        <Typography variant="body1">
                          {option.name}{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            +{Dinero({ amount: option.cost * 100 }).toFormat('$0.00')}
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant="body1">{option.name}</Typography>
                      )}
                    </>
                  }
                />
              ))}
            </RadioGroup>
          </>
        ) : (
          <Select
            className={classes.select}
            displayEmpty={true}
            style={{
              height: '40px',
            }}
            placeholder="Select only one option"
            variant="outlined"
          >
            <MenuItem className={classes.selectOptionContainer} value="none">
              <em>None</em>
            </MenuItem>
            {variation.values.map(option => (
              <MenuItem className={classes.selectOptionContainer} key={option.id} value={option.id}>
                {option.cost ? (
                  <Typography className={classes.selectOption} variant="body1">
                    {option.name}{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      +{Dinero({ amount: option.cost * 100 }).toFormat('$0.00')}
                    </span>
                  </Typography>
                ) : (
                  <Typography className={classes.selectOption} variant="body1">
                    {option.name}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </Select>
        ))}

      {variation.type === 'multiselect' && (
        <FormGroup row className={classes.twoColumns}>
          {variation.values.map((option, index: number) => (
            <Checkbox
              key={option.id}
              label={
                <>
                  {option.cost ? (
                    <Typography variant="body1">
                      {option.name}{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        +{Dinero({ amount: option.cost * 100 }).toFormat('$0.00')}
                      </span>
                    </Typography>
                  ) : (
                    <Typography variant="body1">{option.name}</Typography>
                  )}
                </>
              }
            />
          ))}
        </FormGroup>
      )}

      {variation.type === 'text' && (
        <>
          {variation.values.map((customVal, i: number) => (
            <div key={customVal.id}>
              {customVal.cost > 0 && (
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    Cost: +{Dinero({ amount: customVal.cost * 100 }).toFormat('$0.00')}
                  </span>
                </Typography>
              )}
              <TextField
                variant="outlined"
                className={classes.input}
                multiline
                InputProps={{ classes: { input: classes.inputInner } }}
                size="small"
                rows={1}
                onChange={event => handleChange()}
                placeholder={customVal.name}
                rowsMax={12}
              />
            </div>
          ))}
        </>
      )}

      {variation.type === 'photo' && (
        <>
          <Grid className={classes.photoTitle}>
            <Typography className={classes.variationLabel} variant="h4">
              {variation.name}{' '}
              <span className={variation.required ? classes.required : classes.optional}>
                {variation.required ? '(Required)' : '(Optional)'}
              </span>
            </Typography>
            <Chip className={classes.chip} colorScheme="info" title={'Number of photos uploaded'}>
              {'0/15'}
            </Chip>
          </Grid>
          <Typography variant="body4" className={classes.photoSubtitle}>
            PNG or JPG files only. 2MB max file size.
          </Typography>
          <div>
            <ImageUploader
              imageInProduct={true}
              maxFiles={15}
              dropZoneText={'Drag and drop photos here'}
              onFileDrop={handleFileDrop}
            />
          </div>
        </>
      )}
    </>
  );
};

export default VariationsPreview;
