import React, { useEffect, useRef, useState } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingFooter from './OnboardingFooter';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { BusinessStage } from '@castiron/domain';
import AdminForm from '../../AdminForm';
import {
  LightbulbIcon,
  MoonIcon,
  PeopleGroupIcon,
  RadioOrCheckboxGroup,
  Banner,
  Typography,
} from '@castiron/components';
import { AccessTime } from '@material-ui/icons';
import { accountRepository } from '../../../domain';
import { useTracking } from '@castiron/utils';
interface Props {
  step: number;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  nextStep?: () => void;
  prevStep?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const businessStageSchema = yup.object().shape({
  businessStage: yup
    .string()
    .required('Required')
    .oneOf(['brand-new', 'part-time-entrepreneur', 'full-time-entrepreneur', 'enterprise-business']),
});

const BusinessTier: React.FC<Props> = (props: Props) => {
  const { step, nextStep, prevStep, setHeader, setSubHeader } = props;
  const classes = useStyles();
  const formRef = useRef<FormikProps<any>>();
  const { trackEvent } = useTracking();
  const [showError, setShowError] = useState(false);

  const radioList = [
    {
      icon: <PeopleGroupIcon />,
      label: 'I have a food business with multiple employees.',
      value: 'enterprise-business',
    },
    {
      icon: <AccessTime />,
      label: 'I am a full-time food entrepreneur.',
      value: 'full-time-entrepreneur',
    },
    {
      icon: <MoonIcon />,
      label: 'I am a part-time food entrepreneur.',
      value: 'part-time-entrepreneur',
    },
    {
      icon: <LightbulbIcon />,
      label: 'I am just getting started.',
      value: 'brand-new',
    },
  ];

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  useEffect(() => {
    setHeader('Which of these best describes you?');
    setSubHeader('We will help you get started based on your current business needs.');
  }, []);

  useEffect(() => {
    if (account.businessStage && !formRef?.current?.values.businessStage) {
      formRef?.current?.setFieldValue('businessStage', account.businessStage);
    }
  }, [account]);

  const onNextClick = async () => {
    await formRef.current.validateForm();
    if (formRef.current.isValid) {
      formRef.current.submitForm();
    } else {
      setShowError(true);
    }
  };

  const submit = async () => {
    const { businessStage } = formRef?.current?.values;

    trackEvent('Onboarding Business Stage', { businessStage: businessStage });

    await accountRepository.updateProps(account.id, {
      businessStage,
      'onboardingQuestions.businessStage': businessStage
    });

    nextStep();
  };

  const handleRadioChange = (value: BusinessStage, formikProps: FormikProps<any>) => {
    setShowError(false);
    formikProps.setFieldValue('businessStage', value);
  };

  return (
    <Grid container direction='column'>
      {showError && (
        <Grid item style={{ marginTop: '24px' }}>
          <Banner variant="error">
            <Typography variant="body2" style={{ color: 'inherit' }}>
              Please select an option.
            </Typography>
          </Banner>
        </Grid>
      )}
      <Grid item>
        <Formik
          initialValues={{
            businessStage: account.businessStage || '',
          }}
          validationSchema={businessStageSchema}
          onSubmit={submit}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<any>) => (
            <AdminForm>
              <Grid item xs={12}>
                <RadioOrCheckboxGroup
                  inputType="radio"
                  items={radioList}
                  defaultSelected={false}
                  selectedItem={formikProps.values.businessStage}
                  setSelection={(value: BusinessStage) => handleRadioChange(value, formikProps)}
                />
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
      <OnboardingFooter step={step} nextStep={onNextClick} prevStep={prevStep} />
    </Grid>
  );
};

export default BusinessTier;
