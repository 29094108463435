import React, { ReactNode } from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  primaryLabel: ReactNode;
  secondaryLabel?: ReactNode;
  containerClass?: string;
  required?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  labelContainer: {
    marginTop: 17,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 9,
  },
  primaryLabel: {
    display: 'inline-block',
  },
  required: {
    display: 'inline-block',
    color: theme.branding.red.primary,
  },
  secondaryLabel: {
    color: theme.branding.gray[700],
    textAlign: 'end',
  },
}));

const InputLabel: React.FC<Props> = (props: Props) => {
  const { primaryLabel, secondaryLabel, containerClass, required } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.labelContainer} ${containerClass}`}>
      <Box>
        {typeof primaryLabel === 'string' ? (
          <Typography variant='h5' className={classes.primaryLabel}>
            {primaryLabel}
            {
              required &&
              <span className={classes.required}>&nbsp;*</span>
            }
          </Typography>
        ) : (
          primaryLabel
        )}
      </Box>
      {typeof secondaryLabel === 'string' ? (
        <Typography variant='h5' className={classes.secondaryLabel}>{secondaryLabel}</Typography>
      ) : (
        secondaryLabel
      )}
    </Box>
  );
};

export default InputLabel;
