import React, { MouseEvent, ReactNode, useState } from 'react';
import { Box, Button, ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Typography } from '@castiron/components';
import Popover from '../Popover/Popover';

export interface DropDownOption {
  label: string | ReactNode;
  color?: 'error';
  icon?: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

type Props = {
  options: DropDownOption[];
  title?: ReactNode;
  fullWidth?: boolean;
  containerClass?: string;
  popoverClass?: string;
  variant?: 'arrow' | 'no-arrow';
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: props => props.fullWidth ? '100%' : undefined,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    /* this should 16 all around, but using 11 to match current buttons, equal no arrow to account for undefined */
    padding: props => props.variant === 'no-arrow' ? '8px' : '11px 16px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    width: props => props.fullWidth ? '100%' : undefined,
  },
  error: {
    color: theme.branding.red.primary,
  },
  icon: {
    height: 24,
    width: 24,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 12,
    height: 32,
  },
  popoverPaper: {
    marginTop: '8px',
  },
}));

const Dropdown: React.FC<Props> = (props: Props) => {
  const { options, title, containerClass, popoverClass, variant = 'arrow' } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = action => {
    /* quick and dirty solution a one off feature */
    const actionResponse = action();
    if (typeof actionResponse === 'object' && typeof actionResponse.then === 'function') {
      actionResponse.then(() => handlePopoverClose());
    } else {
      handlePopoverClose();
    }
  };

  return (
    <Box className={`${classes.actions}`}>
      <ButtonBase focusRipple onClick={handleMoreClick} className={`${classes.button} ${containerClass}`}>
        <Grid container justify='space-between'>
          <Grid item>
            <Typography variant={variant === 'arrow' ? 'button' : 'subtitle2'}>{title}</Typography>
          </Grid>
          {
            variant === 'arrow' &&
            <Grid item>
              {
                anchorEl ? (
                  <KeyboardArrowUp className={classes.icon} />
                ) : (
                  <KeyboardArrowDown className={classes.icon} />
                )
              }
            </Grid>
          }
        </Grid>
      </ButtonBase>
      <Popover
        paperClass={`${classes.popoverPaper} ${popoverClass}`}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Grid container direction='column'>
          {
            options.map((option, i) => (
              <Grid item key={`dropdownOption${i}`}>
                <Button
                  startIcon={option.icon}
                  onClick={() => handleClick(option.onClick)}
                  className={`${classes.menuItem} ${option.color == 'error' && classes.error}`}
                  fullWidth
                >
                  <Typography
                    variant={variant === 'arrow' ? 'button' : 'subtitle2'}
                    className={`${option.color == 'error' && classes.error}`}
                  >
                    {option.label}
                  </Typography>
                </Button>
              </Grid>
            ))
          }
        </Grid>
      </Popover>
    </Box>
  );
};

export default Dropdown;
