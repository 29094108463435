import React from 'react';
import {ProductImage} from '@castiron/components';
import {Box, makeStyles, Theme} from '@material-ui/core';
import { Typography } from '@castiron/components';
import CastironLogoImg from '../../assets/img/logo-placeholder-castiron-mark.png';
import {BaseProduct} from '@castiron/domain';

type Props = {
  product: BaseProduct;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootClass: {
    flexShrink: 1,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.branding.gray[800],
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    color: theme.branding.gray[700],
  },
  image: {
    marginRight: 16,
    margin: 'auto',
    display: 'block',
    objectFit: 'cover',
    width: '74px',
    height: '75px',
    borderRadius: '4px'
  },
}));

const ProductItem: React.FC<Props> = (props: Props) => {
  const {product} = props;
  const classes = useStyles();

  const imageSize = '84px';

  const imageSrc = product?.imageObj?.smallVersion
    ? product.imageObj.smallVersion.downloadUrl
    : product?.imageObj?.downloadUrl || CastironLogoImg;

  return (
    <Box className={classes.container}>
      {imageSrc !== CastironLogoImg && (
        <ProductImage
          alt={product?.title}
          className={classes.image}
          src={imageSrc}
          backgroundColor="inherit"
        />
      )}
      <Box className={classes.textContainer}>
        <Typography className={classes.title}>{product.title}</Typography>
      </Box>
    </Box>
  );
};

export default ProductItem;
