import { Switch, SwitchProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
    className?: string;
    onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.branding.gray[100],
            '& + $track': {
                backgroundColor: theme.branding.blue.primary,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.branding.blue.primary,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.branding.gray[500]}`,
        backgroundColor: theme.branding.gray[200],
        opacity: 1,
    },
    checked: {},
    focusVisible: {},
}));


const CustomSwitch: React.FC<SwitchProps> = (props: SwitchProps) => {
    const { className, ...restOfProps } = props;
    const classes = useStyles();

    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...restOfProps}
        />
    );
};

export default CustomSwitch;