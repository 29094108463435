import React, { useEffect } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { getCommunityPackagesAction } from '@castiron/client-admin/src/store/reducers/community';
import { useAppDispatch, useAppSelector } from '@castiron/client-admin/src/hooks';
import { SmallCommmunityPackageCard } from '.';
import { HeaderText, Text, Link } from '@castiron/components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '16px 0px',
  },
  header: {
    marginBottom: 24,
    gap: 8,
  },
  communityPackagesContainer: {
    gap: 8,
    width: '100%',
    overflowX: 'auto',
  },
}));

// Community packages section on Products page
const CommunityPackagesProductsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { communityPackages, shop } = useAppSelector(state => ({
    communityPackages: state.community.communityPackages,
    shop: state.shops.shop,
  }));

  // gets Community packages
  useEffect(() => {
    if (shop) {
      dispatch(getCommunityPackagesAction(shop.id));
    }
  }, [shop]);

  if (!communityPackages || !communityPackages.length) return null;

  return (
    <Grid container className={classes.container}>
      {/* Header */}
      <Grid container className={classes.header}>
        <HeaderText presetFont="heading--xs" fontSize={18} lineHeight={24} fontWeight={600}>
          Community Listings
        </HeaderText>

        {/* See all (links to '/community' page) */}
        <Text presetFont="txt--s" lineHeight={28}>
          <Link onClick={() => history.push('/community')} underline>
            See all
          </Link>
        </Text>
      </Grid>

      {/* Small Community package cards */}
      <Grid container wrap="nowrap" className={classes.communityPackagesContainer}>
        {communityPackages?.map(cp => (
          <SmallCommmunityPackageCard communityPackage={cp} />
        ))}
      </Grid>
    </Grid>
  );
};

export default CommunityPackagesProductsPage;
