import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Input, Typography, WarningIcon } from '@castiron/components';
import { useTracking } from "@castiron/utils";
import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingStepFooter from './OnboardingStepFooter';
import { OnboardingInfo } from './Onboarding';
import { trackHubSpotContactPage } from "../../../lib/trackHubSpotContactEvent";

type Props = {
  progressStep: (values: OnboardingInfo) => void;
};

const getInnerHeight = () => {
  const { innerHeight } = window;
  return innerHeight;
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 12,
      borderColor: theme.branding.gray[400],
      border: '1px solid',
      maxWidth: '588px',
    },
  },
  disclaimer: {
    color: theme.branding.gray[600],
  },
  divider: {
    color: theme.branding.gray[400],
    border: '1px solid',
  },
  errorContainer: {
    padding: '21px 16px',
    backgroundColor: theme.branding.red.light,
    borderRadius: 8,
    margin: '0 10px 0 2px',
    width: '100%',
    '& svg': {
      position: 'relative',
      top: 3,
    },
    '& p': {
      color: theme.branding.red.dark,
    },
  },
  footerContainer: {
    backgroundColor: theme.branding.gray[100],
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '0 0 12px 12px',
    marginTop: '10px',
    padding: 24,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      marginTop: '0px'
    },
  },
  label: {
    margin: '8px 0px -8px 0px',
  },
  link: {
    color: 'inherit',
  },
  input: {
    height: '56px',
  },
  optional: {
    color: theme.branding.gray[600],
    fontSize: '12px',
  },
  scrollContainer: {
    overflow: 'auto',
    marginTop: 0,
    minHeight: 0,
    padding: '32px',
    height: '500px',
    maxHeight: '500px',
    maxWidth: '588px',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: theme.branding.gray[500],
      '-webkit-box-shadow': `0 0 1px ${theme.branding.gray[100]}`
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
    },
  },
}));

const OnboardingQualificationForm: React.FC<Props> = (props: Props) => {
  const { progressStep } = props;
  const classes = useStyles();
  const [businessName, setBusinessName] = React.useState('');
  const [name, setName] = React.useState('');
  const [mobilePhone, setMobilePhone] = React.useState('');

  const [formError, setFormError] = React.useState('');

  const { errors, touched, values, handleBlur, setErrors, setFieldTouched, submitCount, submitForm }: any = useFormikContext();
  const { trackEvent } = useTracking();

  const successHandler = () => {
    if (
      !errors.businessName &&
      values.businessName &&
      !errors.name &&
      values.name &&
      !errors.mobilePhone
    ) {
      window.localStorage.setItem('castironOnboardingForm', JSON.stringify(values));
      progressStep(values);

      trackEvent('Business Questions Captured', {
        ...values
      });

      trackHubSpotContactPage({
        email: values.email,
        company: values.businessName,
        name: values.name,
        mobilephone: values?.mobilePhone
      }, '/signup/business');
    } else {
      const el = document.getElementById('scrollContainer');
      el.scrollTop = 0;
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    setErrors({});
    setFieldTouched('businessName', false);
    setFieldTouched('name', false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (submitCount && (errors.businessName || errors.name) && touched?.businessName && touched?.name) {
      setFormError('Please fill in the required options below to continue.');
    } else {
      setFormError('');
    }
  }, [errors, submitCount]);

  const handleSubmit = () => {
    submitForm().then(successHandler());
  };

  return (
    <Grid container>
      <Box className={classes.box}>
        <Grid container spacing={2} className={classes.scrollContainer} id="scrollContainer">
          <Grid item xs={12}>
            <Typography variant="h2">{"We're almost there!"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body4">{"Just a few more questions to help us support your business"}</Typography>
          </Grid>
          {formError && (
            <Grid className={classes.errorContainer} container wrap="wrap">
              <Typography variant="body2">
                <WarningIcon viewBox='0 0 24 24' /> {formError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Input type="text" error={touched?.businessName && errors?.businessName} name="businessName" label={<Typography variant="subtitle2" className={classes.label}>Shop Name</Typography>} onBlur={handleBlur} opaque={true} className={classes.input} />
            <Typography variant="caption">Most artisans use their business name. ( If needed, you can change it later)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input type="text" error={touched?.name && errors?.name} name="name" label={<Typography variant="subtitle2" className={classes.label}>Your Name</Typography>} onBlur={handleBlur} opaque={true} className={classes.input} />
          </Grid>
          <Grid item xs={12} >
            <Input type="tel" phoneMask={true} error={touched?.mobilePhone && errors?.mobilePhone} placeholder="(555) 555-5555" name="mobilePhone" label={<Typography variant="subtitle2" className={classes.label}>Phone Number <span className={classes.optional}>(optional)</span></Typography>} onBlur={handleBlur} opaque={true} />
          </Grid >
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.disclaimer}>By signing up via text, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. help setting up your store) from Castiron at the cell number used when signing up. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <a className={classes.link} target="_blank" href="https://www.castiron.me/terms-conditions">Terms</a> & <a className={classes.link} target="_blank" href="https://www.castiron.me/privacy-policy">Privacy</a>.</Typography>
          </Grid>
        </Grid>
        <Box className={classes.footerContainer}>
          <OnboardingStepFooter stepIndex={2} totalSteps={3} onContinueClick={handleSubmit} />
        </Box>
      </Box >
    </Grid >
  );
};

export default OnboardingQualificationForm;
