import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Button } from "@castiron/components";

interface Props {
  onDelete: () => void;
  isCreating?: boolean;
  variant?: 'page' | 'footer';
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: theme.branding.red.primary,
  },
  deleteButtonFooter: {
    color: theme.branding.red.primary,
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
}));

const DeleteButton: React.FC<Props> = (props: Props) => {
  const { onDelete, isCreating, variant = 'page' } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return <Button
    className={variant === 'footer' ? classes.deleteButtonFooter : classes.deleteButton}
    variant="outlined"
    onClick={ onDelete }
    disabled={ isCreating }
    fullWidth={ isMobile }
  >
    Delete
  </Button>;
}

export default DeleteButton;
