import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Button, RocketOutlinedIcon } from '@castiron/components';
import { ChecklistValues } from '@castiron/domain';
import { useConfig } from '@castiron/castiron-firebase';
import { useTracking } from '@castiron/utils';
import { shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getShopAction } from '../../../store/reducers/shops';
import firebase from 'firebase/compat/app';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  arrowForward: {
    height: 24,
    marginLeft: 4,
    width: 24,
  },
  checklistBanner: {
    '& p': {
      color: theme.branding.blue.dark,
    },
    '& button': {
      color: theme.branding.blue.dark,
      padding: 0,
      '&:hover': {
        backgroundColor: 'inherit',
      }
    },
  },
  checklistBannerContainer: {
    backgroundColor: theme.branding.blue.light,
    color: theme.branding.blue.dark,
    minHeight: 56,
    padding: '0px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
    },
  },
  rocketOutlinedIcon: {
    height: 20,
    marginRight: 10,
    width: 20,
  },
}));

const ChecklistBanner: React.FC = () => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const config = useConfig();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop
  }));

  const subscriptionsEnabled = config.featureFlag('feature_subscriptions', shop);

  const handleClick = async () => {
    trackEvent('Checklist Banner Clicked', {
      shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email', 'checklistCompletions']),
    });
    if (!count) {
      await shopRepository.updateProps(shop.id,{
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.GoLive),
        status: 'active',
      });
      dispatch(getShopAction(shop.id));
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    if (shop) {
      const list: ChecklistValues[] = shop.checklistCompletions;
      const requiredValues: ChecklistValues[] = [
        ChecklistValues.ProductAdded,
        ChecklistValues.Brand,
        ChecklistValues.Customize,
        ChecklistValues.FulfillmentAdded,
        ChecklistValues.StripeConnection,
      ].filter(cc => !!cc);

      if (list) {
        const itemCount = _.difference(requiredValues, list).length;
        setCount(itemCount);
      }
    }
  }, [shop]);

  const stepText = (
    <Typography variant="body2">
      You're <strong>{`${count} ${count > 1 ? 'steps' : 'step'}`}</strong> away from launching your new shop!
    </Typography>
  );
  const launchText = <Typography variant="body2">Your shop is ready to launch!</Typography>;

  return (
    <Grid container item className={classes.checklistBannerContainer} xs={12}>
      <Grid container item justify="space-between" alignItems="center" className={classes.checklistBanner} xs={12}>
        <Grid container item wrap="nowrap" xs={8} sm={6} alignItems="center">
          <RocketOutlinedIcon className={classes.rocketOutlinedIcon} />
          {count ? stepText : launchText}
        </Grid>
        <Grid container item justify="flex-end" xs={4} sm={6}>
          <Button onClick={handleClick} variant="text">
            <Typography variant="body2">{count ? 'View Steps' : 'Launch Shop'}</Typography>
            <ArrowForward className={classes.arrowForward} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChecklistBanner;
