import React, { useEffect, useRef, useState } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingFooter from './OnboardingFooter';
import { Banner, BentoIcon, CookieOutlinedIcon, RadioOrCheckboxGroup, RiceBowlIcon, SauceOutlinedIcon, Typography, WholeIngredientsIcon } from '@castiron/components';
import { useTracking, defaultCoverPhotoMap } from '@castiron/utils';
import { Formik, FormikProps } from 'formik';
import AdminForm from '../../AdminForm';
import * as yup from 'yup';
import { accountRepository, assetRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ClassOutlined, LocalCafeOutlined } from '@material-ui/icons';
import { Shop } from '@castiron/domain';
import { updateShopAction } from '../../../store/reducers/shops';
import { trackHubSpotContactPage } from "../../../lib/trackHubSpotContactEvent";

interface Props {
  step: number;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  nextStep?: () => void;
  prevStep?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const checkboxList = [
  {
    icon: <RiceBowlIcon />,
    label: 'Appetizers & Snacks',
    description: 'charcuterie, yogurt, dips, etc.',
    value: 'Appetizers & Snacks',
  },
  {
    icon: <ClassOutlined />,
    label: 'Cooking Classes',
    description: 'virtual, local, etc.',
    value: 'Cooking Classes',
  },
  {
    icon: <CookieOutlinedIcon />,
    label: 'Desserts & Baked Goods',
    description: 'cakes, cookies, bread, etc.',
    value: 'Desserts & Baked Goods',
  },
  {
    icon: <LocalCafeOutlined />,
    label: 'Drinks',
    description: 'juices, kombucha, smoothies, etc.',
    value: 'Drinks',
  },
  {
    icon: <BentoIcon />,
    label: 'Hot Food',
    description: 'meal planning, BBQ, catering, etc.',
    value: 'Hot Food',
  },
  {
    icon: <SauceOutlinedIcon />,
    label: 'Shelf-Stable Products',
    description: 'sauces, jams, spice mixes, etc.',
    value: 'Shelf-Stable Products',
  },
  {
    icon: <WholeIngredientsIcon />,
    label: 'Whole Ingredients',
    description: 'fresh fruit, vegetables, herbs, etc.',
    value: 'Whole Ingredients',
  },
];

const validCategories = checkboxList.map(checkbox => checkbox.value);

const categoriesSchema = yup.object().shape({
  categories: yup
    .array(yup.string().oneOf(validCategories))
    .min(1, 'Please select at least one option.')
    .required('Please select at least one option.')
});

const Categories: React.FC<Props> = (props: Props) => {
  const { step, nextStep, prevStep, setHeader, setSubHeader } = props;
  const classes = useStyles();
  const formRef = useRef<FormikProps<any>>();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const [showError, setShowError] = useState(false);

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setHeader('What type of food do you sell?');
    setSubHeader(
      'This helps us provide product templates and imagery, suggest resources, and connect you with peers in your niche. Please choose the answer that describes your products best.',
    );
  }, []);

  const submit = async (values) => {
    try {
      const { categories } = values;

      const artisanCategory = {
        name: categories[0],
        subcategories: categories,
      };

      const mappedCoverCat = defaultCoverPhotoMap[categories[0]];
      const coverImageObj = mappedCoverCat ? await assetRepository.findByArtisanCategory(mappedCoverCat) : undefined;

      if (coverImageObj) {
        delete coverImageObj.shopId;
      }

      const newShop: Shop = {
        ...shop,
        artisanCategory,
        coverImageObj,
      };

      await dispatch(updateShopAction({ shop: newShop }));

      await accountRepository.updateProps(account.id, {
        'onboardingQuestions.categories': categories,
      });

      trackEvent('Onboarding Category Selection', {
        onboardingForm: {
          categories,
          numberOfCategoriesPresented: categories.length,
          email: shop.email,
        },
      });

      trackHubSpotContactPage({
        email: shop.email,
        food_type: categories,
      }, '/signup/3');

      nextStep();
    } catch (error) {
      console.error('Error Submitting Shop Form: ', error);
    }
  };

  const onNextClick = async () => {
    await formRef.current.validateForm();
    if (formRef.current.isValid) {
      formRef.current.submitForm();
    } else {
      setShowError(true);
    }
  };

  const handleCheckboxChange = (value, formikProps: FormikProps<any>) => {
    setShowError(false);
    const currentCategories = formikProps.values.categories;
    let newCategories = [];
    if (currentCategories.includes(value)) {
      newCategories = currentCategories.filter(category => category !== value);
    }
    else {
      newCategories = [...currentCategories, value];
    }
    formikProps.setFieldValue('categories', newCategories);
  };

  return (
    <Grid container direction='column'>
      <Formik
        initialValues={{
          categories: (shop?.artisanCategory?.subcategories || []).filter(category => validCategories.includes(category)),
        }}
        validationSchema={categoriesSchema}
        onSubmit={submit}
        innerRef={formRef}
      >
        {(formikProps: FormikProps<any>) => (
          <AdminForm>
            {showError &&
              <Grid item xs={12} style={{ marginTop: 24 }}>
                <Banner variant='error'>
                  <Typography variant='body2' style={{ color: 'inherit' }}>
                    Please select at least one option.
                  </Typography>
                </Banner>
              </Grid>
            }
            <Grid item xs={12}>
              <RadioOrCheckboxGroup
                inputType="checkbox"
                items={checkboxList}
                selectedItem={formikProps.values.categories}
                setSelection={(value) => handleCheckboxChange(value, formikProps)}
              />
              <OnboardingFooter step={step} nextStep={onNextClick} prevStep={prevStep} />
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default Categories;
