import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalType } from "../../components/RootModal/RootModal";

interface ModelProps {
  show?: boolean;
}
export interface ModalState {
  modalType: ModalType;
  modalProps: ModelProps;
}

const initialState: ModalState = {
  modalType: null,
  modalProps: {
    show: false,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    //eslint-disable-next-line
    openModal: (state, { payload }: PayloadAction<{ modalType: ModalType; modalProps: any }>) => {
      state.modalType = payload.modalType;
      state.modalProps = payload.modalProps;
    },
    //eslint-disable-next-line
    closeModal: state => {
      (state.modalType = initialState.modalType), (state.modalProps = initialState.modalProps);
    },
  },
});
export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
