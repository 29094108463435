export const download = (filename: string, type: string, text: string): void => {
  const newBlob = new Blob([text], { type });

  const blobUrl = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  window.URL.revokeObjectURL(blobUrl);
};