import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  placeholder?: string;
  timeZone?: string;
  disabled?: boolean;
  onChange?: (date: Moment) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    /* make it play nice with the custom input labels */
    marginTop: 0,
  },
  /* bit of a hack, but it works */
  error: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const TimeInput: React.FC<Props> = (props: Props) => {
  const { label, secondaryLabel, required, error, name, placeholder, timeZone, disabled, onChange } = props;

  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const currentFormValue = _.get(values, name);
  const currentValue = currentFormValue === 0 ? null : moment.unix(currentFormValue);
  if (timeZone) {
    currentValue.tz(timeZone);
  }

  const defaultOnChange = (date: Moment) => {
    const dateToSet = moment(date);
    if (timeZone) {
      dateToSet.tz(timeZone, true);
    }
    setFieldValue(name, dateToSet.unix());
  };

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          margin="normal"
          minutesStep={5}
          id="time-picker"
          inputVariant="outlined"
          placeholder={placeholder}
          value={currentValue}
          onChange={onChange || defaultOnChange}
          disabled={disabled}
          className={clsx([classes.default, error && classes.error])}
        />
      </MuiPickersUtilsProvider>
    </InputWrapper>
  );
};

export default TimeInput;