import React, { useCallback } from 'react';
import ReactQuill from 'react-quill';
import { Field } from 'formik';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type QuillProps = {
  id: string;
  placeholder: string;
  value: string;
  onChange: (string) => void;
  onBlur?: (event: any) => void;
  height?: number;
  editorClassName?: string;
};

type InputProps = {
  placeholder: string;
  label?: string;
  name: string;
  height?: number;
  fieldDescription?: string;
  className?: string;
  editorClassName?: string;
  initialValue?: string
  onChange?: (event: any) => void;
};

const useClasses = makeStyles((theme: Theme) => ({
  fieldDescription: {
    color: theme.branding.gray[600],
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 8,
  },
}));

const RichTextEditor: React.FC<QuillProps> = (props: QuillProps) => {
  const { id, placeholder, value, onChange, height, onBlur, editorClassName } = props;

  return (
    <div style={{ position: 'relative' }}>
      <ReactQuill
        id={id}
        placeholder={placeholder}
        modules={{
          toolbar: [
            ['bold', 'underline', 'italic'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
        value={value}
        onChange={onChange}
        style={{
          height: height ? `${height}px` : '250px',
        }}
        onBlur={onBlur}
        className={editorClassName}
      />
    </div>
  );
};

const RichTextInput: React.FC<InputProps> = (props: InputProps) => {
  const { className, label, fieldDescription, name, placeholder, editorClassName, height, initialValue, onChange } = props;
  const classes = useClasses();


  return (
    <div className={className ? className : ''} style={{ marginBottom: '60px' }}>
      {label && <label
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {label}
      </label>}
      {fieldDescription && (
        <Typography variant="h6" className={classes.fieldDescription}>
          {fieldDescription}
        </Typography>
      )}
      <Field name={name} type="string">
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }): JSX.Element => (
          <>
            <RichTextEditor
              id={`richeditor-${name}`}
              placeholder={placeholder}
              value={value ? value : ''}
              onChange={(text): void => {
                if (onChange) {
                  onChange(text);
                } else {
                  setFieldValue(name, text);
                }
              }}
              height={height}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              editorClassName={editorClassName}
            />
          </>
        )}
      </Field>
    </div>
  );
};

export default RichTextInput;
