import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './store';
import { ExperimentConfiguration, Theme } from '@castiron/components';
import './index.css';
import App from './components/App/App';
import Error from './components/Error';
import { Configuration, FirebaseApp, FirebaseContext } from '@castiron/castiron-firebase';
import { createBrowserHistory } from "history";
import firebase from 'firebase/compat/app';
import Spinner from "./components/Spinner";
import experiments from './experiments';
import { AttributionConfig } from "@castiron/utils";
import FeatureConfiguration from "./components/FeatureConfiguration";
import StripeElementsProvider from "./components/StripeElementsProvider";

const history = createBrowserHistory();

if (process.env.REACT_APP_IS_LOCAL !== 'false') {
  Sentry.init({
    dsn: 'https://7616a48270f84e1498d2664c14e90a08@o732523.ingest.sentry.io/5796066',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    autoSessionTracking: true,
    tracesSampleRate: 1,
    environment: process.env.REACT_APP_STAGE,
  });
}

if (process.env.REACT_APP_IS_LOCAL_FIREBASE == 'true') {
  console.warn('Local Environment Detected, pointing Firebase to local emulators.');
  FirebaseApp.functions().useEmulator('localhost', 5001);
  FirebaseApp.firestore().useEmulator('localhost', 6001);
  FirebaseApp.storage().useEmulator('localhost', 9199);
  FirebaseApp.auth().useEmulator('http://localhost:9099');
}

// @ts-ignore
const remoteConfig = firebase.remoteConfig(FirebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 15000;

const error = <Error/>;

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ error } showDialog beforeCapture={ (scope => {
    scope.setTags({ WSOD: true });
  }) }>
    <AttributionConfig>
      <FirebaseContext.Provider value={ FirebaseApp }>
        <Provider store={ store }>
          <FeatureConfiguration>
            <ExperimentConfiguration experiments={ experiments } loadTimeout={ 5000 }
                                     loading={ <Spinner size="fullscreen" show={ true }/> }>
              <Configuration remoteConfig={ remoteConfig }>
                <StripeElementsProvider>
                  <Theme>
                    <Router>
                      <Switch>
                        <App/>
                      </Switch>
                    </Router>
                  </Theme>
                </StripeElementsProvider>
              </Configuration>
            </ExperimentConfiguration>
          </FeatureConfiguration>
        </Provider>
      </FirebaseContext.Provider>
    </AttributionConfig>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
