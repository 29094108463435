import React, { useContext } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { HeaderText, ProductImage, Text, HashTagChip, AddedTag } from '@castiron/components';
import { Product, ResourceSection } from '@castiron/domain';
import {
  AddToShopButton,
  CreatorInfo,
  DownloadAllResourcesButton,
  PackageAddedButtons,
  QuickAddSection,
  ResourceSectionComponent,
} from '.';
import { useHistory } from 'react-router-dom';
import MobileBackButton from '../../MobileBackButton';
import { CommunityPackageContext, CommunityPackageContextData } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  addedTagContainer: {
    marginTop: 24,
  },
  hashtagsContainer: {
    marginTop: 16,
    gap: 8,
  },
  header: {
    marginTop: 16,
  },
  creatorInfo: {
    marginTop: 16,
    marginBottom: 24,
  },
  description: {
    marginBottom: 24,
  },
  downloadAllButton: {
    marginTop: 48,
  },
  quickAddSection: {
    marginTop: 32,
  },
  buttonContainer: {
    marginTop: 16,
    gap: 8,
    padding: '16px 24px',
    backgroundColor: '#fff',
    bottom: '0',
    borderTop: '1px solid #B8B8B8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'sticky',
    width: '100%',
    zIndex: 1000,
  },
  imageContainer: {
    display: 'flex'
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '4px',
    maxHeight: 'unset',
    maxWidth: 'unset',
    display: 'flex'
  }
}));

// Mobile layout for Community package details
const MobileLayout: React.FC = () => {
  const { communityPackage } = useContext<CommunityPackageContextData>(CommunityPackageContext);

  const classes = useStyles();
  const history = useHistory();

  // Handle MobileBackButton click
  const onBackClick = () => history.push('/community');

  // Ensures Community package has product and sets to variable
  const product = communityPackage?.products?.length && (communityPackage.products[0] as Product);
  if (!product) return null;

  return (
    <>
      <MobileBackButton label="Community" onClick={onBackClick} />

      <Grid container className={classes.addedTagContainer}>
        <AddedTag added={!!communityPackage.addedProductId} />
      </Grid>

      {/* HashTags */}
      <Grid container className={classes.hashtagsContainer}>
        {communityPackage?.tags.map(hashtag => (
          <HashTagChip label={hashtag} />
        ))}
      </Grid>

      {/* Community package title */}
      <HeaderText presetFont="heading--l" lineHeight={40} className={classes.header}>
        {communityPackage?.title}
      </HeaderText>

      <CreatorInfo author={communityPackage?.author} className={classes.creatorInfo} />

      {/* Description */}
      <Text
        presetFont="txt--m"
        dangerouslySetInnerHTML={{
          __html: communityPackage?.description,
        }}
        className={classes.description}
      />

      <ProductImage
        alt={product?.title}
        src={product?.imageObj?.downloadUrl}
        backgroundColor="inherit"
        className={classes.image}
        containerClassName={classes.imageContainer}
      />

      {communityPackage.allowDownloadAll && <DownloadAllResourcesButton
        packageId={communityPackage?.id}
        className={classes.downloadAllButton}
        variant="outlined"
        fullWidth
      />}

      <QuickAddSection
        className={classes.quickAddSection}
        communityPackageId={communityPackage?.id}
        added={!!communityPackage?.addedProductId}
      />

      {/* Community package resource sections */}
      {communityPackage?.resources.map((resourceSection: ResourceSection) => (
        <ResourceSectionComponent resourceSection={resourceSection} />
      ))}

      {/* AddToShopButton */}
      <Grid className={classes.buttonContainer}>
        {!!communityPackage.addedProductId ? (
          <PackageAddedButtons addedProductId={communityPackage.addedProductId} fullWidth size="small" />
        ) : (
          <AddToShopButton variant="contained" fullWidth communityPackageId={communityPackage?.id} size="small" />
        )}
      </Grid>
    </>
  );
};

export default MobileLayout;
