import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { CircularProgress, Grid, Hidden, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { SaveButton, DiscardButton, Button } from '@castiron/components';
import { Customer, LineItem, Transaction } from '@castiron/domain';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { customerRepository, transactionRepository } from '../../domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { getProductsAction } from '../../store/reducers/products';
import DetailsBox from './DetailsBox';
import AdminForm from '../AdminForm';
import { updateCustomerAction, getCustomerByIdAction } from '../../store/reducers/customers';
import Dropdown from '../Dropdown';
import FulfillmentDetailsBox from '../FulfillmentDetailsBox';
import GiftDetailsBox from '../GiftDetailsBox';
import { LayoutPageProps } from '../Layout';
import Spinner from '../Spinner';
import UnsavedChangesPrompt from '../UnsavedChangesPrompt.tsx';
import OrderCustomer from './OrderCustomer';
import QuoteRequestDetailsBox from './QuoteRequestDetailsBox';
import { getService } from '../../firebase';
import { Print } from '@material-ui/icons';

const printOrderService = getService('orders', 'printOrder');

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: "8px 16px",
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: 8,
  },
  popover: {
    minWidth: 350,
  },
  printIcon: {
    color: theme.branding.gray[500],
  },
  subheader: {
    marginBottom: '8px',
  },
}));

const OrderView: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [status, setStatus] = useState('open');
  const [customer, setCustomer] = useState<Customer>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [transaction, setTransaction] = useState<Transaction>();
  const formikRef = useRef<FormikProps<any>>();

  const [submitting, setSubmitting] = useState(false);
  const [printing, setPrinting] = useState(false);

  const orderedItems: LineItem[] = transaction?.order?.items
    ? transaction?.order.items
    : transaction?.products.map(p => ({
      id: p.product.id,
      title: p.product.title,
      description: p.product.description,
      category: p.product.category,
      price: p.product.price,
      quantity: p.quantity,
      selections: p.selectedVariationValues,
      type: 'standard',
    }));

  const { shop, isProductsLoading } = useAppSelector(state => ({
    shop: state.shops.shop,
    isProductsLoading: state.products.loading,
  }));

  const { trackEvent } = useTracking();

  const getTransaction = async id => {
    const response = await transactionRepository.get(id);
    if (response == null) history.push('/orders');
    setTransaction(response);
  };

  const getCustomer = async id => {
    const customer = await customerRepository.get(id);
    setCustomer(customer);
  };

  const { id } = useParams<{ id: string }>();

  const statusOptions = [
    {
      label: 'Open',
      onClick: (): void => setStatus('open'),
    },
    {
      label: 'Completed',
      onClick: (): void => setStatus('completed'),
    },
    {
      label: 'Fulfilled',
      onClick: (): void => setStatus('fulfilled'),
    },
    {
      label: 'Canceled',
      onClick: (): void => setStatus('canceled'),
    },
  ];
  const statusSelector = (
    <Dropdown
      popoverClass={classes.popover}
      title={`Status: ${_.capitalize(status)}`}
      options={statusOptions}
    />
  );

  const printOrder = async () => {
    setPrinting(true);
    const printFrame = document.createElement('iframe');
    printFrame.style.display = 'none';
    document.title = `Order ${transaction?.order?.orderNumber || ''}`;

    const response = await printOrderService({
      transactionId: id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
      title: `Order ${transaction?.order?.orderNumber || ''}`,
    });

    printFrame.srcdoc = response.html;

    document.body.appendChild(printFrame);

    printFrame.onload = () => {
      setTimeout(() => {
        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();

        setPrinting(false);
      });
    };

    trackEvent('Print Order', {ordersPrinted: 1});
  };

  useEffect(() => {
    if (id) {
      getTransaction(id);
    }
    setBackLocation(true);

    return () => {
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    const printButton = (
      <Button
        variant='outlined'
        onClick={printOrder}
        loading={printing}
      >
        Print
      </Button>
    );

    const printIcon = (
      <>
        {
          printing &&
          <CircularProgress size={12} />
        }
        <Print onClick={printOrder} className={classes.printIcon} />
      </>
    )

    if (isMobile) {
      setHeaderCTAs([printIcon]);
    } else {
      setHeaderCTAs([printButton, statusSelector]);
    }

    return (() => {
      setHeaderCTAs([])
    })
  }, [isMobile, status, customer, transaction, shop, printing]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation='/orders' />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />
    ])

    return () => {
      setFooterCTAs([])
    }
  }, [submitting]);

  useEffect(() => {
    getCustomer(transaction?.customer);
    setStatus(transaction?.status);
    setPageTitle(`Order ${transaction?.order.orderNumber}`)
  }, [transaction]);

  useEffect(() => {
    const getProducts = async id => {
      dispatch(getProductsAction(id));
    };

    if (shop?.id) getProducts(shop.id);
  }, [shop]);

  const handleSubmit = async values => {
    try {
      setSubmitting(true);

      const newCustomerData = {
        ...customer,
        ...values.customer,
        subscriberOrigination: values.subscribed ? 'admin-update-customer-form' : '',
      };

      if (status === 'fulfilled' && transaction.status !== 'fulfilled') {
        setCustomer(newCustomerData);
        await dispatch(updateCustomerAction(newCustomerData));
        await dispatch(getCustomerByIdAction(customer.id));

        dispatch(
          openModal({
            modalType: 'FULFILL_ORDER',
            modalProps: {
              show: true,
              transaction: transaction,
              customer: newCustomerData
            },
          }),
        );
      } else {
        await transactionRepository.updateProps(transaction.id, {
          status,
        });

        setCustomer(newCustomerData);
        await dispatch(updateCustomerAction(newCustomerData));
        await dispatch(getCustomerByIdAction(customer.id));

        trackEvent('Order updated', { transaction: { id: transaction.id } });
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  Order <strong>{transaction.order.orderNumber}</strong> was updated
                </>
              ),
            },
          }),
        );
        history.push('/orders');
      };

      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;
  const orderSchema = yup.object().shape({
    products: yup.array().of(yup.object()),
    customer: yup.object().shape({
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup
        .string()
        .email('Invalid email')
        .required("Please enter your contact's email"),
      mobileNumber: yup.string().matches(phoneRegExp, 'Please enter a valid 10-digit phone number'),
      notes: yup.string(),
      subscribed: yup.boolean(),
    }),
  });

  const initialValues = {
    customer: {
      firstName: customer?.firstName,
      lastName: customer?.lastName || '',
      email: customer?.email || '',
      mobileNumber: customer?.mobileNumber || '',
      address: customer?.address || '',
      notes: customer?.notes || '',
      subscribed: customer?.subscribed,
    },
    notes: transaction?.notes || ''
  }

  return (
    <div className={classes.container}>
      <Spinner show={isProductsLoading} />
      <Formik
        initialValues={initialValues}
        validationSchema={orderSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {
          ({ dirty }) => (
            <AdminForm>
              <UnsavedChangesPrompt when={dirty} />
              <Hidden mdUp>
                <Grid container justify='flex-end' className={classes.subheader}>
                  <Grid item>
                    {statusSelector}
                  </Grid>
                </Grid>
              </Hidden>
              <Grid spacing={isMobile ? 0 : 6} container>
                <Grid item xs={12} md={7} lg={8}>
                  <DetailsBox transaction={transaction} />
                  {transaction?.order?.type === 'custom' && transaction?.order?.items[0]?.selections?.length > 0 && (
                    <QuoteRequestDetailsBox orderedItems={orderedItems} />
                  )}
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                  <GiftDetailsBox order={transaction} />
                  <OrderCustomer customer={customer} />
                  <FulfillmentDetailsBox customer={customer} transaction={transaction} onUpdate={() => getTransaction(id)} />
                </Grid>
              </Grid>
            </AdminForm >
          )
        }
      </Formik >
    </div >
  );
};

export default OrderView;
