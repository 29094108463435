import React from "react";
import { SchedulerDateTime } from "@devexpress/dx-react-scheduler";
import { AvailabilityStatusLabel } from "@castiron/components";
import { AvailabilitySubtype } from "@castiron/domain";
import { CalendarViewType } from "..";
import AvailabilityStatusToggle from "../AvailabilityStatusToggle";
import moment from "moment-timezone";

interface Props {
  viewType: CalendarViewType;
  availabilityType: AvailabilitySubtype;
  startDate?: SchedulerDateTime;
  endDate?: SchedulerDateTime;
  onSetAvailability?: (type: AvailabilitySubtype, start: number, end: number) => void;
};

const AvailabilityListing: React.FC<Props> = (props: Props) => {
  const { viewType, availabilityType, startDate, endDate, onSetAvailability } = props;

  return (
    availabilityType ? (
      viewType === 'Month' ? (
        availabilityType === 'unavailable' || availabilityType === 'limited' ? (
          <AvailabilityStatusLabel status={availabilityType} variant="short" iconOnly={true} />
        ) : null
      ) : (
        <AvailabilityStatusToggle
          variant="short"
          status={availabilityType}
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          onSetAvailability={onSetAvailability}
        />
      )
    ) : null);
};

export default AvailabilityListing;