import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { listCustomTransactionsAction, listTransactionsAction } from '../../store/reducers/transactions';
import { getProductsAction } from '../../store/reducers/products';
import { openModal } from '../../store/reducers/modalConductor';
import Stats from './Stats';
import Tables from './Tables';
import { setRedirectUrl } from '../../store/reducers/shops';
import Spinner from '../Spinner';
import { getOpenOrders } from '../../lib/getOpenOrders';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Layout/AvatarMenu';
import SubscriptionBanner from '../SubscriptionBanner';
import ViewShopButton from '../Layout/Header/ViewShopButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  spinnerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Dashboard: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Home Page';

  const {
    shop,
    transactions,
    isTransactionsLoading,
    products,
    redirectUrl,
    isProductsLoading,
    isShopLoading,
    modal,
  } = useAppSelector(state => ({
    shop: state.shops.shop,
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
    isProductsLoading: state.products.loading,
    isShopLoading: state.shops.loading,
    products: state.products.products,
    redirectUrl: state.shops.redirectUrl,
    modal: state.modal,
  }));

  const displaySeoLocationModal = () => {
    dispatch(
      openModal({
        modalType: 'SEO_LOCATION_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  useEffect(() => {
    // Check for redirect in query param
    if (shop && redirectUrl && history) {
      history.push(redirectUrl);
      dispatch(setRedirectUrl(''));
    }

    const seoLocationModal = localStorage.getItem('seenSeoLocationModal');
    if (
      !shop?.seoMetadata?.address?.addressLocality &&
      !shop?.seoMetadata?.address?.addressRegion &&
      !seoLocationModal
    ) {
      displaySeoLocationModal();
    }

    setPageTitle('Home');
    setFooterCTAs([]);
    window.Beacon('destroy');

    return () => {
      setPageTitle('');
      window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_KEY);
      window.Beacon('config', { hideFABOnMobile: true });
    };
  }, []);

  useEffect(() => {
    setHeaderCTAs([<ViewShopButton />, isMobile && <AvatarMenu />]);
  }, [isMobile]);

  useEffect(() => {
    if (shop && shop.id) {
      dispatch(getProductsAction(shop.id));
    }
  }, [dispatch, shop]);

  return (
    <>
      {modal.modalType !== 'CHANGE_PLANS_MODAL' && (
        <Spinner show={isShopLoading || isProductsLoading} size={'fullscreen'} />
      )}
      <div>
        <Helmet>
          <title>Home | Castiron</title>
        </Helmet>
        <Grid className={classes.container} container>
          {isVariant && (
            <Grid xs={12}>
              <SubscriptionBanner />
            </Grid>
          )}
          <Grid container spacing={isMobile ? 0 : 6}>
            <Grid item xs={12}>
              <Stats />
              {!isTransactionsLoading && transactions && (
                <Tables ordersData={getOpenOrders(transactions)} productsData={products} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
