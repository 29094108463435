import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Paper, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CheckCircleOutline, Close, ListAlt, MailOutline } from '@material-ui/icons';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  Checkbox,
  ChevronDownIcon,
  Chip,
  Dropdown,
  DropDownOption,
  FaqsIcon,
  FileUploadIcon,
  SvgIcon,
  Typography,
  useFeatures,
  RocketOutlinedIcon,
} from '@castiron/components';
import { Transaction } from '@castiron/domain';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { useConfig } from '@castiron/castiron-firebase';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { listAllTransactionsAction } from '../../store/reducers/transactions';
import { getCustomersAction } from '../../store/reducers/customers';
import { openModal } from '../../store/reducers/modalConductor';
import EmptyList from '../EmptyList';
import { LayoutPageProps } from '../Layout';
import OrdersList from './OrdersList';
import Spinner from '../Spinner';
import EllipsisMenu from '../Layout/Header/EllipsisMenu';
import AvatarMenu from '../Layout/AvatarMenu';
import ResourceGuide from '../ResourceGuide';
import SubscriptionBanner from '../SubscriptionBanner';
import MobileList from './MobileList';
import _ from 'lodash';
import { getService } from '../../firebase';
import { EmptyOrdersIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../EmptyPage';

const printOrderService = getService('orders', 'printOrder');

const useStyles = makeStyles((theme: Theme) => ({
  bannerText: {
    padding: '0px 4px',
    textAlign: 'center',
  },
  checkCircleOutlineIcon: {
    height: 20,
    width: 20,
    paddingBottom: 2,
  },
  chevronDownIcon: {
    height: 40,
    width: 40,
    color: theme.branding.gray[800],
  },
  clearFilterText: {
    cursor: 'pointer',
    paddingLeft: 8,
  },
  closeIcon: {
    height: 20,
    width: 20,
    color: theme.branding.gray[600],
    marginLeft: 8,
  },
  dropdownBanner: {
    width: '100%',
    borderRadius: 12,
    padding: '24px 16px',
    backgroundColor: theme.branding.yellow.light,
    maxWidth: 198,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  faqsIcon: {
    '& svg path': {
      fill: theme.branding.gray[800],
    },
  },
  filterPill: {
    cursor: 'pointer',
    padding: '4px 12px',
  },
  icon: {
    height: 104,
    width: 190,
  },
  learnMore: {
    fontSize: 16,
    color: theme.branding.gray[100],
    marginLeft: 8,
  },
  upgradeButton: {
    padding: '16px 24px',
    marginTop: 8,
  },
  resourceGuide: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 17px',
    },
  },
  rocket: {
    color: '#F57F17',
    height: 14,
    width: 14,
  },
  rocketContainer: {
    backgroundColor: theme.branding.yellow.light,
    borderRadius: '100%',
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  statusApplyFilterButton: {
    padding: 16,
  },
  statusCTAsContainer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: 8,
  },
  statusEndAdornment: {
    top: 6,
  },
  filtersContainer: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
      width: '100%',
      padding: '16px',
    },
  },
  statusInput: {
    cursor: 'pointer',
    color: theme.branding.gray[100],
  },
  statusInputRoot: {
    height: 56,
    minWidth: 125,
    borderRadius: 12,
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
  },
  statusListbox: {
    padding: 0,
  },
  statusOption: {
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
    '&:last-child': {
      border: 'none',
    },
    // Selected
    '&[aria-selected="true"]': {
      background: 'none',
    },
  },
  statusPaperClass: {
    marginTop: 8,
    width: 400,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  statusRoot: {
    '& .MuiFormControl-marginDense': {
      margin: 0,
    },
  },
  statusTag: {
    display: 'none',
  },
  subscriptionBanner: {
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      margin: '16px 17px 0px 17px',
    },
  },
  tooltipText: {
    color: theme.branding.gray[100],
  },
  upgradeToPro: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const Orders: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const ffconfig = useConfig();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const filterOptions = ['open', 'completed', 'fulfilled', 'canceled'];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { shop, account, customers, transactions, isTransactionsLoading, modal, subscription } = useAppSelector(state => ({
    shop: state.shops.shop,
    account: state.shops.account,
    customers: state.customers.customers,
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
    modal: state.modal,
    subscription: state.shops.account.subscription,
  }));

  const [validTransactions, setValidTransactions] = useState<Transaction[]>([]);
  const [orders, setOrders] = useState<Transaction[]>([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [newFilters, setNewFilters] = useState([]);
  const [isStatusFilterApplied, setIsStatusFilterApplied] = useState<boolean>(false);
  const [showResourceGuideProps, setShowResourceGuideProps] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<Transaction[]>([]);
  const [printing, setPrinting] = useState<boolean>(false);

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Order Page';

  const features = useFeatures() || [];
  const isPrintEnabled = features.includes('admin.orders.actions.print');
  const isMultipleFulfillOrdersEnabled = features.includes('admin.orders.actions.multipleFulfillOrders');
  const isSubscriptionPreviewEnabled = ffconfig?.featureFlag('feature_subscriptions_preview', shop);

  const resourceGuideProps = {
    id: 'orders-video-guide',
    link: 'https://castiron.helpscoutdocs.com/article/96-your-customers-check-out-experience-for-non-custom-products',
    title: 'The Customer Checkout Process',
    text: 'See how easy it is for your customers to shop at your store.',
    videoLength: '70 Sec',
    trackEventTitle: 'Order Guide Click',
  };

  const exportOrdersDisplay = 'Export';
  const openExportOrdersMenu = () => {
    dispatch(
      openModal({
        modalType: 'EXPORT_ORDERS',
        modalProps: {
          show: true,
          // Export all orders in the current view if none are selected
          selectedOrders: selectedOrders?.length > 0 ? selectedOrders : orders,
          trackEventProps: {
            exportAllOrders: selectedOrders?.length === 0 || selectedOrders?.length === orders?.length,
            filters: currentFilters,
            selectedOrderCount: selectedOrders?.length > 0 ? selectedOrders.length : orders?.length,
          },
        },
      }),
    );
  };

  useEffect(() => {
    setPageTitle('Orders');
    setFooterCTAs([]);
    return () => {
      setPageTitle('');
      setHeaderCTAs([]);
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    const headerCTAs = isMobile
      ? [
        <EllipsisMenu
          options={[
            {
              display: 'Export',
              icon: <FileUploadIcon />,
              action: openExportOrdersMenu,
            },
          ]}
        />,
        <AvatarMenu />,
      ]
      : [
        <Button variant="outlined" onClick={openExportOrdersMenu}>
          {exportOrdersDisplay}
        </Button>,
      ];
    setHeaderCTAs(transactions?.length == 0 ? [] : headerCTAs);
  }, [isMobile, selectedOrders, validTransactions]);

  useEffect(() => {
    if (shop && shop.id) {
      dispatch(listAllTransactionsAction(shop.id));
      dispatch(getCustomersAction(shop.id));
    }
  }, [dispatch, shop]);

  useEffect(() => {
    if (transactions) {
      const successfulTransactions = transactions?.filter(d => d.transactionStatus === 'succeeded');
      setOrders(successfulTransactions);
      setValidTransactions(successfulTransactions);
      setShowResourceGuideProps(transactions?.length !== 0 && successfulTransactions.length <= 5);
    }
  }, [shop, transactions]);

  useEffect(() => {
    const filterString = urlParams.get('filter');
    if (filterString) {
      const filterChoices = filterString.split(',');
      const toFilter = filterChoices.filter(choice => filterOptions.includes(choice));

      setIsStatusFilterApplied(true);
      applyFilterChange(toFilter);
      setNewFilters(toFilter);
    };
  }, [validTransactions]);

  const contentEmptyList = {
    header: 'No orders yet?',
    subheader:
      'Once customers start placing orders through your website they will appear here for you to view and manage. If you need to enter a manual order start below.',
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: string[]): void => {
    setNewFilters(value);
  };

  const applyFilterChange = (overrideFilters?: string[]): void => {
    const filters = overrideFilters ? overrideFilters : newFilters;
    if (filters.length === 0) {
      setOrders(validTransactions);
    } else {
      setOrders(validTransactions.filter(o => filters.includes(o.status)));
    }
    setCurrentFilters(filters);
    /* clear selections always when filters change */
    setSelectedOrders([]);
  };

  const handleFilterPillChange = (event: React.MouseEvent<HTMLElement>): void => {
    const filterClicked = event.currentTarget.id;
    const newFilters = currentFilters.filter(filter => filter !== filterClicked);
    handleFilterChange(event, newFilters);
    applyFilterChange(newFilters);
  };

  const clearFilters = (event: React.MouseEvent<HTMLElement>): void => {
    handleFilterChange(event, []);
    applyFilterChange([]);
  };

  const renderStatusPaperComponent = ({ children, ...other }) => (
    <Paper {...other} className={classes.statusPaperClass}>
      {children}
      <Grid container wrap="nowrap" justify="space-between" className={classes.statusCTAsContainer}>
        <Grid container item wrap="nowrap" alignItems="center">
          {newFilters.length > 0 && (
            <>
              <Grid item>
                <Typography variant="body2" style={{ color: theme.branding.blue.primary, paddingLeft: 16 }}>
                  {newFilters.length} Selected
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.clearFilterText} onMouseDown={clearFilters} variant="body2">
                  Clear
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item>
          <Button
            className={classes.statusApplyFilterButton}
            onMouseDown={() => applyFilterChange()}
            variant="contained"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  const renderStatusInput = params => (
    <TextField
      {...params}
      InputProps={{ ...params.InputProps }}
      InputLabelProps={{ shrink: false }}
      label={
        <Typography variant="body1" style={{ paddingTop: 4 }}>
          {isMobile ? <CheckCircleOutline className={classes.checkCircleOutlineIcon} /> : 'Status'}
          {currentFilters.length > 0 && isStatusFilterApplied && (
            <span style={{ color: theme.branding.blue.primary, marginLeft: 8 }}>{currentFilters.length}</span>
          )}
        </Typography>
      }
      variant="outlined"
    />
  );

  const renderStatusOption = (option, { selected }) => (
    <Checkbox checked={selected} label={<Typography variant="body1">{_.capitalize(option)}</Typography>} />
  );

  const chevronDownIcon = (
    <SvgIcon className={classes.chevronDownIcon}>
      <ChevronDownIcon />
    </SvgIcon>
  );

  const statusFilter = (
    <Autocomplete
      classes={{
        endAdornment: classes.statusEndAdornment,
        input: classes.statusInput,
        inputRoot: classes.statusInputRoot,
        listbox: classes.statusListbox,
        option: classes.statusOption,
        root: classes.statusRoot,
        tag: classes.statusTag,
      }}
      closeIcon={chevronDownIcon}
      disableClearable
      disableCloseOnSelect
      getOptionLabel={(option: string) => _.capitalize(option)}
      inputValue="" // disables typing
      multiple
      options={filterOptions}
      onChange={handleFilterChange}
      onClose={() => {
        setIsStatusFilterApplied(true);
        setNewFilters(currentFilters);
      }}
      onOpen={() => {
        setIsStatusFilterApplied(false);
      }}
      PaperComponent={renderStatusPaperComponent}
      popupIcon={chevronDownIcon}
      renderInput={renderStatusInput}
      renderOption={renderStatusOption}
      value={newFilters}
    />
  );

  const printOrders = async () => {
    try {
      setPrinting(true);

      const printFrame = document.createElement('iframe');
      printFrame.style.display = 'none';
      document.title = 'Orders';

      const ordersHtml = [];

      for (let order of selectedOrders) {
        // get generated html for the order
        let response = await printOrderService({
          transactionId: order.id,
          timeZone: shop?.config?.timeZone || defaultTimeZone,
          title: 'Orders',
        });
        // append to ordersHtml
        ordersHtml.push(response.html);
      }

      printFrame.srcdoc = ordersHtml.join('');

      document.body.appendChild(printFrame);

      printFrame.onload = () => {
        setTimeout(() => {
          printFrame.contentWindow.focus();
          printFrame.contentWindow.print();

          setPrinting(false);
        });
      };

      trackEvent('Print Order', { ordersPrinted: selectedOrders?.length });
    } catch (err) {
      console.debug(err);
      setPrinting(false);
    }
  };

  const uniqueSelectedCustomers = () => {
    const selectedCustomers = selectedOrders
      .map(ord => {
        const cust = customers.find(c => c.id == ord.customerObj?.id);
        return (
          cust && {
            email: cust.email,
            id: cust.id,
            name: `${cust.firstName} ${cust.lastName ? cust.lastName : ''}`,
          }
        );
      })
      .filter(sc => !!sc);
    return _.uniqBy(selectedCustomers, 'id');
  };

  const onSendClick = async () => {
    await dispatch(
      openModal({
        modalType: 'BULK_CONTACT_MODAL',
        modalProps: {
          customerList: uniqueSelectedCustomers(),
          show: true,
        },
      }),
    );
    setSelectedOrders([]);
  };

  const openOrderFulfilledModal = () => {
    dispatch(
      openModal({
        modalType: 'FULFILL_ORDER',
        modalProps: {
          multipleTransactions: selectedOrders,
          show: true,
          updateList: true,
        },
      }),
    );
    setSelectedOrders([]);
    clearFilters(null);
  };

  const formatGatedText = (text: string) => (
    <Typography variant="button" style={{ display: 'flex' }}>
      {text}{' '}
      <span className={classes.rocketContainer}>
        <RocketOutlinedIcon className={classes.rocket} />
      </span>
    </Typography>
  );

  const upgradeTooltip = (
    <Typography variant="caption" className={classes.tooltipText}>
      Upgrade to Pro to unlock all bulk actions.
    </Typography>
  );

  const openChangePlanModal = async () => {
    trackEvent('Gated Feature Upgrade Clicked', { feature: 'bulkActions' });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  const bulkActions: DropDownOption[] = [
    {
      label: (
        <Typography variant="body2" style={{ color: theme.branding.blue.primary }}>
          {selectedOrders.length} Order{selectedOrders.length === 1 ? '' : 's'} Selected
        </Typography>
      ),
      onClick: () => {
        /* do nothing */
      },
    },
    {
      label: 'Send Message',
      icon: <MailOutline />,
      onClick: onSendClick,
    },
    {
      label: 'Export Report',
      icon: <ListAlt />,
      onClick: openExportOrdersMenu,
    },
    ...(isMultipleFulfillOrdersEnabled
      ? [
        {
          label: 'Mark as Fulfilled',
          icon: <DriveEtaOutlinedIcon />,
          onClick: openOrderFulfilledModal,
        },
      ]
      : isSubscriptionPreviewEnabled
        ? [
          {
            label: formatGatedText('Mark as Fulfilled'),
            icon: <DriveEtaOutlinedIcon />,
            onClick: () => { },
            tooltipText: upgradeTooltip,
          },
        ]
        : []),
    ...(isPrintEnabled
      ? [
        {
          label: 'Print',
          icon: <FaqsIcon className={classes.faqsIcon} />,
          onClick: printOrders,
        },
      ]
      : isSubscriptionPreviewEnabled
        ? [
          {
            label: formatGatedText('Print'),
            icon: <FaqsIcon className={classes.faqsIcon} />,
            onClick: () => { },
            tooltipText: upgradeTooltip,
          },
        ]
        : []),
    ...((!isMultipleFulfillOrdersEnabled || !isPrintEnabled) && isSubscriptionPreviewEnabled
      ? [
        {
          label: (
            <Grid className={classes.dropdownBanner}>
              <Typography variant="body2" className={classes.bannerText}>
                <span style={{ fontWeight: 700 }}>Upgrade to Pro</span> to unlock all Bulk Actions.
              </Typography>
              <Button variant="contained" onClick={openChangePlanModal} className={classes.upgradeButton}>
                <RocketOutlinedIcon className={classes.rocket} style={{ color: theme.branding.yellow.primary }} />
                <Typography variant="button" className={classes.learnMore}>
                  Upgrade
                </Typography>
              </Button>
            </Grid>
          ),
          onClick: () => { },
          isDropdownBanner: true,
        },
      ]
      : []),
  ];

  const filterPills = currentFilters.map(filter => (
    <Grid item key={`filterPill-${filter}`}>
      <Chip className={classes.filterPill} colorScheme="fulfilled" id={filter} onClick={handleFilterPillChange}>
        <Typography variant="caption">{_.capitalize(filter)}</Typography>
        <Close className={classes.closeIcon} />
      </Chip>
    </Grid>
  ));

  const openAddProductModal = () => {
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          createStandardProductLink: '/products/add/standard',
          createCustomProductLink: '/products/add/custom',
          modalOpen: true,
        },
      }),
    );
  };

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'cookie',
      title: 'Add a Product',
      description: 'Enable your customers to place an order online.',
      ctaAction: () => openAddProductModal(),
    },
    {
      icon: 'megaphone',
      title: 'Announce a Product',
      description: 'Once a product is added, tell your customers about it!',
      ctaAction: () => window.open('/marketing/new-product-email', '_self').focus(),
    },
  ];

  const emptyOrders = () => (
    <Grid className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyOrdersIcon className={classes.icon} />}
        title="No Orders to Display"
        description="Here are a few suggestions to help you get your first order
      with Castiron in no time:"
        tipsForYou={tipsForYou}
        page="Orders"
      />
    </Grid>
  );

  return (
    <div>
      <Spinner label="Exporting your PDF" show={printing} size="fullscreen" />
      <Helmet>
        <title>{`Orders | ${shop ? shop.businessName : ''}`}</title>
      </Helmet>
      <Grid justify="center" container>
        {modal.modalType !== 'CHANGE_PLANS_MODAL' && <Spinner show={isTransactionsLoading} />}
        {isVariant && (
          <Grid item xs={12} className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        {showResourceGuideProps && (
          <Grid item xs={12} className={classes.resourceGuide}>
            <ResourceGuide {...resourceGuideProps} />
          </Grid>
        )}
        <>
          {transactions?.length == 0 ? (
            emptyOrders()
          ) : (
            <Grid container direction="column">
              <Grid item style={{ marginBottom: 16 }}>
                <Grid container justify="space-between" alignItems="flex-start" className={classes.filtersContainer}>
                  <Grid item>
                    <Grid container>
                      <Grid container item alignItems="center">
                        {statusFilter}
                        {currentFilters.length > 0 && (
                          <Typography variant="body1" onClick={clearFilters} className={classes.clearFilterText}>
                            Clear
                          </Typography>
                        )}
                      </Grid>
                      {currentFilters.length > 0 && (
                        <Grid container item spacing={1} style={{ marginTop: 10 }}>
                          {filterPills}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {selectedOrders.length > 0 && (
                    <Grid item>
                      <Dropdown
                        title="Bulk Actions"
                        options={bulkActions}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {isMobile ? (
                  <MobileList
                    transactions={orders}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                  />
                ) : (
                  <OrdersList
                    transactions={orders}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </div>
  );
};

export default Orders;
