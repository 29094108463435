import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PopUpEventsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 20" {...props}>
    <path
      d="M22 9V7C16 5 11 0 11 0C11 0 6 5 0 7V9C0 10.49 0.93 11.75 2.24 12.26C2.2 14.76 1.92 17.69 1 20H21C20.08 17.69 19.8 14.76 19.76 12.26C21.07 11.75 22 10.49 22 9ZM11 2.71C12.33 3.85 14.49 5.55 17.11 7H4.89C7.51 5.55 9.67 3.85 11 2.71ZM12 9H15C15 9.83 14.33 10.5 13.5 10.5C12.67 10.5 12 9.83 12 9ZM8.5 10.5C7.67 10.5 7 9.83 7 9H10C10 9.83 9.33 10.5 8.5 10.5ZM5 9C5 9.83 4.33 10.5 3.5 10.5C2.67 10.5 2 9.83 2 9H5ZM3.66 18C4.05 16.14 4.2 14.18 4.23 12.42C4.91 12.27 5.52 11.93 5.99 11.44C6.24 11.69 6.53 11.89 6.84 12.06C6.74 13.93 6.58 16.06 6.32 17.99H3.66V18ZM8.35 18C8.59 16.17 8.74 14.22 8.83 12.47C9.67 12.39 10.44 12.02 11 11.45C11.56 12.02 12.32 12.39 13.17 12.47C13.27 14.22 13.41 16.17 13.65 18H8.35ZM15.67 18C15.4 16.06 15.24 13.93 15.15 12.07C15.46 11.9 15.76 11.7 16 11.45C16.47 11.93 17.08 12.28 17.76 12.43C17.79 14.19 17.94 16.15 18.33 18.01H15.67V18ZM18.5 10.5C17.67 10.5 17 9.83 17 9H20C20 9.83 19.33 10.5 18.5 10.5Z"
      fill="#005FFF"
    />
  </SvgIcon>
);
