import React from 'react';
import { Grid, makeStyles, Theme, } from '@material-ui/core';
import { Button, BulbIcon, Text, } from '@castiron/components';

const useStyles = makeStyles((theme: Theme) => ({
  bulbIcon: {
    color: '#fff',
    background: theme.branding.gray[800],
    borderRadius: '100%',
    padding: 12,
    width: '2em',
    height: '2em',
    marginBottom: 12,
  },
  container:{
    borderRadius: 8,
    textAlign: 'left',
    padding: '24px',
    color: theme.branding.gray[800],
    background: theme.branding.gray[200],
    '& span': {
      height: '100%',
    },
  },
  getInTouchCardContainer: {
    maxWidth: 335,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
      borderRadius: 0,
    },
  },
  linkWrapper: {
    color: theme.branding.gray[800],
    textDecoration: 'none',
    '&:hover': {
      color: theme.branding.gray[800],
      textDecoration: 'none',
    },
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  getInTouchButton: {
    background: theme.branding.gray[800],
    color: '#fff',
    marginTop: 12,
    '&:hover': {
      background: theme.branding.gray[800],
    },
  },
  text: {
    textAlign: 'center',
  },
}));

const ReachOut: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.getInTouchCardContainer}>
      <Button
        variant="outlined"
        className={classes.container}
      >
        <a className={classes.linkWrapper} href='https://www.castiron.me/submit' target='_blank'>
          <Grid container className={classes.descriptionContainer}>
            <BulbIcon className={classes.bulbIcon} />
            <Text
              className={classes.text}
              presetFont="txt--m"
            >
              Have an idea for a product you'd like to see here? Want to share your photos or product ideas with other artisans?
            </Text>
            <Button
              color='secondary'
              className={classes.getInTouchButton}
              variant="contained"
              size="small"
            >
              Get in Touch
            </Button>
          </Grid>
        </a>
      </Button>
    </Grid>
  );
};

export default ReachOut;
