import React, { useEffect } from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../hooks';
import { Button, Checkbox, Input, Typography } from '@castiron/components';
import { Field, useFormikContext } from 'formik';
import { QuoteValues } from '..';

type Props = {
  newCustomer?: boolean;
  onDiscard: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  checkboxesContainer: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxSubtitle: {
    color: theme.branding.gray[700],
    fontSize: 12,
    alignContent: 'center',
  },
  container: {
    border: '1px solid #7777773D',
    borderRadius: 8,
    padding: 28,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 50,
    zIndex: 1,
  },
  emailInputContainer: {
    flexGrow: 0.5,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    height: 36,
    width: 'auto',
  },
  innerItemBox: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    paddingBottom: 32,
    width: '100%',
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '0px 16px',
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 18,
    marginBottom: 13,
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    zIndex: 1,
  },
  editImg: {
    width: 33,
    height: 33,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    padding: '16px 80px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px',
    },
  },
  button: {
    minWidth: 80,
  }
}));

const AddOrEditCustomer: React.FC<Props> = (props: Props) => {
  const {
    newCustomer,
    onDiscard
  } = props;
  const { setFieldValue, values } = useFormikContext<QuoteValues>();
  const classes = useStyles();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    if (newCustomer) {
      setFieldValue('customer.email', '');
    }
  }, [newCustomer]);

  return (
    <Grid className={classes.itemBox}>
      <Grid container className={classes.itemHeader} alignItems="center">
        <Typography className={classes.title}>Customer</Typography>
      </Grid>
      <Grid className={classes.innerItemBox} container direction='column'>
        <Box className={classes.emailInputContainer}>
          <Input
            fullWidth
            label="Email"
            name={'customer.email'}
            placeholder="you@example.com"
          />
        </Box>
        <Input
          type="tel"
          fullWidth
          name={'customer.mobileNumber'}
          label="Mobile phone"
          placeholder="(555) 555-5555"
        />
        <Input
          fullWidth
          name={'customer.firstName'}
          label="First name"
          placeholder="First name"
        />
        <Input
          fullWidth
          name={'customer.lastName'}
          label="Last name"
          placeholder="Last name"
        />
        <Input
          rows={4}
          fullWidth
          multiline
          name={'customer.notes'}
          label="Contact notes"
          placeholder="Notes on preferences, allergies, dropoff information, etc"
        />
        <Box className={classes.checkboxesContainer}>
          <Field
            as={Checkbox}
            onChange={(e): void => {
              setFieldValue('customer.subscribed', e.target.checked);
            }}
            name={'subscribed'}
            checked={values.customer.subscribed}
            label="Yes, this contact has opted in to my marketing list."
          />
        </Box>
      </Grid>
      <Grid className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={(): void => {
            onDiscard();
          }}
          variant="outlined"
        >
          Discard changes
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddOrEditCustomer;
