import { Form as FormikForm } from 'formik';
import {FormikFormProps} from "formik/dist/Form";
import React from "react";
import HubSpotAmexHack from "../Hacks/HubSpotAmexHack";


type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Props = Override<FormikFormProps, {}>;

const AdminForm: React.FC<Props> = (props: Props) => {
  return (
    <FormikForm {...props}>
      <HubSpotAmexHack/>
      {props.children}
    </FormikForm>
  );
}

export default AdminForm;
