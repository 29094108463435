import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@castiron/components';
import CustomerActions from './CustomerActions';

type Props = {
  data: any;
  isMobile?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 26,
    },
  },
  name: {
    fontSize: 37,
    fontWeight: 800,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const CustomerViewHeader: React.FC<Props> = (props: Props) => {
  const { data, isMobile } = props;
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.name} variant="h2">{`${data.firstName || ''} ${data.lastName ||
          ''}`}</Typography>{' '}
      </Box>
      {!isMobile && <CustomerActions />}
    </Box>
  );
};

export default CustomerViewHeader;
