import { combineReducers } from '@reduxjs/toolkit';
import { experimentReducer as experiments } from '@castiron/components';
import users from './users';
import modal from './modalConductor';
import products from './products';
import customers from './customers';
import shops from './shops';
import transactions from './transactions';
import assets from './assets';
import coupons from './coupons';
import balance from './balance';
import community from './community';
import taxonomy from './taxonomy';
import debug from './debug';

const rootReducer = combineReducers({
  users,
  modal,
  shops,
  products,
  customers,
  transactions,
  assets,
  coupons,
  balance,
  community,
  experiments,
  taxonomy,
  debug
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
