import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RiceBowlIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.66 12C17 13.92 15.42 15.54 13.26 16.39L12 16.88V18H8V16.88L6.73 16.38C4.57 15.53 2.99 13.91 2.33 11.99H17.66M10 0C4.48 0 0 4.48 0 10C0 13.69 2.47 16.86 6 18.25V20H14V18.25C17.53 16.86 20 13.69 20 10C20 4.48 15.52 0 10 0ZM8 10V2.26C8.64 2.1 9.31 2 10 2C10.69 2 11.36 2.1 12 2.26V10H8ZM14 10V3.08C16.39 4.47 18 7.04 18 10H14ZM2 10C2 7.05 3.61 4.47 6 3.08V10H2Z"
      fill="#005FFF"
    />
  </SvgIcon>
);