import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { useConfig } from '@castiron/castiron-firebase';
import { Banner, Button, CheckboxInput, Chip, FaqsIcon, RocketOutlinedIcon, SvgIcon, Typography, useFeatures } from '@castiron/components';
import { Transaction } from '@castiron/domain';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import ModalWrapper from '../../RootModal/ModalWrapper';
import Spinner from '../../Spinner';
import GetAppIcon from '@material-ui/icons/GetApp';

const exportOrdersService = getService('orders', 'exportOrders', { type: 'request' });
const exportSoldProductsService = getService('orders', 'exportsoldproducts', { type: 'request' });
const exportSoldProductsSampleService = getService('orders', 'exportsoldproductssample', { type: 'request' });

export interface EventProperties {
  exportAllOrders: boolean;
  filters: string[];
  selectedOrderCount: number;
}

export interface Props {
  show: boolean;
  selectedOrders: Transaction[];
  trackEventProps: EventProperties;
}

interface ReportOptions {
  orderReport: boolean;
  soldProductReport: boolean;
}
const reportOptionsSchema = yup.object().shape({
  orderReport: yup.boolean(),
  soldProductReport: yup.boolean(),
});

const useStyles = makeStyles((theme: Theme) => ({
  actionsButton: {
    padding: 16,
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    marginTop: 16,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  dialogContent: {
    padding: '24px 32px',
  },
  downloadSampleReport: {
    color: theme.branding.blue.primary,
    cursor: 'pointer',
    marginTop: 8,
  },
  faqsIcon: {
    color: theme.branding.blue.primary,
    height: 24,
    width: 24,
  },
  faqsIconContainer: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: 8,
    marginRight: 16,
    padding: 12,
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
    padding: '20px 16px',
    maxHeight: 71,
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  orderButton: {
    padding: '24px 16px',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
  },
  proFeatureChip: {
    backgroundColor: theme.branding.yellow.light,
    color: theme.branding.orange.primary,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    marginBottom: 8,
  },
  rocketIcon: {
    height: 16,
    width: 16,
    marginLeft: 8,
  },
  tooltip: {
    borderRadius: 12,
    fontSize: 12,
    maxWidth: 220,
    padding: 8,
  },
}));

const ExportOrdersModal: React.FC<Props> = (props: Props) => {
  const { show, selectedOrders, trackEventProps } = props;
  /* I don't love that I'm using this for business logic and leaving it in the trackEventProps */
  const { exportAllOrders } = trackEventProps;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracking();
  const features = useFeatures() || [];
  const ffconfig = useConfig();

  const { shop, account, subscription, tier } = useAppSelector(state => ({
    shop: state.shops.shop,
    account: state.shops.account,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
  }));

  const isSoldProductsEnabled = features.includes('admin.orders.reports.soldProducts');
  const isSubscriptionsPreviewEnabled = ffconfig?.featureFlag('feature_subscriptions_preview', shop);

  const handleClose = (): void => {
    trackEvent('Export Orders Closed');
    dispatch(closeModal());
  };

  const downloadSampleReport = async (event) => {
    /* stops Change Plans Modal from opening */
    event.stopPropagation();

    const csv = await exportSoldProductsSampleService({});
    const filename = `Sample Sold Products Report`;
    download(filename, 'text/csv', csv);
  };

  const openChangePlanModal = () => {
    trackEvent('Shop View Upgrade Modal', {
      shopId: shop.id,
      location: 'exportReportsModal',
      tier: tier,
      currentPlan: subscription?.plan.name,
    });

    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  const onSubmit = useCallback(
    async (values: ReportOptions) => {
      try {
        setIsSubmitting(true);

        if (values.orderReport) {
          const csv = await exportOrdersService({
            transactions: selectedOrders,
            timeZone: shop?.config?.timeZone || defaultTimeZone,
          });

          const date = moment().format('MMDDYYYY');
          const filename = `${shop.websiteUrl}-orders-${date}.csv`;

          download(filename, 'text/csv', csv);
        }

        if (values.soldProductReport) {
          const csv = await exportSoldProductsService({
            transactionIds: selectedOrders.map(tx => tx.id),
          });

          const date = moment().format('MMDDYYYY');
          const filename = `${shop.websiteUrl}-sold-products-${date}.csv`;

          download(filename, 'text/csv', csv);
        }

        setIsSubmitting(false);

        let content: string;
        if (values.orderReport && values.soldProductReport) {
          content = 'Orders and Sold Products reports exported.';
        } else if (values.orderReport) {
          content = 'Orders report exported.';
        } else if (values.soldProductReport) {
          content = 'Sold Products report exported.';
        } else {
          content = 'Orders report exported.';
        }

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: content,
            },
          }),
        );

        trackEvent(
          'Export Orders Clicked',
          {
            ...trackEventProps,
            reports: _.compact([
              values.orderReport && 'Orders',
              values.soldProductReport && 'Sold Products',
            ]),
          }
        );
      } catch (err) {
        console.debug(err);
        setIsSubmitting(false);
      }
    },
    [shop],
  );

  const reports = [
    {
      name: 'Orders Report',
      description: 'Includes detailed order information including customer, fulfillment, product, and variations.',
      value: 'orderReport',
      display: true,
    },
    {
      name: 'Sold Products Report',
      description: 'Includes all products sold for your selected orders as well as calculated totals for each.',
      value: 'soldProductReport',
      display: isSoldProductsEnabled,
    },
  ];

  const initialValues: ReportOptions = {
    orderReport: false,
    soldProductReport: false,
  };

  return (
    <ModalWrapper size={'sm'} show={show} fullScreen={isMobile}>
      <Grid style={{ position: 'relative' }}>
        <Spinner show={isSubmitting} size={'fullscreen'} label="Gathering your order data…" />
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <Close />
        </IconButton>
        <Grid>
          <DialogTitle className={classes.header}>
            <Typography variant="h3">Export Reports</Typography>
          </DialogTitle>
          <Formik
            validationSchema={reportOptionsSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form>
                <DialogContent className={classes.dialogContent}>
                  <Grid container direction='column' spacing={3}>
                    <Grid item>
                      <Banner variant='info-white'>
                        {
                          !!exportAllOrders &&
                          <Typography variant="body2">
                            Exports will include all <b>{selectedOrders.length} order{selectedOrders.length === 1 ? '' : 's'}</b> from your current view.
                          </Typography>
                        }
                        {
                          !exportAllOrders &&
                          <Typography variant="body2">
                            Exports will include data for your <b>{selectedOrders.length} selected order{selectedOrders.length === 1 ? '' : 's'}</b>.
                          </Typography>
                        }
                      </Banner>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Grid container direction='column' spacing={1}>
                            {
                              reports.map((report, index) => {
                                const showSoldProductsPreview = !report.display && isSubscriptionsPreviewEnabled;
                                if (!report.display && !showSoldProductsPreview) return;
                                return (
                                  <Grid item key={`orderReport${index}`}>
                                    <Box
                                      className={classes.orderButton}
                                      style={showSoldProductsPreview ? {cursor: 'pointer'} : {}}
                                      onClick={() => {
                                        if (showSoldProductsPreview) {
                                          openChangePlanModal();
                                        }
                                      }}
                                    >
                                      <Grid container wrap='nowrap' alignItems='flex-start'>
                                        <Grid item className={classes.faqsIconContainer}>
                                          <SvgIcon className={classes.faqsIcon}>
                                            <FaqsIcon />
                                          </SvgIcon>
                                        </Grid>
                                        <Grid container item direction='column' alignItems='flex-start'>
                                          {showSoldProductsPreview &&
                                            <Tooltip
                                              arrow
                                              classes={{
                                                tooltip: classes.tooltip
                                              }}
                                              PopperProps={{
                                                style: {
                                                  marginTop: '-12px',
                                                }
                                              }}
                                              title='Upgrade to Pro to unlock Premium Reports and many other features.'
                                            >
                                              <span>
                                                <Chip className={classes.proFeatureChip}>
                                                  Pro Feature <RocketOutlinedIcon className={classes.rocketIcon}/>
                                                </Chip>
                                              </span>
                                            </Tooltip>
                                          }
                                          <Typography variant="subtitle1">{report.name}</Typography>
                                          <Typography variant="body4">{report.description}</Typography>
                                          {showSoldProductsPreview &&
                                            <Typography variant='body2' className={classes.downloadSampleReport} onClick={downloadSampleReport}>
                                              <GetAppIcon /> Download Sample Report
                                            </Typography>
                                          }
                                        </Grid>
                                        {report.display &&
                                          <Grid item>
                                            <CheckboxInput name={report.value} />
                                          </Grid>
                                        }
                                      </Grid>
                                    </Box>
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actionsContainer}>
                  <Button onClick={handleClose} variant="outlined" className={classes.actionsButton}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={!_.values(values).some(val => !!val)} className={classes.actionsButton}>
                    Export
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default ExportOrdersModal;
