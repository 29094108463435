import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const RetailStoreIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 16" {...props}>
    <path
      d="M15.36 5L15.96 8H2.04L2.64 5H15.36ZM17 0H1V2H17V0ZM17 3H1L0 8V10H1V16H11V10H15V16H17V10H18V8L17 3ZM3 14V10H9V14H3Z"
      fill="#005FFF"
    />
  </SvgIcon>
);
