// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import config from './config';

let FirebaseApp;
if (!firebase.apps.length) {
  FirebaseApp = firebase.initializeApp(config);
} else {
  console.debug('Using existing Firebase app');
  FirebaseApp = firebase.app();
}

export default FirebaseApp;
