import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Grid, Theme, useMediaQuery } from "@material-ui/core";
import Typography from "../Typography";
import { WarningIcon } from '../Icons';
import clsx from "clsx";

interface Props {
  text: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: theme.branding.red.light,
    borderRadius: '8px'
  },
  errorInner: {
    display: 'flex',
    alignItems: 'center'
  },
  errorText: {
    color: theme.branding.red.dark,
  },
  errorTextMobile: {
    padding: '0px 20px 20px'
  },
  warningIcon: {
    margin: '24.5px 0px 6px 24px'
  },
}));

const ErrorBox: React.FC<Props> = (props: Props) => {
  const { text, className } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return <Box className={clsx([classes.error, className])}>
    <Grid container direction='row' className={classes.errorInner} spacing={1} justify="center">
      <Grid item>
        <Box className={classes.warningIcon}>
          <WarningIcon htmlColor={theme.branding.red.dark} fontSize='large' />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant='body2' className={clsx([classes.errorText,isMobile ? classes.errorTextMobile : undefined])}>{ text }</Typography>
      </Grid>
    </Grid>
  </Box>;
}

export default ErrorBox;
