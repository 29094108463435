import React, { useState } from 'react';
import { Box, Grid, Popover, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import moment from 'moment-timezone';
import { CloseIcon } from '@castiron/components';
import { Transaction } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import IndividualListing from './IndividualListing';
import { CalendarViewType } from '..';

interface Props {
  transactions: Transaction[];
  startDate: SchedulerDateTime;
  viewType: CalendarViewType;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  groupHeader: {
    backgroundColor: theme.branding.gray[200],
    padding: '8px 16px',
    marginBottom: '16px',
  },
  groupIndividualListing: {
    paddingLeft: '16px',
  },
  groupListing: {
    paddingLeft: '4px',
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.branding.blue.light,
    },
  },
  popover: {
    width: '250px',
    borderRadius: '8px',
    paddingBottom: '16px',
  },
}));

const AggregatedListing: React.FC<Props> = (props: Props) => {
  const { transactions, startDate, viewType } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();

  const [anchorEl, setAnchorEl] = useState(null);

  const popoverOpen = Boolean(anchorEl);

  const openPopover = (event: React.MouseEvent): void => {
    event.stopPropagation();
    trackEvent('Calendar "More Listings" Clicked', {
      view: viewType,
      totalListings: transactions.length
    });


    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box className={classes.groupListing} onClick={openPopover}>
      <Typography variant="caption">+ {transactions.length} More</Typography>
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popover}>
          <Grid container direction="column">
            <Grid item className={classes.groupHeader}>
              <Typography variant="h6">{moment(startDate).format('MMMM D, YYYY')}</Typography>
            </Grid>
            {transactions.map(tx => {
              return (
                <Grid item key={`tx${tx.order.orderNumber}`} className={classes.groupIndividualListing}>
                  <IndividualListing transaction={tx} viewType={viewType} context='moreMenu' />
                </Grid>
              );
            })}
          </Grid>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </Box>
      </Popover>
    </Box>
  );
};

export default AggregatedListing;