import { Breadcrumbs, makeStyles, Theme, Link as MUILink, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
  label: string;
  curLabel?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbs: {
    fontSize: 14,
    marginBottom: 12,
    '& .MuiBreadcrumbs-li p': {
      fontSize: 14,
    },
    zIndex: 2,
    position: 'relative',
  },
}));

// Because of implementation, breadcrumbs is so far only needed in a small
// number of pages, so this component is optimized for Customers/Products.
// components/breadcrumbs exists for dynamic link generation, to be used on Layout component,
// which might be needed in future implementation
const PageBreadcrumbs: React.FC<Props> = (props: Props) => {
  const { link, label, curLabel } = props;
  const classes = useStyles();

  return (
    <Breadcrumbs separator=">" className={classes.breadcrumbs}>
      <MUILink component={Link} to={link}>
        {label}
      </MUILink>
      <Typography>{curLabel}</Typography>
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
