import React from "react";

/**
 * We have HubSpot's tracking pixel in the Admin app b/c of the onboarding funnel. However, it will indiscriminately
 * scrape ANY contact info it finds on ANY form and sent it to HubSpot, which has the effect of overwriting emails
 * when Artisans manually create contacts or email them using our form. This hack adds hidden fields that trick
 * HubSpot into thinking a form has sensitive information and thus will NOT scrape the form.
 *
 * @constructor
 */

const HubSpotAmexHack = () => {
  return <>
    <label htmlFor="amex" style={{display: 'none'}}>AMEX</label>
    <input type="hidden" name="amex" required={false} value="" id="amex"/>
  </>;
}

export default HubSpotAmexHack;
