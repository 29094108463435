import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ActiveVariant {
  index: number;
  name: string;
}

export interface ExperimentData {
  id: string;
  name: string;
  activeVariant: ActiveVariant;
}

export interface ExperimentState {
  active: ExperimentData[];
}

const initialState: ExperimentState = {
  active: [],
};


const experimentSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    addExperiment: (state: Draft<ExperimentState>, action: PayloadAction<ExperimentData>) => {
      state.active = [
        ...state.active,
        action.payload
      ];
    }
  },

});

export const { addExperiment: addExperimentAction } =  experimentSlice.actions;
export default experimentSlice.reducer;
