import { Grid, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { ReactNode } from 'react';
import { Typography } from '@castiron/components';
import ModalWrapper from './ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';

export type Props = {
  icon?: ReactNode;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode[];
  show?: boolean;
  onClose?: () => void;
  containerClass?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    marginLeft: 8,
  },
  actionsContainer: {
    padding: 12,
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    minHeight: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },
  },
  closeIcon: {
    margin: '16px 16px 0 0',
    cursor: 'pointer',
    color: theme.branding.gray[800],
    fontSize: 32,
  },
  container: {
    textAlign: 'center',
    height: '100%',
  },
  contentContainer: {
    marginBottom: 24,
    padding: `0 24px`,
  },
  iconContainer: {
    fontSize: 63,
  },
  paperClass: {
    borderRadius: 16,
    maxWidth: 504,
  },
  titleContainer: {
    marginBottom: 8,
    padding: `0 24px`,
  },
}));

const GeneralModal: React.FC<Props> = (props: Props) => {
  const { icon, title, content, actions, show, onClose, containerClass } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    };
    dispatch(closeModal());
  };

  return (
    <ModalWrapper paperClass={!isXsMobile && classes.paperClass} fullScreen={isXsMobile} show={show} onClose={handleClose}>
      <Grid container direction="column" className={containerClass? containerClass : classes.container}>
        <Grid container justify="flex-end">
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        {icon && (
          <Grid container justify="center" className={classes.iconContainer}>
            {icon}
          </Grid>
        )}
        {title && (
          <Grid container justify="center" className={classes.titleContainer}>
            {typeof title === 'string' ? (
              <Typography variant='h2'>{title}</Typography>
            ) : (
              title
            )}
          </Grid>
        )}
        {content && (
          <Grid container justify="center" className={classes.contentContainer}>
            {typeof content === 'string' ? (
              <Typography variant='body1'>{content}</Typography>
            ) : (
              content
            )}
          </Grid>
        )}
        {actions && (
          <Grid container justify="flex-end" className={classes.actionsContainer}>
            {
              actions.map((action, index) => (
              <Grid item key={`action${index}`} className={classes.action}>
                {action}
              </Grid>
              ))
            }
          </Grid>
        )}
      </Grid>
    </ModalWrapper>
  );
};

export default GeneralModal;
