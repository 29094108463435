import React from 'react';
import { Checkbox } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from "../InputWrapper";

interface Props extends BaseInputProps {
  className?: string;
  name: string;
  variant?: 'label-left' | 'label-right';
};

const CheckboxInput: React.FC<Props> = (props: Props) => {
  const { label, secondaryLabel, name, required, error, variant='label-left', className } = props;

  const { values, handleChange, setFieldValue } = useFormikContext();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
  };

  return (
    <InputWrapper variant={variant} label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <Checkbox
        className={className}
        checked={!!_.get(values, name)}
        onChange={handleToggle}
      />
    </InputWrapper>
  );
};

export default CheckboxInput;