import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CustomersIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 16" {...props}>
    <g transform="translate(-7.000000, -8.000000)">
      <path d="M16.8181818,16.8888889 C18.5708562,16.8888889 20.0017899,18.3857823 20.0869038,20.2669893 L20.0909091,20.4444444 L20.0909091,23.1111111 C20.0909091,23.5669778 19.7750688,23.9426748 19.3681469,23.99402 L19.2727273,24 L7.81818182,24 C7.39859247,24 7.05276738,23.6568649 7.00550456,23.2147768 L7,23.1111111 L7,20.4444444 C7,18.5403044 8.37779162,16.9857097 10.1093851,16.8932404 L10.2727273,16.8888889 L16.8181818,16.8888889 Z M21.7272727,16.8888889 C23.4799471,16.8888889 24.9108808,18.3857823 24.9959947,20.2669893 L25,20.4444444 L25,23.1111111 C25,23.5669778 24.6840891,23.9426748 24.2772227,23.99402 L24.1818182,24 L22.1660636,24 C21.8837091,24 21.6851364,23.6933333 21.7141,23.3882667 L21.7239386,23.2506222 L21.7272727,23.1111111 L21.7272727,20.4444444 C21.7272727,19.2570667 21.3701364,18.1603556 20.7661545,17.2741333 C20.6726273,17.1368889 20.7389061,16.9392439 20.878968,16.8970172 L20.9347818,16.8888889 L21.7272727,16.8888889 Z M13.5454545,8 C15.3529,8 16.8181818,9.59187556 16.8181818,11.5555556 C16.8181818,13.5192356 15.3529,15.1111111 13.5454545,15.1111111 C11.7379764,15.1111111 10.2727273,13.5192356 10.2727273,11.5555556 C10.2727273,9.59187556 11.7379764,8 13.5454545,8 Z M18.4546273,8 C20.2621545,8 21.7273545,9.59187556 21.7273545,11.5555556 C21.7273545,13.5192356 20.2621545,15.1111111 18.4546273,15.1111111 C18.2738091,15.1111111 18.0963455,15.0952 17.9234636,15.0645333 C17.6446273,15.0151111 17.5496364,14.6571556 17.7003455,14.3976 C18.1781636,13.5748444 18.4546273,12.6002756 18.4546273,11.5555556 C18.4546273,10.5108356 18.1781636,9.53626667 17.7003455,8.71352 C17.5496364,8.45398222 17.6446273,8.09603556 17.9234636,8.04659556 C18.0963455,8.01593778 18.2738091,8 18.4546273,8 Z" />
    </g>
  </SvgIcon>
);
