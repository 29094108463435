import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { Chip, MultipleSelect, Text } from '@castiron/components';
import { useAppSelector } from '../../../hooks';

interface Props {
  initialCategories?: string[];
  initialLocations?: string[];
  initialFulfillmentTypes?: string[];
  onCategoryChange: (categories: string[]) => void;
  onLocationChange: (locations: string[]) => void;
  onFulfillmentChange: (fulfillmentTypes: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    background: theme.branding.gray[200],
    marginBottom: '8px'
  },
  filterSelects: {
    background: "#FFFFFF"
  }
}));

const STATES = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
const FULFILLMENT_TYPES = ['Pickup', 'Shipping', 'Delivery'];

const ArtisanFilter: React.FC<Props> = (props: Props) => {
  const {
    initialCategories,
    onCategoryChange,
    initialLocations,
    onLocationChange,
    initialFulfillmentTypes,
    onFulfillmentChange
  } = props;

  const classes = useStyles();
  const { taxonomy } = useAppSelector(state => ({
    taxonomy: state.taxonomy.taxonomy
  }));

  const categoryOptions = (taxonomy)
    ? taxonomy.categories.map(c => c.name)
    : [];

  return (
    <Grid item container className={classes.filters} direction="row" spacing={2}>
      <Grid item xs={12}>
        <Text variant="subtitle2" bold>Filter Artisans</Text>
      </Grid>
      <Grid item sm={4} xs={12}>
        <MultipleSelect
          options={categoryOptions}
          value={initialCategories || ['All']}
          onChange={(event, values) => onCategoryChange(values)}
          placeholderPrefix="Product Types"
          rootClass={classes.filterSelects}
          allOption="All"
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <MultipleSelect
          options={STATES}
          value={initialLocations || ['Anywhere']}
          onChange={(event, values) => onLocationChange(values)}
          placeholderPrefix="Location"
          rootClass={classes.filterSelects}
          allOption="Anywhere"
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <MultipleSelect
          options={FULFILLMENT_TYPES}
          value={initialFulfillmentTypes || ['All']}
          onChange={(event, values) => onFulfillmentChange(values)}
          placeholderPrefix="Fulfillment Types"
          rootClass={classes.filterSelects}
          allOption="All"
        />
      </Grid>
    </Grid>
  );
};

export default ArtisanFilter;
