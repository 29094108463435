import React from 'react';
import { Box, makeStyles, MenuItem, Select, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import InputLabel from '../InputLabel';

type Props = {
  options: { label: string; value: string }[];
  label: string;
  subLabel?: string;
  name: string;
  placeholder?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.graphite,
  },
  selectorContainer: {
    marginTop: 12,
    marginBottom: 8,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  extraMargin: {
    padding: 26,
  },
  modifiedMargin: {
    padding: 12,
  },
  inputInner: {
    width: '100%',
  },
}));

const DropDownSelector: React.FC<Props> = (props: Props) => {
  const { options, label, subLabel, name, placeholder } = props;
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  return (
    <Box className={classes.selectorContainer}>
      <InputLabel
        primaryLabel={
          <Box className={classes.textContainer}>
            <Typography variant="subtitle1" className={classes.label}>
              {label}
            </Typography>
            {subLabel && (
              <Typography variant="subtitle1" className={classes.subLabel}>
                {subLabel}
              </Typography>
            )}
          </Box>
        }
      />
      <Select
        onChange={event => {
          setFieldValue(name, event.target.value);
        }}
        name={name}
        value={values[name]}
        variant="outlined"
        fullWidth={true}
        inputProps={{ classes: { input: classes.inputInner } }}
        placeholder={placeholder}
      >
        <MenuItem value="none">{placeholder}</MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DropDownSelector;
