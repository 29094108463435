import React, { useContext } from 'react';
import { Grid, Hidden, makeStyles, Theme } from '@material-ui/core';
import PageBreadcrumbs from '../../PageBreadcrumbs';
import { HeaderText, LeftColumn, ProductImage, RightColumn, Text, HashTagChip, AddedTag } from '@castiron/components';
import { Product, ResourceSection } from '@castiron/domain';
import {
  AddToShopButton,
  CreatorInfo,
  DownloadAllResourcesButton,
  PackageAddedButtons,
  QuickAddSection,
  ResourceSectionComponent,
} from '.';
import { CommunityPackageContext, CommunityPackageContextData } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: 16,
  },
  content: {
    marginTop: 32,
    gap: 80,
  },
  creatorInfo: {
    marginTop: 8,
  },
  downloadAllButton: {
    marginTop: 16,
  },
  quickAddSection: {
    marginTop: 40,
  },
  hashtagsContainer: {
    marginTop: 16,
    gap: 8,
  },
  imageContainer: {
    height: 'auto'
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '4px',
    maxHeight: 'unset',
    maxWidth: 'unset'
  }
}));

// Desktop layout for Community package details
const DesktopLayout: React.FC = () => {
  const { communityPackage } = useContext<CommunityPackageContextData>(CommunityPackageContext);

  const classes = useStyles();

  const product = communityPackage?.products?.length && (communityPackage.products[0] as Product);
  if (!product) return null;

  return (
    <>
      {/* Page bread crumbs */}
      <PageBreadcrumbs link="/community" label="Community" curLabel={'Community listing detail'} />

      <Hidden lgUp>
        <Grid container>
          <AddedTag added={!!communityPackage.addedProductId} />
        </Grid>
      </Hidden>

      {/* Header */}
      <Grid container alignItems="center" justify="space-between" wrap="nowrap" className={classes.header}>
        {/* Community package title */}
        <Grid container wrap="nowrap">
          <HeaderText presetFont="heading--l" lineHeight={50}>
            {communityPackage?.title}
          </HeaderText>
          <Hidden mdDown>
            <AddedTag style={{ marginLeft: 16 }} added={!!communityPackage.addedProductId} />
          </Hidden>
        </Grid>

        <Grid container wrap="nowrap" justify="flex-end" style={{ gap: 8, width: 425 }}>
          {!!communityPackage.addedProductId ? (
            <PackageAddedButtons addedProductId={communityPackage.addedProductId} />
          ) : (
            <AddToShopButton variant="contained" communityPackageId={communityPackage?.id} />
          )}
        </Grid>
      </Grid>

      <CreatorInfo author={communityPackage?.author} className={classes.creatorInfo} />

      {/* Content */}
      <Grid container wrap="nowrap" className={classes.content}>
        <LeftColumn>
          {/* Description */}
          <Text
            presetFont="txt--m"
            dangerouslySetInnerHTML={{
              __html: communityPackage?.description,
            }}
          />

          {communityPackage.allowDownloadAll && <DownloadAllResourcesButton
            packageId={communityPackage?.id}
            className={classes.downloadAllButton}
            variant="outlined"
            fullWidth
          />}

          <QuickAddSection
            className={classes.quickAddSection}
            communityPackageId={communityPackage?.id}
            added={!!communityPackage?.addedProductId}
          />

          {/* Community package resource sections */}
          {communityPackage?.resources.map((resourceSection: ResourceSection) => (
            <ResourceSectionComponent resourceSection={resourceSection} />
          ))}
        </LeftColumn>

        <RightColumn>
          <ProductImage
            alt={product?.title}
            src={product?.imageObj?.downloadUrl}
            backgroundColor="inherit"
            className={classes.image}
            containerClassName={classes.imageContainer}
          />

          {/* HashTags */}
          <Grid container className={classes.hashtagsContainer}>
            {communityPackage?.tags.map(hashtag => (
              <HashTagChip label={hashtag} />
            ))}
          </Grid>
        </RightColumn>
      </Grid>
    </>
  );
};

export default DesktopLayout;
