import { useAppDispatch } from '../../../hooks';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '@castiron/components';
import { Formik, FormikProps } from 'formik';
import { Logo, Button } from '@castiron/components';
import DropDownSelector from '@castiron/components/src/Forms/DropdownSelector';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router';
import CheckboxSelector from '../../Products/EditProduct/FormComponents/CheckboxSelector';
import React, { useEffect, useState } from 'react';
import { apiUpdateShopAction } from '../../../store/reducers/shops';
import { useTracking } from "@castiron/utils";
import AdminForm from '../../AdminForm';

interface FormValues {
  productType?: string;
  businessStage?: string;
  allergens: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  cancelButton: {
    color: theme.branding.gray[600],
  },
  checkbox: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '5px',
    padding: '15px',
    width: '48%',

    '& label': {
      alignItems: 'center',
      marginBottom: 0,
    },
  },
  checkboxes: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '1.5em 0',
  },
  checkboxOther: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '5px',
    width: '48%',

    '& label': {
      marginBottom: 0,
      position: 'relative',
      top: '15px',
      left: '15px',
    },
  },
  checkboxesTitle: {
    fontSize: 18,
    marginTop: '2em',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      fontSize: '36px',
    },
    width: '100%',
    paddingBottom: 32,
  },
  formControl: {
    display: 'flex',
    marginBottom: '15px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  innerContainer: {
    height: '100%',
    margin: '1em 0',
  },
  logo: {
    maxWidth: 144,
    margin: '1em 0',
  },
  otherInput: {
    display: 'block',
    position: 'absolute',
    left: '45px',
    top: '3px',

    '& .MuiFormControl-marginNormal': {
      margin: 0,
    },
  },
  subtitle: {
    color: theme.branding.gray[600],
    fontSize: 16,
  },
  root: {
    display: 'flex',
  },
  pageContainer: {
    flexGrow: 1,
  },
  onboardingContainer: {
    [theme.breakpoints.up('xs')]: {
      margin: 'auto',
      maxWidth: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '1em auto',
      maxWidth: '800px',
    },
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 180px)',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
  },
  button: {},
  buttonLoading: {
    color: theme.palette.grey.A400,
  },
}));

const productTypes = [
  { label: 'Bagels', value: 'bagels' },
  { label: 'Beverages', value: 'beverages' },
  { label: 'Breads', value: 'breads' },
  { label: 'Cakes and Cupcakes', value: 'cakes-and-cupcakes' },
  { label: 'Candy', value: 'candy' },
  { label: 'Catered Meals', value: 'catered-meals' },
  { label: 'Chocolates', value: 'chocolates' },
  { label: 'Coffee & Tea', value: 'coffee-tea' },
  { label: 'Cookies', value: 'cookies' },
  { label: 'Dairy Products', value: 'dairy' },
  { label: 'Desserts', value: 'desserts' },
  { label: 'Eggs & Meat', value: 'eggs-meat' },
  { label: 'Honey', value: 'honey' },
  { label: 'Hot Sauce and Salsas', value: 'hot-sauce-salsas' },
  { label: 'Ice Cream', value: 'ice-cream' },
  { label: 'Jams and Jellies', value: 'jams-and-jellies' },
  { label: 'Juices', value: 'juices' },
  { label: 'Meal Prep', value: 'meal-prep' },
  { label: 'Pasta & Grains', value: 'pasta-grains' },
  { label: 'Personal Chef', value: 'personal-chef' },
  { label: 'Pickled & Fermented Foods', value: 'pickled-foods' },
  { label: 'Pies', value: 'pies' },
  { label: 'Popcorn', value: 'popcorn' },
  { label: 'Prepared Meals', value: 'prepared-meals' },
  { label: 'Produce', value: 'produce' },
  { label: 'Soups', value: 'soups' },
  { label: 'Spices', value: 'spices' },
  { label: 'Syrups', value: 'syrups' },
];

const allergenVals = [
  { label: 'Milk', value: 'milk' },
  { label: 'Eggs', value: 'eggs' },
  { label: 'Fish', value: 'fish' },
  { label: 'Shellfish', value: 'shellfish' },
  { label: 'Tree Nuts', value: 'treeNuts' },
  { label: 'Wheat', value: 'wheat' },
  { label: 'Peanuts', value: 'peanuts' },
  { label: 'Soy Beans', value: 'soyBeans' },
  { label: 'Sesame', value: 'sesame' },
];

const businessStages = [
  { label: "I'm brand new, not selling yet", value: 'brand-new' },
  { label: "I've made sales but do not have an online ordering option", value: 'no-online-ordering' },
  { label: "I'm selling online but not happy with my current site", value: 'selling-online-unhappy' },
  {
    label: "I'm selling online through an existing website and want to see how Castiron compares",
    value: 'compairing',
  },
  { label: "I'm not selling and not planning to, just looking around", value: 'just-looking' },
];

const getProductTypeValue = type => {
  switch (type) {
    case 'bagels':
    case 'desserts':
    case 'pasta-grains':
      return 'breads-baked-goods-desserts';
    case 'candy':
    case 'chocolates':
    case 'coffee-tea':
    case 'dairy':
    case 'eggs-meat':
    case 'honey':
    case 'ice-cream':
    case 'popcorn':
    case 'soups':
    case 'syrups':
      return 'other';
    case 'hot-sauce-salsas':
    case 'produce':
      return 'produce';
    case 'meal-prep':
    case 'personal-chef':
    case 'prepared-meals':
      return 'pre-made-meals-catering-meal-prep';
    default:
      return type;
  }
};

const initialValues: FormValues = {
  productType: 'none',
  businessStage: 'none',
  allergens: [],
};

interface ButtonState {
  text: string;
  className: string;
  disabled: boolean;
}

const CategoryAndAllergens: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const defaultButtonState = { text: 'Next', className: classes.button, disabled: false };
  const [buttonState, setButtonState] = useState<ButtonState>(defaultButtonState);
  const { trackPage } = useTracking();

  const setButtonLoading = () => {
    setButtonState({
      text: 'Saving . . .',
      className: classes.buttonLoading,
      disabled: true,
    });
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    trackPage();
  }, []);

  const onSubmit = async (values, helpers) => {
    setButtonLoading();

    const user = firebase.auth().currentUser;

    await dispatch(
      apiUpdateShopAction({
        id: user.uid,
        props: {
          productTypes: values.productType === 'none' ? ['other'] : [getProductTypeValue(values.productType)],
          businessStage: values.businessStage === 'none' ? undefined : values.businessStage,
          allergens: values.allergens,
        },
      }),
    );

    history.push('/artisan-details');
  };

  return (
    <div className={classes.pageContainer}>
      <Grid className={classes.onboardingContainer} container xs={12}>
        <Formik validateOnMount onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, setFieldValue, values }: FormikProps<FormValues>) => (
            <AdminForm className={classes.form}>
              <Grid container className={classes.innerContainer}>
                <Grid className={classes.header} container>
                  <div className={classes.logo}>
                    <Logo width={144} style={{ marginRight: 19 }} />
                  </div>
                  <Typography className={classes.subtitle} variant="body2">
                    Step 2 of 3
                  </Typography>
                </Grid>
                <Grid className={classes.form} style={{ marginTop: '80px' }} container xs={12}>
                  <Typography className={classes.title} variant="h1" color="initial">
                    Welcome!
                  </Typography>
                  <Typography className={classes.subtitle} variant="body2">
                    You're on your way to having your very own online store. Before your store is ready to launch,
                    answer a few questions that will help us to customize your experience.
                  </Typography>
                  <DropDownSelector
                    options={productTypes}
                    name="productType"
                    label="What type of product is the main focus for your business?"
                    subLabel="This will help us customize your store setup experience and make it even easier."
                    placeholder="Select your product type"
                  />
                  <CheckboxSelector
                    options={allergenVals}
                    name="allergens"
                    label="Which of these common allergens are present in your kitchen?"
                    subLabel="Keep your customers informed as they browse."
                  />
                  <DropDownSelector
                    options={businessStages}
                    name="businessStage"
                    label="What stage is your business in?"
                    placeholder="Select business stage"
                  />
                </Grid>
                <Grid className={classes.buttonsContainer}>
                  <Button
                    variant="outlined"
                    type="button"
                    className={classes.cancelButton}
                    onClick={() => history.push('/artisan-details')}
                  >
                    Skip this step
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleSubmit()}
                    className={buttonState.className}
                    disabled={buttonState.disabled}
                    variant="contained"
                  >
                    {buttonState.text}
                  </Button>
                </Grid>
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default CategoryAndAllergens;
