import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { Banner, Button, Card, Chip, Typography } from '@castiron/components';
import { areSubscriptionFeaturesActive, Subscription } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import { getService } from '../../../firebase';
import { LayoutPageProps } from '../../Layout';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import SubscriptionBanner from '../../SubscriptionBanner';
import PaymentMethodDisplay from '../../SelectPlan/PlanComponents/PaymentMethodDisplay';
import Tooltip from '../../Tooltip';
import Spinner from '../../Spinner';

const getTaxesService = getService('subscriptions', 'getplantax', { version: 2 });
const getBalanceService = getService('stripe','getbalance', { version: 2 });
const uncancelSubscriptionService = getService('subscriptions', 'uncancelsubscription', { version: 2 });

const useStyles = makeStyles((theme: Theme) => ({
  apContainer: {},
  apTitle: {
    fontWeight: 600,
    marginBottom: 8,
  },
  apValue: {},
  ccErrorButton: {
    color: 'inherit',
    padding: 0,
  },
  cancelSubButton: {
    padding: 16,
    '&:hover': {
      backgroundColor: theme.branding.red.light,
      borderColor: theme.branding.red.primary,
    },
  },
  changePaymentButton: {
    [theme.breakpoints.down('xs')]: {
      padding: 11,
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  cpContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    padding: 24,

    '& > .MuiGrid-item': {
      paddingLeft: 0,
    },
  },
  cpChip: {
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0px 7px',
  },
  cpChipCancelled: {
    color: theme.branding.red.primary,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0px 7px',
  },
  cpDescriptor: {
    color: theme.branding.gray[700],
  },
  cpTitle: {
    color: theme.branding.blue.primary,
    fontSize: 18,
    lineHeight: '28px',
  },
  detailsContainer: {
    '& > section': {
      marginBottom: 0,
    },
  },
  moneyBanner: {
    marginTop: 8,
    fontSize: 14,
  },
  subscriptionBanner: {
    marginBottom: 16,
  },
}));

type LocalStarterType = {
  lastPaymentDate?: number;
  nextPaymentDate?: number;
  plan: {
    name: string;
  };
  price: {
    amount: number;
    frequency: string;
  };
  status: string;
};

// Only used if previous subscription plan was canceled
const localStarterSubscription: LocalStarterType = {
  lastPaymentDate: moment().unix(),
  nextPaymentDate: moment()
    .add(1, 'month')
    .unix(),
  plan: {
    name: 'Starter',
  },
  price: {
    amount: 0,
    frequency: 'monthly',
  },
  status: 'canceled',
};

const SubscriptionPlans: React.FC<LayoutPageProps> = props => {
  const { setPageTitle, setBackLocation, setHeaderCTAs } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [activeSubscription, setActiveSubscription] = useState<Subscription | LocalStarterType>(
    localStarterSubscription,
  );
  const [currentPaymentTaxes, setCurrentPaymentTaxes] = useState(0);
  const [isSubmittingResub, setIsSubmittingResub] = useState(false);
  const fullDateFormat = 'MMMM D, YYYY';

  const { account, isShopLoading, subscription, tier, shop, userState } = useAppSelector(state => ({
    account: state.shops.account,
    isShopLoading: state.shops.loading,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
    shop: state.shops.shop,
    userState: state.shops.userState,
  }));

  const planCancelDate = moment.unix(account?.subscription?.nextPaymentDate).format('MMM D');
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const hasCreditCardErrors = subscription?.status === 'payment-failed';

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Plans & Pricing Page';

  const trialDaysLeft = moment.unix(subscription?.trialEndDate).diff(moment(), 'days') + 1;

  const openChangePaymentMethodModal = () => {
    trackEvent('Shop View Change Payment Modal', {
      shopId: shop.id,
      location: 'changePaymentMethod',
      tier: tier,
      currentPlan: subscription?.plan.name,
    });

    dispatch(
      openModal({
        modalType: 'CHANGE_PAYMENT_MODAL',
        modalProps: {
          open: true,
          forcePlanDetails: {
            plan: subscription.plan,
            price: subscription.price,
          },
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle('Plans & Pricing');
    setBackLocation(true);

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('displayPaymentUpdate') === 'true') {
      openChangePaymentMethodModal();
    }

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (areSubscriptionFeaturesActive(subscription)) {
      setActiveSubscription(subscription);

      const getTaxes = async () => {
        const taxes = await getTaxesService({
          address: account?.billingAddress,
          planId: subscription.plan.id,
          priceId: subscription.price.id,
        });

        setCurrentPaymentTaxes(taxes.tax);
        setIsLoading(false);
      };

      if (account?.billingAddress) {
        getTaxes();
      }
    }
    setIsLoading(false);
  }, [subscription, account]);

  useEffect(() => {
    setHeaderCTAs([]);
  }, []);

  useEffect(() => {
    if (account) {
      getBalanceService({}).then(resp => setBalance(Math.abs(resp.balance)));
    }
  }, [account]);

  // Hide Learn more for now
  // useEffect(() => {
  //   const headerCTAs = isMobile
  //     ? [
  //         <EllipsisMenu
  //           options={[
  //             {
  //               display: 'Learn More',
  //               action: () => window.open('https://www.castiron.me/pricing', '_blank'),
  //             },
  //           ]}
  //         />,
  //       ]
  //     : [
  //         <Button variant="outlined" onClick={() => window.open('https://www.castiron.me/pricing', '_blank')}>
  //           Learn More
  //         </Button>,
  //       ];
  //   setHeaderCTAs(headerCTAs);
  //   return () => setHeaderCTAs([]);
  // }, [isMobile]);

  const openChangePlanModal = () => {
    trackEvent('Shop View Upgrade Modal', {
      shopId: shop.id,
      location: 'plansPricingPage',
      tier: tier,
      currentPlan: subscription?.plan.name,
    });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          selectedPlan: {
            plan: subscription?.plan,
            price: subscription?.price,
          },
        },
      }),
    );
  };

  const openCancelPlanModal = () => {
    dispatch(
      openModal({
        modalType: 'CANCEL_PLAN_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  const planChip = {
    inTrial: (
      <Chip colorScheme="yellow" className={classes.cpChip}>
        {trialDaysLeft} Days Remaining
      </Chip>
    ),
    legacyInTrial: (
      <Chip colorScheme="yellow" className={classes.cpChip}>
        {trialDaysLeft} Days Remaining
      </Chip>
    ),
    currentSubscriber: (
      <Chip colorScheme="blue" className={classes.cpChip}>
        My Current Plan
      </Chip>
    ),
    cancellingSubscriber: (
      <Chip colorScheme="quotesLightRed" bold className={classes.cpChipCancelled}>
        Ends on {planCancelDate}
      </Chip>
    ),
  };

  const getCurrentPlanButtonText = () => {
    switch (userState) {
      case 'inTrial':
        if (subscription?.paymentMethod) return 'Change Plan'
      case 'legacyInTrial':
        return 'Upgrade Now';
      case 'currentSubscriber':
        return 'Change Plan';
      case 'cancellingSubscriber':
        return 'Resume Subscription';
      case 'legacyNewSubscriber':
      case 'legacyTrialCompleted':
        return 'See Plans';
      default:
        return 'Change Plan';
    }
  };

  const getCreditedPaymentDate = () => {
    if (subscription?.nextPaymentDate) {
      if (subscription?.price?.frequency === 'yearly') {
        return moment
          .unix(subscription?.nextPaymentDate)
          .add(Math.floor(balance / subscription?.price?.amount), 'y')
          .format('MM/DD/YYYY');
      } else {
        return moment
          .unix(subscription?.nextPaymentDate)
          .add(Math.floor(balance / subscription?.price?.amount), 'M')
          .format('MM/DD/YYYY');
      }
    } else {
      return 'next payment date';
    }
  };

  const getDescriptor = () => {
    switch (userState) {
      case 'inTrial':
      case 'legacyInTrial':
        return 'Free Trial';
      case 'currentSubscriber':
      case 'cancellingSubscriber':
        return (
          Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat('$0.00') +
          ' / ' +
          (activeSubscription?.price?.frequency === 'yearly' ? 'year' : 'month')
        );
      case 'legacyNewSubscriber':
      case 'legacyTrialCompleted':
        return (
          Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat('$0.00') +
          ' / ' +
          (activeSubscription?.price?.frequency === 'yearly' ? 'year' : 'mo.') +
          ' + ' +
          tier.castironTakeRate +
          '%'
        );
      default:
        Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat('$0.00') +
          ' / ' +
          (activeSubscription?.price?.frequency === 'yearly' ? 'year' : 'month');
    }
  };

  const getEmptyState = () => {
    return userState == 'inTrial' || userState == 'legacyInTrial' ? '-' : 'None';
  };

  const resumeSubscription = async () => {
    setIsSubmittingResub(true);
    const formattedBillingDate = moment.unix(account?.subscription?.nextPaymentDate).format('LL');

    const response = await uncancelSubscriptionService({
      nextBillingDate: formattedBillingDate,
    });

    if (response?.success) {
      dispatch(
        openModal({
          modalType: 'RESUME_SUBSCRIPTION_MODAL',
          modalProps: {
            open: true,
            nextBillingDate: formattedBillingDate
          },
        }),
      );
    }

    setIsSubmittingResub(false);
  };

  const CurrentPlanContainer = () => {
    return (
      <>
        {isVariant && (
          <Grid className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        <Grid className={classes.cpContainer} container alignItems="center" justify="space-between">
          <Grid container spacing={1} direction="column" item xs={8}>
            <Grid item>{planChip[userState]}</Grid>
            <Grid item>
              <Typography variant="h6" className={classes.cpTitle}>
                {activeSubscription?.plan?.name || 'Starter'} Plan
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.cpDescriptor}>
                {getDescriptor()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Button
              variant="outlined"
              disabled={isSubmittingResub}
              onClick={() => {
                // trackEvent('SubscriptionPlans', 'Upgrade');
                userState === 'cancellingSubscriber' ? resumeSubscription() : openChangePlanModal();
              }}
            >
              {getCurrentPlanButtonText()}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const PaymentMethodCard = () => {
    const changePaymentButton = (
      <Button variant="outlined" onClick={openChangePaymentMethodModal} className={classes.changePaymentButton}>
        Update
      </Button>
    );

    return (
      <Grid container>
        <Card
          title="Payment Method"
          sideMessage={
            areSubscriptionFeaturesActive(subscription) && subscription?.paymentMethod ? changePaymentButton : null
          }
        >
          {subscription?.paymentMethod ? (
            <Grid container direction="column" spacing={2}>
              {hasCreditCardErrors && (
                <Grid item>
                  <Banner variant="error">
                    <Typography variant="body2">
                      Error processing your subscription. Please update your payment method.
                    </Typography>
                  </Banner>
                </Grid>
              )}
              <Grid item>
                <Box style={{ padding: '12px' }}>
                  <PaymentMethodDisplay
                    brand={subscription?.paymentMethod?.brand}
                    last4={subscription?.paymentMethod?.last4}
                    expirationDate={subscription?.paymentMethod?.expirationDate}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">None</Typography>
          )}
        </Card>
      </Grid>
    );
  };

  const ActivePlanCard = () => {
    return (
      <Grid container className={classes.detailsContainer}>
        <Card title="Subscription Details">
          <Grid container direction="column" spacing={2}>
            <Grid container direction="column" item className={classes.apContainer}>
              <Typography variant="subtitle2" className={classes.apTitle}>
                Active Subscription
              </Typography>
              {areSubscriptionFeaturesActive(subscription) && subscription?.paymentMethod ? (
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    Billed {activeSubscription?.price?.frequency}
                  </Typography>
                  <Typography variant="body1" className={classes.apValue}>
                    {Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat('$0.00')}
                    {'/'}
                    {activeSubscription?.price?.frequency === 'monthly' ? 'month' : 'year'}
                  </Typography>
                </Grid>
              ) : (
                <Typography variant="body1" className={classes.apValue}>
                  {getEmptyState()}
                </Typography>
              )}
            </Grid>
            <Grid container direction="column" item className={classes.apContainer}>
              <Typography variant="subtitle2" className={classes.apTitle}>
                Last Payment
              </Typography>
              {subscription?.paymentMethod && subscription?.lastPaymentDate ? (
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    {moment.unix(subscription?.lastPaymentDate).format(fullDateFormat)}
                  </Typography>
                  <Typography variant="body1" className={classes.apValue}>
                    {Dinero({ amount: subscription?.price?.amount || 0 }).toFormat('$0.00')}
                    {currentPaymentTaxes
                      ? ` + (${Dinero({ amount: currentPaymentTaxes || 0 }).toFormat('$0.00')} tax)`
                      : ''}
                  </Typography>
                </Grid>
              ) : (
                <Typography variant="body1" className={classes.apValue}>
                  {getEmptyState()}
                </Typography>
              )}
            </Grid>
            <Grid container direction="column" item className={classes.apContainer}>
              <Typography variant="subtitle2" className={classes.apTitle}>
                Next Scheduled Payment
              </Typography>
              {subscription?.paymentMethod &&
                areSubscriptionFeaturesActive(subscription) &&
                subscription.status != 'pending-canceled' ? (
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    {moment.unix(subscription?.nextPaymentDate).format(fullDateFormat)}
                  </Typography>
                  <Typography variant="body1" className={classes.apValue}>
                    {Dinero({ amount: activeSubscription?.price.amount }).toFormat('$0.00')}
                    {currentPaymentTaxes
                      ? ` + (${Dinero({ amount: currentPaymentTaxes || 0 }).toFormat('$0.00')} tax)`
                      : ''}
                  </Typography>
                </Grid>
              ) : (
                <Typography variant="body1" className={classes.apValue}>
                  {getEmptyState()}
                </Typography>
              )}
            </Grid>
            {balance !== 0 && userState == 'currentSubscriber' && (
              <Grid container direction="column" item className={classes.apContainer}>
                <Grid container direction="row">
                  <Typography variant="subtitle2" className={classes.apTitle}>
                    Plan Credits
                  </Typography>
                  <Tooltip title="Leftover balance from plan cancellation" style={{ marginBottom: 8 }} />
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    Remaining Balance
                  </Typography>
                  <Typography variant="body1" className={classes.apValue}>
                    {`+ ${Dinero({ amount: balance }).toFormat('$0,0.00')}`}
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Banner variant="money" className={classes.moneyBanner}>
                    Your {Dinero({ amount: balance }).toFormat('$0,0.00')} in plan credit will be applied to future
                    billing cycles until it runs out. Your card won’t be charged again until {getCreditedPaymentDate()}.
                  </Banner>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Helmet>
        <title>Plans & Pricing | Castiron</title>
      </Helmet>
      {isLoading || isShopLoading ? (
        <Spinner show={isLoading || isShopLoading} />
      ) : (
        <Grid container direction="column" spacing={3} className={classes.container}>
          {hasCreditCardErrors && (
            <Grid item>
              <Banner variant="error">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={8} sm={10}>
                    <Typography variant="body2">
                      We were unable to process your latest payment. Please update your payment method to continue
                      enjoying the features of the {subscription?.plan?.name} Plan.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="text" className={classes.ccErrorButton} onClick={openChangePaymentMethodModal}>
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Banner>
            </Grid>
          )}
          <Grid item>
            <CurrentPlanContainer />
          </Grid>
          <Grid item>
            <ActivePlanCard />
          </Grid>
          <Grid item style={{ paddingBottom: 0 }}>
            <PaymentMethodCard />
          </Grid>
          <Grid item style={{ paddingTop: 0 }}>
            {(subscription?.status === "active" || subscription?.status === "trial") && subscription?.paymentMethod && (
              <Button
                variant="outlined"
                className={classes.cancelSubButton}
                onClick={openCancelPlanModal}
                disableRipple
                fullWidth={isMobile}
              >
                <Typography variant="button" style={{ color: theme.branding.red.primary }}>
                  Cancel Subscription
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionPlans;
