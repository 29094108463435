import React from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type ButtonProps = Override<
  MUIButtonProps,
  {
    variant?: 'text' | 'outlined' | 'contained';
  }
> & {
  loading?: boolean,
  loadingClassName?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '16px',
  },
  borderOutlined: {
    borderColor: theme.branding.gray[400],
  },
}));

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, color, variant, className, loading, loadingClassName, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <MUIButton
      color={color || 'primary'}
      variant={variant || 'text'}
      className={`${classes.button}
      ${variant === 'outlined' && classes.borderOutlined}
      ${className}`}
      {...restOfProps}
      startIcon={loading && <CircularProgress classes={{ root: loadingClassName }} size={16} />}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
