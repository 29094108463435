import React, { useState } from 'react';
import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import Spinner from '../../Spinner';

type Props = {
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onDelete?: () => void;
  onEdit?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  }
}));

const QuoteCustomerActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, onDelete, onEdit } = props;
  const classes = useStyles();

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <>
      <Spinner show={showSpinner} size="fullscreen" />
      <ButtonBase focusRipple className={classes.buttonBase} onClick={(e) => { onEdit(); onClose(e); }}>
        <Typography className={classes.menuLabel}>Edit customer info</Typography>
      </ButtonBase>
      <ButtonBase focusRipple className={classes.buttonBase} onClick={(e) => { onDelete(); onClose(e); }}>
        <Typography className={classes.menuLabel}>Remove customer from quote</Typography>
      </ButtonBase>
    </>
  );
};

export default QuoteCustomerActionsMenu;
