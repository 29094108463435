import React, { useMemo } from 'react';
import { Grid, makeStyles, Theme, Typography, Button as MUIButton, Link } from '@material-ui/core';
import ButtonGroup from '../../ButtonGroup';
import { Field, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { Input, Button, Chip } from '@castiron/components';
import { FormValues } from '.';
import { defaultMsg } from '../EditUpdateEmail';
import { Coupon } from '@castiron/domain/src';
import PreviewButton from '../PreviewButton';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginBottom: '16px',
  },
  cancelButton: {
    marginBottom: '16px',
    marginTop: '40px',
  },
  sendContainer: {
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: '#F8F8F8',
  },
  send: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  sendLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  decorator: {
    color: '#FC6E26',
  },
  sublabel: {
    color: theme.branding.gray[600],
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  defaultMsg: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.branding.gray[800],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  editCoupon: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#005FFF',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

type Props = {
  formikProps: FormikProps<FormValues>;
  coupon: Coupon;
  marketingSendId?: string;
};

const MobileForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { formikProps, coupon, marketingSendId } = props;

  const { values, setFieldValue, errors, touched, isSubmitting } = formikProps;

  const isValid = useMemo(() => !errors.status || !errors.artisanMessage, [errors]);

  return (
    <Form>
      <Grid container direction="column">
        <Chip colorScheme={values.status === 'active' ? 'success' : 'error'} uppercase bold className={classes.chip}>
          {values.status === 'active' ? 'Active' : 'Inactive'}
        </Chip>
        <Typography variant="h3">Shop update</Typography>
        {marketingSendId && <PreviewButton marketingSendId={marketingSendId} />}
        <Grid container direction="column" className={classes.sendContainer} style={{ margin: '32px 0px 8px 0px' }}>
          <Typography className={classes.sendLabel}>Next send:</Typography>
          <Typography className={classes.send}>Ongoing</Typography>
        </Grid>
        <Grid container direction="column" className={classes.sendContainer}>
          <Grid container justify="space-between">
            <Typography className={classes.sendLabel}>Subscriber coupon details:</Typography>
            {coupon && (
              <Link className={classes.editCoupon} onClick={() => history.push(`/coupons/edit/${coupon.id}`)}>
                Edit coupon amount
              </Link>
            )}
          </Grid>

          {coupon ? (
            <>
              <Grid container justify="space-between">
                <Typography className={classes.send}>{coupon.code}</Typography>
              </Grid>
              <Grid container justify="space-between">
                <Typography className={classes.send}>
                  {coupon.discount.type === 'amount'
                    ? `$${coupon.discount.value} off`
                    : `${coupon.discount.value}% off`}
                </Typography>
                {coupon.maximumPerCustomer && (
                  <Typography className={classes.send}>{coupon.maximumPerCustomer} per customer</Typography>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container justify="space-between">
                <Typography className={classes.send} style={{ marginBottom: '8px' }}>
                  THANKYOUSAVE10
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <Typography className={classes.send} style={{ margin: '0px 16px' }}>
                  10% off
                </Typography>
                <Typography className={classes.send}>1 per customer</Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Typography
          className={classes.label}
          style={{
            marginBottom: '-14px',
            marginTop: '32px',
          }}
        >
          Send status<span className={classes.decorator}> *</span>
        </Typography>
        <Field
          required
          as={ButtonGroup}
          error={touched.status && errors.status}
          name="status"
          value={values.status}
          onChange={(value): void => {
            switch (value) {
              case 'active':
                setFieldValue('status', 'active');
                break;
              case 'inactive':
                setFieldValue('status', 'inactive');
                break;
            }
          }}
          buttons={['active', 'inactive']}
        />
        <Typography className={classes.sublabel}>
          Setting your send status to inactive will turn off the monthly shop update autosend.
        </Typography>
        <Typography
          className={classes.label}
          style={{
            marginTop: '32px',
            marginBottom: '-8px',
          }}
        >
          Personal sign off<span className={classes.decorator}> *</span>
        </Typography>
        <Input
          error={touched.artisanMessage && errors.artisanMessage}
          name="artisanMessage"
          multiline
          rows={8}
          placeholder={defaultMsg}
        />
        <Grid container justify="flex-end">
          <Link className={classes.defaultMsg} onClick={() => setFieldValue('artisanMessage', defaultMsg)}>
            Reset to default mesage
          </Link>
        </Grid>
        <Button
          className={classes.cancelButton}
          onClick={() => history.push('/marketing')}
          variant="outlined"
          fullWidth
        >
          Cancel
        </Button>
        <Button disabled={!isValid || isSubmitting} fullWidth type="submit" variant="contained">
          Save
        </Button>
      </Grid>
    </Form>
  );
};

export default MobileForm;
