import React, { ReactNode } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '../Typography';
import { Button } from '../Button';

export interface CardHeaderProps {
  title: string | ReactNode;
  sideMessage?: ReactNode;
  showEdit?: boolean;
  onEditClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  edit: {
    textAlign: 'end',
  },
  editButton: {
    color: theme.palette.primary.light,
  },
  header: {
    padding: '24px',
  },
}));

const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
  const { title, sideMessage, showEdit, onEditClick } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className={classes.header} direction="row" alignItems="center">
      <Grid item xs={showEdit ? 10 : 12}>
        <Grid container wrap={isMobile ? 'wrap' : 'nowrap'} justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {
            sideMessage &&
            <Grid item>
              {sideMessage}
            </Grid>
          }
        </Grid>
      </Grid>
      {showEdit && (
        <Grid item xs={2} className={classes.edit}>
          <Button className={classes.editButton} variant="text" onClick={onEditClick}>
            Edit
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CardHeader;