export function removeEmpty<T>(obj: T): Partial<T> {
  Object.keys(obj).forEach(function(key) {
    // Get this value and its type
    const value = obj[key];
    const type = typeof value;
    if (Array.isArray(value)) {
      obj[key] = value
        .map(item => {
          const type = typeof item;
          if (type === 'object' && item) {
            return removeEmpty(item);
          } else return item;
        })
        .filter(item => (typeof item === 'object' ? !!Object.keys(item).length : true));
    } else if (type === 'object' && value) {
      // Recurse...
      removeEmpty(value);
      // ...and remove if now "empty" (NOTE: insert your definition of "empty" here)
      if (!Object.keys(value).length) {
        delete obj[key];
      }
    } else if (type === 'undefined' || value === null || Number.isNaN(value)) {
      // Undefined, remove it
      delete obj[key];
    }
  });

  return obj;
}
