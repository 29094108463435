import React, { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import obModalDesktopScreen2 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen2.png';
import obModalDesktopScreen3 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen3.png';
import obModalDesktopScreen4 from '../../assets/img/onboardingModals/welcomeModal/DesktopScreen4.png';
import obModalMobileScreen2 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen2.png';
import obModalMobileScreen3 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen3.png';
import obModalMobileScreen4 from '../../assets/img/onboardingModals/welcomeModal/MobileScreen4.png';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Layout/AvatarMenu';
import ViewShopButton from '../Layout/Header/ViewShopButton';
import PrelaunchSetup from './PrelaunchSetup';

const useStyles = makeStyles((theme: Theme) => ({
  positionAdjustment: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '88px',
    },
  },
  welcomeModalMediaAdjustments: {
    position: 'absolute',
    bottom: '88px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 22px',
    },
    [theme.breakpoints.down('xs')]: {
      right: 0, // Needed for Safari to position the image correctly
    },
  },
}));

const Home: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getIframeSizes = () => {
    if (isMobile) {
      return {
        width: '343px',
        height: '193px',
      };
    } else if (!isMobile) {
      return {
        width: '504px',
        height: '283px',
      };
    }
  };

  useEffect(() => {
    setPageTitle('Home');
    setFooterCTAs([]);
    window.Beacon('destroy');

    return () => {
      setPageTitle('');
      setFooterCTAs([]);
      window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_KEY);
      window.Beacon('config', { hideFABOnMobile: true });
    };
  }, []);

  useEffect(() => {
    setHeaderCTAs([<ViewShopButton />, isMobile && <AvatarMenu />]);

    return () => {
      setHeaderCTAs([]);
    };
  }, [isMobile]);

  useEffect(() => {
    const welcomeModalContent = [
      {
        header: `Welcome to Castiron! 🎉`,
        body: 'If you have a minute, we have a few pointers to help you start your journey!',
        media: (
          <iframe
            width={getIframeSizes().width}
            height={getIframeSizes().height}
            src="https://www.youtube.com/embed/o3k3V3QkdY4?rel=0"
            title="Welcome to Castiron"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ),
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen2} alt="Welcome Screen 2" />
        ) : (
          <img src={obModalDesktopScreen2} alt="Welcome Screen 2" />
        ),
        mediaClassName: classes.welcomeModalMediaAdjustments,
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen3} alt="Welcome Screen 3" />
        ) : (
          <img src={obModalDesktopScreen3} alt="Welcome Screen 3" />
        ),
        mediaClassName: classes.welcomeModalMediaAdjustments,
      },
      {
        media: isMobile ? (
          <img src={obModalMobileScreen4} alt="Welcome Screen 4" />
        ) : (
          <img src={obModalDesktopScreen4} alt="Welcome Screen 4" />
        ),
        mediaClassName: classes.positionAdjustment,
      },
    ];

    if (shop?.status === 'prelaunch' && account) {
      if (!account.onboardingModals || (account.onboardingModals && !account.onboardingModals?.welcomeModalShown)) {
        dispatch(
          openModal({
            modalType: 'ONBOARDING_MODAL',
            modalProps: {
              show: true,
              stepContent: welcomeModalContent,
              onboardingModalType: 'welcomeModal',
            },
          }),
        );
      }
    }
  }, [account]);

  /* eventually this will be a set of tabs, for now just the one content pane */
  return <PrelaunchSetup />;
};

export default Home;
