import _ from 'lodash';
import { Category, FulfillmentOption } from '../shop';
import { SelectedInputFieldValue } from '../inputfield/model';
import { BaseProduct, CustomProduct } from '../product';
import { RequestedFulfillment } from './model';

export type LineItemType = 'standard' | 'custom';
export type OrderType = LineItemType;
export type OrderStage = 'quote' | 'order';

export interface SubLineItem {
  id: string;
  title: string;
  price: number;
  quantity: number;
  total?: number;
  notes?: string;
}

export interface LineItem {
  id: string;
  title: string;
  description: string;
  category?: Category;
  price?: number;
  quantity: number;
  selections?: SelectedInputFieldValue[];
  subLineItems?: SubLineItem[];
  subtotal?: number;
  subLineItemTotal?: number;
  total?: number;
  type: LineItemType;
  policies?: string;
  communityPackageId?: string;
  notes?: string;
}

export interface Order {
  orderNumber?: string;
  type: OrderType;
  stage: OrderStage;
  items: LineItem[];
  requestedFulfillment?: RequestedFulfillment;
  fulfillmentOption?: FulfillmentOption;
  orderTotal?: number;
  origination?: string;
  paymentSentDuePeriod?: '24hours' | '7days' | 'none';
  paymentDueDate?: number;
}

export interface OrderSearchResult {
  id: string;
  orderNumber: string;
  type: OrderType;
  stage: OrderStage;
  customerName: string;
  status: string;
  frontendStatus: string;
  transactionStatus: string;
  fulfillmentOption?: {
    id?: string;
    type?: string;
    displayName?: string;
    date?: number;
  };
  createdAt: number;
  numItems: number;
  total?: number;
}

/* finds the product matching the id on the line item and enriches with the product information, returns input line item if product is not found */
export const enrichLineItemWithCustomProductInformation = (lineItem: LineItem, products: BaseProduct[]): LineItem => {
  const product = products.find(prd => prd.id === lineItem.id && prd.type === 'custom');
  const customProduct = product ? (product as CustomProduct) : undefined;
  return customProduct
    ? {
        ...lineItem,
        title: customProduct.title,
        description: customProduct.description,
        category: customProduct.category,
        policies: customProduct.policies,
        communityPackageId: customProduct.communityPackageId,
      }
    : lineItem;
};

const updateSubLineItemTotals = (sumSubLineItems: SubLineItem[]) => {
  if (!sumSubLineItems) return undefined;

  return sumSubLineItems.map(i => ({
    ...i,
    total: i.price * i.quantity,
  }));
};

const sumSubLineItems = (subLineItems: SubLineItem[]) => {
  if (!subLineItems) return 0;
  return _.reduce(updateSubLineItemTotals(subLineItems), (sum, i) => sum + i.total, 0);
};

const sumSelections = (selections: SelectedInputFieldValue[]) => {
  if (!selections) return 0;
  return _.reduce(
    selections,
    (sum, sel) => {
      const optionTotal = _.reduce(sel.selectedValues, (osum, o) => osum + o.cost || 0, 0);
      return optionTotal + sum;
    },
    0,
  );
};

/**
 * See the comments on calculateSubtotal in cart/index.ts for why this calculation seems wrong, but isn't.
 *
 * @param order
 */
export const updateOrderTotals = (order: Order): Order => {
  const updatedItems = order?.items?.map(item => {
    const updatedSubLineItems = updateSubLineItemTotals(item.subLineItems);
    const subLineItemTotal = sumSubLineItems(updatedSubLineItems);
    const productTotal = (item.price + sumSelections(item.selections)) * item.quantity;
    return {
      ...item,
      subLineItems: updatedSubLineItems,
      subLineItemTotal,
      subtotal: productTotal,
      total: subLineItemTotal + productTotal,
    };
  });
  return {
    ...order,
    items: updatedItems,
    orderTotal: _.reduce(updatedItems || [], (sum, i) => sum + i.total, 0),
  };
};

/* returns an array of numbers that will represent either [start, end] or [date] depending on if we have a range */
export const determineOrderFulfillmentDateTime = (order: Order): number[] => {
  const fo = order?.fulfillmentOption;
  if (!fo) {
    return [];
  }
  /* orders should only ever have 1 date array in their schedule as of today */
  return (fo?.schedule?.type === 'fixed' && fo?.schedule.dates?.length > 0 && [fo.schedule.dates[0].startTime, fo.schedule.dates[0].endTime])
    || (fo?.date && [fo.date])
    || [];
};