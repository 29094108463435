import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const ScrollToError: React.FC = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    const yOffset = -120;
    const y = el?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }, [submitting]);
  return null;
};

export default ScrollToError;
