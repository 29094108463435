import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getService } from "../../firebase";

const getStripeBalanceService = getService('stripe','getstripebalancev2', { version: 2 });

export interface BalanceState {
  amount?: number;
  loading: boolean;
  error: string;
}

const initialState: BalanceState = {
  loading: false,
  error: '',
};

const getStripeBalance = async () => {
  const response = await getStripeBalanceService({});
  console.debug('getStripeBalance Response: ', response);
  return response.amount;
};

export const getStripeBalanceAction = createAsyncThunk('balance/getStripeBalance', getStripeBalance);

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStripeBalanceAction.pending, state => {
        state.loading = true;
      })
      .addCase(getStripeBalanceAction.fulfilled, (state, action) => {
        state.amount = action.payload;
        state.loading = false;
      });
  },
});

export default balanceSlice.reducer;
