import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { AddressInput, Card, Checkbox, TextAreaInput, TextInput } from '@castiron/components';
import { FulfillmentOption } from '@castiron/domain';
import DateTimeOptions from './DateTimeOptions';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: {
    paddingTop: 6,

    '& label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '& label span': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      '& label': {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
    },
  },
}));

const PickupDetails: React.FC = () => {
  const formik = useFormikContext<FulfillmentOption>();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <DateTimeOptions fulfillmentType='pick up' />
      </Grid>
      <Grid item>
        <Card title="Pickup Details">
          <Grid container direction="column" spacing={3}>
            {formik.values.postalCode && (
              <Grid item>
                <TextInput
                  label='Neighborhood, Borough, or Zip Code'
                  name='postalCode'
                  required
                  error={formik.touched.postalCode && formik.errors.postalCode}
                />
              </Grid>
            )}
            <Grid item>
              <TextAreaInput
                label="Details"
                secondaryLabel="Provide your customers with the info they need to make an informed selection at checkout."
                required
                name="description"
                rowCount={5}
                error={formik.touched.description && formik.errors.description}
              />
            </Grid>
            {formik.values.schedule && formik.values.schedule.type != 'flexible' && (
              <Grid item className={classes.checkboxContainer}>
                <Checkbox
                  label="Send a reminder to your customers 24 hours before pickup time."
                  checked={formik.values.sendPickupReminderEmail}
                  onChange={event => {
                    event.target.checked
                      ? formik.setFieldValue('sendPickupReminderEmail', event.target.checked)
                      : formik.setFieldValue('sendPickupReminderEmail', false);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item>
        <Card title="Pickup Location">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <AddressInput
                label="Pickup Address"
                required
                addressFields={{
                  address: 'address.fullAddress',
                  addressLine1: 'address.addressLine1',
                  city: 'address.city',
                  region: 'address.region',
                  regionName: 'address.regionName',
                  postalCode: 'address.postalCode',
                  country: 'address.country',
                }}
                error={formik.touched.address?.fullAddress && formik.errors.address?.fullAddress}
              />
            </Grid>
            <Grid item>
              <TextInput label="Apt/Ste/Floor (Optional)" name="address.addressLine2" />
            </Grid>
            <Grid item>
              <TextAreaInput
                label={
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="subtitle2">Pickup Notes (Optional)</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        Let your customers know anything that will help them pick up their items.
                      </Typography>
                    </Grid>
                  </Grid>
                }
                name="afterPurchaseDetails"
                rowCount={5}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PickupDetails;
