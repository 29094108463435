import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import PencilIcon from '../../assets/img/pencil.svg';
import DuplicateIcon from '../../assets/img/duplicate.svg';
import TrashIcon from '../../assets/img/trash.svg';
import LinkIcon from '@material-ui/icons/Link';
import { SvgIcon } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { useHistory } from 'react-router';
import { deleteProductAction, duplicateProductAction } from '../../store/reducers/products';
import { useTracking } from '@castiron/utils';
import { BaseProduct } from '@castiron/domain';

type Props = {
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  product: BaseProduct;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 36,
    width: 'auto',
  },
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  delete: {
    color: theme.palette.error.main,
  },
  copiedSuccess: {
    width: '100%',
    height: 36,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    color: theme.branding.gray[700],
    padding: '5px 10px',
  },
  copyIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    width: 36,
  },
  copyIcon: {
    height: 24,
    width: 24,
  },
}));

const ProductActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, product } = props;
  const { id, title } = product;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const [copySuccess, setCopySuccess] = useState(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const handleDuplicate = useCallback(() => {
    dispatch(duplicateProductAction(id)).then(res => {
      trackEvent('Product Duplicated', { product: { id: id } });
      history.push(`/products/edit/${(res.payload as BaseProduct).id}`);
    });
  }, []);

  const onDelete = useCallback(
    id => async (): Promise<void> => {
      dispatch(deleteProductAction(id)).then(res => {
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: false,
              content: (
                <>
                  Product <strong>{title}</strong> was deleted.
                </>
              ),
            },
          }),
        );

        trackEvent('Product Deleted', { product: { id: id } });
      });
    },
    [],
  );

  const onDeleteClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    //technically not deleted unless they respond to modal
    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          onDelete: onDelete(id),
        },
      }),
    );
    onClose(event);
  }, []);

  const onEditClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    history.push(`/products/edit/${id}`);
    onClose(event);
  }, []);

  const handleCopy = (event: React.MouseEvent<HTMLElement>) => {
    setCopySuccess(true);
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}/product/${id}`);
    trackEvent('Product Link Copied', { product: { id: id } });
    setTimeout(() => {
      onClose(event);
    }, 1200);

    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  return (
    <>
      <ButtonBase disabled={copySuccess} onClick={onEditClick} focusRipple className={classes.buttonBase}>
        <SvgIcon className={classes.icon}>
          <PencilIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Edit</Typography>
      </ButtonBase>
      <ButtonBase disabled={copySuccess} focusRipple className={classes.buttonBase} onClick={handleDuplicate}>
        <SvgIcon className={classes.icon}>
          <DuplicateIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Duplicate</Typography>
      </ButtonBase>
      {copySuccess ? (
        <div className={classes.copiedSuccess}>Link copied!</div>
      ) : (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={handleCopy}>
          <div className={classes.copyIconContainer}>
            <LinkIcon className={classes.copyIcon} />
          </div>
          <Typography className={classes.menuLabel}>Copy link</Typography>
        </ButtonBase>
      )}
      <ButtonBase disabled={copySuccess} focusRipple onClick={onDeleteClick} className={classes.buttonBase}>
        <SvgIcon className={`${classes.icon} ${classes.delete}`}>
          <TrashIcon />
        </SvgIcon>
        <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
      </ButtonBase>
    </>
  );
};

export default ProductActionsMenu;
