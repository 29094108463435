import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from '@castiron/components';
import React from 'react';
import ModalWrapper from '../RootModal/ModalWrapper';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Input, Button } from '@castiron/components';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import Close from '@material-ui/icons/Close';
import { getCurrentUser } from '@castiron/castiron-firebase';
import AdminForm from '../AdminForm';

export type Props = {
  show: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '25px 48px 39px 39px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '44px 17px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
  },
  linkContainer: {
    marginBottom: 47,
  },
  link: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
  },
  actions: {
    marginTop: 24,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 0,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
    },
  },
}));

const ResetPasswordModal: React.FC<Props> = (props: Props) => {
  const { show } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    dispatch(closeModal());
  };

  const passwordSchema = yup.object().shape({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required('Password is required'),
    newPasswordConfirm: yup
      .string()
      .required('Password Confirmation is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const onSubmit = async (values, { setFieldError }) => {
    try {
      const user = await getCurrentUser();
      await user.updatePassword(values.newPassword);
      handleClose();
    } catch (err) {
      console.log('Error resetting password: ', err);
      setFieldError('general', err.message);
    }
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <Close />
      </IconButton>
      <DialogTitle>
        <Typography className={classes.title}>Reset password</Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          validationSchema={passwordSchema}
          onSubmit={onSubmit}
          initialValues={{
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
        >
          {() => (
            <AdminForm>
              <Input type="password" label="Current password" name="oldPassword" />
              <div className={classes.linkContainer}>
                {/* <Link className={classes.link} role="button" tabIndex={0} id="forgot-password">
                  Forgot password?
                </Link> */}
              </div>
              <Input type="password" label="New password" name="newPassword" />
              <Input type="password" label="Confirm" name="newPasswordConfirm" />
              <DialogActions className={classes.actions}>
                <Button fullWidth={isMobile} className={classes.button} type="submit" variant="contained">
                  Save
                </Button>
                <Button fullWidth={isMobile} className={classes.button} onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
              </DialogActions>
            </AdminForm>
          )}
        </Formik>
      </DialogContent>
    </ModalWrapper>
  );
};

export default ResetPasswordModal;
