import firebase from 'firebase/compat/app';
import mime from 'mime-types';

async function upload(file, metadata, options, callbacks, context) {
  return new Promise(function (resolve, reject) {
    // const callbacks = {
    //     updateProgress,
    //     paused,
    //     running,
    //     error,
    //     success
    // };
    // const options = {
    //     folder: '',
    //     filename: '',
    //     isRoot: false,
    // }
    // const metadata = {
    //     contentType: 'image/jpeg',
    //     shopId: '',
    //     originalFilename: file.name,
    // }

    console.debug('inside upload: ', file);
    console.debug('callbacks: , ', callbacks);

    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = firebase.storage();

    // Create a storage reference from our storage service
    const storageRef = storage.ref();

    const folderName = `${options && options.folder ? options.folder : metadata.shopId}/`;
    const folder = options && options.isRoot ? '' : folderName;

    console.debug(`File Type: [${file.type}]`);
    const ext = mime.extension(file.type);
    console.debug(`File Extension: [${ext}]`);

    const filename = `${options && options.filename ? options.filename : metadata.originalFilename}.${mime.extension(
      file.type,
    )}`;
    console.debug(`File Name: [${filename}]`)
    const contentType = metadata && metadata.contentType ? metadata.contentType : mime.contentType(file.name);

    // Upload file and metadata to the object 'images/mountains.jpg'
    const uploadTask = storageRef.child(folder + filename).put(file, {
      contentType,
      customMetadata: metadata,
    });

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      snapshot => handleSnapshot(snapshot, callbacks),
      error => {
        handleError(error, callbacks, metadata);
        reject();
      },
      () => {
        handleSuccess(uploadTask, callbacks, metadata, options, context);
        resolve();
      },
    );
  });
}

function handleSnapshot(snapshot, callbacks) {
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  if (callbacks && callbacks.updateProgress) callbacks.updateProgress(progress);
  console.debug(`Upload is ${progress}% done`);

  switch (snapshot.state) {
    case firebase.storage.TaskState.PAUSED: // or 'paused'
      if (callbacks && callbacks.paused) callbacks.paused();
      console.debug('Upload is paused');
      break;
    case firebase.storage.TaskState.RUNNING: // or 'running'
      if (callbacks && callbacks.running) callbacks.running();
      console.debug('Upload is running');
      break;
  }
}

function handleError(error, callbacks, metadata) {
  console.error('Error uploading asset: ', error);
  console.error('Server Resp: ', error.serverResponse);
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  if (callbacks && callbacks.error) callbacks.error(error, callbacks, metadata);
  switch (error.code) {
    case 'storage/unauthorized':
      // User doesn't have permission to access the object
      break;
    case 'storage/canceled':
      // User canceled the upload
      break;

    // ...

    case 'storage/unknown':
      // Unknown error occurred, inspect error.serverResponse
      break;
  }
}

function handleSuccess(uploadTask, callbacks, metadata, options, context) {
  // Upload completed successfully, now we can get the download URL
  uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
    console.debug('File available at', downloadURL);
    if (callbacks && callbacks.success) callbacks.success(downloadURL, metadata, options, context);
  });
}

export default upload;
