import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import {
  HeaderText,
  Link,
  Text,
  ImagesSolidIcon,
  GiftSolidIcon,
  ToolsSolidIcon,
  StarSolidIcon,
} from '@castiron/components';
import { IconTypes, PackageResource, ResourceSection } from '@castiron/domain';

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    marginTop: 40,
  },
  sectionHeader: {
    marginBottom: 9,
  },
  sectionHeaderIcon: {
    color: theme.branding.gray[600],
    transform: 'scale(.8)',
    marginRight: 8,
  },
  sectionTitle: {
    marginRight: 21,
  },
  resourcesContainer: {
    marginTop: 16,
  },
  bullet: {
    paddingLeft: 10,
  },
  resource: {
    marginBottom: 5,
  },
  dashes: {
    borderBottom: `1px dashed ${theme.branding.gray[600]}`,
    flex: 1,
    height: 12,
    margin: '0px 8px',
  },
}));

type Props = {
  resourceSection: ResourceSection;
};

// Resource section
const ResourceSectionComponent: React.FC<Props> = (props: Props) => {
  const { resourceSection } = props;

  const classes = useStyles();

  const selectIcon = (iconType: IconTypes) => {
    switch (iconType) {
      case 'gift':
        return <GiftSolidIcon className={classes.sectionHeaderIcon} />;
      case 'image':
        return <ImagesSolidIcon className={classes.sectionHeaderIcon} />;
      case 'tools':
        return <ToolsSolidIcon className={classes.sectionHeaderIcon} />;
      case 'star':
        return <StarSolidIcon className={classes.sectionHeaderIcon} />;
      default:
        return <StarSolidIcon className={classes.sectionHeaderIcon} />;
    }
  };

  return (
    <Grid className={classes.sectionContainer}>
      {/* Header */}
      <Grid container alignItems="center" wrap="nowrap" className={classes.sectionHeader}>
        {/* Section icon */}
        {selectIcon(resourceSection.icon)}

        {/* Section name */}
        <HeaderText presetFont="heading--xs" lineHeight={24} className={classes.sectionTitle}>
          {resourceSection.name}:
        </HeaderText>
      </Grid>

      {/* Bulleted list of resources */}
      <Grid className={classes.resourcesContainer}>
        {resourceSection.resources?.map((resource: PackageResource) => (
          <Grid container wrap="nowrap" className={classes.resource}>
            {/* Bullet */}
            <li className={classes.bullet} />

            {/* Resource name */}
            <Grid container wrap="nowrap" alignItems="center" item>
              <Text presetFont="txt--s">{resource.name}</Text>
              <Grid className={classes.dashes} />
            </Grid>

            {/* Resource open link */}
            <Text presetFont="txt--s">
              <Link underline href={resource.url} target="_blank">
                Open
              </Link>
            </Text>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ResourceSectionComponent;
