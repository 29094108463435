import React, { useEffect } from 'react';
import Input from '../Input';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';

type Props = {
  isExpanded?: boolean;
  label?: string;
  required?: boolean;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  autoFocus?: boolean;
  useLabel?: boolean;
  fulfillmentType?: string;
  addressFields?: {
    address: string;
    addressLine1: string;
    city: string;
    region: string;
    regionName: string;
    postalCode: string;
    country: string;
  };
  disabled?: boolean;
  name?: string;
  onChange?: (e: any) => void;
  regionsOnly?: boolean;
};

declare global {
  interface Window {
    google: any;
  }
}

/* deprecated: use Inputs/AddressInput instead */
const AddressAutoComplete: React.FC<Props> = (props: Props) => {
  const { setFieldValue, values }: any = useFormikContext();
  const {
    label,
    required,
    autoFocus,
    useLabel,
    fulfillmentType,
    addressFields,
    disabled,
    name,
    onChange,
    regionsOnly = false,
  } = props;

  const fieldMappings = addressFields || {
    address: 'address',
    addressLine1: 'addressOne',
    city: 'city',
    region: 'state',
    regionName: 'stateName',
    postalCode: 'postalCode',
    country: 'country',
  };

  // @ts-ignore
  let autocompleteObj;

  const onPlaceChanged = (): void => {
    //if we need to break things up for the DB, if we're fine with it as is....
    //https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    // @ts-ignore
    let place = autocompleteObj.getPlace();

    setFieldValue(fieldMappings.address, place.formatted_address);

    let address = '';
    let city = '';
    let state = '';
    let stateName = '';
    let postalCode = '';
    let country = '';

    for (const component of place.address_components as typeof window.google.maps.GeocoderAddressComponent) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          address = `${component.long_name} ${address}`;
          break;
        }
        case 'route': {
          address += component.short_name;
          break;
        }
        // City can come through in a few different component types
        // Assigned in order of importance
        case 'locality':
        case 'sublocality':
        case 'sublocality_level_1':
        case 'administrative_area_level_3':
        case 'sublocality_level_2':
        case 'sublocality_level_3':
        case 'sublocality_level_4':
        case 'sublocality_level_5':
        case 'administrative_area_level_4':
        case 'administrative_area_level_5':
        case 'neighborhood':
        case 'colloquial_area': {
          if (!city)
            // Only assign once
            city = component.long_name;
          break;
        }
        case 'administrative_area_level_1': {
          state = component.short_name;
          stateName = component.long_name;
          break;
        }
        case 'postal_code': {
          postalCode = `${component.long_name}`;
          break;
        }
        case 'country': {
          country = component.short_name;
          break;
        }
        // case 'postal_code_suffix': {
        //   postcode = `${postcode}-${component.long_name}`;
        //   break;
        // }
      }
    }

    setFieldValue(fieldMappings.addressLine1, address);
    setFieldValue(fieldMappings.city, city);
    setFieldValue(fieldMappings.region, state);
    setFieldValue(fieldMappings.regionName, stateName);
    setFieldValue(fieldMappings.postalCode, postalCode);
    setFieldValue(fieldMappings.country, country);
  };

  useEffect(() => {
    if (props.isExpanded) {
      let autocompleteEl = document.getElementById('inputAutocomplete');
      if (autocompleteEl) {
        autocompleteObj = new window.google.maps.places.Autocomplete(autocompleteEl, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components', 'geometry', 'formatted_address'],
          types: regionsOnly ? ['(regions)'] : ['address'],
        });

        autocompleteObj.addListener('place_changed', onPlaceChanged);
        autocompleteEl.onkeydown = event => {
          if (event.code === 'Backspace' || event.code === 'Delete') {
            setFieldValue(fieldMappings.addressLine1, null);
            setFieldValue(fieldMappings.city, null);
            setFieldValue(fieldMappings.region, null);
            setFieldValue(fieldMappings.regionName, null);
            setFieldValue(fieldMappings.postalCode, null);
            setFieldValue(fieldMappings.country, null);
          }
        };
      }
    }
  }, [props.isExpanded]);

  const type = fulfillmentType ? fulfillmentType : values.fulfillmentType === 'shipping' ? 'shipping' : 'delivery';
  const labelValue = label ? label : useLabel ? 'Address' : undefined;

  return (
    <Input
      id="inputAutocomplete"
      error={props.error}
      name={name || fieldMappings.address}
      value={_.get(values, fieldMappings.address)}
      fullWidth
      label={labelValue}
      required={required}
      placeholder={regionsOnly ? 'Enter Zip Code' : `Enter ${type} address`}
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={onChange || null}
    />
  );
};

export default AddressAutoComplete;
