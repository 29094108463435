import React from "react";
import { FeatureName } from "@castiron/domain";
import { useFeatures } from "@castiron/components";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";

interface Props extends RouteProps {
  feature: FeatureName;
  redirectTo?: string;
}

const DEFAULT_PATH = '/';

const GatedRoute: React.FC<Props> = (props: Props) => {
  const { feature, children, redirectTo } = props;

  const features = useFeatures();

  const target = features.includes(feature)
    ? children
    : <Redirect to={ redirectTo || DEFAULT_PATH }/>;

  return (
    <Route { ...props }>
      { target }
    </Route>
  );
}

export default GatedRoute;
