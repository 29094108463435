/* eslint-disable react/display-name */
import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import ListHeader from '../ListHeader';
import { GridCellParams, GridColumns, GridValueFormatterParams } from '@material-ui/data-grid';
import { useAppSelector } from '../../hooks';
import Dinero from 'dinero.js';
import DataGrid from '../DataGrid/DataGrid';
import ProductItem from './ProductItem';
import { useHistory } from 'react-router';
import ActionsMenu from '../ActionsMenu';
import SortHeader from '../SortHeader';
import { BaseProduct } from '@castiron/domain';
import { Chip } from '@castiron/components';

type Props = {
  products: BaseProduct[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
    },
  },
  dataGridContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
}));

const ProductList: React.FC<Props> = (props: Props) => {
  const { products } = props;
  const [pageFinalized, setPageFinalized] = useState(false);
  const [filters, setFilters] = useState(['All']);
  const [filteredProducts, setFilteredProducts] = useState<BaseProduct[]>([]);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const filterOptions = ['All', 'Active', 'Inactive', 'Sold Out'];

  const columns: GridColumns = [
    {
      field: 'title',
      headerName: 'Item',
      flex: 1,
      renderCell: (params: GridCellParams): ReactElement => {
        return <ProductItem product={params.row as BaseProduct} />;
      },
      renderHeader: (params: GridCellParams): ReactElement => <SortHeader params={params} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      renderCell: (params: GridCellParams): ReactElement => {
        let status;
        if (params.value === 'active') status = 'success';
        if (params.value === 'inactive') status = 'warning';
        if (params.value === 'soldOut') status = 'error';

        return (
          <Chip colorScheme={status} uppercase bold>
            {params.value === 'soldOut' ? 'Sold Out' : params.value.toString()}
          </Chip>
        );
      },
      renderHeader: (params: GridCellParams): ReactElement => <SortHeader params={params} />,
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      align: isMobile ? 'right' : 'left',
      flex: 0.6,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.row.type === 'custom') {
          return 'Custom';
        } else {
          const unlimitedInventory = params?.row?.unlimitedInventory;
          return unlimitedInventory ? 'Unlimited' : params.value;
        }
      },
      renderHeader: (params: GridCellParams): ReactElement => <SortHeader params={params} />,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 0.6,
      valueFormatter: (params: GridValueFormatterParams): string => {
        const price: number = params.row.type === 'custom' ? params.row.startingPrice : params.row.price;
        return Dinero({ amount: (price || 0) }).toFormat('$0.00');
      },
      renderHeader: (params: GridCellParams): ReactElement => <SortHeader params={params} />,
    },
    {
      field: 'actionMenu',
      renderHeader: (): ReactElement => <div style={{ display: 'none' }} />,
      flex: 0.3,
      sortable: false,
      renderCell: (params: GridCellParams): ReactElement => (
        <ActionsMenu id={params.id} product={params.row as BaseProduct} type="products" />
      ),
    },
  ];

  const columnsMobile: GridColumns = columns.filter(
    column => column.field === 'title' || column.field === 'inventory',
  );

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleAddClick = (): void => {
    history.push('/products/add');
  };

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: string[]): void => {
    const map = {
      active: 'Active',
      inactive: 'Inactive',
      soldOut: 'Sold Out',
    };
    setFilters(value);
    if (value.includes('All')) setFilteredProducts(products);
    else {
      setFilteredProducts(products.filter(product => value.includes(map[product.status])));
    }
  };

  const handleCellClick = (params: GridCellParams): void => {
    if (params.field !== 'actionMenu') history.push(`/products/edit/${params.id}`);
  };

  useEffect(() => {
    /* this is frustrating, but the different columns/default sorts of mobile and desktop
     * cause the page to break on mobile page loads due to the columns and default sort
     * being out of sync on mobile page loads (when the isMobile resolves from false to true)
     * this appears to fix it
     */
    setPageFinalized(true);
  }, []);

  return (
    <Grid className={classes.container} item xs={12}>
      <ListHeader
        onAddClick={handleAddClick}
        showImport
        isMobile={isMobile}
        onFilterChange={handleFilterChange}
        filters={filters}
        filterOptions={filterOptions}
        content={{}}
      />
      {pageFinalized && (
        <Box className={classes.dataGridContainer}>
          {
            isMobile ? (
              <DataGrid
                sortModel={[{ field: 'title', sort: 'asc' }]}
                onCellClick={handleCellClick}
                rowHeight={96}
                columns={columnsMobile}
                rows={filteredProducts}
              />
            ) : (
              <DataGrid
                sortModel={[
                  { field: 'status', sort: 'asc' },
                  { field: 'title', sort: 'asc' },
                ]}
                onCellClick={handleCellClick}
                rowHeight={96}
                columns={columns}
                rows={filteredProducts}
              />
            )
          }

        </Box>
      )}
    </Grid>
  );
};

export default React.memo(ProductList);
