import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { Formik, getIn, useFormikContext } from 'formik';
import { makeStyles, Grid, MenuItem, useTheme, useMediaQuery, Box, ButtonBase, Link } from '@material-ui/core';
import { Instagram, Lock, LockOpen, OpenInNew } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import {
  Input,
  SvgIcon,
  FormErrorList,
  Button,
  Typography,
  Chip,
  FacebookIcon,
  TikTokIcon,
  SaveButton,
  DiscardButton,
} from '@castiron/components';
import { Shop, SocialMediaInfo } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { getCastIronAssetsAction } from '../../../../store/reducers/assets';
import { openModal } from '../../../../store/reducers/modalConductor';
import { getWebsiteUrlsAction, updateShopAction } from '../../../../store/reducers/shops';
import Badges from './components/Badges';
import CheckboxSelector from '../../../Products/EditProduct/FormComponents/CheckboxSelector';
import InputLabel from '../../../InputLabel';
import RichTextInput from '../../../RichTextEditor';
import UnsavedChangesPrompt from '../../../UnsavedChangesPrompt.tsx';
import AdminForm from '../../../AdminForm';
import { LayoutPageProps } from '../../../Layout';
import ViewShopButton from '../../../Layout/Header/ViewShopButton';
import states from '../../states';
import ScrollToError from '../../../ScrollToError';

import obModalDesktopScreen1 from '../../../../assets/img/onboardingModals/businessDetailsModal/BusinessDetailsDesktopScreen1.png';
import obModalDesktopScreen2 from '../../../../assets/img/onboardingModals/businessDetailsModal/BusinessDetailsDesktopScreen2.png';
import obModalMobileScreen1 from '../../../../assets/img/onboardingModals/businessDetailsModal/BusinessDetailsMobileScreen1.png';
import obModalMobileScreen2 from '../../../../assets/img/onboardingModals/businessDetailsModal/BusinessDetailsMobileScreen2.png';

const useStyles = makeStyles((theme: Theme) => ({
  businessDetailsModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  businessDetailsModalContentAdjustments: {
    maxWidth: '100% !important',
  },
  button: {
    minWidth: 0,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  captionText: {
    color: theme.branding.gray[700],
  },
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
  },
  descriptionField: {
    paddingTop: '16px',
  },
  aboutTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  detailsInput: {
    marginTop: '24px',
  },
  editButton: {
    color: theme.branding.blue.primary,
    paddingRight: 14,
  },
  infoBox: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '8px',
    padding: '24px',
  },
  inputURL: {
    backgroundColor: theme.branding.gray[400],
    padding: 14,
    borderRadius: '4px 0 0 4px',
    [theme.breakpoints.down('xs')]: {
      width: '125px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  labelContainer: {
    marginBottom: '-6px',
  },
  lock: {
    height: 15,
  },
  openNew: {
    color: theme.branding.blue.primary,
    marginLeft: '5px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 22,
  },
  socialIcon: {
    '& svg > path': {
      fill: 'rgba(0, 0, 0, 0.87)',
    },
  },
  tabPanel: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  websiteInput: {
    padding: 0,
    width: '100%',
  },
}));

const BusinessDetails: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const onMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { account, shop, castIronAssets, blockedWebsiteUrls } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    castIronAssets: state.assets.castIronAssets,
    blockedWebsiteUrls: state.shops.blockedWebsiteUrls,
  }));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const focus = urlParams.get('focus');
  const { section } = useParams<{ section: 'badges' | 'allergens' | 'social' }>();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formikRef = useRef() as any;
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(true);

  yup.addMethod(yup.string, 'blockedWebsiteUrl', function() {
    // Checks for own shop name here because its faster than
    //    filtering it out of the array.
    // @ts-ignore
    return this.test({
      name: 'name',
      message: 'That name is already taken.',
      test: value => {
        if (!blockedWebsiteUrls) return false;
        return (shop && value === shop.websiteUrl) || !blockedWebsiteUrls.includes(value);
      },
    });
  });

  const storeSchema = yup.object().shape({
    businessName: yup.string().required('Please enter a shop name'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),
    websiteUrl: yup
      .string()
      // @ts-ignore
      .blockedWebsiteUrl('Please choose a different URL')
      .lowercase()
      .trim()
      .required('Please enter a shop URL'),
    aboutTitle: yup.string(),
    description: yup.string(),
    seoMetadata: yup.object().shape({
      address: yup.object().shape({
        addressLocality: yup.string().required('Please enter a City'),
        addressRegion: yup.string().required('Please select a State'),
        postalCode: yup
          .string()
          .min(5, 'Zip Code must be at least 5 numbers')
          .required('Please enter a Zip Code'),
      }),
    }),
    facebook: yup
      .string()
      .url('Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?facebook.com\/(.*?)/gi,
        'Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.',
      )
      .nullable(),
    instagram: yup
      .string()
      .url('Please enter a valid Instagram URL that follows the format https://instagram.com/your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?instagram.com\/(.*?)/gi,
        'Please enter a valid Instagram URL that follows the format https://instagram.com/your-username.',
      )
      .nullable(),
    tiktok: yup
      .string()
      .url('Please enter a valid TikTok URL that follows the format https://tiktok.com/@your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?tiktok.com\/(.*?)/gi,
        'Please enter a valid TikTok URL that follows the format https://tiktok.com/@your-username.',
      )
      .nullable(),
    madeBadge: yup.string(),
    certificationsBadges: yup.array().of(yup.string()),
    specialDietsBadges: yup.array().of(yup.string()),
    moreBadges: yup.array().of(yup.string()),
    awardBadge: yup.string(),
  });

  const emptyValues = {
    businessName: '',
    email: '',
    websiteUrl: '',
    seoMetadata: {
      address: {
        addressLocality: '',
        addressRegion: '',
        postalCode: '',
      },
    },
    aboutTitle: '',
    description: '',
    facebook: '',
    instagram: '',
    tiktok: '',
    allergens: [],
    madeBadge: '',
    certificationsBadges: [],
    specialDietsBadges: [],
    moreBadges: [],
    awardBadge: '',
  };

  const initialValues = {
    ...emptyValues,
    ...shop,
    aboutTitle: shop?.aboutTitle || '',
    facebook: shop?.socialMedia?.facebookLink || '',
    instagram: shop?.socialMedia?.instagramLink || '',
    tiktok: shop?.socialMedia?.tiktokLink || '',
    madeBadge: shop?.badges?.made || '',
    certificationsBadges: shop?.badges?.certifications || [],
    specialDietsBadges: shop?.badges?.specialDiets || [],
    moreBadges: shop?.badges?.more || [],
    awardBadge: shop?.badges?.award || '',
  };

  const allergenRef = useRef<HTMLDivElement>();
  const allergenOptions = [
    { label: 'Milk', value: 'milk' },
    { label: 'Eggs', value: 'eggs' },
    { label: 'Fish', value: 'fish' },
    { label: 'Shellfish', value: 'shellfish' },
    { label: 'Tree Nuts', value: 'treeNuts' },
    { label: 'Wheat', value: 'wheat' },
    { label: 'Peanuts', value: 'peanuts' },
    { label: 'Soy Beans', value: 'soyBeans' },
    { label: 'Sesame', value: 'sesame' },
  ];

  const socialRef = useRef<HTMLDivElement>();

  const onWebsiteUrlChange = (event, setFieldValue) => {
    setFieldValue(
      'websiteUrl',
      event.target.value
        .toLowerCase()
        .replace(/\s+/gi, '-')
        .replace(/[^0-9a-zA-Z_-]/gi, ''),
    );
  };

  const onPostalCodeChange = (event, setFieldValue) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 5);
    setFieldValue('seoMetadata.address.postalCode', sanitizedValue);
  };

  useEffect(() => {
    setPageTitle('Business Details');
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton />]);

    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/store/dashboard" />,
      <SaveButton formikState={formikRef.current} isSubmitting={submitting} />,
    ]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
      setFooterCTAs([]);
    };
  }, [submitting]);

  useEffect(() => {
    if (castIronAssets.length) {
      if (focus === 'social-media') {
        document.getElementById('social-media').scrollIntoView();
      } else if (focus === 'allergens') {
        document.getElementById('allergens').scrollIntoView();
      } else if (focus === 'about') {
        setTimeout(() => {
          document.getElementById('about').scrollIntoView();
        }, 300);
      }
    }
  }, [focus, castIronAssets]);

  useEffect(() => {
    dispatch(getWebsiteUrlsAction());
  }, []);

  useEffect(() => {
    dispatch(getCastIronAssetsAction());
  }, [dispatch]);

  const businessDetailsModalContent = [
    {
      header: 'Share What Makes Your Shop Unique!',
      body:
        'Add a title, store description, and allergen information to help your customers connect with your business.',
      media: isMobile ? (
        <img src={obModalMobileScreen1} alt="Welcome Business Details Screen 1" />
      ) : (
        <img src={obModalDesktopScreen1} alt="Welcome Business Details Screen 1" />
      ),
      mediaClassName: classes.businessDetailsModalMediaAdjustments,
      contentClassName: classes.businessDetailsModalContentAdjustments,
    },
    {
      header: 'See Your Shop Come to Life!',
      body: (
        <>
          After editing, click <b>View My Shop</b> to view your storefront.
        </>
      ),
      media: isMobile ? (
        <img src={obModalMobileScreen2} alt="Welcome Business Details Screen 2" />
      ) : (
        <img src={obModalDesktopScreen2} alt="Welcome Business Details Screen 2" />
      ),
      mediaClassName: classes.businessDetailsModalMediaAdjustments,
      contentClassName: classes.businessDetailsModalContentAdjustments,
    },
  ];

  const openWelcomeBusinessDetailsModal = () => {
    dispatch(
      openModal({
        modalType: 'ONBOARDING_MODAL',
        modalProps: {
          show: true,
          stepContent: businessDetailsModalContent,
          onboardingModalType: 'businessDetailsModal',
        },
      }),
    );
  };

  useEffect(() => {
    if (shop?.status === 'prelaunch' && account) {
      if (
        !account.onboardingModals ||
        (account.onboardingModals && !account.onboardingModals?.businessDetailsModalShown)
      ) {
        openWelcomeBusinessDetailsModal();
      }
    }
  }, [account]);

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      const facebook = values.facebook;
      delete values.facebook;
      const instagram = values.instagram;
      delete values.instagram;
      const tiktok = values.tiktok;
      delete values.tiktok;

      const {
        websiteUrl,
        madeBadge,
        certificationsBadges,
        specialDietsBadges,
        moreBadges,
        awardBadge,
        ...restOfValues
      } = values;

      const socialMedia: SocialMediaInfo = {};

      if (facebook) socialMedia.facebookLink = facebook;
      if (instagram) socialMedia.instagramLink = instagram;
      if (tiktok) socialMedia.tiktokLink = tiktok;

      const badges = {
        made: madeBadge || '',
        certifications: certificationsBadges,
        specialDiets: specialDietsBadges,
        more: moreBadges,
        award: awardBadge,
      };

      if (
        values.seoMetadata.address.addressLocality !== shop?.seoMetadata?.address?.addressLocality ||
        values.seoMetadata.address.addressRegion !== shop?.seoMetadata?.address?.addressRegion
      ) {
        values.seoMetadata.manuallyUpdated = true;
      }

      const newShop: Shop = {
        ...shop,
        ...restOfValues,
        socialMedia,
        badges,
        websiteUrl: websiteUrl
          .trim()
          .replace(/\s+/g, '-')
          .replace(/[^0-9a-zA-Z_-]/gi, '')
          .toLowerCase(),
      };

      const newCompletions = [];

      await dispatch(updateShopAction({ shop: newShop, newCompletions })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                celebrate: true,
                content: 'Changes have been saved!',
              },
            }),
          );
        }
      });

      if (initialValues.websiteUrl !== newShop.websiteUrl) {
        trackEvent('Shop url edited', {
          shop: newShop.id,
          shopName: newShop.businessName,
          shopUrl: newShop.websiteUrl,
          shopOldUrl: shop.websiteUrl,
        });
      }

      if (initialValues.businessName !== newShop.businessName) {
        trackEvent('Shop name edited', {
          shop: newShop.id,
          shopName: newShop.businessName,
          shopOldName: shop.businessName,
          shopUrl: newShop.websiteUrl,
        });
      }

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  return (
    <Grid container>
      <Helmet>
        <title>Business Details | Shop | Castiron</title>
      </Helmet>
      <Grid item xs={12} className={classes.tabPanel}>
        <Formik
          initialValues={initialValues}
          validationSchema={storeSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ setFieldValue, values, isSubmitting, errors, submitCount, dirty, touched }) => (
            <AdminForm>
              <ScrollToError />
              <Grid spacing={!onMediumScreen && 6} container>
                <Grid className={classes.section} item xs={12} lg={8}>
                  <Grid>
                    <Typography variant="h4" style={{ marginBottom: '8px' }}>
                      Shop Details
                    </Typography>
                    <Grid className={classes.infoBox}>
                      <InputLabel
                        containerClass={classes.labelContainer}
                        primaryLabel={<Typography variant="subtitle2">Shop URL*</Typography>}
                        secondaryLabel={
                          <SvgIcon className={classes.lock}>{disabled ? <Lock /> : <LockOpen />}</SvgIcon>
                        }
                      />
                      <Input
                        error={touched.websiteUrl && errors.websiteUrl}
                        name="websiteUrl"
                        type="text"
                        disabled={disabled}
                        className={classes.websiteInput}
                        startAdornment={
                          <Box className={classes.inputURL}>
                            <Typography variant="body4">{process.env.REACT_APP_SHOP_URL}</Typography>
                          </Box>
                        }
                        endAdornment={
                          disabled && (
                            <ButtonBase onClick={(): void => setDisabled(false)}>
                              <Typography variant="body4" className={classes.editButton}>
                                Edit
                              </Typography>
                            </ButtonBase>
                          )
                        }
                        onBlur={(): void => setDisabled(true)}
                        onChange={e => onWebsiteUrlChange(e, setFieldValue)}
                      />
                      <Link href={`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`} target="_blank">
                        <Typography variant="button">View Your Shop</Typography>
                        <OpenInNew className={classes.openNew} fontSize="inherit" />
                      </Link>
                      <Grid className={classes.detailsInput}>
                        <Typography variant="subtitle2">Shop Name*</Typography>
                        <Input error={touched.businessName && errors.businessName} name="businessName" fullWidth />
                        <Typography className={classes.captionText} variant="caption">
                          Your shop name will appear throughout your site to your customers
                        </Typography>
                      </Grid>
                      <Grid className={classes.detailsInput}>
                        <Typography variant="subtitle2">Shop Contact Email*</Typography>
                        <Input
                          error={touched.email && errors.email}
                          placeholder="example@email.com"
                          name="email"
                          fullWidth
                        />
                        <Typography className={classes.captionText} variant="caption">
                          This email address will be used for sending and receiving emails from customers.
                        </Typography>
                      </Grid>
                      <Grid container xs={12} spacing={2} className={classes.detailsInput}>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2">City*</Typography>
                          <Input
                            error={
                              getIn(touched, 'seoMetadata.address.addressLocality') &&
                              getIn(errors, 'seoMetadata.address.addressLocality')
                            }
                            name="seoMetadata.address.addressLocality"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2">State*</Typography>
                          <Input
                            error={
                              getIn(touched, 'seoMetadata.address.addressRegion') &&
                              getIn(errors, 'seoMetadata.address.addressRegion')
                            }
                            name="seoMetadata.address.addressRegion"
                            fullWidth
                            select
                          >
                            {states.map(state => {
                              return (
                                <MenuItem key={state.name} value={state.abbreviation}>
                                  {state.name}
                                </MenuItem>
                              );
                            })}
                          </Input>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle2">Zip Code*</Typography>
                          <Input
                            onChange={e => onPostalCodeChange(e, setFieldValue)}
                            error={
                              getIn(touched, 'seoMetadata.address.postalCode') &&
                              getIn(errors, 'seoMetadata.address.postalCode')
                            }
                            name="seoMetadata.address.postalCode"
                            fullWidth
                          />
                        </Grid>
                        <Typography className={classes.captionText} variant="caption" style={{ padding: '0px 8px' }}>
                          Your location helps us automate your SEO, connect you with partners, and more.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className={classes.aboutTitle}>
                      <Typography variant="h4" style={{ marginBottom: '8px' }}>
                        Shop Description
                      </Typography>
                      <Chip colorScheme="info" className={classes.chip}>
                        <Typography variant="h6">Recommended</Typography>
                      </Chip>
                    </Grid>
                    <Grid className={classes.infoBox}>
                      <Grid item id="about">
                        <Typography variant="subtitle2">Title</Typography>
                        <Input
                          autoFocus={focus === 'about'}
                          name="aboutTitle"
                          fullWidth
                          placeholder="My Story, Bio, etc"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">Description</Typography>
                        <RichTextInput
                          name="description"
                          placeholder="The site description will appear on your site’s homepage. This is a place to tell your customers about yourself or Store"
                          className={classes.descriptionField}
                          initialValue={shop.description}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Badges focus={section === 'badges'} currentBadges={shop.badges} />
                </Grid>
                <Grid className={classes.section} item xs={12} lg={4}>
                  <div id="social-media" ref={socialRef}>
                    <Typography variant="h4">Social links</Typography>
                    <Input
                      startAdornment={<FacebookIcon />}
                      name="facebook"
                      placeholder="facebook.com/"
                      error={touched.facebook && errors.facebook}
                    />
                    <Input
                      startAdornment={<Instagram />}
                      name="instagram"
                      placeholder="instagram.com/"
                      error={touched.instagram && errors.instagram}
                    />
                    <Input
                      containerClass={classes.socialIcon}
                      startAdornment={<TikTokIcon />}
                      name="tiktok"
                      placeholder="tiktok.com/@"
                      error={touched.tiktok && errors.tiktok}
                    />
                  </div>
                  <div id="allergens" ref={allergenRef}>
                    <Typography variant="h4">Allergen Information</Typography>
                    <Typography variant="body4">
                      Let your customers know if your products were made on equipment that also produce products that
                      contain any of the following ingredients:
                    </Typography>
                    <CheckboxSelector name="allergens" columns={2} options={allergenOptions} />
                  </div>
                </Grid>
                <Box className={classes.buttonsContainer}>
                  {!!submitCount && !!errors && !isSubmitting && <FormErrorList errors={errors} />}
                </Box>
                <UnsavedChangesPrompt when={dirty} />
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BusinessDetails;
