import { CarrierOption } from '@castiron/domain';

export function createTrackingNumberUrl(trackingNumber: string, shippingCarrier: CarrierOption) {
  switch (shippingCarrier) {
    case 'ups':
      return `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
    case 'dhlexpress':
      return `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}&brand=DHL`;
    case 'fedex':
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
    case 'usps':
      return `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`;
    case 'other':
    default:
      return `https://www.google.com/search?q=${trackingNumber}`;
  }
}
