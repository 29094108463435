import React from 'react';
import SvgIcon from '../SvgIcon';
import EditIcon from '../assets/pencil.svg';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import ProductImage from '../ProductImage';
import { useDropzone } from 'react-dropzone';
import clsx from "clsx";

type Props = {
  src: any;
  onFile: (files) => void;
  width?: string;
  height?: string;
  alt?: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  enabled: boolean;
  className?: string;
  imageComponent?: JSX.Element;
  containerClass?: string;
  iconButtonClass?: string;
};

const maxImageSize = 340;

interface StyleProps {
  objectFit: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    height: 'inherit',
    width: '100%'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    borderRadius: 6,
  },
  iconButton: {
    zIndex: 20,
    top: 'calc(50% - 16px)',
    left: 'calc(50% - 16px)',
    backgroundColor: theme.branding.gray[100],
    opacity: '75%',
    position: 'absolute',
    borderRadius: 4,
    width: 32,
    height: 32,
  },
  dropzone: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  fileUploaderContainer: {
    height: 'inherit',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  icon: {
    color: theme.branding.gray[800],
    width: 38,
    height: 38,
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: maxImageSize,
    maxHeight: maxImageSize,
    height: '100%',
    objectFit: (props) => props.objectFit || 'contain',
    overflow: 'hidden',
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[800],
  },
}));

const EditableImage: React.FC<Props> = (props: Props) => {
  const { onFile, src, alt, enabled, className, imageComponent, width, height, objectFit, containerClass, iconButtonClass } = props;
  const { getRootProps, getInputProps, open } = useDropzone({ accept: 'image/jpeg, image/png', onDrop: onFile, maxFiles: 1, noClick:true });

  const classes = useStyles({
    objectFit
  });

  const image = imageComponent || <ProductImage
    alt={alt}
    src={src}
    className={className ? className : undefined}
    height={height}
    width={width}
    containerClassName={containerClass}
  />;

  const onClick = (event) => {
    event.stopPropagation();
    open();
  }


  return (enabled ?
    <div {...getRootProps({ className: clsx([classes.container, containerClass]) })} onClick={onClick}>
      <div className={classes.fileUploaderContainer}>
        <input {...getInputProps()} />
        <ButtonBase className={clsx(classes.iconButton, iconButtonClass)}>
          <SvgIcon className={classes.icon}>
            <EditIcon />
          </SvgIcon>
        </ButtonBase>
        {image}
      </div>
    </div>
    : image
  );
};

export default EditableImage;
