import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CharcuterieIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M0.750027 5.38081C0.747742 5.1189 0.89159 4.85596 1.20717 4.75305L15.3832 0.130445C16.2488 -0.151825 17.1987 0.0306381 17.8982 0.613518L23.0106 4.87382C23.1777 5.01313 23.2515 5.19911 23.25 5.38173L0.750027 5.38081ZM23.2486 17.9546C23.2486 19.0509 22.3599 19.9397 21.2635 19.9397H14.6426C14.6452 19.8847 14.6465 19.8296 14.6465 19.7743C14.6465 17.8557 13.0911 16.3003 11.1725 16.3003C9.25388 16.3003 7.69853 17.8557 7.69853 19.7743C7.69853 19.8296 7.69983 19.8847 7.70241 19.9397H2.73573C1.63938 19.9397 0.750612 19.0509 0.750612 17.9546V6.70557H23.2486V10.3535C21.5839 10.4745 20.271 11.8634 20.271 13.559C20.271 15.2545 21.5839 16.6434 23.2486 16.7644V17.9546ZM13.9847 13.6535C15.4466 13.6535 16.6316 12.4685 16.6316 11.0067C16.6316 9.54486 15.4466 8.35984 13.9847 8.35984C12.5229 8.35984 11.3379 9.54486 11.3379 11.0067C11.3379 12.4685 12.5229 13.6535 13.9847 13.6535ZM5.05171 15.3078C6.14806 15.3078 7.03683 14.419 7.03683 13.3226C7.03683 12.2263 6.14806 11.3375 5.05171 11.3375C3.95536 11.3375 3.06659 12.2263 3.06659 13.3226C3.06659 14.419 3.95536 15.3078 5.05171 15.3078ZM21.5944 13.559C21.5944 12.5949 22.316 11.7993 23.2486 11.683V15.4349C22.316 15.3186 21.5944 14.5231 21.5944 13.559ZM9.02819 19.9397C9.02404 19.8849 9.02195 19.8298 9.02195 19.7743C9.02195 18.5866 9.98478 17.6237 11.1725 17.6237C12.3602 17.6237 13.323 18.5866 13.323 19.7743C13.323 19.8298 13.321 19.8849 13.3168 19.9397H9.02819ZM13.9847 12.3301C13.2538 12.3301 12.6613 11.7376 12.6613 11.0067C12.6613 10.2758 13.2538 9.68325 13.9847 9.68325C14.7157 9.68325 15.3082 10.2758 15.3082 11.0067C15.3082 11.7376 14.7157 12.3301 13.9847 12.3301ZM5.05171 13.9843C4.68626 13.9843 4.39 13.6881 4.39 13.3226C4.39 12.9572 4.68626 12.6609 5.05171 12.6609C5.41716 12.6609 5.71341 12.9572 5.71341 13.3226C5.71341 13.6881 5.41716 13.9843 5.05171 13.9843Z"
      fill="#004CCC"
    />
  </SvgIcon>
);
