import React, { ReactNode, useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { BaseProduct, Coupon, Transaction } from '@castiron/domain';
import { Button, ChevronDownIcon } from '@castiron/components';
import MoreIcon from '../../assets/img/more-icon.png';
import CouponsActionsMenu from '../Coupons/CouponTable/CouponActionsMenu';
import CustomerActionsMenu from '../Customers/CustomerActionsMenu';
import Popover from '../Popover/Popover';
import ProductActionsMenu from '../Products/ProductActionsMenu';
import QuoteActionsMenu from '../Quotes/QuoteActionsMenu';
import QuoteCustomerActionsMenu from '../Quotes/QuoteCustomerActionMenu';

type Props = {
  type: 'customers' | 'products' | 'notes' | 'coupons' | 'custom-orders' | 'quotes' | 'quotes-customer';
  id?: any;
  note?: string;
  coupon?: Coupon;
  product?: BaseProduct;
  transaction?: Transaction;
  editing?: boolean;
  displayAsTextButton?: boolean;
  children?: ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
};

const useStyles = makeStyles(theme => ({
  icon: {
    height: 36,
    width: 'auto',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuContainer: {
    borderRadius: '8px',
  },
  chevron: {
    color: theme.branding.blue.primary,
  },
}));

const ActionsMenu: React.FC<Props> = (props: Props) => {
  const { type, id, note, coupon, product, transaction, displayAsTextButton, children, editing, onDelete, onEdit } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      {
        displayAsTextButton ?
          <Button variant="outlined" onClick={handleClick}>
            {children}
            <ChevronDownIcon />
          </Button>
          :
          <ButtonBase onClick={handleClick}>
            <img className={classes.icon} src={MoreIcon} />
          </ButtonBase>
      }
      <Popover onClose={(e) => handleClose(e)} anchorEl={anchorEl} paperClass={classes.menuContainer}>
        {type === 'products' && <ProductActionsMenu product={product} onClose={handleClose} />}
        {type === 'customers' && <CustomerActionsMenu id={id} onClose={handleClose} />}
        {type === 'coupons' && <CouponsActionsMenu coupon={coupon} onClose={handleClose} />}
        {type === 'quotes' && <QuoteActionsMenu transaction={transaction} editing={editing} onClose={handleClose} />}
        {type === 'quotes-customer' && <QuoteCustomerActionsMenu onDelete={onDelete} onEdit={onEdit} onClose={handleClose} />}
      </Popover>
    </div>
  );
};

export default ActionsMenu;
