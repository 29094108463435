import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { ShopSummary } from "@castiron/domain";
import ArtisanCard from "./ArtisanCard";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getTaxonomyAction } from "../../../store/reducers/taxonomy";
import ArtisanFilter from "./ArtisanFilter";
import Pagination from "@material-ui/lab/Pagination";
import { getService } from "../../../firebase";


const findArtisanActiveShopsService = getService('shops', 'findArtisanActiveShops');

interface FilterOptions {
  artisanCategories?: string[];
  region?: string[];
  fulfillment?: string[];
  num: number;
  page: number;
}

const NUM_PER_PAGE = 9;

const useStyles = makeStyles((theme: Theme) => ({
  pageinator: {
    "& > *": {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: 'flex'
    }
  }
}));


const ArtisanList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [artisans, setArtisans] = useState<ShopSummary[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const { shop, taxonomy } = useAppSelector(state => ({
    shop: state.shops.shop,
    taxonomy: state.taxonomy.taxonomy
  }));
  const classes = useStyles();

  const setOption = (optionName: string, values: any): void => {
    setFilterOptions((ops) => ({
      ...ops,
      [optionName]: values
    }));
  };

  const setPageOption = (page: number) => {
    setPage(page);
    setOption('page', page);
  }

  useEffect(() => {
    if (shop && !filterOptions) {
      setFilterOptions({
        artisanCategories: shop.artisanCategory?.name && [shop.artisanCategory?.name],
        page,
        num: NUM_PER_PAGE
      });
    }
  }, [shop, filterOptions]);

  useEffect(() => {
    if (filterOptions) {
      const search = async () => {
        const resp = await findArtisanActiveShopsService(filterOptions);
        setArtisans(resp.artisans);
        setTotal(resp.total);
      };

      search();
    }
  }, [filterOptions])

  useEffect(() => {
    if (!taxonomy)
      dispatch(getTaxonomyAction());
  }, [taxonomy])

  return (
    <Grid container spacing={ 2 }>
      <ArtisanFilter
        initialCategories={ [shop.artisanCategory?.name] }
        onCategoryChange={ (cats) => setOption('artisanCategories', cats) }
        onLocationChange={ (regions) => setOption('region', regions) }
        onFulfillmentChange={ (types) => setOption('fulfillment', types?.map(t => t.toLowerCase())) }
      />
      { artisans?.map(a => <ArtisanCard key={ a.id } shop={ a }/>) }
      <Grid item xs={ 12 } className={ classes.pageinator }>
        <Pagination
          count={ Math.ceil(total / NUM_PER_PAGE) }
          page={ page }
          onChange={ (event, value) => setPageOption(value) }/>
      </Grid>
    </Grid>
  );
}

export default ArtisanList;
