import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const RecipeBookIcon = (props: SvgIconProps) => (
  <svg width="88" height="66" viewBox="0 0 88 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9758 58.1571C43.7612 58.1571 43.5466 58.1094 43.3558 58.014L42.4496 57.5609C32.1233 52.362 19.9607 51.6227 9.06202 55.5338C8.63275 55.6769 8.17964 55.6292 7.79807 55.3669C7.44034 55.1045 7.22571 54.6991 7.22571 54.246V3.95006C7.22571 3.3777 7.58343 2.85304 8.13194 2.66225C13.9271 0.587455 19.9845 -0.294931 26.1374 0.0866412C32.2902 0.468213 38.1807 2.06605 43.6897 4.83244L44.5959 5.28556C45.049 5.52404 45.359 6.001 45.359 6.50182V56.7739C45.359 57.2509 45.1206 57.704 44.7151 57.9425C44.4767 58.0856 44.2143 58.1571 43.9758 58.1571ZM23.371 50.2633C24.301 50.2633 25.2073 50.2872 26.1374 50.3587C31.8848 50.7164 37.4176 52.1235 42.5926 54.556V7.3365L42.4496 7.26496C32.4094 2.20913 20.6284 1.37444 9.96826 4.92783V52.3143C14.3325 50.9549 18.8159 50.2633 23.371 50.2633Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.2058 16.3272C52.6096 16.3272 52.0372 15.9218 51.8703 15.3018C51.6795 14.5625 52.1088 13.8232 52.8481 13.6085C58.524 12.0822 64.3429 11.7007 70.1619 12.4161C70.9251 12.5115 71.4497 13.2031 71.3543 13.9424C71.2589 14.7056 70.5673 15.2302 69.828 15.1348C64.3668 14.4432 58.9055 14.8248 53.5874 16.2557C53.4443 16.3272 53.325 16.3272 53.2058 16.3272Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.2289 24.8173C52.6327 24.8173 52.0603 24.4119 51.8934 23.7918C51.7026 23.0525 52.1319 22.3132 52.8712 22.0986C58.547 20.5723 64.366 20.1907 70.185 20.9062C70.9481 21.0016 71.4728 21.6932 71.3774 22.4325C71.282 23.1718 70.5904 23.7203 69.8511 23.6249C64.3898 22.9333 58.9286 23.3148 53.6104 24.7457C53.4674 24.7934 53.3481 24.8173 53.2289 24.8173Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.277 33.3073C52.6808 33.3073 52.1084 32.9019 51.9415 32.2819C51.7507 31.5426 52.1799 30.8033 52.9192 30.5886C58.5951 29.0623 64.4141 28.6808 70.2331 29.3962C70.9962 29.4916 71.5209 30.1832 71.4255 30.9225C71.3301 31.6857 70.6385 32.2103 69.8992 32.1149C64.4379 31.4233 58.9767 31.8049 53.6585 33.2358C53.5154 33.2835 53.3962 33.3073 53.277 33.3073Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3246 41.7972C52.7284 41.7972 52.156 41.3918 51.9891 40.7717C51.7983 40.0324 52.2276 39.2931 52.9669 39.0785C58.6427 37.5522 64.4617 37.1706 70.2807 37.8861C71.0438 37.9815 71.5685 38.6731 71.4731 39.4123C71.3777 40.1516 70.6861 40.7002 69.9468 40.6048C64.4856 39.9132 59.0243 40.2947 53.7062 41.7256C53.5631 41.7733 53.4438 41.7972 53.3246 41.7972Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9762 65.1207C42.3306 65.1207 40.7089 64.7392 39.2065 63.9999C38.2526 63.5229 37.2509 63.0698 36.2493 62.6882C32.1713 61.0904 27.7355 60.2557 23.3951 60.2557C19.3409 60.2557 15.3344 60.9473 11.4949 62.3305L5.03198 64.6676C3.88726 65.073 2.59946 64.9061 1.59783 64.2145C0.596205 63.499 0 62.3543 0 61.1381V9.17275C0 8.40961 0.620053 7.78955 1.3832 7.78955H8.27534C9.03848 7.78955 9.65854 8.40961 9.65854 9.17275C9.65854 9.93589 9.03848 10.5559 8.27534 10.5559H2.7664V61.0904C2.7664 61.4242 2.93333 61.7343 3.19566 61.9251C3.48184 62.1158 3.81572 62.1635 4.12574 62.0443L10.5886 59.7072C14.7144 58.2286 19.0309 57.4654 23.419 57.4654C28.0932 57.4654 32.8867 58.3717 37.2748 60.0887C38.348 60.518 39.4211 60.995 40.4705 61.5196C42.6884 62.6405 45.3117 62.6405 47.5295 61.5196C48.555 60.995 49.6282 60.518 50.7252 60.0887C55.1133 58.3717 59.9068 57.4654 64.581 57.4654C68.9691 57.4654 73.2856 58.2286 77.4114 59.7072L83.8743 62.0443C84.1843 62.1635 84.5182 62.1158 84.8043 61.9251C85.0905 61.7343 85.2336 61.4242 85.2336 61.0904V10.5559H79.7962C79.0331 10.5559 78.413 9.93589 78.413 9.17275C78.413 8.40961 79.0331 7.78955 79.7962 7.78955H86.6168C87.38 7.78955 88 8.40961 88 9.17275V61.0904C88 62.3066 87.4038 63.4752 86.4022 64.1668C85.4005 64.8822 84.1127 65.0492 82.968 64.6199L76.5052 62.2828C72.6894 60.8996 68.6829 60.208 64.6049 60.208C60.2645 60.208 55.8287 61.0427 51.7507 62.6405C50.7491 63.0221 49.7474 63.4752 48.7935 63.9522C47.2434 64.7392 45.5978 65.1207 43.9762 65.1207Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9764 58.1571C43.7141 58.1571 43.4756 58.0856 43.261 57.9425C42.8555 57.704 42.6171 57.2509 42.6171 56.7739V6.50182C42.6171 5.97716 42.9032 5.50019 43.3802 5.28556L44.2864 4.83244C49.7954 2.06605 55.6859 0.468213 61.8387 0.0866412C67.9916 -0.294931 74.049 0.587455 79.8442 2.66225C80.3927 2.85304 80.7504 3.3777 80.7504 3.95006V54.2221C80.7504 54.6753 80.5358 55.0807 80.178 55.343C79.8203 55.6053 79.3433 55.653 78.9141 55.5099C68.0393 51.5988 55.8528 52.3381 45.5265 57.537L44.6203 57.9902C44.3818 58.1094 44.1672 58.1571 43.9764 58.1571ZM45.3358 7.3365V54.556C50.5347 52.1235 56.0436 50.6926 61.791 50.3587C67.3 50.0248 72.7135 50.6926 77.9601 52.3143V4.92783C67.3 1.37444 55.519 2.20913 45.4789 7.26496L45.3358 7.3365Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4544 44.2775H22.1076C21.3444 44.2775 20.7244 43.6575 20.7244 42.8943C20.7244 42.1312 21.3444 41.5111 22.1076 41.5111H30.4544C31.2176 41.5111 31.8376 42.1312 31.8376 42.8943C31.8376 43.6575 31.2176 44.2775 30.4544 44.2775Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1078 44.2775H19.2221C17.8151 44.2775 16.5988 43.2758 16.3365 41.8926L14.5002 32.5441C14.2617 31.3756 14.5717 30.1832 15.3348 29.2531C16.098 28.323 17.2189 27.7983 18.4113 27.7983C20.4622 27.7983 22.1793 29.3485 22.3701 31.3756L23.4909 42.7512C23.5386 43.1328 23.3956 43.5143 23.1332 43.8005C22.8709 44.1105 22.4893 44.2775 22.1078 44.2775ZM18.3874 30.5647C17.8866 30.5647 17.5766 30.8509 17.4335 31.0179C17.2904 31.1848 17.0758 31.5425 17.1712 32.0433L19.0075 41.3918C19.0313 41.4872 19.1029 41.5588 19.1983 41.5588H20.5815L19.6037 31.7094C19.556 31.0417 19.0313 30.5647 18.3874 30.5647Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0337 33.4265C20.3421 33.4265 19.7459 32.9018 19.6744 32.1864C19.5551 31.0655 19.9367 29.9446 20.676 29.1338C21.4391 28.2991 22.5123 27.8221 23.6332 27.8221H23.6809C25.8749 27.8221 27.6635 29.6108 27.6635 31.8048C27.6635 32.5679 27.0435 33.188 26.2803 33.188C25.5172 33.188 24.8971 32.5679 24.8971 31.8048C24.8971 31.1371 24.3486 30.5647 23.657 30.5647H23.6332C23.2755 30.5647 22.9654 30.7078 22.7269 30.9701C22.4885 31.2324 22.3692 31.5663 22.4169 31.924C22.4885 32.6872 21.94 33.3549 21.1768 33.4265C21.1291 33.4265 21.0814 33.4265 21.0337 33.4265Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5272 33.4265C31.4795 33.4265 31.4318 33.4265 31.3841 33.4265C30.621 33.3549 30.0725 32.6872 30.144 31.924C30.1678 31.5663 30.0725 31.2324 29.834 30.9701C29.5955 30.7078 29.2855 30.5647 28.9277 30.5647H28.88C28.2123 30.5647 27.6399 31.1132 27.6399 31.8048C27.6399 32.5679 27.0199 33.188 26.2567 33.188C25.4936 33.188 24.8735 32.5679 24.8735 31.8048C24.8735 29.6108 26.6622 27.8221 28.8562 27.8221H28.9039C30.0248 27.8221 31.0979 28.2991 31.8611 29.1338C32.6242 29.9685 32.9819 31.0894 32.8627 32.1864C32.8388 32.9018 32.2426 33.4265 31.5272 33.4265Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3639 44.2774H30.4782C30.0966 44.2774 29.7151 44.1105 29.4527 43.8243C29.1904 43.5381 29.0712 43.1566 29.095 42.775L30.2159 31.3994C30.4067 29.3484 32.1237 27.8221 34.1747 27.8221C35.3671 27.8221 36.488 28.3468 37.2511 29.2769C38.0143 30.207 38.3243 31.3994 38.0858 32.5679L36.2495 41.9164C35.9633 43.2758 34.7471 44.2774 33.3639 44.2774ZM31.9807 41.5349H33.3639C33.4593 41.5349 33.5308 41.4633 33.5546 41.3679L35.391 32.0194C35.4864 31.5186 35.2717 31.1609 35.1286 30.994C34.9855 30.827 34.6755 30.5408 34.1747 30.5408C33.5308 30.5408 33.0061 31.0178 32.9584 31.6617L31.9807 41.5349Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2805 44.2775C25.5174 44.2775 24.8973 43.6575 24.8973 42.8944V31.4949C24.8973 30.7317 25.5174 30.1117 26.2805 30.1117C27.0437 30.1117 27.6637 30.7317 27.6637 31.4949V42.8944C27.6637 43.6575 27.0437 44.2775 26.2805 44.2775Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9784 30.5646C15.7399 30.3262 13.7128 28.1083 14.5952 25.6042C14.9767 24.5549 15.7876 23.6725 17.0277 23.0047C17.2662 22.8617 17.5285 22.7424 17.8147 22.6232C17.5762 21.7169 17.4808 20.5245 18.0055 19.356C18.6732 17.8297 20.1041 16.8042 22.2982 16.3034C24.1583 15.8741 25.3984 15.111 25.8992 14.1332C26.1616 13.5847 26.1139 13.1077 26.1139 13.1077C25.9946 12.5592 26.2331 11.9869 26.7101 11.6768C27.187 11.3668 27.8071 11.3668 28.2602 11.7007C30.335 13.1793 32.0521 16.1126 31.3605 18.9028C30.8597 20.9061 28.9518 23.4817 22.6797 24.2925C21.6781 24.4118 20.7957 24.6026 20.0564 24.7934C20.0326 24.7934 19.9849 24.8172 19.961 24.8172C19.341 24.9841 18.7925 25.1988 18.3632 25.4373C17.9339 25.6757 17.3854 26.0335 17.2185 26.5104C16.9323 27.2736 17.7193 28.3467 18.0055 28.6568L15.9784 30.5646ZM28.1887 15.6833C27.4971 16.8519 26.0423 18.2589 22.9421 18.9982C22.0597 19.2129 20.915 19.6421 20.5572 20.4768C20.3664 20.9299 20.3903 21.4308 20.4857 21.86C21.058 21.7408 21.6781 21.6454 22.322 21.55C26.0185 21.073 28.2841 19.8806 28.6895 18.2112C28.9041 17.4243 28.6656 16.4942 28.1887 15.6833Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.7543 30.5647C24.635 30.5647 24.5158 30.5408 24.3965 30.517C23.6572 30.3262 23.228 29.563 23.4188 28.8238C23.4426 28.7284 23.4665 28.633 23.4903 28.5137C23.6572 27.8221 23.8957 26.8682 24.8974 25.8666C25.8751 24.8888 27.473 23.9587 29.9055 22.9094C30.931 22.4801 31.4079 22.027 31.4079 21.7647C31.4318 21.55 31.2171 21.0731 30.5494 20.453C30.0009 19.9284 29.4047 19.5706 29.4047 19.5706C28.7608 19.1891 28.5461 18.3305 28.9277 17.6866C29.3093 17.0427 30.1678 16.8281 30.8117 17.2097C30.9071 17.2574 31.6703 17.7105 32.4334 18.4498C33.6497 19.5945 34.222 20.763 34.1505 21.9555C34.0551 23.4102 32.9819 24.5788 30.9787 25.4373C28.9277 26.3197 27.5207 27.1067 26.8291 27.7983C26.3521 28.2752 26.2805 28.6091 26.1375 29.1576C26.1136 29.2769 26.0898 29.3961 26.0421 29.5392C25.9228 30.1593 25.3505 30.5647 24.7543 30.5647Z" fill="#005FFF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6056 30.994C35.2717 30.994 34.914 30.8748 34.6517 30.6125C34.1031 30.0878 34.0793 29.2054 34.604 28.6808C35.5817 27.6791 35.4386 27.0352 35.4148 26.8445C35.1286 25.4374 32.863 24.1735 32.076 23.8157C31.3844 23.5057 31.0744 22.6949 31.3606 22.0033C31.6706 21.3117 32.4576 20.9778 33.1731 21.2878C33.6023 21.4786 37.4896 23.2434 38.1096 26.2721C38.3243 27.2976 38.2289 28.8716 36.5834 30.5648C36.321 30.851 35.9633 30.994 35.6056 30.994Z" fill="#005FFF" />
  </svg>
);