import FirebaseApp from '../config/initFirebase';
import 'firebase/compat/firestore';

const db = FirebaseApp.firestore();

// if no id is passed in, create a new document
// Else use the set
export async function set(collectionName, item, id) {
  const collection = db.collection(collectionName);
  if (!id) {
    return await collection.add(item);
  }

  const result = await collection.doc(id).set(item, { merge: true });

  return await get(collectionName, id);
}

export default db;

export async function get(collectionName, id) {
  const collection = db.collection(collectionName);

  if (id) {
    const result = await collection.doc(id).get();
    return {
      id: result.id,
      ...result.data(),
    };
  }
}

// Comparisons is an array of these objects: { prop: '', comparison: '', value: '' }
// https://firebase.google.com/docs/firestore/query-data/queries
// .where(prop, comparison, value);
export async function query(collectionName, comparisons, orderBy = {}) {
  if (!collectionName) return [];
  if (!comparisons) comparisons = [];

  const collection = db.collection(collectionName);

  let collectionComparisons = collection;
  // Chain multiple where clauses together
  comparisons.forEach(item => {
    const { prop, comparison, value } = item;
    collectionComparisons = collectionComparisons.where(prop, comparison, value);
  });

  Object.keys(orderBy).forEach(field => {
    collectionComparisons = collectionComparisons.orderBy(field, orderBy[field]);
  });

  const result = await collectionComparisons.get();
  return result.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function list(collectionName) {
  return await db.collection(collectionName).get();
}

export async function remove(collectionName, id) {
  return await db
    .collection(collectionName)
    .doc(id)
    .delete();
}
