import React from 'react';
import { Box } from '@material-ui/core';
import DynamicSelect from './DynamicSelect';
import InputLabel from '../../../InputLabel';
import Tooltip from '../../../Tooltip';

type Props = {
  classes: any;
  value: any;
  onChange: (e) => void;
  categories?: any[];
};

const CategoryInput: React.FC<Props> = (props: Props) => {
  const { classes, onChange, value, categories } = props;

  return (
    <Box>
      <InputLabel
        containerClass={classes.categoryLabel}
        primaryLabel="Category"
        secondaryLabel={
          <Tooltip title="If you would like to categorize your products in your external store, create categories here." />
        }
      />
      <DynamicSelect value={value} options={categories} onChange={onChange} />
    </Box>
  );
};

export default CategoryInput;
