import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { CommunityPackage } from '@castiron/domain';
import { DesktopLayout, MobileLayout } from '.';
import { useParams } from 'react-router-dom';
import { communityPackageRepository, productRepository } from '@castiron/client-admin/src/domain';
import { CommunityPackageContext } from '.';
import { PageContainer, PageContent, Spinner } from '@castiron/components';
import { useAppSelector } from '@castiron/client-admin/src/hooks';
import { LayoutPageProps } from '../../Layout';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px 16px',
    },
  },
}));

interface Params {
  id?: string;
}

// Wrapper for Community Package Details
const CommunityPackageDetails: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs } = props;

  const classes = useStyles();
  const { id } = useParams<Params>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const [communityPackage, setCommunityPackage] = useState<CommunityPackage>(null);

  const { products, shop } = useAppSelector(state => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  // Modifies community package with added property
  const modifyCommunityPackageWithAdded = async (communityPackage: CommunityPackage) => {
    let productsToCheck;

    if (products.length) {
      productsToCheck = products;
    } else {
      productsToCheck = await productRepository.findByShopId(shop.id);
    }

    const productAdded = productsToCheck.find(product => product.communityPackageId === communityPackage.id);

    return productAdded ? { ...communityPackage, addedProductId: productAdded.id } : communityPackage;
  };

  useEffect(() => {
    setPageTitle('Community Package');
    setBackLocation(true);
    setHeaderCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  // Gets the Community package object
  useEffect(() => {
    const getCommunityPackage = async () => {
      setIsLoading(true);
      const result = await communityPackageRepository.get(id);
      if (result) {
        const modifiedCommunityPackage = await modifyCommunityPackageWithAdded(result);
        setCommunityPackage(modifiedCommunityPackage);
      }
      setIsLoading(false);
    };

    if (id) getCommunityPackage();
  }, [id]);

  if (!communityPackage) return null;

  return (
    <CommunityPackageContext.Provider value={{ communityPackage }}>
      <PageContainer helmetTitle="Community | Castiron" className={classes.container}>
        <Helmet>
          <title>Community | Castiron</title>
        </Helmet>

        <Spinner show={isLoading} />

        <PageContent>{isMobile ? <MobileLayout /> : <DesktopLayout />}</PageContent>
      </PageContainer>
    </CommunityPackageContext.Provider>
  );
};

export default CommunityPackageDetails;
