import firebase from 'firebase/compat/app';
import { HttpsCallable } from '@firebase/functions-types';
import axios from 'axios';
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { determineAttribution } from "@castiron/utils";
import { FirebaseApp } from "@castiron/castiron-firebase";

const { REACT_APP_IS_LOCAL_FIREBASE } = process.env;

type BoundedContextName =
  | 'accounts'
  | 'asana'
  | 'auth'
  | 'customers'
  | 'firestore'
  | 'images'
  | 'marketing'
  | 'messaging'
  | 'orders'
  | 'packages'
  | 'products'
  | 'shops'
  | 'stripe'
  | 'subscriptions'
  | 'test'
  | 'transactions'

const FUNCTIONS_STUB = {
  httpsCallable: (message: any): HttpsCallable => {
    console.warn('Using stub httpsCallable implementation');
    return data => Promise.resolve({ data: {} });
  },
};

const useStub = FirebaseApp?.firebase?.apps.length === 0;
const functions = () => (useStub ? FUNCTIONS_STUB : FirebaseApp.functions());
const functionsV2 = useStub ? undefined : getFunctions();

const retrieveExperiments = () => {
  const storedExp = localStorage.getItem('experiments');
  return storedExp ? JSON.parse(storedExp) : undefined;
};

export const getServiceV1 = (boundedContext: BoundedContextName, serviceName: string, type: 'call' | 'request' = 'call') => {
  return async (request: Record<string, any>) => {
    console.debug(`Making request to [${serviceName}]`, request);
    const attribution = determineAttribution();
    const call = type == 'call' ? functions().httpsCallable(`${boundedContext}-${serviceName}`) : getRequestService(boundedContext, serviceName);
    /* if payload is from the data layer, then call data() to remove non serializable fields */
    const requestPayload = request.data ? request.data() : request;
    const result = await call({
      ...requestPayload,
      attribution: attribution,
      experiments: retrieveExperiments(),
    });
    console.debug(`Response from [${serviceName}]`, result);
    return result.data;
  };
};

export const getServiceV2 = (boundedContext: BoundedContextName, serviceName: string) => {
  return async (request: Record<string, any>) => {
    const attribution = determineAttribution();
    const call =  useStub ? FUNCTIONS_STUB.httpsCallable(`${boundedContext}-${serviceName}`) : httpsCallableFromURL(
      functionsV2,
      `https://${boundedContext}-${serviceName}-${process.env.REACT_APP_RUN_BASE_URL}.run.app`,
      {
        timeout: 500
      }
    );
    console.debug(`Calling Service [${serviceName}]`, request);
    const result = await call({
      ...request,
      attribution: attribution
    });
    console.debug(`Response from call to Service [${serviceName}]`, result);
    return result.data;
  }
}


export const getService = (boundedContext: BoundedContextName, serviceName: string, config?: { version?: number, type?: 'call' | 'request' }) =>
  (!REACT_APP_IS_LOCAL_FIREBASE && config?.version === 2) ? getServiceV2(boundedContext, serviceName) : getServiceV1(boundedContext, serviceName, config?.type);

export const getRequestService = (boundedContext: BoundedContextName, serviceName: string) => {
  const serviceUrl = getServiceUrl(boundedContext, serviceName);

  return async (request: Record<string, any>): Promise<any> => {
    const attribution = determineAttribution();
    const experiments = retrieveExperiments();

    const token = await firebase.auth().currentUser?.getIdToken();
    const resp = await axios.post(
      serviceUrl,
      {
        ...request,
        attribution,
        experiments,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    );
    return resp;
  };
};

export const getServiceUrl = (boundedContext: BoundedContextName, serviceName: string) => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
  const isLocal = process.env.REACT_APP_IS_LOCAL_FIREBASE || process.env.NEXT_PUBLIC_IS_LOCAL_FIREBASE;
  return isLocal === 'true'
    ? `http://localhost:5001/castiron-localdev/us-central1/${boundedContext}-${serviceName}`
    : `https://us-central1-${projectId}.cloudfunctions.net/${boundedContext}-${serviceName}`;
};
