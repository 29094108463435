import React from 'react';
import { Grid, GridProps, Hidden, makeStyles, Theme } from '@material-ui/core';
import { HeaderText, SvgIcon, Text, CheckmarkSolidIcon, StarSolidIcon } from '@castiron/components';
import { AddToShopButton } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    marginBottom: 9,
  },
  sectionHeaderIcon: {
    color: theme.branding.gray[600],
    transform: 'scale(.8)',
    marginRight: 8,
  },
  sectionTitle: {
    marginRight: 21,
  },
  addToShopButton: {
    minWidth: 110,
  },
  sectionContainer: {
    marginTop: 40,
  },
  checkmarkIcon: {
    color: theme.branding.blue.primary,
    transform: 'scale(.8)',
    marginRight: 10,
  },
  resourcesContainer: {
    marginTop: 16,
  },
  resource: {
    marginBottom: 5,
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Props = Override<
  GridProps,
  {
    communityPackageId: string;
    added?: boolean;
  }
>;

// Quick add section (checkmarks)
const QuickAddSection: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Grid {...props}>
      {/* Header */}
      <Grid container alignItems="center" wrap="nowrap" className={classes.sectionHeader}>
        <StarSolidIcon className={classes.sectionHeaderIcon} />

        {/* Title */}
        <HeaderText presetFont="heading--xs" lineHeight={24} className={classes.sectionTitle}>
          Customizable quick add product details:
        </HeaderText>

        {/* AddToShopButton */}
        {!props.added && (
          <Hidden smDown>
            <AddToShopButton
              variant="outlined"
              size="small"
              communityPackageId={props.communityPackageId}
              className={classes.addToShopButton}
            />
          </Hidden>
        )}
      </Grid>

      {/* Checkmark list of resources */}
      <Grid className={classes.resourcesContainer}>
        {[
          'Hi-res product image',
          'Product name and description',
          'Recommended sale price (based on material costs and other similar products)',
          'Allergen info',
        ].map(label => (
          <Grid container wrap="nowrap" className={classes.resource}>
            <CheckmarkSolidIcon className={classes.checkmarkIcon} />

            {/* Label */}
            <Text presetFont="txt--s">{label}</Text>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default QuickAddSection;
