import _ from 'lodash';
import { Transaction, backendStateToFrontendState } from "@castiron/domain";

export type QuoteAction = 'archive' | 'cancel' | 'resendQuote' | 'save' | 'sendMessage' | 'sendQuote' | 'unarchive' | 'view';

export const availableQuoteActions = (tx: Transaction): QuoteAction[] => {
  if (tx && tx.order?.type === 'custom') {
    switch (backendStateToFrontendState(tx, 'quote')) {
      case 'new':
      case 'draft':
        return ['sendQuote', 'sendMessage', 'cancel', 'archive', 'save'];
      case 'pending':
        return ['resendQuote', 'sendMessage', 'cancel', 'archive', 'save'];
      case 'canceled':
        return ['sendMessage', 'archive'];
      case 'archived':
        return ['sendMessage', 'unarchive'];
      case 'paid':
        return ['sendMessage', 'view', 'archive'];
    }
  }

  return [];
};

export const quoteNextSteps = (tx: Transaction): string => {
  if (tx) {
    switch (backendStateToFrontendState(tx, 'quote')) {
      case 'new':
        return 'Review the request from your customer, add any missing information, then Send for your customer to review and complete payment.';
      case 'draft':
        return 'Complete the details of the quote, then Send for your customer to review and complete payment.';
      case 'pending':
        return 'Wait for your customer to accept and pay or decline this quote.';
      default:
        return null;
    }
  }
  return null;
};

export const validateQuote = (tx: Transaction): string[] => {
  const order = tx?.order
  const product = order?.items.length > 0 && order?.items[0];
  const missingProduct = product?.title ? null : 'Product';
  /* zero is a valid price */
  const missingProductPrice = missingProduct || product.price !== undefined ? null : 'Product price';
  const missingProductQty = missingProduct || product.quantity ? null : 'Product quantity';
  const missingCustomer = tx?.customerObj ? null : 'Customer';
  const fulfillmentOption = order?.fulfillmentOption;
  const missingfulfillment = fulfillmentOption && fulfillmentOption.type ? null : 'Fulfillment method';
  const missingfulfillmentDate = fulfillmentOption && fulfillmentOption.date ? null : 'Fulfillment date';
  const missingShippingAddress = (fulfillmentOption?.type !== 'shipping' || tx?.shippingInfo?.address) ? null : 'Shipping address';
  const missingDeliveryAddress = (fulfillmentOption?.type !== 'delivery' || tx?.shippingInfo?.address) ? null : 'Delivery address';
  const missingPickupAddress = (fulfillmentOption?.type !== 'pickup' || tx?.shippingInfo?.address) ? null : 'Pickup address';
  const missingEmail = tx?.customerObj?.email ? null : 'Email';
  return [
    missingProduct,
    missingProductPrice,
    missingProductQty,
    missingCustomer,
    missingfulfillment,
    missingfulfillmentDate,
    missingShippingAddress,
    missingDeliveryAddress,
    missingPickupAddress,
    missingEmail,
  ].filter(err => !!err);
};

export const quoteSendErrors = {
  missingRequired: 'Please fill out all required information before sending to your customer: ',
  illegalTotal: 'Quote subtotal must be $0.50 or greater before sending to your customer',
  missingEmail: 'Please enter a valid email address to save customer',
};

export const prepareQuoteSegmentData = (transaction: Transaction) => {
  return {
    transaction: { id: transaction.id },
    order: { orderNumber: transaction.order.orderNumber },
    product: { id: _.get(transaction, 'order.items[0].id') },
    customer: { id: transaction.customerObj?.id, email: transaction.customerObj?.email },
  };
};
