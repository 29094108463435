import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import moment, { Moment } from "moment-timezone";
import DateInput from '../DateInput';
import { BaseInputProps } from '../InputWrapper';
import TimeInput from '../TimeInput';

interface Props extends BaseInputProps {
  variant?: 'row' | 'two-row';
  startTimeName: string;
  endTimeName: string;
  timeZone?: string;
  disablePast?: boolean;
  startTimeError?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  endTimeError?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
};

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    /* make it play nice with the custom input labels */
    marginTop: 0,
  },
  /* bit of a hack, but it works */
  error: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      }
    },
  },
  /* struggling to figure out how to position this more elegantly */
  hyphen: {
    marginTop: '50px',
    marginLeft: '4px',
  },
}));

const DateTimeRangeInput: React.FC<Props> = (props: Props) => {
  const {
    variant = 'row',
    label,
    startTimeName,
    endTimeName,
    timeZone,
    disablePast = true,
    startTimeError,
    endTimeError
  } = props;

  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const startTime = _.get(values, startTimeName);
  const endTime = _.get(values, endTimeName);

  const [startDate, setStartDate] = useState(startTime ? moment.unix(startTime).tz(timeZone) : null);

  const onDateChange = async (date: Moment) => {
    setStartDate(date);

    const updateTime = async (name: string, defaultHour: number) => {
      const newTime = moment(date).tz(timeZone, true).hour(defaultHour).minute(0).second(0).millisecond(0);
      const existingTime = _.get(values, name);
      if (existingTime) {
        const existingTimeMoment = moment.unix(existingTime);
        if (timeZone) {
          existingTimeMoment.tz(timeZone);
        }
        newTime.hour(existingTimeMoment.hour()).minute(existingTimeMoment.minute());
      }
      /* await here to ensure downstream validation happens with new values */
      await setFieldValue(name, newTime.unix());
    };

    /* await here to ensure downstream validation happens with new values */
    await updateTime(startTimeName, 12);
    updateTime(endTimeName, 13);
  };

  const onStartTimeChange = async (date: Moment) => {
    const newStartTimeDate = moment(date);
    if (timeZone) { newStartTimeDate.tz(timeZone); }
    const newStartTimeUnix = newStartTimeDate.unix();
    const diff = newStartTimeUnix - startTime;
    /* if the diff is positive, then we moved forward and we want to adjust the end time accordingly */
    if (diff > 0) {
      let endDate;
      if (endTime === 0) {
        endDate = moment(newStartTimeDate).add(30, 'minute');
      } else {
        endDate = moment.unix(endTime);
        if (timeZone) { endDate.tz(timeZone); }
        endDate.add(diff, 'second');
        if (endDate.day() !== date.day()) {
          endDate.hour(0).minute(0).second(0).millisecond(0).subtract(5, 'minute');
        }
      }
      /* await here to ensure downstream validation happens with new values */
      await setFieldValue(endTimeName, endDate.unix());
    }
    setFieldValue(startTimeName, newStartTimeUnix);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={variant === 'row' ? 6 : 12}>
        <DateInput
          label={label}
          placeholder='Add a Date'
          selectedDate={startDate}
          onChange={onDateChange}
          disablePast={disablePast}
        />
      </Grid>
      <Grid item xs={12} sm={variant === 'row' ? 6 : 12}>
        <Grid container spacing={1} justify='space-between'>
          <Grid item xs={5}>
            <TimeInput
              name={startTimeName}
              label='Start Time'
              placeholder='Start Time'
              disabled={!startTime}
              onChange={onStartTimeChange}
              error={startTimeError}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant='body1' className={classes.hyphen}>-</Typography>
          </Grid>
          <Grid item xs={5}>
            <TimeInput name={endTimeName} label='End Time' placeholder='End Time' error={endTimeError} disabled={!endTime} />
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default DateTimeRangeInput;