import { Attribution, getSource, Session } from "@castiron/domain";
import { SESSION_ATTRIBUTION_KEY } from "./index";
import axios from "axios";
import { removeEmpty } from "../removeEmpty";

const getSessionService = async () => {
  const resp = await axios.get(
    '/session/get',
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
  return resp.data as Session;
}

export const propagateAttribution = async () => {
  // @ts-ignore
  if (!process.browser) return;

  let attribution: Attribution;
  try {
    const attributionJson = sessionStorage.getItem(SESSION_ATTRIBUTION_KEY);
    if (attributionJson) {
      attribution = JSON.parse(attributionJson);
    } else {

      const session = await getSessionService();

      if (session.attribution) {
        attribution = session.attribution;
        sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify(attribution));
      } else {
        const referrer = window.document.referrer;
        const params = new URLSearchParams(window.location.search);
        attribution = removeEmpty({
          referringSourceUrl: referrer,
          source: getSource((referrer)),
          utmParams: {
            campaign: params.get('utm_campaign'),
            source: params.get('utm_source'),
            term: params.get('utm_term'),
            medium: params.get('utm_medium'),
            content: params.get('utm_content')
          }
        } as Attribution);
        sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify(attribution));
      }
    }

    return attribution;
  } catch (err) {
    console.error('Error getting propagated attribution', err);
    return undefined;
  }
}