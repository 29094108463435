import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import StickyFooter, { FooterProps } from './Footer/StickyFooter';
import Footer from './Footer';
import Header, { HeaderProps } from './Header/Header';
import SideDrawer from './SideDrawer/SideDrawer';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useAppSelector } from "../../hooks";

export interface LayoutPageProps {
  setPageTitle: (pageTitle: string) => void;
  setBackLocation?: (location: boolean | string) => void;
  setLongTitle: (longTitle: boolean) => void;
  setOptFooterFormat?: (format: string) => void;
  setHeaderCTAs?: (ctas: ReactNode[]) => void;
  setFooterCTAs?: (ctas: ReactNode[]) => void;
  setDisplayNav?: (boolean) => void;
}

interface Props extends HeaderProps, FooterProps {
  children: ReactNode;
  displayNav: boolean;
}

const Layout: React.FC<Props> = (props: Props) => {
  const { pageTitle, backLocation, longTitle, optFooterFormat, headerCTAs, footerCTAs, displayNav } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isContentGenerated, setIsContentGenerated] = useState<boolean>();
  const [showContentToast, setShowContentToast] = useState<boolean>(false);

  const { account } = useAppSelector(state => ({
    account: state.shops.account
  }));

  useEffect(() => {
    if (account) {
      console.debug('Content Status', {
        account: account.config?.wasShopGenerated,
        state: isContentGenerated
      });
      if (account.config?.wasShopGenerated && isContentGenerated === false) {
        console.debug('Showing AI Toast!')
        setShowContentToast(true);
      }
      setIsContentGenerated(!!account.config?.wasShopGenerated);
    }
  }, [account]);

  const classes = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    hasStickyFooter: {
      marginBottom: 120,
    },
    innerContainer: {
      flexGrow: 1,
      maxWidth: 'fill-available',
    },
    mainContentArea: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 24,
        paddingLeft: 56,
        paddingRight: 35,
        minHeight: 'calc(100vh - 200px)',
        maxWidth: '1300px',
        margin: '0 auto',
      },
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down(1575)]: {
        maxWidth: 'calc(100vw - 300px)',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 180px)',
        maxWidth: '100%',
      },
    },
  }))();

  return (
    <>
      <Snackbar open={showContentToast} style={{zIndex: 2000}} autoHideDuration={5000} anchorOrigin={{horizontal: 'right', vertical: 'top'}} onClose={() => setShowContentToast(false)}>
        <Alert severity="info">Your shop content has been generated!</Alert>
      </Snackbar>
      {displayNav && (
        <div className={classes.root}>
          <>
            <div style={{ display: 'flex', width: '100%' }}>
              <>
                <SideDrawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
                <div className={classes.innerContainer}>
                  <Header
                    pageTitle={pageTitle}
                    backLocation={backLocation}
                    headerCTAs={headerCTAs}
                    setDrawerOpen={setDrawerOpen}
                    longTitle={longTitle}
                  />
                  <div className={classes.mainContentArea}>{props.children}</div>
                  <Footer stickyFooterClass={!_.isEmpty(footerCTAs) && classes.hasStickyFooter} />
                  {!_.isEmpty(footerCTAs) && <StickyFooter optFooterFormat={optFooterFormat} footerCTAs={footerCTAs} />}
                </div>
              </>
            </div>
          </>
        </div>
      )}
      {!displayNav && <div>{props.children}</div>}
    </>
  );
};

export default Layout;
