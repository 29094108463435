import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';
import firebase from 'firebase/compat/app';
import * as yup from 'yup';
import _ from 'lodash';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { OnboardingQuestions, TaxonomyCategory } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createShopAction, setFromOnboarding, setIsOnboarding } from '../../../store/reducers/shops';
import { getTaxonomyAction } from '../../../store/reducers/taxonomy';
import domainAuth from '../../Auth/domainAuth';
import SignUpSpinner from '../../SignUpSpinner';
import Spinner from '../../Spinner';
import OnboardingWrapper from './OnboardingWrapper';
import OnboardingEmailCapture from './OnboardingEmailCapture';
import OnboardingCategories from './OnboardingCategories';
import HowToSell from './HowToSell';
import OrderVolume from './OrderVolume';
import OnboardingQualificationForm from './OnboardingQualificationForm';
import MigrationOffer from './MigrationOffer';
import OnboardingMigrationSubmitted from './OnboardingMigrationSubmitted';
import Signup from './Signup';
import { trackHubSpotContactPage } from '../../../lib/trackHubSpotContactEvent';
import { useParams } from 'react-router-dom';
import momentTimezone from 'moment-timezone';

interface MigrationInformation {
  existingShopUrl: string;
  instagramUrl: string;
  facebookUrl: string;
}

interface SignupInformation {
  password: string;
}

export interface OnboardingInfo {
  businessName: string;
  name: string;
  categories: TaxonomyCategory[];
  email: string;
  salesMethod: string[];
  orderVolume: string;
  mobilePhone?: string;
  migrationInformation: MigrationInformation;
  signup: SignupInformation;
}

const categorySchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string(),
  imageUrl: yup.string(),
  urlParam: yup.string(),
  subcategories: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      color: yup.string(),
      imageUrl: yup.string(),
      urlParam: yup.string(),
    }),
  ),
});

const migrationInformation = yup
  .object({
    existingShopUrl: yup.string(),
    instagramUrl: yup.string(),
    facebookUrl: yup.string(),
  })
  .test(
    'one-of-urls',
    'Please provide at least one URL.',
    ({ existingShopUrl, instagramUrl, facebookUrl }) => !!existingShopUrl || !!instagramUrl || !!facebookUrl,
  );

const signupSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
});

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;

const onboardingSchema = yup.object().shape({
  businessName: yup.string().required('Shop Name is required'),
  categories: yup
    .array()
    .of(categorySchema)
    .required('Please select at least one option.'),
  email: yup
    .string()
    .email('Please enter an email address to get started.')
    .required('Please enter an email address to get started.'),
  mobilePhone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  name: yup.string().required('Please enter a name'),
  orderVolume: yup.string().required('Please select an option.'),
  migrationInformation,
  signup: signupSchema,
});

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',

    '& button': {
      '& span': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.branding.blue.primary,
  },
  businessStageButton: {
    borderRadius: 20,
    color: theme.branding.gray[800],
    margin: '8px 0',
  },
  businessStageContainer: {
    margin: '16px 0 32px',
  },
  categoryInput: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0 32px',
    padding: '12px 16px',
  },
  categoryPage: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    margin: '0 auto',

    '& h2': {
      marginBottom: 32,
      padding: '0 16px',
    },

    '& button': {
      '& span': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '.75rem',
    marginTop: '-28px',
    marginBottom: 32,
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  form: {
    marginBottom: 32,
  },
  main: {
    margin: '108px auto 0',
    '& h2': {
      marginBottom: 32,
    },
  },
  subCategory: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '12px 0',
    padding: 4,

    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },

    '& label': {
      marginBottom: 0,
    },
  },
  subCategoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '16px 0 32px',
    width: '100%',
  },
  subCategoryHeader: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
      marginBottom: '-10px',
    },
  },
  submitButton: {
    backgroundColor: '#000',
    color: '#fff',
    marginTop: 32,
  },
}));

type Step =
  | 'email'
  | 'category'
  | 'howtosell'
  | 'qualifications'
  | 'migrate'
  | 'migration-submitted'
  | 'signup'
  | 'orderVolume';

const ICP_CATEGORIES = ['Cookies', 'Cakes', 'Cupcakes', 'Sweet Pastries', 'Charcuterie Boards', 'Other Desserts'];
const ICP_BLACKLIST = ['Event Catering', 'Ready to Eat Meals', 'Meal Prep'];

interface Params {
  step?: string;
}

const Onboarding: React.FC = () => {
  console.debug('Onboarding V3');
  const formRef = useRef() as any;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent, trackUser } = useTracking();

  const [showSpinner, setShowSpinner] = useState<{ show: boolean; message?: string }>({ show: false });
  const [shopCompleted, setShopCompleted] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [startSignUpSpinner, setStartSignUpSpinner] = useState(false);
  const [endSignUpSpinner, setEndSignUpSpinner] = useState(false);
  const [categories, setCategories] = useState<TaxonomyCategory[]>();
  const [urlCategory, setUrlCategory] = useState<TaxonomyCategory[]>();
  const [localStorageValues, setLocalStorageValues] = useState<OnboardingInfo>();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlEmail = urlParams.get('email');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { step } = useParams<Params>();

  const progressStep = (values: OnboardingInfo) => {
    switch (step) {
      case undefined:
      case 'email':
        history.push('/signup/category');
        break;
      case 'category':
        history.push('/signup/howtosell');
        break;
      case 'howtosell':
        history.push('/signup/orderVolume');
        break;
      case 'orderVolume':
        history.push('/signup/qualifications');
        break;
      case 'qualifications':
        history.push('/signup/signup');
        break;
      case 'migrate':
        if (values.businessName && values.name) {
          history.push('/signup/signup');
        } else {
          history.push('/signup/qualifications');
        }
        break;
      default:
      /* do nothing */
    }
  };

  const createShop = async (
    email,
    artisanName,
    shopName,
    artisanCategory,
    onboardingQuestions: OnboardingQuestions,
    user?,
  ) => {
    trackEvent('Shop Login Created', {
      user: {
        id: user?.uid,
        email,
        name: artisanName,
      },
    });

    const nameParts = artisanName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : undefined;

    const shopResponse = await dispatch(
      createShopAction({
        businessName: shopName,
        email: email,
        firstName,
        lastName,
        artisanCategory,
        tags: ['ICPOnboarding'],
        onboardingQuestions,
        socialMedia: {
          faceBookLink: onboardingQuestions.migrationInformation.facebookUrl,
          instagramLink: onboardingQuestions.migrationInformation.instagramUrl,
        },
        timeZone: momentTimezone.tz.guess() || 'America/Chicago',
      }),
    );
    return shopResponse.payload;
  };

  const onSubmit = async (values, socialUser) => {
    switch (step) {
      case 'migrate':
        const migrationTx = Sentry.startTransaction({
          op: 'submit',
          name: 'Migration Request',
          tags: {
            transactionType: 'business',
          },
        });
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setSpan(migrationTx);
          scope.setUser({
            email: values.email,
          });
        });
        setShowSpinner({ show: true });
        try {
          const artisanCategory = {
            name: values.categories[0].name,
            subcategories: values.categories.map(subcategory => subcategory.name),
          };
          const { shop, account } = await createShop(values.email, values.name, values.businessName, artisanCategory, {
            orderVolume: values.orderVolume,
            salesMethod: values.salesMethod,
            migrationInformation: values.migrationInformation,
            categories: values.categories.map(c => c.name),
            contactOptions: {
              mobilePhone: values.mobilePhone,
              instagramUrl: values.migrationInformation.instagramUrl,
              facebookUrl: values.migrationInformation.facebookUrl,
            },
          });
          /* we want to submit for migration, emit event to get data into hubspot */
          trackEvent('Onboarding Migration Requested', {
            shop,
            account,
          });
          const nameParts = values.name.split(' ');
          const firstName = nameParts.shift();
          const lastName = nameParts.length > 0 ? nameParts.join(' ') : '';

          trackHubSpotContactPage(
            {
              email: values.email,
              facebook_account: values.migrationInformation.facebook_account,
              instagram_account: values.migrationInformation.instagram_account,
              existing_site_url__migration_: values.migrationInformation.existingShopUrl,
              migration: true,
              firstname: firstName,
              lastname: lastName,
            },
            '/signup/migration',
          );
          migrationTx.finish();
          history.push('/signup/migration-submitted');
        } catch (err) {
          console.error('Failed to begin shop migration', err);
        }
        setShowSpinner({ show: false, message: 'Submitting your migration request . . .' });
        break;
      case 'signup':
        const createTx = Sentry.startTransaction({
          op: 'submit',
          name: 'Create Shop Request',
          tags: {
            transactionType: 'business',
          },
        });
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setSpan(createTx);
          scope.setUser({
            email: values.email,
          });
        });
        setStartSignUpSpinner(true);
        try {
          let user;
          if (!socialUser?.uid) {
            const span = createTx.startChild({
              op: 'auth-create',
              description: 'Create Auth Account',
            });
            console.debug('Creating Auth Account');
            const result = await firebase.auth().createUserWithEmailAndPassword(values.email, values.signup.password);
            await domainAuth();
            user = result.user;
            await user.sendEmailVerification();
            await user.updateProfile({
              displayName: values.name,
            });
            span.finish();
          } else {
            user = socialUser;
          }

          const shop = await shopRepository.get(user.uid);
          if (!shop) {
            const span = createTx.startChild({
              op: 'shop-create',
              description: 'Create Shop',
            });
            trackUser(user.uid);

            const artisanCategory = {
              name: values.categories[0].name,
              subcategories: values.categories.map(subcategory => subcategory.name),
            };

            const { shop } = await createShop(
              values.email,
              values.name,
              values.businessName,
              artisanCategory,
              {
                orderVolume: values.orderVolume,
                salesMethod: values.salesMethod,
                migrationInformation: values.migrationInformation,
                categories: values.categories.map(c => c.name),
                contactOptions: {
                  mobilePhone: values.mobilePhone,
                  instagramUrl: values.migrationInformation.instagramUrl,
                  facebookUrl: values.migrationInformation.facebookUrl,
                },
              },
              user,
            );

            const nameParts = values.name.split(' ');
            const firstName = nameParts.shift();
            const lastName = nameParts.length > 0 ? nameParts.join(' ') : '';

            trackHubSpotContactPage(
              {
                email: values.email,
                migration: false,
                firstname: firstName,
                lastname: lastName,
              },
              '/signup/create',
            );
            span.finish();
            createTx.finish();
            setShopCompleted(true);
          } else {
            setShopCompleted(true);
            await dispatch(setIsOnboarding(false));
            history.push('/');
            setStartSignUpSpinner(false);
          }
        } catch (err) {
          console.error('Error Setting up Email User', err);
          if (err.code === 'auth/email-already-in-use') {
            history.push('/signup/account/existing', {
              existingAccountEmail: values.email,
            });
          }
          setStartSignUpSpinner(false);
        }
        break;
    }
  };

  const { taxonomy } = useAppSelector(state => ({
    taxonomy: state.taxonomy.taxonomy,
  }));

  useEffect(() => {
    setShowSpinner({ show: true });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(getTaxonomyAction());
    dispatch(setIsOnboarding(true));
    dispatch(setFromOnboarding(true));

    if (urlEmail) {
      /* TODO: determine page based on local storage values */
      history.push('/signup/category');
    }

    // check local storage for values from a previous session
    const locStorageValues = window.localStorage.getItem('castironOnboardingForm');
    if (locStorageValues) {
      setLocalStorageValues(JSON.parse(locStorageValues));
    }
  }, [formRef]);

  useEffect(() => {
    if (!categories) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const urlCategory = urlParams.get('category');

      if (urlCategory && taxonomy) {
        const genericUrlCategory = urlCategory.split('-')[0];
        const category = taxonomy.categories.find(category => category.urlParam === genericUrlCategory);
        if (category) {
          const subCategory = category.subcategories.find(subCategory => subCategory.urlParam === urlCategory);
          setUrlCategory([subCategory]);
        }
      }
    }
    setShowSpinner({ show: false });
  }, [taxonomy]);

  useEffect(() => {
    if (startSignUpSpinner) {
      if (seconds > 6) {
        if (shopCompleted) {
          setEndSignUpSpinner(true);
        }
        if (seconds > 15) {
          setStartSignUpSpinner(false);
        }
      }
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startSignUpSpinner, seconds]);

  useEffect(() => {
    if (endSignUpSpinner) {
      const timer = setTimeout(() => {
        dispatch(setIsOnboarding(false));
        history.push('/');
        setStartSignUpSpinner(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [endSignUpSpinner]);

  const initialValues = {
    businessName: localStorageValues?.businessName || '',
    name: localStorageValues?.name || '',
    categories: categories ? categories : localStorageValues?.categories || [],
    email: urlEmail ? urlEmail : localStorageValues?.email || '',
    salesMethod: localStorageValues?.salesMethod || [],
    orderVolume: localStorageValues?.orderVolume || '',
    mobilePhone: localStorageValues?.mobilePhone || '',
    migrationInformation: localStorageValues?.migrationInformation || {
      existingShopUrl: '',
      instagramUrl: '',
      facebookUrl: '',
    },
    signup: localStorageValues?.signup || {
      password: '',
    },
  };

  const handleCategoryChange = (categories: TaxonomyCategory[], values, setFieldValue) => {
    setCategories(categories);
    setFieldValue('categories', categories);
    window.localStorage.setItem('castironOnboardingForm', JSON.stringify(values));
    progressStep(values);
  };

  let schema = onboardingSchema;
  if (step === 'signup') {
    schema = onboardingSchema.omit(['migrationInformation']);
  } else if (step === 'migrate') {
    schema = onboardingSchema.omit(['signup']);
  }

  console.debug(`Step: [${step}]`);

  return (
    <>
      <Formik onSubmit={onSubmit} validationSchema={schema} initialValues={initialValues} innerRef={formRef}>
        {({ setFieldValue, values }: FormikProps<OnboardingInfo>) => (
          <OnboardingWrapper includeBackgroundImages={!isMobile || step === 'email'} step={step}>
            <Spinner show={showSpinner.show} size="fullscreen" label={showSpinner.message} />
            <SignUpSpinner start={startSignUpSpinner} end={endSignUpSpinner} />
            <Form className={classes.form}>
              {(!step || step === 'email') && <OnboardingEmailCapture progressStep={progressStep} />}
              {step === 'category' && (
                <OnboardingCategories
                  urlCategory={urlCategory}
                  setCategories={categories => handleCategoryChange(categories, values, setFieldValue)}
                />
              )}
              {step === 'howtosell' && <HowToSell progressStep={progressStep} />}
              {step === 'orderVolume' && <OrderVolume progressStep={progressStep} />}
              {step === 'qualifications' && <OnboardingQualificationForm progressStep={progressStep} />}
              {step === 'migrate' && <MigrationOffer progressStep={progressStep} />}
              {step === 'migration-submitted' && <OnboardingMigrationSubmitted />}
              {step === 'signup' && (
                <Signup
                  socialSubmit={user => onSubmit(values, user)}
                  showSpinner={() => setShowSpinner({ show: true, message: 'Creating your shop . . .' })}
                />
              )}
            </Form>
          </OnboardingWrapper>
        )}
      </Formik>
    </>
  );
};

export default Onboarding;
