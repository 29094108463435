import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Grid, Hidden, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import { Button, MultipleSelect } from '@castiron/components';
import { backendStateToFrontendState } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCustomersAction } from '../../../store/reducers/customers';
import { listCustomTransactionsAction, listTransactionsAction } from '../../../store/reducers/transactions';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';
import { prepareQuoteSegmentData } from '../QuoteUtils';
import { LayoutPageProps } from '../../Layout';
import AvatarMenu from '../../Layout/AvatarMenu';
import Spinner from '../../Spinner';
import ResourceGuide from '../../ResourceGuide';
import SubscriptionBanner from '../../SubscriptionBanner';
import { EmptyQuotesIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../../EmptyPage';

const createCustomOrderService = getService('orders', 'createcustomorderv2', { version: 2 });

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    paddingLeft: '8px',
    '& button': {
      padding: '8px',
    },
  },
  ctaContainer: {
    paddingBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
  },
  icon: {
    height: 104,
    width: 98,
  },
  separator: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      /* was #EEEEEE, went with closest I could find */
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
    },
  },
  subscriptionBanner: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      marginBottom: 0,
    },
    marginTop: 0,
    marginBottom: 16,
  },
  viewContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
}));

const QuotesView: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { trackEvent } = useTracking();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [showSpinner, setShowSpinner] = useState(false);
  const [isQuotesEmpty, setIsQuotesEmpty] = useState(false);
  const [filters, setFilters] = useState(['New', 'Pending', 'Canceled', 'Draft', 'Paid']);

  const filterOptions = ['New', 'Pending', 'Canceled', 'Draft', 'Paid', 'Archived'];

  const { shop, transactions, isTransactionsLoading, isCustomerLoading, modal } = useAppSelector(state => ({
    shop: state.shops.shop,
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
    isCustomerLoading: state.customers.loading,
    modal: state.modal,
  }));

  const filteredQuotes =
    transactions?.filter(tx => {
      const isCustom = tx.order.type === 'custom';
      const frontEndState = backendStateToFrontendState(tx, 'quote')?.toLowerCase();
      const isInFilteredStatus = filters.map(status => status.toLowerCase()).includes(frontEndState);
      return isCustom && isInFilteredStatus;
    }) || [];

  const onFilterChange = (event, value) => {
    setFilters(value);
    trackEvent('Filter Quote Clicked', { Filters: value });
  };

  const createNewQuote = async () => {
    setShowSpinner(true);
    const body = {
      id: shop.id,
      status: 'proposed',
      origination: 'artisan',
    };
    try {
      const createCustomOrderResponse = await createCustomOrderService(body);
      console.debug('Create custom product order response: ', createCustomOrderResponse);
      const { id } = createCustomOrderResponse;
      setShowSpinner(false);
      trackEvent('Create Quote Clicked', {
        ...prepareQuoteSegmentData(createCustomOrderResponse),
      });
      history.push('/quotes/edit/' + id);
    } catch (e) {
      console.error('Error creating custom product order: ', e);
      trackEvent('Create Quote Failed', { errorMsg: e });
      setShowSpinner(false);
    }
  };

  const newQuoteButton = (
    <Button variant="contained" fullWidth onClick={() => createNewQuote()}>
      New Quote
    </Button>
  );

  const OverrideTooltip = withStyles({
    tooltip: {
      padding: 16,
      background: theme.branding.gray[800],
      borderRadius: 12,
      fontSize: 14,
      color: theme.branding.ghostWhite,
      width: 212,
    },
    arrow: {
      color: theme.branding.gray[800],
    },
  })(Tooltip);

  const disabledQuoteButton = (
    <OverrideTooltip title="To send a new quote, complete your setup guide and launch your shop." arrow>
      <span>
        <Button variant="contained" fullWidth onClick={() => createNewQuote()} disabled={true}>
          New Quote
        </Button>
      </span>
    </OverrideTooltip>
  );

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Quotes Page';

  const resourceGuideProps = {
    id: 'quotes-video-guide',
    link: 'https://castiron.helpscoutdocs.com/article/97-the-custom-order-experience-in-castiron',
    title: 'The Custom Quote Process',
    text: 'Accept custom order requests and invoice customers directly from Castiron.',
    videoLength: '3 Mins',
    trackEventTitle: 'Quote Guide Click',
  };

  useEffect(() => {
    const filterString = urlParams.get('filter');
    if (filterString) {
      const filterChoices = filterString.split(',');
      const toFilter = filterChoices.filter(choice => filterOptions.includes(choice));
      setFilters(toFilter);
    }
    setPageTitle('Quotes');
    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([shop?.status === 'prelaunch' ? disabledQuoteButton : newQuoteButton]);
    }
  }, [isMobile, shop]);

  useEffect(() => {
    if (isMobile) {
      shop?.status === 'prelaunch' ? setFooterCTAs([]) : setFooterCTAs([newQuoteButton]);
    } else {
      setFooterCTAs([]);
    }
  }, [isMobile, shop]);

  /* this allows a page refresh to work correctly */
  useEffect(() => {
    if (shop && shop.id) {
      dispatch(listTransactionsAction(shop.id));
      dispatch(listCustomTransactionsAction(shop.id));
      dispatch(getCustomersAction(shop.id));
    }
  }, [dispatch, shop]);

  useEffect(() => {
    if (transactions) {
      const customTransactions = transactions.filter(tx => tx?.order?.type === 'custom');
      setIsQuotesEmpty(customTransactions.length == 0);
    }
  }, [shop, transactions]);

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'cookie',
      title: 'Create a Custom Order Form',
      description: 'Quotes are sent when order forms are submitted.',
      ctaAction: () => window.open('/products/add/custom', '_self').focus(),
    },
    {
      icon: 'article',
      title: 'Read Our Quote Guide',
      description: 'Learn how you can start receiving quotes in Castiron!',
      ctaAction: () =>
        window
          .open('https://castiron.helpscoutdocs.com/article/97-the-custom-order-experience-in-castiron', '_blank')
          .focus(),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Quotes | ${shop ? shop.businessName : ''}`}</title>
      </Helmet>
      <Grid container direction="column" className={classes.viewContainer}>
        {modal.modalType !== 'CHANGE_PLANS_MODAL' && (
          <Spinner show={isTransactionsLoading || isCustomerLoading || showSpinner} />
        )}
        {isVariant && (
          <Grid className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        {isQuotesEmpty ? (
          <EmptyPage
            icon={<EmptyQuotesIcon className={classes.icon} />}
            title="No Quotes to Display"
            description="Get your first quote in Castiron in no time by doing one, 
              or both, of the following:"
            tipsForYou={tipsForYou}
            page="Quotes"
          />
        ) : (
          <>
            <Grid item className={`${classes.separator} ${classes.ctaContainer}`}>
              <Grid container justify="flex-end">
                <Grid item xs={8} md={6}>
                  <MultipleSelect value={filters} onChange={onFilterChange} options={filterOptions} />
                </Grid>
              </Grid>
            </Grid>
            {isMobile ? <MobileContent quotes={filteredQuotes} /> : <DesktopContent quotes={filteredQuotes} />}
          </>
        )}
      </Grid>
    </>
  );
};

export default QuotesView;
