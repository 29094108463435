import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Logo } from '@castiron/components';
import Footer from '../../Layout/Footer';

// Various category images
import LeftImage from '../../../assets/img/cheftools-left.png';
import RightImage from '../../../assets/img/cheftools-right.png';

// Various onboarding images
import OnboardingMobileImage from '../../../assets/img/Onboarding-Mobile-With-Logo.png';
import OnboardingDesktop from '../../../assets/img/Onboarding-Desktop-With-Typography.png';

interface Props {
  includeBackgroundImages?: boolean;
  children: ReactNode;
  step?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  parentContainer: {
    minWidth: '100%',
    height: '100vh',
  },
  desktopImageContainer: {
    backgroundImage: `url(${OnboardingDesktop})`,
    backgroundSize: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundRepeat: 'no-repeat',
  },
  mobileImageContainer: {
    backgroundImage: `url(${OnboardingMobileImage})`,
    backgroundSize: 'cover',
    height: 328,
    width: '100%',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    maxWidth: 524,
  },
  header: {
    margin: '40px 0',
    [theme.breakpoints.down('xs')]: {
      margin: '40px 0 0 0',
    },
  },
  logo: {
    '& path': {
      fill: theme.branding.gray[800], // logo text
    },
    '& #Group-3': {
      fill: theme.branding.gray[800], // logo pan outline
    },
  },
  onboardingContent: {
    backgroundImage: `url(${LeftImage}), url(${RightImage})`,
    backgroundPosition: 'top left, bottom right',
    backgroundSize: '200px 400px, 150px, 300px',
    backgroundRepeat: 'no-repeat, no-repeat',
    minHeight: '100vh',
  },
}));

const OnboardingWrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { includeBackgroundImages, step, children } = props;
  /* default to true if not set, only disable images on explicit false */
  const includeImages = includeBackgroundImages !== false;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/no-helpscout.css" />
      </Helmet>
      {!step &&
        ((!isMobile && (
          <Grid container className={classes.parentContainer}>
            <Grid
              item
              container
              justify="center"
              xs={12}
              sm={4}
              md={6}
              lg={7}
              xl={8}
              style={{ padding: '0px 20px 0px 20px' }}
            >
              <Grid className={classes.container}>
                <Grid className={classes.header} item container justify="flex-start">
                  <Logo width={144} className={classes.logo} />
                </Grid>
                {children}
                {step && (
                  <Grid container item justify="center" sm={12}>
                    <Footer />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item className={classes.desktopImageContainer} xs={12} sm={8} md={6} lg={5} xl={4} />
          </Grid>
        )) ||
          (isMobile && (
            <Grid container justify="center">
              <Grid container item className={classes.mobileImageContainer} sm={12} />
              <Grid container item justify="center" style={{ padding: 20 }} sm={12}>
                {children}
              </Grid>
              {step && (
                <Grid container item justify="center" sm={12}>
                  <Footer />
                </Grid>
              )}
            </Grid>
          )))}
      {step && (
        <Grid
          container
          direction="column"
          className={includeImages && classes.onboardingContent}
          alignItems="center"
          justify="center"
        >
          <Grid className={classes.header} item container justify="center">
            <Logo width={144} className={classes.logo} />
          </Grid>
          <Grid item container xs={12} justify="center">
            {children}
          </Grid>
          <Footer />
        </Grid>
      )}
    </>
  );
};

export default OnboardingWrapper;
