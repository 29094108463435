import moment from 'moment';
import Dinero from 'dinero.js';
import { Coupon, EffectiveStatus } from '.';
import {LineItem} from "../order";

const checkCouponValid = (coupon: Coupon, subtotal: number, items: LineItem[]) => {
  const currentTime = moment().unix();
  const totalQuantity = items.map(p => p.quantity).reduce((a, q) => a + q);
  return (
    !!coupon &&
    currentTime > coupon.duration.startDate &&
    (!coupon.duration.endDate || currentTime < coupon.duration.endDate) &&
    (!coupon.minimum ||
      (coupon.minimum.type === 'orderValue' ? subtotal >= coupon.minimum.value : totalQuantity >= coupon.minimum.value))
  );
};

const couponDiscount = (coupon: Coupon, subtotal: number) => {
  if (!coupon) return;

  if (coupon.discount.type === 'amount') {
    return `- ${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')}`;
  }
  const subtractionAmount = Math.round((coupon.discount.value / 100) * subtotal);
  return `- ${Dinero({ amount: subtractionAmount }).toFormat('$0.00')} (${coupon.discount.value}%)`;
};

const getEffectiveStatus = (coupon: Coupon): EffectiveStatus => {
  const today = moment().unix();
  if (coupon.status === 'deleted') {
    return 'deleted';
  }
  if (coupon.duration.startDate > today) {
    return 'scheduled';
  }
  if (coupon.duration.endDate && coupon.duration.endDate < today) {
    return 'expired';
  }

  return 'active';
};

const prepCouponEvent = (coupon: Coupon): any => {
  const eventCoupon = {
    id: coupon.id,
    status: coupon.status,
    createdAt: coupon.createdAt,
    code: coupon.code,
    minimum: coupon.minimum
      ? {
          ...coupon.minimum,
          minimumType: coupon.minimum.type,
        }
      : null,
    discount: coupon.discount
      ? {
          ...coupon.discount,
          discountType: coupon.discount,
        }
      : null,
    duration: coupon.duration,
  };
  eventCoupon.discount && delete eventCoupon.discount.type;
  eventCoupon.minimum && delete eventCoupon.minimum.type;
  return eventCoupon;
};

export default { checkCouponValid, couponDiscount, getEffectiveStatus, prepCouponEvent };
