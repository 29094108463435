import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const SauceOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 10 21" {...props}>
    <path
      d="M3.4 3L4 0H6L6.6 3H8C8.55226 3 9 3.44774 9 4V7C9 7.27554 8.88859 7.525 8.70836 7.70586L9.63204 9.00992C9.87142 9.34788 10 9.75188 10 10.166V19C10 20.1046 9.10454 21 8 21H2C0.89546 21 0 20.1046 0 19V10.166C0 9.75188 0.128594 9.3479 0.36796 9.00992L1.29164 7.70586C1.11141 7.525 1 7.27554 1 7V4C1 3.44774 1.44774 3 2 3H3.4ZM3 5V6H7V5H3ZM6.4658 8H3.5342L1.99998 10.166V19H7.99998V10.166L6.4658 8Z"
      fill="#005FFF"
    />
  </SvgIcon>
);