import React from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CreateOutlined } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  placeholder: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.branding.gray[600],
  },
  input: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    '& input::placeholder': {
      color: theme.branding.gray[600],
      opacity: 1,
    },
  },
}));

const TitleInput: React.FC<Props> = (props: Props) => {
  const { name, placeholder, error } = props;

  const classes = useStyles();

  const formik = useFormikContext();

  return (
    <InputWrapper error={error}>
      <Input
        style={{ margin: '0px' }}
        type='text'
        className={classes.input}
        name={name}
        placeholder={placeholder}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <CreateOutlined className={classes.icon} />
          </InputAdornment>
        }
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={!!error}
      />
    </InputWrapper>
  );
};

export default TitleInput;