import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const BreadIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M23.25 7.23231C23.25 9.63424 19.6616 11.3416 11.9928 11.3416C4.32395 11.3416 0.75 9.63424 0.75 7.23231C0.75 4.52653 3.26769 2.39952 6.9574 1.38666C6.9574 1.38666 7.4783 4.10691 6.36415 6.92846C9.50402 4.78698 8.67926 1.01045 8.67926 1.01045C9.64871 0.851286 10.676 0.764469 11.7323 0.75C11.7323 0.75 12.6728 3.47026 11.3416 6.92846C14.6986 5.07637 13.6133 0.807878 13.6133 0.807878C14.7854 0.894695 15.8995 1.0828 16.9124 1.35772C16.9124 1.35772 17.578 4.0201 16.3191 6.92846C19.1986 5.16318 18.75 1.9799 18.75 1.9799C21.4847 3.12299 23.25 4.97508 23.25 7.23231Z"
      fill="#004CCC"
    />
  </SvgIcon>
);
