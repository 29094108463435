import React, { useState } from 'react';
import { Grid, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircleOutline, ErrorOutline, NotInterested } from '@material-ui/icons';
import moment, { Moment } from 'moment-timezone';
import clsx from 'clsx';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { AvailabilityStatusLabel, Typography } from '@castiron/components';
import { shopRepository } from '../../domain';
import { AvailabilitySubtype } from '@castiron/domain';

interface Props {
  status: AvailabilitySubtype;
  variant?: 'short';
  iconOnly?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  onSetAvailability?: (type: AvailabilitySubtype, start: number, end: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkCircleOutlineIcon: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  container: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.branding.gray[300],
      textDecoration: 'none',
    },
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconContainer: {
    borderRadius: 100,
    height: 32,
    width: 32,
    marginRight: 8,
  },
  errorOutlineIcon: {
    color: theme.branding.orange.primary,
    backgroundColor: theme.branding.yellow.light,
  },
  notInterestedIcon: {
    color: theme.branding.red.primary,
    backgroundColor: theme.branding.red.light,
  },
  popoverContainer: {
    width: 280,
  },
  popoverItem: {
    padding: '6px 8px',
    width: '100%',
  },
}));

const AvailabilityStatusToggle: React.FC<Props> = (props: Props) => {
  const { status, startDate, onSetAvailability, endDate, variant, iconOnly = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { shop, tier } = useAppSelector(state => ({
    shop: state.shops.shop,
    tier: state.shops.account.tier,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const popoverOpen = Boolean(anchorEl);

  const openPopover = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event: React.MouseEvent): void => {
    event.stopPropagation();
    handleClose();
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const availabilityStatuses = {
    available: {
      iconClass: classes.checkCircleOutlineIcon,
      icon: <CheckCircleOutline className={classes.icon} />,
      labelLong: 'Available',
      labelShort: 'Available',
    },
    limited: {
      iconClass: classes.errorOutlineIcon,
      icon: <ErrorOutline className={classes.icon} />,
      labelLong: 'Limited Availability',
      labelShort: 'Limited',
    },
    unavailable: {
      iconClass: classes.notInterestedIcon,
      icon: <NotInterested className={classes.icon} />,
      labelLong: 'Not Available',
      labelShort: 'Not Available',
    },
  };

  const availabilityLabels = ['available', 'limited', 'unavailable'];

  const handleSubmit = async (status, statusLabel) => {
    try {
      setIsSubmitting(true);

      const adjustedStartDate = moment(startDate)
        .tz(timeZone)
        .unix();

      const adjustedEndDate = moment(startDate)
        .tz(timeZone)
        .unix();

      await shop.addCalendarAvailability(status, adjustedStartDate, adjustedEndDate);

      onSetAvailability && onSetAvailability(status, adjustedStartDate, adjustedEndDate);

      /* we are saving dates, let's check and see if a timezone is set in the config */
      if (!shop.config?.timeZone) {
        /* if not, set one */
        await shopRepository.updateProps(shop.id, {
          config: {
            ...shop.config,
            timeZone: defaultTimeZone,
          },
        });
      }

      trackEvent('Calendar Availability Toggle', {
        status,
        adjustedStartDate,
        adjustedEndDate,
        tier,
      });

      setIsSubmitting(false);

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            content: `${moment.unix(adjustedStartDate).format('MM/DD/YYYY')} updated to ${statusLabel}.`,
            show: true,
          },
        }),
      );
      handleClose();
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <a onClick={openPopover}>
      <AvailabilityStatusLabel status={status} variant="short" iconOnly={false} />
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container className={classes.popoverContainer}>
          {availabilityLabels
            .filter(label => label !== status)
            .map(status => (
              <a
                onClick={() => handleSubmit(status, availabilityStatuses[status].labelLong)}
                className={classes.container}
                key={status}
              >
                <Grid container alignItems="center" wrap="nowrap" className={classes.popoverItem}>
                  <Grid
                    container
                    item
                    className={clsx(availabilityStatuses[status].iconClass, classes.iconContainer)}
                    alignContent="center"
                    justify="center"
                  >
                    {availabilityStatuses[status].icon}
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">Change to {availabilityStatuses[status].labelLong}</Typography>
                  </Grid>
                </Grid>
              </a>
            ))}
        </Grid>
      </Popover>
    </a>
  );
};

export default AvailabilityStatusToggle;
