import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { Category, ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { Asset } from '../asset/model';
import { InputField } from '../inputfield/model';
import { FieldFunctions } from '../base/repository';
import { ProductSeoMetadata } from './model';

export const shopVisibleStatuses = ['active', 'inactive'];

export type ProductType = 'standard' | 'custom';
export type ProductStatus = 'active' | 'inactive' | 'deleted' | 'hidden';

export interface Metrics {
  numSold: number;
  totalRevenue: number;
}

export interface BaseProduct extends ShopRelatedDocument<BaseProduct> {
  type: ProductType;
  title: string;
  description?: string;
  category?: Category;
  image?: string;
  imageObj?: Asset;
  allergen?: string[];
  dietary?: string[];
  unlimitedInventory?: boolean;
  variations?: InputField[];
  communityPackageId?: string;
  metrics?: Metrics;
  status: ProductStatus;
  productTemplateId?: string;
  seoMetadata?: ProductSeoMetadata;
}

export interface Product extends BaseProduct {
  type: 'standard';
  inventory: number;
  price: number;
}

export interface CustomProduct extends BaseProduct {
  type: 'custom';
  startingPrice?: number;
  minimum?: string;
  policies?: string;
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export class ProductRepository extends ShopRelatedRepository<BaseProduct> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'products', fieldFunctions);
  }

  public async findActive(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: '==', value: 'active' }],
      orderBy: [{ field: 'title', direction: 'asc' }],
    });
  }

  public async findAll(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
      orderBy: [{ field: 'title', direction: 'asc' }],
    });
  }

  public async findShopVisible(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: 'in', value: shopVisibleStatuses }],
      orderBy: [{ field: 'title', direction: 'asc' }],
    });
  }

  public async listByType<T extends BaseProduct>(shopId: string, type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'type', operator: '==', value: type }],
    }) as Promise<T[]>;
  }

  public async getAsType<T extends BaseProduct>(id: string): Promise<T> {
    return this.get(id) as Promise<T>;
  }

  public async findActiveOfTypeForShop<T extends BaseProduct>(shopId: string, type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'status', operator: '==', value: 'active' },
        { field: 'type', operator: '==', value: type },
      ],
    }) as Promise<T[]>;
  }

  public async findActiveInStock(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'status', operator: '==', value: 'active' },
        { field: 'inventory', operator: '>', value: 0 },
      ],
    });
  }

  public async findWithStatus(shopId: string, status: ProductStatus[]): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: 'in', value: status }],
    });
  }
}
