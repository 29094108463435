import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Typography } from '@castiron/components';
import { BaseProduct } from '@castiron/domain';
import ShareItem from './ShareItem';
import { useHistory } from 'react-router-dom';
import { getProductsAction } from '../../store/reducers/products';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '5em',
    width: 'calc(100vw - 30px)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 360px)',
    },
  },
  headerContainer: {
    marginBottom: '16px',
  },
  header: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
  },
  itemContainer: {
    'overflow-x': 'scroll',
  },
  subheader: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.branding.gray[600],
    marginBottom: '16px',
  },
  title: {
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: 0,
  },
  addProductContainer: {
    backgroundColor: '#F8F8F8',
    borderRadius: '8px',
    marginBottom: '20px',
    marginRight: '20px',
    minWidth: '16em',
    maxWidth: '16em',
    padding: '16px',
  },
  addProductText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '16px',
  },
  addProductButton: {
    backgroundColor: theme.branding.gray[200],
  },
}));

const ProductShare: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, haveLoaded, products } = useAppSelector(state => ({
    haveLoaded: state.products.haveLoaded,
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const [sortedProducts, setSortedProducts] = useState<BaseProduct[]>(products);
  const [shopHashtag, setShopHashtag] = useState<string>();

  useEffect(() => {
    if (shop) setShopHashtag(shop.websiteUrl.replaceAll('-', ''));
  }, [shop]);

  useEffect(() => {
    if (!haveLoaded) {
      dispatch(getProductsAction(shop.id));
    } else {
      const updatedProducts = [];
      const createdProducts = [];
      const remainingProducts = [];

      products.forEach(p => {
        if (p.updatedAt && p.status === 'active') updatedProducts.push(p);
        if (!p.updatedAt && p.createdAt && p.status === 'active') createdProducts.push(p);
        if (!p.createdAt && !p.updatedAt && p.status === 'active') remainingProducts.push(p);
      });

      updatedProducts.sort(function(x, y) {
        return y.updatedAt - x.updatedAt;
      });

      createdProducts.sort(function(x, y) {
        return y.createdAt - x.updatedAt;
      });

      remainingProducts.sort(function(x, y) {
        return y.updatedAt - x.updatedAt;
      });

      const sorted = [...updatedProducts, ...createdProducts, ...remainingProducts];
      if (sorted.length > 10) sorted.length = 10; // mutate array down to 10 items
      setSortedProducts(sorted);
    }
  }, [products]);

  return (
    <Grid className={classes.container} container direction="column">
      <Grid className={classes.headerContainer}>
        <Typography className={classes.header}>Share one of your newest products</Typography>
        <Typography className={classes.subheader}>
          Refresh your feeds with the latest products available in your shop. You'll be able to customize your post
          content before posting.
        </Typography>
      </Grid>
      <Grid className={classes.itemContainer} container direction="row" wrap="nowrap">
        {sortedProducts.map((product, i) => {
          const textOption1 = `Just added to my store: ${product?.title}. #${shopHashtag} #shoplocal #supportlocal`;
          const textOption2 = `Look what I just added to my store! Try my ${product?.title} the next time you place an order. #${shopHashtag} #shoplocal #supportlocal`;
          const productShareText = i % 2 ? textOption1 : textOption2;
          const productShareUrl = `${process.env.REACT_APP_SHOP_URL}${shop.websiteUrl}/product/${product.id}`;

          return (
            <ShareItem
              key={product.id}
              img={product.imageObj?.downloadUrl}
              pinterestImg={product.imageObj?.downloadUrl ? product.imageObj?.downloadUrl : ''}
              shareUrl={productShareUrl}
              text={productShareText}
              itemType="product"
              item={product}
            />
          );
        })}
        {sortedProducts.length < 10 && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.addProductContainer}
          >
            <Typography className={classes.addProductText}>
              Shops with more products can reach a larger audience.
            </Typography>
            <Button
              onClick={() => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                history.push('/products/add');
              }}
              className={classes.addProductButton}
              variant="outlined"
              color="primary"
            >
              Add a product
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductShare;
