import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import OrderViewBox from './OrderViewBox';
import { LineItem } from '@castiron/domain';
import { Box, Container } from '@material-ui/core';
import { Card, PhotoListAndGallery, Typography } from '@castiron/components';

type Props = {
  orderedItems: LineItem[];
};

const useStyles = makeStyles((theme: Theme) => ({
  customOrderDetails: {
    marginTop: '4px',
    minWidth: '100px',
  },
  customDetailsItemContainer: {
    display: 'flex',
    gap: 12,
    padding: '0 8px',
  },
  itemBox: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    alignItems: 'center',
  },
  itemDetails: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  variationItem: {
    color: theme.branding.gray[700],
  },
  variationLabel: {
    color: theme.branding.gray[800],
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    marginBottom: '1em',
  },
  selection: {
    marginTop: '4px',
    minWidth: '100px',
  },
  selectionValues: {
    marginTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
}));

const QuoteRequestDetailsBox: React.FC<Props> = (props: Props) => {
  const { orderedItems } = props;
  const classes = useStyles();

  return (
    <Card title="Original Request Details" removeChildPadding>
      {orderedItems.length > 0 &&
        orderedItems.map((item, index) => (
          <Container key={item.id} className={classes.itemBox} style={index === orderedItems.length - 1 ? {borderBottom: 'none'} : {}}>
            <Container className={classes.customDetailsItemContainer}>
              <div className={classes.itemDetails}>
                {item.selections &&
                  item.selections?.map(s => (
                    <Box key={s.inputFieldId} className={classes.selectionContainer}>
                      <Box className={classes.selection}>
                        <Typography className={classes.variationLabel} variant='subtitle2'>{s.inputFieldName}</Typography>
                      </Box>
                      <Box className={classes.selectionValues}>
                        {s.selectedValues.map(v => (
                          <Box key={v.id} className={classes.innerContainer}>
                            <Typography className={classes.variationItem} variant='body1'>{v.name}</Typography>
                          </Box>
                        ))}
                        {s.selectedAssets && <PhotoListAndGallery images={ s.selectedAssets } />}
                      </Box>
                    </Box>
                  ))}
              </div>
            </Container>
          </Container>
        ))}
    </Card>
  );
};

export default QuoteRequestDetailsBox;
