import FirebaseApp from '../../config/initFirebase';
import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import 'firebase/compat/auth';

async function loginWithProvider(provider, callback, onExistingAccount) {
  try {
    const result = await FirebaseApp.auth().signInWithPopup(provider);

    console.debug('loginWithProvider Result: ', result);
    /** type {firebase.auth.OAuthCredential} */
    const credential = result.credential; //as firebase.auth.OAuthCredential;

    // This gives you a Google Access Token. You can use it to access the Google API.
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.debug('Provider user: ', user);
    if (callback) callback(user);
    return user;
    // ...
  } catch (err) {
    console.error('Error with LoginWithProvider: ', err);
    if (err.code === 'auth/account-exists-with-different-credential' && onExistingAccount) {
      onExistingAccount(err);
    }
  }
}

export default loginWithProvider;
