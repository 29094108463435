import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import {
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { Input, Logo, Text, Button } from '@castiron/components';
import * as yup from 'yup';
import firebase from 'firebase/compat/app';
import { apiUpdateShopAction, getShopAction, setFromOnboarding, setIsOnboarding } from '../../../store/reducers/shops';
import { useHistory } from 'react-router';
import {useTracking} from "@castiron/utils/build/hooks/useTracking";
import AdminForm from '../../AdminForm';

interface FormValues {
  firstName: string;
  lastName: string;
  postalCode: string;
  facebookLink?: string;
  instagramLink?: string;
  country: string;
}

interface ButtonState {
  text: string;
  className: string;
  disabled: boolean;
}

declare global {
  interface Window {
    google: any;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '30px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginRight: 0,
      width: '100%',
      '& .MuiButton-root': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  country: {
    width: '100%',
  },
  countrySelect: {
    marginTop: 16,
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  formControl: {
    display: 'flex',
    marginBottom: '15px',
    width: '100%',
    '& p': {
      alignItems: 'center',
      background: theme.branding.gray[600],
      border: `1px solid ${theme.branding.gray[400]}`,
      borderRadius: '3px 0 0 3px',
      display: 'flex',
      margin: 0,
      padding: '0 10px',
    },
  },
  formControlInline: {
    display: 'flex',
    marginBottom: '15px',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formLabel: {
    marginBottom: 0,
    color: theme.palette.text.primary,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  innerContainer: {
    height: '100vh',
  },
  logo: {
    maxWidth: 136,
    margin: '1em 0',
  },
  name: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '1.5em 0',
  },
  socialInput: {
    '& .MuiFormControl-marginNormal': {
      margin: 0,
      width: '90%',
    },
  },
  socialLinks: {
    display: 'flex',
    marginTop: '1em',
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  root: {
    display: 'flex',
  },
  pageContainer: {
    flexGrow: 1,
    marginBottom: '30px',
  },
  onboardingContainer: {
    [theme.breakpoints.up('xs')]: {
      margin: 'auto',
      maxWidth: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '1em auto',
      maxWidth: '800px',
    },
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      minHeight: 'calc(100vh - 180px)',
    },
  },
  button: {
    height: 'fit-content'
  },
  buttonLoading: {
    color: theme.palette.grey.A400,
    height: 'fit-content'
  },
  stripeMsg: {
    padding: 21,
    borderRadius: 6,
    backgroundColor: 'RGBA(0, 95, 255, 0.05)',
    marginBottom: 16,
    width: '100%',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 16,
    },
  },
  letUsKnow: {
    textDecoration: 'underline',
    color: theme.branding.blue.primary,
    cursor: 'pointer',
    padding: '0px 3px',
  },
}));

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  postalCode: '',
  facebookLink: '',
  instagramLink: '',
  country: 'United States of America',
};

const onboardingSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(255)
    .required('Please enter your first name.'),
  lastName: yup
    .string()
    .max(255)
    .required('Please enter your last name'),
  postalCode: yup
    .string()
    .when('country', (country) => {
      if(country === 'Bolivia' || country === 'Hong Kong' || country === 'Netherlands') {
        return yup.string().notRequired()
      } else {
        return yup.string().required('Postal code or zip code required')
      }
    }),
    facebookLink: yup
    .string()
    .url('Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.')
    .max(255)
    .matches(
      /^http(s)?:\/\/(www\.)?facebook.com\/(.*?)/gi,
      'Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.',
    )
    .nullable(),
  instagramLink: yup
    .string()
    .url('Please enter a valid Instagram URL that follows the format https://instagram.com/your-username')
    .max(255)
    .matches(
      /^http(s)?:\/\/(www\.)?instagram.com\/(.*?)/gi,
      'Please enter a valid Instagram URL that follows the format https://instagram.com/your-username.',
    )
    .nullable(),
  country: yup
    .string()
    .required('Please select your country'),
});

const countryList = [
  {'id': 'ar', 'name': 'Argentina'},
  {'id': 'au', 'name': 'Australia'},
  {'id': 'at', 'name': 'Austria'},
  {'id': 'be', 'name': 'Belgium'},
  {'id': 'bo', 'name': 'Bolivia'},
  {'id': 'bg', 'name': 'Bulgaria'},
  {'id': 'ca', 'name': 'Canada'},
  {'id': 'cl', 'name': 'Chile'},
  {'id': 'co', 'name': 'Colombia'},
  {'id': 'cr', 'name': 'Costa Rica'},
  {'id': 'hr', 'name': 'Croatia'},
  {'id': 'cy', 'name': 'Cyprus'},
  {'id': 'cz', 'name': 'Czech Republic'},
  {'id': 'dk', 'name': 'Denmark'},
  {'id': 'do', 'name': 'Dominican Republic'},
  {'id': 'eg', 'name': 'Egypt'},
  {'id': 'ee', 'name': 'Estonia'},
  {'id': 'fi', 'name': 'Finland'},
  {'id': 'fr', 'name': 'France'},
  {'id': 'de', 'name': 'Germany'},
  {'id': 'gr', 'name': 'Greece'},
  {'id': 'hk', 'name': 'Hong Kong'},
  {'id': 'hu', 'name': 'Hungary'},
  {'id': 'is', 'name': 'Iceland'},
  {'id': 'in', 'name': 'India'},
  {'id': 'ie', 'name': 'Ireland'},
  {'id': 'il', 'name': 'Israel'},
  {'id': 'it', 'name': 'Italy'},
  {'id': 'ke', 'name': 'Kenya'},
  {'id': 'lv', 'name': 'Latvia'},
  {'id': 'li', 'name': 'Liechtenstein'},
  {'id': 'lt', 'name': 'Lithuania'},
  {'id': 'lu', 'name': 'Luxembourg'},
  {'id': 'mt', 'name': 'Malta'},
  {'id': 'mx', 'name': 'Mexico'},
  {'id': 'nl', 'name': 'Netherlands'},
  {'id': 'nz', 'name': 'New Zealand'},
  {'id': 'no', 'name': 'Norway'},
  {'id': 'py', 'name': 'Paraguay'},
  {'id': 'pe', 'name': 'Peru'},
  {'id': 'ph', 'name': 'Philippines'},
  {'id': 'pl', 'name': 'Poland'},
  {'id': 'pt', 'name': 'Portugal'},
  {'id': 'ro', 'name': 'Romania'},
  {'id': 'sa', 'name': 'Saudi Arabia'},
  {'id': 'sg', 'name': 'Singapore'},
  {'id': 'sk', 'name': 'Slovakia'},
  {'id': 'si', 'name': 'Slovenia'},
  {'id': 'za', 'name': 'South Africa'},
  {'id': 'es', 'name': 'Spain'},
  {'id': 'se', 'name': 'Sweden'},
  {'id': 'th', 'name': 'Thailand'},
  {'id': 'tt', 'name': 'Trinidad and Tobago'},
  {'id': 'tr', 'name': 'Turkey'},
  {'id': 'gb', 'name': 'United Kingdom'},
  {'id': 'us', 'name': 'United States of America'},
  {'id': 'uy', 'name': 'Uruguay'},
];

type Props = {
  setCategory?: React.Dispatch<React.SetStateAction<string>>;
};

const ShopLaunchStep: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultButtonState = { text: 'Done', className: classes.button, disabled: false };
  const [buttonState, setButtonState] = useState<ButtonState>(defaultButtonState);
  const { trackPage } = useTracking();

  const {
    setCategory,
  } = props;

  const postalRequired = (country: string) => {
    if(country === 'Bolivia' || country === 'Hong Kong' || country === 'Netherlands') {
      return false;
    } else {
      return true;
    }
  };

  const setButtonLoading = () => {
    setButtonState({
      text: 'Finalizing . . .',
      className: classes.buttonLoading,
      disabled: true,
    });
  };

  const setButtonNormal = () => {
    setButtonState(defaultButtonState);
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    trackPage();
  }, []);

  const onSubmit = async values => {
    setButtonLoading();

    const user = firebase.auth().currentUser;

    await dispatch(
      apiUpdateShopAction({
        id: user.uid,
        props: {
          firstName: values.firstName,
          lastName: values.lastName,
          location: values.location,
          socialMedia: {
            facebookLink: values.facebookLink ? values.facebookLink : null,
            instagramLink: values.instagramLink ? values.instagramLink : null,
          },
          physicalAddress: {
            country: values.country,
            postalCode: values.postalCode,
          },
        },
      }),
    );

    await user.updateProfile({
      displayName: `${values.firstName} ${values.lastName}`,
    });

    const onboardP = dispatch(setIsOnboarding(false));
    const fromOnboardP = dispatch(setFromOnboarding(true));
    const shopP = dispatch(getShopAction(user.uid));
    await Promise.all([onboardP, fromOnboardP, shopP]);
    history.push('/');
  };

  return (
    <div className={classes.pageContainer}>
      <Grid className={classes.onboardingContainer} container xs={12}>
        <Formik onSubmit={onSubmit} validationSchema={onboardingSchema} initialValues={initialValues}>
          {({ handleSubmit, touched, errors, setFieldValue, values }: FormikProps<FormValues>) => (
            <AdminForm className={classes.form}>
              <Grid container className={classes.innerContainer}>
                <Grid className={classes.header} container>
                  <div className={classes.logo}>
                    <Logo width={144} style={{ marginRight: 19 }} />
                  </div>
                  <Typography className={classes.subtitle} variant="body2">
                    Step 3 of 3
                  </Typography>
                </Grid>
                <Grid className={classes.form} container xs={12}>
                  <Typography className={classes.title} variant="h1">
                    A little about you
                  </Typography>
                  <Typography className={classes.subtitle} variant="body2">
                    We'll use these details to create your store and let your customers know who they're buying from.
                  </Typography>
                  <Grid className={classes.name} container>
                    <FormControl className={classes.formControlInline} required>
                      <FormLabel className={classes.formLabel} required>
                        First name
                      </FormLabel>
                      <Input
                        error={touched.firstName && errors.firstName}
                        id="firstName"
                        required
                        type="text"
                        name="firstName"
                      />
                    </FormControl>
                    <FormControl className={classes.formControlInline} required>
                      <FormLabel className={classes.formLabel} required>
                        Last name
                      </FormLabel>
                      <Input
                        error={touched.lastName && errors.lastName}
                        id="lastName"
                        required
                        type="text"
                        name="lastName"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    wrap="nowrap"
                    direction='column'
                    alignItems='flex-start'
                  >
                    <Grid item xs={12} className={classes.country}>
                      <FormControl className={classes.formControl}>
                        <FormLabel className={classes.formLabel} required>
                          Country
                        </FormLabel>
                        <Select
                          // error={touched.country && errors.country}
                          id="country"
                          required
                          name="country"
                          onChange={event => setFieldValue('country', event.target.value as string)}
                          variant="outlined"
                          className={classes.countrySelect}
                          value={values.country}
                        >
                          {countryList.map((country) => (
                            <MenuItem key={country.id} value={country.name}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.stripeMsg}>
                      <Text presetFont="txt--s" style={{ backgroundColor: 'unset', border: 'unset' }}>
                        Transactions through Castiron are currently only in USD but your business can be based <a href="https://stripe.com/docs/connect/cross-border-payouts" target="_blank">anywhere Stripe© is available</a>!
                      </Text>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4} style={{ width: '100%' }}>
                      <FormControl className={classes.formControl}>
                        <FormLabel className={classes.formLabel} required={postalRequired(values.country)}>
                          Your postal/zip code
                        </FormLabel>
                        <Input
                          id="postalCode"
                          type="text"
                          name="postalCode"
                          error={touched.postalCode && errors.postalCode}
                          placeholder="Postal/Zip code"
                          fullWidth={isMobile}
                          onChange={e => {
                              setFieldValue('postalCode', e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>Facebook page link</FormLabel>
                    <Input
                      error={touched.facebookLink && errors.facebookLink}
                      id="facebookLink"
                      type="text"
                      name="facebookLink"
                      placeholder="https://www.facebook.com/your-username"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>Instagram link</FormLabel>
                    <Input
                      error={touched.instagramLink && errors.instagramLink}
                      id="instagramLink"
                      type="text"
                      name="instagramLink"
                      placeholder="https://www.instagram.com/your-username"
                    />
                  </FormControl>
                </Grid>
                <Grid className={classes.buttonsContainer}>
                  <Button
                    onClick={() => handleSubmit()}
                    type="submit"
                    className={buttonState.className}
                    disabled={buttonState.disabled}
                    variant="contained"
                  >
                    {buttonState.text}
                  </Button>
                </Grid>
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default ShopLaunchStep;
