import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';

export type Props = {
  itemName?: string;
  onDelete?: () => Promise<void>;
};

const useStyles = makeStyles(() => ({
  title: {
    '& h2': {
      fontWeight: 800,
    },
  },
  button: {
    fontWeight: 600,
  },
}));

const DeleteModal: React.FC<Props> = (props: Props) => {
  const { itemName, onDelete } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="xs" show={true}>
      <DialogTitle className={classes.title} id="alert-dialog-title">{`Delete ${itemName || 'item'}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to delete this ${itemName || 'item'}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose} color="primary">
          NO
        </Button>
        <Button className={classes.button} onClick={onDelete || handleClose} color="primary">
          YES
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default DeleteModal;
