import React from 'react';
import { useAppDispatch } from '../../hooks';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { closeModal } from '../../store/reducers/modalConductor';

type Props = {
  show: boolean;
  children: React.ReactNode;
  size?: any;
  fullWidth?: boolean;
  fullScreen?: boolean;
  paperClass?: any;
  hideBackdrop?: boolean;
  disableBackdropClick?: boolean;
  disableEnforceFocus?: boolean;
  disableScrollLock?: boolean;
  rootClass?: string;
  TransitionComponent?: any;
  onClose?: () => void;
  className?: any;
};

const ModalWrapper: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line
  const dispatch = useAppDispatch();
  const handleClose = (): void => {
    if (props.onClose) props.onClose();
    dispatch(closeModal());
  };
  const theme = useTheme();
  const screenIsXS = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      disableRestoreFocus
      disableBackdropClick={props.disableBackdropClick}
      disableEnforceFocus={props.disableEnforceFocus}
      hideBackdrop={props.hideBackdrop}
      disableScrollLock={props.disableScrollLock}
      classes={{
        paper: props.paperClass,
        root: props.rootClass,
      }}
      open={props.show}
      fullScreen={props.fullScreen || screenIsXS}
      maxWidth={props.size || false}
      TransitionComponent={props.TransitionComponent}
      onClose={handleClose}
      className={props.className || ''}
    >
      {props.children}
    </Dialog>
  );
};

export default ModalWrapper;