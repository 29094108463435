import React, {ReactNode, useContext, useEffect, useState} from "react";
import {User} from "../../models/User";
import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {Button, Link, Logo, Text} from "@castiron/components";

import {FirebaseContext} from "@castiron/castiron-firebase";
import firebase from 'firebase/compat/app';
import {useHistory, useLocation} from "react-router";

interface Props {
  email?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  onboardingContainer: {},
  header: {
    backgroundColor: theme.branding.gray[800],
    display: 'flex',
    padding: 16,
    width: '100%',
  },
  headerText: {
    color: '#fff',
    fontSize: '.875em',
    fontWeight: 'bold',
  },
  logo: {
    '& path': {
      fill: '#fff', // logo text
    },
    '& #Group-3': {
      fill: '#fff', // logo pan outline
    },
  },
  content: {
    marginTop: '180px',
    textAlign: 'center'
  },
  title: {
    marginBottom: '24px'
  },
  resendBox: {
    marginTop: '24px',
    padding: '8px 24px 8px 24px',
  },
  loginButton: {
    color: theme.branding.gray[800]
  }
}));

const ExistingAccount: React.FC<Props> = (props: Props) => {
  const { email:emailProp } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{existingAccountEmail?: string}>();

  const email = emailProp || location.state?.existingAccountEmail;

  const onClick = () => {
    history.push('/', {
      existingAccountFromSignup: true
    });
  }

  return (
    <Grid className={classes.onboardingContainer} container direction="column" justify="center" alignContent="center">
      <Grid item className={classes.header} container component="header" justify="space-between" alignItems="center"
            xs={12}>
        <Logo width={144} className={classes.logo}/>
        <Typography className={classes.headerText} variant="h1" align="left">
          A Shop for Your Food Biz
        </Typography>
      </Grid>
      <Grid item container component="article" className={classes.content} xs={12} justify="center"
            alignContent="center" direction="column" alignItems="center">
        <Grid item className={classes.title}>
          <Text variant="h4">You're already on your way to business success!</Text>
        </Grid>
        <Grid item>
          <Text variant="body1">
            Good news! An account already exists for
            <br/>
            <Text bold>{email}</Text>
            Log in to pick up where you left off!
          </Text>
        </Grid>
        <Grid item className={classes.resendBox}>
          <Button variant="outlined" onClick={onClick} className={classes.loginButton}>Log In</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExistingAccount;
