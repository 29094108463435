import { Box, makeStyles, Theme } from '@material-ui/core';
import { Typography } from "@castiron/components";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { transactionRepository } from '../../domain';
import { useAppSelector } from '../../hooks';
import Difference from './Difference';
import Dinero from "dinero.js";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 24,
    display: 'flex',
    gap: 20,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  box: {
    flex: 1,
    borderRadius: 16,
    border: `1px solid ${theme.branding.gray[700]}3D`,
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      paddingTop: 18,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  title: {
    color: theme.branding.gray[800],
    fontWeight: 700,
    fontSize: 16,
    opacity: 0.5,
  },
  value: {
    textAlign: 'center',
  },
  sign: {
    color: '#868686',
    fontSize: 45,
    verticalAlign: 'super',
    fontFamily: 'Roboto',
  },
  decimal: {
    verticalAlign: 'super',
  },
}));

const Stats: React.FC = () => {
  const classes = useStyles();
  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [thisWeeksTransactions, setThisWeeksTransactions] = useState([]);
  const [lastWeeksTransactions, setLastWeeksTransactions] = useState([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

  useEffect(() => {
    const getTransactions = async () => {
      await setIsTransactionsLoading(true);
      if (shop && shop.id) {
        const thisWeekResponse = await transactionRepository.getHistoricalTransactions({
          shopId: shop.id,
          startDate: moment()
            .startOf('isoWeek')
            .unix(),
          endDate: moment()
            .endOf('isoWeek')
            .unix(),
        });

        await setThisWeeksTransactions(thisWeekResponse.filter(transaction => transaction.status !== 'canceled'));
        const lastWeekResponse = await transactionRepository.getHistoricalTransactions({
          shopId: shop.id,
          startDate: moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .unix(),
          endDate: moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
            .unix(),
        });
        await setLastWeeksTransactions(lastWeekResponse.filter(transaction => transaction.status !== 'canceled'));
      }
      setIsTransactionsLoading(false);
    };

    getTransactions();
  }, [shop]);

  let thisWeeksTransactionTotal = 0;
  let thisWeeksTransactionTotalParts = [];
  if (thisWeeksTransactions?.length > 0) {
    thisWeeksTransactionTotal = thisWeeksTransactions.reduce((acc, transaction) => acc + transaction.totals.total, 0);
    thisWeeksTransactionTotalParts = (thisWeeksTransactionTotal / 100).toFixed(2).split('.');
  }

  let lastWeeksTransactionTotal = 0;
  if (lastWeeksTransactions?.length > 0) {
    lastWeeksTransactionTotal = lastWeeksTransactions.reduce((acc, transaction) => acc + transaction.totals.total, 0);
  }

  return (
    <div className={classes.container}>
      <Box className={classes.box}>
        <div className={classes.titleContainer}>
          <Typography variant="subtitle1">Weekly Revenue</Typography>
          {!isTransactionsLoading && (
            <Difference oldValue={lastWeeksTransactionTotal} currentValue={thisWeeksTransactionTotal} unit="$" />
          )}
        </div>
        <Typography className={classes.value}>
          <Typography variant="h1" className={classes.decimal}>
            {Dinero({amount: thisWeeksTransactionTotal}).toFormat('$0.00')}
          </Typography>
        </Typography>
      </Box>
      <Box className={classes.box}>
        <div className={classes.titleContainer}>
          <Typography variant="subtitle1">Weekly Sales</Typography>
          {!isTransactionsLoading && (
            <Difference
              oldValue={lastWeeksTransactions?.length || 0}
              currentValue={thisWeeksTransactions?.length || 0}
            />
          )}
        </div>
        <Typography className={classes.value} variant="h1">{thisWeeksTransactions?.length}</Typography>
      </Box>
    </div>
  );
};

export default Stats;
