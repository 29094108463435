
import React from "react";
import SvgIcon, { SvgIconProps } from "../SvgIcon";

export const ConfettiIcon = (props: SvgIconProps) => (
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3576 81.0975C10.3401 81.0975 9.3226 80.7125 8.5251 79.97C7.3151 78.815 6.9026 77.1375 7.4526 75.57L22.8251 31.5975C23.2101 30.525 24.3651 29.9475 25.4651 30.3325C26.5376 30.7175 27.1151 31.8725 26.7301 32.9725L11.3576 76.9175L53.9001 59.18C54.9451 58.74 56.1551 59.235 56.5951 60.28C57.0351 61.325 56.5401 62.535 55.4951 62.975L12.9801 80.74C12.4576 80.9875 11.9076 81.0975 11.3576 81.0975Z" fill="#005FFF" />
    <path d="M53.3225 63.3875C48.18 63.3875 40.535 58.41 34.375 52.58C30.1675 48.5925 26.675 44.275 24.6125 40.425C21.4775 34.65 22.44 31.735 23.815 30.2775C25.1625 28.8475 28.05 27.72 33.99 30.4975C37.95 32.3675 42.46 35.585 46.6675 39.5725C54.725 47.1625 61.4625 57.3925 57.255 61.8475C56.2375 62.92 54.89 63.3875 53.3225 63.3875ZM27.665 32.9175C27.1975 32.9175 26.895 33 26.785 33.1375C26.4825 33.4675 26.4275 35.1725 28.215 38.4725C30.085 41.9375 33.275 45.8975 37.18 49.5825C46.09 57.9975 53.24 60.06 54.2025 59.0425C55.165 58.025 52.69 51.0125 43.78 42.5975C39.875 38.9125 35.75 35.9425 32.175 34.265C30.03 33.2475 28.545 32.9175 27.665 32.9175Z" fill="#005FFF" />
    <path d="M34.1551 70.895C25.1076 65.67 19.0851 56.76 16.3076 51.8925L19.8826 49.8575C22.4401 54.34 27.9951 62.59 36.1901 67.32L34.1551 70.895Z" fill="#005FFF" />
    <path d="M16.9127 77.495C14.6027 75.4875 12.3752 73.095 10.2852 70.4275L13.5302 67.8975C15.4552 70.345 17.4902 72.545 19.6077 74.3875L16.9127 77.495Z" fill="#005FFF" />
    <path d="M48.9499 36.6575C48.6199 36.6575 48.2899 36.5475 48.0149 36.3C47.4649 35.7775 47.4099 34.925 47.9324 34.3475C49.6099 32.505 51.8099 32.8625 53.5699 33.1925C55.2749 33.495 56.0999 33.55 56.7049 32.89C57.3099 32.23 57.1724 31.4325 56.7049 29.755C56.2374 28.0225 55.6599 25.8775 57.3374 24.035C59.0149 22.1925 61.2149 22.55 62.9749 22.88C64.6799 23.1825 65.5049 23.2375 66.1099 22.5775C66.7149 21.9175 66.5774 21.0925 66.1374 19.415C65.6699 17.6825 65.0924 15.5375 66.7699 13.695C68.4474 11.8525 70.6474 12.21 72.4074 12.5125C74.1399 12.815 74.9374 12.87 75.5424 12.21C76.0649 11.66 76.9174 11.605 77.4949 12.1275C78.0449 12.65 78.0999 13.5025 77.5774 14.08C75.8999 15.9225 73.6999 15.565 71.9399 15.235C70.2074 14.9325 69.4099 14.8775 68.8049 15.5375C68.1999 16.1975 68.3374 17.0225 68.7774 18.7C69.2449 20.4325 69.8224 22.5775 68.1449 24.42C66.4674 26.2625 64.2674 25.905 62.5074 25.575C60.7749 25.2725 59.9774 25.2175 59.3724 25.8775C58.7674 26.5375 58.9049 27.335 59.3724 29.0125C59.8399 30.745 60.4174 32.89 58.7399 34.7325C57.0624 36.575 54.8624 36.2175 53.1024 35.8875C51.3974 35.585 50.5724 35.53 49.9674 36.19C49.7199 36.52 49.3349 36.6575 48.9499 36.6575Z" fill="#005FFF" />
    <path d="M44.6599 29.0125C44.6049 29.0125 44.5499 29.0125 44.4674 29.0125C43.7249 28.93 43.2024 28.16 43.2849 27.4175C43.5049 25.465 45.5949 24.97 47.2999 24.5575C47.8224 24.4475 48.6199 24.255 49.0324 24.0625C48.6749 23.7875 47.9324 23.43 47.4649 23.1825C45.8974 22.4125 43.9724 21.45 44.1924 19.4975C44.4124 17.545 46.5024 17.05 48.2074 16.6375C48.7299 16.5275 49.5274 16.335 49.9399 16.1425C49.5824 15.8675 48.8399 15.4825 48.3724 15.2625C46.8324 14.4925 44.8799 13.53 45.0999 11.5775C45.3199 9.625 47.4099 9.13 49.1149 8.7175C49.7749 8.5525 50.8749 8.305 51.0949 8.0575C51.1774 7.315 51.8099 6.875 52.5799 6.93C53.3224 7.0125 53.8449 7.7825 53.7624 8.525C53.5424 10.4775 51.4524 10.9725 49.7474 11.385C49.2249 11.5225 48.4274 11.715 48.0149 11.9075C48.3724 12.1825 49.1149 12.5675 49.6099 12.7875C51.1499 13.5575 53.1024 14.52 52.8824 16.4725C52.6624 18.425 50.5724 18.92 48.8674 19.3325C48.3449 19.4425 47.5474 19.635 47.1349 19.8275C47.4924 20.1025 48.2349 20.46 48.7024 20.7075C50.2424 21.4775 52.1949 22.44 51.9749 24.365C51.7549 26.3175 49.6649 26.8125 47.9599 27.225C47.2999 27.39 46.1999 27.6375 45.9799 27.885C45.8974 28.5725 45.3199 29.0125 44.6599 29.0125Z" fill="#005FFF" />
    <path d="M44.6599 29.0125C44.6049 29.0125 44.5499 29.0125 44.4674 29.0125C43.7249 28.93 43.2024 28.16 43.2849 27.4175C43.5049 25.465 45.5949 24.97 47.2999 24.5575C47.8224 24.4475 48.6199 24.255 49.0324 24.0625C48.6749 23.7875 47.9324 23.43 47.4649 23.1825C45.8974 22.4125 43.9724 21.45 44.1924 19.4975C44.4124 17.545 46.5024 17.05 48.2074 16.6375C48.7299 16.5275 49.5274 16.335 49.9399 16.1425C49.5824 15.8675 48.8399 15.4825 48.3724 15.2625C46.8324 14.4925 44.8799 13.53 45.0999 11.5775C45.3199 9.625 47.4099 9.13 49.1149 8.7175C49.7749 8.5525 50.8749 8.305 51.0949 8.0575C51.1774 7.315 51.8099 6.875 52.5799 6.93C53.3224 7.0125 53.8449 7.7825 53.7624 8.525C53.5424 10.4775 51.4524 10.9725 49.7474 11.385C49.2249 11.5225 48.4274 11.715 48.0149 11.9075C48.3724 12.1825 49.1149 12.5675 49.6099 12.7875C51.1499 13.5575 53.1024 14.52 52.8824 16.4725C52.6624 18.425 50.5724 18.92 48.8674 19.3325C48.3449 19.4425 47.5474 19.635 47.1349 19.8275C47.4924 20.1025 48.2349 20.46 48.7024 20.7075C50.2424 21.4775 52.1949 22.44 51.9749 24.365C51.7549 26.3175 49.6649 26.8125 47.9599 27.225C47.2999 27.39 46.1999 27.6375 45.9799 27.885C45.8974 28.5725 45.3199 29.0125 44.6599 29.0125Z" fill="#005FFF" />
    <path d="M63.3876 46.7775C61.7376 46.7775 60.7751 45.0725 59.9776 43.6975C59.6476 43.12 59.0976 42.13 58.7951 41.965C58.0251 42.075 57.4201 41.6075 57.3101 40.865C57.1726 40.1225 57.7776 39.4075 58.5201 39.2975C60.4726 38.995 61.5176 40.8375 62.3701 42.35C62.6176 42.8175 63.0301 43.505 63.3326 43.8625C63.4976 43.4225 63.6351 42.625 63.7451 42.1025C64.0476 40.3975 64.4326 38.28 66.3576 37.95C68.2826 37.6475 69.3551 39.49 70.2076 41.0025C70.4826 41.47 70.8676 42.185 71.1701 42.5425C71.3351 42.1025 71.4726 41.305 71.5826 40.7825C71.8851 39.0775 72.2701 36.96 74.1951 36.63C76.1476 36.3275 77.1926 38.17 78.0451 39.6825C78.3751 40.26 78.9251 41.25 79.2276 41.415C79.9976 41.2775 80.6026 41.7725 80.7126 42.515C80.8226 43.2575 80.2451 43.9725 79.5026 44.0825C77.5501 44.385 76.5051 42.5425 75.6526 41.03C75.3776 40.5625 74.9926 39.8475 74.6626 39.49C74.4976 39.93 74.3601 40.7275 74.2501 41.25C73.9476 42.955 73.5626 45.0725 71.6101 45.4025C69.6576 45.7325 68.6126 43.8625 67.7601 42.35C67.5126 41.8825 67.1001 41.1675 66.7976 40.8375C66.6326 41.2775 66.4951 42.075 66.3851 42.5975C66.0826 44.3025 65.6976 46.42 63.7726 46.75C63.6626 46.7775 63.5251 46.7775 63.3876 46.7775Z" fill="#005FFF" />
    <path d="M63.3876 46.7775C61.7376 46.7775 60.7751 45.0725 59.9776 43.6975C59.6476 43.12 59.0976 42.13 58.7951 41.965C58.0251 42.075 57.4201 41.6075 57.3101 40.865C57.1726 40.1225 57.7776 39.4075 58.5201 39.2975C60.4726 38.995 61.5176 40.8375 62.3701 42.35C62.6176 42.8175 63.0301 43.505 63.3326 43.8625C63.4976 43.4225 63.6351 42.625 63.7451 42.1025C64.0476 40.3975 64.4326 38.28 66.3576 37.95C68.2826 37.6475 69.3551 39.49 70.2076 41.0025C70.4826 41.47 70.8676 42.185 71.1701 42.5425C71.3351 42.1025 71.4726 41.305 71.5826 40.7825C71.8851 39.0775 72.2701 36.96 74.1951 36.63C76.1476 36.3275 77.1926 38.17 78.0451 39.6825C78.3751 40.26 78.9251 41.25 79.2276 41.415C79.9976 41.2775 80.6026 41.7725 80.7126 42.515C80.8226 43.2575 80.2451 43.9725 79.5026 44.0825C77.5501 44.385 76.5051 42.5425 75.6526 41.03C75.3776 40.5625 74.9926 39.8475 74.6626 39.49C74.4976 39.93 74.3601 40.7275 74.2501 41.25C73.9476 42.955 73.5626 45.0725 71.6101 45.4025C69.6576 45.7325 68.6126 43.8625 67.7601 42.35C67.5126 41.8825 67.1001 41.1675 66.7976 40.8375C66.6326 41.2775 66.4951 42.075 66.3851 42.5975C66.0826 44.3025 65.6976 46.42 63.7726 46.75C63.6626 46.7775 63.5251 46.7775 63.3876 46.7775Z" fill="#005FFF" />
    <path d="M36.4374 27.005C37.7588 27.005 38.8299 25.9338 38.8299 24.6125C38.8299 23.2912 37.7588 22.22 36.4374 22.22C35.1161 22.22 34.0449 23.2912 34.0449 24.6125C34.0449 25.9338 35.1161 27.005 36.4374 27.005Z" fill="#005FFF" />
    <path d="M64.5424 55.4675C65.8637 55.4675 66.9349 54.3963 66.9349 53.075C66.9349 51.7537 65.8637 50.6825 64.5424 50.6825C63.2211 50.6825 62.1499 51.7537 62.1499 53.075C62.1499 54.3963 63.2211 55.4675 64.5424 55.4675Z" fill="#005FFF" />
  </svg>

);