import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Input, Theme } from "@material-ui/core";
import { getService } from "../../firebase";
import { Transaction } from "@castiron/domain";
import { capitalize, removeEmpty } from "@castiron/utils";
import Select from "../Select";
import Dinero from "dinero.js";
import { OrderSearchResult } from "@castiron/domain/build/order";
import { useAppSelector } from "../../hooks";

interface Props {

}

const useStyles = makeStyles((theme: Theme) => ({}));

const searchService = getService('orders', 'search', { version: 2 });
const calendarSearch = getService('shops', 'calendareventsearch', { version: 2 });


const SearchTest: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const [total, setTotal] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [results, setResults] = useState<OrderSearchResult[]>([]);
  const [status, setStatus] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [fulfullType, setFulfullType] = useState<string>('');
  const [custName, setCustName] = useState<string>('');
  const [prodTitle, setProdTitle] = useState<string>('');

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    const search = async () => {
      const results = await searchService(removeEmpty({
        stage: 'order',
        type: type || undefined,
        status: status || undefined,
        fulfillmentMethod: fulfullType || undefined,
        customerName: custName || undefined,
        productTitle: prodTitle || undefined,
      }));
      const events = await calendarSearch({
        startTime: 1673901139,
        endTime: 1674505939,
        type: 'availability',
        shopId: shop?.id,
      });
      console.debug('Events', events);
      setTotal(results.total);
      setTotalSales(results.totalSales);
      setResults(results.orders);
    }

    search();
    console.debug('Here', {
      type, status, fulfullType
    })
  }, [type, status, fulfullType, custName, prodTitle]);

  return <Grid container direction="column" spacing={ 3 }>
    <Grid item container direction="row" spacing={ 2 }>
      <Grid item>
        <Select label="Status" options={ ['','active',
          'open',
          'fulfilled',
          'completed',
          'canceled'].map(f => ({ label: f === '' ? 'All' : capitalize(f), value: f })) } onChange={ e => {
          setStatus(e.target.value)
        } } value={ status } placeholder='Status'/>
      </Grid>
      <Grid item>
        <Select label="Type" options={ ['','standard', 'custom'].map(f => ({ label: f === '' ? 'All' : capitalize(f), value: f })) }
                onChange={ e => setType(e.target.value) } value={ type } placeholder='Type'/>
      </Grid>
      <Grid item>
        <Select label="Fulfillment Type"
                options={ ['','pickup', 'delivery', 'shipping'].map(f => ({ label: f === '' ? 'All' : capitalize(f), value: f })) }
                onChange={ e => setFulfullType(e.target.value) } value={ fulfullType } placeholder='Fulfillment Type'/>
      </Grid>
      <Grid item>
        <Input value={custName} onChange={e => setCustName(e.target.value)} placeholder="Customer Name"/>
      </Grid>
      <Grid item>
        <Input value={prodTitle} onChange={e => setProdTitle(e.target.value)} placeholder="Product Name"/>
      </Grid>
    </Grid>
    <Grid item>
      <strong>Total: { total }</strong>
    </Grid>
    <Grid item>
      <strong>Total Sales: { Dinero({ amount: totalSales || 0 }).toFormat('$0.00') }</strong>
    </Grid>
    { results.map(order => (<Grid item container direction="row" key={ order.id } spacing={ 3 }>
      <Grid item style={{width: 50}}>{ order.orderNumber }</Grid>
      <Grid item style={{width: 100}}>{ order.type }</Grid>
      <Grid item style={{width: 200}}>{ order.customerName }</Grid>
      <Grid item style={{width: 100}}>{ order.fulfillmentOption?.type }</Grid>
      <Grid item style={{width: 100}}>{ Dinero({ amount: order.total || 0 }).toFormat('$0.00') }</Grid>
      <Grid item>{ order.status }</Grid>
    </Grid>)) }
  </Grid>;
}

export default SearchTest;
