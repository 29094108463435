const dataOnly = <T>(doc: T): T => {
  const entries = Object.entries(doc);
  const recurse = entries.map(e => {
    const [key, value] = e;
    let cleanValue;
    if (value instanceof Object && !(value instanceof Function) && !(value instanceof Array)) {
      cleanValue = value.data && value.data instanceof Function ? value.data() : dataOnly(value);
    } else {
      cleanValue = value;
    }
    return [key, cleanValue];
  });
  const noFunct = recurse.filter(e => !(e[1] instanceof Function));
  return Object.fromEntries(noFunct) as T;
};

export default dataOnly;
