import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const WholeIngredientsIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 20" {...props}>
    <path
      d="M8.99994 0C8.50289 0 8.09994 0.402944 8.09994 0.9V1.86316C3.55198 2.3193 0 6.19524 0 10.9092C0 15.9299 4.02944 20.0001 9 20.0001C13.9706 20.0001 18 15.9299 18 10.9092C18 6.1952 14.448 2.31923 9.89994 1.86315V0.9C9.89994 0.402944 9.497 0 8.99994 0ZM8.09994 3.87651V4.55455C8.09994 5.0516 8.50289 5.45455 8.99994 5.45455C9.497 5.45455 9.89994 5.0516 9.89994 4.55455V3.87649C13.3268 4.32311 16 7.2857 16 10.9092C16 14.8444 12.8471 18.0001 9 18.0001C5.15291 18.0001 2 14.8444 2 10.9092C2 7.28574 4.67312 4.32318 8.09994 3.87651Z"
      fill="#005FFF"
    />
  </SvgIcon>
);