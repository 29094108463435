import React from 'react';
import { Button } from '@castiron/components';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '16px 0px',
  },
  leftButton: {
    marginRight: 4,
  },
  rightButton: {
    marginLeft: 4,
  },
}));

const OnboardingFooter: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { step, nextStep, prevStep } = props;

  const handlePrevStep = () => {
    window.scrollTo(0, 0);
    prevStep();
  };

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    nextStep();
  };

  return (
    <Grid container item xs={12} spacing={1} justify="flex-end" alignItems="center" className={classes.container}>
      {step !== 1 && (
        <Button className={classes.leftButton} variant="outlined" onClick={handlePrevStep}>
          Back
        </Button>
      )}
      <Button type="submit" className={classes.rightButton} variant="contained" color="primary" onClick={handleNextStep}>
        {step === 5 ? 'Finish' : 'Continue'}
      </Button>
    </Grid>
  );
};

export default OnboardingFooter;
